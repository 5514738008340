import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorageService} from './token-storage.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {PaymentDetails} from '../model/PaymentDetails';

const API = environment.SERVER_BASE_URL + 'pay';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  private paymentDetails: PaymentDetails = {};

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
  }


  createPayment(bidTAG: string, fee: number): Observable<any> {
    return this.http.post<any>(API, {
      price: 1, //fixme: change for fee later
      currency: 'PLN',
      method: 'paypal',
      intent: 'sale',
      description: 'testing',
      bidTag: bidTAG
    });
  }

  executePayment(bidTAG: string): Observable<any> {
    console.log(this.paymentDetails);
    return this.http.post<any>(API + '/execute', {
      paymentId: this.paymentDetails.paymentId,
      payerID: this.paymentDetails.payerID,
      ifSucceed: String(this.paymentDetails.issuccs),
      bidTag: bidTAG
    });
  }


  isPaymentBeingProcessed(activatedRoute: ActivatedRoute): boolean {
    const tag = activatedRoute.snapshot.paramMap.get('tag');
    activatedRoute.queryParams.subscribe(params => {
      this.paymentDetails.issuccs = params['issuccs'];
      this.paymentDetails.payerID = params['PayerID'];
      this.paymentDetails.paymentId = params['paymentId'];
    });
    return this.paymentDetails.issuccs !== undefined;
  }

}
