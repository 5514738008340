import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-painter',
  templateUrl: './become-painter.component.html',
  styleUrls: ['./become-painter.component.scss']
})
export class BecomePainterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollToShowHow(): void {
    document.getElementById('section-two-index')!.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

}
