<div class="wrapper">
  <!-- <div class="project-creator-header" *ngIf="headerVisible">
    <button class="custom-button" (click)="navigateBack()">BACK</button>
    <button class="custom-button cancel-button" (click)="cancelProjectCreation()">CANCEL</button>
  </div> -->

  <div class="project-creator-header">
    <button class="custom-button" (click)="navigateBack()">BACK</button>
    <button class="custom-button cancel-button" (click)="cancelProjectCreation()">CANCEL</button>
  </div>

  <!--CHOOSE CREATOR TYPE-->
    <div class="page">
        <div class="page-title">Create a New Project</div>

        <div class="way-card-wrapper">
            <div class="way-card">
                <div class="way-header basic">Basic Creator</div>
                <div class="way-content">
                    <div class="way-title">
                        Do you like to keep things as
                        simple as possible?
                    </div>
                    <div class="way-description">
                        Then this creator was designed for you! Create
                        your project and post it on the market in <b>less
                            than 60 seconds!</b>
                    </div>
                    <div class="creator-way">
                        <a routerLink="/home/make-new-project-details">

                            <img src="./assets/icons/creator-way.png" (click)="choosePostingWay(EPostingNewProjectWay.BASIC_WAY)">
                        </a>
                    </div>
                </div>
            </div>

            <div class="way-card">
                <div class="way-header advanced">Advanced Creator</div>

                <div class="way-content">
                    <div class="way-title">
                        Are you all about details?
                    </div>
                    <div class="way-description">
                        You will be asked to provide us with some more
                        detailed information so our model can estimate
                        cost of your project
                    </div>
                    <div class="additional-info">
                        <img src="./assets/icons/tick.png">
                        <div class="additional-info-text">Includes Pro Features</div>
                    </div>
                    <div class="creator-way">
                        <a routerLink="/home/make-new-project-details">

                            <img src="./assets/icons/creator-way.png"  (click)="choosePostingWay(EPostingNewProjectWay.ALGORITHM_WAY)">

                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

  <!--CHOOSE PROJECT TYPE BASIC PATH-->
  <div class="page" *ngIf="projectTypeSelectorVisible && basicCreatorVisible && !creatorSelectorVisible">
    <div class="page-title">Choose project type</div>
    <app-create-new-project-page>
      <div class="choose-project-type" page-content>
        <div class="project-type-wrapper" *ngFor="let projectType of pathSelectors.projectTypes">
          <div class="project-type-card" (click)="chooseProjectType(projectType.value)">
            <img [src]="projectType.image" alt="projectTypeImage">
            <div class="project-type-info-wrapper">
              <div class="project-type-label">{{projectType.label}}</div>
              <div class="project-type-description">{{projectType.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </app-create-new-project-page>
  </div>

  <!--CHOOSE PROJECT TYPE ADVANCED PATH -->
  <div class="page"
    *ngIf="!basicCreatorVisible && !creatorSelectorVisible && !projectAdvancedExterior && !projectAdvancedInterior">
    <div class="page-title">Choose project type</div>
    <app-create-new-project-page>
      <div class="choose-project-type" page-content>
        <div class="project-type-wrapper" *ngFor="let projectType of pathSelectors.projectTypes">
          <div class="project-type-card" *ngIf="projectType.ifAdvanced" (click)="chooseProjectType(projectType.value)">
            <img [src]="projectType.image" alt="projectTypeImage">
            <div class="project-type-info-wrapper">
              <div class="project-type-label">{{projectType.label}}</div>
              <div class="project-type-description">{{projectType.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </app-create-new-project-page>
  </div>

  <!--CHOOSE JOB TYPE FOR EXTERIOR ADVANCED -->
  <div *ngIf="projectAdvancedExterior && choosingJobType" class="page">
    <div class="page-title">Chose job type for your exterior project</div>
    <app-create-new-project-page>
      <div class="displaying-pages-images" page-content>
        <div class="image-wrapper" *ngFor="let job of pathSelectors.jobTypes;" (click)="chooseJobType(job.value)">
          <img class="image-image" [src]="job.image" alt="section-item">
          <div class="image-label">{{job.label}}</div>
        </div>
      </div>
    </app-create-new-project-page>
  </div>

  <!--QUESTIONS -->
  <div class="page" *ngIf="displayingPages">
    <div *ngFor="let section of pages;" [ngClass]="{'hidden': section.id  !== currentPage}" class="single-page">
      <div class="page-title">{{ pages[currentPage - 1].title }}</div>
      <app-create-new-project-page>
        <div class="displaying-page-content" page-content>
          <div class="displaying-pages-boxes" *ngIf="section.type === EProjectCreatorInputType.SELECT">
            <!--<div [ngClass]="[item.selected ? 'selected box' : 'box' ]" *ngFor="let item of section.items"
                 (click)="changeCheckboxState(item.value, currentPage - 1, section)">
              {{item.label}}
            </div>
            <!-- -------------Code by rupinder------------- -->
            <div class="select_room_no">
              <select [(ngModel)]="room" class="select-input"
                (change)="changeCheckboxState(room,  currentPage - 1, section)">
                <option value="" disabled>Select</option>
                <option *ngFor="let item of section.items" [value]="item.value"> {{item.label}}</option>
              </select>
            </div>
          </div>
          <div class="displaying-pages-boxes" *ngIf="section.type === EProjectCreatorInputType.BOXES">
            <div [ngClass]="[item.selected ? 'selected box' : 'box' ]" *ngFor="let item of section.items"
              (click)="changeCheckboxState(item.value, currentPage - 1, section)">
              {{item.label}}
            </div>
            <!-- -------------Code by rupinder------------- -->

          </div>


          <div class="displaying-pages-boxes-with-images"
            *ngIf="section.type === EProjectCreatorInputType.BOXES_WITH_IMAGES">
            <div [ngClass]="[item.selected ? 'selected box-with-image' : 'box-with-image' ]"
              *ngFor="let item of section.items" (click)="changeCheckboxState(item.value, currentPage - 1, section)">
              <img class="box-with-image-image" *ngIf="item.image" alt="box-with-image-icon" [src]="item.image">
              <div [ngClass]="item.image == null ? 'text-align-center box-with-image-label' : 'box-with-image-label'">
                {{item.label}}</div>
            </div>
          </div>

          <div class="displaying-pages-checkboxes" *ngIf="section.type === EProjectCreatorInputType.CHECKBOXES">
            <div class="checkbox-wrapper" *ngFor="let item of section.items"
              (click)="changeCheckboxState(item.value, currentPage - 1, section)">
              <div class="checkbox-label">{{item.label}}</div>
              <img class="checkbox-image" alt="checkbox-icon"
                [src]="item.selected ? './assets/project-creator/checkbox-checked.png' : './assets/project-creator/checkbox-unchecked.png'">
            </div>
          </div>

          <div class="displaying-pages-images" *ngIf="section.type === EProjectCreatorInputType.IMAGES">
            <div [ngClass]="[item.selected ? 'selected image-wrapper' : 'image-wrapper' ]"
              *ngFor="let item of section.items" (click)="changeCheckboxState(item.value, currentPage - 1, section)">
              <img class="image-image" [src]="item.image" alt="section-item">
              <div class="image-label">{{item.label}}</div>
            </div>
          </div>

          <div class="displaying-pages-images-multiple"
            *ngIf="section.type === EProjectCreatorInputType.IMAGES_MULTIPLE">
            <div [ngClass]="[item.selected ? 'selected image-wrapper' : 'image-wrapper' ]"
              *ngFor="let item of section.items" (click)="changeCheckboxState(item.value, currentPage - 1, section)">
              <img class="image-image" [src]="item.image" alt="section-item">
              <div [ngClass]="item.selected ? 'selected image-label' : 'image-label'" class="image-label">{{item.label}}
              </div>
            </div>
          </div>

        </div>
      </app-create-new-project-page>

    </div>
  </div>

  <!-- CREATE ROOMS FOR INTERIOR ADVANCED -->
  <div class="page" *ngIf="projectAdvancedExterior && actualCreatorContent">
    <div class="page-title">Manage walls</div>
    <app-create-new-project-page>
      <app-exterior-creator (saveRooms)="assignRoomsAndGoNextPage($event)" page-content></app-exterior-creator>
    </app-create-new-project-page>
  </div>

  <!-- CREATE ROOMS FOR INTERIOR ADVANCED -->
  <div class="page" *ngIf="projectAdvancedInterior && actualCreatorContent">
    <div class="page-title">Manage your rooms</div>
    <app-create-new-project-page>
      <app-room-creator (saveRooms)="assignRoomsAndGoNextPage($event)" page-content></app-room-creator>
    </app-create-new-project-page>
  </div>

  <!-- PROJECT DETAILS -->
  <div class="page" *ngIf="projectDetailedInfoVisible">
    <div class="page-title">Project detailed info</div>
    <div class="page-content project-details" [formGroup]="projectDetailsForm">
      <div class="section-wrapper" *ngFor="let section of projectDetailsSections.items">
        <div class="project-details-header">{{section.title}}</div>
        <div class="project-details-description">{{section.description}}</div>
        <ng-template [ngIf]="section.id == 0">
          <div class="image-uploader">
            <input class="form-control" type="file" accept="image/*" multiple id="formFile" (change)="uploadFile($event)">
          </div>
        </ng-template>
        <ng-template [ngIf]="section.id == 1">
          <div class="text-area-wrapper">
            <textarea formControlName="title" placeholder="Enter title" maxlength="100" [rows]="1"
              (input)="characterCounterForTitle($event)"></textarea>
            <div class="hint">{{section.hint}}</div>
          </div>
          <div *ngIf="!projectDetailsForm.controls['title'].valid">
            <span style="color: red"
                *ngIf="projectDetailsForm.controls['title'].touched && projectDetailsForm.controls['title'].hasError('minlength')">
                Title must be at least 5 character
            </span>
        </div>
        </ng-template>
        <ng-template [ngIf]="section.id == 2">
          <div class="text-area-wrapper">
            <textarea formControlName="description" placeholder="Add description" maxlength="400" [rows]="6"
              (input)="characterCounterForDescription($event)"></textarea>
              
            <div class="hint">{{section.hint}}</div>
          </div>
          <div *ngIf="!projectDetailsForm.controls['description'].valid">
            <span *ngIf="projectDetailsForm.controls['description'].touched && projectDetailsForm.controls['description'].hasError('minlength')"> 
              Description must be at least 5 character
            </span>
          </div>
        </ng-template>
        <ng-template [ngIf]="section.id == 3">
          <div class="d-flex">
            <app-ngx-date-range-picker (dateChanged)="setProjectDetailsDate($event)"></app-ngx-date-range-picker>
          </div>
        </ng-template>
      </div>
      <div class="post-project-button">
        <button class="custom-button" (click)="sendNewProjectRequest()" [disabled]="!projectDetailsForm.valid"
          *ngIf="postProjectVisible">POST PROJECT</button>
      </div>
    </div>
  </div>

  <!-- SUMMARY-->
  <div class="summary" *ngIf="projectSummaryVisible">
    <div class="summary-wrapper">
      <img [src]="'./assets/project-creator/success-icon.png'" alt="success-icon">
      <div class="success-title">Your project has been successfully created!</div>
      <div class="success-hint">Your project has been posted to marketplace! You can check its details in My projects
        section
      </div>
      <div class="success-estimated-cost" *ngIf="this.projectType.value=='PROJECT_EXTERIOR'">
        <div class="cost-title">Total Estimate Cost(For all rooms)</div>
        <div class="cost">$4000</div>
      </div>
      <button class="custom-button" [routerLink]="'/home/my-project-card'">My Projects</button>
    </div>
  </div>

  <footer>
    <button class="custom-button" (click)="navigateForward()" [disabled]="nextButtonDisabled" *ngIf="nextButtonVisible">
      NEXT
    </button>
  </footer>
</div>