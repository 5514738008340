<!--<div class="notification-wrapper">
    <img class="icon" src="./assets/icons/notifications/notification-icon-cancelled.png">
    <div class="notification-main">
        <div class="notification-title"><span>Bid for your project was cancelled</span></div>
        <div class="notification-content">We are sorry but it seems like Brush has cancelled
            bid for your project.</div>
        <footer>
            <div class="how-long-ago">17:13 | 2022-04-08</div>
            <div class="clear-content">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>-->

<div class="route-title">
    <span>Notifications</span>
    <app-notification-bell></app-notification-bell>
  </div>
<div *ngFor="let item of notifications">
    <div class="notification-wrapper" *ngIf="isPainter && item.type=='dispute'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type)">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/my-jobs/completed-details/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>
     <div class="notification-wrapper" *ngIf="isCustomer && item.type=='dispute'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type)">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>
    <div class="notification-wrapper" *ngIf="isCustomer && item.type=='project approval'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type)">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>
     <div class="notification-wrapper" *ngIf="isPainter && item.type=='project' && item?.project?.status== 'approved'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type)">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/my-project-detail/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>
<div class="notification-wrapper" *ngIf="isPainter && item.type=='bid'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main" (click)="unseennoti(item.id,item.type)">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/my-bids/bid/{{item.type_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isCustomer && item.type=='bid'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main"  (click)="unseennoti(item.id,item.type)">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)" ><span routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>


<div class="notification-wrapper" *ngIf="isPainter && item.type=='project' && (item?.project?.status == 'brushed' || item?.project?.status == 'completed')">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png" >
    <div class="notification-main"  (click)="unseennoti(item.id,item.type)">

        <div *ngIf="item?.project?.status == 'brushed'" class="notification-title" ><span (click)="unseennoti(item.id,item?.project?.status);viewDemoDetailsscomp(item?.project_id, item?.project?.status)"> {{item.title}}</span></div>
                <div *ngIf="item?.project?.status == 'completed'" class="notification-title" ><span (click)="unseennoti(item.id,item?.project?.status);viewDemoDetailss(item?.project_id, item?.project?.status) ">{{item.title}}</span></div>

        <div class="notification-content" (click)="unseennoti(item.id,item.type)">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isCustomer && item.type=='project'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main"  (click)="unseennoti(item.id,item.type)">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span  routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isPainter && item.type=='chat'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main"  (click)="unseennoti(item.id,item.type)">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/chat/{{item.room_id}}/{{item.primary_room_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isCustomer && item.type=='chat'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main" (click)="unseennoti(item.id,item.type)">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"  routerLink="/home/cus-chat/{{item.room_id}}/{{item.primary_room_id}}"><span>{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>
</div>
<!--<div class="notification-wrapper">
  
    <img class="icon" src="./assets/icons/notifications/notification-icon-cancelled.png">
    <div class="notification-main">
        <div class="notification-title"><span>Bid cancelled</span></div>
        <div class="notification-content">We are sorry but it seems like Brush has cancelled
            bid for your project.</div>
        <footer>
            <div class="how-long-ago">17:13 | 2022-04-08</div>
            <div class="clear-content">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>
<!-- <app-modal [displayModal]="deleteMessageModalVisible" [title]="'Delete notification'"
    [subclaim]="'Are you sure you want to delete this notification?'" [confirmButtonText]="'Confirm'"
    (confirmButtonClicked)="deleteMessageEvent()" (cancelButtonClicked)="deleteModalToggle($event)"></app-modal> -->