<div class="login_page_wrap img_wrap_brsh register_pg">
  <div class="d-flex ovr_flw">
    <!-- <div class="div_imge_hm">
      <img src="assets/Rectangle.png" class="error-icon side_img">
    </div> -->
    <div class="main-box logn_wrap">
      <img src="assets/logo.png" class="error-icon">
      <p class="title">Register</p>

      <!-- <p class="title"><span *ngIf="!isPainterRegistration">Let's go!</span><span *ngIf="isPainterRegistration">Brush
          with us!</span>
      </p> -->
      <form [formGroup]="userDataForm" class="form margin-b10">
        <div class="input-wrapper">
          <label><span class="label-text">Email</span></label>
          <div class="div_eml div_incs">
            <span class="icon-wrapper icn_sld_pn"><img
                [src]="validateEmail() ?'assets/icons/errors/envelope-solid-error.svg' : 'assets/icons/envelope-solid.svg'"
                class="icon"></span>
            <input formControlName="emailAddress" class="email" type="email" placeholder="Enter your email" email>
          </div>
          <div class="error-wrp" *ngIf="validateEmail()">
            <div class="error-text" *ngIf="checkForEmailErrors()">{{emailErrorMessage}}</div>
          </div>
        </div>

        <div class="input-wrapper" *ngIf="isPainterRegistration">
          <label><span class="label-text">Phone number</span></label>
          <div class="div_incs">
            <span class="icon-wrapper icn_sld_pn"><img
                [src]="validatePhoneNumber() ?'assets/icons/errors/phone-solid-error.svg' : 'assets/icons/phone-solid.svg'"
                class="icon"></span>
            <input  formControlName="phoneNumber" class="email" type="tel"
              maxlength="13" placeholder="NXX-NXX-XXXX" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
          </div>
          <div class="error-wrp" *ngIf="validatePhoneNumber()">
            <div class="error-text" *ngIf="checkForPhoneNumberErrors()">{{phoneNumberErrorMessage}}</div>
          </div>
        </div>

        <div class="input-wrapper">
          <label><span class="label-text">Password</span></label>
          <div class="div_incs">
            <span class="icon-wrapper icn_sld_pn"><img
                [src]="validatePassword() ? 'assets/icons/errors/lock-solid-error.svg' : 'assets/icons/lock-solid.svg'"
                class="icon"></span>
            <input formControlName="password" [type]="isPwVisible ? 'text' : 'password'" class="email"
              placeholder="Enter password">
            <span class="hint" *ngIf="!isPwVisible">
              <a class="hint-text text_hide_inc" (click)="togglePasswordVisibility()">
                <div class="eye_ins_img"><svg width="1em" height="1em" viewBox="0 0 576 512"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                  </svg>
                </div>
              </a>
            </span>
            <span class="hint" *ngIf="isPwVisible">
              <a class="hint-text text_hide_inc" (click)="togglePasswordVisibility()">
                <div class="eye_ins_img"><svg width="1em" height="1em" viewBox="0 0 640 512"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                  </svg>
                </div>
              </a>
            </span>
          </div>
          <div class="error-wrp" *ngIf=validatePassword()>
            <div class="error-text" *ngIf="checkPasswordForErrors()">{{passwordErrorMessage}}</div>
          </div>
        </div>

      </form>
      <div class="div_tet div_rgstr d-flex">
        <div>
          <div class="check_box_css div_remmbr d-flex">
            <div><input class="form-check-input rempr_me" type="checkbox" name="remember_me" id="checkbox" [(ngModel)]="terms"
                ></div>
            <p class="wrp_fld" for="checkbox">By signing up, I agree to the <a class="text-purple" routerLink='/terms-conditions'>Terms and
              conditions</a> of Brush exchange</p>
  
                
          </div>
           <div class="error-wrp" *ngIf='showerror'>
              <div class="error-text" >Please agree terms and conditions</div>
            </div>
        </div>
        
        <!-- <div 
        class="checkbox-wrapper">
          <div class="checkbox">
            <input type="checkbox" id="checkbox" formControlName="accepted">
            
            <label for="checkbox"><span>By checking this box, you agree to our <a href="#" class="terms-policy">Terms of
                  Service</a> and <a href="#" class="terms-policy">Privacy
                  Policy</a> and consent to data transfer, hosting and processing outside of the EU.</span></label>
          </div>
        </div> -->

        <div class="div_btn_lgn ">
          <!-- <button (click)="submitRegisterForm()" [disabled]="!userDataForm.valid" class="custom-button">Get Started
            <svg width="1em" height="1em" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
              viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
            </svg>
          </button> -->

          <button class="custom-button" (click)="submitRegisterForm()" [disabled]="!userDataForm.valid" >Get Started
            <svg width="1em" height="1em" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
              viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
            </svg>
          </button>
        </div>
      </div>
      <div class="div_lgn_imgs">
        <p class="hint-small margin-b10">Or SignUp With</p>
        <div class="imgs_set_icn">
          <!--<div class="face_book" (click)="signInWithFacebook()">
            <img src="assets/Vector.png" alt="">
          </div>-->
          <div class="div_google" (click)="signInWithGoogle()">
            <img src="assets/Group 206.png" alt="">
          </div>
        </div>
        <div class="last_div_wrap" >
          <p>Already have an account?<a routerLink="/login" class="text-purple"> Login </a></p>
        </div>

        <!-- <div class="socials-wrapper">
          <div class="floating-button"><img src="assets/icons/social-media/google-brands.svg" class="icn"></div>
          <div class="floating-button"><img src="assets/icons/social-media/twitter-brands.svg" class="icn"></div>
          <div class="floating-button"><img src="assets/icons/social-media/facebook-f-brands.svg" class="icn"></div>
        </div> -->
      </div>

    </div>
  </div>
</div>