import {Injectable} from '@angular/core';
import {ProjectBasicData} from '../../model/ProjectBasicData';

@Injectable({
  providedIn: 'root'
})
export class ProjectBasicShareService {
  private currentProject!: ProjectBasicData;

  setCurrentProject(value: ProjectBasicData): void {
    this.currentProject = value;
  }

  getCurrentProject(): ProjectBasicData {
    return this.currentProject;
  }

}
