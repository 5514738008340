<div class="flex-card">
  <div class="inner-flx">
    <img [src]="img" class="img">
    <div>
      <p [ngClass]="{'customer-label': ifCustomerLabel() , 'painter-label' : !ifCustomerLabel() }">{{label}}</p>
      <div class="title">{{title}}</div>
    </div>

  </div>

  <p class="description">{{description}}</p>
</div>
