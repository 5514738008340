import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'phoneNumber'})

export class PhoneNumberPipe implements PipeTransform{

  transform(value: string): string {
      return value.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ').trim();
  }

}
