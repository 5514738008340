import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Wall} from '../../../model/Wall';
import {GreaterThanZeroValidator} from '../../../_helpers/greater-than-zero-validator';
import {AtLeastOneFieldRequiredValidator} from '../../../_helpers/one-field-required.valdiator';


@Component({
  selector: 'app-wall-card',
  templateUrl: './wall-card.component.html',
  styleUrls: ['./wall-card.component.scss']
})
export class WallCardComponent implements OnInit {
  @Input() editMode!: boolean;
  @Input() duplicateButtonVisible!: boolean;
  @Input() wallName!: any;
  @Input() wall!: Wall;
  @Input() disableForm!: boolean;
  @Input() exteriorCreator!: boolean;


  @Output() wallCreated = new EventEmitter();
  @Output() wallEdited = new EventEmitter();
  @Output() deleteWall = new EventEmitter();
  @Output() copyWall = new EventEmitter();

  wallFormGroup!: any;

  constructor(private formBuilder: FormBuilder) {
   }

  delete(): void {
    this.deleteWall.emit();
  }

  copy(): void {
    this.copyWall.emit();
  }

  saveWall(): void {
  console.log(this.wallFormGroup)
    this.wallCreated.emit(this.wallFormGroup.value);
    this.resetForm();
  }

  resetForm(): void {
    this.wallFormGroup.get('previousWallPaper')?.setValue(false);
    this.wallFormGroup.get('newWallpaper')?.setValue(false);
    this.wallFormGroup.get('previousDecorative')?.setValue(false);
    this.wallFormGroup.get('newDecorative')?.setValue(false);
    this.wallFormGroup.get('previousFaux')?.setValue(false);
    this.wallFormGroup.get('newFaux')?.setValue(false);
    this.wallFormGroup.get('wallWidth')?.setValue('');
    this.wallFormGroup.get('wallHeight')?.setValue('');
    this.wallFormGroup.get('previousColor')?.setValue('');
    this.wallFormGroup.get('newColor')?.setValue('');
  }


  ngOnInit(): void {
    console.log(this.exteriorCreator);
    console.log(!this.exteriorCreator);
    if (!this.exteriorCreator) {
      console.log('here');
      this.wallFormGroup = this.formBuilder.group({
        wallWidth: ['', [Validators.required, GreaterThanZeroValidator]],
        wallHeight: ['', [Validators.required, GreaterThanZeroValidator]],
        previousWallpaper: [false],
        newWallpaper: [false],
        previousColor: [''],
        newColor: [''],
        previousDecorative: [false],
        newDecorative: [false],
        previousFaux: [false],
        newFaux: [false],
      }, {
        validators: [
          AtLeastOneFieldRequiredValidator(['previousWallpaper', 'previousColor', 'previousDecorative', 'previousFaux']),
          AtLeastOneFieldRequiredValidator(['newWallpaper', 'newColor', 'newDecorative', 'newFaux'])
        ]
      });
    } else {
      console.log('bro');
      this.wallFormGroup = this.formBuilder.group({
        wallWidth: ['', [Validators.required, GreaterThanZeroValidator]],
        wallHeight: ['', [Validators.required, GreaterThanZeroValidator]],
        previousColor: [''],
        newColor: [''],
      }, {
        validators: [
          AtLeastOneFieldRequiredValidator(['previousColor']),
          AtLeastOneFieldRequiredValidator(['newColor'])
        ]
      });
    }

    // if (this.wall != null) {
    //   this.wallFormGroup.setValue(this.wall);
    //   if (this.disableForm) {
    //     this.wallFormGroup.disable();
    //   } else {
    //     this.wallFormGroup.valueChanges.subscribe(() => {
    //       this.wallEdited.emit(this.wallFormGroup.value);
    //     });
    //   }
    // }
  }
}
