import {Component, OnInit, EventEmitter,Output } from '@angular/core';
import {AdvancedCreatorComponent} from '../../../../../components/MAIN_COMPONENTS/AdvancedCreatorComponent';
import {EAdvancedCreatorInputType} from '../../../../../model/enums/EAdvancedCreatorInputType';
import {Wall} from '../../../../../model/Wall';
import {Room} from '../../../../../model/Room';

@Component({
  selector: 'app-exterior-creator',
  templateUrl: './exterior-creator.component.html',
  styleUrls: ['./exterior-creator.component.scss']
})
export class ExteriorCreatorComponent extends AdvancedCreatorComponent implements OnInit {
  @Output('saveRooms') saveRooms = new EventEmitter();
  room: any

  currentWallBeingCreated: any = {};
    pagesForRoom: any[] = [];
      rooms: Room[] = [];

  exteriorCreatorVisible = false;
  currentRoomBeingCreated: Wall;
  walls: Wall[] = [];
  indexEdited!: any;

  constructor() {
    super();
    this.createPages(false);
    this.currentPage = this.pages[this.currentPageIndex];
  }

  ngOnInit(): void {
  }
   saveRoomsAndGoNextPage(): void {
    this.saveRooms.emit(this.rooms);
  }
  openExteriorCreator(): void {
    this.resetAllValues();
    this.exteriorCreatorVisible = true;
  }

  closeExteriorCreator(): void {
    this.exteriorCreatorVisible = false;
  }

  resetAllValues(): void {
    this.exteriorCreatorVisible = false;
  }


  select(section: any, item: any): void {
  console.log(section,item,this.room)
    this.backButtonVisible = true;
    this.nextButtonDisabled = false;
    if (section.sectionKey !== this.tagsPrefix) {
      this.unselectAllItemsInSectionAndChangeItemState(item);
      this.currentWallBeingCreated[section.sectionKey] = item.value;
    } else {
      if (this.currentWallBeingCreated[this.tagsPrefix] == null) {
        this.currentWallBeingCreated[this.tagsPrefix] = {};
      }
      if (section.multipleChoice) {
        item.selected = !item.selected;
        if (item.selected) {
          this.currentWallBeingCreated[item.mapLabel] = item.value;
        } else {
          delete this.currentWallBeingCreated[item.mapLabel];
        }
        this.currentPage.minimumChecked = false;
        for (const key of Object.keys(this.currentWallBeingCreated)) {
          for (const sectionItem of section.items) {
            if (sectionItem.mapLabel === key) {
              this.currentPage.minimumChecked = true; // check if at least one object contains at least one key as section mapLabel
              this.nextButtonDisabled = false;
            }
          }
        }
      } else {
        this.unselectAllItemsInSectionAndChangeItemState(item);
        this.currentWallBeingCreated[section.sectionPrefix] = item.value;
      }
    }
    if (!section.multipleChoice) {
      this.nextPage();
    }
  }

  sslect(section: any, item: any){
    if (!section.multipleChoice) {
      this.nextPage();
    }
  }

  unselectAllItemsInSectionAndChangeItemState(item: any): void {
    this.currentPage.items.forEach((sectionItem: any) => sectionItem.selected = false);
    item.selected = !item.selected;
  }

  addWall(event: Wall): void {
    this.walls.push(event);
    console.log(this.walls)
    localStorage.setItem('walls', JSON.stringify(this.walls))
  }

  deleteWall(index: number): void {
    this.walls.splice(index, 1);
  }

  wallEdited(wall: Wall, index: number): void {
    this.walls[index] = wall;
  }
    addRoom(): void {
   
    this.currentWallBeingCreated.walls = this.walls;
  //  this.calculateAreas();

    if (this.indexEdited == null) {
      this.pagesForRoom[this.walls.length] = this.pages;
      this.walls.push(Object.assign({}, this.currentWallBeingCreated));
    } else {
      this.walls[this.indexEdited] = this.currentWallBeingCreated;
      this.pagesForRoom[this.indexEdited] = this.pages;
    }
    this.roomCreatorVisible = false;
  }
}
