<!-- <div class="route-title" *ngIf="tokenStorage.isUserPainter()"><span>My Profile</span><app-notification-bell></app-notification-bell></div> -->
<div class="route-title">
  <div class="d-flex">
    <a *ngIf="profile?.role == 'customer'" class="bck_btn" routerLink="/home/my-project-card">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>
    <a *ngIf="profile?.role == 'painter'" class="bck_btn" routerLink="/home/marketplace">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>
    <span>Painter Profile</span>
  </div>
  <app-notification-bell></app-notification-bell>
</div>

<div class="profile-wrapper">
  <div class="contact-wrapper" *ngIf="!edit">
    <div class="contact-header">
      <div class="avatar">
        <img [src]="previewImage2" onerror="src='./assets/default.png'" />
      </div>
      <!-- <button (click)="editp()">Edit Profile</button> -->
      <div class="info">
        <div class="full-name">{{ profile?.full_name }}</div>
        <div class="address">
          <img src="assets/icons/contact/localization.png" alt="localization" />
          <div class="address-text">
            {{ profile?.city }} , {{ profile?.state }},{{ profile?.zip_code }}
          </div>
        </div>
        <app-painter-rating class="rating" [avgRating]="profile?.avgRating" [displayRatingText]="false">
        </app-painter-rating>
        <div class="edit-prfle" *ngIf="!id">
          <a (click)="editp()">Edit Profile</a>
        </div>
      </div>
    </div>
    <div class="credits_earn_warp">
      <div class="credits_wrap">
        <span><svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"
            xml:space="preserve">
            <g>
              <g>
                <path d="M361.739,278.261c-27.664,0-50.087,22.423-50.087,50.087s22.423,50.087,50.087,50.087H512V278.261H361.739z
              M361.739,345.043c-9.22,0-16.696-7.475-16.696-16.696s7.475-16.696,16.696-16.696s16.696,7.475,16.696,16.696
             S370.96,345.043,361.739,345.043z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M361.739,244.87h83.478v-50.087c0-27.619-22.468-50.087-50.087-50.087H16.696C7.479,144.696,0,152.174,0,161.391v333.913
             C0,504.521,7.479,512,16.696,512H395.13c27.619,0,50.087-22.468,50.087-50.087v-50.087h-83.478
             c-46.032,0-83.478-37.446-83.478-83.478C278.261,282.316,315.707,244.87,361.739,244.87z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M461.913,144.696h-0.158c10.529,13.973,16.854,31.282,16.854,50.087v50.087H512v-50.087
             C512,167.164,489.532,144.696,461.913,144.696z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M478.609,411.826v50.087c0,18.805-6.323,36.114-16.854,50.087h0.158C489.532,512,512,489.532,512,461.913v-50.087H478.609
             z" />
              </g>
            </g>
            <g>
              <g>
                <path
                  d="M273.369,4.892c-6.521-6.521-17.087-6.521-23.609,0l-14.674,14.674l91.74,91.738h52.956L273.369,4.892z" />
              </g>
            </g>
            <g>
              <g>
                <path d="M173.195,4.892c-6.521-6.522-17.086-6.522-23.608,0L43.174,111.304h236.435L173.195,4.892z" />
              </g>
            </g>
          </svg>
        </span>
        <div>
          <h6>
            {{ profile?.credit }}
          </h6>
          <p class="light_txt">Available Credits</p>
        </div>
      </div>
      <a *ngIf="!id" (click)="modalFlag1 = true"> Add Credits </a>
    </div>
    <div class="main-info contact_dtl_wrap" *ngIf="!id">
      <div class="bsns_dtl_wrp" *ngIf="profile?.business_name">
        <div class="title">Business details</div>
        <p>{{ profile?.business_name }}</p>
      </div>
      <div class="bsns_dtl_wrp main-info contact_dtl_wrap" *ngIf="!id">
        <div class="title">About me</div>
        <p>{{ profile?.about_me }}</p>
      </div>
      <div class="title">Contact Details</div>
      <div>
        <div class="contact-detail">
          <img src="assets/icons/contact/phone.png" alt="phone" />
          <div class="contact-data">+{{ profile?.phone_number }}</div>
        </div>
        <div class="contact-detail">
          <img src="assets/icons/contact/email.png" alt="phone" />
          <div class="contact-data">{{ profile?.email }}</div>
        </div>
        <div class="contact-detail">
          <img src="assets/icons/contact/localization.png" alt="phone" />
          <div class="contact-data">
            {{ profile?.city }} {{ profile?.zip_code }} , {{ profile?.state }}
          </div>
        </div>
      </div>
      <!-- <div class="contact-not-available">Detailed contact is not available for a now.
      </div> -->

      <!-- <ng-content select="[additional-info]"></ng-content> -->
    </div>

    <div class="brushing-since">
      Brushing since {{ profile?.created_at | date }}
    </div>

    <div class="badges contact_dtl_wrap" *ngIf="show">
      <div class="title">Badges</div>
      <div class="badges-content">
        <div class="badge" *ngIf="currentdate > newdate">
          <div class="tooltip">
            <img class="badge-img" src="./assets/icons/painter-badges/badge1.png" />
            <span class="tooltiptext">Painter is brushing over 1 year.</span>
          </div>
        </div>

        <div class="badge" *ngIf="reviewlist?.length > 100">
          <div class="tooltip">
            <img class="badge-img" src="./assets/icons/painter-badges/badge2.png" />
            <span class="tooltiptext">Painter has more than 100 comments.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="badges contact_dtl_wrap" *ngIf="!show">
      <div class="title">Badges</div>
      <div class="painter-has-no-badges">
        Painter has not received any badge yet
      </div>
    </div>
  </div>

  <div class="comments-wrapper" *ngIf="!edit">
    <!-- <div class="post-comment">
      <app-comment-card></app-comment-card>
    </div> -->

    <div class="comments">
      <div class="comments-header">
        <div class="title" #comments>
          Comments<span class="total-comments">
            ({{ totalRecords }})
          </span>
        </div>

        <div class="dropdown drop_menu">
          <span class="drop_sort" (click)="showfilterrevire()">Sort by: {{ filteroption | titlecase }}
            <span class="drp_arrw"><i class="fa fa-chevron-down"></i></span></span>

          <div class="dropdown-content" *ngIf="shownewreview">
            <ul>
              <li (click)="getreviewlistfilter('newest')">New</li>
              <li (click)="getreviewlistfilter('oldest')">Oldest</li>
            </ul>
          </div>
          <!-- <app-select-input class="select-input">
                    </app-select-input> -->
        </div>
        <!-- <app-comment-card *ngFor="let comment of pageableResponse?.content" [comment]="comment" [editable]="false"></app-comment-card> -->

      </div>
      <div class="comment mt-2" *ngFor="let item of reviewlist">
        <div class="comment-header">
          <div class="avatar-full-name">
            <div class="avatar">
              <img [src]="
                    item?.customer_name?.profile_image &&  item?.customer_name?.profile_image != 'null'
                      ?   item?.customer_name?.profile_image
                      : 'assets/default.png'
                  " />
            </div>
            <div class="comment-info">
              <!-- <div class="full-name">{{ fullName}}</div> -->
              <div class="full-name">
                {{ item?.customer_name?.full_name }}
              </div>
              <!-- <div *ngIf="comment" class="posted-date">{{comment.howLongAgo}}</div> -->
              <div *ngIf="item.days_remaining == 0" class="posted-date">
                Today
              </div>

              <div *ngIf="item.days_remaining != 0" class="posted-date">
                {{ item.days_remaining }} days ago
              </div>
            </div>
          </div>
          <!-- <app-painter-rating *ngIf="!editable" [avgRating]="comment.rating" [displayRatingText]="false"></app-painter-rating> -->
          <app-painter-rating [avgRating]="item.customer_rating" [displayRatingText]="false"></app-painter-rating>
          <div class="rating-wrapper" *ngIf="avgRating">
            <div class="stars">
              <!-- <div class="avg-rating">{{avgRatingString}}<span class="total-comments" *ngIf="totalCommentsForPainter">({{totalCommentsForPainter}})</span></div> -->
            </div>
          </div>

          <!-- <app-painter-rating *ngIf="editable" (selectedRateEmitter)="setSelectedRate($event)" #editablePainterRating></app-painter-rating> -->
        </div>
        <!-- <div class="content" *ngIf="!editable"><pre>{{comment.content}}</pre></div> -->
        <div class="content">
          <pre>{{ item.customer_comment }}</pre>
        </div>
      </div>
      <!-- <mat-paginator [length]="totalRecords" [pageSizeOptions]="[5,10,25,100]" aria-label="Select page"
                        [pageSize]="5" (page)="paginationOnChange($event)">
                    </mat-paginator> -->
      <div class="pagination">
        <button (click)="goToPreviousPage()" [disabled]="offset === 0">Previous</button>

        <ng-container *ngFor="let pageNumber of getPageNumbers()">
          <button (click)="goToPage(pageNumber)" [disabled]="pageNumber === currentPage"
            [class.active]="pageNumber === currentPage">{{ pageNumber }}</button>
        </ng-container>

        <button (click)="goToNextPage()" [disabled]="offset + limit >= totalRecords">Next</button>
      </div>

    </div>
  </div>
  <div class="my-profile-route-content" *ngIf="edit">
    <form class="form" [formGroup]="profileForm" (ngSubmit)="changeprofile()">
      <section class="setting-section">
        <div class="setting-title">Profile images</div>

        <div class="d-flex align-items-center gap-2">
          <div class="setting-section-content profile-image-section">
            <!-- <app-profile-image-chooser></app-profile-image-chooser>-->

            <!-- <input class="input_class" (change)="fileUpload2($event)" class="text_inputs" type="file"> -->
            <div class="user_img_container">
              <!-- <img [src]="  previewImage2 " style="
      height: 61px;
  "> -->

              <img [src]=" previewImage2  ?  previewImage2 : './assets/default.png'" alt="">
            </div>
          </div>
          <div class="uplod-img">
            <div class="container">
              <p>Drag n Drop Image or <b>click here </b> to change</p>

              <input class="input_class" (change)="fileUpload2($event)" class="text_inputs" type="file" />
            </div>
          </div>
        </div>
      </section>

      <section class="setting-section">
        <div class="setting-title">Account Information</div>
        <div class="setting-section-content basic-data respFlexColm">
          <div class="basic-data-info"><span>Type</span> {{profile?.role | titlecase}}</div>
          <div class="basic-data-info">
            <span>Client ID</span> {{ profile?.id }}
          </div>
          <!-- <div class="basic-data-info"><span>Registration date</span> {{myData.signUpDate | dateOnly}}</div> -->
          <div class="basic-data-info">
            <span>Registration date</span> {{ profile?.sign_up_date | date }}
          </div>
        </div>
      </section>

      <section class="setting-section">
        <div class="setting-title">Preferences</div>
        <div class="d-flex align-items-center gap-2 prefrencChecks">
          <div class="d-flex align-items-center checkbox_div">
            <input type="checkbox" [(ngModel)]="stay_logged_in" (click)="click($event)"
              [ngModelOptions]="{ standalone: true }" />
            &nbsp;&nbsp;<label>Stay signed in until logout</label>&nbsp;&nbsp;&nbsp;&nbsp;
          </div>

          <div class="d-flex align-items-center checkbox_div" *ngIf="profile.role == 'customer'">
            <!-- customer   -->

            <input type="checkbox" (click)="clicka($event)" [(ngModel)]="send_email"
              [ngModelOptions]="{ standalone: true }" />&nbsp;&nbsp;<label>Send me an e-mail when there <br />
              are new bids for my project</label>
          </div>

          <div class="d-flex align-items-center checkbox_div" *ngIf="profile.role == 'painter'">
            <!-- painter -->

            <input type="checkbox" (click)="clicka($event)" [(ngModel)]="send_email"
              [ngModelOptions]="{ standalone: true }" />&nbsp;&nbsp;<label>Send me an e-mail when there <br />
              is a new project <p *ngIf="savedstate"> in {{savedstate}} </p>
            </label>
          </div>
        </div>
      </section>

      <section class="setting-section">

        <div class="">
          <div class="setting-title">About me</div>
          <div class="setting-section-content inputs">
            <div class="input-wrapper input-wrapper-without-image textarea_wrp">
              <textarea formControlName="about_me" rows="5" placeholder="About me"> </textarea>

            </div>
          </div>
        </div>
        <div class="setting-title">Phone</div>
        <div class="setting-section-content inputs">
          <div class="input-wrapper input-wrapper-without-image">
            <input formControlName="phone" maxLength="15"
              oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
              placeholder="(123)-456-7890" />
            <div class="error-label" *ngIf="
                !profileForm.controls['phone'].valid &&
                profileForm.controls['phone'].touched
              ">
              <p class="error-label" *ngIf="profileForm.controls['phone'].hasError('required')">
                <a class="error-wrp">
                  <img src="assets/icons/errors/error-solid.svg" class="error-icon" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="setting-section">
        <form [formGroup]="passwordform">
          <div class="setting-title">Password</div>
          <div class="setting-section-content inputs passwords">
            <div class="input-wrapper input-wrapper-without-image">
              <div class="input-with-hint">
                <input placeholder="Current Password" [type]="Cpass" formControlName="currPswd" type="password" />
                <span *ngIf="Cpass == 'text'" class="hint" (click)="onEyeClick('Cpass', 'password')">
                  <a class="hint-text">Show</a>
                </span>
                <span *ngIf="Cpass == 'password'" class="hint" (click)="onEyeClick('Cpass', 'text')">
                  <a class="hint-text">Show</a>
                </span>
              </div>
              <span *ngIf="
                  (passwordform.get('currPswd')?.touched ||
                    passwordform.get('currPswd')?.dirty) &&
                  passwordform.get('currPswd')?.invalid &&
                  passwordform.get('currPswd')?.errors?.required
                " class="err_msg">
                *Please enter password
              </span>
              <span *ngIf="
                  (passwordform.get('currPswd')?.touched ||
                    passwordform.get('currPswd')?.dirty) &&
                  passwordform.get('currPswd')?.invalid &&
                  passwordform.get('currPswd')?.errors?.pattern
                " class="err_msg">
                *Please enter valid password
              </span>
            </div>
            <div class="input-wrapper input-wrapper-without-image">
              <div class="input-with-hint">
                <input [type]="pass" formControlName="newPswd" class="email" placeholder="Enter New Password" />
                <span class="hint" *ngIf="pass == 'text'" (click)="onEyeClick('pass', 'password')">
                  <a class="hint-text">Show</a>
                </span>
                <span class="hint" *ngIf="pass == 'password'" (click)="onEyeClick('pass', 'text')">
                  <a class="hint-text">Show</a>
                </span>
              </div>
              <!-- <span class="hint">
              <a class="hint-text">Hide</a>
            </span> -->
              <span *ngIf="
                  (passwordform.get('newPswd')?.touched ||
                    passwordform.get('newPswd')?.dirty) &&
                  passwordform.get('newPswd')?.invalid &&
                  passwordform.get('newPswd')?.errors?.required
                " class="err_msg">
                *Please enter new password
              </span>
              <span *ngIf="
                  (passwordform.get('newPswd')?.touched ||
                    passwordform.get('newPswd')?.errors?.required ||
                    passwordform.get('newPswd')?.dirty) &&
                  passwordform.get('newPswd')?.invalid &&
                  passwordform.get('newPswd')?.errors?.pattern
                " class="err_msg">
                *Please enter valid password
              </span>
              <span *ngIf="
                  passwordform.get('newPswd')?.touched &&
                  passwordform.get('currPswd')?.touched &&
                  passwordform.get('newPswd')?.value ==
                    passwordform.get('currPswd')?.value &&
                  passwordform.get('currPswd')?.valid
                " class="err_msg">
                *New password must not match with old password
              </span>
              <!-- <small id="emailHelp" class="form-text text-pink">
              Password must contain at least 8 characters, including upper/lowercase and numbers
            </small> -->
            </div>
            <div class="input-wrapper input-wrapper-without-image">
              <div class="input-with-hint">
                <input [type]="confirmPass" formControlName="conPswd" placeholder="Repeat New Password"
                  type="password" />
                <span class="hint" *ngIf="confirmPass == 'text'" (click)="onEyeClick('confirmPass', 'password')">
                  <a class="hint-text">Show</a>
                </span>
                <span class="hint" *ngIf="confirmPass == 'password'" (click)="onEyeClick('confirmPass', 'text')">
                  <a class="hint-text">Show</a>
                </span>
              </div>
              <span *ngIf="
                  (passwordform.get('conPswd')?.touched ||
                    passwordform.get('conPswd')?.dirty) &&
                  passwordform.get('conPswd')?.invalid &&
                  passwordform.get('conPswd')?.errors?.required
                " class="err_msg">
                *Please enter confirm password
              </span>
              <span *ngIf="
                  (passwordform.get('conPswd')?.touched ||
                    passwordform.get('conPswd')?.dirty) &&
                  passwordform.hasError('pwdNotSame') &&
                  !passwordform.get('conPswd')?.errors?.required
                " class="form-text text-pink">
                *Password does not match
              </span>
            </div>
            <div class="error-wrp" *ngIf="error">
              {{ error }}
            </div>

            <button class="custom-button reset-password-button" (click)="submitForm()" type="submit"
              [disabled]="!passwordform.valid">
              Reset
            </button>
          </div>
        </form>
      </section>

      <section class="setting-section">
        <div class="setting-title">Address</div>
        <div class="setting-section-content inputs addresses">
          <div class="input-wrapper input-wrapper-without-image Addrs">
            <input formControlName="address" placeholder="212 Rishi Nagar" />
            <div class="error-label" *ngIf="
                !profileForm.controls['address'].valid &&
                profileForm.controls['address'].touched
              ">
              <p class="error-label" *ngIf="profileForm.controls['address'].hasError('required')">
                Please enter address
              </p>
            </div>
          </div>
          <!--<div class="input-wrapper input-wrapper-without-image">
            <input formControlName="city" placeholder="Haibowal Kalan" />
            <div
              class="error-label"
              *ngIf="
                !profileForm.controls['city'].valid &&
                profileForm.controls['city'].touched
              "
            >
              <p
                class="error-label"
                *ngIf="profileForm.controls['city'].hasError('required')"
              >
                Please enter city
              </p>
            </div>
          </div>-->

          <div class="input-wrapper input-wrapper-without-image zipAddrs">
            <input formControlName="zipcode" placeholder="33602" maxlength="6" minlength="3" (keyup)="checkzip($event)"
              oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
            <div class="error-label" *ngIf="errorpin">
              <p class="error-label" *ngIf="errorpin">
                {{errorpin}}
              </p>
            </div>
          </div>
          <div class="input-wrapper input-wrapper-without-image">
            <input formControlName="city" placeholder="City" readonly
              oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">

          </div>
          <div class="input-wrapper input-wrapper-without-image">
            <input formControlName="state" placeholder="State" readonly
              oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">

          </div>
        </div>
      </section>

      <div class="text-left btn-div">
        <button class="custom-button single-button">Save changes</button>
      </div>
    </form>
  </div>

  <app-modal class="painterSide" [displayModal]="modalFlag1" (cancelButtonClicked)="closeComplainModal()">
    <slot-content>
      <div class="add-credit-sec">
        <h4>Add Credit</h4>

        <div class="credit_img">
          <img src="./assets/coin.svg" alt="" />
        </div>

        <div class="form-group">
          <input type="text" placeholder="Add Amount" [(ngModel)]="price" />
        </div>

        <div class="text-center mt-3">
          <button class="btn custom-button" (click)="addcredit()">Add</button>
        </div>
      </div>
    </slot-content>
  </app-modal>
</div>