<section class="user_profile_sec">
    <div class="custom-contianer">
        <div class="user_dtl_sec">
            <h2>
                User Profile
            </h2>
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="user_persl_dtl">

                                <input class="input_class" (change)="fileUpload2($event)" class="text_inputs"
                                    type="file">
                                <div class="user_img_container pfle-edit">


                                    <img [src]="  previewImage2 ">

                                </div>
                                <div class="d-flex lr_content">
                                    <h6>
                                        My profile
                                    </h6>
                                    <div class="left_dtl">
                                        <p>
                                            {{profile?.sign_up_date | date}}
                                        </p>
                                        <p>Client ID: {{profile.id}}</p>
                                    </div>
                                </div>
                                <form action="" [formGroup]="profileForm" (ngSubmit)="changeprofile()"
                                    class="form margin-b10 user_profile_form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="input-wrapper bid_inpt">
                                                <div class="div_eml div_incs">
                                                    <input class="email" placeholder="John Jorden"
                                                        formControlName="email">
                                                    <div class="error-label"
                                                        *ngIf="!profileForm.controls['email'].valid && (profileForm.controls['email'].touched)">
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['email'].hasError('required')">
                                                            Please enter email
                                                        </p>
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['email'].hasError('pattern')">
                                                            Please enter valid email
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="input-wrapper bid_inpt">
                                                <div class="div_eml div_incs">
                                                    <input class="email" placeholder="9876543210"
                                                        formControlName="phone" maxLength="15"
                                                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <div class="error-label"
                                                        *ngIf="!profileForm.controls['phone'].valid && (profileForm.controls['phone'].touched)">
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['phone'].hasError('required')">
                                                            Please enter phone number
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="input-wrapper bid_inpt">
                                                <div class="div_eml div_incs">
                                                    <input class="email" placeholder="johnJorden123@gmail.com"
                                                        formControlName="name">
                                                    <div class="error-label"
                                                        *ngIf="!profileForm.controls['name'].valid && (profileForm.controls['name'].touched)">
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['name'].hasError('required')">
                                                            Please enter name
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="button_theme">Save</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>