import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PainterBidComponent} from '../../MAIN_COMPONENTS/PainterBidComponent';
import {FormBuilder} from '@angular/forms';
import {BidsService} from '../../../services/bids.service';
import {TokenStorageService} from '../../../services/token-storage.service';
import {Bid} from '../../../model/Bid';
import {MessageService} from '../../../services/message.service';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
  providers: [CurrencyPipe]
})
export class CurrencyInputComponent extends PainterBidComponent implements OnInit, OnChanges {
  @Input() bid!: Bid;
  @Input() readOnly!: boolean;
  @Input() buttonText!: string;
  @Output() buttonClicked = new EventEmitter();
  modalVisible = false;
  valueChanged = false;

  constructor(private currencyPipe: CurrencyPipe, protected formBuilder: FormBuilder, protected bidsService: BidsService, protected tokenStorage: TokenStorageService, protected messageService: MessageService) {
    super(formBuilder, bidsService, tokenStorage, messageService);
  }

  ngOnInit(): void {
    super.project = this.bid.project;
    super.myBid = this.bid;
    super.ngOnInit();
  }

  emitValue(): void {
    this.buttonClicked.emit(this.newOfferValue.value);
    this.modalVisible = false;
    this.valueChanged = false;
    this.newOfferForm.controls.newPrice.setValue(null);
    this.newOfferForm.controls.newPrice.setErrors(null);
  }

  toggleModalVisibility(): void {
    this.modalVisible = !this.modalVisible;
  }

  onBidPriceChanged(): void {
  }

  onBidPlaced(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.project = this.bid.project;
    super.myBid = this.bid;
    super.ngOnInit();
    if (this.readOnly) {
      this.newOfferValue.disable();
    }
  }

  onBidDeleted(): void {
  }
  preFormatValueOnBlur(event: any): void {
    event.target.value = this.currencyPipe.transform(event.target.value);
  }
  consumeWrongCharacters(event: any): void {
    const currentVal = event.target.value;
    if (!(currentVal[currentVal.length - 1] as string)?.match('^[0-9]+$')) {
      event.target.value = currentVal.slice(0, -1);
    }
    this.valueChanged = event.target.value?.length > 0;
  }

  undoCurrencyPipe(event: any): void {
    if (event.target.value != null && event.target.value !== '') {
      event.target.value = parseInt(event.target.value.replace('$', '').replace(',', ''), 10);
    }
  }
}
