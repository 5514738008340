<div>
  <div class="option">
    Period of time
    <select (change)="emit($event)">
      <option value="14">Last 14 days</option>
      <option value="30">Last 30 days</option>
      <option value="90">Last 90 days</option>
      <option value="365">Last year</option>
      <option value="1095">Last 3 years</option>
    </select>
  </div>
<div echarts [options]="chartOption" class="demo-chart"></div>
</div>
