<div class="smooth-scroll">
    <app-header [userTheme]="true"></app-header>

    <!-- <app-header></app-header> -->

    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="help-section">
                <div class="sec-a" style="background-image: url({{data.img1}});">
                    <div class="cntnt-sec">
                        <form [formGroup]="searchForm">
                            <h1 class="text-center">{{data.heading1}}</h1>
                            <div class="srch-bar">
                                <input type="text" placeholder="Search.." formControlName="search">
                                <button class="srch-btn"><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div>
                        </form>
                    </div>
                </div>

                <!-- Help old -->
                <!-- <div class="sec-b">
                    <div class="hlp-container">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img2" alt="">
                                            <h4>{{data.heading2}}</h4>
                                            <p class="light_txt">{{data.description1}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img3" alt="">
                                            <h4>{{data.heading3}}</h4>
                                            <p class="light_txt">{{data.description2}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img4" alt="">
                                            <h4>{{data.heading4}}</h4>
                                            <p class="light_txt">{{data.description3}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img5" alt="">
                                            <h4>{{data.heading5}}</h4>
                                            <p class="light_txt">{{data.description4}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img6" alt="">
                                            <h4>{{data.heading6}}</h4>
                                            <p class="light_txt">{{data.description5}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img7" alt="">
                                            <h4>{{data.heading7}}</h4>
                                            <p class="light_txt">{{data.description6}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- Help New -->
                <div class="sec-b">
                    <div class="container">
                        <h1 class="how-does-it-work">Help</h1>
                        <div *ngFor="let item of faqData; let i = index" class="accordion"
                            [ngClass]="{ 'active': item?.active }">
                            <div class="accordion-header" (click)="toggleAccordion(i)">
                                {{ item.question }}
                                <i class="fa"
                                    [ngClass]="{'fa-chevron-up': item?.active, 'fa-chevron-down': !item?.active}"></i>
                            </div>
                            <div class="accordion-body">
                                {{ item.answer }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>

</div>