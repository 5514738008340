<nb-card class="nb-theme-brush">
  <nb-card-header>Fr by room type</nb-card-header>
  <nb-card-body>
    <form [formGroup]="frByRoomTypeForm" class="form margin-b10">
      <div class="input-wrapper">
        <label><span class="label-text">Bathroom factor</span></label>
        <input formControlName="bathroomFactor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Bedroom factor</span></label>
        <input formControlName="bedroomFactor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Dining room factor</span></label>
        <input formControlName="diningRoomFactor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Hallway factor</span></label>
        <input formControlName="hallwayFactor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">High celling room factor</span></label>
        <input formControlName="highCellingRoomFactor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Kitchen factor</span></label>
        <input formControlName="kitchenFactor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Other factor</span></label>
        <input formControlName="otherFactor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text"></span></label>
        <button class="custom-button" [disabled]="!frByRoomTypeForm.valid" (click)="showModal()">Save</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>

<app-modal [displayModal]="changePropertyModalVisible"
           [title]="'Updating fr by room type'"
           [subclaim]="'Do you want to change fr by room type values?'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="changeFrByRoomType()"
           (cancelButtonClicked)="hideModal()"></app-modal>

