import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-img-carousel',
  templateUrl: './img-carousel.component.html',
  styleUrls: ['./img-carousel.component.scss']
})
export class ImgCarouselComponent implements OnInit {

  constructor() {
    this.animNumber = -1;
  }

  @Input() images: any;
  @Input() selectedImage: any;
  @Output() carouselClose = new EventEmitter();

  animState = 'middle';
  animNumber: number;


  ngOnInit(): void {
    this.searchForIndex();
  }

  private searchForIndex(): void {
    this.animNumber = this.images.indexOf(this.selectedImage);
  }

  public close(): void {
    this.carouselClose.emit();
  }

  public previous(): void {
    this.animState = 'left';
    this.animNumber = this.animNumber > 0 ? this.animNumber - 1 : this.images.length - 1;
    this.blur();
  }

  public next(): void {
    this.animState = 'right';
    this.animNumber = this.animNumber < this.images.length - 1 ? this.animNumber + 1 : 0;
    this.blur();
  }
  //fixme: 2do
  public animDone(): void {
    // this.animImage = this.selectedImage;
    this.animState = 'middle';
  }

  private blur(): void {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement !== null) {
      activeElement.blur();
    }
  }

  public onEvent(event: Event): void {
    event.stopPropagation();
  }
}
