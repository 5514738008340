import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {NavigationExtras, Router} from '@angular/router';
    import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.component.html',
  styleUrls: ['./welcome-screen.component.scss']
})
export class WelcomeScreenComponent implements OnInit {
location: any
error: any
      isPainterRegistration: boolean = false

  userDataForm!: FormGroup;
   googlePlaceOptions: any = {
   // componentRestrictions: {  },
    componentRestrictions: { country: 'UA' },

    fields: [
      'address_components',
      'geometry',
      'name',
      'formatted_address',
      'adr_address',
    ],
    strictBounds: false,
    // types: ["establishment"],
    types: ['(regions)'],
  };

  options:any={
   types: ['(regions)'],
    componentRestrictions: { country: 'UA' },
    
        strictBounds: false,

    }
  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private router: Router,
              private toastr: ToastrService) { 
               this.userDataForm = this.formBuilder.group({
       full_address: ['', [Validators.required]],
       state: ['',[Validators.required]],
        city: ['', [Validators.required]],
        zip: ['', [Validators.required, Validators.minLength(4)]],
        latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      location: ['']
        
      });}

  ngOnInit(): void {

    if(localStorage.getItem('role')){
  let a =localStorage.getItem('role')
  if(a=='painter'){
  this.isPainterRegistration = true
  }
 
        
  }
  }

  submitRegisterForm(){
  console.log(this.userDataForm)
 let obj={
      full_name: localStorage.getItem('name'),
email: localStorage.getItem('email'),
role: localStorage.getItem('role'),
password: localStorage.getItem('password'),
phone_number: localStorage.getItem('phone'),
business_name : localStorage.getItem('businesss_name'),
full_address: this.userDataForm.value.full_address,
location : this.userDataForm.value.location,
latitude : this.userDataForm.value.latitude,
longitude : this.userDataForm.value.longitude,
state : this.userDataForm.value.state,

city:this.userDataForm.value.city,
//state:this.userDataForm.value.state,
zip_code:this.userDataForm.value.zip
 }
   this.authService.register(obj).subscribe((res : any) => {
    this.router.navigate(['/login'])

    localStorage.clear();
    this.toastr.success('Registerd successfully! Please check mail for vefication.', 'Success!');
     this.authService.showMessage({
        message: "Registerd successfully"
      })
  
     //  this.media_mag = res.data
    //   this.currentStep = this.serviceDetails.step
    //   this.patchServiceForm()
      }, err => {

          console.log(err.error.errors )
           this.toastr.error('error',err.error.errors.msg);
      this.authService.showMessage({
        message: err.error.errors ? err.error.errors.msg : 'Something went Wrong'
      })
       })
  }


   handleAddressChange(event: any) {
   console.log(this.location)
    console.log('address change', event, event.geometry.location.lat(), event.geometry.location.lng());
     this.userDataForm.controls['latitude'].patchValue(event.geometry.location.lat());
      this.userDataForm.controls['longitude'].patchValue(event.geometry.location.lng());
            this.userDataForm.controls['location'].patchValue(event.formatted_address);
                        this.userDataForm.controls['state'].patchValue(event.name);


   }

   clearLocation(){
   console.log('test')
   }
   checkzip(e: any){
   console.log(e, this.location)

   let obj={
          'pin_code' : this.userDataForm.value.zip
   }
           this.authService.checkzipcode(this.userDataForm.value.zip).subscribe((res : any) => {
           if(res){
           this.error =''
             this.userDataForm.controls['latitude'].patchValue(res.response[0].latitude);
      this.userDataForm.controls['longitude'].patchValue(res.response[0].longitude);
            this.userDataForm.controls['city'].patchValue(res.response[0].city);
                        this.userDataForm.controls['state'].patchValue(res.response[0].administrativeLevels?.level1long);
                        console.log(this.userDataForm)
           }
   },error => {
        console.log(error);
        this.error = 'Please enter valid zip code'
        })
   }
}
