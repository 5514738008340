import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhiteSpaceValidator } from '../../../../_helpers/white-space.validator';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-make-new-project-deatils',
  templateUrl: './make-new-project-deatils.component.html',
  styleUrls: ['./make-new-project-deatils.component.scss']
})
export class MakeNewProjectDeatilsComponent implements OnInit {
  type: any
  proppaint: any
  colorFinish: any = []
  project_type: any
  modalFlagnewopen: boolean = false
  newfile: any = []
  newtwst: any
  opennewmodal: boolean = false
  propertyselecnewt: any
  pro: boolean = false
  showsurfacenew: boolean = false
  opennewmodalsurface: any = false
  filesnewnew: any = []
  svalue: any
  new: any = []
  files: any = []
  inputvalue: any
  p_newname: any
  desc: any
  filesnewnewnew: any = []
  newfilenew: any = []
  p_name: any
  shoenxt: boolean = true
  shownexttow: boolean = true
  otherproperty: any = []
  othersotherproperty: any = [{ 'image': './assets/project-creator/boat.jpg', 'title': 'Boats' }, { 'image': './assets/project-creator/parking-lot.jpg', 'title': 'Parking lots' }, { 'image': './assets/project-creator/furniture.jpg', 'title': 'Furniture stain' }, { 'image': './assets/project-creator/wrapping-vehicles.jpg', 'title': 'Wrapping vehicles' }]
  openother: boolean = false
  provdeitem: any
  providibf_items: boolean = false
  propertyshow: boolean = false
  height: any
  showither: boolean = false
  showprojectdetails: boolean = false
  selected_rroms: any = []
  n: any = []
  stepperIndex: any = 0;
  //projectDetailsSections: any=[]
  roompainted: any = []
  filesn: any = []
  pType: boolean = false
  whendone: boolean = false
  all_applicables: boolean = false
  when_to_be_done: any
  property_typemew: any
  items_applicatbleother: any = []
  projectapplivcablesother: any = []
  color_finish: boolean = false
  showmaterial: boolean = true
  showsurface: boolean = false
  home_type: any
  puwallpaper: any
  surface_condition: any
  items_applicatble: any = []
  selected_ids_rooms: any = []
  selected_cond: any = []
  shoeothernew: boolean = false
  show: boolean = true
  surface_cond: boolean = false
  showwallpaper: boolean = false
  items_to_painted: any = []
  selected_ids: any = []
  height_ceiling: boolean = true
  painted: boolean = true
  rooms_painted: boolean = false
  showtitle: boolean = false
  showwhatpainted: boolean = false
  room: any
  material: any
  titlenew: any
  addedmat: any = []
  sufvalue: any
  projectapplivcables: any = []
  materials: any = []
  surfacevalue: any = [{ 'name': 'I am not sure ', 'value': 'not_sure' },]
  itemsother: any = [
    { id: 1, label: 'Power wash', selected: false, value: 'powerWash', mapLabel: 'powerWash' },
    { id: 2, label: 'Cleaning windows', selected: false, value: 'cleaningWindows', mapLabel: 'cleaningWindows' },
    { id: 3, label: 'Painting gutters', selected: false, value: 'cleaningFloors', mapLabel: 'cleaningFloors' },
    { id: 4, label: 'Disposing of the debris', selected: false, value: 'disposingDebris', mapLabel: 'disposingDebris' },
    { id: 4, label: 'Other', selected: false, value: 'other', mapLabel: 'other' },
  ]
  all_applicables_list: any = [
    { id: 0, label: 'Moving furniture', selected: false, value: 'moving_furniture', mapLabel: 'movingFurniture' },
    { id: 1, label: 'Cleaning windows', selected: false, value: 'cleaning_windows', mapLabel: 'cleaningWindows' },
    { id: 2, label: 'Cleaning the floors', selected: false, value: 'cleaning_floor', mapLabel: 'cleaningFloors' },
    { id: 3, label: 'Disposing of the debris', selected: false, value: 'disposing_debris', mapLabel: 'disposingDebris' },
    { id: 4, label: 'Removing/attaching light fixtures', selected: false, value: 'removing_fixtures', mapLabel: 'lightFixtures' },
  ]
  condition: any = [
    { id: 1, label: 'No repairs needed', selected: false, value: 'good', image: '/assets/project-creator/good.png' },
    { id: 2, label: 'Need some repairs', selected: false, value: 'fair', image: '/assets/project-creator/fair.png' },
    { id: 3, label: 'Need major repairs', selected: false, value: 'bad', image: '/assets/project-creator/sad.png' },
    { id: 4, label: 'Need help in assessing surface condition', selected: false, value: 'needHelpinMeasurment' },

  ]
  itemspainted: any = [
    { id: 1, label: 'Repainting - same color', selected: false, value: 'same_color', mapLabel: 'sameColor' },
    { id: 2, label: 'Repainting - different color', selected: false, value: 'color_change', mapLabel: 'colorChange' },
    { id: 3, label: 'Decorative painting - faux finish', selected: false, value: 'faux_finish', mapLabel: 'fauxFinish' },
    { id: 4, label: 'Decorative painting - texture coating', selected: false, value: 'texture_coating', mapLabel: 'textureCoating' },
  ]
  items: any = [
    { id: 0, label: 'Living Room', selected: false, value: 'livingroom', mapLabel: 'roomLiving', image: '/assets/project-creator/living-room.png' },
    { id: 1, label: 'Bathroom', selected: false, value: 'bathroom', mapLabel: 'roomBathroom', image: '/assets/project-creator/bathroom.png' },
    { id: 2, label: 'Bedroom', selected: false, value: 'bedroom', mapLabel: 'roomBedroom', image: '/assets/project-creator/bedroom.png' },
    { id: 3, label: 'Dining Room', selected: false, value: 'dining', mapLabel: 'roomDining', image: '/assets/project-creator/dining-room.png' },
    { id: 4, label: 'Kitchen', selected: false, value: 'kitchen', mapLabel: 'roomKitchen', image: '/assets/project-creator/kitchen.png' },
    { id: 5, label: 'Hallway', selected: false, value: 'hallway', mapLabel: 'roomHallway', image: '/assets/project-creator/hallway.png' },
    { id: 6, label: 'Others', selected: false, value: 'others', mapLabel: 'roomOthers', image: '/assets/project-creator/others.png' },
  ]
  itemswhatpainted: any = [
    { id: 1, label: 'Walls', selected: false, value: 'walls', mapLabel: 'paintingWalls', image: '/assets/project-creator/walls.png' },
    { id: 2, label: 'Trim', selected: false, value: "trim", mapLabel: 'paintingTrim', image: '/assets/project-creator/trims.png' },
    { id: 3, label: 'Ceiling', selected: false, value: 'ceiling', mapLabel: 'paintingCeiling', image: '/assets/project-creator/ceiling.png' },
    { id: 4, label: 'Doors', selected: false, value: "doors", mapLabel: 'paintingDoors', image: '/assets/project-creator/doors.png' },
    { id: 5, label: 'Window frames', selected: false, value: 'windowframes', mapLabel: 'paintingWindows', image: '/assets/project-creator/window-frames.png' }
  ]
  roomsno: any = [{ 'value': '1', 'name': '1' }, { 'value': '2', 'name': '2' }, { 'value': '3', 'name': '3' }, { 'value': '4', 'name': '4' }, { 'value': '5', 'name': '5' }, { 'value': '6', 'name': '6' }]
  show1: boolean = false
  projectDetailsForm: FormGroup
  property_type: any
  projectDetailsSections = {
    sectionTitle: 'Project detailed info',
    items: [
      {
        id: 0,
        title: 'Images',
        description: 'Upload your images here. Projects with attached images are more attractive for professionals.',
      },
      {
        id: 1,
        title: 'Project title', description: 'Please enter short title which will describe your job in few words. If you will leave this space empty, we will provide it for you.', hint: '0 /100 characters'
      },
      {
        id: 2,
        title: 'Description', description: 'Please provide us with short description of your project. It\n' +
          '          will help potential professionals with better understanding', hint: '0 /400 characters'
      },
      {
        id: 3,
        title: 'Timeline',
        description: 'Please set the start date and end date to provide us with the timeframe of your project.',
      },
    ]
  };
  constructor(private formBuilder: FormBuilder, private servicehttp: HttpserviceService, private router: Router,
  ) {
    this.projectDetailsForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(5), WhiteSpaceValidator]],
      title: ['', [Validators.required, Validators.minLength(5)]],
      images: this.formBuilder.array([]),
      startDate: ['', [Validators.required]],
      dueDate: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.type = localStorage.getItem('type')
  }

  closeComplainModalnew() {
    //  this.modalFlagnew= false
    this.openother = false
    this.modalFlagnewopen = false
    this.opennewmodal = false
    this.opennewmodalsurface = false
    this.shoeothernew = false
  }
  choseProject(type: any) {
    this.project_type = type
    this.show = false
    this.show1 = true
  }
  homeorbusiness(type: any) {
    this.home_type = type
    this.show1 = false
    this.propertyshow = true

    let param = 'exterior'
    this.servicehttp.getprojectstep(param).subscribe(response => {
      this.otherproperty = response.icon
    });
  }
  //  first(){
  //  this.show= true
  //  this.show1= false
  //  this.project_type = false
  //  this.propertyshow= false
  //  this.proppaint=''
  //  this.home_type=''
  // }

  first() {
    this.router.navigate(['/'], {
      skipLocationChange: true
    }).then(res => {
      this.router.navigate([`/home/make-new-project-details`])
    })
  }
  propertyselect(type: any) {
    //console.log(this.proppaint)
    this.proppaint = type
    console.log('type',type);
    
    console.log('@@@',this.proppaint)
    this.stepperIndex = 1;
  }

  propertyselectext(type: any, id: any) {
    //console.log(this.proppaint)
    this.proppaint = type
    console.log('selcet',this.proppaint);
    
    if (this.proppaint == 'home') {
      this.pro = true;
      this.propertyshow = false
      let param = id
      this.servicehttp.getprojectstepsecond(param).subscribe(response => {
        this.materials = response.response
      });

    } else {
      this.stepperIndex = 1;
      let param = id
      this.servicehttp.getprojectstepsecond(param).subscribe(response => {
        this.materials = response.response
      });

    }
    console.log('###',this.proppaint)

  }

  property(type: any) {
    //console.log(this.proppaint)
    this.propertyselecnewt = type
    console.log('type',this.propertyselecnewt)
    this.stepperIndex = 1;
  }


  changeCheckboxState(room: any) {
    console.log(room)
    this.room = room
    this.painted = false
    this.rooms_painted = true
  }
  newnwxt() {

  }
  changeCheckboxStatsurf(a: any) {
    this.sufvalue = a
    this.svalue = a
    //  this.showsurface = false
    if (a == 'other') {
      this.opennewmodalsurface = true
    } else {
      this.surface_cond = true

      //  this.surface_cond= true
      //  this.stepperIndex = 2
    }
    // 
  }
  changeCheckboxStatematerial(room: any) {
    console.log(room)
    this.material = room
    if (room == 'other') {
      this.opennewmodal = true

      this.showtitle = true
    }

    //	this.painted = false
    //	this.rooms_painted = true
  }
  addnewcxt() {

    console.log(this.material)
    this.showmaterial = false
    this.showsurface = true
  }
  seletcedis(i: any) {
    // console.log(i,this.selected_ids)
    let a = this.selected_ids.find((element: any) => element == i)
    //console.log(a)
    if (a == undefined) {
      return "image-wrapper; ";

    } else {
      return "selected image-wrapper; color:black";
    }
  }
  openmodalimage() {
    // console.log(a)
    this.modalFlagnewopen = true
    //   this.newobj= a
    //    this.addedrroms.splice(this.newobj, 1)
    //  console.log(this.addedrroms)
    //  const index = this.addedrroms.findIndex(x => x.LastName === "Skeet");

  }
  openmodalnewimage() {
    // console.log(a)
    this.openother = true
    //   this.newobj= a
    //    this.addedrroms.splice(this.newobj, 1)
    //  console.log(this.addedrroms)
    //  const index = this.addedrroms.findIndex(x => x.LastName === "Skeet");

  }
  savenew() {
    this.new = []
    this.files = []
    this.p_newname = ''
    this.filesn = []
    this.newfile = []
    this.p_name = ''
    console.log(this.p_newname)
    this.modalFlagnewopen = false
    this.openother = false

  }
  savenewnew() {
    this.addedmat.push({ 'decription': this.desc, 'title': this.titlenew })

    this.titlenew = ''
    this.desc = ''
    this.filesnewnew = []
    this.newfilenew = []
    //this.p_name=''
    console.log(this.addedmat)
    this.opennewmodal = false
    this.openother = false

  }
  savenewnewsurface() {
    this.svalue = this.inputvalue
    this.opennewmodalsurface = false
    //  this.stepperIndex = 2

    this.surface_cond = true
  }
  uploadFilesnew(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.newfile.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.newfile.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.newfile.length; i++) {
      formData.append("images", this.newfile[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.newfile)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.filesnewnew.push({ 'image': res.imgs[i] })
      }

      console.log("files", this.filesnewnew)
      this.otherproperty.push({ 'title': this.p_newname, 'image': this.filesnewnew[0].image })
      // this.newobj.images= this.filesnewnew
      this.newfile = []
      this.filesnewnew = []
      this.p_newname = ''
      const file = ''
      console.log(file)
      console.log(this.otherproperty)
      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }
  uploadFiles(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.new.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.new.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.new.length; i++) {
      formData.append("images", this.new[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.newfile)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.files.push({ 'image': res.imgs[i] })
      }

      console.log("files", this.files)
      this.othersotherproperty.push({ 'title': this.p_name, 'image': this.files[0].image })
      // this.newobj.images= this.filesnewnew
      console.log(this.othersotherproperty)
      this.files = []
      this.new = []
      // this.p_newname=''
      // this.p_newname=''
      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }

  removeSelectedFile(index: any) {
    // Delete the item from fileNames list
    this.filesn.splice(index, 1);
    // delete file from FileList
    //this.fileList.splice(index, 1);
  }
  goto() {
    this.showwallpaper = true;
    this.color_finish = false
  }
  seletcedispainted(i: any) {
    let a = this.items_to_painted.find((element: any) => element == i)
    if (a == undefined) {
      return "image-wrapper; ";

    } else {
      return "selected image-wrapper checlboxselected_div; color:black";
    }
  }
  seletctedproj(i: any) {
    //console.log(i,this.items_to_painted)
    let a = this.items_applicatble.find((element: any) => element == i)
    //console.log(a)

    if (a == undefined) {
      return "image-wrapper; ";

      return "./assets/project-creator/checkbox-checked.png ";
    } else {
      return "selected image-wrapper checlboxselected_div; color:black";

      return "./assets/project-creator/checkbox-unchecked.png";
    }
  }
  seletctedprojother(i: any) {
    //  console.log(i,this.items_to_painted)
    let a = this.items_applicatbleother.find((element: any) => element == i)
    if (a == undefined) {
      return "image-wrapper; ";

      return "./assets/project-creator/checkbox-checked.png ";

    } else {
      return "selected image-wrapper checlboxselected_div; color:black ";

      return "./assets/project-creator/checkbox-unchecked.png";
    }
  }

  propertyType(a: any) {
    this.property_typemew = a;
    this.pType = false
    this.whendone = true
  }
  changecondition(items: any, i: any) {
    this.selected_cond.push(i)
    this.surface_condition = items
    this.stepperIndex = 3
    this.condition.forEach((item: any) => {
      //   console.log(item)
      if (item.value === items) {
        //   console.log(item.value)
        item.selected = true;

        //    this.items.set(this.pages[sectionIndex].sectionKey, item.value);
      } else {
        item.selected = false;
      }

    });
    this.surface_cond = false
    this.providibf_items = true
  }
  changeconditionnew(items: any, i: any) {
    this.selected_cond.push(i)
    this.surface_condition = items
    // this.stepperIndex= 3

    this.condition.forEach((item: any) => {
      //   console.log(item)
      if (item.value === items) {
        //   console.log(item.value)
        item.selected = true;

        //    this.items.set(this.pages[sectionIndex].sectionKey, item.value);
      } else {
        item.selected = false;
      }

    });
    this.surface_cond = false
    this.providibf_items = true
  }
  uploadFile(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.n.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.n.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.n.length; i++) {
      formData.append("images", this.n[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.n)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.filesn.push({ 'name': res.imgs[i] })
      }

      console.log("files", this.filesn)

      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;
      this.n = []

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }

  characterCounterForTitle(event: any): void {
    this.projectDetailsSections.items[1].hint = event.target.value.length + ' /100 characters';
  }

  characterCounterForDescription(event: any): void {
    this.projectDetailsSections.items[2].hint = event.target.value.length + ' /400 characters';
  }

  setProjectDetailsDate(event: any): void {
    this.projectDetailsForm.get('startDate')?.setValue(event.startDate);
    this.projectDetailsForm.get('dueDate')?.setValue(event.endDate);
  }
  seletcedisroom(i: any) {
    //console.log(i,this.selected_cond)
    let a = this.selected_ids_rooms.find((element: any) => element == i)
    //console.log(a)
    if (a == undefined) {
      return "image-wrapper; ";

    } else {
      return "selected image-wrapper; color:black";
    }
  }

  whentobe(a: any) {
    this.when_to_be_done = a
    this.whendone = false;
    this.all_applicables = true
  }
  slectcond(i: any) {
    console.log(i, this.selected_ids_rooms)
    let a = this.selected_ids_rooms.find((element: any) => element == i)
    console.log(a)
    if (a == undefined) {
      return "image-wrapper; ";

    } else {
      return "selected image-wrapper; color:black";
    }
  }
  putWallpaper(a: any) {
    this.puwallpaper = a
    this.showwallpaper = false
    this.surface_cond = true
  }

  sendNewProjectRequest() {
    let obj = {
      'creater_type': this.type,
      'project_type': this.project_type,
      'home_type': this.home_type,
      'property_type': this.proppaint,
      'room_type': this.selected_rroms,
      'what_painted': this.roompainted,
      'surface_condition': this.surface_condition,
      'room_no': this.room,
      'wallpaper': this.puwallpaper,
      'height_of_ceiling': this.height,
      'finishing': this.colorFinish,
      'providing_items': this.provdeitem,
      'other_kinds_of_property': this.property_typemew,
      'jobtime': this.when_to_be_done,
      'project_applicable': this.projectapplivcables,
      'images': this.filesn,
      'project_title': this.projectDetailsForm.value.title,
      'description': this.projectDetailsForm.value.description,
      'startDate': this.projectDetailsForm.value.startDate,
      'endDate': this.projectDetailsForm.value.dueDate,
      'material': this.material,
      'other_thing_painter_to_do': this.projectapplivcablesother,
      'surface_area_to_be_painted': this.svalue,
      'matrial_desc': this.addedmat,
      'total_price': 0


    }
    this.servicehttp.postprojectnew(obj).subscribe(response => {
      this.showprojectdetails = true
    });
  }
  heightofceiling(e: any) {
    console.log(e)
    this.height = e
    this.height_ceiling = false
    this.color_finish = true
  }
  pItems(a: any) {
    this.provdeitem = a
    this.providibf_items = false
    this.pType = true
    this.whendone = true
    this.showither = true
  }
  next() {
    this.showwhatpainted = true
    this.painted = false
    this.shoenxt = false

    this.rooms_painted = false
  }
  changeCheckboxStateroom(items: any, id: any) {
    ///console.log(items,id)



    let b = this.selected_rroms.find((element: any) => element == items)
    console.log(b)
    if (b) {
      console.log('b')
      const index: number = this.selected_rroms.indexOf(b);
      this.selected_ids.splice(index, 1)

      this.selected_rroms.splice(index, 1)
    } else {
      this.selected_rroms.push(items)
      this.selected_ids.push(id)

    }


    console.log(this.selected_rroms, this.selected_ids)
  }
  selectProject(items: any, i: any) {
    //  this.stepperIndex= 4
    let b = this.projectapplivcables.find((element: any) => element == items)
    console.log(b)
    if (b) {
      console.log('b')
      const index: number = this.projectapplivcables.indexOf(b);
      this.items_applicatble.splice(index, 1)

      this.projectapplivcables.splice(index, 1)
    } else {
      this.projectapplivcables.push(items)
      this.items_applicatble.push(i)

    }


    //  console.log(this.selected_rroms, this.selected_ids)

    console.log(this.items_applicatble, this.projectapplivcables)






  }
  savenewother() {
    this.projectapplivcablesother.push(this.newtwst)
    this.shoeothernew = false
    this.newtwst = ''
  }
  selectProjectother(items: any, i: any) {

    if (items == 'other') {

      let b = this.projectapplivcablesother.find((element: any) => element == items)
      console.log(b)
      if (b) {
        console.log('b')
        const index: number = this.projectapplivcablesother.indexOf(b);
        this.items_applicatbleother.splice(index, 1)

        this.projectapplivcablesother.splice(index, 1)
        this.shoeothernew = false
      } else {
        this.projectapplivcablesother.push(items)
        this.items_applicatbleother.push(i)
        this.shoeothernew = true

      }
    } else {


      let b = this.projectapplivcablesother.find((element: any) => element == items)
      console.log(b)
      if (b) {
        console.log('b')
        const index: number = this.projectapplivcablesother.indexOf(b);
        this.items_applicatbleother.splice(index, 1)

        this.projectapplivcablesother.splice(index, 1)
      } else {
        this.projectapplivcablesother.push(items)
        this.items_applicatbleother.push(i)

      }
    }
    //  this.stepperIndex= 3

  }
  itemPainted(items: any, i: any) {



    let b = this.colorFinish.find((element: any) => element == items)
    console.log(b)
    if (b) {
      console.log('b')
      const index: number = this.colorFinish.indexOf(b);
      this.items_to_painted.splice(index, 1)

      this.colorFinish.splice(index, 1)
    } else {
      this.colorFinish.push(items)
      this.items_to_painted.push(i)

    }


    console.log(this.selected_rroms, this.selected_ids)
    this.itemspainted.forEach((item: any) => {
      //   console.log(item)
      if (item.value === items) {
        //   console.log(item.value)
        item.selected = true;

        //    this.items.set(this.pages[sectionIndex].sectionKey, item.value);
      } else {
        item.selected = false;
      }

    });
    console.log(this.itemspainted, this.items_to_painted)
  }
  second() {
    this.showwhatpainted = true
    this.painted = true
    this.rooms_painted = true

  }
  uploadFilesnewnew(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.newfilenew.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.newfilenew.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.newfilenew.length; i++) {
      formData.append("images", this.newfilenew[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.newfilenew)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.filesnewnewnew.push({ 'image': res.imgs[i] })
      }

      console.log("files", this.filesnewnew)
      // this.newobj.images= this.filesnewnew
      //    console.log(this.otherproperty)
      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;
      console.log(this.addedmat)

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }
  fourth() {
    this.all_applicables = true
    this.pType = true
    this.whendone = true
    this.providibf_items = true
  }
  third() {
    this.color_finish = true
    this.shownexttow = false
    this.height_ceiling = true
    this.showwallpaper = true
    this.surface_cond = true
    this.showsurface = true
    this.showmaterial = true
  }
  changeCheckboxStateroompainted(items: any, id: any) {

    let b = this.roompainted.find((element: any) => element == items)
    console.log(b)
    if (b) {
      console.log('b')
      const index: number = this.roompainted.indexOf(b);
      this.selected_ids_rooms.splice(index, 1)
      this.roompainted.splice(index, 1)
    } else {
      this.roompainted.push(items)
      this.selected_ids_rooms.push(id)
    }
  }
}
