import {ServerDataSource} from 'ng2-smart-table';
import {AfterViewInit, Component} from '@angular/core';
import {EMessageType} from '../../model/enums/EMessageType';
import {ImageFile} from '../../model/ImageFile';


@Component({
  template: ''
})
export class BaseUploadFileComponent{
  urlRegex = /(https?:\/\/[^\s]+)/g;
  EMessageType = EMessageType;
  fileImage: any;
  possibleImagesExtension = ['jpg', 'jpeg', 'png', 'gif'];
  possiblePdfExtension = ['pdf'];
  possibleDocExtension = ['doc'];
  possibleExcelExtension = ['xlsx'];
  possiblePptExtension = ['ppt', 'pptx'];

  getBase64Prefix(fileExtension: string): string {
    if (this.possibleImagesExtension.includes(fileExtension)) {
      return 'data:image/png;base64,';
    } else if (this.possibleDocExtension.includes((fileExtension))) {
      return 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,';
    } else if (this.possiblePdfExtension.includes((fileExtension))) {
      return 'data:application/pdf;base64,';
    } else if (this.possiblePptExtension.includes((fileExtension))) {
      return 'data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64,';
    } else if (this.possibleExcelExtension.includes((fileExtension))) {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    } else {
      return 'data:text/plain;base64,';
    }
  }

  assignFileImage(fileExtension: string): void {
    console.log(fileExtension);
    if (this.possibleImagesExtension.includes(fileExtension)) {
      this.fileImage = '/assets/icons/chat/file-type-image.svg';
    } else if (this.possibleDocExtension.includes((fileExtension))) {
      this.fileImage = '/assets/icons/chat/file-type-word.svg';
    } else if (this.possiblePdfExtension.includes((fileExtension))) {
      this.fileImage = '/assets/icons/chat/file-type-pdf.svg';
    } else if (this.possiblePptExtension.includes((fileExtension))) {
      this.fileImage = '/assets/icons/chat/file-type-powerpoint.svg';
    } else if (this.possibleExcelExtension.includes((fileExtension))) {
      this.fileImage = '/assets/icons/chat/file-type-excel.svg';
    } else {
      this.fileImage = '/assets/icons/chat/file-type-text.svg';
    }
  }

  processFile(fileName: string, fileSize: number, content: Blob): ImageFile {
    const file = {
      name: fileName,
      src: '',
      size: this.formatBytes(fileSize),
      progress: 0,
      uploaded: false
    } as ImageFile;
    const reader = new FileReader();
    reader.readAsDataURL(content);
    reader.onload = (ev: any) => {
      file.src = ev.target.result;
    };
    return file;
  }

  formatBytes(bytes: any, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }


}
