import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-inspired-latest',
  templateUrl: './get-inspired-latest.component.html',
  styleUrls: ['./get-inspired-latest.component.css']
})
export class GetInspiredLatestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
