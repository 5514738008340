import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectInputOption} from '../../../model/components/SelectInputOption';


@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit {

  constructor() {
  }
  @Input() ownValueText!: string;
  @Input() ownValueType!: string;
  @Input() inputOptions!: SelectInputOption[];
  @Input() label: any;
  @Input() absolute = true;
  labelManuallySelected = '';

  @Output() valueChanged = new EventEmitter();
  @Output() ownOptionChanged = new EventEmitter();
  @ViewChild('optionsContainer') container!: ElementRef;

  ngOnInit(): void {
    if (this.label != null) {
      this.labelManuallySelected = this.label;
    }
  }

  toggleInput(event: any): void {
    this.container.nativeElement.classList.toggle('active');
  }

  optionSelected(option: SelectInputOption): void {
    this.inputOptions.forEach(item => {
      if (item.optionLabel === option.optionLabel) {
        item.optionSelected = true;
        this.label = option.optionLabel;
        this.labelManuallySelected = option.optionLabel;
      } else {
        item.optionSelected = false;
      }
    });
    this.container.nativeElement.classList.remove('active');
    this.valueChanged.emit(option);
  }

  onMouseLeave(): void {
    this.container.nativeElement.classList.remove('active');
  }
  ownValueChange(event: any): void {
    const value = event.target.value;
    if (value != null && value !== '') {
      this.label = this.ownValueText.replace('${value}', value);
    } else {
      this.label = this.labelManuallySelected;
    }

    this.ownOptionChanged.emit(value);

  }

}
