import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  pathName = '';
  path = '';
  urls = [
    {url: '', name: 'Landing page'},
    {url: '/host', name: 'Become a painter'},
    {url: '/login', name: 'Login'},
    {url: '/login/forgot', name: 'Forgot password'},
    {url: '/login/recovery/:token', name: 'Recover password'},
    {url: '/register', name: 'Sign up'},
    {url: '/home', name: 'Home'},
    {url: '/home/bids', name: 'My bids'},
  ];

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.pipe(take(1)).subscribe(data => {
      console.log(data.path);
      this.checkForPathName(data.path);
    });
  }

  checkForPathName(givenPath: string): void {
    this.urls.forEach(item => {
      if (item.url === givenPath) {
        this.path = item.url;
        this.pathName = item.name;
      }
    });
  }


}
