import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';

@Component({
  selector: 'app-one-of-many-options-input',
  templateUrl: './one-of-many-options-input.component.html',
  styleUrls: ['./one-of-many-options-input.component.scss']
})
export class OneOfManyOptionsInputComponent implements OnChanges{
  @ViewChild('searchWrapper') searchWrapper!: ElementRef;

  @Input() topResult!: number;
  @Input() placeHolder!: string;
  @Input() allowCustomValues = true;
  @Input() allowEmptyValues = false;
  @Input() pathForData!: string;
  @Input() imgSrc = '';
  @Output() selectedOption = new EventEmitter();
  searchData!: string[];
  displayData!: string[];
  input = '';

  constructor() {
   // this.prepareData();
  }

  prepareData(): void {
      fetch(this.pathForData)
        .then(response => response.text())
        .then(data => {
          const split = data.split(',');
          split[split.length - 1] = split[split.length - 1].replace('\n', '').replace('\r', ''); // replace end of line chars
          this.searchData = split.filter((n, i) => split.indexOf(n) === i); // filter unique values
        });

  }

  searchValue(event: any): void {
    this.input = event.target.value;
    if (this.input.length > 0) {
      this.searchWrapper.nativeElement.classList.add('active');
      if (this.searchData) {
        this.displayData = this.searchData.filter(str => str.toLowerCase()
          .includes(event.target.value.toLowerCase())).slice(0, this.topResult != null ? this.topResult : 3);
      }
      if (this.allowCustomValues === true) {
        this.selectedOption.emit(event.target.value);
      }
      if (!this.checkIfValueIsImpossible()) {
        this.selectedOption.emit(event.target.value);
      }
      if (event.target.value == null || event.target.value === '' || this.checkIfValueIsImpossible()) {
        this.selectedOption.emit('');
      }
      if (this.allowEmptyValues && (this.input === '' || this.input == null)) {
        this.selectedOption.emit('');
      }

    } else {
      this.wrapperInActive();
    }

  }

  setSelectedOption(value: string): void {
    this.input = value;
    this.selectedOption.emit(value);
    this.wrapperInActive();
  }

  onBlur(event: any): void {
    if (this.checkIfValueIsImpossible()) {
      if (this.input != null) {
        // this.input.value = '';
        this.input = '';
      }
    }
    this.wrapperInActive();
    if (this.allowEmptyValues && (this.input === '' || this.input == null)) {
      this.selectedOption.emit('');
    }
  }

  wrapperInActive(): void {
    this.searchWrapper.nativeElement.classList.remove('active');
    this.displayData = [];
  }

  checkIfValueIsImpossible(): boolean {
    return !this.allowCustomValues && !this.searchData?.map(val => val.toLowerCase()).includes(this.input.toLowerCase());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pathForData) {
      this.prepareData();
    }
    if (changes.placeHolder) {
      if (this.input != null) {
        this.input = '';
      }
    }
  }
}
