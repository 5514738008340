


<div class="modal-keeper" *ngIf="displayModal" (click)="closeModal()">
  <div class="modal-dialog modal_width_respon">
    <!--FIXME verify if there is any difference somewhere between closeModal and cancelButtonClicked-->
    
      <img  class="close-modal-icon" *ngIf="cancelButtonText == null" 
      src="assets/icons/close-modal.png" 
      (click)="cancelClicked($event); closeModal();">
    
   
    <!-- <div class="modal-title" *ngIf="title != ''">
      <div class="modal-claim">{{title}}</div>
      <div class="modal-subclaim" [innerHTML]="subclaim"></div>
      <input *ngIf="inputType == 'text'" type="number" placeholder="{{inputPlaceHolder}}" (change)="inputChange($event)">
      <input *ngIf="inputType == 'number'" type="number" placeholder="{{inputPlaceHolder}}" (change)="inputChange($event)">
    </div> -->
    <ng-content select="slot-content"></ng-content>
    <!-- <div class="modal-actions" *ngIf="!withoutActions">
      <button (click)="confirmClicked($event)" class="custom-button confirm-button" [disabled]="confirmButtonDisabled">{{confirmButtonText}}</button>
      <button *ngIf="cancelButtonText != null" class="custom-button cancel-button" (click)="cancelClicked($event)">{{cancelButtonText}}</button>
    </div> -->
  </div>
</div>







