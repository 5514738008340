<div class="container-bg login_screens">

  <img src="assets/splashes/1.svg" class="splash1">
  <img src="assets/splashes/3.svg" class="splash2">
  <img src="assets/splashes/5.svg" class="splash3">
  <img src="assets/splashes/2.svg" class="splash4">
  <img src="assets/splashes/4.svg" class="splash5">


  <img src="assets/splashes/bottom-splash.svg" class="bottom-splash">
  <img src="assets/splashes/sub-splash.svg" class="sub-splash">
  <img src="assets/splashes/main-splash.svg" class="hero-splash">
  <div class="main-box">

    <div class="rst-pass logn_wrap">
      <div class="">
        <div class="">
          <p class="title margin-b15">Reset password</p>
          <form [formGroup]="userDataForm" class="form margin-b10">
            <div class="input-wrapper margin-b15">
              <label><span class="label-text">New Password</span></label>
              <input formControlName="password" placeholder="Enter new password" >
            </div>

            <div class="input-wrapper">

              <label><span class="label-text">Confirm Password</span></label>

              <input formControlName="confpassword" placeholder="Confirm password" >
               <span class="error-text" style="
    font-size: 11px;
    color: red;
"
*ngIf="(userDataForm.get('confpassword')?.touched || userDataForm.get('confpassword')?.dirty) && userDataForm.hasError('pwdNotSame') && !userDataForm.get('confpassword')?.errors?.required"                                    >
                                    *New password must not match with old password
                                </span>
            </div>
             
          </form>

          <div class="text-center">
            <div class="w-100 mt-3">
              <button (click)="submitForm()" type="submit" class="button_theme">Save
              </button>
              <p class="hint-color hint-small margin-b10">or <a routerLink="/login" class="hint-color">Sign in </a></p>
            </div>

          </div>

        </div>
      </div>
    </div>

  </div>
</div>