export enum EProjectType {
  PROJECT_INTERIOR = 'PROJECT_INTERIOR',
  PROJECT_EXTERIOR = 'PROJECT_EXTERIOR',
  PROJECT_OTHERS = 'PROJECT_OTHERS'
}

export interface RedirectUrl {
  value: string;
}

export function getRedirectLinkForProjectType(eProjectType: EProjectType): RedirectUrl {
  switch (eProjectType) {
    case EProjectType.PROJECT_INTERIOR:
      return {value: 'interior'};
    case EProjectType.PROJECT_EXTERIOR:
      return {value: 'exterior'};
    case EProjectType.PROJECT_OTHERS:
      return {value: 'others'};
  }
}

export function getProjectTypeByRedirectValue(value: string): EProjectType {
  switch (value) {
    case 'interior':
      return EProjectType.PROJECT_INTERIOR;
    case 'exterior':
      return EProjectType.PROJECT_EXTERIOR;
    case 'others':
      return EProjectType.PROJECT_OTHERS;
    default:
      throw Error('Unknown project type');
  }
}

