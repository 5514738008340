<div class="image-upload">
  <img src="{{tokenStorage.getProfileImage() | defaultAvatar}}" alt="profileImage" class="profile-image">
  <app-profile-image-upload (whenFilesChange)="selectFile($event)" #imagePicker></app-profile-image-upload>
</div>
<div class="info">
  <div class="crop-info" *ngIf="uploadedFile != null">Please crop your image (display resolution up to 1400 &times; 1400 pixels).</div>
  <div class="buttons">
  <button class="custom-button" (click)="upload()" *ngIf="uploadedFile != null">Save</button>
  <button class="custom-button" (click)="closeCropper()" *ngIf="uploadedFile != null">Cancel</button>
  </div>
  <h4 *ngIf="success == true">Changing profile image in progress.</h4>
  <div class="progress-container" *ngIf="success == true">
    <div class="progress" [style.width]="progress + '%'"></div>
  </div></div>
<div class="image-cropper-wrapper" #imageCropper >
  <image-cropper id="imageCropper"
                 [imageChangedEvent] = "imageFileForCropper"
                 [maintainAspectRatio]="true"
                 [cropperMaxWidth]="IMAGE_MAX_WIDTH"
                 [cropperMaxHeight]="IMAGE_MAX_HEIGHT"
                 [aspectRatio]="IMAGE_MAX_WIDTH/IMAGE_MAX_HEIGHT"
                 [cropperMinHeight]="IMAGE_MAX_HEIGHT/CROPPER_MIN_SIZE_DIVIDER"
                 [cropperMinWidth]="IMAGE_MAX_WIDTH/CROPPER_MIN_SIZE_DIVIDER"
                 [onlyScaleDown]="false"
                 [initialStepSize]="100"
                 (imageCropped)="imageCropped($event)"
                 [roundCropper]="false"
                 [alignImage]="'center'"
  ></image-cropper>
</div>
