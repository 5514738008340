import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {TokenStorageService} from '../../../../services/token-storage.service';
import {MessageService} from '../../../../services/message.service';
import {PageableResponse} from '../../../../model/PageableResponse';
import {FirstNamePipe} from '../../../../pipes/first-name.pipe';
import {ShareDataService} from '../../../../services/data-sharing/share-data.service';
import {ImageFile} from '../../../../model/ImageFile';
import {EMessageType} from '../../../../model/enums/EMessageType';
import {BaseUploadFileComponent} from '../../../../components/MAIN_COMPONENTS/base-upload-file.component';
import {Router, ActivatedRoute} from '@angular/router';
import {getRedirectLinkForProjectType} from '../../../../model/enums/EProjectType';
import {Message} from '../../../../model/Message';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { SocketService } from 'src/app/services/socket.service';
import * as moment from 'moment';
import { Messagenew } from 'src/app/views/models/model';
    import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-chat-window',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [FirstNamePipe]
})
export class ChatComponent extends BaseUploadFileComponent implements OnInit, OnDestroy {
@ViewChild('scrollMe') private myScrollContainer!: ElementRef;

rooms: any=[]
  modalFlag:boolean = false;
  displayDetailsModalVisible = false
  roomid: any
  modalFlagnew: boolean = false
  fileuploaderror: any
               today: string = moment().format("YYYY-MM-DD");
               bid: any
                 private mContainer !: ElementRef

  profile: any
  projectdetails: any
  messages: Messagenew[] =[] //
         path :any= 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
         files: any=[]
         cus_profile: any
         filesArr: any=[]
         new: any=[]
  s_detials: any
  typedText: any
  newsenderid: any
  newrecieverid : any
  primary_room_id: any
  r_details: any
  @ViewChild('chatWindow') chatWindow!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('imageInput') imageInput!: ElementRef;
  conversationalistFullName!: string;
  conversationalistEmail!: string;
  isConversationListEmpty = true;
  pageable!: PageableResponse;
  renderer2Listener: any;
  page = 0;
  loading = false;
  pageSize = 12;
  isUserCustomer!: boolean;
  newMessageContent: any;
  selectedFile!: ImageFile | null;
  conversation: any;
  scrollToBottom = true; // scroll to bottom only when new conversation is opened or new message has been sent/received

  @ViewChild('chatWindow') set ft(chatComponent: ElementRef) {
    if (chatComponent != null) {
      this.renderer2Listener = this.renderer2.listen(chatComponent.nativeElement, 'scroll', (evt) => {
        if (chatComponent.nativeElement.scrollTop === 0) {
          this.scrollToBottom = false;
          if (this.pageable != null && this.pageable.totalElements) {
            if (this.pageable.totalElements > this.messageService.getPrivateMessages().length) {
              this.page += 1;
              this.loading = true;
              this.messageService.getPrivateChatMessages(this.messageService.getOpenedPrivateConversation()!.conversationId, this.page, this.pageSize).subscribe(res => {
                const temp = res.content as Message[];
                this.loading = false;
                chatComponent.nativeElement.scrollTop = 10;
                this.messageService.setPrivateMessages(temp.reverse().concat(this.messageService.getPrivateMessages()));
              });
            }
          }
        }
      });
    }
  }

  constructor(private router: Router, private toastr : ToastrService, private activatedRoute: ActivatedRoute, private shareDataService: ShareDataService, private firstNamePipe: FirstNamePipe, public messageService: MessageService, public tokenStorage: TokenStorageService, private renderer2: Renderer2, private authService: HttpserviceService,     private socketService: SocketService,
) {
    

    super();

    this.socketService.initSocket();
  }


  ngOnInit(): void {
    this.getprofile();

   this.roomid= this.activatedRoute.snapshot.params.id
       this.primary_room_id = this.activatedRoute.snapshot.params.primary_id
        if(this.roomid){
           this.openChatrrom(this.primary_room_id, this.roomid)

  }
  this.getrooms()
  this.joinRoom();       
  this.onGetMessage();
  this.onGetMessagemedia();
    this.scrollToBottom = true;
    this.shareDataService.newChatMessageObservable.subscribe(() => this.scrollToBottomOnNewMessage())
    this.isUserCustomer = this.tokenStorage.isUserCustomer();
    this.messageService.getStartedConversations().subscribe(conversations => {
      this.messageService.setPrivateConversations(conversations);
      this.isConversationListEmpty = conversations == null || conversations.length === 0;
      if (!this.isConversationListEmpty) {
        this.conversation = conversations[0];
        this.messageService.setOpenedPrivateConversation(conversations[0]);
        this.loadMessages(conversations[0], this.tokenStorage.getUser().fullName === conversations[0]?.userCustomer.userPersonal.fullName ? conversations[0].userPainter.userPersonal.fullName : conversations[0].userCustomer.userPersonal.fullName);
      }
    });
  }

    joinRoom() {
    console.log('a')
    this.socketService.onEvent('room join').subscribe(res => {
      console.log("professional joined ", res)
    })
          console.log("professional joined ")

  }

   onGetMessage() {
        console.log('onGetMessage')

    this.socketService.onEvent('textMessage').subscribe((response: any) => {
      // we will push message on the date wise
              console.log('onGetMessage')

         const index = this.messages.findIndex(item => item.date == this.today);

      if (index >= 0) {
       this.messages[index].chats.push(response[0])
      } else {
        this.messages.push({
          date: this.today,
          chats: [response[0]]
        })
      }
   
  
   setTimeout(() => {
        this.scrollToBottomnew()
      }, 200)
    })
  }

     scrollToBottomnew(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
               console.log('testscroll')

        } catch(err) { }                 
    }
   onGetMessagemedia() {
        console.log('onGetMessagemedia')

    this.socketService.onEvent('media message').subscribe((response: any) => {
      // we will push message on the date wise
              console.log('onGetMessage')

       const index = this.messages.findIndex(item => item.date == this.today);

      if (index >= 0) {
       this.messages[index].chats.push(response[0])
      } else {
        this.messages.push({
          date: this.today,
          chats: [response[0]]
        })
      }
      setTimeout(() => {
        this.scrollToBottomnew()
      }, 200)
    })

   
   
    
  }
    createRoom() {
    this.socketService.emitAction("room join", {
    
      room_id: this.roomid
      
    })
  }

   onScrollToBottom() {
    try {
      this.mContainer.nativeElement.scrollTop = this.mContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }
  ngOnDestroy(): void {
    if (this.renderer2Listener != null) {
      this.renderer2Listener = null;
    }
    if (this.messageService.getOpenedPrivateConversation() != null) {
      this.messageService.setConversationContentAsRead(this.messageService.getOpenedPrivateConversation()?.conversationId).subscribe(res => {
        this.messageService.setOpenedPrivateConversation(null);
      });
    }
    this.messageService.getUnreadChatConversationsCount().subscribe(res => {
      this.messageService.setUnreadConversationCount(res.content);
    });
  }

   getprofile(){
         this.authService.getprofile().subscribe((response : any) => {
         this.profile= response.profileData
  })
  }
  openChatrrom(id:any, room_id: any){
  this.messages=[]
  this.roomid= room_id
  this.primary_room_id= id
  let param ="room_id=" +room_id + '&primary_room_id='+ id
    this.authService.getchatrromdetails(param).subscribe((response: any) => {
    //  this.messages = response.data
       this.newsenderid== response.firstReceiver_id
    this.newrecieverid= response.firstReceiver_id
        this.bid= response.data[0].room_details?.bidDetails

      this.projectdetails = response.data[0].room_details.Project_detail
      this.s_detials= response.data[0].userData
            this.r_details= response.data[0].senderData
            for (var i = 0; i < response.data.length; i++) {
        const val = response.data[i];
     //   console.log(val)
        const index = this.messages.findIndex((item: any) => moment(val.date).format("YYYY-MM-DD") == item.date);
       // console.log(index)
        if (index >= 0) {
        console.log(val)
                  this.messages[index].chats.unshift(val)

        
        //  console.log("Unshift..");
                    console.log("Unshift..", this.messages);

        } else {
          this.messages.unshift({
            date: moment(val.date).format("YYYY-MM-DD"),
            chats: [val]
          })
          console.log("push..", this.messages);
        }
      }
    //  this.previewImage2 = this.path + this.profile.profile_image
      //     this.selectedFile2 =  this.profile.profile_image
      // console.log(this.profileForm)
      //   this.toastr.success('Password changed!', 'Success!');

    })
    this.createRoom()
  }

  opendetails(id: any){
    this.modalFlag=true;
 
		 this.authService.getprofilebyvalue(id).subscribe((response : any) => {
         this.cus_profile =response.profileData
         })
  }
  getrooms(){
         this.authService.getrooms().subscribe((response: any) => {
      this.rooms = response.data

    //  this.previewImage2 = this.path + this.profile.profile_image
      //     this.selectedFile2 =  this.profile.profile_image
      // console.log(this.profileForm)
      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

    viewDemoDetailss(id: any) {
    this.router.navigate(['/home/my-jobs/details/'+id]);
  }
   deleteproj(){
  let obj={
        'room_id': this.roomid
  }
   this.authService.deletechatroom(obj).subscribe((response : any) => {
             this.toastr.success('Deleted successfully.', 'Success!');
               this.modalFlagnew = false
               window.location.reload()
        // this.profile= response.profileData
  })
  }

  open(){
  this.modalFlagnew = true
  }
  sendMessage(): void {
    if (this.selectedFile != null) {
      const fileExtension = this.selectedFile.name.split('.');
      if (this.possibleImagesExtension.includes(fileExtension[fileExtension.length - 1])) {
        this.messageService.sendPrivateMessage(this.conversationalistEmail, this.selectedFile.src, EMessageType.IMAGE, this.selectedFile.name);
        this.resetInputValues();
      } else {
        this.messageService.sendPrivateMessage(this.conversationalistEmail, this.selectedFile.src, EMessageType.FILE, this.selectedFile.name);
        this.resetInputValues();
      }
    } else {
      if (this.newMessageContent != null && this.newMessageContent.trim() !== '') {
        this.messageService.sendPrivateMessage(this.conversationalistEmail, this.newMessageContent, EMessageType.TEXT, '');
        this.resetInputValues();
      }
    }
  }
    selectFile(event: any) {
    let files = event.target.files
    console.log(files)
    if (files) {
      for (let i = 0; i < files.length; i++) {
      console.log(files[i])
            if (files[i].size > 31457280) {
                       this.new.splice(files[i],1);

           //     this.toastr.error('The file should not exceed more than 30MB.', 'Error!');
         return;

            }else{
             let val = files[i];
        this.filesArr.push(val);
        console.log(this.filesArr);
            }

       
      }
    }
  }
      uploadFiles(evt: any) {
      console.log(evt.target.files)
    if (!evt.target.files.length) {
        return;
    }
	  for (var i = 0; i < evt.target.files.length; i++) { 
         if (evt.target.files[i].size > 31457280) {
          this.toastr.error('The file should not exceed more than 30MB.', 'Error!');
         return;
           this.new.splice(evt.target.files[i],1);
          
         }else{
         	              this.new.push(evt.target.files[i]);

         }

        }
	 
		  //  this.isLoading = true;

	//const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();
    console.log(file.size)
	   if (file.size/1024/1024 > 1) {
    
     
          for (var i = 0; i < evt.target.files.length; i++) { 
	              this.new.splice(evt.target.files.indexOf(i),1);

   }
      this.toastr.error('The file should not exceed more than 30MB.', 'Error!');
         return;
    //  this.isLoading = false;
	  
		//this.fileuploaderror= "The file should not exceed more than 30MB."
    
    }

        const fr = new FileReader();
        fr.readAsDataURL(file);
        const formData = new FormData();
		for (var i = 0; i < this.new.length; i++) { 
      formData.append("images",this.new[i]);

    }
		        //  formData.append('job_id',job_id);

		//	console.log(this.newfile)

		console.log(formData)
        this.authService.uploadimgaes(formData).subscribe(async res => {
        let a = 'image'
        for(let i=0; i<res.imgs.length;i++){
		this.files.push({'url': res.imgs[i],'name': this.filesArr[0].name, 'size': this.filesArr[0].size})
        }
      
        console.log("files",this.files)
     //   this.othersotherproperty.push({'title': this.p_name, 'image': this.files[0].image})
       // this.newobj.images= this.filesnewnew
       // console.log(this.othersotherproperty)
      
       // this.p_newname=''
       // this.p_newname=''
		//   console.log("post file obj =========== ",obj)
         
		  		          //  this.isLoading = false;

        },(err) => {
          this.authService.handleError(err)
          console.log(err)
		  		         //   this.isLoading = false;

        })
 
	}
      removeFile(index: number) {
    this.filesArr.splice(index)
  }

   viewDemoDetailsscomp(id: any, type:any) {
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-jobs/completed-details/'+id]);
  }
   viewDemoDetailssc(id: any, type:any) {
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-project-detail/'+id]);
  }
  
  downloadFile(url: string) {
    console.log(url, "sss");
    this.authService.download(url);
  }

    getFileIcon(file: any): any {
  // console.log(file)
  }
   getURLofFile(file: File) {
    // check if image is coming
    const type = file.type.split("/")[0];
    var fileExt = file.name.split('.').pop();
  //  console.log(file)
    // if (type == "image") {
    //   const url = URL.createObjectURL(file);
    //   return this.sanitizer.bypassSecurityTrustUrl(url)
    // } else {
    return 'assets/imgs/file_icons/' + fileExt?.toLocaleLowerCase() + ".png";
    // }
  }
    sendMessagenew(): any {

    console.log(this.typedText)

    if (this.typedText) {
      this.socketService.emitAction("textMessage", {
        sender_id: this.profile.id,
        receiver_id: this.newrecieverid,
        message: this.typedText,
        room_id: this.roomid,
      primary_room_id: this.primary_room_id

      });

      // remove message after sent
      this.typedText = "";
      console.log('messagesent', this.r_details.id, this.s_detials.id, this.typedText, this.roomid, this.primary_room_id)
    
     let param ="room_id=" +this.roomid + '&primary_room_id='+ this.primary_room_id
    this.authService.getchatrromdetails(param).subscribe((response: any) => {
    //  this.messages = response.data
      this.s_detials= response.data[0].userData
            this.r_details= response.data[0].senderData
            console.log(this.s_detials, this.r_details)
                  this.projectdetails = response.data[0].room_details.Project_detail


                    // we will push message on the date wise
 
                  })
                  console.log(this.messages)
  //  this.openChatrrom(this.primary_room_id, this.roomid)

  this.getrooms();
  }
  }
  
   sendMessagenewupload(): any {

    console.log(this.typedText)

      this.socketService.emitAction("media message", {
        sender_id: this.profile.id,
        receiver_id: this.s_detials.id,
        message_type: 'media',
        attachments:this.files[0],
        message: this.typedText,
        room_id: this.roomid,
      primary_room_id: this.primary_room_id

      });

      // remove message after sent
      this.typedText = "";
      this.filesArr=[]
      this.files=[]
      console.log('messagesent', this.r_details.id, this.s_detials.id, this.typedText, this.roomid, this.primary_room_id, this.files[0])
    
    let param ="room_id=" +this.roomid + '&primary_room_id='+ this.primary_room_id
    this.authService.getchatrromdetails(param).subscribe((response: any) => {
    //  this.messages = response.data
      this.s_detials= response.data[0].userData
            this.r_details= response.data[0].senderData
            console.log(this.s_detials, this.r_details)
                  this.projectdetails = response.data[0].room_details.Project_detail


                    // we will push message on the date wise
 
                  })
                  console.log(this.messages)
  this.getrooms()




  }

  loadMessages(conversation: any, conversationalistFullName: string): void {
    this.messageService.setOpenedPrivateConversation(conversation);
    this.conversation = conversation;
    this.conversationalistFullName = conversationalistFullName;
    this.conversationalistEmail = conversation.userCustomer.email === this.tokenStorage.getEmail() ? conversation.userPainter.email : conversation.userCustomer.email;
    this.page = 0;
    this.scrollToBottom = true;
    this.messageService.getPrivateChatMessages(conversation.conversationId, 0, this.pageSize).subscribe(res => {
      this.pageable = res;
      this.messageService.setPrivateMessages(res.content?.reverse());
    });
  }


  getProjectTitle(conv: any): string {
    //FIXME each project should have title
    return 'Need a painter for my mansion, Urgent!';
    //return conv.project?.title;
  }

  viewDetails(): void {
    if (this.tokenStorage.isUserCustomer()) {
      this.router.navigateByUrl('home/my-projects/details/' + getRedirectLinkForProjectType(this.conversation.project.projectType).value + '/' + this.conversation.project.tag + '/' + this.conversation.bid.tag);
    } else if (this.tokenStorage.isUserPainter()) {
      this.router.navigateByUrl('home/my-bids/bid/' + this.conversation.bid.tag);
    }

  }

  autoGrowOrShrink(event: any): void {
    event.target.style.height = 'auto';
    event.target.style.height = event.target.scrollHeight + 'px';
  }

  fileBrowseHandler(event: any): void {
    this.processData(event.target.files);
  }


  processData(actualFiles: any): void {
    this.selectedFile = this.processFile(actualFiles[0].name, actualFiles[0].size, actualFiles[0]);
    const fileExtension = actualFiles[0].name.split('.');
    this.assignFileImage(fileExtension[fileExtension.length - 1]);
  }

  deleteFile(): void {
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    this.selectedFile = null;
  }

  @HostListener('document:keydown', ['$event'])
  keyDownEvent(event: any): void {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();
      this.sendMessage();
    }
  }
  resetInputValues(): void {
    this.selectedFile = null;
    this.fileInput.nativeElement.value = null;
    this.imageInput.nativeElement.value = null;
    this.newMessageContent = null;
  }
  scrollToBottomOnNewMessage(): void {
    this.scrollToBottom = false;
    setTimeout(() => {
      this.chatWindow.nativeElement.scrollTop = this.chatWindow.nativeElement.scrollHeight;
    }, 200);
  }

  makeComplain(){}
  openAcceptBidModal(){}
  checkPendingOrNewStatus():boolean{ return true}
  openDenyBidModal(){}
  closeComplainModal(){
    this.modalFlag = false
  }

}
