import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Complain} from '../model/Complain';

const API = environment.SERVER_BASE_URL + 'complain';


@Injectable(
  {providedIn: 'root'}
)
export class ComplainService {

  constructor(private httpClient: HttpClient) {
  }

  postComplain(newComplain: Complain): Observable<any>{
    return this.httpClient.post(API, newComplain);
  }
}
