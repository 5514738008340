import {Component, HostListener} from '@angular/core';

@Component({
  template: ''
})
export abstract class KeyBoardListenerComponent{
  abstract onEnterClicked(): void;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onEnterClicked();
    }
  }

}
