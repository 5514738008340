import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './views/register/register.component';
import { LoginComponent } from './views/login/login.component';
import { NewGetInspiredComponent } from './views/new-get-inspired/new-get-inspired.component';

import { HomeComponent } from './views/home/home.component';
import { AuthGuardService } from './services/auth-guard-service';
import { EmailConfirmedComponent } from './views/register/email-confirmed/email-confirmed.component';
import { PasswordResetComponent } from './views/login/password-reset/password-reset.component';
import { ChangefpassowordComponent } from './views/changefpassoword/changefpassoword.component';
import { MyProfileEditComponent } from './views/my-profile-edit/my-profile-edit.component';
import { MyProfileUserEditComponent } from './views/my-profile-user-edit/my-profile-user-edit.component';

import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { AdminGuard } from './services/adminGuard';

import { UserDetailsComponent } from './components/navigation-others/user-details/user-details.component';
import { ForgotPasswordComponent } from './views/login/forgot-password/forgot-password.component';
import { SplashBgComponent } from './views/splash-bg/splash-bg.component';
import { ConfirmRegistrationComponent } from './views/register/confirm-registration/confirm-registration.component';
import { BecomePainterComponent } from './views/become-painter/become-painter.component';
import { MyProjectsComponent } from './views/home/home-nav-routes/my-projects/my-projects.component';

import { MarketplaceComponent } from './views/home/home-nav-routes/marketplace/marketplace.component';
import { MyBidsComponent } from './views/home/home-nav-routes/my-bids/my-bids.component';
import { BidDetailsComponent } from './views/home/home-nav-routes/my-bids/bid-details/bid-details.component';
import { PainterProfileComponent } from './views/home/home-nav-routes/painter-profile/painter-profile.component';
import { AdminDashboardComponent } from './views/home/home-nav-routes/admin-dashboard/admin-dashboard.component';
import { ERole } from './model/enums/ERole';
import { NotificationsComponent } from './views/home/home-nav-routes/notifications/notifications.component';
import { NewNotificationComponent } from './views/home/home-nav-routes/new-notification/new-notification.component';

import { MyProfileComponent } from './views/home/home-nav-routes/my-profile/my-profile.component';
import { ProfileSettingsComponent } from './views/home/home-nav-routes/my-profile/profile-settings/profile-settings.component';

import { CreateNewProjectComponent } from './views/home/home-nav-routes/create-new-project/create-new-project.component';
import { ChatComponent } from './views/home/home-nav-routes/chat/chat.component';
import { CusChatComponent } from './views/home/home-nav-routes/cus-chat/cus-chat.component';

import { ProjectDetailsComponent } from './components/projects/project-details/project-details.component';
import { MyJobsComponent } from './views/home/home-nav-routes/my-jobs/my-jobs.component';
import { MyJobDetailsComponent } from './views/home/home-nav-routes/my-jobs/my-job-details/my-job-details.component';
import { CompletedJobDetailsComponent } from './views/home/home-nav-routes/my-jobs/completed-job-details/completed-job-details.component';
import { CompletedJobDetailsNewComponent } from './views/home/home-nav-routes/my-jobs/completed-job-details-new/completed-job-details-new.component';

import { WelcomeOneComponent } from './views/login/welcome-one/welcome-one.component';
import { WelcomeTwoComponent } from './views/login/welcome-two/welcome-two.component';
import { WelcomeThreeComponent } from './views/login/welcome-three/welcome-three.component';
import { MyProjectCardComponent } from './views/home/home-nav-routes/my-project-card/my-project-card.component';
import { PaymentComponent } from './views/home/home-nav-routes/payment/payment.component';

import { MyProjectDetailComponent } from './views/home/home-nav-routes/my-project-detail/my-project-detail.component';
import { NewProjectCompleteDetailsComponent } from './views/home/home-nav-routes/new-project-complete-details/new-project-complete-details.component';

import { UserProjectDetailComponent } from './views/home/home-nav-routes/user-project-detail/user-project-detail.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { WelcomeScreenComponent } from './views/login/welcome-screen/welcome-screen.component';
import { TermsConditionComponent } from './views/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { BlogsComponent } from './views/blogs/blogs.component';
import { AboutUsComponent } from './views/about-us/about-us.component';
import { HelpComponent } from './views/help/help.component';
import { HowItWorksComponent } from './views/how-it-works/how-it-works.component';
import { MakeNewProjectComponent } from './views/home/home-nav-routes/make-new-project/make-new-project.component';
import { MakeNewProjectDeatilsComponent } from './views/home/home-nav-routes/make-new-project-deatils/make-new-project-deatils.component';
import { BidPaymentComponent } from './views/home/home-nav-routes/bid-payment/bid-payment.component';
import { MakeNewProjectAdvancedComponent } from './views/home/home-nav-routes/make-new-project-advanced/make-new-project-advanced.component';
import { FaqComponent } from './views/faq/faq.component';
import { PaymentSuccessComponent } from './views/home/payment-success/payment-success.component';
import { PainterRatingComponent } from './components/painter-rating/painter-rating.component';
// import { ModalsComponent } from './views/modals/modals.component';


const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'user-details', component: UserDetailsComponent },
  { path: 'host', component: BecomePainterComponent },
  {
    path: 'terms-conditions', component: TermsConditionComponent
  },
  {
    path: 'privacy-policy', component: PrivacyPolicyComponent
  },
  {
    path: 'help', component: HelpComponent
  },
  {
    path: 'how-works', component: HowItWorksComponent
  },
  {
    path: 'about-us', component: AboutUsComponent
  },
  {
    path: 'contact-us', component: ContactUsComponent
  },
  {
    path: 'blogs', component: BlogsComponent
  },
  {
    path: 'faq', component: FaqComponent
  },
  {
    path: 'resetPassword/:email',
    component: ChangefpassowordComponent
  },
  {
    path: 'get-inspired',
    component: NewGetInspiredComponent
  },
  {
    path: 'login', component: SplashBgComponent, children: [
      {
        path: '',
        component: LoginComponent
      },
      {
        path: 'forgot',
        component: ForgotPasswordComponent
      },
      {
        path: 'WelcomeOne',
        component: WelcomeOneComponent
      },
      {
        path: 'WelcomeTwo',
        component: WelcomeTwoComponent
      },
      {
        path: 'WelcomeThree',
        component: WelcomeThreeComponent
      },
      {
        path: 'register-location',
        component: WelcomeScreenComponent,
      },
      {
        path: 'recovery/:token',
        component: PasswordResetComponent
      },



    ]
  },
  {
    path: 'register', component: SplashBgComponent, children: [
      {
        path: '',
        component: RegisterComponent
      },
      {
        path: 'confirm',
        component: ConfirmRegistrationComponent,
      },
      {
        path: 'confirm/confirmRegistration',
        component: EmailConfirmedComponent
      }
    ]
  },



  {
    path: 'home', component: HomeComponent, canActivate: [AuthGuardService],
    children: [
      {
        path: 'my-profile-edit',
        component: MyProfileEditComponent,
      },
      {
        path: 'my-profile-edit-user',
        component: MyProfileUserEditComponent,
      },
      {
        path: 'chat', component: ChatComponent
      },
      {
        path: 'chat/:id/:primary_id', component: ChatComponent
      },
      {
        path: 'cus-chat', component: CusChatComponent
      },
      {
        path: 'cus-chat/:id/:primary_id', component: CusChatComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'notifications-new',
        component: NewNotificationComponent
      },
      {
        path: 'my-jobs',
        children: [
          {
            path: '',
            component: MyJobsComponent
          },
          {
            path: 'details/:id',
            component: MyJobDetailsComponent
          },
          {
            path: 'completed-details/:id',
            component: CompletedJobDetailsNewComponent
          },

        ]
      },
      {
        path: 'admin', component: AdminDashboardComponent, canActivate: [AuthGuardService]
      },



      {
        path: 'marketplace', component: MarketplaceComponent
      },

      {
        path: 'my-profile',
        component: PainterProfileComponent,
      },
      {
        path: 'my-profile/:comments',
        component: PainterProfileComponent,
      },

      {
        path: 'settings',
        component: ProfileSettingsComponent,
        // children: [
        //   {
        //     path: 'general',
        //     component: ProfileSettingsComponent
        //   },
        // ]
      },
      {
        path: 'my-projects',
        children: [
          {
            path: '',
            component: MyProjectsComponent
          },
          {
            path: 'details/:projectType/:tag/:bidId', component: ProjectDetailsComponent
          },
          {
            path: 'details/:projectType/:tag',
            component: ProjectDetailsComponent
          }
        ]
      },
      {
        path: 'new-project',
        component: CreateNewProjectComponent
      },
      {
        path: 'payment',
        component: PaymentComponent
      },
      {
        path: 'payment/:price',
        component: PaymentComponent
      },
      {
        path: 'my-bids',
        children: [
          {
            path: '',
            component: MyBidsComponent
          },
          {
            path: 'bid/:id',
            component: BidDetailsComponent
          }
        ]
      },
      {
        // path: 'profile/:clientid',
        path: 'profile',
        component: PainterProfileComponent
      },
      {
        path: 'profile/:id',
        component: PainterProfileComponent,
      },
      {
        path: 'activity',
        component: NotificationsComponent
      },
      {
        path: 'my-project-card',
        component: MyProjectCardComponent,
      },
      {
        path: 'my-project-detail/:id',
        component: MyProjectDetailComponent,
      },
      {
        path: 'my-project-detail-new/:id',
        component: NewProjectCompleteDetailsComponent,
      },
      {
        path: 'project-detail/:id',
        pathMatch: "full",
        component: UserProjectDetailComponent,
      },
      {
        path: 'project-not-found',
        component: PageNotFoundComponent,
      },
      {
        path: 'make-new-project',
        component: MakeNewProjectComponent,
      },
      {
        path: 'make-new-project-details',
        component: MakeNewProjectDeatilsComponent,
      },
      {
        path: 'make-new-project-advanced',
        component: MakeNewProjectAdvancedComponent,
      },
      {
        path: 'bid-payment',
        component: BidPaymentComponent,
      },
      {
        path: 'payment-success',
        component: PaymentSuccessComponent,
      },
      // {
      //   path: 'rating',
      //   component: PainterRatingComponent,
      // },
      
      // {
      //   path: 'login-with-google-modal',
      //   component: ModalsComponent,
      // },


    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
