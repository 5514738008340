<div class="top-config-panel">
  <div class="bar-wrapper"
       #barwrapper id="barwrapper">
    <img
      *ngIf="isSidebarCollapsed"
      class="collapsed-menu"
      [src]="isSidebarExpanded ? '/assets/icons/double-right-color.svg' : '/assets/icons/bar-menu.svg'"
      (mouseenter)="emitExpandEvent(true)"
      (mouseLeftDown)="leavingDown($event)"
      (click)="makeSidebarFixed()" appMouseDiretionDetection >

<!--    <img-->
<!--      *ngIf="isSidebarCollapsed"-->
<!--      class="collapsed-menu"-->
<!--      [src]="isSidebarExpanded ? '/assets/icons/double-right-color.svg' : '/assets/icons/bar-menu.svg'"-->
<!--      (mouseenter)="expandSidebar(true)"-->
<!--      (mouseLeftDown)="leavingDown($event)"-->
<!--      (click)="makeSidebarFixed()" appMouseDiretionDetection >-->
  </div>

  <div class="custom-input-search" *ngIf="searchInput">
    <img src="assets/nav-icons/search-solid.svg" class="input-icon">
    <input placeholder="{{searchText}}" class="search-input">
  </div>
  <button class="add-btn" *ngIf="extraButton" (click)="onExtraButtonClicked()">{{extraButtonText}}</button>
</div>
