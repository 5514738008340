import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {UserService} from '../../services/user.service';
import {TokenStorageService} from '../../services/token-storage.service';
import {ProfileImageUploadComponent} from '../input/profile-image-upload/profile-image-upload.component';

@Component({
  selector: 'app-profile-image-chooser',
  templateUrl: './profile-image-chooser.component.html',
  styleUrls: ['./profile-image-chooser.component.scss']
})
export class ProfileImageChooserComponent implements OnInit {

  @ViewChild('imageCropper') imageCropper!: ElementRef;
  @ViewChild('imagePicker') imagePicker!: ProfileImageUploadComponent;
  uploadedFile!: Blob | null;
  imageBase64!: string | ArrayBuffer | null | undefined;
  imageFileForCropper!: null;
  readonly IMAGE_MAX_WIDTH = 1400;
  readonly IMAGE_MAX_HEIGHT = 1400;
  readonly CROPPER_MIN_SIZE_DIVIDER = 2;
  success = false;
  progress = 0;

  ngOnInit(): void {
  }
  constructor(private userService: UserService, public tokenStorage: TokenStorageService) {

  }

  selectFile(event: any): void{
    this.imageCropper.nativeElement.style.display = 'block';
    this.success = false;
    if (event.target.files.length === 1) {
      if (!(event.target.files[0] instanceof Blob)) {
        this.closeCropper();
        console.log('Error: Invalid file format');
        return;
      }
      this.uploadedFile = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.uploadedFile as Blob);
      reader.onload = e => {
        const img = new Image();
        img.src = reader.result + '';
        img.onload = l => {
          this.imageFileForCropper = event;
        };
      };
      reader.onerror = e1 => {
        console.log('Error: ', e1);
      };
    } else {
      this.closeCropper();
    }
  }
  closeCropper(): void{
    this.imageCropper.nativeElement.style.display = 'none';
    this.imagePicker.reset();
    this.uploadedFile = null;
    this.imageFileForCropper = null;
  }
  upload(): void{
    this.userService.changeProfileImage(this.imageBase64 as string).subscribe(res => {
      this.success = true;
      this.simulateUploadProcess();
      setTimeout(() => {
        this.userService.getMyBasicData().subscribe(res => {
          console.log(res);
          this.tokenStorage.saveProfileImage(res.profileImage);
        });
      }, 10000);

      this.closeCropper();
    });
  }
  imageCropped(event: ImageCroppedEvent): void {
    console.log(event);
    this.imageBase64 = event.base64;
    console.log(this.bytesToMegaBytes(this.dataURLtoFile(this.imageBase64).size));
  }

  private simulateUploadProcess(): void {
      const progressInterval = setInterval(() => {
        if (this.progress === 100) {
          this.progress = 0;
          this.success = false;
          clearInterval(progressInterval);
        } else {
          this.progress += 1;
        }
      }, 100);

  }

  dataURLtoFile(dataurl: any): any {

    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], 'test', {type: mime});
  }

  bytesToMegaBytes(bytes: any): any {
    return bytes / (1024 * 1024);
  }

}
