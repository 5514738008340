import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../../services/admin.service';
import {FormBuilder, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

// @ts-ignore
@Component({
  selector: 'app-brush-fr-by-room-type',
  templateUrl: './fr-by-room-type-table.component.html',
  styleUrls: ['./fr-by-room-type-table.component.scss']
})
export class FrByRoomTypeTableComponent implements OnInit{
  frByRoomType: any;
  changePropertyModalVisible = false;

  frByRoomTypeForm = this.formBuilder.group({
      id: [''],
      bathroomFactor: ['', [Validators.required]],
      bedroomFactor: ['', [Validators.required]],
      diningRoomFactor: ['', [Validators.required]],
      hallwayFactor: ['', [Validators.required]],
      highCellingRoomFactor: ['', [Validators.required]],
      kitchenFactor: ['', [Validators.required]],
      otherFactor: ['', [Validators.required]],
    });

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.adminService.getFrByRoomType().subscribe(res => {
      this.frByRoomType = res;
      this.frByRoomTypeForm.patchValue(this.frByRoomType);
    });

  }
  showModal(): void {
    this.changePropertyModalVisible = true;
  }
  hideModal(): void {
    this.changePropertyModalVisible = false;
  }
  changeFrByRoomType(): void {
    this.adminService.updateFrByRoomType(this.frByRoomTypeForm.value).subscribe(res => {
      this.frByRoomType = res;
      this.hideModal();
      this.toastrService.success('Updated fr by room type', 'Success');
      this.frByRoomTypeForm.patchValue(this.frByRoomType);
    }, error => {
      this.toastrService.success(error.message, 'Failure');
    });
  }
}

