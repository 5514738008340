import {Directive, EventEmitter, HostListener, OnInit, Output, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appFocusOut]'
})
export class FocusoutDirective implements OnInit {

  constructor(private view: ViewContainerRef) {
  }

  ngOnInit(): void {
  }

  @Output() focusOut = new EventEmitter();

  @HostListener('mouseleave', ['$event'])
  public onListenerTriggered(event: any): void {
    this.focusOut.emit(true);
  }
}
