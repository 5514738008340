import {Component, EventEmitter, Output} from '@angular/core';
import {Wall} from '../../../../../model/Wall';
import {FormBuilder, Validators} from '@angular/forms';
import {GreaterThanZeroValidator} from '../../../../../_helpers/greater-than-zero-validator';
import {Room} from '../../../../../model/Room';
import {AdvancedCreatorComponent} from '../../../../../components/MAIN_COMPONENTS/AdvancedCreatorComponent';
import {EAdvancedCreatorInputType} from '../../../../../model/enums/EAdvancedCreatorInputType';


@Component({
  selector: 'app-room-creator',
  templateUrl: './room-creator.component.html',
  styleUrls: ['./room-creator.component.scss'],
})
export class RoomCreatorComponent extends AdvancedCreatorComponent {
  @Output('saveRooms') saveRooms = new EventEmitter();
  room: any
  currentRoomBeingCreated: Room;
  ERoomCreatorInputType = EAdvancedCreatorInputType;

  rooms: Room[] = [];
  pagesForRoom: any[] = [];

  indexEdited!: any;
  walls: Wall[] = [];

  ceilingFormGroup = this.formBuilder.group({
    ceilingWidth: ['', [Validators.required, GreaterThanZeroValidator]],
    ceilingHeight: ['', [Validators.required, GreaterThanZeroValidator]],
    ceilingNewColor: ['', Validators.required],
    ceilingCurrentColor: ['', Validators.required],
  });

  constructor(private formBuilder: FormBuilder) {
    super();
    this.createPages(true);
    this.currentPage = this.pages[this.currentPageIndex];
  }

  select(section: any, item: any): void {
    this.backButtonVisible = true;
    this.nextButtonDisabled = false;
    if (section.sectionKey !== this.tagsPrefix) {
      this.unselectAllItemsInSectionAndChangeItemState(item);
      this.currentRoomBeingCreated[section.sectionKey] = item.value;
    } else {
      if (this.currentRoomBeingCreated[this.tagsPrefix] == null) {
        this.currentRoomBeingCreated[this.tagsPrefix] = {};
      }
      if (section.multipleChoice) {
        item.selected = !item.selected;
        if (item.selected) {
          this.currentRoomBeingCreated[item.mapLabel] = item.value;
        } else {
          delete this.currentRoomBeingCreated[item.mapLabel];
        }
        this.currentPage.minimumChecked = false;
        for (const key of Object.keys(this.currentRoomBeingCreated)) {
          for (const sectionItem of section.items) {
            if (sectionItem.mapLabel === key) {
              this.currentPage.minimumChecked = true; // check if at least one object contains at least one key as section mapLabel
              this.nextButtonDisabled = false;
            }
          }
        }
      } else {
        this.unselectAllItemsInSectionAndChangeItemState(item);
        this.currentRoomBeingCreated[section.sectionPrefix] = item.value;
      }
    }
    if (!section.multipleChoice) {
      this.nextPage();
    }
  }

  openRoomCreator(): void {
    this.resetAllValues();

    this.roomCreatorVisible = true;
  }

  cancelCreatingRoom(): void {
    this.roomCreatorVisible = false;
  }

  modifyCeiling(): void {
    this.ceilingCreatorVisible = true;
    this.wallCreatorVisible = false;
    this.pageTitle = 'Ceiling';
    this.ceilingFormGroup.get('ceilingNewColor')?.setValue('#ffffff');
    this.ceilingFormGroup.get('ceilingCurrentColor')?.setValue('#ffffff');
    this.walls.forEach(wall => {
      this.ceilingFormGroup.get('ceilingWidth')?.setValue(wall.wallWidth);
      if (wall.wallWidth !== this.ceilingFormGroup.get('ceilingWidth')?.value) {
        this.ceilingFormGroup.get('ceilingHeight')?.setValue(wall.wallWidth);
      }
      if (this.ceilingFormGroup.get('ceilingHeight')?.value === null) {
        this.ceilingFormGroup.get('ceilingHeight')?.setValue(this.ceilingFormGroup.get('ceilingWidth')?.value);
      }
    });
    this.buttonsVisibleFunc();
  }

  modifyWalls(): void {
    this.ceilingCreatorVisible = false;
    this.wallCreatorVisible = true;
    this.pageTitle = 'Wall Creator';
    this.buttonsVisibleFunc();
  }

  addWall(event: Wall): void {
    this.walls.push(event);
  }

  deleteWall(index: number): void {
    this.walls.splice(index, 1);
  }

  wallEdited(wall: Wall, index: number): void {
    this.walls[index] = wall;
  }

  calculateAreas(): void {
    let totalWallsArea = 0;
    this.walls.forEach(wall => {
      totalWallsArea += wall.wallWidth * wall.wallHeight;
    });
    this.currentRoomBeingCreated.totalArea = totalWallsArea + (this.ceilingFormGroup.controls['ceilingWidth'].value * this.ceilingFormGroup.controls['ceilingHeight'].value);
    this.currentRoomBeingCreated.totalWallsArea = totalWallsArea;
  }

  addRoom(): void {
    Object.keys(this.ceilingFormGroup.controls).forEach(key => {
      this.currentRoomBeingCreated[key] = this.ceilingFormGroup.controls[key].value;
    });
    this.currentRoomBeingCreated.walls = this.walls;
    this.calculateAreas();

    if (this.indexEdited == null) {
      this.pagesForRoom[this.rooms.length] = this.pages;
      this.rooms.push(Object.assign({}, this.currentRoomBeingCreated));
    } else {
      this.rooms[this.indexEdited] = this.currentRoomBeingCreated;
      this.pagesForRoom[this.indexEdited] = this.pages;
    }
    this.roomCreatorVisible = false;
  }

  editRoom(index: number): void {
    this.resetAllValues();
    this.pages = this.deepObjectCopy(this.pagesForRoom[index]);
    this.currentPage = this.pages[this.currentPageIndex];
    this.pageTitle = this.currentPage.title;
    Object.keys(this.ceilingFormGroup.controls).forEach(key => {
      this.ceilingFormGroup.controls[key].setValue(this.rooms[index][key]);
    });
    this.indexEdited = index;
    this.walls = this.deepObjectCopy(this.rooms[index].walls);
    this.currentRoomBeingCreated = this.deepObjectCopy(this.rooms[index]);
    this.roomCreatorVisible = true;
    this.nextButtonVisible = true;
    this.nextButtonDisabled = false;
  }

  deleteRoom(index: number): void {
    this.rooms.splice(index, 1);
    this.pagesForRoom.splice(index, 1);
  }

  copyRoom(index: number): void {
    this.rooms.push(this.deepObjectCopy(this.rooms[index]));
    this.pagesForRoom.push(this.deepObjectCopy(this.pagesForRoom[index]));
  }

  sslect(section: any, item: any){
    if (!section.multipleChoice) {
      this.nextPage();
    }
  }

  saveRoomsAndGoNextPage(): void {
    this.saveRooms.emit(this.rooms);
  }

  resetAllValues(): void {
    this.createPages(true);
    this.indexEdited = null;
    this.roomCreatorVisible = false;
    this.ceilingFormGroup.reset();
    this.nextButtonDisabled = true;
    this.currentRoomBeingCreated = {} as Room;
    this.currentPageIndex = 0;
    this.currentPage = this.pages[this.currentPageIndex];
    this.displayingPages = true;
    this.backButtonVisible = false;
    this.nextButtonVisible = false;
    this.wallCreatorVisible = false;
    this.ceilingCreatorVisible = false;
    this.ceilingFormGroup.reset();
    this.walls = [];
  }
}



