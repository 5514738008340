import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent implements OnInit {

  @Input() img: string | undefined;
  @Input() title: string | undefined;
  @Input() label: string | undefined;
  @Input() description: string | undefined;

  constructor() {
  }

  ngOnInit(): void {
  }

  ifCustomerLabel(): boolean {
    return this.label === 'customer';
  }

}
