import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { NbTabComponent, NbTabsetComponent } from '@nebular/theme';
import { SelectInputOption } from 'src/app/model/components/SelectInputOption';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpserviceService } from '../../../../services/httpservice.service';
import * as moment from 'moment';
import { CurrencyPipe, KeyValue } from '@angular/common';
import { UserPainterProfile } from '../../../../model/UserPainterProfile';

import { ToastrService } from 'ngx-toastr';
import { EComplainReason } from 'src/app/model/enums/EComplainReason';
import { EProjectStatus } from 'src/app/model/enums/EProjectStatus';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

enum OrderingFunctionEnum {
  new,
  lowest,
  highest,
  oldest
}
interface Badge {
  tooltip: string;
  imgSrc: string;
  isReached: boolean;
}


@Component({
  selector: 'app-user-project-detail',
  templateUrl: './user-project-detail.component.html',
  styleUrls: ['./user-project-detail.component.scss']
})
export class UserProjectDetailComponent implements OnInit {
  // selectedItem = '2';
  biddetails: any = {}
  shownew: boolean = false
  editnew: boolean = false
  reporting_reason: any = []
  bImage: any
  painterneww_id: any
  makeComplainDialogVisible1dispute: boolean = false
  // imageObject: any =[]
  // imageObject: any =[]
  //  profile: any
  //  path: any = 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
  edit: boolean = false
  //previewImage2: any
  bids: any = []
  price: any
  shownewreview: boolean = false
  // modalFlag: boolean = false;
  reviewlist: any = []
  filteroption: any = 'newest'
  filternewoption: any = 'new'
  painterId:any;
  // modalFlag1: boolean = false;
  starRating: any;
  currrenttab: any
  profileForm!: FormGroup
  imgForm!: FormGroup
  stay_logged_in: boolean = false;
  send_email: boolean = false
  semail: any
  currentdate: any
  show: boolean = false
  newdate: any
  //  id: any
  approved_bids: any = []
  stay_log: any
  previewImage2: any
  selectedFile2: any;
  passwordform!: FormGroup
  error: any
  isFormSubmitted = false;
  pass: String = 'password'
  confirmPass: String = 'password'
  Cpass: String = 'password';
  //   path :any= 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
  //profile: any

  totalRecords: any;
      limit: any= '5'
      offset : any= '0'
      currentPage: any = 1; // Current page number



  changeContactModalVisible = false;
  changePasswordModalVisible = false;
  changeAddressModalVisible = false;
  isPasswordNotChangedDueToError = false;
  isContactNotChangedDueToError = false;
  contactNotChangedDueToErrorSubclaim = '';
  passwordNotChangedDueToErrorSubclaim = '';


  @ViewChild('comments', { read: ElementRef }) set ft(comments: ElementRef) {
    if (comments != null) {
      const scrollToComments = this.activatedRoute.snapshot.paramMap.get('comments');
      if (scrollToComments != null) {
        setTimeout(() => {
          comments.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }


  painterProfile!: UserPainterProfile;
  userSelectedRate!: string;
  userCommentContent!: string;
  avgRating!: number;
  painterBadges: Badge[] = [
    { tooltip: 'Painter is brushing for over 1 year', imgSrc: './assets/icons/painter-badges/badge1.png', isReached: true },
    { tooltip: 'Painter has more than 100 comments', imgSrc: './assets/icons/painter-badges/badge2.png', isReached: true }
  ];
  option: any
  complaint: any
  options: any = [{ 'label': 'No response from the other party' }, { 'label': 'I cannot continue to work on this project' }, { 'label': 'The job does not match the provided description' }, { 'label': 'Other' }]
  modalFlagreport: boolean = false
  modalFlag: boolean = false;
  displayDetailsModalVisible = false
  path: any = 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
  reason: any
  modalFlagBlock: boolean = false;
  displayDetailsModalVisibleBlock = false

  modalFlag1: boolean = false;
  imageObject: any = []
  roomdetails: any
  imageobjectarray: any = []
  displayDetailsModalVisible1 = false

  projectProperties: Map<string, any> = new Map<string, any>();

  order: any = [{ 'value': 'new', 'lable': 'New' }, { 'value': 'oldest', 'lable': 'Oldest' }, { 'value': 'highest', 'lable': 'Highest' }, { 'value': 'lowest', 'lable': 'Lowest' }]
  orderOptions: SelectInputOption[] = [
    {
      optionLabel: 'Sort By: New',
      optionValue: OrderingFunctionEnum.new,
      sortDir: '',
      optionSelected: true,
      optionDisabled: false
    },
    {
      optionLabel: 'Sort By: Old',
      optionValue: OrderingFunctionEnum.oldest,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    },
    {
      optionLabel: 'Sort By: Offer Highest',
      optionValue: OrderingFunctionEnum.highest,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    },
    {
      optionLabel: 'Sort By: Offer Lowest',
      optionValue: OrderingFunctionEnum.lowest,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    }
  ];
  imageObjecta = [{
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    title: ''
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    title: ''
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    title: ''
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg'
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    title: ''
  }];
  profile: any

  id: any
  projectDetails: any = {}
  selectedComplainReason: any;
  complainReasons!: { label: string; visible: boolean; value: any; }[];
  project: any;
  makeComplainDialogVisible!: boolean;
  makeComplainDialogVisible1!: boolean;
  feedBackform: any

  constructor(private authService: HttpserviceService, private route: ActivatedRoute, private toastr: ToastrService, private r: Router, private fb: FormBuilder, private activatedRoute: ActivatedRoute,) {
    this.feedBackform = this.fb.group({
      skills: [''],
      complaint: ['']
    })
  }

  ngOnInit(): void {
    if (localStorage.getItem('currenttab')) {
      this.currrenttab = localStorage.getItem('currenttab')
    }
    this.getprofile()
    this.id = this.route.snapshot.params.id
    console.log(this.route.snapshot.params.id)
    this.getPRojectdetails()
    this.getbids()

    this.getreporting()
  }
  

  getprofilebyid(id: any) {
    console.log(id)
    this.painterId=id
    this.editnew = true
    this.painterneww_id = id
    this.authService.getprofilebyvalue(id).subscribe((response: any) => {
      this.profile = response.profileData
      var date = new Date(this.profile.created_at);
      this.currentdate = new Date()
      this.newdate = date.setFullYear(date.getFullYear() + 1);
      console.log(this.newdate)
      var d = new Date(this.profile.created_at);
      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      var c = new Date(year + 1, month, day);
      console.log(c);
      this.newdate = new Date(year + 1, month, day);
      console.log(c)

      if ((this.currentdate > this.newdate) || (this.reviewlist.count > 100)) {
        this.show = true
      } else {
        this.show = false
      }
      //   this.toastr.success('Password changed!', 'Success!');

    })
    // let obj = {
    //   'id': id,
    //   'role': 'painter',
    //   'limit': this.limit,
    //   'offset' : this.offset
    // }
    // this.authService.getreivewlist(obj).subscribe((response: any) => {
    //   this.reviewlist = response.data
    //   this.totalRecords = response.count;
    //   this.reviewlist.forEach((val: any, ind: number) => {
    //     let current_date: any = moment(new Date());
    //     let deadline_date: any = moment(val.created_at);
    //     val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
    //     console.log('rem days', val.days_remaining);
    //   });
    // })
    this.getreviewlist(id);

    
  }

  
  // showfilter(){
  // console.log(this.shownew)
  //   this.shownew= true

  // }

  showfilter() {
    console.log(11111);
    console.log(this.shownew)
    this.shownew = !this.shownew

  }
  showfilterrevire() {
    console.log(11111);
    console.log(this.shownew)
    this.shownewreview = !this.shownewreview

  }

  newfiler(a: any) {
    this.filternewoption = a
    let obj = {
      'project_id': this.id,
      'order_filter': a
    }
    this.authService.sortbids(obj).subscribe((response: any) => {
      this.bids = response.bids
      this.approved_bids = response.approved_bid
      this.shownew = false

    })
  }

  getreviewlistfilter(a: any) {
    this.filteroption = a
    let obj = {
      'id': this.painterneww_id,
      'role': 'painter',
      'order_filter': a,
      'limit': 5
    }
    this.authService.getreivewlist(obj).subscribe((response: any) => {
      this.reviewlist = response.data
      this.shownewreview = false
      this.reviewlist.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  
  //=====================getreviewlist24FebAmitStart
  getreviewlist(id:any) {
    let obj = {
      'id': id,
      'role': 'painter',
      'limit': this.limit,
      'offset' : this.offset
    }
    this.authService.getreivewlist(obj).subscribe((response: any) => {
      this.reviewlist = response.data
      this.totalRecords = response.count;
      this.reviewlist.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);
        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
        console.log('rem days', val.days_remaining);
      });
    })
  }

  goToPage(pageNumber: number) {
    console.log('page number-->>>',pageNumber)
   this.offset = (pageNumber - 1) * this.limit;
   this.currentPage = pageNumber;
   this.getreviewlist(this.painterId);
 }

 goToPreviousPage() {
   console.log('offset value in previous-->>>', this.offset);
   if (this.currentPage > 1) {
      this.currentPage--;
      this.offset -= this.limit;
      console.log('offset value in previous-->>>', this.offset);
      this.getreviewlist(this.painterId);
   }
}

goToNextPage() {
   console.log('next page offset ', this.offset);
   if ((this.currentPage * this.limit) < this.totalRecords) {
      this.currentPage++;
      this.offset += this.limit;
      this.getreviewlist(this.painterId);
   }
}

getPageNumbers(): any[] {
  const totalPages = Math.ceil(this.totalRecords / this.limit);
  return Array(totalPages).fill(0).map((x, i) => i + 1);
}
//=====================getreviewlist24FebAmitEnd

  filternew(a: any) {
    console.log(a.target)
  }
  click(e: any) {
    console.log(e)
  }
  getprofile() {
    this.authService.getprofile().subscribe((response: any) => {
      this.profile = response.profileData
    })
  }
  viewdispute() {
    this.makeComplainDialogVisible1dispute = true


  }
  goto() {
    this.editnew = !this.editnew
  }
  clos() {
    this.makeComplainDialogVisible1dispute = false

  }
  makeComplain() { }
  openAcceptBidModal() { }
  checkPendingOrNewStatus(): boolean { return true }
  openDenyBidModal() { }
  closeComplainModal() {
    this.modalFlag = false
    this.modalFlag1 = false
    this.imageobjectarray = []
    this.modalFlagBlock = false
    this.modalFlagreport = false
    this.makeComplainDialogVisible1 = false;
    this.makeComplainDialogVisible = false
    this.makeComplainDialogVisible1dispute = false
  }

  openmodal(a: any) {
    this.modalFlag1 = true
    this.roomdetails = a
    console.log(this.roomdetails)
    for (let i = 0; i < this.roomdetails?.images?.length; i++) {
      this.imageobjectarray.push({
        'image': this.roomdetails.images[i].image,
        'thumbImage': this.roomdetails.images[i].image,
        'title': ''
      })
    }

    //  this.imageobjectarray= this.roomdetails.images
    console.log(this.imageobjectarray)
  }
  getbiddetails(id: any) {
    this.modalFlag = true;
    this.authService.getbidsbyId(id).subscribe((response: any) => {
      this.biddetails = response.bid
      if (this.biddetails.painter_details.profile_image) {
        this.bImage = this.biddetails.painter_details.profile_image

      }

    });
  }
  showroom(id: any) {

  }
  getreporting() {
    let obj = {
      'project_id': this.id,
      'reason': this.reason
    }
    this.authService.getreportingReason().subscribe((response: any) => {
      this.reporting_reason = response.reasons



    })
  }
  getbids() {
    let obj = {
      'project_id': this.id
    }
    this.authService.sortbids(obj).subscribe((response: any) => {
      this.bids = response.bids
      this.approved_bids = response.approved_bid

    })
  }
  getPRojectdetails() {
    let obj = {
      'project_id': this.id
    }
    this.authService.getProjects(obj).subscribe((response: any) => {
      this.projectDetails = response.projects[0]
      console.log('project detailssssss@@@@@',this.projectDetails);
      
      for (let i = 0; i < response.projects[0].images.length; i++) {
        console.log(response.projects[0].images[i])
        this.imageObject.push({
          'image': response.projects[0].images[i].image,
          'thumbImage': response.projects[0].images[i].image,
          'title': ''
        })
      }


      response.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });
      //  this.setPropertyIfNotNullOrEmpty('Other information', this.projectDetails.tags);

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return 0;
  };
  setPropertyIfNotNullOrEmpty(title: string, value: string): void {
    console.log(value)
    if (value != null && value !== '') {
      while (value.includes('_')) {
        value = value.replace('_', ' ');
      }
      if (!value.includes(',')) {
        value = value[0] + value.substr(1).toLowerCase();
      } else {
        value = value.slice(0, value.length - 2);
      }
      this.projectProperties.set(title, value);
    }
  }
  close() {
    this.modalFlag = false
    this.modalFlagreport = false

  }

  acceptbid(id: any, status: any) {
    if (status == 'approved') {
      let obj = {
        'bid_status1': 'approved',
        'id': id,
        "bid_status": "approved",
        'bid_status2': 'expired',
        'project_id': this.id,
        'status': 'approved',
        'type': 'approved'
      }
      this.authService.acceptRejectBidschange(obj).subscribe((response: any) => {
        this.toastr.success('Status updated!', 'Success!')
        this.modalFlag = false
       

        let obj2={
            'project_id' : this.id
        }
        this.authService.changeallbidstatus(obj2).subscribe((response: any) => {

        });
         this.ngOnInit()

      });

    } else {
      let obj = {
        'type': 'disapproved',
        // 'bid_status1': 'disapproved',
        'id': id,
        'project_id': this.id
      }
      this.authService.acceptRejectBidschange(obj).subscribe((response: any) => {
        this.toastr.success('Status updated!', 'Success!')
        this.modalFlag = false
        this.ngOnInit()



      });
    }
  }
  markcomplete(id: any) {
    let obj = {
      'project_id': id,
      'customer_status': 'completed'
    }
    this.authService.changeprojectstatus(obj).subscribe((response: any) => {
      this.toastr.success('Status updated!', 'Success!')
      this.r.navigate(['/home/my-project-card'])



    });
  }
  sendreport() {
    if (!this.reason) {
      return
    } else {
      let obj = {
        'project_id': this.id,
        'reason': this.reason
      }
      this.authService.createreason(obj).subscribe((response: any) => {
        this.toastr.success('Report reason sent!', 'Success!');
        this.modalFlagreport = false
      })
    }

  }

  openComplainModal(): void {

    this.makeComplainDialogVisible = true;

  }

  sendreview(): void {

    this.makeComplainDialogVisible1 = true;

  }

  save() {
    let obj = {
      'customer_status': 'disputed',
      'dispute_reason': this.complaint,
      'project_id': this.id,
      'dispute_option': this.option

    }

    this.authService.disputeproject(obj).subscribe((response: any) => {
      this.toastr.success('Status updated!', 'Success!')
      window.location.reload()
      this.makeComplainDialogVisible = false


    });
  }

  savenew() {
    console.log(this.feedBackform)
    let obj = {
      'rating': localStorage.getItem('rating'),
      'comment': this.feedBackform.value.complaint,
      'project_id': this.id,
      'customer_id': this.projectDetails?.customer_id,
 'painter_id': this.projectDetails?.painter_id
      //'dispute_option': this.option
    }

    this.authService.changereview(obj).subscribe((response: any) => {
      this.toastr.success('Review submitted!', 'Success!')
      // window.location.reload()
      localStorage.removeItem('rating')
      this.r.navigate(['/home/my-project-card'])
      this.makeComplainDialogVisible1 = false



    });
  }
}
