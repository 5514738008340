import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {IDirty} from '../../model/interfaces/IDirty';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {RoutingConfirmDialogService} from './routing-confirm-dialog-service';
import {RoutingConfirmDialogServiceReference} from './routing-confirm-dialog-service-reference';

@Injectable()
export class DirtyGuardService implements CanDeactivate<IDirty> {
  constructor(
    private confirmationDialogService: RoutingConfirmDialogService,
    private confirmationDialogReferenceService: RoutingConfirmDialogServiceReference
  ) { }
  public canDeactivate(
    component: IDirty,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let canLeave: boolean = !component.isDirty();
    if (canLeave === false) {
      canLeave = this.confirmationDialogService.loadComponent(
        component.getRef(),
        nextState.url
      );
      this.confirmationDialogReferenceService.allow = false;
    }
    return canLeave;
  }
}
