import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'defaultAvatar'})
export class DefaultAvatarPipe implements PipeTransform {

  transform(avatar: string): any {
    if (avatar != null && avatar !== '' && avatar !== 'null') {
      return avatar;
    }
    return './assets/log.svg';
  }

}
