import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
  import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  amt: string = '';
  amt1: string = ''
  sucessData: any
  modalFlag: boolean = false;
  error: any;
  price: any;

  constructor(private authService: HttpserviceService, private route: ActivatedRoute, private toastr: ToastrService
    , private router: Router) {


  }

  @ViewChild('paypalRef', { static: true }) private paypalRef: ElementRef | undefined

  ngOnInit(): void {
    //------get credit
    this.authService.getcredit().subscribe(res => {
      this.amt1 = res.data;
      console.log('CREDITTT', res);

    })

    this.renderPayPalButton()


  }


  renderPayPalButton() {
    console.log('Amttt', this.amt1);

    console.log(window.paypal);
    paypal.Buttons({
      style: {
        layout: 'horizontal'
      },
      createOrder: (data: any, action: any) => {

        return action.order.create({
          purchase_units: [
            {
              amount: {
                value: this.amt1,
                currency_code: 'USD'
              }
            }
          ]
        });
      },
      onApprove: (data: any, actions: any) => {
        console.log('onApprove - transaction was approved, but not authorized@@@@', data, actions);
         return actions.order.capture().then((details: any)=> {
        // Iske baad dekho details mein kya aa rha hai capture krne k baad then wo server wali api chla kar save krwa do
        console.log('Payment completed:', details);
    
        actions.order.get().then((details: any) => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
          let obj = {
            'transaction_id': details?.id,
            'amount': details?.purchase_units?.amount?.value,
            'status': details?.status,
            'card_holder_name': details?.payer?.name.given_name + ' ' + details?.payer?.name.surname,
            'transaction_time': details?.create_time
          }

          this.authService.savepayment(obj).subscribe((response: any) => {
            // console.log(this.sucessData);

            if (response.code == 200) {
              this.authService.addCreditIntoPaypal({price:this.amt1}).subscribe((res:any)=>{
                    console.log('credit added to profile', res,'Amt is:',this.amt1);                    
              })
              this.sucessData = response.data;
              this.modalFlag = true;
              console.log('PAYMENT RES', this.sucessData);
              this.router.navigate([`/home/profile`])

              this.toastr.success('Credit added', 'Success!');
              // Close the modal after 5 seconds
              setTimeout(() => {
                this.closeComplainModal();
              }, 5000);

            }

          })
        });
          });
      },
      onError: (err: any) => {
        console.log(err);
      }
    }).render(this.paypalRef?.nativeElement);
  }


 


  closeComplainModal() {
    this.modalFlag = false
  }




}
