 import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NbTabComponent, NbTabsetComponent } from '@nebular/theme';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import firebase from 'firebase/app'
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireMessaging } from '@angular/fire/messaging';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';

import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-my-project-card',
  templateUrl: './my-project-card.component.html',
  styleUrls: ['./my-project-card.component.scss']
})
export class MyProjectCardComponent implements OnInit {

  modalFlag: boolean = false;
  @ViewChild('tabset')
  tabset: any;
  @ViewChild('bidTabset') bidsTabset!: NbTabComponent;
  projects: any = [];
  modalFlagnew: boolean = false
  modalusrdetail: boolean = false
  archiveid: any
  waiting: any
  errorpin: any
  deleteid: any
  // selectedtab: any
  archivedJobs: any = []
  archived: any
  disputed: any
  disputedjobs: any = []
  brushed: any
  completedprojects: any = []
  com_count: any
  completed: any
  projectsapproved: any = []
  brushedproject: any = []
  limit: any = 10
  offset: any = 0
  tokensave: any
  page = 0
  firebaseConfig = {
    apiKey: "AIzaSyBbaKxbqufRCfrwGpQ3sfuBgIifIhTCP1A",
    authDomain: "facebook-clone-f4994.firebaseapp.com",
    projectId: "facebook-clone-f4994",
    storageBucket: "facebook-clone-f4994.appspot.com",
    messagingSenderId: "593047789391",
    appId: "1:593047789391:web:11459d7b291b9465542f3a",
    measurementId: "G-FNW1K23DBJ",

  };
    profileForm!: FormGroup

  currentMessage = new BehaviorSubject(null);
  firebaseApp: any;
  fcmtokennew: any
  selectedtab: any
  // user !: SocialUser
  messaging = firebase.messaging;
  constructor(private authService: HttpserviceService, private fb: FormBuilder, private toastr: ToastrService, private router: Router, private angularFireMessaging: AngularFireMessaging,  private service: AuthService,
    private route: ActivatedRoute,) {

        this.profileForm = this.fb.group({
     // email: ['', [ Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
        name: ['',[Validators.required]],

      profile_image: [null],
      address: ['', [Validators.required, Validators.maxLength(15)]],
      city: [''],
      state: [''],
      zipcode: ['', [Validators.required]],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
    //  address: ['', Validators.required]

    })
  }
  ngAfterViewInit(): void {

  }


  ngOnInit(): void {
  this.selectedtab = 'two'
    if(localStorage.getItem('loginstatus') == 'true' && localStorage.getItem('loginbysocial')=='true'){
  this.modalusrdetail = true
  }
    if (localStorage.getItem('role') == "painter") {
      this.toastr.error('Access denied!', 'Error!');


      let obj = {
        'device_token': localStorage.getItem('fcmtoken'),
        'device_id': localStorage.getItem('device_id')

      }
      this.authService.deteleFcm(obj).subscribe((resp: any) => {
        console.log("token=> deleted ", resp);
        this.authService.logout();
      })

      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    //  console.log(this.tabset)
    // this.tabset.select('tab3');

    //  this.firebaseApp = firebase.initializeApp(this.firebaseConfig);
    // this.getToken();
    if (!localStorage.getItem('fcmtoken')) {
      this.requestPermission();
      this.tokensave = uuidv4()
    }

    //  this.createfcm()

    console.log(uuidv4(), this.tokensave)
    this.getdisputed()
    this.getarchived()
    this.getProjects()
    this.getProjectsapproved();
    this.getbrushed();
    this.getcompletedproj();
    if (localStorage.getItem('currenttab')) {
      this.selectedtab = localStorage.getItem('currenttab')
      console.log(this.selectedtab)
      this.updateRoute({
        step: this.selectedtab,

      })
    } else {
      this.selectedtab = 'one'
    }


    

  }

  archive(id: any) {
    this.archiveid = id
    this.modalFlagnew = true
  }
  getToken() {

    // console.log("this.messaging: ",this.messaging)

    const fcmtoken = this.messaging()
      .getToken()
      .then((mainToken) => {
        return mainToken;
        console.log(mainToken)
      });

    fcmtoken.then((token) => console.log('fcmtoken:::', token)).catch(err => {
      console.error("Get Token: ", err)

    });

  }
  createfcm() {

  }

    checkzip(e: any) {
    // console.log(e, this.location)

   
    this.service.checkzipcode(this.profileForm.value.zipcode).subscribe((res: any) => {
      if (res) {
        this.errorpin = ''
        this.profileForm.controls['latitude'].patchValue(res.response[0].latitude);
        this.profileForm.controls['longitude'].patchValue(res.response[0].longitude);
        this.profileForm.controls['city'].patchValue(res.response[0].city);
        this.profileForm.controls['state'].patchValue(res.response[0].administrativeLevels?.level1long);
        console.log(this.profileForm)
      }
    }, error => {
      console.log(error);
      this.errorpin = 'Please enter valid zip code'
    })
  }

  changePageNumber(event: number): void {
    console.log(event)
    this.page = event - 1;
    console.log(this.page)
    //   this.filter(false);
  }

  delet(id: any) {
    this.deleteid = id
    this.modalFlag = true
  }
  deleteproj() {
    let obj = {
      "project_id": this.deleteid
    }
    this.authService.deleteproject(obj).subscribe((response: any) => {
      this.toastr.success('Project deleted!', 'Success!');
      this.modalFlag = false
      this.getProjects()

    });
  }
  rchiveproject() {
    let obj = {
      "project_id": this.deleteid
    }
    let param = this.archiveid
    this.authService.changesttus(param).subscribe((response: any) => {
      this.toastr.success('Archived successfully!', 'Success!');
      this.modalFlagnew = false
      this.getdisputed()
      this.getarchived()
      this.getProjects()
      this.getProjectsapproved();
      this.getbrushed();
      this.getcompletedproj();

    });
  }
  getProjects() {
    let obj = {
      'limit': this.limit,
      'offset': this.offset,
      'status': 'pending'
    }
    this.authService.getProjects(obj).subscribe((response: any) => {
      this.projects = response.projects
      this.waiting = response.count
      this.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

    changeprofile() {
    console.log(this.profileForm)
   if(this.profileForm.invalid){
          this.profileForm.markAllAsTouched()

          return;
   }
    const formdata = new FormData()
    formdata.append('full_address', this.profileForm?.get('address')?.value)
        formdata.append('full_name', this.profileForm?.get('name')?.value)

   // formdata.append('profile_image', this.selectedFile2)
    formdata.append('phone_number', this.profileForm.value.phone)
    formdata.append('latitude', this.profileForm?.get('latitude')?.value)
    formdata.append('longitude', this.profileForm?.get('longitude')?.value)
    formdata.append('state', this.profileForm?.get('state')?.value)
    formdata.append('location', this.profileForm?.get('location')?.value)

    formdata.append('zip_code', this.profileForm?.get('zipcode')?.value)
  //  formdata.append('stay_logged_in', this.stay_log)
   // formdata.append('send_email', this.semail)

    formdata.append('city', this.profileForm?.get('city')?.value)


    this.authService.updateprofile(formdata).subscribe((response: any) => {
      ///  this.profile= response.profileData
     this.toastr.success(' Updated Successfully!', 'Success!');
     localStorage.removeItem('loginbysocial');
     localStorage.removeItem('loginstatus');
     this.modalusrdetail = false
    //  this.getprofile()
     // window.location.reload();
    })
  }

  yourFunc(e: any, id: any) {

    this.selectedtab = e
    this.updateRoute({
      step: this.selectedtab,
    })
    localStorage.setItem('currenttab', this.selectedtab)
    this.router.navigate(['/home/project-detail/' + id])

  }
  updateRoute(queryParams: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge'
      }
    );
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.fcmtokennew = token
        localStorage.setItem('fcmtoken', this.fcmtokennew)
        localStorage.setItem('device_id', this.tokensave)

        const data = {

          device_token: token,
          device_type: 'web',
          device_id: this.tokensave,

        }
        this.authService.craetFcm(data).subscribe((resp: any) => {
          console.log("createFcmToken=>", resp);

        })


      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }


  getcompletedproj() {
    let obj = {
      'limit': this.limit,
      'offset': this.offset,
      'status': 'completed'
    }
    this.authService.getProjects(obj).subscribe((response: any) => {
      this.completedprojects = response.projects
      this.com_count = response.count
      this.completedprojects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  getbrushed() {
    let obj = {
      'limit': this.limit,
      'offset': this.offset,
      'status': 'brushed'
    }
    this.authService.getProjects(obj).subscribe((response: any) => {
      this.brushedproject = response.projects
      this.brushed = response.count
      this.brushedproject.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  getProjectsapproved() {
    let obj = {
      'limit': this.limit,
      'offset': this.offset,
      'status': 'approved'
    }
    this.authService.getProjects(obj).subscribe((response: any) => {
      this.projectsapproved = response.projects
      this.completed = response.count
      this.projectsapproved.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  getdisputed() {
    let obj = {
      'limit': this.limit,
      'offset': this.offset,
      'status': 'disputed'
    }
    this.authService.getProjects(obj).subscribe((response: any) => {
      this.disputedjobs = response.projects
      this.disputed = response.count
      this.disputedjobs.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  getarchived() {
    let obj = {
      'limit': this.limit,
      'offset': this.offset,
      'status': 'archived'
    }
    this.authService.getProjects(obj).subscribe((response: any) => {
      this.archivedJobs = response.projects
      this.archived = response.count
      console.log('archivedJobs', this.archivedJobs)
      this.archivedJobs.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days archived', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

  closeComplainModal() {
    this.modalFlag = false
    this.modalFlagnew = false
    this.modalusrdetail = false
    localStorage.removeItem('loginbysocial');
     localStorage.removeItem('loginstatus');
  }

}
