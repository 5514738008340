import {Component, OnInit} from '@angular/core';
import {Message} from '../../../../model/Message';
import {EMessageStatus} from '../../../../model/enums/EMessageStatus';
import {MessageService} from '../../../../services/message.service';
import {PageableComponent} from '../../../../components/MAIN_COMPONENTS/PageableComponent';
import {SelectInputOption} from '../../../../model/components/SelectInputOption';
import {ShareDataService} from '../../../../services/data-sharing/share-data.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends PageableComponent implements OnInit {

  orderOptions2: SelectInputOption[] = [
    {optionID: 1, optionLabel: 'Received date: Newest', optionValue: 'time', sortDir: 'DESC', optionDisabled: false, optionSelected: true},
    {optionID: 2, optionLabel: 'Received date: Oldest', optionValue: 'time', sortDir: 'ASC', optionDisabled: false, optionSelected: false},

  ];

  statusOptions: SelectInputOption[] = [
    {optionID: 1, optionLabel: 'Status: All', optionValue: null, optionDisabled: false, optionSelected: true},
    {optionID: 2, optionLabel: 'Status: Unread', optionValue: EMessageStatus.UNREAD, optionDisabled: false, optionSelected: false},
    {optionID: 3, optionLabel: 'Status: Read', optionValue: EMessageStatus.READ, optionDisabled: false, optionSelected: false},

  ];

  constructor(private messageService: MessageService, private sidebarData: ShareDataService) {
    super();
  }

  ngOnInit(): void {
    this.filter(true);
  }

  filter(startFromFirstPage: boolean): void {
    this.messageService.getAllReceivedMessages(
      this.page,
      this.selectedPageSize.optionValue,
      this.selectedStatus.optionValue,
      this.selectedOrdering.optionValue,
      this.selectedOrdering.sortDir).subscribe(res => {
      this.pageableResponse = res;
    });
  }

  changeOrdering(event: any): void {
    this.orderOptions2.forEach(item => {
      if (item.optionLabel === event.optionLabel) {
        item.optionSelected = true;
      } else {
        item.optionSelected = false;
      }
    });
    this.filter(false);
  }

  get selectedOrdering(): any {
    return this.orderOptions2.filter(pt => pt.optionSelected === true)[0];
  }

  markMessageAsRead(message: Message): void {
    this.messageService.changeMessageStatus({messageId: message.messageId, messageStatus: EMessageStatus.READ}).subscribe(res => {
    }, error => {
      console.log(error);
    });
    this.sidebarData.changeCounter(-1);
  }

  deleteMessage(message: Message): void {
    this.messageService.changeMessageStatus({messageId: message.messageId, messageStatus: EMessageStatus.DELETED}).subscribe(res => {
      this.filter(false);
    }, error => {
      console.log(error);
    });
  }
  changeStatuses(event: any): void {
    this.statusOptions.forEach(item => {
      if (item.optionLabel === event.optionLabel) {
        item.optionSelected = true;
      } else {
        item.optionSelected = false;
      }
    });
    this.filter(false);
  }
  get selectedStatus(): any {
    return this.statusOptions.filter(pt => pt.optionSelected === true)[0];
  }

}
