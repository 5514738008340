import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class RoutingConfirmDialogServiceReference {
  private _componentRef: any;
  private _routerState: string;
  private _allow: boolean;

  constructor(
    private router: Router
  ) {

  }

  set componentRef(ref) {
    this._componentRef = ref;
  }

  get componentRef(): any {
    return this._componentRef;
  }

  set allow(allow) {
    this._allow = allow;
  }

  get allow(): any {
    return this._allow;
  }

  set routerState(state) {
    this._routerState = state;
  }

  get routerState(): string {
    return this._routerState;
  }

  public unloadComponent(): void {
    this.componentRef.destroy();
  }

  public destroyComponentAndAllowNavigation(): void {
    this.componentRef.destroy();
    this.router.navigate([this.routerState]);
  }

}
