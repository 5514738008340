<div class="route-title">
    <div class="d-flex">
        <a *ngIf="profile.role=='customer'" class="bck_btn" routerLink="/home/my-project-card">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </a>
        <a *ngIf="profile.role=='painter'" class="bck_btn" routerLink="/home/marketplace">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </a>
        <span>Settings</span>
    </div>
    <app-notification-bell></app-notification-bell>
</div>
<div class="my-profile-route-content">
    <form class="form" [formGroup]="profileForm">
        <section class="setting-section">
            <div class="setting-title">Profile images</div>

            <div class="d-flex align-items-center gap-2 flex_wrap prfle-stng">
                <div class="setting-section-content profile-image-section">
                    <!-- <app-profile-image-chooser></app-profile-image-chooser>-->

                    <!-- <input class="input_class" (change)="fileUpload2($event)" class="text_inputs" type="file"> -->
                    <div class="user_img_container ">


                        <!-- <img [src]="  previewImage2 " style="
      height: 61px;
  "> -->

                        <img [src]="previewImage2" onerror="src='./assets/default.png'" alt="">
                    </div>
                </div>
                <div class="uplod-img">

                    <div class="container">
                        <p class="upld_txt"> Drag n Drop Image or
                            <b>click here</b> to change
                        </p>

                        <input class="input_class" (change)="fileUpload2($event)" class="text_inputs" type="file">

                    </div>
                </div>
            </div>


        </section>

        <section class="setting-section">
            <div class="setting-title">Account Information</div>
            <div class="setting-section-content basic-data respFlexColm">
                <div class="basic-data-info"><span>Type</span> {{profile?.role | titlecase}} </div>
                <div class="basic-data-info"><span>Client ID</span> {{profile?.id}}</div>
                <!-- <div class="basic-data-info"><span>Registration date</span> {{myData.signUpDate | dateOnly}}</div> -->
                <div class="basic-data-info"><span>Registration date</span> {{profile?.sign_up_date | date}}
                </div>
            </div>
        </section>

        <section class="setting-section">
            <div class="setting-title">Preferences</div>
            <div class="d-flex  flex_wrap prefrencChecks">
                <div class="d-flex  checkbox_div">
                    <input type="checkbox" [(ngModel)]="stay_logged_in" (click)="click($event)"
                        [ngModelOptions]="{standalone: true}"> &nbsp;&nbsp;
                    <label>Stay signed in until logout</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
                <div class="d-flex  checkbox_div" *ngIf="profile.role=='customer'">
                    <!-- customer   -->
                    <input type="checkbox" (click)="clicka($event)" [(ngModel)]="send_email"
                        [ngModelOptions]="{standalone: true}">&nbsp;&nbsp;<label>Send me an e-mail when there <br> are
                        new bids for
                        my
                        project</label>
                </div>
                <div class="d-flex checkbox_div" *ngIf="profile.role=='painter'">
                    <!-- painter -->
                    <input type="checkbox" (click)="clicka($event)" [(ngModel)]="send_email"
                        [ngModelOptions]="{standalone: true}">&nbsp;&nbsp;<label>Send me an e-mail when there <br> is a
                        new project  <p *ngIf ="savedstate"> within {{savedstate}} </p>
                    </label>
                </div>
            </div>
        </section>

        <section class="setting-section">
            <div class="setting-title">Phone</div>
            <div class="setting-section-content inputs mb_30">
                <div class="input-wrapper input-wrapper-without-image">
                    <input formControlName="phone" maxLength="15"
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        placeholder="(123)-456-7890">
                    <div class="error-label"
                        *ngIf="!profileForm.controls['phone'].valid && (profileForm.controls['phone'].touched)">
                        <p class="error-label" *ngIf="profileForm.controls['phone'].hasError('required')">
                            <a class="error-wrp">
                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div class="setting-title">Email</div>
            <div class="setting-section-content inputs">
                <div class="input-wrapper input-wrapper-without-image">
                    <input formControlName="email"
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                        placeholder="enter email">
                    <div class="error-label"
                        *ngIf="!profileForm.controls['email'].valid && (profileForm.controls['email'].touched)">
                        <p class="error-label" *ngIf="profileForm.controls['email'].hasError('required')">
                            <a class="error-wrp">
                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                            </a>
                        </p>

                        <p class="error-label" *ngIf="profileForm.controls['email'].hasError('pattern')">
                            <a class="error-wrp">
                                enter valid email
                            </a>
                        </p>
                    </div>




                </div>
            </div>
        </section>
        <section class="setting-section">
            <form [formGroup]="passwordform">
                <div class="setting-title">Password</div>
                <div class="d-flex pswdstng_wrp">
                    <div class="flds_err">
                        <div class="setting-section-content inputs passwords">
                            <div class="input-wrapper input-wrapper-without-image">
                                <div class="input-with-hint">
                                    <input placeholder="Current Password" [type]="Cpass" formControlName="currPswd"
                                        type="password">
                                    <span *ngIf="Cpass == 'text'" class="hint"
                                        (click)="onEyeClick('Cpass', 'password')">
                                        <a class="hint-text">Show</a>
                                    </span>
                                    <span *ngIf="Cpass == 'password'" class="hint"
                                        (click)="onEyeClick('Cpass', 'text')">
                                        <a class="hint-text">Show</a>
                                    </span>
                                </div>
                                <span
                                    *ngIf="(passwordform.get('currPswd')?.touched || passwordform.get('currPswd')?.dirty) && passwordform.get('currPswd')?.invalid && passwordform.get('currPswd')?.errors?.required"
                                    class="err_msg">
                                    *Please enter password
                                </span>
                                <span
                                    *ngIf="(passwordform.get('currPswd')?.touched || passwordform.get('currPswd')?.dirty) && passwordform.get('currPswd')?.invalid  && passwordform.get('currPswd')?.errors?.pattern"
                                    class="err_msg">
                                    *Please enter valid password
                                </span>

                            </div>
                            <div class="input-wrapper input-wrapper-without-image">
                                <div class="input-with-hint">
                                    <input [type]="pass" formControlName="newPswd" class="email"
                                        placeholder="Enter New Password">
                                    <span class="hint" *ngIf="pass == 'text'" (click)="onEyeClick('pass', 'password')">
                                        <a class="hint-text">Show</a>
                                    </span>
                                    <span class="hint" *ngIf="pass == 'password'" (click)="onEyeClick('pass', 'text')">
                                        <a class="hint-text">Show</a>
                                    </span>
                                </div>
                                <!-- <span class="hint">
                        <a class="hint-text">Hide</a>
                      </span> -->
                                <span
                                    *ngIf="(passwordform.get('newPswd')?.touched || passwordform.get('newPswd')?.dirty) && passwordform.get('newPswd')?.invalid && passwordform.get('newPswd')?.errors?.required"
                                    class="err_msg">
                                    *Please enter new password
                                </span>
                                <span
                                    *ngIf="(passwordform.get('newPswd')?.touched || passwordform.get('newPswd')?.errors?.required || passwordform.get('newPswd')?.dirty) && passwordform.get('newPswd')?.invalid  && passwordform.get('newPswd')?.errors?.pattern"
                                    class="err_msg">
                                    *Please enter valid password
                                </span>
                                <span
                                    *ngIf="(passwordform.get('newPswd')?.touched && passwordform.get('currPswd')?.touched) && (passwordform.get('newPswd')?.value)==(passwordform.get('currPswd')?.value) && passwordform.get('currPswd')?.valid"
                                    class="err_msg">
                                    *New password must not match with old password
                                </span>
                                <!-- <small id="emailHelp" class="form-text text-pink">
                        Password must contain at least 8 characters, including upper/lowercase and numbers
                      </small> -->



                            </div>
                            <div class="input-wrapper input-wrapper-without-image">
                                <div class="input-with-hint">
                                    <input [type]="confirmPass" formControlName="conPswd"
                                        placeholder="Repeat New Password" type="password">
                                    <span class="hint" *ngIf="confirmPass == 'text'"
                                        (click)="onEyeClick('confirmPass', 'password')">
                                        <a class="hint-text">Show</a>
                                    </span>
                                    <span class="hint" *ngIf="confirmPass == 'password'"
                                        (click)="onEyeClick('confirmPass', 'text')">
                                        <a class="hint-text">Show</a>
                                    </span>
                                </div>
                                <span
                                    *ngIf="(passwordform.get('conPswd')?.touched || passwordform.get('conPswd')?.dirty) && passwordform.get('conPswd')?.invalid && passwordform.get('conPswd')?.errors?.required"
                                    class="err_msg">
                                    *Please enter confirm password
                                </span>
                                <span
                                    *ngIf="(passwordform.get('conPswd')?.touched || passwordform.get('conPswd')?.dirty) && passwordform.hasError('pwdNotSame') && !passwordform.get('conPswd')?.errors?.required"
                                    class="form-text text-pink">
                                    *Password does not match
                                </span>
                            </div>
                        </div>
                        <div class="error-wrp" *ngIf="error">
                            {{error}}
                        </div>
                    </div>


                    <button class="custom-button reset-password-button" (click)="submitForm()" type="submit"
                        [disabled]="!passwordform.valid">Reset
                    </button>


                </div>


            </form>
        </section>


        <section class="setting-section">
            <div class="setting-title">Address</div>
            <div class="setting-section-content inputs addresses">
                <div class="input-wrapper input-wrapper-without-image Addrs">
                    <input formControlName="address" placeholder="4958 Ethels Lane">
                    <div class="error-label"
                        *ngIf="!profileForm.controls['address'].valid && (profileForm.controls['address'].touched)">
                        <p class="error-label" *ngIf="profileForm.controls['address'].hasError('required')">
                            Please enter address
                        </p>
                    </div>
                </div>
                <!--  <div class="input-wrapper input-wrapper-without-image">
                    <input formControlName="city" placeholder="Tampa">
                    <div class="error-label"
                        *ngIf="!profileForm.controls['city'].valid && (profileForm.controls['city'].touched)">
                        <p class="error-label" *ngIf="profileForm.controls['city'].hasError('required')">
                            Please enter city
                        </p>
                    </div>
                </div>-->

                <!-- <input formControlName="state" placeholder=" Florida">
                    <div class="error-label"
                        *ngIf="!profileForm.controls['state'].valid && (profileForm.controls['state'].touched)">
                        <p class="error-label" *ngIf="profileForm.controls['state'].hasError('required')">
                            Please enter state
                        </p>
                    </div>-->
                <!--	<input type="text" name="" class="form-control" placeholder="Location" formControlName="location" 
                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                    ngx-google-places-autocomplete  #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" (input)="clearLocation()" >
                    <input type="hidden" formControlName="latitude">
                        <input type="hidden" formControlName="longitude">

                         <div class="error-label"
                        *ngIf="!profileForm.controls['location'].valid && (profileForm.controls['location'].touched)">
                        <p class="error-label" *ngIf="profileForm.controls['location'].hasError('required')">
                            Please enter location
                        </p>
                    </div>-->

                <div class="input-wrapper input-wrapper-without-image zipAddrs">
                    <input formControlName="zipcode" placeholder="33602" maxlength="6" minlength="3"
                        (keyup)="checkzip($event)"
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                    <div class="error-label" *ngIf="errorpin">
                        <p class="error-label" *ngIf="errorpin">
                            {{errorpin}}
                        </p>
                    </div>
                </div>
                <div class="input-wrapper input-wrapper-without-image">
                    <input formControlName="city" placeholder="City" readonly
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">

                </div>
                <div class="input-wrapper input-wrapper-without-image">
                    <input formControlName="state" placeholder="State" readonly
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">

                </div>
            </div>
        </section>

        <div class="text-left btn-div d-flex">
            <button class="custom-button single-button" (click)="changeprofile()">Save changes
            </button>
            <button class="border_button single-button dltprfl_btn" (click)="showodal();">Delete Profile
            </button>

        </div>

    </form>

</div>



<app-modal class="project_report_mdl dlt_prfl_mdl" [displayModal]="modalFlag"
    (cancelButtonClicked)="closeComplainModal()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <p class="title exchng_clr">Delete Profile?</p>
                <p>
                    Are you sure you want to delete the profile?
                </p>
                <div class="d-flex dbl_btns">
                    <button class="custom-button" (click)="deleteacc()">Delete</button>
                    <button class="border_button" (click)=" this.modalFlag= false">Cancel</button>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>