<div class="main-container">
  <div class="container expanded" id="sidebar" #sidebar (window:resize)="calculateSideBarHeight()">
    <div class="relative-content">
      <ng-content select="[hide-or-expand]" class="hide-or-expand-content"></ng-content>
      <div class="logo-content">
        <div class="logo-wrapper">
          <img src="assets/logo-new.png" class="logo">
        </div>
      </div>


      <div class="user-content"  *ngIf="isPainter" [routerLink]="'/home/profile'">
        <div class="avatar-wrapper">
          <!-- <img src="{{profile?.profile_image!='undefined' ? path + profile?.profile_image :  'assets/default.png'}}" alt="avatar" class="avatar-image"> -->
          <img src="{{profile?.profile_image}}" onerror="src='assets/default.png'" alt="avatar" class="avatar-image">
        </div>
        <div class="usr-data">
          <div class="full-name">
            <!-- <span class="first-name">{{ userData.fullName | firstName }}&nbsp;</span>
            <span class="last-name">{{ userData.fullName | lastName }}</span> -->

            <span class="first-name">{{profile?.full_name}} &nbsp;</span>
          <!--  <span class="last-name">Bansal</span>-->
          </div>
          <!-- <div class="email">{{userData.email}}</div> -->

          <div class="email">{{profile?.email}}</div>
        </div>
      </div>
      <div class="user-content"  *ngIf="isCustomer" [routerLink]="'/home/settings'">
        <div class="avatar-wrapper">
          <!-- <img src="{{profile?.profile_image!='undefined' ? path + profile?.profile_image : 'assets/default.png'}}" alt="avatar" class="avatar-image"> -->
          <img src="{{ profile?.profile_image}}" onerror="src='assets/default.png'" alt="avatar" class="avatar-image">

        </div>
        <div class="usr-data">
          <div class="full-name">
            <!-- <span class="first-name">{{ userData.fullName | firstName }}&nbsp;</span>
            <span class="last-name">{{ userData.fullName | lastName }}</span> -->

            <span class="first-name">{{profile?.full_name}} &nbsp;</span>
          <!--  <span class="last-name">Bansal</span>-->
          </div>
          <!-- <div class="email">{{userData.email}}</div> -->

          <div class="email">{{profile?.email}}</div>
        </div>
      </div>

      <div class="nav-content">

        <ul class="navigation-list" #navRoutingSection>
          <li *ngIf="isPainter" class="navigation-item" [routerLink]="'/home/marketplace'" routerLinkActive='active'>
            <nb-icon pack="fas" icon="tasks" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">Projects</span>
          </li>
          <li *ngIf="isPainter" class="navigation-item" [routerLink]="'/home/my-bids'" routerLinkActive='active'>
            <nb-icon pack="fas" icon="user" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">My Bids</span>
            <!-- <li *ngIf="isCustomer" class="navigation-item" [routerLink]="'/home/new-project'" routerLinkActive='active'>
            <nb-icon pack="fas" icon="plus-square" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">Create Project</span></li> -->
          <li *ngIf="isCustomer" class="navigation-item" [routerLink]="'/home/my-project-card'"
            routerLinkActive='active'>
            <nb-icon pack="fas" icon="tasks" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">Projects</span>
          <li *ngIf="isPainter" class="navigation-item" [routerLink]="'/home/my-jobs'" routerLinkActive='active'>
            <nb-icon pack="fas" icon="brush" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">My Jobs</span>
          </li>
          <li *ngIf="isPainter" class="navigation-item" [routerLink]="'/home/my-profile'" routerLinkActive='active'>
            <nb-icon pack="fas" icon="address-card" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">My Profile</span>
          </li>
          <li *ngIf="isPainter" class="navigation-item" [routerLink]="'/home/chat'"
            routerLinkActive='active'>
            <nb-icon pack="fas" icon="comments" class="nav-icon"></nb-icon>
            <span
              [ngClass]="messageService.informAboutNewChatMessage() ? 'unread navbar-route-title' : 'navbar-route-title'">Messages
              {{messageService.informAboutNewChatMessage() ? '(' + messageService.getUnreadConversationCountOrZero() +
              ')' : ''}}</span>
          </li>
            <li *ngIf="isCustomer" class="navigation-item" [routerLink]="'/home/cus-chat'"
            routerLinkActive='active'>
            <nb-icon pack="fas" icon="comments" class="nav-icon"></nb-icon>
            <span
              [ngClass]="messageService.informAboutNewChatMessage() ? 'unread navbar-route-title' : 'navbar-route-title'">Messages
              {{messageService.informAboutNewChatMessage() ? '(' + messageService.getUnreadConversationCountOrZero() +
              ')' : ''}}</span>
          </li>

          <li *ngIf="isAdmin" class="navigation-item" [routerLink]="'/home/admin'" routerLinkActive='active'>
            <nb-icon pack="fas" icon="user-cog" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">Admin Dashb.</span>
          </li>
          <li *ngIf="isCustomer || isPainter" class="navigation-item" [routerLink]="'/home/settings'"
            routerLinkActive='active'>
            <nb-icon pack="fas" icon="cog" class="nav-icon"></nb-icon>
            <span class="navbar-route-title">Settings</span>
          </li>
        </ul>
      </div>

      <!--   <div class="navigation-item active bottom-content" routerLink="/home/my-project-card">
        <nb-icon pack="fas" icon="list-check" class="nav-icon"></nb-icon>
        <span class="navbar-route-title">Projects</span>
      </div> -->
      <!-- <div class="navigation-item bottom-content" routerLink="/home/my-profile-edit-user">
        <nb-icon pack="fas" icon="sign-out" class="nav-icon"></nb-icon>
        <span class="navbar-route-title">My profile</span>
      </div> -->
      <!--   <div class="navigation-item bottom-content" routerLink="/home/chat">
        <nb-icon pack="fas" icon="comments" class="nav-icon"></nb-icon>
        <span class="navbar-route-title">Messages</span>
      </div> -->
      <!-- <div class="navigation-item bottom-content" routerLink="/home/change-password">
        <nb-icon pack="fas" icon="sign-out" class="nav-icon"></nb-icon>
        <span class="navbar-route-title">Change password</span>
      </div> -->
      <!--   <div class="navigation-item bottom-content" routerLink="/home/settings">
        <nb-icon pack="fas" icon="gear" class="nav-icon"></nb-icon>
        <span class="navbar-route-title">Settings</span>
      </div>-->
      <div class="navigation-item bottom-content m-auto" (click)="logout()">
        <nb-icon pack="fas" icon="sign-out" class="nav-icon"></nb-icon>
        <span class="navbar-route-title">Log Out</span>
      </div>

    </div>
  </div>
</div>