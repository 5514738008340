import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NotificaitonsShareService} from '../../../services/data-sharing/NotificaitonsShareService';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '../../../services/project.service';
import {Room} from '../../../model/Room';
import {NbTabComponent, NbTabsetComponent} from '@nebular/theme';
import {TokenStorageService} from '../../../services/token-storage.service';
import {EJobType} from '../../../model/enums/EJobType';
import {EProjectType} from '../../../model/enums/EProjectType';
import {EPostingNewProjectWay} from '../../../model/enums/EPostingNewProjectWay';
import * as moment from 'moment';

import {HttpserviceService} from '../../../services/httpservice.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('tabset') tabset!: NbTabsetComponent;
  @ViewChild('bidTabset') bidsTabset!: NbTabComponent;


  constructor(public tokenStorage: TokenStorageService, private sharedService: NotificaitonsShareService, private activatedRoute: ActivatedRoute, private projectService: ProjectService, private router: Router, private authService: HttpserviceService) {
  }
  id: any
  projectDetails: any
  @Input() project: any;
  @Input() ifPainter = false;
  @Input() laborCost!: number;
  @Output() bidStatusChanged = new EventEmitter();
  @Output() placeBid = new EventEmitter();
  @Output() viewPainterProfile = new EventEmitter();

  isMainActive = true;
  isBidsActive = false;
  isRoomsActive = false;
  EPostingNewProjectWay = EPostingNewProjectWay;
  roles = {
    admin: false,
    customer: false,
    painter: false
  };

  ngOnInit(): void {
   this.id= this.activatedRoute.snapshot.params.id
  console.log(this.activatedRoute.snapshot.params.id)
  this.getPRojectdetails()
    this.processPermissions();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //  this.loadProjectData();
    const subscription = this.sharedService.notificationType.subscribe(log => {
      this.prepareFlags(log);

    });
   

  }
    getPRojectdetails(){
   this.authService.getProjectsdetails(this.id).subscribe((response : any) => {
         this.projectDetails= response.projects[0]

          response.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = deadline_date.diff(current_date, 'days');
        
        console.log('rem days', val.days_remaining);
        	

				
      });
        
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }

  processPermissions(): void {
    this.roles.admin = this.tokenStorage.isUserAdmin();
    this.roles.customer = this.tokenStorage.isUserCustomer();
    this.roles.painter = this.tokenStorage.isUserPainter();
  }

  ngAfterViewInit(): void {
    const bidTag = this.activatedRoute.snapshot.paramMap.get('bidId');
    if (bidTag != null) {
      const int = setInterval(() => {
        if (this.tabset != null && this.bidsTabset != null) {
          this.tabset.selectTab(this.bidsTabset);
          clearInterval(int);
        }
      }, 200);
    }

  }

  loadProjectData(): void {
    const redirectValue = this.activatedRoute.snapshot.paramMap.get('projectType');
    const tag = this.activatedRoute.snapshot.paramMap.get('tag');
    this.projectService.getProjectWithFullDetailsByBasicData(redirectValue as string, tag as string).subscribe(data => {
      this.project = data;
      console.log(this.project);
      this.calculatePrice(this.project.rooms);
    });
  }

  navigateBack(): void {
    if (this.roles.customer) {
      this.router.navigateByUrl('/home/my-projects');
    } else if (this.roles.painter) {
      this.router.navigateByUrl('/home/marketplace');
    }  else if (this.roles.admin) {
      this.router.navigateByUrl('/home/admin');
    }
  }

  calculatePrice(rooms: Room[]): void {
    if (rooms != null) {
      rooms.forEach(room => {
        this.laborCost = this.laborCost + room.laborCost;
      });
    }
  }

  prepareFlags(notificaiton: string): void {
    this.isMainActive = false;
    this.isBidsActive = false;
    this.isRoomsActive = false;
    console.log(notificaiton);
    switch (notificaiton) {
      case 'BID_ACCEPTED_NOTIFICATION':
        this.isBidsActive = true;
        break;
      case 'BID_CANCELED_NOTIFICATION':
        this.isBidsActive = true;
        break;
      case 'NEW_BID_NOTIFICATION':
        console.log('here');
        this.isBidsActive = true;
        break;
      case 'NEW_PROJECT_NOTIFICATION':
        this.isMainActive = true;
        break;
      case 'NEW_PROJECT_IN_AREA_NOTIFICATION':
        this.isMainActive = true;
        break;
    }
  }

  ngOnDestroy(): void {
    this.sharedService.notificationType.unsubscribe();
  }
  actionOnBidStatusChange(): void {
    this.loadProjectData();
    this.bidStatusChanged.emit();
  }
}
