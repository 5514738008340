import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ShareDataService} from '../../../services/data-sharing/share-data.service';

@Component({
  selector: 'app-top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.scss']
})
export class TopPanelComponent implements OnInit, OnDestroy, AfterViewInit {


  @Input() extraButton: boolean;
  @Input() extraButtonText: string | undefined;
  @Input() searchText: string | undefined;
  @Input() searchInput: boolean;

  @Output() extraButtonClicked = new EventEmitter();

  @ViewChild('barwrapper') barMenuWrapper!: ElementRef;

  isSidebarCollapsed!: boolean;
  isSidebarExpanded!: boolean;

  constructor(private sidebarData: ShareDataService) {
    this.extraButton = false;
    this.searchInput = false;
  }

  ngOnInit(): void {
   // this.sidebarData.isCollapsed.subscribe(status => this.handleCollapse(status as boolean));
    // this.sidebarData.isExpanded.subscribe(status => this.handleExpand(status as boolean));
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
  }

  onExtraButtonClicked(): void {
    this.extraButtonClicked.emit();
  }

  handleExpand(ifExpand: boolean): void {
    this.isSidebarExpanded = ifExpand;
    if (ifExpand) {
      this.barMenuWrapper.nativeElement.classList.add('active');
    } else {
      this.barMenuWrapper.nativeElement.classList.remove('active');
    }
  }

  handleCollapse(ifCollapsed: boolean): void {
    this.isSidebarCollapsed = ifCollapsed;
    if (!ifCollapsed) {
      this.barMenuWrapper.nativeElement.classList.remove('active');
    }
  }

  makeSidebarFixed(): void {
   // this.emitCollapseEvent(false);
   // this.emitExpandEvent(false);
  }

  leavingDown(e: any): void {
  //  this.emitExpandEvent(e);
  }


  emitExpandEvent(isSidebarExpanded: boolean): void {
   // this.sidebarData.changeIsExpanded(isSidebarExpanded);
  }

  emitCollapseEvent(isSidebarCollapsed: boolean): void {
   // this.sidebarData.changeIsCollapsed(isSidebarCollapsed);
  }

}
