import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Room} from '../../../model/Room';
import {ERoomType} from '../../../model/enums/ERoomType';
import {ProjectService} from '../../../services/project.service';


@Component({
  selector: 'app-room-card',
  templateUrl: './room-card.component.html',
  styleUrls: ['./room-card.component.scss']
})
export class RoomCardComponent implements OnInit {
  ERoomType = ERoomType;
  @Input() room!: Room;
  roomEstimatedPrice$!: number;
  deleteRoomModalVisible!: boolean;
  lowerBound!: any;
  upperBound!: any;
  @Output() edit = new EventEmitter();
  @Output() deleteRoom = new EventEmitter();
  @Output() copy = new EventEmitter();

  constructor(public projectService: ProjectService) {


  }

  delete(): void {
    this.deleteRoom.emit();
  }

  editRoom(): void {
    this.edit.emit();
  }

  duplicate(): void {
    this.copy.emit();
  }

  ngOnInit(): void {
    if (this.room) {
      this.projectService.calculateSingleRoomPrice(this.room).subscribe(response => {
        this.roomEstimatedPrice$ = response;
        //fixme: hardcoded
        this.lowerBound = Math.round(this.roomEstimatedPrice$ * 1.04);
        this.upperBound = Math.round(this.roomEstimatedPrice$ * 1.56);
      });
    }
  }
}
