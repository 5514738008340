<div class="main-box">
  <ng-template [ngIf]="!isPinSubmitted">
    <ng-template [ngIf]="!anotherConfirmationRequested">
      <div class="title margin-b10">We are almost there!</div>
      <img src="assets/illustrations/register-confirmation/shipping.svg" class="img-xl">
      <div class="hint-small">We've sent a code <br> to your <span *ngIf="ifEmailConfirmation">email address</span><span
        *ngIf="!ifEmailConfirmation">phone number</span>.
      </div>
      <div class="hint-xsmall margin-b10"><b>Please enter it below</b></div>
      <div class="hint-small margin-b10" *ngIf="queryParams?.confirmationParam && !isPinWrong">If you have not received
        <span *ngIf="ifEmailConfirmation">email</span> <span *ngIf="!ifEmailConfirmation">SMS</span> click <a
          class="hint-color" (click)="sendAnotherPin()">HERE</a></div>
      <div class="input-wrapper">
        <span class="icon-wrapper" *ngIf="isPinWrong"><img src="assets/icons/errors/error-solid.svg"
                                                           class="icon"></span>
        <input (input)="pinInputChange($event)" [(ngModel)]="pin" type="number" maxlength="5" placeholder="*****"
               [ngClass]="isPinWrong ? 'code-input margin-b15 border-red' : 'code-input margin-b15'">
        <div class="error-wrp" *ngIf="isPinWrong">
          <img src="assets/icons/errors/error-solid.svg" class="error-icon">
          <div class="error-text">Invalid PIN</div>
        </div>
      </div>
      <div class="buttons">
        <button class="custom-button" *ngIf="isPinWrong" (click)="sendAnotherPin()">Send me a new PIN</button>
        <button class="custom-button" [disabled]="confirmButtonDisabled()" (click)="validatePin()">Submit</button>
      </div>
    </ng-template>


    <ng-template [ngIf]="anotherConfirmationRequested">
      <div class="title">Confirm your <span *ngIf="ifEmailConfirmation">email</span> <span *ngIf="!ifEmailConfirmation">number</span>
      </div>
      <img
        [src]="ifEmailConfirmation ? '/assets/illustrations/register-confirmation/newsletter.svg' : '/assets/illustrations/register-confirmation/contact.svg'"
        class="img-xl">
      <span *ngIf="ifEmailConfirmation" class="hint">Please check your inbox at: </span>
      <span *ngIf="!ifEmailConfirmation" class="hint">Please check your SMS inbox</span>
      <div class="data margin-b10"><span *ngIf="ifEmailConfirmation">{{queryParams?.confirmationParam}}</span> <span
        *ngIf="!ifEmailConfirmation">{{queryParams?.confirmationParam | phoneNumber}}</span></div>
      <div class="hint-small margin-b10">We have sent another
        <span *ngIf="ifEmailConfirmation"> email to provided address. Remember to check your spam folder. If it won't appear in few minutes please repeat procedure.</span>
        <span *ngIf="!ifEmailConfirmation">SMS to provided number. If it won't appear in 3 minutes, please repeat procedure.</span>
      </div>
      <button class="custom-button" (click)="anotherConfirmationRequestedSubmit()">Go back</button>

    </ng-template>
  </ng-template>

  <ng-template [ngIf]="isPinSubmitted">
    <ng-template [ngIf]="isPinValid">
      <div class="title margin-b10">Awesome!</div>
      <img src="assets/illustrations/register-confirmation/special.svg" class="img-xxl margin-b10">
      <div class="hint-small"><b>Your <span *ngIf="ifEmailConfirmation">email</span><span *ngIf="!ifEmailConfirmation">phone number</span>
        has been <br> successfully confirmed.</b></div>
      <div class="hint-xsmall margin-b10 margin-t25">You can now login using your credentials and start your journey
        with brush!
      </div>
      <button class="custom-button margin-b10" (click)="navigateToLogin()">Login</button>
    </ng-template>

    <ng-template [ngIf]="!isPinValid">
      <div class="title margin-b10">Oooooopss!!</div>
      <img src="assets/illustrations/register-confirmation/error.svg" class="img-xxl">
      <div class="hint-small">Something went wrong!</div>
      <div class="hint-xsmall margin-b10">It seems that we were not able to confirm your <span
        *ngIf="ifEmailConfirmation">email address</span> <span *ngIf="!ifEmailConfirmation">phone number</span>. <br>
        <b>We highly recommend you to press button below to receive new PIN </b></div>
      <button class="custom-button margin-b10" (click)="sendAnotherPin()">Send new PIN</button>
    </ng-template>
  </ng-template>
</div>
