import {Directive, ElementRef, EventEmitter, HostBinding, HostListener, OnInit, Output, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[appMouseDiretionDetection]'
})
export class MouseDirectionDirective implements OnInit {

  constructor(private view: ViewContainerRef) {
  }


  ngOnInit(): void {
    this.component = this.view.element.nativeElement;
  }

  // @HostBinding() element!: ElementRef;
  @Output() mouseLeftDown = new EventEmitter();
  component: any;


  @HostListener('mouseleave', ['$event'])
  public onListenerTriggered(event: any): void {
    const elemBounding = this.component.getBoundingClientRect();
    let template = '';
    const elementLeftEdge = elemBounding.left;
    const elementTopEdge = elemBounding.top;
    const elementRightEdge = elemBounding.right;
    const elementBottomEdge = elemBounding.bottom;

    const mouseX = event.pageX;
    const mouseY = event.pageY;

    const topEdgeDist = Math.abs(elementTopEdge - mouseY);
    const bottomEdgeDist = Math.abs(elementBottomEdge - mouseY);
    const leftEdgeDist = Math.abs(elementLeftEdge - mouseX);
    const rightEdgeDist = Math.abs(elementRightEdge - mouseX);

    const min = Math.min(topEdgeDist, bottomEdgeDist, leftEdgeDist, rightEdgeDist);

    switch (min) {
      case leftEdgeDist:
        template = 'left';
        break;
      case rightEdgeDist:
        template = 'right';
        break;
      case topEdgeDist:
        template = 'top';
        break;
      case bottomEdgeDist:
        template = 'bottom';
        break;
    }
    if (template === 'bottom') {
      this.mouseLeftDown.emit(true);
    } else {
      this.mouseLeftDown.emit(false);
    }
  }


}
