import {Pipe, PipeTransform} from '@angular/core';
import {EBidStatus} from '../model/enums/EBidStatus';

@Pipe({name: 'enumPipe'})
export class EnumPipe implements PipeTransform {

  transform(value: any, enumClass: any, capitalizeFirstLetterOnly?: boolean, secondWordOnly?: boolean): any {
    if (enumClass[value] != null) {
      const returnValue = (enumClass[value] as string).split('_').join(' ');
      if (!capitalizeFirstLetterOnly && !secondWordOnly) {
        return returnValue;
      }
      const words = returnValue.split(' ');
      if (words.length > 1 && secondWordOnly && capitalizeFirstLetterOnly) {
        return words[1].charAt(0).toUpperCase() + words[1].slice(1).toLowerCase();
      }
      if (words.length > 1 && secondWordOnly && !capitalizeFirstLetterOnly) {
        return words[1];
      }
      if(words.length === 1) {
        return words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
      }
      let finalWord = '';
      for (const word of words) {
        finalWord += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
      }
      finalWord = finalWord.trim();
      return finalWord;
    }
    return '';
  }
}
