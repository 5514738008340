import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {SelectInputOption} from '../../../model/components/SelectInputOption';
import {SimpleDropDownComponentItem} from '../../../model/components/SimpleDropDownComponentItem';


@Component({
  selector: 'app-simple-drop-down-component',
  templateUrl: './simple-drop-down.component.html',
  styleUrls: ['./simple-drop-down.component.scss']
})
export class SimpleDropDownComponent implements OnInit, OnChanges {

  constructor() {
  }

  @Input() items!: SimpleDropDownComponentItem [];
  @Input() placeHolderOrValue: any;

  @Output() valueChanged = new EventEmitter();
  @ViewChild('optionsContainer') container!: ElementRef;

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    if (this.items != null) {
      this.items = this.items.filter(x => x.visible || x.visible === undefined);
    }
  }

  toggleInput(event: any): void {
    this.container.nativeElement.classList.toggle('active');
  }

  optionSelected(option: any, event: any): void {
    this.container.nativeElement.classList.remove('active');
    this.placeHolderOrValue = option.label;
    this.valueChanged.emit(option);
    event.preventDefault();
    event.stopPropagation();
  }


  onMouseLeave(): void {
    this.container.nativeElement.classList.remove('active');
  }

}
