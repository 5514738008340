<div class="contact-wrapper" *ngIf="user">
  <div class="contact-header">
    <div class="avatar">
      <img src="{{user.profileImage | defaultAvatar}}">
    </div>
    <div class="info">
      <div class="full-name">{{user.userPersonal.fullName}}</div>
      <div class="address">
        <img src="assets/icons/contact/localization.png" alt="localization">
        <div class="address-text">Hypoluxo 33462 , Florida</div>
      </div>
      <app-painter-rating class="rating" *ngIf="isUserPainter" [totalCommentsForPainter]="totalCommentsForPainter"
        [avgRating]="user.avgRating">
      </app-painter-rating>
    </div>
  </div>
  <div class="main-info">
    <div class="title">Contact Details</div>
    <div *ngIf="detailedContactAvailable">
    <div class="contact-detail">
      <img src="assets/icons/contact/phone.png" alt="phone">
      <div class="contact-data">{{user.userPersonal.phoneNumber}}</div>
    </div>
    <div class="contact-detail">
      <img src="assets/icons/contact/email.png" alt="phone">
      <div class="contact-data">{{user.email}}</div>
    </div>
    <div class="contact-detail">
      <img src="assets/icons/contact/localization.png" alt="phone">
      <div class="contact-data">Hypoluxo 33462 , Florida</div>
    </div>
    </div>
    <div *ngIf="!detailedContactAvailable" class="contact-not-available">Detailed contact is not available for a now.</div>
    <div class="brushing-since">
      Brushing since {{user.signUpDate | dateOnly}}
    </div>
    <div class="report" *ngIf="this.tokenStorage.isUserCustomer()">
      <span *ngIf="!reportPresent" (click)="reportModalVisible = true" class="report-hoverable">Report This Painter</span>
      <span *ngIf="reportPresent">You've reported this painter</span>
    </div>
    <ng-content select="[additional-info]"></ng-content>
  </div>
</div>
<app-modal [displayModal]="reportModalVisible"
           [title]="'Report Project'"
           [subclaim]="'Select reason for reporting this project'"
           [confirmButtonText]="'Send Report'"
           [confirmButtonDisabled]="selectedReportReason == null"
           (confirmButtonClicked)="sendReport()"
           (cancelButtonClicked)="reportModalVisible = false">
  <slot-content>
    <app-simple-drop-down-component [placeHolderOrValue]="'Select Reason'" [items]="reportReasons"
                                    (valueChanged)="select($event)"></app-simple-drop-down-component>
  </slot-content>

</app-modal>
