import {Component, EventEmitter, Output} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-ngx-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [CurrencyPipe]
})
export class DateRangePickerComponent {
  selectedDate!: {startDate: any, endDate: any};
  @Output() dateChanged = new EventEmitter();

  constructor() {
  }
  isInvalidDate(date: any): boolean {
  return date <= new Date(new Date().setDate(new Date().getDate() - 1)); // minus one day
}

  rangeClicked(event: any): void {
    if (!this.isInvalidDate(new Date(event.startDate))) {
      this.dateChanged.emit({startDate: this.formatDate(event.startDate), endDate: this.formatDate(event.endDate)});
    }
  }
  formatDate(date: any): any {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    return [year, month, day].join('-');
  }
}
