<!-- <div *ngIf="conversation != null" [ngClass]="messageService.getOpenedPrivateConversation()?.conversationId == conversation.conversationId? 'with-box-shadow wrapper' : 'wrapper'"> -->
  <div class="chat_container">
    <div class="contact">
      <div class="info">
        <div class="avatar-wrapper">
          <!-- <img src="{{profileImage| defaultAvatar}}" alt="avatar" class="avatar-image"> -->
          <img src="assets/Ellipse14.png" alt="avatar" class="avatar-image">
        </div>
        <div class="detailed-info">
          <!-- <div class="name">{{username}}</div> -->
          <div class="name">Wilson Stanton</div>
          <!-- <div class="topic">{{topic}}</div> -->
          <div class="topic">Need a painter for my mansion, Urgent!</div>
          <!-- <div [ngClass]="isUnread && messageService.getOpenedPrivateConversation()?.conversationId != conversation.conversationId? 'unread content' : 'content'">{{lastMessageFrom}}{{conversation.lastMessageContent}}</div> -->
          <div class="you_replay">You: Hello Wilson</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="contact">
      <div class="info">
        <div class="avatar-wrapper">
          <!-- <img src="{{profileImage| defaultAvatar}}" alt="avatar" class="avatar-image"> -->
          <img src="assets/Ellipse1.png" alt="avatar" class="avatar-image">
        </div>
        <div class="detailed-info">
          <!-- <div class="name">{{username}}</div> -->
          <div class="name">Wilson Stanton</div>
          <!-- <div class="topic">{{topic}}</div> -->
          <div class="topic">Need a painter for my mansion, Urgent!</div>
          <!-- <div [ngClass]="isUnread && messageService.getOpenedPrivateConversation()?.conversationId != conversation.conversationId? 'unread content' : 'content'">{{lastMessageFrom}}{{conversation.lastMessageContent}}</div> -->
          <div class="you_replay">You: Hello Wilson</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="contact">
      <div class="info">
        <div class="avatar-wrapper">
          <!-- <img src="{{profileImage| defaultAvatar}}" alt="avatar" class="avatar-image"> -->
          <img src="assets/Ellipse2.png" alt="avatar" class="avatar-image">
        </div>
        <div class="detailed-info">
          <!-- <div class="name">{{username}}</div> -->
          <div class="name">Wilson Stanton</div>
          <!-- <div class="topic">{{topic}}</div> -->
          <div class="topic">Need a painter for my mansion, Urgent!</div>
          <!-- <div [ngClass]="isUnread && messageService.getOpenedPrivateConversation()?.conversationId != conversation.conversationId? 'unread content' : 'content'">{{lastMessageFrom}}{{conversation.lastMessageContent}}</div> -->
          <div class="you_replay">You: Hello Wilson</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="contact">
      <div class="info">
        <div class="avatar-wrapper">
          <!-- <img src="{{profileImage| defaultAvatar}}" alt="avatar" class="avatar-image"> -->
          <img src="assets/Ellipse3.png" alt="avatar" class="avatar-image">
        </div>
        <div class="detailed-info">
          <!-- <div class="name">{{username}}</div> -->
          <div class="name">Wilson Stanton</div>
          <!-- <div class="topic">{{topic}}</div> -->
          <div class="topic">Need a painter for my mansion, Urgent!</div>
          <!-- <div [ngClass]="isUnread && messageService.getOpenedPrivateConversation()?.conversationId != conversation.conversationId? 'unread content' : 'content'">{{lastMessageFrom}}{{conversation.lastMessageContent}}</div> -->
          <div class="you_replay">You: Hello Wilson</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="contact">
      <div class="info">
        <div class="avatar-wrapper">
          <!-- <img src="{{profileImage| defaultAvatar}}" alt="avatar" class="avatar-image"> -->
          <img src="assets/Ellipse4.png" alt="avatar" class="avatar-image">
        </div>
        <div class="detailed-info">
          <!-- <div class="name">{{username}}</div> -->
          <div class="name">Wilson Stanton</div>
          <!-- <div class="topic">{{topic}}</div> -->
          <div class="topic">Need a painter for my mansion, Urgent!</div>
          <!-- <div [ngClass]="isUnread && messageService.getOpenedPrivateConversation()?.conversationId != conversation.conversationId? 'unread content' : 'content'">{{lastMessageFrom}}{{conversation.lastMessageContent}}</div> -->
          <div class="you_replay">You: Hello Wilson</div>
        </div>
      </div>
    </div>
    <hr>
    <div class="contact">
      <div class="info">
        <div class="avatar-wrapper">
          <!-- <img src="{{profileImage| defaultAvatar}}" alt="avatar" class="avatar-image"> -->
          <img src="assets/Ellipse5.png" alt="avatar" class="avatar-image">
        </div>
        <div class="detailed-info">
          <!-- <div class="name">{{username}}</div> -->
          <div class="name">Wilson Stanton</div>
          <!-- <div class="topic">{{topic}}</div> -->
          <div class="topic">Need a painter for my mansion, Urgent!</div>
          <!-- <div [ngClass]="isUnread && messageService.getOpenedPrivateConversation()?.conversationId != conversation.conversationId? 'unread content' : 'content'">{{lastMessageFrom}}{{conversation.lastMessageContent}}</div> -->
          <div class="you_replay">You: Hello Wilson</div>
        </div>
      </div>
    </div>
</div>
