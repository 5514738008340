import {Component, EventEmitter, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  template: ''
})
export abstract class BrushTableCustomFilterComponent implements OnChanges{
  @Output() filter = new EventEmitter<any>();
  abstract emit(event: any): void;
  ngOnChanges(changes: SimpleChanges): void {
  }

}
