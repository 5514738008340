import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ShareDataService {

  hideSideNavbarFunction: any;
  hideMarketplaceFiltersFunction: any;

  private notificationCounter = new Subject();
  private newChatMessageSubject = new Subject();

  notificationCount = this.notificationCounter.asObservable();
  newChatMessageObservable = this.newChatMessageSubject.asObservable();

  constructor() {
  }

  newChatMessageForOpenedConversation(): void {
    this.newChatMessageSubject.next();
  }
  changeCounter(status: number): void {
    this.notificationCounter.next(status);
  }
  setHideSideNavbarFunction(hideFunction: any): void {
    this.hideSideNavbarFunction = hideFunction;
  }
  hideSideNavbarIfExpanded(): void {
    this.hideSideNavbarFunction();
  }
  setHideMarketplaceFiltersFunction(hideFunction: any): void {
    this.hideMarketplaceFiltersFunction = hideFunction;
  }

  hideMarketplaceFiltersIfExpanded(): void {
    if (this.hideMarketplaceFiltersFunction != null) { // only for marketplace view
      this.hideMarketplaceFiltersFunction();
    }
  }

}
