<!-- <app-notification-bell></app-notification-bell> -->

<div class="crd_wpr">
  <div class="main-wrapper rm_text_bck_grnd">
    <div class="div_upr_text d-flex">
      <div class="d-flex align-items-center">
        <a routerLink="/home/my-project-card" [queryParams]="{ step: currrenttab }">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </a>
        <h1>Project</h1>
      </div>

      <app-notification-bell></app-notification-bell>
      <!--  <div class="bell_notification">
                <img src="assets/icons/notifications-icon.png" alt="notifications-icon">
                <div class="unread-notification-count">2</div>
            </div>-->
    </div>
    <nb-card class="nb-theme-brush card rooms_crds_rpn">
      <nb-card-body>
        <nb-tabset>
          <nb-tab tabTitle="Details" class="active">
            <div class="tab-content-wrapper d-flex">
              <div class="project_my">
                <div>
                  <section class="image-section-wrapper">
                    <ng-image-slider #nav [images]="imageObject" [infinite]="false" [autoSlide]="1"
                      [imageSize]="{ width: '80%', height: 300 }" slideImage="1"></ng-image-slider>
                  </section>
                  <section class="details">
                    <div class="title-wrapper">
                      <div class="d-flex align-items-center">
                        <span class="title">{{
                          projectDetails.project_title
                          }}</span>
                        <span class="yellow_clr">{{
                          projectDetails.status | titlecase
                          }}</span>
                      </div>
                      <!--   <button class="mark-as-completed-button" (click)="markcomplete(projectDetails.id)"
                                                *ngIf="projectDetails.bid_status=='approved' && projectDetails.status=='brushed'">Mark Project as
                                                Complete</button>-->
                    </div>
                    <div class="sub-title">
                      <div class="project-type">
                        {{ projectDetails.project_type }}
                      </div>
                      <div class="circle"></div>
                      <div class="when-posted" *ngIf="projectDetails.days_remaining != 0">
                        Posted {{ projectDetails.days_remaining }} days ago
                      </div>
                      <div class="when-posted" *ngIf="projectDetails.days_remaining == 0">
                        Posted today
                      </div>
                    </div>
                    <div class="description">
                      <pre>{{ projectDetails.description }}</pre>
                    </div>
                    <div class="properties">
                      <div class="property">
                        <div class="property-key">Type of Project</div>
                        <div>
                          <span class="property-value" *ngIf="projectDetails.project_type">
                            {{ projectDetails.project_type | titlecase }}
                          </span>
                        </div>
                      </div>
                      <div class="property">
                        <div class="property-key">Type of Property</div>
                        <div>
                          <span class="property-value" *ngIf="projectDetails.home_type">
                            {{ projectDetails.home_type | titlecase }}
                          </span>
                        </div>
                      </div>

                      <div class="property">
                        <div class="property-key">Job Type</div>
                        <div class="property-value" *ngIf="projectDetails.creater_type != 'basic'">
                          {{ projectDetails.creater_type | titlecase }}
                        </div>
                        <div class="property-value" *ngIf="projectDetails.creater_type == 'basic'">
                          Basic without an Estimate
                        </div>
                      </div>
                      <div class="property">
                        <div class="property-key">Property Type</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.property_type | titlecase }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.jobtime">
                        <div class="property-key">Scheduling Preferences</div>
                        <div class="property-value" *ngIf="
                            projectDetails.jobtime ==
                            'I_can_adjust_to_the_painter'
                          ">
                          I can adjust to the painter
                        </div>
                        <div class="property-value" *ngIf="projectDetails.jobtime == 'anytime'">
                          Anytime
                        </div>
                        <div class="property-value" *ngIf="projectDetails.jobtime == 'during_the_day'">
                          During the day
                        </div>
                      </div>
                      <div class="property" *ngIf="projectDetails.room">
                        <div class="property-key">Number of Rooms</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.room.length }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.height_of_ceiling">
                        <div class="property-key">Ceiling Height</div>
                        <div>
                          <span class="property-value">
                            {{
                            projectDetails.height_of_ceiling
                            ? projectDetails.height_of_ceiling + " " + "feet"
                            : "N/A"
                            }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.wallpaper">
                        <div class="property-key">Has wallpaper</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.wallpaper }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails?.room_type?.length">
                        <div class="property-key">Items in scope</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails?.room_type ; let i = index;">
                           {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.room_type.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails?.what_painted?.length">
                        <div class="property-key">What needs to be painted</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails.what_painted; let i = index;">
                          {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.what_painted.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>

                      <div class="property" *ngIf="projectDetails?.finishing?.length">
                        <div class="property-key">Finishing</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails.finishing; let i = index;">
                            {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.finishing.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>

                      <div class="property" *ngIf="projectDetails?.project_applicable?.length">
                        <div class="property-key">Project Applicable</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails.project_applicable; let i = index;">
                           {{ item | removeUnderscore }} {{i === projectDetails.project_applicable.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>

                      <div class="property" *ngIf="projectDetails.surface_area_to_be_painted">
                        <div class="property-key">
                          Surface area to be painted
                        </div>
                        <div>
                          <span class="property-value">
                            {{
                            projectDetails.surface_area_to_be_painted.replace(
                            "_",
                            " "
                            ) | titlecase
                            }}
                            ft
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.room">
                        <div class="property-key">Room number</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.room.length }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails?.providing_items">
                        <div class="property-key">
                          Customer will provide items
                        </div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.providing_items.replace("_", " ") }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.surface_area">
                        <div class="property-key">Surface area</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.surface_area }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.room_no">
                        <div class="property-key">
                          No of rooms to be painted
                        </div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.room_no }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.home_type">
                        <div class="property-key">Home type</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.home_type | titlecase }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.surface_condition">
                        <div class="property-key">Surface condition</div>
                        <div>
                          <span class="property-value" *ngIf="projectDetails.surface_condition  == 'good'">
                           No repairs needed
                          </span>
                          <span class="property-value" *ngIf="projectDetails.surface_condition  == 'fair'">
                         Need some repairs
                          </span>
                          <span class="property-value" *ngIf="projectDetails.surface_condition  == 'bad'">
                         Need major repairs
                          </span>
                          <span class="property-value" *ngIf="projectDetails.surface_condition  == 'needHelpinMeasurment'">
                          Need help in assessing surface condition
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.material">
                        <div class="property-key">Surface Type</div>
                        <div class="property-value" *ngIf="!projectDetails.matrial_desc.length">
                          {{ projectDetails.material }}
                        </div>
                        <div class="property-value" *ngIf="projectDetails.matrial_desc.length">
                          {{ projectDetails.matrial_desc[0].title | titlecase }},
                          {{ projectDetails.matrial_desc[0].decription | titlecase}}
                        </div>
                      </div>
                      <div class="property" *ngIf="projectDetails?.startDate">
                        <div class="property-key">Project start date-</div>
                        <div class="property-value">
                          {{ projectDetails.startDate | date }}
                        </div>
                      </div>

                      <div class="property" *ngIf="projectDetails?.endDate">
                        <div class="property-key">
                          Project expected to be completed by-
                        </div>
                        <div class="property-value">
                          {{ projectDetails.endDate | date }}
                        </div>
                      </div>
                      <div class="property" *ngIf="
                          projectDetails?.other_thing_painter_to_do?.length
                        ">
                        <div class="property-key">
                          Other things painter to do
                        </div>
                        <!-- <div class="property-value">{{projectDetails.other_thing_painter_to_do}}
                                                </div> -->
                        <div class="property-value">
                          <span class="pntr_badge" *ngFor="
                              let item of projectDetails.other_thing_painter_to_do; let i =index;
                            ">                                                      {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.other_thing_painter_to_do.length -1 ? '' : ',' }}

                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section class="main alerts_wrap">
                    <div class="main-info-wrapper project-in-progress" *ngIf="
                        projectDetails.bid_status == 'approved' &&
                        projectDetails.status == 'brushed' &&
                        projectDetails.customer_status != 'completed'
                      ">
                      <div class="d-flex">
                        <nb-icon icon="clock"></nb-icon>

                        <div class="main-info-text">Project in Progress</div>
                      </div>
                      <button class="custom-button" type="button" (click)="markcomplete(projectDetails.id)">
                        Mark Complete
                      </button>
                    </div>
                    <div class="main-info-wrapper project-pending" *ngIf="
                        projectDetails.status == 'brushed' &&
                        projectDetails.customer_status == 'completed'
                      ">
                      <div class="d-flex">
                        <nb-icon icon="clock"></nb-icon>

                        <div class="main-info-text">
                          Customer Status - Completed , Painter Status - Brushed
                        </div>
                      </div>
                    </div>

                    <div class="main-info-wrapper project-in-progress" *ngIf="
                        projectDetails.bid_status == 'approved' &&
                        projectDetails.status == 'brushed' &&
                        projectDetails.customer_status != 'completed'
                      ">
                      <div class="d-flex">
                        <nb-icon icon="clock"></nb-icon>
                        <div class="main-info-text">
                          Is there something wrong about this job or painter?
                          Please let us know!
                        </div>
                      </div>

                      <button class="custom-button" (click)="openComplainModal()">
                        Raise an Issue
                      </button>
                    </div>
                    <div class="main-info-wrapper project-pending" *ngIf="projectDetails.painter_status == 'disputed'">
                      <div class="d-flex">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                        <div class="main-info-text">
                          Job was disputed by painter, you will get an update
                          soon
                        </div>
                      </div>
                      <button class="custom-button" (click)="viewdispute()">
                        Review
                      </button>
                    </div>
                    <div class="main-info-wrapper project-pending" *ngIf="projectDetails.customer_status == 'disputed'">
                      <div class="d-flex">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                        <div class="main-info-text">
                          Job was disputed by customer, you will get an update
                          soon
                        </div>
                      </div>
                      <button class="custom-button" (click)="viewdispute()">
                        Review
                      </button>
                    </div>
                    <div class="main-info-wrapper project-completed" *ngIf="projectDetails?.status == 'archived' && projectDetails?.customer_status && projectDetails?.painter_status">
                      <div class="d-flex">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                        <div class="main-info-text">Project is completed</div>
                      </div>
                    </div>
                     <div class="main-info-wrapper project-completed" *ngIf="projectDetails?.status == 'archived' && !projectDetails?.customer_status && !projectDetails?.painter_status">
                      <div class="d-flex">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                        <div class="main-info-text">Project is archived</div>
                      </div>
                    </div>
                  </section>
                  <section class="main" *ngIf="projectDetails.status == 'completed'">
                    <div class="main-info-wrapper project-in-progress" *ngIf="
                        !projectDetails?.review_detail ||
                        !projectDetails?.review_detail?.customer_rating
                      ">
                      <div class="d-flex">
                        <nb-icon icon="clock"></nb-icon>
                        <div class="main-info-text">Add review!</div>
                      </div>

                      <button class="custom-button" (click)="sendreview()">
                        Review
                      </button>
                    </div>
                    <div class="main-info-wrapper project-in-progress"
                      *ngIf="projectDetails?.review_detail?.customer_rating">
                      <div class="d-flex">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                        <div class="main-info-text">Review added!</div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab tabTitle="Bids[{{ projectDetails?.bids?.length }}]" class="bid-tab">
            <div class="bids_card" *ngIf="!editnew">
              <!-- <select (click)="newfiler($event)" >
                        <option *ngFor="let opt of order"  [value]="opt.value">
        {{opt.lable}} 
</option>
</select> -->

              <!-- <div class="dropdown drop_menu">
                <span class="drop_sort"
                  >Sort by: New
                  <span class="drp_arrw"
                    ><i class="fa fa-chevron-down"></i></span
                ></span>
                <div class="dropdown-content">
                  <ul>
                    <li newfiler>New</li>
                    <li>Oldest</li>
                    <li>Highest</li>
                    <li>Lowest</li>
                  </ul>
                </div>
              </div> -->
              <!-- <div class="dropdown">
                <span class="drop_sort">Sort by: New</span>
                <span class="drp_arrw" (click)="showfilter()"
                  ><i class="fa fa-chevron-down"></i
                ></span>
                <div class="dropdown-content" *ngIf="shownew">
                  <ul>
                    <li (click)="newfiler('new')">New</li>
                    <li (click)="newfiler('oldest')">Oldest</li>
                    <li (click)="newfiler('highest')">Highest</li>
                    <li (click)="newfiler('lowest')">Lowest</li>
                  </ul>
                </div>
              </div> -->
              <div *ngIf="approved_bids?.length">
                <div class="setting-title">Winning Bid</div>

                <nb-card class="nb-theme-brush card">
                  <nb-card-body *ngFor="let item of approved_bids">
                    <div class="bid-card-wrapper" *ngIf="item.status == 'approved'">
                      <div class="d-flex">
                        <div class="bid_img" *ngIf="item?.painter_details?.profile_image && item?.painter_details?.profile_image !='null'">
                          <img [src]="item?.painter_details?.profile_image" onerror="src='./assets/default.png'"/>
                        </div>
                        <div class="bid_img" *ngIf="!item?.painter_details?.profile_image || item?.painter_details?.profile_image =='null'">
                          <img src="" onerror="src='./assets/default.png'"/>
                        </div>
                        <div class="info">
                          <div class="painter-details">
                            <div class="div_upr d-flex align-items-center">
                              <h3>
                                {{ item?.painter_details?.full_name }}
                              </h3>
                              <span (click)="
                                  getprofilebyid(item?.painter_details?.id)
                                " class="undr_lne_text"><a>View Profile</a></span>
                            </div>
                            <div class="sub-info">
                              <img src="assets/icons/star.png" alt="star" class="logo" />
                              <span class="rating">
                                {{
                                item?.painter_details?.avgRating
                                | number : "1.0-0"
                                }}.0</span>
                              <span class="no-of-reviews">({{
                                item?.painter_details?.completed_projects
                                }}
                                Reviews)</span>
                            </div>
                          </div>
                          <div class="bid-details">
                            <div class="bid-amount-text">Bid Amount</div>
                            <div class="bid-amount approved">
                              ${{ item.bid }}.00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="content">
                        <div class="cover-letter">Cover Letter</div>
                        <div class="bid-comment not-detailed">
                          <pre class="pre_set">{{ item.cover_letter }} </pre>
                        </div>
                        <div class="d-flex custmr_report">
                          <div class="d-flex align-items-center chat_with_painter">
                            <button class="custom-button" (click)="getbiddetails(item.id)">
                              Manage Bid
                            </button>
                            <button *ngIf="item?.room?.room_id" class="border_button" routerLink="/home/cus-chat/{{
                                item?.room?.room_id
                              }}/{{ item?.room?.id }}">
                              Chat with painter
                            </button>
                          </div>
                          <p><a (click)="modalFlagreport = true"> Report</a></p>
                        </div>
                      </div>
                    </div>
                  </nb-card-body>
                </nb-card>
                <div>
                  <div class="setting-title">Other bidders</div>

                  <nb-card class="nb-theme-brush card">
                    <nb-card-body *ngFor="let item of bids">
                      <div class="bid-card-wrapper" *ngIf="item.status != 'approved'">
                        <div class="d-flex">
                          <div class="bid_img" *ngIf="item?.painter_details?.profile_image && item?.painter_details?.profile_image != 'null' ">
                            <img [src]="item?.painter_details?.profile_image" />
                          </div>
                          <div class="bid_img" *ngIf="!item?.painter_details?.profile_image || item?.painter_details?.profile_image == 'null'">
                            <img src="" onerror="src='./assets/default.png'"/>
                          </div>
                          <div class="info">
                            <div class="painter-details">
                              <div class="div_upr d-flex align-items-center">
                                <h3>
                                  {{ item?.painter_details?.full_name }}
                                </h3>
                                <span (click)="
                                    getprofilebyid(item?.painter_details?.id)
                                  " class="undr_lne_text"><a>View Profile</a></span>
                              </div>
                              <div class="sub-info">
                                <img src="assets/icons/star.png" alt="star" class="logo" />
                                <span class="rating">
                                  {{
                                  item?.painter_details?.avgRating
                                  | number : "1.0-0"
                                  }}.0</span>
                                <span class="no-of-reviews">({{
                                  item?.painter_details?.reviewscount
                                  }}
                                  Reviews)</span>
                              </div>
                            </div>
                            <div class="bid-details">
                              <div class="bid-amount-text">Bid Amount</div>
                              <div class="bid-amount approved">
                                ${{ item.bid }}.00
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="content">
                          <div class="cover-letter">Cover Letter</div>
                          <div class="bid-comment not-detailed">
                            <pre class="pre_set">{{ item.cover_letter }} </pre>
                          </div>
                          <div class="d-flex custmr_report">
                            <div class="d-flex align-items-center chat_with_painter">
                              <button class="custom-button" (click)="getbiddetails(item.id)">
                                Manage Bid
                              </button>
                              <button *ngIf="item?.room?.room_id" class="border_button" routerLink="/home/cus-chat/{{
                                  item?.room?.room_id
                                }}/{{ item?.room?.id }}">
                                Chat with painter
                              </button>
                            </div>
                            <p>
                              <a (click)="modalFlagreport = true"> Report</a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </nb-card-body>
                  </nb-card>
                </div>
              </div>
              <div *ngIf="!projectDetails?.approved_bids?.length">
                <div class="dropdown drop_menu">
                  <span class="drop_sort" (click)="showfilter()">Sort by: {{ filternewoption | titlecase }}
                    <span class="drp_arrw"><i class="fa fa-chevron-down"></i></span></span>

                  <div class="dropdown-content" *ngIf="shownew">
                    <ul>
                      <li (click)="newfiler('new')">New</li>
                      <li (click)="newfiler('oldest')">Oldest</li>
                      <li (click)="newfiler('highest')">Highest</li>
                      <li (click)="newfiler('lowest')">Lowest</li>
                    </ul>
                  </div>
                </div>
                <nb-card class="nb-theme-brush card">
                  <nb-card-body *ngFor="let item of bids">
                    <div class="bid-card-wrapper">
                      <div class="d-flex">
                        <div class="bid_img" *ngIf="item?.painter_details?.profile_image && item?.painter_details?.profile_image != 'null'">
                          <img [src]="item?.painter_details?.profile_image" onerror="src='./assets/default.png'"/>
                        </div>
                        <div class="bid_img" *ngIf="!item.painter_details?.profile_image ||item?.painter_details?.profile_image =='null'">
                          <img src="" onerror="src='./assets/default.png'"/>
                        </div>
                        <div class="info">
                          <div class="painter-details">
                            <div class="div_upr d-flex align-items-center">
                              <h3>
                                {{ item?.painter_details?.full_name }}
                              </h3>
                              <span (click)="
                                  getprofilebyid(item?.painter_details?.id)
                                " class="undr_lne_text"><a>View Profile</a></span>
                            </div>
                            <div class="sub-info">
                              <img src="assets/icons/star.png" alt="star" class="logo" />
                              <span class="rating">
                                {{
                                item?.painter_details?.avgRating
                                | number : "1.0-0"
                                }}.0</span>
                              <span class="no-of-reviews">({{
                                item?.painter_details?.reviewscount
                                }}
                                Reviews)</span>
                            </div>
                          </div>
                          <div class="bid-details">
                            <div class="bid-amount-text">Bid Amount</div>
                            <div class="bid-amount approved">
                              ${{ item.bid }}.00
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="content">
                        <div class="cover-letter">Cover Letter</div>
                        <div class="bid-comment not-detailed">
                          <pre class="pre_set">{{ item.cover_letter }} </pre>
                        </div>
                        <div class="d-flex custmr_report">
                          <div class="d-flex align-items-center chat_with_painter">
                            <button class="custom-button" (click)="getbiddetails(item.id)">
                              Manage Bid
                            </button>
                            <button *ngIf="item?.room?.room_id" class="border_button" routerLink="/home/cus-chat/{{
                                item?.room?.room_id
                              }}/{{ item?.room?.id }}">
                              Chat with painter
                            </button>
                          </div>
                          <p><a (click)="modalFlagreport = true"> Report</a></p>
                        </div>
                      </div>
                    </div>
                  </nb-card-body>
                </nb-card>
              </div>
            </div>

            <!-- --------painter profile-------------- -->

            <div class="profile-wrapper" *ngIf="editnew">
              <div class="contact-wrapper">
                <a (click)="goto()">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </a>
                <div class="contact-header">
                  <div class="avatar">
                    <img [src]="
                        profile?.profile_image
                          ?  profile?.profile_image
                          : './assets/default.png'
                      " onerror="src='./assets/default.png'">
                  </div>
                  <!-- <button (click)="editp()">Edit Profile</button> -->
                  <div class="info">
                    <div class="full-name">{{ profile?.full_name }}</div>
                    <div class="address">
                      <img src="assets/icons/contact/localization.png" alt="localization" />
                      <div class="address-text">
                        {{ profile?.state }},
                        {{ profile?.zip_code }}
                      </div>
                    </div>
                    <div class="rtng_div">
                      <app-painter-rating class="rating" [avgRating]="profile.avgRating" [displayRatingText]="false">
                      </app-painter-rating>
                      <span class="rating">
                        {{ profile?.avgRating | number : "1.0-0" }}.0</span>
                      <span class="no-of-reviews">({{ reviewlist?.length }} Reviews)</span>
                    </div>
                  </div>
                </div>
                <div class="brushing-since">
                  Brushing since {{ profile?.created_at | date }}
                </div>
                 <div class="brushing-since">
                 <b>About painter -</b>  {{ profile?.about_me | titlecase }}
                </div>
                <!-- <div class="credits_earn_warp">
                                <div class="credits_wrap">
                                    <span><svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"
                                        xml:space="preserve">
                                        <g>
                                        <g>
                                            <path d="M361.739,278.261c-27.664,0-50.087,22.423-50.087,50.087s22.423,50.087,50.087,50.087H512V278.261H361.739z
                                        M361.739,345.043c-9.22,0-16.696-7.475-16.696-16.696s7.475-16.696,16.696-16.696s16.696,7.475,16.696,16.696
                                        S370.96,345.043,361.739,345.043z" />
                                        </g>
                                        </g>
                                        <g>
                                        <g>
                                            <path d="M361.739,244.87h83.478v-50.087c0-27.619-22.468-50.087-50.087-50.087H16.696C7.479,144.696,0,152.174,0,161.391v333.913
                                        C0,504.521,7.479,512,16.696,512H395.13c27.619,0,50.087-22.468,50.087-50.087v-50.087h-83.478
                                        c-46.032,0-83.478-37.446-83.478-83.478C278.261,282.316,315.707,244.87,361.739,244.87z" />
                                        </g>
                                        </g>
                                        <g>
                                        <g>
                                            <path d="M461.913,144.696h-0.158c10.529,13.973,16.854,31.282,16.854,50.087v50.087H512v-50.087
                                        C512,167.164,489.532,144.696,461.913,144.696z" />
                                        </g>
                                        </g>
                                        <g>
                                        <g>
                                            <path d="M478.609,411.826v50.087c0,18.805-6.323,36.114-16.854,50.087h0.158C489.532,512,512,489.532,512,461.913v-50.087H478.609
                                        z" />
                                        </g>
                                        </g>
                                        <g>
                                        <g>
                                            <path
                                            d="M273.369,4.892c-6.521-6.521-17.087-6.521-23.609,0l-14.674,14.674l91.74,91.738h52.956L273.369,4.892z" />
                                        </g>
                                        </g>
                                        <g>
                                        <g>
                                            <path d="M173.195,4.892c-6.521-6.522-17.086-6.522-23.608,0L43.174,111.304h236.435L173.195,4.892z" />
                                        </g>
                                        </g>
                                    </svg>
                                    </span>
                                    <div>
                                    <h6>
                                        {{profile?.credit}}
                                    </h6>
                                    <p class="light_txt">
                                        Available Credits
                                    </p>
                                    </div>
                                </div>
                                <a *ngIf="!id" (click)="modalFlag1=true;">
                                    Add Credits
                                </a>
                            </div> -->
                <div class="main-info contact_dtl_wrap" *ngIf="!id">
                  <div class="bsns_dtl_wrp" *ngIf="profile?.business_name">
                    <div class="title">Business details</div>
                    <p>{{ profile?.business_name }}</p>
                  </div>

                  <div class="title">Contact Details</div>
                  <div>
                    <div class="contact-detail">
                      <img src="assets/icons/contact/phone.png" alt="phone" />
                      <div class="contact-data">
                        +{{ profile.phone_number }}
                      </div>
                    </div>
                    <div class="contact-detail">
                      <img src="assets/icons/contact/email.png" alt="phone" />
                      <div class="contact-data">{{ profile.email }}</div>
                    </div>
                    <div class="contact-detail">
                      <img src="assets/icons/contact/localization.png" alt="phone" />
                      <div class="contact-data">
                        {{ profile.state }},{{
                        profile.zip_code
                        }}
                      </div>
                    </div>
                  </div>
                  <!-- <div class="contact-not-available">Detailed contact is not available for a now.

                                <div class="main-info contact_dtl_wrap" *ngIf="!id">
                                    <div class="title">Contact Details</div>
                                    <div>
                                        <div class="contact-detail">
                                            <img src="assets/icons/contact/phone.png" alt="phone">
                                            <div class="contact-data">+{{profile.phone_number}}</div>
                                        </div>
                                        <div class="contact-detail">
                                            <img src="assets/icons/contact/email.png" alt="phone">
                                            <div class="contact-data">{{profile.email}}</div>
                                        </div>
                                        <div class="contact-detail">
                                            <img src="assets/icons/contact/localization.png" alt="phone">
                                            <div class="contact-data">{{profile.city}} {{profile.zip_code}} ,
                                                {{profile.state}}</div>
                                        </div>
                                    </div>
                                    <div class="contact-not-available">Detailed contact is not available for a now.
                            </div> -->

                  <!-- <ng-content select="[additional-info]"></ng-content> -->
                </div>

                <div class="badges contact_dtl_wrap" *ngIf="show">
                  <div class="title">Badges</div>
                  <div class="badges-content">
                    <div class="badge" *ngIf="currentdate > newdate">
                      <div class="tooltip">
                        <img class="badge-img" src="./assets/icons/painter-badges/badge1.png" />
                        <span class="tooltiptext">Painter is brushing over 1 year.</span>
                      </div>
                    </div>

                    <div class="badge" *ngIf="reviewlist?.length > 10">
                      <div class="tooltip">
                        <img class="badge-img" src="./assets/icons/painter-badges/badge2.png" />
                        <span class="tooltiptext">Painter has more than 100 comments.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="badges contact_dtl_wrap" *ngIf="!show">
                  <div class="title">Badges</div>
                  <div class="painter-has-no-badges">
                    Painter has not received any badge yet
                  </div>
                </div>
              </div>

              <div class="comments-wrapper">
                <!-- <div class="post-comment">
                            <app-comment-card></app-comment-card>
                            </div> -->

                <div class="comments">
                  <div class="comments-header">
                    <div class="title" #comments>
                      Comments<span class="total-comments">
                        ({{ reviewlist?.length }})
                      </span>
                    </div>
                    <div class="dropdown drop_menu">
                      <span class="drop_sort" (click)="showfilterrevire()">Sort by: {{ filteroption | titlecase }}
                        <span class="drp_arrw"><i class="fa fa-chevron-down"></i></span></span>

                      <div class="dropdown-content" *ngIf="shownewreview">
                        <ul>
                          <li (click)="getreviewlistfilter('newest')">New</li>
                          <li (click)="getreviewlistfilter('oldest')">
                            Oldest
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!--<div class="dropdown drop_menu">
                      <span class="drop_sort"
                        >Sort by: New
                        <span class="drp_arrw"
                          ><i class="fa fa-chevron-down"></i></span
                      ></span>
                      <div class="dropdown-content">
                        <ul>
                          <li>New</li>
                          <li>Oldest</li>
                          
                        </ul>
                      </div>
                    </div>
                    <!-- <app-select-input class="select-input"> </app-select-input> -->
                  </div>
                  <!-- <app-comment-card *ngFor="let comment of pageableResponse?.content" [comment]="comment" [editable]="false"></app-comment-card> -->
                  <div class="comment" *ngFor="let item of reviewlist">
                    <div class="comment-header">
                      <div class="avatar-full-name">
                        <div class="avatar">
                          <img [src]="
                              item.customer_name.profile_image && item.customer_name.profile_image != 'null'
                                ?  item.customer_name.profile_image
                                : './assets/default.png'
                            " />
                        </div>
                        <div class="comment-info">
                          <!-- <div class="full-name">{{ fullName}}</div> -->
                          <div class="full-name">
                            {{ item.customer_name.full_name }}
                          </div>
                          <!-- <div *ngIf="comment" class="posted-date">{{comment.howLongAgo}}</div> -->
                          <div *ngIf="item.days_remaining == 0" class="posted-date">
                            Today
                          </div>

                          <div *ngIf="item.days_remaining != 0" class="posted-date">
                            {{ item.days_remaining }} days ago
                          </div>
                        </div>
                      </div>
                      <!-- <app-painter-rating *ngIf="!editable" [avgRating]="comment.rating" [displayRatingText]="false"></app-painter-rating> -->
                      <app-painter-rating [avgRating]="item.customer_rating"
                        [displayRatingText]="false"></app-painter-rating>
                      <div class="rating-wrapper" *ngIf="avgRating">
                        <div class="stars">
                          <div class="stars-wrapper" *ngFor="
                              let rating of item.customer_review;
                              index as i
                            "></div>
                          <!-- <div class="avg-rating">{{avgRatingString}}<span class="total-comments" *ngIf="totalCommentsForPainter">({{totalCommentsForPainter}})</span></div> -->
                        </div>
                      </div>

                      <!-- <app-painter-rating *ngIf="editable" (selectedRateEmitter)="setSelectedRate($event)" #editablePainterRating></app-painter-rating> -->
                    </div>
                    <!-- <div class="content" *ngIf="!editable"><pre>{{comment.content}}</pre></div> -->
                    <div class="content">
                      <pre>{{ item.customer_comment }}</pre>
                    </div>
                  </div>
                  <!-- <app-pagination [adjacentPagesNo]="2" [totalPages]="44" [pageNumber]="3">
                            </app-pagination>-->
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab tabTitle="Rooms({{
              projectDetails?.room?.length ? projectDetails?.room?.length : '0'
            }})" class="room-tab">
            <div class="rooms">
              <div class="d-flex mrgn_tbs">
                <!-- <div class="div_inr_cost mrgn_ryt_txt" >
                                    <div class="room-card-wrapper">
                                        <div class="div_imgs_room">
                                            <img class="bed_innr" src="assets/Rectangle.png" alt="">
                                        </div>
                                        <div class="info tym_info">
                                            <div class="room-type">{{item.roomType}}</div>
                                            <div class="cost-text">Estimated Cost</div>
                                            <div class="cost">$3,400</div>
                                            <div class="undr_lne_text vw_detls" (click)="modalFlag1=true;">View Details
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                <div class="div_inr_cost" *ngFor="let item of projectDetails.room">
                  <div class="room-card-wrapper">
                    <div class="div_imgs_room">
                      <img style="height: 32px" src="{{
                          item?.images
                            ? item?.images[0]?.image
                            : projectDetails?.images[0]?.image
                        }}" alt="" onerror="src='./assets/default.png'"/>
                    </div>
                    <div class="info tym_info">
                      <div class="room-type">
                        {{ item.sel_rrom[0] | titlecase }}
                      </div>
                      <!-- <div class="cost-text" *ngIf="item?.details?.ceiling_paint_cost  != 0  ">Ceiling
                        paint cost : $ {{item?.details?.ceiling_paint_cost |
                        number:'1.0-2'}}</div> -->
                      <!-- <div class="cost-text">Wall paint cost :
                        $ {{item.details?.wall_paint_cost | number:'1.0-2'}}</div> -->

                      <div class="cost-text" *ngIf="item?.details?.gallons_ceiling_paint !=0">Gallons
                        ceiling paint : {{item?.details?.gallons_ceiling_paint |
                        number:'1.0-2'}}</div>
                      <div class="v">Gallons wall paint :
                        {{item?.details?.gallons_wall_paint | number:'1.0-2'}}</div>
                      <div class="cost-text">High range : $ {{item?.details?.high_range |
                        number:'1.0-2'}}</div>

                      <div class="cost-text">Low range : $ {{item?.details?.low_range |
                        number:'1.0-2'}}</div>
                      <!-- <div class="cost-text">Prep time : {{item?.details?.prepTime |
                        number:'1.0-2'}}</div> -->
                      <!-- <div class="cost-text">Labour hours : {{item?.details?.labour_hour |
                        number:'1.0-2'}}</div> -->
                      <!-- <div class="cost-text">Surface area : {{item?.details?.surfaceArea |
                        number:'1.0-2'}} sqft</div> -->

                      <div class="undr_lne_text" (click)="openmodal(item)">
                        View Details
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nb-tab>
        </nb-tabset>
      </nb-card-body>
    </nb-card>

    <app-modal class="bid-detail-mdl" [displayModal]="modalFlag" (cancelButtonClicked)="closeComplainModal()">
      <slot-content>
        <div class="bid_detail_modal">
          <div class="bid-card-wrapper">
            <div class="d-flex">
              <div class="bid_img">
                <img [src]="biddetails?.painter_details?.profile_image" onerror="src='./assets/default.png'">
              </div>
              <div class="info">
                <div class="painter-details">
                  <div class="div_upr d-flex">
                    <h3>
                      {{ biddetails?.painter_details?.full_name }}
                    </h3>
                    <span routerLink="/home/profile/{{
                        biddetails?.painter_details?.id
                      }}" class="undr_lne_text"><a>View Profile</a></span>
                  </div>
                  <div class="sub-info">
                    <img src="assets/icons/star.png" alt="star" class="logo" />
                    <span class="rating">
                      {{
                      biddetails?.painter_details?.avgRating
                      | number : "1.0-0"
                      }}.0</span>
                    <span class="no-of-reviews">(
                      {{ biddetails?.painter_details?.completed_projects }}
                      Reviews)</span>
                  </div>
                </div>
                <div class="bid-details">
                  <div class="bid-amount-text">Bid Amount</div>
                  <div class="bid-amount">${{ biddetails.bid }}</div>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="cover-letter">Cover Letter</div>
              <div class="bid-comment not-detailed">
                <p>
                  {{ biddetails.cover_letter }}
                </p>
              </div>
              <div class="d-flex dbl_btns bids_withdrwa_btns" *ngIf="biddetails.status == 'pending'">
                <button class="custom-button" (click)="acceptbid(biddetails.id, 'approved')">
                  Accept
                </button>
                <button class="border_button" (click)="acceptbid(biddetails.id, 'disapproved')">
                  Decline
                </button>
              </div>
              <div class="d-flex dbl_btns bids_withdrwa_btns" *ngIf="biddetails.status == 'approved'">
                <button class="custom-button" disabled>Approved</button>
                <button class="border_button" (click)="close()">Cancel</button>
              </div>
              <div class="d-flex dbl_btns bids_withdrwa_btns" *ngIf="biddetails.status == 'disapproved'">
                <button class="custom-button" disabled>Disapproved</button>
                <button type="button" class="border_button" (click)="close()">
                  Cancel
                </button>
              </div>
              <div class="d-flex dbl_btns bids_withdrwa_btns" *ngIf="biddetails.status == 'expired'">
                <button class="custom-button" disabled>Expired</button>
                <button type="button" class="border_button" (click)="close()">
                  Cancel
                </button>
              </div>
              <div class="d-flex dbl_btns bids_withdrwa_btns" *ngIf="biddetails.status == 'withdraw'">
                <button class="custom-button" disabled>Withdrawn</button>
                <button type="button" class="border_button" (click)="close()">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </slot-content>
    </app-modal>

    <app-modal class="bid-detail-mdl" [displayModal]="modalFlagBlock" (cancelButtonClicked)="closeComplainModal()">
      <slot-content>
        <div class="bid_detail_modal">
          <div class="bid-card-wrapper">
            <div class="d-flex">
              <div class="bid_img">
                <img src="/assets/Ellipse14.png" />
              </div>
              <div class="info">
                <div class="painter-details">
                  <div class="div_upr d-flex">
                    <h3>Wilson Stanton</h3>
                    <span routerLink="/home/profile" class="undr_lne_text"><a>View Profile</a></span>
                  </div>
                  <div class="sub-info">
                    <img src="assets/icons/star.png" alt="star" class="logo" />
                    <span class="rating"> 4.67</span>
                    <span class="no-of-reviews">(323 Reviews)</span>
                  </div>
                </div>
                <div class="bid-details">
                  <div class="bid-amount-text">Bid Amount</div>
                  <div class="bid-amount">$3,400.00</div>
                </div>
              </div>
            </div>
            <div class="content">
              <div class="cover-letter">Cover Letter</div>
              <div class="bid-comment not-detailed">
                <p>
                  Nulla facilisis facilisis vivamus arcu vulputate id interdum
                  id. Pharetra integer sed ac ipsum. Quisque diam sapien euismod
                  vitae laoreet curabitur ornare platea congue. Semper iaculis
                  turpis tincidunt nunc etiam fringilla in. Lorem est dolor
                  aliquet tellus. Tempor, cursus congue ac nec in aliquam ipsum.
                </p>
                <p>
                  In nibh pellentesque posuere sit ultrices vestibulum gravida
                  purus. Nibh non congue tortor, adipiscing. Euismod quis risus
                  et id lacus, ornare auctor diam. At sit condimentum cursus
                  gravida lobortis eget quam praesent. Elementum auctor pretium
                  faucibus dolor. Quisque ullamcorper nisl, neque purus rhoncus
                  rhoncus in arcu. Enim eget mauris neque, eu scelerisque non,
                  semper sed. Non nibh fermentum in porttitor mattis. Sit etiam
                  eget pellentesque urna. Massa fringilla turpis vitae viverra
                  ligula egestas at.
                </p>
              </div>
              <div class="d-flex dbl_btns">
                <button class="custom-button">Accept</button>
                <button class="border_button" (click)="modalFlag = true">
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>
      </slot-content>
    </app-modal>

    <!--------------------Room Detail Modal----------------------->

    <app-modal class="rooms_detail_modal modal_width painterSide mydtls" [displayModal]="modalFlag1"
      (cancelButtonClicked)="closeComplainModal()" *ngIf="roomdetails">      
      <slot-content>     
        <div class="div_inr_cost mrgn_ryt_txt">
          <div class="room-card-wrapper flex_wrap_class">
            <div class="div_imgs_room" *ngIf="roomdetails?.images">
              <img class="bed_innr" [src]="roomdetails?.images[0]?.image" alt="" />
            </div>
            <div class="div_imgs_room" *ngIf="!roomdetails?.images">
              <img class="bed_innr" [src]="projectDetails?.images[0]?.image" alt="" />
            </div>
            <div class="info tym_info">
              <div class="room-type">
                {{ roomdetails?.sel_rrom[0] | titlecase }}
              </div>
            </div>
          </div>
        </div>
        <div class="view-all-imgs">
          <div class="slider-rooms">
            <div class="room_all_images">
              <div class="slider_imgs" *ngIf="imageobjectarray.length">
                <ng-image-slider #nav [images]="imageobjectarray" [infinite]="false" [autoSlide]="1"
                  class="slider_img_size" slideImage="1">
                </ng-image-slider>
                <!-- [imageSize]="{width: '25%', height: '130px'}" -->
              </div>
            </div>
          </div>
        </div>
        <div class="room_dtl_sec row">
          <div class="col-4" *ngIf="roomdetails.rooms">
            <p class="cover-letter" *ngIf="roomdetails.rooms?.length">Areas to be painted</p>
            <p *ngFor="let item of roomdetails.rooms; let i =index;">
            {{ (item | titlecase)}} {{i === roomdetails.rooms.length -1 ? '' : ',' }}
              
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.doors">
            <p class="cover-letter">No of doors</p>
            <p>
              {{ roomdetails.doors }}
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.no_wondows">
            <p class="cover-letter">No of windows</p>
            <p>
              {{ roomdetails.no_wondows }}
            </p>
          </div>
           <div class="col-4" *ngIf="roomdetails.moving_furniture_exterior == 'no'">
            <p class="cover-letter">Moving furniture</p>
            <p>
             No
            </p>
          </div>
           <div class="col-4" *ngIf="roomdetails.moving_furniture_exterior == 'multiple_pieces'">
            <p class="cover-letter">Moving furniture</p>
            <p>
             Multiple pieces
            </p>
          </div>
           <div class="col-4" *ngIf="roomdetails.moving_furniture_exterior == 'couple_pieces'">
            <p class="cover-letter">Moving furniture</p>
            <p>
            Couple of pieces
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.closet">
            <p class="cover-letter">Closet</p>
            <p>
              {{ roomdetails.closet | titlecase }}
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.crown">
            <p class="cover-letter">Crown molding</p>
            <p>
              {{ roomdetails.crown | titlecase }}
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.base">
            <p class="cover-letter">Base molding</p>
            <p>
              {{ roomdetails.base | titlecase }}
            </p>
          </div>
        </div>
        <div class="room_dtl_sec row">
          <!--<div class="col-4">
                        <p class="cover-letter"> Color/Finish</p>
                        <p>
                            Decorative painting
                            Faux finish
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="cover-letter">Wallpaper</p>
                        <p>
                            Yes
                        </p>
                    </div>-->
          <div class="col-4">
            <p class="cover-letter">Surface Condition</p>
           
                        
            <p *ngIf="roomdetails?.surface_condition[0]  == 'good'">
              No repairs needed
            </p>
            <p *ngIf="roomdetails?.surface_condition[0]  == 'bad'">
              Need major repairs
            </p>
            <p *ngIf="roomdetails?.surface_condition[0]  == 'fair'">
             Need some repairs
            </p>
            <p *ngIf="roomdetails?.surface_condition[0]  == 'needHelpinMeasurment'">
               Need help in assessing surface condition
            </p>
          </div>
        </div>
        <div class="room_dtl_sec row">
          <div class="col-4">
            <p class="cover-letter">Work supply</p>
            <p>
              {{ projectDetails.providing_items | removeUnderscore }}
            </p>
          </div>
          <div class="col-8" *ngIf="projectDetails?.other_thing_painter_to_do?.length">
            <p class="cover-letter">Additional</p>
            <p *ngFor="let item of projectDetails.other_thing_painter_to_do; let i=index;">
               {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.other_thing_painter_to_do.length -1 ? '' : ',' }}
            </p>
          </div>
        </div>
               <div class="old_color_warp" *ngIf="roomdetails?.length">
                <p class="cover-letter"> Ceiling Details</p>
                <div class="d-flex" >
                 <p>
                  (Length-{{ roomdetails?.length }} x Width-{{ roomdetails?.width}})
                </p>
                 
                </div>
              </div>
               

        <div class="old_color_warp" *ngIf="roomdetails?.ceilingPreviousColor">

          <p class="cover-letter">Old Ceiling Color</p>
          <div class="d-flex">
            <div class="color_clr" style="background-color: {{ roomdetails.ceilingPreviousColor }};"></div>

          </div>
        </div>
        <div class="old_color_warp" *ngIf="roomdetails?.ceilingnewColor">
          <p class="cover-letter">New Ceiling Color</p>
          <div class="d-flex">
            <div class="color_clr" style="background-color: {{ roomdetails.ceilingnewColor }};"></div>

          </div>
        </div>
        <div *ngFor="let item of roomdetails?.walls; let i = index">
          <div *ngIf="i < 4">
            <div class="wall_dtl" *ngIf="item.wallHeight">
              <div class="d-flex">
                <div class="room-type">Wall {{ i + 1 }}</div>
                <p>
                  (Height-{{ item.wallHeight }} x Length-{{ item.wallWidth }})
                </p>
              </div>
            </div>
            <div *ngIf="item.wallHeight && item.wallWidth">
              <div class="old_color_warp">
                <p class="cover-letter">Old Color</p>
                <div class="d-flex">
                  <div class="color_clr" style="background-color: {{ item.previousColor }};"></div>
                  <div class="btn_tgs">
                    <button class="Otline_grey grey" *ngIf="item.previousWallpaper">
                      Has wallpaper
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.previousDecorative">
                      Decorative painting
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.previousFaux">
                      Faux Finish
                    </button>
                  </div>
                </div>
              </div>
              <div class="old_color_warp">
                <p class="cover-letter">New Color</p>
                <div class="d-flex">
                  <div class="color_clr sec_color" *ngIf="item.newColor" style="background-color: {{ item.newColor }};">
                  </div>
                  <div class="btn_tgs">
                    <button class="Otline_grey grey" *ngIf="item.newWallpaper">
                      Has Wallpaper
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.newDecorative">
                      Decorative painting
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.newFaux">
                      Faux Finish
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--   <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 2</div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>

                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 3 </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>

                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 4 </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Ceiling </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="ceiling_wrap">
                    <div class="old_color_warp">
                        <p class="cover-letter"> Old Color</p>
                        <div class="color_clr"></div>
                    </div>
                    <div class="old_color_warp">
                        <p class="cover-letter"> New Color</p>
                        <div class="color_clr sec_color"></div>
                    </div>
                </div>-->
      </slot-content>
    </app-modal>

    <app-modal class="project_report_mdl" [displayModal]="modalFlagreport" (cancelButtonClicked)="closeComplainModal()">
         
      <slot-content>
         
        <div class="bid_detail_modal">
          <div class="bid-card-wrapper">
            <p class="title exchng_clr">Report Project</p>
            <p>Select Reason for reporting this painter</p>
            <div class="select_room_no">
              <!--  <app-select-input [label]="orderOptions[0].optionLabel"
                              [inputOptions]="orderOptions"
                            
                              class="select-input">
                            </app-select-input>-->
              <select (change)="click($event)" [(ngModel)]="reason" class="select-input">
                <option *ngFor="let item of reporting_reason" [value]="item.reason">
                  {{ item.reason }}
                </option>
              </select>
            </div>
            <div class="d-flex dbl_btns">
              <button class="custom-button" (click)="sendreport()">
                Send Reports
              </button>
            </div>
          </div>
        </div>
      </slot-content>
    </app-modal>

    <app-modal [displayModal]="makeComplainDialogVisible" class="markComplain_modal" [title]="'Make a complain'"
      [subclaim]="'Select reason for complain.'" (confirmButtonClicked)="makeComplain()"
      [confirmButtonText]="'Make complain'" (cancelButtonClicked)="closeComplainModal()">
      <slot-content>
        <h2 class="rs_issue_ttl">Raise Issue</h2>
        <div class="input-wrapper">
          <p>Reason</p>
          <select [(ngModel)]="option">
            <option [value]="item.label" *ngFor="let item of options">
              {{ item.label }}
            </option>
          </select>
        </div>

        <div class="input-wrapper">
          <p>Please tell us what exactly has gone wrong</p>
          <textarea class="content" rows="5" [(ngModel)]="complaint" placeholder="Write a review..."></textarea>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button (click)="save()" class="custom-button">
            Submit your issue
          </button>
        </div>
      </slot-content>
    </app-modal>

    <app-modal [displayModal]="makeComplainDialogVisible1" class="markComplain_modal" [title]="'Rate'"
      (cancelButtonClicked)="closeComplainModal()">
      <slot-content>
        <form class="chat_wrap" [formGroup]="feedBackform">
          <app-painter-rating></app-painter-rating>
          <div class="input-wrapper">
            <p>Please write your review below</p>
            <textarea class="content" rows="5" formControlName="complaint" placeholder="Write a review..."></textarea>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <button (click)="savenew()" class="custom-button">Save</button>
          </div>
        </form>
      </slot-content>
    </app-modal>

    <app-modal [displayModal]="makeComplainDialogVisible1dispute" class="markComplain_modal revw_modl" [title]="'Rate'"
      (cancelButtonClicked)="closeComplainModal()">
      <slot-content>
        <div class="input-wrapper">
          <p>
            Title
            <span class="rvw_dt">({{ projectDetails?.dispute_time | date }})</span>
          </p>

          <!-- <p>{{ projectDetails?.dispute_time | date }}</p> -->

          <textarea class="content" disabled [(ngModel)]="projectDetails.dispute_option"
            placeholder="Write a complain..."></textarea>
        </div>

        <div class="input-wrapper">
          <p>Description</p>
          <textarea class="content" rows="5" [(ngModel)]="projectDetails.dispute_reason" disabled
            placeholder="Write a complain..."></textarea>
        </div>
        <div class="input-wrapper" *ngIf="projectDetails?.dispute_reply?.length">
          <p>
            Admin reply
            <span class="rvw_dt">({{ projectDetails?.dispute_reply[0].created_at | date }})</span>
          </p>
          <!-- <p>{{ projectDetails?.dispute_reply[0].created_at | date }}</p> -->

          <textarea class="content" rows="5" [(ngModel)]="projectDetails.dispute_reply[0].reply" disabled
            placeholder="Write a complain..."></textarea>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <button (click)="clos()" class="custom-button">Close</button>
        </div>
      </slot-content>
    </app-modal>
  </div>
</div>