import {Component, SimpleChanges} from '@angular/core';
import {BrushTableCustomFilterComponent} from '../../../MAIN_COMPONENTS/BrushTableCustomFilterComponent';


@Component({
  selector: 'app-report-status-picker',
  templateUrl: './report-status-picker.component.html',
  styleUrls: ['./report-status-picker.component.scss']
})
export class ReportStatusPickerComponent extends BrushTableCustomFilterComponent {

  constructor() {
    super();
  }
  emit(event: any): void {
    this.filter.emit(event.target.value);
  }

}
