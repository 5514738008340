import {Observable} from 'rxjs';
import {PageableResponse} from '../model/PageableResponse';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {CustomHttpParameterCodec} from '../_helpers/requests/CustomHttpParameterCodes';
import {TokenStorageService} from './token-storage.service';
import {EReportStatus} from '../model/enums/EReportStatus';
import {ProjectService} from './project.service';
import {EProjectStatus} from '../model/enums/EProjectStatus';
import {MessageService} from './message.service';
import {BrushNotification} from './notification-service';
import {getRedirectLinkForProjectType} from '../model/enums/EProjectType';
import {ChangedProjectData} from '../model/ChangedProjectData';

const API = environment.SERVER_BASE_URL + 'admin';

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  public encoder: CustomHttpParameterCodec;

  constructor(private http: HttpClient, private projectService: ProjectService, private messageService: MessageService) {
    this.encoder = new CustomHttpParameterCodec();
  }

  getUsersStats(lastDays: number): Observable<any> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (lastDays !== undefined && lastDays !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        lastDays, 'lastDays');
    }
    return this.http.get<any>(API + '/user-stats', {params: queryParameters});
  }

  changeHrByState(hrByStateId: number, newHMean: any): Observable<any> {
    return this.http.put<any>(API + '/hr-by-state/' + hrByStateId + '/' + newHMean, null);
  }

  getFrByRoomType(): Observable<any> {
    return this.http.get<any>(API + '/fr-by-room-type');
  }

  getAlgorithmFactors(): Observable<any> {
    return this.http.get<any>(API + '/algorithm-factors');
  }

  updateAlgorithmFactors(updateAlgorithmFactors: any): Observable<any> {
    return this.http.put<any>(API + '/algorithm-factors', updateAlgorithmFactors);
  }

  updateFrByRoomType(updateFrByRoomType: any): Observable<any> {
    return this.http.put<any>(API + '/fr-by-room-type', updateFrByRoomType);
  }

  updateReportStatus(reportId: any, newReportStatus: EReportStatus): Observable<any> {
    return this.http.put<any>(API + '/change-report-status/' + reportId + '/' + EReportStatus[newReportStatus], null);
  }

  changeProjectStatus(projectTag: string, projectStatus: EProjectStatus): Observable<any> {
    return this.http.put<any>(API + '/change-project-status/' + projectTag + '/' + EProjectStatus[projectStatus], null);
  }

  changeProjectData(projectTag: string, changedData: ChangedProjectData): Observable<any> {
    return this.http.put<any>(API + '/change-project-data/' + projectTag, changedData);
  }
}
