import { Component, OnInit } from '@angular/core';
import {HttpserviceService} from '../../services/httpservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
    import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-profile-user-edit',
  templateUrl: './my-profile-user-edit.component.html',
  styleUrls: ['./my-profile-user-edit.component.css']
})
export class MyProfileUserEditComponent implements OnInit {
  profileForm!: FormGroup
   imgForm: FormGroup
   previewImage2: any
       selectedFile2: any;
       path :any= 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
 profile: any
  constructor( private authService: HttpserviceService, private fb: FormBuilder,private toastr: ToastrService) {
   this.profileForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
      name: ['',[Validators.required]],
      profile_image: [null]

    }),
      this.imgForm = this.fb.group({
      //  question: '',  
      attachment: this.fb.group({
        path: [''],
        name: [''],
        img: []
      }),
      //   type: 'mcq'

    });
    
    
    }

  ngOnInit(): void {
  this.getprofile()
  }

  changeprofile(){
  if(!this.previewImage2){
        return
  }
  let obj={
        profile_image:'',
        full_name : this.profileForm.value.name,
        email : this.profileForm.value.email,
        phone_number : this.profileForm.value.phone,
  }
  const formdata=new FormData()
   formdata.append('full_name',this.profileForm?.get('name')?.value)
      formdata.append('profile_image',this.selectedFile2)
   formdata.append('email',this.profileForm.value.email)
         formdata.append('phone_number',this.profileForm.value.phone)
    //  formdata.append('full_name',this.profileForm?.get('full_name')?.value)


   this.authService.updateprofile(formdata).subscribe((response : any) => {
       ///  this.profile= response.profileData
           this.toastr.success('Profile Updated Successfully!', 'Success!');
           this.getprofile()
      })
  }

      fileUpload2(event : any) {
      //console.log(event)
    
    console.log(event, event.target.files[0])
    if(event.target.files[0] > 2000){
     this.authService.showMessage({
             message: 'Please select file less than 2kb'
          })
          return;
    }
    this.selectedFile2 = event.target.files[0]
     const reader = new FileReader();
    reader.onload= function  (this: any){
      this.profileForm.patchValue({
        sign_img: reader.result

      })
                          this.previewImage2 = reader.result;

     }.bind(this)
     reader.readAsDataURL(this.selectedFile2);
    console.log(this.selectedFile2)
   //  this.previewImage2 =this.selectedFile2.name;
  }

  getprofile(){
		 this.authService.getprofile().subscribe((response : any) => {
         this.profile= response.profileData
         this.profileForm.patchValue({
         'email': this.profile.email,
         'phone': this.profile.phone_number,'name': this.profile.full_name,
        
         })
         this.previewImage2 =  this.profile.profile_image
           this.selectedFile2 =  this.profile.profile_image
         console.log(this.profileForm)
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }
}
