import {Pipe, PipeTransform} from '@angular/core';

import {TProvidingItems} from '../model/enums/tags/TProvidingItems';
import {TSurfaceArea} from '../model/enums/tags/TSurfaceArea';
import {SurfaceCondition} from '../model/enums/template/SurfaceCondition';
import {RoomsNumber} from '../model/enums/template/RoomsNumber';
import {HasWallpaper} from '../model/enums/template/HasWallpaper';
import {CeilingHeight} from '../model/enums/template/CeilingHeight';
import {WorkingSchedule} from '../model/enums/template/WorkingSchedule';


@Pipe({name: 'tagPipe'})
export class TagPipe implements PipeTransform {

  //fixme:
  transform(value: any, type: string): any {
    switch (type) {
      case 'ceilingHeight':
        return this.ceilingHeight(value);
      case 'cleaningFloors':
        return 'floors cleaning';
      case 'cleaningWindows':
        return 'windows cleaning';
      case 'colorChange':
        return 'regular painting';
      case 'disposingDebris':
        return 'disposingDebris';
      case 'fauxFinish':
        return 'fauxFinish';
      case 'movingFurniture':
        return 'furniture moving';
      case 'hasWallpaper':
        return this.hasWallpaper(value);
      case 'lightFixtures':
        return 'lightFixtures';
      case 'localType':
        return 'localType';
      case 'paintingCeiling':
        return 'paintingCeiling';
      case 'paintingDoors':
        return 'paintingDoors';
      case 'paintingTrim':
        return 'paintingTrim';
      case 'paintingWalls':
        return 'paintingWalls';
      case 'paintingWindows':
        return 'paintingWindows';
      case 'powerWash':
        return 'powerWash';
      case 'propertyType':
        return 'propertyType';
      case 'providingItems':
        return this.providingItems(value);
      case 'roomsNumber':
        return this.roomsNumber(value);
      case 'sameColor':
        return 'sameColor';
      case 'schedulingPreferences':
        return this.schedulingPreferences(value);
      case 'surfaceArea':
        return this.surfaceArea(value);
      case 'surfaceCondition':
        return this.surfaceCondition(value);
      case 'textureCoating':
        return 'textureCoating';

    }
  }

  schedulingPreferences(value: WorkingSchedule): any{
    let returnValue;
    switch (value){
      case WorkingSchedule.BETWEEN_8_AND_16:
        returnValue = '8-16 work';
        break;
      case WorkingSchedule.BETWEEN_16_AND_22:
        returnValue = '16-22 work';
        break;
      case WorkingSchedule.CAN_ADJUST:
        returnValue = 'Flexible schedule';
        break;
      case WorkingSchedule.DURING_NIGHT_SHIFT:
        returnValue = 'Night shift';
        break;
    }
    return returnValue;
  }

  surfaceArea(value: TSurfaceArea): any {
    let returnValue;
    switch (value) {
      case TSurfaceArea.LESS_THAN_30 :
        returnValue = 'Small area';
        break;
      case TSurfaceArea.MORE_THAN_30_LESS_THAN_50 || TSurfaceArea.MORE_THAN_50_LESS_THAN_70:
        returnValue = 'Medium area';
        break;
      case TSurfaceArea.MORE_THAN_70:
        returnValue = 'Large area';
        break;
      case TSurfaceArea.NEED_HELP_IN_MEASURE:
        returnValue = 'Area measurement needed';
    }
    return returnValue;
  }

  surfaceCondition(value: SurfaceCondition): string {
    let returnValue;
    switch (value) {
      case SurfaceCondition.BAD :
        returnValue = 'Surface needs major repairs';
        break;
      case SurfaceCondition.POOR:
        returnValue = 'Surface needs repairs';
        break;
      case SurfaceCondition.FAIR:
        returnValue = 'Fine surface condition';
        break;
      case SurfaceCondition.GOOD:
        returnValue = 'Excellent surface condition';
        break;
      case SurfaceCondition.NEED_HELP_IN_MEASURE:
        returnValue = 'Need expert opinion for surface';
        break;
    }
    return returnValue;
  }

  providingItems(value: TProvidingItems): string {
    let returnValue = '';
    switch (value) {
      case TProvidingItems.NO_ITEMS :
        returnValue = 'No items provided';
        break;
      case TProvidingItems.ONLY_PAINT:
        returnValue = 'Customer provides paint';
        break;
      case TProvidingItems.ALL_BUT_NEED_LIST:
        returnValue = 'Customer provides all items';
        break;
    }
    return returnValue;
  }

  hasWallpaper(value: HasWallpaper): string {
    let returnValue = '';
    switch (value) {
      case HasWallpaper.NO_WALLPAPER :
        returnValue = 'No wallpaper';
        break;
      case HasWallpaper.HAS_WALLPAPER:
        returnValue = 'Wallpaper';
        break;
    }
    return returnValue;
  }

  roomsNumber(value: RoomsNumber): string {
    let returnValue = '';
    switch (value) {
      case RoomsNumber.ONE_ROOM :
        returnValue = 'One room';
        break;
      case RoomsNumber.TWO_ROOMS:
        returnValue = 'Two rooms';
        break;
      case RoomsNumber.THREE_ROOMS:
        returnValue = 'Three rooms';
        break;
      case RoomsNumber.FOUR_ROOMS:
        returnValue = 'Four rooms';
        break;
      case RoomsNumber.FIVE_ROOMS:
        returnValue = 'Five rooms';
        break;
      case RoomsNumber.SIX_ROOMS:
        returnValue = 'Six rooms';
        break;
      case RoomsNumber.SEVEN_ROOMS:
        returnValue = 'Seven or more rooms';
        break;
    }
    return returnValue;
  }

  private ceilingHeight(value: CeilingHeight): any {
    let returnValue;
    switch (value) {
      case CeilingHeight.FOUR_FT:
        returnValue = 'Normal height';
        break;
      case CeilingHeight.FIVE_FT:
        returnValue = 'Normal height';
        break;
      case CeilingHeight.SIX_FT :
        returnValue = 'Ceiling height above average';
        break;
      case  CeilingHeight.SEVEN_FT:
        returnValue = 'Ceiling height above average';
        break;
      case CeilingHeight.EIGHT_AND_MORE_FT:
        returnValue = 'High altitude painting';
        break;
    }
    return returnValue;
  }
}
