import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BidsService } from '../../../../services/bids.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PageableComponent } from '../../../../components/MAIN_COMPONENTS/PageableComponent';
import { EBidStatus } from '../../../../model/enums/EBidStatus';
import { Bid } from '../../../../model/Bid';
import { SelectInputOption } from '../../../../model/components/SelectInputOption';
import { PageableResponse } from '../../../../model/PageableResponse';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { HttpserviceService } from '../../../../services/httpservice.service';

    import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-bids',
  templateUrl: './my-bids.component.html',
  styleUrls: ['./my-bids.component.scss']
})
export class MyBidsComponent extends PageableComponent implements OnInit {

  modalFlag: boolean = false;
  displayDetailsModalVisible = false
  bids: any=[]
  proj_id: any
  wid: any
     selectedtab: any

  expiredbids: any=[]
  accepetedbids: any=[]
  deletedbids: any=[]
  rejectedbids: any=[]
  @ViewChild('bidStatuses') bidStatuses!: ElementRef;
  bidsAcceptedPageableResponse!: PageableResponse;
  bidsPendingPageableResponse!: PageableResponse;
  bidsDeniedPageableResponse!: PageableResponse;
  bidsDeletedPageableResponse!: PageableResponse;
  bidsExpiredPageableResponse!: PageableResponse;
  activeStatus = EBidStatus.ACCEPTED;
  modalFlagnew: boolean = false

  orderOptions: SelectInputOption[] = [
    { optionLabel: 'Received date: Newest', optionValue: 'dateTime', sortDir: 'DESC', optionSelected: true, optionDisabled: false },
    { optionLabel: 'Received date: Oldest', optionValue: 'dateTime', sortDir: 'ASC', optionSelected: false, optionDisabled: false }
  ];

  constructor(private bidsService: BidsService,  private authService: HttpserviceService,private toastr: ToastrService,private route: ActivatedRoute,
    private router: Router) {
    super();
  }

  ngOnInit(): void {
    if(localStorage.getItem('currenttab')){
                this.selectedtab = localStorage.getItem('currenttab')
                console.log(this.selectedtab)
                     this.updateRoute({
          step: this.selectedtab,
         
        })
                }else{
                    this.selectedtab ='one'
                }
  this.getbids()
  this.getacceptedbids()
    this.getBids(EBidStatus.ACCEPTED).subscribe(res => {
      this.bidsAcceptedPageableResponse = res;
      this.pageableResponse = res;
    });
    this.getBids(EBidStatus.PENDING).subscribe(res => {
      this.bidsPendingPageableResponse = res;
    });
    this.getBids(EBidStatus.DENIED).subscribe(res => {
      this.bidsDeniedPageableResponse = res;
    });
    this.getBids(EBidStatus.DELETED).subscribe(res => {
      this.bidsDeletedPageableResponse = res;
    });
    this.getBids(EBidStatus.EXPIRED).subscribe(res => {
      this.bidsExpiredPageableResponse = res;
    });
  }

  viewDetails(event: Bid): void {
    this.router.navigate(['/home/my-bids/bid/' + event.tag]);
  }

  open(id: any, p_id: any){
        this.modalFlagnew = true
        this.wid=id
        this.proj_id = p_id
  }

  filter(startFromFirstPage: boolean): void {
    if (startFromFirstPage) {
      this.page = 0;
    }
    this.bidsService.getAllBidBasic(
      this.activeStatus,
      this.page,
      this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue,
      this.selectedOrdering.sortDir).subscribe(response => {
        switch (this.activeStatus) {
          case EBidStatus.ACCEPTED:
            this.bidsAcceptedPageableResponse = response;
            break;
          case EBidStatus.PENDING:
            this.bidsPendingPageableResponse = response;
            break;
          case EBidStatus.DENIED:
            this.bidsDeniedPageableResponse = response;
            break;
          case EBidStatus.DELETED:
            this.bidsDeletedPageableResponse = response;
            break;
          case EBidStatus.EXPIRED:
            this.bidsExpiredPageableResponse = response;
            break;
        }
      }, error => {
        console.log(error);
      });
  }
   updateRoute(queryParams: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge'
      }
    );
  }
   yourFunc(e: any){
 
          this.selectedtab=e
         this.updateRoute({
          step: this.selectedtab,
         })
       localStorage.setItem('currenttab',this.selectedtab)
  //     this.router.navigate(['/home/project-detail/'+ id])
       
  }
  get selectedOrdering(): any {
    return this.orderOptions.filter(pt => pt.optionSelected)[0];
  }

  changeOrdering(event: any): void {
    this.orderOptions.forEach(item => {
      if (item.optionLabel === event.optionLabel) {
        item.optionSelected = true;
      } else {
        item.optionSelected = false;
      }
    });
    this.filter(false);
  }
  getBids(status: EBidStatus): Observable<any> {
    return this.bidsService.getAllBidBasic(
      status,
      this.page,
      this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue,
      this.selectedOrdering.sortDir);
  }
  onChangeTab(event: any): void {
    const tabTitle = event.tabTitle.toLowerCase();
    if (tabTitle.includes('pending')) {
      this.activeStatus = EBidStatus.PENDING;
    } else if (tabTitle.includes('denied')) {
      this.activeStatus = EBidStatus.DENIED;
    } else if (tabTitle.includes('accepted')) {
      this.activeStatus = EBidStatus.ACCEPTED;
    } else if (tabTitle.includes('expired')) {
      this.activeStatus = EBidStatus.EXPIRED;
    } else if (tabTitle.includes('deleted')) {
      this.activeStatus = EBidStatus.DELETED;
    }
    this.filter(true);
  }
  viewDemoDetailss(id:any){

  }
  viewDemoDetails(id: any) {
    this.router.navigate(['/home/my-bids/bid/' + id]);
  }
  makeComplain() { }
  openAcceptBidModal() { }
  checkPendingOrNewStatus(): boolean { return true }
  openDenyBidModal() { }
  closeComplainModal() {
    this.modalFlag = false
  }
   closeComplainModalnew() {
    this.modalFlagnew = false
  }
  getacceptedbids(){
  let param= "status=approved" 
          this.authService.getbids(param).subscribe((response: any) => {
          console.log(response)
      this.accepetedbids = response.response
      
     console.log(this.bids)
      this.bids.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.project_details?.project_sub_details?.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });
    })
    let paramr= "status=disapproved" 
          this.authService.getbids(paramr).subscribe((response: any) => {
          console.log(response)
      this.rejectedbids = response.response
      
     console.log(this.bids)
      this.bids.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val?.project_details?.project_sub_details?.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });
       })
        let paramrm= "status=withdraw" 
          this.authService.getbids(paramrm).subscribe((response: any) => {
          console.log(response)
      this.deletedbids = response.response
      
     console.log(this.bids)
      this.bids.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val?.project_details?.project_sub_details?.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);


          
      });
       })
        let paramrmexp= "status=expired" 
          this.authService.getbids(paramrmexp).subscribe((response: any) => {
          console.log(response)
      this.expiredbids = response.response
      
     console.log(this.bids)
      this.bids.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val?.project_details?.project_sub_details?.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });
       })
  }

  getbids(){
    let param= "status=pending" 

          this.authService.getbids(param).subscribe((response: any) => {
          console.log(response)
      this.bids = response.response
      
     console.log(this.bids)
      this.bids.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val?.project_details?.project_sub_details?.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });
     

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

   withdraw(status: any){
  let obj={
         'bid_status1': status,
        'id' : this.wid,
        'type': 'withdraw',
        'project_id' : this.proj_id
  }
   this.authService.acceptRejectBidschange( obj).subscribe((response : any) => {
   this.toastr.success('Status updated!', 'Success!')
             this.modalFlagnew = false
             this.ngOnInit()
        	

				
      });
  }

  cancel(){
        this.modalFlagnew= false
  }
}
