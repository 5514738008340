<div class="wrapper" id="uploadedImages" #galleryOverflowScroll>
  <div class="nav-icon" *ngIf="isGalleryOverflown()" (pointerdown)="mouseDownSwipeLeft()" (pointerup)="clearInterval()"
       (click)="swipeLeft()">
    <img src="assets/project-creator/arrow-left.png">
  </div>
  <div class="upload-container" (drop)="onFileDropped($event)" #container>
    <input type="file"
           #fileDropRef
           id="fileDropRef"
           [accept]="'.jpg, .jpeg, .png, .gif'"
           [multiple]="multiple"
           (change)="fileBrowseHandler($event)"/>
    <img src="assets/project-creator/upload-file.png">
    <div class="hint">Upload</div>
  </div>
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="image-wrapper">
      <div class="image-sub-wrapper">
        <img [src]="file.src" alt="image" class="uploaded-image">
        <img src="assets/project-creator/delete-uploaded-file.png" class="delete-image" (click)="deleteFile(i)">
      </div>
    </div>
  </div>
  <div class="nav-icon" *ngIf="isGalleryOverflown()" (pointerdown)="mouseDownSwipeRight()" (pointerup)="clearInterval()"
       (click)="swipeRight()">
    <img src="assets/project-creator/arrow-right.png">
  </div>

</div>
