<div class="paymentCardWrap">
  <div class="paymentCard card">
    <div class="bgLayer"></div>
    <img src="./assets/imgs/payment.svg" class="payImg" alt="">
    <div #paypalRef class="payInn">
      <div class="zTop">
        <h1>${{amt1}}.00</h1>
      </div>
    </div>
  </div>
</div>

<!-- <div>
    <p *ngIf="sucessData?.card_holder_name"><span>card_holder_name    </span><br> {{sucessData?.card_holder_name}}</p>
    <p *ngIf="sucessData?.transaction_id"><span>transaction_id</span><br>{{sucessData?.transaction_id}}</p>
    <p *ngIf="sucessData?.transaction_time"><span>transaction_time</span> <br>{{sucessData?.transaction_time |date}}</p>
    <p *ngIf="sucessData?.status"><span>status</span><br>{{sucessData?.status}}</p>
</div> -->






<!-- <div class="view-bid-details">
    <a class="button_theme" (click)="modalFlag = true">Payment Success</a>
</div> -->


<app-modal class="painterSide" [displayModal]="modalFlag" (cancelButtonClicked)="closeComplainModal()">
  <slot-content>

    <form>
      <div class="payment-status-success-popup">
        <img src="assets/check.gif" class="gif_size">
        <p class="payment_main_head">Payment Successfull!!</p>
        <div class="d-flex justify-content-between align-items-center mt-3">
          <div class="left_content">
            <p>Card Holder Name</p>
            <p>Status</p>
            <!-- <p>Mobile</p> -->
            <!-- <p>Email</p> -->
          </div>
          <div class="right_content">
            <p>{{sucessData?.card_holder_name}}</p>
            <p>{{sucessData?.status}}</p>
            <!-- <p>+91 8582258658</p> -->
            <!-- <p>abcd@gmail.com</p> -->
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center amount_paid mt-3">
          <p>Amount Paid</p>
          <p>${{amt1}}.00</p>
        </div>

        <div class="d-flex justify-content-between align-items-center left_content">
          <p>Transaction Id</p>
          <p>{{sucessData?.transaction_id}}</p>
        </div>

      </div>
      <div class="text-center mt-3">
        <button class="btn custom-button" routerLink="/home/my-profile">Back To Profile</button>
      </div>
    </form>

  </slot-content>
</app-modal>