import {Component} from '@angular/core';
import {EChartsOption} from 'echarts';
import {AdminService} from '../../../services/admin.service';

@Component({
  selector: 'app-user-stats-chart',
  templateUrl: './user-stats-chart.component.html',
  styleUrls: ['./user-stats-char.component.scss']
})
export class UserStatsChartComponent {
  chartOption!: EChartsOption;

  constructor(private adminService: AdminService)
  {
    this.getData(14);
  }

  emit(event: any): void {
    this.getData(event.target.value);
  }
  getData(lastDays: number): void{
    this.adminService.getUsersStats(lastDays).subscribe(response => {
    this.chartOption = {
    title: {text: 'User stats'},
    legend: {
      data: ['Last sign ins', 'Sign ups']
    },
    xAxis: {

      data: response.date
    },
    yAxis: {},
    tooltip: {},
    series: [
      {
        name: 'Last sign ins',
        type: 'bar',
        data: response.signIns,
        color: '#9633D3' // $--main-dark,

      },
      {
        name: 'Sign ups',
        type: 'bar',
        color: '#FD71AF', // $--accent-dark: ;
        data: response.signUps
      }
      ]
  };
});
  }
}
