import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AdminService} from '../../../services/admin.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-brush-table-algorithm-factors',
  templateUrl: './algorithm-factors-table.component.html',
  styleUrls: ['./algorithm-factors-table.component.scss']
})
export class AlgorithmFactorsTableComponent implements OnInit {
  algorithmFactors: any;
  changePropertyModalVisible = false;

  algorithmFactorsForm = this.formBuilder.group({
    crownMolding: ['', [Validators.required]],
    doorsQuantity: ['', [Validators.required]],
    floorMolding: ['', [Validators.required]],
    hourlyCeiling: ['', [Validators.required]],
    hourlyPaint: ['', [Validators.required]],
    hourlyWallpaper: ['', [Validators.required]],
    largeCloset: ['', [Validators.required]],
    mediumCloset: ['', [Validators.required]],
    smallCloset: ['', [Validators.required]],
    notSameColor: ['', [Validators.required]],
    painterMarkup: ['', [Validators.required]],
    sameColor: ['', [Validators.required]],
    surfaceBad: ['', [Validators.required]],
    surfaceFair: ['', [Validators.required]],
    surfaceGood: ['', [Validators.required]],
    surfaceMajorRepairs: ['', [Validators.required]],
    windowsQuantity: ['', [Validators.required]],
  });

  constructor(private adminService: AdminService, private formBuilder: FormBuilder, private toastrService: ToastrService) {
  }

  ngOnInit(): void {
    this.adminService.getAlgorithmFactors().subscribe(res => {
      this.algorithmFactors = res;
      this.algorithmFactorsForm.patchValue(this.algorithmFactors);
    });
  }
  showModal(): void {
    this.changePropertyModalVisible = true;
  }

  changeAlgorithmFactors(): void {
    this.adminService.updateAlgorithmFactors(this.algorithmFactorsForm.value).subscribe(res => {
      this.algorithmFactors = res;
      this.hideModal();
      this.toastrService.success('Updated algorithm factors', 'Success');
      this.algorithmFactorsForm.patchValue(this.algorithmFactors);
    }, error => {
      this.toastrService.success(error.message, 'Failure');
    });
  }

  hideModal(): void {
    this.changePropertyModalVisible = false;
  }

}



