<div [ngClass]="hide ? 'bell-wrapper hide' : 'bell-wrapper'">
  <img src="assets/icons/notifications-icon.png" alt="notifications-icon" (click)="show()">
  <div *ngIf="notifications>0" class="unread-notification-count">{{ notifications}}</div>
  <div class="notification-list" *ngIf="shownew ">

   <div class="ntctnlp_wrap" *ngFor="let item of notificationsnew">

    <div class="notification-wrapper" *ngIf="  isPainter && item.type=='dispute'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type);shownew= !shownew">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/my-jobs/completed-details/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>
     <div class="notification-wrapper" *ngIf=" isCustomer && item.type=='dispute'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type);shownew= !shownew">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>
      <div class="notification-wrapper" *ngIf="isCustomer && item.type=='project approval'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type);shownew= !shownew">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>
     <div class="notification-wrapper" *ngIf="isPainter && item.type=='project' && item?.project?.status== 'approved'">
    
        <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
        <div class="notification-main" (click)="unseennoti(item.id,item.type);shownew= !shownew">
    
            <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/my-project-detail/{{item.project_id}}">{{item.title}}</span></div>
            <div class="notification-content">{{item.body}}</div>
            <footer>
                <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
                <div class="clear-content" (click)="delete(item.id)">
                    <span class="clear-text">Clear</span>
                    <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
                </div>
            </footer>
        </div>
    </div>

<div class="notification-wrapper" *ngIf="isPainter && item.type=='bid'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main" (click)="unseennoti(item.id,item.type);shownew= !shownew">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/my-bids/bid/{{item.type_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isCustomer && item.type=='bid'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main"  (click)="unseennoti(item.id,item.type);shownew= !shownew">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)" ><span routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>


<div class="notification-wrapper" *ngIf="isPainter && item.type=='project' && (item?.project?.status == 'brushed' || item?.project?.status == 'completed')">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png" >
    <div class="notification-main"  (click)="unseennoti(item.id,item.type);shownew= !shownew">

        <div *ngIf="item?.project?.status == 'brushed'" class="notification-title" ><span (click)="unseennoti(item.id,item?.project?.status);viewDemoDetailsscomp(item?.project_id, item?.project?.status)"> {{item.title}}</span></div>
                <div *ngIf="item?.project?.status == 'completed'" class="notification-title" ><span (click)="unseennoti(item.id,item?.project?.status);viewDemoDetailss(item?.project_id, item?.project?.status) ">{{item.title}}</span></div>

        <div class="notification-content" (click)="unseennoti(item.id,item.type)">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isCustomer && item.type=='project'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main"  (click)="unseennoti(item.id,item.type);shownew= !shownew">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span  routerLink="/home/project-detail/{{item.project_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isPainter && item.type=='chat'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main"  (click)="unseennoti(item.id,item.type);shownew= !shownew">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"><span routerLink="/home/chat/{{item.room_id}}/{{item.primary_room_id}}">{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>

<div class="notification-wrapper" *ngIf="isCustomer && item.type=='chat'">
    
    <img class="icon" src="./assets/icons/notifications/notification-icon-star.png">
    <div class="notification-main" (click)="unseennoti(item.id,item.type);shownew= !shownew">

        <div class="notification-title" (click)="unseennoti(item.id,item.type)"  routerLink="/home/cus-chat/{{item.room_id}}/{{item.primary_room_id}}"><span>{{item.title}}</span></div>
        <div class="notification-content">{{item.body}}</div>
        <footer>
            <div class="how-long-ago">{{item.created_at|date}} |  {{moment(item.created_at).fromNow()}}</div>
            <div class="clear-content" (click)="delete(item.id)">
                <span class="clear-text">Clear</span>
                <img src="./assets/icons/notifications/notification-delete.png" alt="delete">
            </div>
        </footer>
    </div>
</div>
</div>
    <div class="see-all" *ngIf="notificationsnew.length" (click)="shownew= !shownew" routerLink="/home/notifications-new">See All Notifications</div>
    <div class="no-new-notifications" *ngIf="!notificationsnew.length">
      You don't have any notifications</div>
  </div>
</div>