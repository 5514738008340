import {ServerDataSource} from 'ng2-smart-table';
import {AfterViewInit, Component} from '@angular/core';


@Component({
  template: ''
})
export class BaseTableViewComponent{
  source!: ServerDataSource;
  settings: any;
  noDataMessage = 'Data not found';
  pageSize = 10;
  totalElements!: number;

}
