import { Component, OnInit } from '@angular/core';
import {HttpserviceService} from '../../../services/httpservice.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
profile:any =[]
  constructor(private authService: HttpserviceService) { }

  ngOnInit(): void {
  this.getlink()
  }

   getlink(){
		 this.authService.getfooterlinks().subscribe((response : any) => {
         this.profile= response.icon[0].socialIcon
         console.log(this.profile)


      })
      }
      getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
