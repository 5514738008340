import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { WhiteSpaceValidator } from '../../../../_helpers/white-space.validator';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GreaterThanZeroValidator } from '../../../../_helpers/greater-than-zero-validator';
import { AtLeastOneFieldRequiredValidator } from '../../../../_helpers/one-field-required.valdiator';

@Component({
  selector: 'app-make-new-project-advanced',
  templateUrl: './make-new-project-advanced.component.html',
  styleUrls: ['./make-new-project-advanced.component.scss']
})
export class MakeNewProjectAdvancedComponent implements OnInit {
shownextbutton: boolean = false
  type: any
  proppaint: any
  opennewmodal: boolean = false
  showtitle: boolean = false
  nowindowsext: boolean = true
  colorFinish: any = []
  modalFlagnewopen: boolean = false
  project_type: any
  extresp: any
  //materials: any;
  newaddedarray: any = []
  low_range: any = []
  titlenew: any
  desc: any
  roof: any
  palletshowhide: any =[]
  palleshowhidenew : any =[]
  showprev: boolean = false
  //isrrof: boolean= false
  gallaonswall: any = []
  gallonsceiling: any = []
  high_range: any = []
  shoenxt: boolean = true
  sum: any
  shownesxt: boolean = false
  newgallonceiling: any
  newgallonwll: any
  newhighrange: any
  newlowrange: any
  newfile: any = []
  arrayfinal: any = []
  newcolor: any
  move_furniture: boolean = false
  prevcolor: any
  details: any = {}
  filesnewnew: any = []
  p_name: any
  otherproperty: any = []
  shownexttow: boolean = true
  provdeitem: any
  addedrroms: any = []
  sel_cond: any = []
  providibf_items: boolean = true
  propertyshow: boolean = false
  height: any
  itemsnew!: FormArray;
  orderForm!: FormGroup;
  addrooms: boolean = false
  showither: boolean = false
  showprojectdetails: boolean = false
  selected_rroms: any = []
  n: any = []
  stepperIndex: any = 0;
  //projectDetailsSections: any=[]
  roompainted: any = []
  windows: any
  nowindows: boolean = false
  nodoors: boolean = false
  closetye: boolean = false
  sconction: boolean = false
  iscrwom: boolean = false
  baseyes: boolean = false
  ceilingis: boolean = false
  surface_condition: any
  modalFlagnew: boolean = false
  add_wall: boolean = false
  doors: any
  closetshoose: any
  furnitervalue: any
  filesn: any = []
  modalFlag12: boolean = false
  addedwalls: any = []
  pType: boolean = false
  whendone: boolean = false
  all_applicables: boolean = false
  when_to_be_done: any
  property_typemew: any
  items_applicatbleother: any = []
  projectapplivcablesother: any = []
  filesnew: any = []
  showprevaddroom: boolean = false
  new: any = []
  setextroomnewshow: boolean = false
  setextroom: boolean = false
  color_finish: boolean = false
  showmaterial: boolean = false
  showsurface: boolean = false
  home_type: any
  puwallpaper: any
  newfilenew: any = []
  shownew: boolean = false
  sconctionext: boolean = false
  isrrof: boolean = false
  filesnewnewnew: any = []
  addedmat: any = []
  items_applicatble: any = []
  selected_ids_rooms: any = []
  selected_cond: any = []
  addroomsext: boolean = false
  show: boolean = true
  setprevaddroomnewshow: boolean = false
  closet: any
  surface_cond: boolean = false
  showwallpaper: boolean = false
  items_to_painted: any = []
  selected_ids: any = []
  height_ceiling: boolean = true
  painted: boolean = true
  rooms_painted: boolean = false
  showwhatpainted: boolean = false
  room: any
  setprevaddroom: boolean = false
  newobj: any = {}
  material: any
  iscrowyesn: boolean = false
  wallFormGroup: FormGroup;
  ceilingGroup: FormGroup;
  productForm!: FormGroup
  productFormext!: FormGroup
  crown: any
  base: any
  sufvalue: any
  projectapplivcables: any = []
  materials: any = []
  surfacevalue: any = [{ 'name': 'Less than 30 ft�', 'value': 'less_than_30' }, { 'name': '30-50ft� ', 'value': '30_50' }, { 'name': '50-70ft�', 'value': '50_70' }, { 'name': 'More than 70ft� ', 'value': 'more_70' }, { 'name': 'I am not sure ', 'value': 'not_sure' },]
  itemsother: any = [
    { id: 1, label: 'Power wash', selected: false, value: 'powerWash', mapLabel: 'powerWash' },
    { id: 2, label: 'Cleaning windows', selected: false, value: 'cleaningWindows', mapLabel: 'cleaningWindows' },
    { id: 3, label: 'Painting gutters', selected: false, value: 'cleaningFloors', mapLabel: 'cleaningFloors' },
    { id: 4, label: 'Disposing of the debris', selected: false, value: 'disposingDebris', mapLabel: 'disposingDebris' },
  ]
  all_applicables_list: any = [
    { id: 1, label: 'Moving furniture', selected: false, value: 'moving_furniture', mapLabel: 'movingFurniture' },
    { id: 2, label: 'Cleaning windows', selected: false, value: 'cleaning_windows', mapLabel: 'cleaningWindows' },
    { id: 3, label: 'Cleaning the floors', selected: false, value: 'cleaning_floor', mapLabel: 'cleaningFloors' },
    { id: 4, label: 'Disposing of the debris', selected: false, value: 'disposing_debris', mapLabel: 'disposingDebris' },
    { id: 5, label: 'Removing/attaching light fixtures', selected: false, value: 'removing_fixtures', mapLabel: 'lightFixtures' },
  ]
  condition: any = [
    { id: 1, label: 'No repairs needed', selected: false, value: 'good', image: '/assets/project-creator/good.png' },
    { id: 2, label: 'Need some repairs', selected: false, value: 'fair', image: '/assets/project-creator/fair.png' },
    { id: 3, label: 'Need major repairs', selected: false, value: 'bad', image: '/assets/project-creator/sad.png' },
    { id: 4, label: 'Need help in assessing surface condition', selected: false, value: 'needHelpinMeasurment' },

  ]
  no_of_windows: any = [{ 'name': '0', 'value': '0' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }]
  no_of_doors: any = [{ 'name': '0', 'value': '0' }, { 'name': '1', 'value': '1' }, { 'name': '2', 'value': '2' }, { 'name': '3', 'value': '3' }, { 'name': '4', 'value': '4' }, { 'name': '5', 'value': '5' }]
  closet_choose: any = [{ 'name': 'No closet', 'value': 'no' }, { 'name': 'Small', 'value': 'small' }, { 'name': 'Medium', 'value': 'medium' }, { 'name': 'Large', 'value': 'large' }]

  itemspainted: any = [
    { id: 1, label: 'Repainting - same color', selected: false, value: 'same_color', mapLabel: 'sameColor' },
    { id: 2, label: 'Repainting - different color', selected: false, value: 'color_change', mapLabel: 'colorChange' },
    { id: 3, label: 'Decorative painting - faux finish', selected: false, value: 'faux_finish', mapLabel: 'fauxFinish' },
    { id: 4, label: 'Decorative painting - texture coating', selected: false, value: 'texture_coating', mapLabel: 'textureCoating' },
  ]
  items: any = [
    { id: 1, label: 'Living Room', selected: false, value: 'livingroom', mapLabel: 'roomLiving', image: '/assets/project-creator/living-room.png' },
    { id: 2, label: 'Bathroom', selected: false, value: 'bathroom', mapLabel: 'roomBathroom', image: '/assets/project-creator/bathroom.png' },
    { id: 3, label: 'Bedroom', selected: false, value: 'bedroom', mapLabel: 'roomBedroom', image: '/assets/project-creator/bedroom.png' },
    { id: 4, label: 'Dining Room', selected: false, value: 'dining', mapLabel: 'roomDining', image: '/assets/project-creator/dining-room.png' },
    { id: 5, label: 'Kitchen', selected: false, value: 'kitchen', mapLabel: 'roomKitchen', image: '/assets/project-creator/kitchen.png' },
    { id: 6, label: 'Hallway', selected: false, value: 'hallway', mapLabel: 'roomHallway', image: '/assets/project-creator/hallway.png' },
    { id: 7, label: 'Others', selected: false, value: 'others', mapLabel: 'roomOthers', image: '/assets/project-creator/others.png' },
  ]
  itemswhatpainted: any = [
    { id: 1, label: 'Walls', selected: false, value: 'walls', mapLabel: 'paintingWalls', image: '/assets/project-creator/walls.png' },
    { id: 2, label: 'Trim', selected: false, value: "trim", mapLabel: 'paintingTrim', image: '/assets/project-creator/trims.png' },
    { id: 3, label: 'Ceiling', selected: false, value: 'ceiling', mapLabel: 'paintingCeiling', image: '/assets/project-creator/ceiling.png' },
    { id: 4, label: 'Doors', selected: false, value: "doors", mapLabel: 'paintingDoors', image: '/assets/project-creator/doors.png' },
    { id: 5, label: 'Window frames', selected: false, value: 'windowframes', mapLabel: 'paintingWindows', image: '/assets/project-creator/window-frames.png' }
  ]
  roomsno: any = [{ 'value': '1', 'name': '1' }, { 'value': '2', 'name': '2' }, { 'value': '3', 'name': '3' }, { 'value': '4', 'name': '4' }, { 'value': '5', 'name': '5' }, { 'value': '6', 'name': '6' }]
  show1: boolean = false
  projectDetailsForm: FormGroup
  property_type: any
  projectDetailsSections = {
    sectionTitle: 'Project detailed info',
    items: [
      {
        id: 0,
        title: 'Images',
        description: 'Upload your images here. Projects with attached images are more attractive for professionals.',
      },
      {
        id: 1,
        title: 'Project title', description: 'Please enter short title which will describe your job in few words. If you will leave this space empty, we will provide it for you.', hint: '0 /100 characters'
      },
      {
        id: 2,
        title: 'Description', description: 'Please provide us with short description of your project. It\n' +
          '          will help potential professionals with better understanding', hint: '0 /400 characters'
      },
      {
        id: 3,
        title: 'Timeline',
        description: 'Please set the start date and end date to provide us with the timeframe of your project.',
      },
    ]
  };
  constructor(private formBuilder: FormBuilder, private servicehttp: HttpserviceService, private router: Router,
  ) {
    this.projectDetailsForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(5), WhiteSpaceValidator]],
      title: ['', [Validators.required, Validators.minLength(5), WhiteSpaceValidator]],
      images: this.formBuilder.array([]),
      startDate: ['', [Validators.required]],
      dueDate: ['', [Validators.required]],
    });
    this.wallFormGroup = this.formBuilder.group({
      wallWidth: ['', [Validators.required, GreaterThanZeroValidator]],
      wallHeight: ['', [Validators.required, GreaterThanZeroValidator]],
      previousWallpaper: [false],
      newWallpaper: [false],
      previousColor: [''],
      newColor: [''],
      previousDecorative: [false],
      newDecorative: [false],
      previousFaux: [false],
      newFaux: [false],
    }, {
      validators: [
        AtLeastOneFieldRequiredValidator(['previousWallpaper', 'previousColor', 'previousDecorative', 'previousFaux']),
        AtLeastOneFieldRequiredValidator(['newWallpaper', 'newColor', 'newDecorative', 'newFaux'])
      ]
    });

    this.ceilingGroup = this.formBuilder.group({

      previousColor: [''],
      newColor: [''],
       length: [''],
       width: ['']
    })

  }


  ngOnInit(): void {
    this.type = localStorage.getItem('type')
    this.productForm = this.formBuilder.group({
      plans: this.formBuilder.array([]),
    });
    this.productFormext = this.formBuilder.group({
      plans: this.formBuilder.array([]),
    });
    this.orderForm = new FormGroup({
      items: new FormArray([])
    });
  }
  optionproduct(): FormArray {
    return this.productForm.get("plans") as FormArray
  }

  //   console.log(this.productForm.controls.length)

  optionproductext(): FormArray {
    return this.productFormext.get("plans") as FormArray
  }
  closeComplainModalnew() {
    this.modalFlagnew = false
    this.opennewmodal = false
    this.modalFlagnewopen = false
    this.modalFlag12 = false
  }

  savenewnew() {
    this.titlenew = ''
    this.desc = ''
    this.filesnewnew = []
    this.newfilenew = []
    //this.p_name=''
    console.log(this.addedmat)
    this.opennewmodal = false
    //  this.openother = false

  }
  addhoneyext(): any {
    const docForm = this.formBuilder.group({
      wallWidth: ['', [Validators.required, GreaterThanZeroValidator]],
      wallHeight: ['', [Validators.required, GreaterThanZeroValidator]],
      previousColor: [''],
      newColor: [''],

    });
    console.log(docForm)
    this.honeymoonext.push(docForm);
    console.log(this.productFormext.value.plans)
    this.addedwalls.push(this.productFormext.value.plans)
    this.palletshowhide.push(false)
    this.palleshowhidenew.push(false)
    // console.lo

  }
  addhoney(): any {
    const docForm = this.formBuilder.group({
      wallWidth: ['', [Validators.required, GreaterThanZeroValidator]],
      wallHeight: ['', [Validators.required, GreaterThanZeroValidator]],
      previousWallpaper: [false],
      newWallpaper: [false],
      previousColor: [''],
      newColor: [''],
      previousDecorative: [false],
      newDecorative: [false],
      previousFaux: [false],
      newFaux: [false],
    }, {
      validators: [
        AtLeastOneFieldRequiredValidator(['previousWallpaper', 'previousColor', 'previousDecorative', 'previousFaux']),
        AtLeastOneFieldRequiredValidator(['newWallpaper', 'newColor', 'newDecorative', 'newFaux'])
      ]
    });
    this.honeymoon.push(docForm);
    console.log(this.productForm.value.plans)
    this.addedwalls.push(this.productForm.value.plans)


  }
  save() {
    console.log(this.addedrroms)
    this.addedrroms.push()
    this.modalFlagnew = false

  }
  savenew() {
    this.modalFlagnewopen = false
  }

  changeCheckboxStatematerialnew(room: any) {
    console.log(room)
    this.material = room
    if (room == 'other') {
      this.opennewmodal = true

      this.showtitle = true
    }

    //	this.painted = false
    //	this.rooms_painted = true
  }

  openmodal(a: any) {
    console.log(a)
    this.modalFlagnew = true
    this.newobj = a
    //    this.addedrroms.splice(this.newobj, 1)
    console.log(this.addedrroms)
    //  const index = this.addedrroms.findIndex(x => x.LastName === "Skeet");

  }
  openmodalimage() {
    // console.log(a)
    this.modalFlagnewopen = true
    //   this.newobj= a
    //    this.addedrroms.splice(this.newobj, 1)
    //  console.log(this.addedrroms)
    //  const index = this.addedrroms.findIndex(x => x.LastName === "Skeet");

  }
  addwll() {
    this.addhoneyext();
    let obj = {
      'walls': this.addedwalls[4],
      'sel_rrom': this.selected_rroms,
      'no_wondows': this.windows,
      'doors': this.doors,
      "closet": this.closetshoose,
      'surface_condition': this.surface_condition,
      'crown': this.crown,
      'base': this.base,
      'rooms': this.roompainted,
      'length' : this.ceilingGroup.value.length,
      'width' : this.ceilingGroup.value.width,
      'ceilingnewColor': this.ceilingGroup.value.newColor,
      'ceilingPreviousColor': this.ceilingGroup.value.previousColor,
      //'celing_area' : this.productForm.value.plans[0].wallWidth  * this.productForm.value.plans[2].wallWidth,
      'moving_furniture_exterior': this.furnitervalue,
      'surface_type': this.material,
      'roof': this.roof
    }

    this.servicehttp.createwllext(obj).subscribe(response => {
      console.log('room created', response)
      this.details = response
      this.arrayfinal.push(this.details.final_price)
      this.gallaonswall.push(this.details.gallons_ceiling_paint)
      this.gallonsceiling.push(this.details.gallons_wall_paint)
      this.low_range.push(this.details.low_range)
      this.high_range.push(this.details.high_range)
      this.addedrroms.push({ 'walls': this.addedwalls[4], 'sel_rrom': this.selected_rroms, 'no_wondows': this.windows, 'doors': this.doors, "closet": this.closetshoose, 'surface_condition': this.sel_cond, 'crown': this.crown, 'base': this.base, 'rooms': this.roompainted, 
      'ceilingnewColor': this.ceilingGroup.value.newColor, 'details': this.details, 'ceilingPreviousColor': this.ceilingGroup.value.previousColor, 'width' :   this.ceilingGroup.value.width, 'length': this.ceilingGroup.value.length})

      this.extresp = response
      this.addroomsext = false
      // this.sele
      this.stepperIndex = 2;

    })
  }
  addroom() {
    this.addhoney()
    console.log(this.addedwalls[4])
    this.addrooms = true
    this.shownextbutton = true
    let obj = {
      'walls': this.addedwalls[4],
      'sel_rrom': this.selected_rroms,
      'no_wondows': this.windows,
      'doors': this.doors,
      "closet": this.closetshoose,
      'surface_condition': this.surface_condition,
      'crown': this.crown,
      'base': this.base,
      'rooms': this.roompainted,
      'length' : this.ceilingGroup.value.length,
      'width' : this.ceilingGroup.value.width,
      'ceilingnewColor': this.ceilingGroup.value.newColor,
      'ceilingPreviousColor': this.ceilingGroup.value.previousColor,
      'celing_area': this.productForm.value.plans[0].wallWidth * this.productForm.value.plans[2].wallWidth,
      'moving_furniture_exterior': this.furnitervalue
    }

    this.servicehttp.cretenewroom(obj).subscribe(response => {
      console.log('room created', response)
      this.details = response
      this.addedrroms.push({ 'walls': this.addedwalls[4], 'sel_rrom': this.selected_rroms, 'no_wondows': this.windows, 
      'doors': this.doors, "closet": this.closetshoose, 'surface_condition': this.sel_cond, 'crown': this.crown, 'base': this.base,
      'rooms': this.roompainted, 'ceilingnewColor': this.ceilingGroup.value.newColor, 'details': this.details, 'ceilingPreviousColor': this.ceilingGroup.value.previousColor, 'height':  this.ceilingGroup.value.height,
      'length' :   this.ceilingGroup.value.length, 'width' :   this.ceilingGroup.value.width, 'moving_furniture_exterior': this.furnitervalue })
      this.arrayfinal.push(this.details.final_price)
      this.gallaonswall.push(this.details.gallons_ceiling_paint)
      this.gallonsceiling.push(this.details.gallons_wall_paint)
      this.low_range.push(this.details.low_range)
      this.high_range.push(this.details.high_range)


      this.newaddedarray.push({ 'newparam': this.details })
      console.log(this.addedrroms, this.arrayfinal)

      this.selected_ids = []
      this.furnitervalue = ''
      this.details = {}
      this.selected_ids_rooms = []
      this.condition = [
        { id: 1, label: 'No repairs needed', selected: false, value: 'good', image: '/assets/project-creator/good.png' },
        { id: 2, label: 'Need some repairs', selected: false, value: 'fair', image: '/assets/project-creator/fair.png' },
        { id: 3, label: 'Need major repairs', selected: false, value: 'bad', image: '/assets/project-creator/sad.png' },
        { id: 4, label: 'Need help in assessing surface condition', selected: false, value: 'needHelpinMeasurment' },
      ]
      //this.addrooms= true
      this.items = [
        { id: 1, label: 'Living Room', selected: false, value: 'livingroom', mapLabel: 'roomLiving', image: '/assets/project-creator/living-room.png' },
        { id: 2, label: 'Bathroom', selected: false, value: 'bathroom', mapLabel: 'roomBathroom', image: '/assets/project-creator/bathroom.png' },
        { id: 3, label: 'Bedroom', selected: false, value: 'bedroom', mapLabel: 'roomBedroom', image: '/assets/project-creator/bedroom.png' },
        { id: 4, label: 'Dining Room', selected: false, value: 'dining', mapLabel: 'roomDining', image: '/assets/project-creator/dining-room.png' },
        { id: 5, label: 'Kitchen', selected: false, value: 'kitchen', mapLabel: 'roomKitchen', image: '/assets/project-creator/kitchen.png' },
        { id: 6, label: 'Hallway', selected: false, value: 'hallway', mapLabel: 'roomHallway', image: '/assets/project-creator/hallway.png' },
        { id: 7, label: 'Others', selected: false, value: 'others', mapLabel: 'roomOthers', image: '/assets/project-creator/others.png' },
      ]
      console.log(this.items)
      this.all_applicables = false
      this.shownesxt = true
      this.add_wall = false
      this.selected_rroms = []
      this.items_applicatble = []
      this.sel_cond = []
      this.doors = ''
      this.windows = ''
      this.closetshoose = ''
      this.selected_cond = []
      this.crown = '';
      this.base = ''
      this.roompainted = []
      this.ceilingGroup.reset()
      this.resetForm()
      this.addedwalls = []

      // this.showprojectdetails = true
    });
    console.log(this.details)






    // this.addrooms= true
  }

  removeall() {


  }
  addroomext() {
    this.addhoney()
    this.addrooms = true
    this.addedrroms.push({
      'walls': this.addedwalls[4], 'sel_rrom': this.selected_rroms, 'no_wondows': this.windows, 'doors': this.doors, "closet": this.closetshoose, 'surface_condition': this.surface_condition, 'crown': this.crown, 'base': this.base, 'rooms': this.roompainted,
      'ceilingnewColor': this.ceilingGroup.value.newColor, 'details': this.details, 'ceilingPreviousColor': this.ceilingGroup.value.previousColor ,
      'width' :   this.ceilingGroup.value.width, 'length' :   this.ceilingGroup.value.length ,  'moving_furniture_exterior': this.furnitervalue
    })
    console.log(this.addedrroms)
    this.selected_ids = []
    this.selected_ids_rooms = []

    this.items = [
      { id: 1, label: 'Living Room', selected: false, value: 'livingroom', mapLabel: 'roomLiving', image: '/assets/project-creator/living-room.png' },
      { id: 2, label: 'Bathroom', selected: false, value: 'bathroom', mapLabel: 'roomBathroom', image: '/assets/project-creator/bathroom.png' },
      { id: 3, label: 'Bedroom', selected: false, value: 'bedroom', mapLabel: 'roomBedroom', image: '/assets/project-creator/bedroom.png' },
      { id: 4, label: 'Dining Room', selected: false, value: 'dining', mapLabel: 'roomDining', image: '/assets/project-creator/dining-room.png' },
      { id: 5, label: 'Kitchen', selected: false, value: 'kitchen', mapLabel: 'roomKitchen', image: '/assets/project-creator/kitchen.png' },
      { id: 6, label: 'Hallway', selected: false, value: 'hallway', mapLabel: 'roomHallway', image: '/assets/project-creator/hallway.png' },
      { id: 7, label: 'Others', selected: false, value: 'others', mapLabel: 'roomOthers', image: '/assets/project-creator/others.png' },
    ]
    this.condition = [
      { id: 1, label: 'No repairs needed', selected: false, value: 'good', image: '/assets/project-creator/good.png' },
      { id: 2, label: 'Need some repairs', selected: false, value: 'fair', image: '/assets/project-creator/fair.png' },
      { id: 3, label: 'Need major repairs', selected: false, value: 'bad', image: '/assets/project-creator/sad.png' },
      { id: 4, label: 'Need help in assessing surface condition', selected: false, value: 'needHelpinMeasurment' },

    ]
    //this.addrooms= true
    this.shownesxt = true

    this.all_applicables = false
    this.add_wall = false
    this.selected_rroms = []

    this.sel_cond = []
    this.doors = ''
    this.closet = ''
    this.windows = ''
    this.closetshoose = ''
    this.selected_cond = []
    this.crown = '';
    this.base = ''
    this.roompainted = []
    this.ceilingGroup.reset();
    this.resetForm()
    // this.addrooms= true
  }
  addwallext() {
    console.log(this.addedwalls)
    this.addhoneyext()
    this.addrooms = true
    let a = this.addedwalls.length - 1
    console.log(a)
    this.addedrroms.push({
      'walls': this.addedwalls[a], 'sel_rrom': this.selected_rroms, 'no_wondows': this.windows, 'doors': this.doors, "closet": this.closetshoose, 'surface_condition': this.surface_condition, 'crown': this.crown, 'base': this.base, 'rooms': this.roompainted,
      'ceilingnewColor': this.ceilingGroup.value.newColor, 'ceilingPreviousColor': this.ceilingGroup.value.previousColor,  'width' :   this.ceilingGroup.value.width, 'length': this.ceilingGroup.value.length,
       'moving_furniture_exterior': this.furnitervalue
    })
    console.log(this.addedrroms)
    this.selected_ids = []
    this.selected_ids_rooms = []

    this.items = [
      { id: 1, label: 'Living Room', selected: false, value: 'livingroom', mapLabel: 'roomLiving', image: '/assets/project-creator/living-room.png' },
      { id: 2, label: 'Bathroom', selected: false, value: 'bathroom', mapLabel: 'roomBathroom', image: '/assets/project-creator/bathroom.png' },
      { id: 3, label: 'Bedroom', selected: false, value: 'bedroom', mapLabel: 'roomBedroom', image: '/assets/project-creator/bedroom.png' },
      { id: 4, label: 'Dining Room', selected: false, value: 'dining', mapLabel: 'roomDining', image: '/assets/project-creator/dining-room.png' },
      { id: 5, label: 'Kitchen', selected: false, value: 'kitchen', mapLabel: 'roomKitchen', image: '/assets/project-creator/kitchen.png' },
      { id: 6, label: 'Hallway', selected: false, value: 'hallway', mapLabel: 'roomHallway', image: '/assets/project-creator/hallway.png' },
      { id: 7, label: 'Others', selected: false, value: 'others', mapLabel: 'roomOthers', image: '/assets/project-creator/others.png' },
    ]
    this.condition = [
      { id: 1, label: 'No repairs needed', selected: false, value: 'good', image: '/assets/project-creator/good.png' },
      { id: 2, label: 'Need some repairs', selected: false, value: 'fair', image: '/assets/project-creator/fair.png' },
      { id: 3, label: 'Need major repairs', selected: false, value: 'bad', image: '/assets/project-creator/sad.png' },
      { id: 4, label: 'Need help in assessing surface condition', selected: false, value: 'needHelpinMeasurment' },

    ]
    //this.addrooms= true
    this.shownesxt = true

    this.all_applicables = false
    this.add_wall = false
    this.selected_rroms = []

    this.sel_cond = []
    this.doors = ''
    this.windows = ''
    this.closet = ''
    this.closetshoose = ''
    this.selected_cond = []
    this.crown = '';
    this.base = ''
    this.roompainted = []
    this.ceilingGroup.reset();
    this.resetForm()

    // this.addrooms= true
  }
  addnewrromsfrom() {
    console.log('d')
    this.addrooms = true
  }
  addnewrroms() {
    this.all_applicables = true
  }
  addmore() {
    this.optionproduct().push(this.addhoney());

    console.log(this.productForm.value.plans)
  }
  addmoreext() {
    this.optionproductext().push(this.addhoneyext());

    console.log(this.productFormext.value.plans)
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      wallWidth: ['', [Validators.required, GreaterThanZeroValidator]],
      wallHeight: ['', [Validators.required, GreaterThanZeroValidator]],
      previousWallpaper: [false],
      newWallpaper: [false],
      previousColor: [''],
      newColor: [''],
      previousDecorative: [false],
      newDecorative: [false],
      previousFaux: [false],
      newFaux: [false],
    }, {
      validators: [
        AtLeastOneFieldRequiredValidator(['previousWallpaper', 'previousColor', 'previousDecorative', 'previousFaux']),
        AtLeastOneFieldRequiredValidator(['newWallpaper', 'newColor', 'newDecorative', 'newFaux'])
      ]
    });
  }

  addItem(): void {
    //  this.itemsnew = this.orderForm.get('items') as FormArray;
    this.itemsnew.push(this.createItem());
    console.log(this.itemsnew)
  }
  showsetprev() {
    this.showprev = true
  }
  showsetnew() {
    this.shownew = true
  }
  setshowroom(i : any) {

    this.palletshowhide[i] = true
  }
  setshowroomnew(i: any) {

  this.palleshowhidenew[i] = true
  }
  setvalue(e: any) {
    console.log(e)
    this.ceilingGroup.patchValue({ 'previousColor': e });
    this.showprev = false

    console.log(this.ceilingGroup)
  }
  setvaluenew(e: any) {
    console.log(e)
    this.ceilingGroup.patchValue({ 'newColor': e });
    this.shownew = false

    console.log(this.ceilingGroup)
  }

  setvalueaddroom(e: any, i: any) {
    console.log(this.productForm)
    console.log(e)
    this.optionproduct().controls[i].patchValue({ 'previousColor': e })
    console.log(this.optionproduct, this.productForm)
    //  if(this.productForm?.get('plans').controls.length >0 ){
    //     this.productForm?.get('plans')?.get('previousColor').patchValue(e);

    //  }

    // this.productForm.value?.plans[i].patchValue({'previousColor' : e})
    //  this.productForm?.value?.plans[i].patchValue({'previousColor' : e});
    //  this.productForm.patchValue({'previousColor' : e});
    this.palletshowhide[i] = false

    console.log(this.productForm)
  }
  showextroon() {
    this.setextroomnewshow = true
  }
  showextroonext() {
    this.setextroom = true
  }
  setvalueaddroomnew(e: any, i: any) {
    console.log(e)
    this.optionproduct().controls[i].patchValue({ 'newColor': e })
    //  this.productForm.patchValue({'previousColor' : e});
    this.palleshowhidenew[i] = false
   console.log(this.productForm)
  }
  setvalueaddroomnewext(e: any, i: any) {
    console.log(e)
    this.optionproductext().controls[i].patchValue({ 'previousColor': e })
    //  this.productForm.patchValue({'previousColor' : e});
    this.setextroomnewshow = false

    console.log(this.productFormext)
  }

  setvalueaddroomnewextrrom(e: any, i: any) {
    console.log(e)
    this.optionproductext().controls[i].patchValue({ 'newColor': e })
    //  this.productForm.patchValue({'previousColor' : e});
    this.setextroom = false

    console.log(this.productForm)
  }

  get aliasesArrayControl() {
    return (this.orderForm.get('items') as FormArray).controls;
  }
  saveWall(): void {

    console.log(this.wallFormGroup, this.projectDetailsForm)
    //  this.wallCreated.emit(this.wallFormGroup.value);
    //  this.resetForm();
  }
  get complianceArray(): FormArray {
    return this.productForm.get("plans") as FormArray
  }
  resetForm(): void {
    const control = <FormArray>this.productForm.controls['plans'];
    for (let i = control.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
    const controlnew = <FormArray>this.productFormext.controls['plans'];
    for (let i = control.length - 1; i >= 0; i--) {
      controlnew.removeAt(i)
    }



  }
  choseProject(type: any) {
    this.project_type = type
    this.show = false
    this.show1 = true
  }
  homeorbusiness(type: any) {
    this.home_type = type
    this.show1 = false
    this.propertyshow = true
    let param = 'exterior'
    this.servicehttp.getprojectstep(param).subscribe(response => {
      this.otherproperty = response.icon
    });
  }
  get honeymoon() {
    return this.productForm.controls['plans'] as FormArray;
  }
  get honeymoonext() {
    return this.productFormext.controls['plans'] as FormArray;
  }
  first() {
    this.show = true
    this.show1 = true
    this.project_type = true
    this.propertyshow = true
  }
  propertyselect(type: any) {
    //console.log(this.proppaint)
    this.proppaint = type
    this.addroomsext = true
    console.log(this.proppaint)
    this.stepperIndex = 1;
  }
  changeCheckboxState(room: any) {
    console.log(room)
    this.room = room
    this.painted = false
    this.rooms_painted = true
  }
  changeCheckboxStatsurf(a: any) {
    this.sufvalue = a
    this.showsurface = false
    this.stepperIndex = 2
  }
  changeCheckboxStatematerial(room: any) {
    console.log(room)
    this.windows = room
    this.nowindows = false
    this.nowindowsext = false
    this.nodoors = true
    console.log(this.material)
    this.showmaterial = false
    this.showsurface = true
    //	this.painted = false
    //	this.rooms_painted = true
  }
  removeSelectedFile(index: any) {
    // Delete the item from fileNames list
    this.filesn.splice(index, 1);
    // delete file from FileList
    //this.fileList.splice(index, 1);
  }
  remove(index: any) {
    // Delete the item from fileNames list
    this.honeymoon.removeAt(index)
    this.palletshowhide.removeAt(index)
    this.palleshowhidenew.removeAt(index)
    // delete file from FileList
    //this.fileList.splice(index, 1);
  }
  duplicate(index: any) {
    // Delete the item from fileNames list
    //  this.honeymoon.removeAt(index)
    console.log("formarry length", this.honeymoon.value.length)
    console.log(index, this.honeymoon)
    let b = this.honeymoon.at(index)
    console.log(b)
    const docForm = this.formBuilder.group({
      wallWidth: ['', [Validators.required, GreaterThanZeroValidator]],
      wallHeight: ['', [Validators.required, GreaterThanZeroValidator]],
      previousWallpaper: [false],
      newWallpaper: [false],
      previousColor: [''],
      newColor: [''],
      previousDecorative: [false],
      newDecorative: [false],
      previousFaux: [false],
      newFaux: [false],
    }, {
      validators: [
        AtLeastOneFieldRequiredValidator(['previousWallpaper', 'previousColor', 'previousDecorative', 'previousFaux']),
        AtLeastOneFieldRequiredValidator(['newWallpaper', 'newColor', 'newDecorative', 'newFaux'])
      ]
    });
    this.honeymoon.push(docForm);
    console.log("values", this.honeymoon.at(index).value)
    let formValuesKeys = Object.keys(this.honeymoon.at(index).value);
    let length = this.honeymoon.value.length - 1;
    console.log("length", length, "values", this.honeymoon.value.length)
    formValuesKeys.forEach((ele: any) => {
      console.log("his.honeymoon.controls[length]?.get(ele)", this.honeymoon.controls[length]?.get(ele)?.value)
      this.honeymoon.controls[length]?.get(ele)?.patchValue(b.get(ele)?.value);
      console.log("patching values", this.honeymoon, "    =====value name", b.get(ele)?.value)
    })

    console.log(this.productForm.value.plans)
    this.addedwalls.push(this.productForm.value.plans)
    //this.addedwalls.push(b)
    console.log(this.addedwalls)
    console.log(this.honeymoon.value)
    let a = this.honeymoon.value.find((element: any) => element == index)
    this.palletshowhide.push(false)
     this.palleshowhidenew.push(false)
    console.log(a)
    // delete file from FileList
    //this.fileList.splice(index, 1);
  }
  removeext(index: any) {
    // Delete the item from fileNames list
    this.honeymoonext.removeAt(index)
    // delete file from FileList
    //this.fileList.splice(index, 1);
  }
  changecloset(room: any) {
    console.log(room)
    this.closetshoose = room
    console.log(this.material)

    this.closetye = false
    this.sconction = true
    //	this.painted = false
    //	this.rooms_painted = true
  }
  changeCheckdoors(room: any) {
    //console.log(room)
    this.doors = room
    this.nodoors = false
    this.closetye = true
    //     console.log(this.material)
    this.showmaterial = false
    this.showsurface = true
    this.sconctionext = true
    //	this.painted = false
    //	this.rooms_painted = true
  }
  iscrown(a: any) {
    this.crown = a
    this.iscrwom = false
    this.baseyes = true
  }
  isroof(a: any) {
    this.roof = a
    this.isrrof = false
    this.addroomsext = true
  }
  uploadFilesnewnew(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.newfilenew.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.newfilenew.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.newfilenew.length; i++) {
      formData.append("images", this.newfilenew[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.newfilenew)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.filesnewnewnew.push({ 'image': res.imgs[i] })
      }

      console.log("files", this.filesnewnew)
      this.addedmat.push({ 'decription': this.desc, 'image': this.filesnewnewnew[0].image, 'title': this.titlenew })
      // this.newobj.images= this.filesnewnew
      //    console.log(this.otherproperty)
      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;
      console.log(this.addedmat)

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }
  isbase(a: any) {
    this.base = a
    this.baseyes = false
    this.ceilingis = true
  }
  seletcedis(i: any) {
    // console.log(i,this.selected_ids)
    let a = this.selected_ids.find((element: any) => element == i)
    //console.log(a)
    if (a == undefined) {
      return "image-wrapper; ";

    } else {
      return "selected image-wrapper; color:black";
    }
  }

  propertyselectext(type: any, id: any) {
    //console.log(this.proppaint)
    this.proppaint = type
    if (this.proppaint == 'home') {
      this.showmaterial = true;
      this.propertyshow = false
      let param = id
      this.servicehttp.getprojectstepsecond(param).subscribe(response => {
        this.materials = response.response
      });

    } else {
      //this.stepperIndex = 1;
      this.showmaterial = true;
      this.propertyshow = false
      let param = id
      this.servicehttp.getprojectstepsecond(param).subscribe(response => {
        this.materials = response.response
      });

    }
    console.log(this.proppaint)

  }
  goto() {
    this.showwallpaper = true;
    this.color_finish = false
  }
  seletcedispainted(i: any) {
    console.log(i, this.items_to_painted)
    let a = this.items_to_painted.find((element: any) => element == i)
    console.log(a)
    if (a == undefined) {
      return "./assets/project-creator/checkbox-checked.png ";

    } else {
      return "./assets/project-creator/checkbox-unchecked.png";
    }
  }
  seletctedproj(i: any) {
    console.log(i, this.items_to_painted)
    let a = this.items_applicatble.find((element: any) => element == i)
    console.log(a)
    if (a == undefined) {
      return "./assets/project-creator/checkbox-checked.png ";

    } else {
      return "./assets/project-creator/checkbox-unchecked.png";
    }
  }
  seletctedprojother(i: any) {
    console.log(i, this.items_to_painted)
    let a = this.items_applicatbleother.find((element: any) => element == i)
    console.log(a)
    if (a == undefined) {
      return "./assets/project-creator/checkbox-checked.png ";

    } else {
      return "./assets/project-creator/checkbox-unchecked.png";
    }
  }

  propertyType(a: any) {
    this.property_typemew = a;
    this.pType = false
    this.whendone = true
  }
  changecondition(items: any, i: any) {
    this.selected_cond.push(i)
    this.stepperIndex = 3
    this.surface_condition = items
    this.sel_cond.push(items)
    this.sconction = false
    this.sconctionext = false
    this.isrrof = true

    this.iscrwom = true
    this.condition.forEach((item: any) => {
      //   console.log(item)
      if (item.value === items) {
        //   console.log(item.value)
        item.selected = true;

        //    this.items.set(this.pages[sectionIndex].sectionKey, item.value);
      } else {
        item.selected = false;
      }

    });
    //  this.surface_cond= false
    //   this.providibf_items= true
  }
  uploadFilesnew(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.newfile.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.newfile.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.newfile.length; i++) {
      formData.append("images", this.newfile[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.newfile)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.filesnewnew.push({ 'image': res.imgs[i] })
      }

      console.log("files", this.filesnewnew)
      this.otherproperty.push({ 'title': this.p_name, 'image': this.filesnewnew[0].image })
      // this.newobj.images= this.filesnewnew
      console.log(this.otherproperty)
      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }
  uploadFiles(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.new.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.new.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.new.length; i++) {
      formData.append("images", this.new[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.new)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.filesnew.push({ 'image': res.imgs[i] })
      }

      console.log("files", this.filesnew)
      this.newobj.images = this.filesnew

      console.log(this.addedrroms, 'images check',this.newobj.images)
      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;
      this.filesnew = []
      this.new = []

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }

  uploadFile(evt: any) {
    console.log(evt.target.files)
    if (!evt.target.files.length) {
      return;
    }
    for (var i = 0; i < evt.target.files.length; i++) {
      this.n.push(evt.target.files[i]);

    }

    //  this.isLoading = true;

    //const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

    if (file.size / 1024 / 1024 > 25) {
      //  this.isLoading = false;
      for (var i = 0; i < evt.target.files.length; i++) {
        this.n.splice(evt.target.files.indexOf(i), 1);

      }
      //this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

    const fr = new FileReader();
    fr.readAsDataURL(file);
    const formData = new FormData();
    for (var i = 0; i < this.n.length; i++) {
      formData.append("images", this.n[i]);

    }
    //  formData.append('job_id',job_id);

    console.log(this.n)

    console.log(formData)
    this.servicehttp.uploadimgaes(formData).subscribe(async res => {
      let a = 'image'
      for (let i = 0; i < res.imgs.length; i++) {
        this.filesn.push({ 'name': res.imgs[i] })
      }

      console.log("files", this.filesn)
      this.n = []

      //   console.log("post file obj =========== ",obj)

      //  this.isLoading = false;

    }, (err) => {
      this.servicehttp.handleError(err)
      console.log(err)
      //   this.isLoading = false;

    })

  }

  characterCounterForTitle(event: any): void {
    this.projectDetailsSections.items[1].hint = event.target.value.length + ' /100 characters';
  }

  characterCounterForDescription(event: any): void {
    this.projectDetailsSections.items[2].hint = event.target.value.length + ' /400 characters';
  }

  setProjectDetailsDate(event: any): void {
    this.projectDetailsForm.get('startDate')?.setValue(event.startDate);
    this.projectDetailsForm.get('dueDate')?.setValue(event.endDate);
  }
  seletcedisroom(i: any) {
    // console.log(i,this.selected_cond)
    let a = this.selected_ids_rooms.find((element: any) => element == i)
    //console.log(a)
    if (a == undefined) {
      return "image-wrapper; ";

    } else {
      return "selected image-wrapper; color:black";
    }
  }

  whentobe(a: any) {
    this.when_to_be_done = a
    this.whendone = false;
    this.all_applicables = true
    this.addrooms = true
  }
  slectcond(i: any) {
    console.log(i, this.selected_ids_rooms)
    let a = this.selected_ids_rooms.find((element: any) => element == i)
    console.log(a)
    if (a == undefined) {
      return "image-wrapper; ";

    } else {
      return "selected image-wrapper; color:black";
    }
  }
  putWallpaper(a: any) {
    this.puwallpaper = a
    this.showwallpaper = false
    this.surface_cond = true
  }

  sendNewProjectRequest() {
    if (this.arrayfinal.length) {
      this.sum = this.arrayfinal.reduce((sum: any, p: any) => sum + p);
      this.newlowrange = this.low_range.reduce((sum: any, p: any) => sum + p);
      this.newhighrange = this.high_range.reduce((sum: any, p: any) => sum + p);
      this.newgallonwll = this.gallaonswall.reduce((sum: any, p: any) => sum + p);
      this.newgallonceiling = this.gallonsceiling.reduce((sum: any, p: any) => sum + p);

      console.log(this.sum, this.newgallonceiling, this.newgallonwll, this.newhighrange, this.newlowrange)
    }

    let obj = {
      'creater_type': this.type,
      'project_type': this.project_type,
      'home_type': this.home_type,
      'property_type': this.proppaint,
      'room_type': this.selected_rroms,
      'what_painted': this.roompainted,
      'surface_condition': this.surface_condition,
      'room_no': this.room,
      'wallpaper': this.puwallpaper,
      'height_of_ceiling': this.height,
      'finishing': this.colorFinish,
      'providing_items': this.provdeitem,
      'other_kinds_of_property': this.property_typemew,
      'jobtime': this.when_to_be_done,
      'project_applicable': this.projectapplivcables,
      'images': this.filesn,
      'project_title': this.projectDetailsForm.value.title,
      'description': this.projectDetailsForm.value.description,
      'startDate': this.projectDetailsForm.value.startDate,
      'endDate': this.projectDetailsForm.value.dueDate,
      'material': this.material,
      'other_thing_painter_to_do': this.projectapplivcablesother,
      'surface_area_to_be_painted': this.sufvalue,
      'room': this.addedrroms,
      'matrial_desc': [],
      'total_price': this?.sum


    }
    this.servicehttp.postprojectnew(obj).subscribe(response => {
      this.showprojectdetails = true
      console.log(this.arrayfinal)
      console.log(this.sum)

    });
  }
  heightofceiling(e: any) {
    console.log(e)
    this.height = e
    this.height_ceiling = false
    this.color_finish = true
  }
  pItems(a: any) {
    this.provdeitem = a
    this.providibf_items = false
    this.whendone = true
    this.showither = true
  }
  next() {
    this.showwhatpainted = true
    this.painted = false
    this.shoenxt = false

    this.rooms_painted = false
  }

  nethh() {

  }
  changeCheckboxStateroom(items: any, id: any) {
    ///console.log(items,id)



    let b = this.selected_rroms.find((element: any) => element == items)
    console.log(b)
    if (b) {
      console.log('b')
      const index: number = this.selected_rroms.indexOf(b);
      this.selected_ids.splice(index, 1)

      this.selected_rroms.splice(index, 1)
    } else {
      this.selected_rroms.push(items)
      this.selected_ids.push(id)

    }

    this.all_applicables = false
    this.addrooms = false
    this.nowindows = true
    console.log(this.selected_rroms, this.selected_ids)
  }
  selectProject(items: any, i: any) {
    this.stepperIndex = 4
    this.items_applicatble.push(i)
    this.projectapplivcables.push(items)
    this.all_applicables_list.forEach((item: any) => {
      //   console.log(item)
      if (item.value === items) {
        //   console.log(item.value)
        item.selected = true;

        //    this.items.set(this.pages[sectionIndex].sectionKey, item.value);
      } else {
        item.selected = false;
      }

    });
  }
  selectProjectother(items: any, i: any) {
    this.stepperIndex = 3
    this.items_applicatbleother.push(i)
    this.projectapplivcablesother.push(items)
    this.itemsother.forEach((item: any) => {
      //   console.log(item)
      if (item.value === items) {
        //   console.log(item.value)
        item.selected = true;

        //    this.items.set(this.pages[sectionIndex].sectionKey, item.value);
      } else {
        item.selected = false;
      }

    });
  }
  itemPainted(items: any, i: any) {
    this.items_to_painted.push(i)
    this.colorFinish.push(items)
    this.itemspainted.forEach((item: any) => {
      //   console.log(item)
      if (item.value === items) {
        //   console.log(item.value)
        item.selected = true;

        //    this.items.set(this.pages[sectionIndex].sectionKey, item.value);
      } else {
        item.selected = false;
      }

    });
    console.log(this.itemspainted)
  }
  second() {
    this.sconction = true
    this.closetye = true
    this.nodoors = true
    this.nowindows = true
    this.iscrwom = true
    this.ceilingis = true
    this.baseyes = true
    this.addrooms = true
    this.addroomsext = true

  }
  fourth() {
    this.all_applicables = true
    this.pType = true
    this.whendone = true
    this.providibf_items = true
  }
  third() {
    this.color_finish = true
    this.shownexttow = false
    this.height_ceiling = true
    this.showwallpaper = true
    this.surface_cond = true
    this.showsurface = true
    this.showmaterial = true
  }

  changeCheckboxStateroompainted(items: any, id: any) {

    let b = this.roompainted.find((element: any) => element == items)
    console.log(b)
    if (b) {
      console.log('b')
      const index: number = this.roompainted.indexOf(b);
      this.selected_ids_rooms.splice(index, 1)
      this.roompainted.splice(index, 1)
    } else {
      this.roompainted.push(items)
      this.selected_ids_rooms.push(id)
    }
  }

  newaddnext() {
    console.log(this.ceilingGroup)
    this.ceilingis = false
    this.move_furniture = true
    //  this.add_wall= true

  }

  isfurnitre(a: any) {
    this.furnitervalue = a
    this.move_furniture = false
    this.add_wall = true

  }
}
