import {Component} from '@angular/core';


@Component({
  selector: 'app-create-new-project-page',
  templateUrl: './create-new-project-page.component.html',
  styleUrls: ['./create-new-project-page.component.scss'],
})
export class CreateNewProjectPageComponent {
  constructor() {
  }
}



