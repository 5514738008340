export enum PropertyType{
  BUNGALOW = 'BUNGALOW',
  STOREY_HOUSE = 'STOREY_HOUSE',
  TENEMENT = 'TENEMENT',
  CONDO = 'CONDO',
  MULTI_STOREY_BUILDING = 'MULTI_STOREY_BUILDING',
  APARTMENT = 'APARTMENT',
  HOUSE = 'HOUSE',
  TOWN_HOUSE = 'TOWN_HOUSE'
}
