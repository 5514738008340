<!-- <app-header [anyKindForm]="true" [userTheme]="userRegistration" [login]="loginRoute">

</app-header> -->


<div class="container-bg login_screens">

  <img src="assets/splashes/1.svg" class="splash1">
  <img src="assets/splashes/3.svg" class="splash2">
  <img src="assets/splashes/5.svg" class="splash3">
  <img src="assets/splashes/2.svg" class="splash4">
  <img src="assets/splashes/4.svg" class="splash5">


  <img src="assets/splashes/bottom-splash.svg" class="bottom-splash">
  <img src="assets/splashes/sub-splash.svg" class="sub-splash">
  <img src="assets/splashes/main-splash.svg" class="hero-splash">
  <router-outlet class="content"></router-outlet>
</div>

