import {RoutingConfirmDialogServiceReference} from '../../../services/dirty-guard/routing-confirm-dialog-service-reference';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-routing-confirm-dialog-component',
  templateUrl: './routing-confirm-dialog.component.html'
})
export class RoutingConfirmationDialogComponent implements OnInit{
  ngOnInit(): void {
  }

  constructor(
    private confirmationDialogReferenceService: RoutingConfirmDialogServiceReference) {
  }

  public closeDialog(): void {
    this.confirmationDialogReferenceService.unloadComponent();
  }

  public navigateAway(): void {
    this.confirmationDialogReferenceService.allow = true;
    this.confirmationDialogReferenceService.destroyComponentAndAllowNavigation();
  }
}
