<section class="user_profile_sec">
    <div class="custom-contianer">
        <div class="user_dtl_sec">
            <h2>
                User Profile
            </h2>
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="user_persl_dtl position-relative">
                                <span class="img-updte">

                                    <i class="fa-solid fa-upload"></i>
                                    <input class="input_class" (change)="fileUpload2($event)" class="text_inputs"
                                        type="file">
                                </span>
                                <div class="user_img_container">
                                     <img [src]="  previewImage2 "> 
                                   
                                </div>
                                <div class="d-flex lr_content">
                                    <h6>
                                        My profile
                                    </h6>
                                    <div class="left_dtl">
                                        <p>
                                            {{profile?.sign_up_date | date}}
                                        </p>
                                        <p>Client ID: {{profile.id}}</p>
                                    </div>
                                </div>
                                <form action="" [formGroup]="profileForm" (ngSubmit)="changeprofile()"
                                    class="form margin-b10 user_profile_form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="input-wrapper bid_inpt">
                                                <div class="div_eml div_incs">
                                                    <input class="email" placeholder="John Jorden"
                                                        formControlName="email">
                                                    <div class="error-label"
                                                        *ngIf="!profileForm.controls['email'].valid && (profileForm.controls['email'].touched)">
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['email'].hasError('required')">
                                                            Please enter email
                                                        </p>
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['email'].hasError('pattern')">
                                                            Please enter valid email
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="input-wrapper bid_inpt">
                                                <div class="div_eml div_incs">
                                                    <input class="email" placeholder="9876543210"
                                                        formControlName="phone"  maxLength="15"
                                                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                                    <div class="error-label"
                                                        *ngIf="!profileForm.controls['phone'].valid && (profileForm.controls['phone'].touched)">
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['phone'].hasError('required')">
                                                            Please enter phone number
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="input-wrapper bid_inpt">
                                                <div class="div_eml div_incs">
                                                    <input class="email" placeholder="johnJorden123@gmail.com"
                                                        formControlName="name">
                                                    <div class="error-label"
                                                        *ngIf="!profileForm.controls['name'].valid && (profileForm.controls['name'].touched)">
                                                        <p class="error-label"
                                                            *ngIf="profileForm.controls['name'].hasError('required')">
                                                            Please enter name
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="button_theme">Save</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card user_projts_bids margin_btm">
                        <div class="card-header">
                            <h6>
                                My Projects
                            </h6>
                        </div>
                        <div class="card-body">
                            <div class="my_prjs_list">
                                <ul>
                                    <li>
                                        <div class="d-flex prj_times">
                                            <div>
                                                <h5>
                                                    Need a painter for my mansion, Urgent!
                                                </h5>
                                                <p class="light_txt">
                                                    Project Interior
                                                </p>
                                            </div>
                                            <p class="light_txt prj_time">
                                                18 days ago
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex prj_times">
                                            <div>
                                                <h5>
                                                    Need a painter for my mansion, Urgent!
                                                </h5>
                                                <p class="light_txt">
                                                    Project Interior
                                                </p>
                                            </div>
                                            <p class="light_txt prj_time">
                                                18 days ago
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex prj_times">
                                            <div>
                                                <h5>
                                                    Need a painter for my mansion, Urgent!
                                                </h5>
                                                <p class="light_txt">
                                                    Project Interior
                                                </p>
                                            </div>
                                            <p class="light_txt prj_time">
                                                18 days ago
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card user_projts_bids">
                        <div class="card-header">
                            <h6>
                                My Projects
                            </h6>
                        </div>
                        <div class="card-body">
                            <div class="my_bids_user">
                                <ul>
                                    <li>
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex user_bidsList">
                                                <div>
                                                    <img _ngcontent-hce-c386="" src="./assets/Ellipse14.png">
                                                </div>
                                                <div>
                                                    <h6>
                                                        Wilson Stanton
                                                    </h6>
                                                    <div class="sub-info">
                                                        <img src="assets/icons/star.png" alt="star" class="logo">
                                                        <span class="rating"> 4.67</span>
                                                        <span class="no-of-reviews">(323 Reviews)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="user_bid_amt">
                                                <p class="light_txt">
                                                    Bid Amount
                                                </p>
                                                <h6>
                                                    $500.00
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex user_bidsList">
                                                <div>
                                                    <img _ngcontent-hce-c386="" src="./assets/Ellipse14.png">
                                                </div>
                                                <div>
                                                    <h6>
                                                        Wilson Stanton
                                                    </h6>
                                                    <div class="sub-info">
                                                        <img src="assets/icons/star.png" alt="star" class="logo">
                                                        <span class="rating"> 4.67</span>
                                                        <span class="no-of-reviews">(323 Reviews)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="user_bid_amt">
                                                <p class="light_txt">
                                                    Bid Amount
                                                </p>
                                                <h6>
                                                    $500.00
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="d-flex justify-content-between">
                                            <div class="d-flex user_bidsList">
                                                <div>
                                                    <img _ngcontent-hce-c386="" src="./assets/Ellipse14.png">
                                                </div>
                                                <div>
                                                    <h6>
                                                        Wilson Stanton
                                                    </h6>
                                                    <div class="sub-info">
                                                        <img src="assets/icons/star.png" alt="star" class="logo">
                                                        <span class="rating"> 4.67</span>
                                                        <span class="no-of-reviews">(323 Reviews)</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="user_bid_amt">
                                                <p class="light_txt">
                                                    Bid Amount
                                                </p>
                                                <h6>
                                                    $500.00
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>