import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {Navigation, Params, Router} from '@angular/router';
import {EConfirmationType} from '../../../model/enums/EConfirmationType';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})
export class ConfirmRegistrationComponent implements OnInit {
  isPinSubmitted = false;
  isPinValid = false;
  isPinWrong = false;
  pin = '';
  anotherConfirmationRequested = false;
  queryParams?: Params;
  ifEmailConfirmation = true;

  constructor(private authService: AuthService,
              private router: Router) {
    const navigation = this.router.getCurrentNavigation()!;
    if (!(navigation.extras.replaceUrl)) {
      this.getParamsFromRoute(navigation);
    }
  }

  ngOnInit(): void {
  }

  getParamsFromRoute(route: Navigation): void {
    this.queryParams = route.extras.queryParams as {
      confirmationType: string,
      confirmationParam: string;
    };
    this.ifEmailConfirmation = this.queryParams.confirmationType === EConfirmationType.EMAIL;
  }

  validatePin(): void {
    if (this.queryParams?.confirmationParam && this.pin) {
      this.authService.confirmPin(this.pin, this.ifEmailConfirmation,
        this.queryParams.confirmationParam).subscribe(response => {
          console.log(response);
          this.isPinSubmitted = true;
          this.isPinValid = true;
          this.isPinWrong = false;
        },
        error => {
          if (error.status === 404) {
            this.isPinValid = false;
            this.isPinWrong = true;
            this.isPinSubmitted = false;
          } else {
            console.log(error);
            this.isPinSubmitted = true;
            this.isPinWrong = false;
            this.isPinValid = false;
          }
        });
    } else {
      this.isPinValid = false;
    }
  }

  sendAnotherPin(): void {
    if (this.queryParams?.confirmationParam) {
      this.anotherConfirmationRequested = true;
      this.isPinSubmitted = false;
      this.isPinValid = false;
      this.pin = '';
      if (this.ifEmailConfirmation) {
        this.authService.resendConfirmationMail(this.queryParams.confirmationParam).subscribe(response => {
          console.log(response);
        }, error => {
          console.log(error);
        });
      } else {
        this.authService.resendConfirmationSMS(this.queryParams.confirmationParam).subscribe(response => {
          console.log(response);
        }, error => {
          console.log(error);
        });
      }
    }
  }

  confirmButtonDisabled(): boolean {
    if (this.isPinWrong) {
      return true;
    }
    if (this.pin.length === 5) {
      return false;
    } else {
      return true;
    }
  }

  navigateToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  anotherConfirmationRequestedSubmit(): void {
    this.isPinWrong = false;
    this.anotherConfirmationRequested = false;
  }

  pinInputChange(event: any): void {
    const tempPin = event.target.value;
    if (tempPin.length >= 5) {
      event.target.value = tempPin.substring(0, 5);
      this.pin = event.target.value;
    }
    this.isPinWrong = false;
  }
}
