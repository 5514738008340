import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EProjectStatus } from '../../../../../model/enums/EProjectStatus';
import { ProjectService } from '../../../../../services/project.service';
import { BidsService } from '../../../../../services/bids.service';
import { getRedirectLinkForProjectType } from '../../../../../model/enums/EProjectType';
import { SimpleDropDownComponentItem } from '../../../../../model/components/SimpleDropDownComponentItem';
import { ComplainService } from '../../../../../services/complain.service';
import { Complain } from '../../../../../model/Complain';
import { EComplainReason } from '../../../../../model/enums/EComplainReason';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-my-job-details',
  templateUrl: './my-job-details.component.html',
  styleUrls: ['./my-job-details.component.scss']
})
export class MyJobDetailsComponent implements OnInit {
  project!: any;
  myBid!: any;
  projectDetails: any = {}
  // reporting_reason: any = []
  id: any
  complaint: any
  options: any = [{ 'label': 'No response from the other party' }, { 'label': 'I cannot continue to work on this project' }, { 'label': 'The job does not match the provided description' }, { 'label': 'Other' }]
  option: any
  EProjectStatus = EProjectStatus;
  EComplainReason = EComplainReason;
  markAsCompletedModalVisible = false;
  makeComplainDialogVisible = false;
  selectedComplainReason!: EComplainReason;
  complainReasons!: SimpleDropDownComponentItem[];
  complain!: Complain;


  constructor(private projectService: ProjectService, private router: Router, private bidsService: BidsService, private activatedRoute: ActivatedRoute, private toastr: ToastrService,
    private complainService: ComplainService, private authService: HttpserviceService) {
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params.id

    const tag = this.activatedRoute.snapshot.paramMap.get('tag');
    this.getData(tag);
    this.getPRojectdetails()
  }

  navigateBack(): void {
    this.router.navigateByUrl('/home/my-jobs');
  }
  markcomplete(id: any) {
    let obj = {
      'project_id': id,
      'painter_status': 'completed'
    }
    this.authService.changeprojectstatus(obj).subscribe((response: any) => {
      this.toastr.success('Status updated!', 'Success!')


      this.router.navigate(['/home/my-jobs'])


    });
  }

  navigateToProject(): void {
    this.router.navigate(['home/my-project-detail']);
  }
  getPRojectdetails() {
    let obj = {
      'project_id': this.id
    }
    this.authService.getjobs(obj).subscribe((response: any) => {
      this.projectDetails = response.projects[0]

      response.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

  getData(bidTag: any): void {
    this.bidsService.getBidWithAllDetailsForPainer(bidTag).subscribe(response => {
      this.myBid = response.bid;
      this.project = response.project;
      this.complain = this.project.complain;
    },
      error => {
        console.log(error);
      });
  }

  markCompleted(): void {
    this.markAsCompletedModalVisible = false;
    this.projectService.markProjectAsCompleted(this.project.tag).subscribe(res => {
      this.getData(this.myBid.tag);
    });
  }

  makeComplain(): void {
    const complain: Complain = { content: 'empty for now', reason: this.selectedComplainReason, projectTag: this.project.tag };
    this.complainService.postComplain(complain).subscribe(res => {
      this.makeComplainDialogVisible = false;
    }, error => {

    });
  }
  closeComplainModal(): void {
    this.makeComplainDialogVisible = false;
    this.selectedComplainReason = EComplainReason.DEFUALT;
  }
  pay(): void {
  }

  openComplainModal(): void {

    this.makeComplainDialogVisible = true;
  }

  select(event: any): void {
    this.selectedComplainReason = event.value;
  }

  chat(id: any) {

  }
  viewDemoDetailsscomp(id: any, type: any) {
    localStorage.setItem('type', type)
    this.router.navigate(['/home/my-project-detail-new/' + id]);
  }
  save() {
    let obj = {
      'painter_status': 'disputed',
      'dispute_reason': this.complaint,
      'project_id': this.id,
      'dispute_option': this.option
    }

    this.authService.disputeproject(obj).subscribe((response: any) => {
      this.toastr.success('Status updated!', 'Success!')
      this.makeComplainDialogVisible = false
         window.location.reload()
      this.router.navigate(['/home/my-jobs'])


    });
  }


}
