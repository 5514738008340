import {Component, Input} from '@angular/core';
import {Room} from '../../../model/Room';
import {ERoomType} from '../../../model/enums/ERoomType';


@Component({
  selector: 'app-room-card-details',
  templateUrl: './room-card-details.component.html',
  styleUrls: ['./room-card-details.component.scss']
})
export class RoomCardDetailsComponent {
  @Input() room!: Room;
  @Input() pricesVisible!: boolean;
  ERoomType = ERoomType;

  constructor() {}
}
