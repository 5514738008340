import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-make-new-project',
  templateUrl: './make-new-project.component.html',
  styleUrls: ['./make-new-project.component.scss']
})
export class MakeNewProjectComponent implements OnInit {

  constructor( private router: Router) { }

  ngOnInit(): void {
  }
  gotonext(status: any){
  if(status=='basic'){

  
  console.log(status)
  		localStorage.setItem('type',status)

  this.router.navigate(['/home/make-new-project-details'])
  }else{
        
  console.log(status)
  		localStorage.setItem('type',status)

  this.router.navigate(['/home/make-new-project-advanced'])
  }
  }
}
