import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'dateOnly'})
export class DateOnlyPipe implements PipeTransform {
  // format HH:mm:ss YYYY-MMM-dd
  transform(date: string): string {
    return date.split(' ')[1]?.split('-')?.join(' ');
  }

}
