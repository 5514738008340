import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from '../../../../services/comment.service';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { PainterService } from '../../../../services/painter.service';
import { PageableComponent } from '../../../../components/MAIN_COMPONENTS/PageableComponent';
import { NewComment } from '../../../../model/NewComment';
import { UserPainterProfile } from '../../../../model/UserPainterProfile';
import { MessageService } from '../../../../services/message.service';
import { BrushNotification } from '../../../../services/notification-service';
import { SelectInputOption } from '../../../../model/components/SelectInputOption';
import { HttpserviceService } from '../../../../services/httpservice.service';
import {AuthService} from '../../../../services/auth.service';

import {FormBuilder, FormGroup,Validators} from '@angular/forms';
    import { ToastrService } from 'ngx-toastr';
    import * as moment from 'moment';

interface Badge {
  tooltip: string;
  imgSrc: string;
  isReached: boolean;
}

@Component({
  selector: 'app-painter-profile',
  templateUrl: './painter-profile.component.html',
  styleUrls: ['./painter-profile.component.scss']
})
export class PainterProfileComponent extends PageableComponent implements OnInit {
  profile: any
  path: any = 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
  edit: boolean= false
  errorpin: any
  //previewImage2: any
    filteroption: any = 'newest'

  show: boolean= false
  price: any
  modalFlag: boolean = false;
  reviewlist: any=[]
  modalFlag1: boolean = false;
    starRating :any;
      totalRecords: any;
      limit: any= '5'
      offset : any= '0'
    newdate: any
    currentdate: any
  profileForm!: FormGroup
   imgForm: FormGroup | undefined
   stay_logged_in: boolean= false;
   send_email: boolean = false
   semail: any
   shownewreview : boolean=  false
   id: any
   savedstate: any
   stay_log : any
   previewImage2: any
       selectedFile2: any;
       passwordform: FormGroup
 error: any
  isFormSubmitted = false;
   pass: String = 'password'
  confirmPass: String = 'password'
  Cpass: String = 'password';
    //   path :any= 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
 //profile: any
//================Amit24FEB
 // reviewlist: any[] = [];
 // limit: number = 5; // Number of items per page
 // offset: number = 0; // Starting offset
 currentPage: any = 1; // Current page number
 // totalRecords: any = 0; // Total number of records




  changeContactModalVisible = false;
  changePasswordModalVisible = false;
  changeAddressModalVisible = false;
  isPasswordNotChangedDueToError = false;
  isContactNotChangedDueToError = false;
  contactNotChangedDueToErrorSubclaim = '';
  passwordNotChangedDueToErrorSubclaim = '';

  @ViewChild('comments', { read: ElementRef }) set ft(comments: ElementRef) {
    if (comments != null) {
      const scrollToComments = this.activatedRoute.snapshot.paramMap.get('comments');
      if (scrollToComments != null) {
        setTimeout(() => {
          comments.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }

  orderOptions: SelectInputOption[] = [
    { optionID: 1, optionLabel: 'Sort By: New', optionValue: 'dateTime', sortDir: 'DESC', optionDisabled: false, optionSelected: true },
    { optionID: 2, optionLabel: 'Sort By: Old', optionValue: 'dateTime', sortDir: 'ASC', optionDisabled: false, optionSelected: false },
  ];

  painterProfile!: UserPainterProfile;
  userSelectedRate!: string;
  userCommentContent!: string;
  avgRating!: number;
  painterBadges: Badge[] = [
    { tooltip: 'Painter is brushing for over 1 year', imgSrc: './assets/icons/painter-badges/badge1.png', isReached: true },
    { tooltip: 'Painter has more than 100 comments', imgSrc: './assets/icons/painter-badges/badge2.png', isReached: true }
  ];

  constructor(private formBuilder: FormBuilder, private toastr : ToastrService,
    public tokenStorage: TokenStorageService,
    private commentService: CommentService,
    private router : Router,
     private service: AuthService,
    private activatedRoute: ActivatedRoute,
    private painterService: PainterService,
    private authService: HttpserviceService,
    private messageService: MessageService) {
    super();
    this.loadPainterProfileDataAndComments();
    this.profileForm = this.formBuilder.group({
    //  email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
    //  name: ['',[Validators.required]],
    about_me :[''],
    email: [''],
      profile_image: [null],
      address: ['', [Validators.required, Validators.maxLength(15)]],
      city: ['', [Validators.required]],
      state: [''],
         latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      location: ['', Validators.required],
      zipcode: ['', [Validators.required]],
      })
        this.passwordform = this.formBuilder.group({
    currPswd: ['', [Validators.required]],
    newPswd : ['', [Validators.required]],
          conPswd: ['', [Validators.required]],

  }, {
      validator: this.checkPassword,
    });
  }

  ngOnInit(): void {
      this.id = this.activatedRoute.snapshot.params.id
if(this.id){
    this.getprofilebyid()

}else{
    this.getprofile()

}
this.getreviewlist()
  }

     checkzip(e: any){
  // console.log(e, this.location)

   let obj={
          'pin_code' : this.profileForm.value.zip
   }
           this.service.checkzipcode(this.profileForm.value.zipcode).subscribe((res : any) => {
           if(res){
           this.errorpin =''
             this.profileForm.controls['latitude'].patchValue(res.response[0].latitude);
      this.profileForm.controls['longitude'].patchValue(res.response[0].longitude);
            this.profileForm.controls['city'].patchValue(res.response[0].city);
                        this.profileForm.controls['state'].patchValue(res.response[0].administrativeLevels?.level1long);
                        console.log(this.profileForm)
           }
   },(error: any) => {
        console.log(error);
        this.errorpin = 'Please enter valid zip code'
        })
   }
  editp(){
        this.edit= true
  }



    changeprofile(){

  let obj={
        profile_image:'',
        full_name : this.profileForm.value.name,
        email : this.profileForm.value.email,
        phone_number : this.profileForm.value.phone,
  }
  const formdata=new FormData()
   formdata.append('address',this.profileForm?.get('address')?.value)
      formdata.append('profile_image',this.selectedFile2)
              formdata.append('about_me', this.profileForm?.get('about_me')?.value)

   formdata.append('city',this.profileForm?.get('city')?.value)
         formdata.append('phone_number',this.profileForm.value.phone)
    // formdata.append('state',this.profileForm?.get('state')?.value)
      formdata.append('zip_code',this.profileForm?.get('zipcode')?.value)
      formdata.append('stay_logged_in',this.stay_log)
      formdata.append('send_email',this.semail)
       formdata.append('latitude',this.profileForm?.get('latitude')?.value)
            formdata.append('longitude',this.profileForm?.get('longitude')?.value)
                        formdata.append('state',this.profileForm?.get('state')?.value)
                                formdata.append('email', this.profileForm?.get('email')?.value)

                        //   formdata.append('location',this.profileForm?.get('location')?.value)
    //  formdata.append('city',this.profileForm?.get('city')?.value)


   this.authService.updateprofile(formdata).subscribe((response : any) => {
       ///  this.profile= response.profileData
           this.toastr.success('Profile Updated Successfully!', 'Success!');
           this.getprofile()
               this.edit= false
               window.location.reload();
      })
  }
   handleAddressChange(event: any) {
  // console.log(this.location)
    console.log('address change', event, event.geometry.location.lat(), event.geometry.location.lng());
     this.profileForm.controls['latitude'].patchValue(event.geometry.location.lat());
      this.profileForm.controls['longitude'].patchValue(event.geometry.location.lng());
            this.profileForm.controls['location'].patchValue(event.formatted_address);
                                    this.profileForm.controls['state'].patchValue(event.name);

   }

   clearLocation(){
   console.log('test')
   }

      fileUpload2(event : any) {
      console.log(event)

    console.log(event, event.target.files[0])
    if(event.target.files[0] > 2000){
     this.authService.showMessage({
             message: 'Please select file less than 2kb'
          })
          return;
    }
    this.selectedFile2 = event.target.files[0]
     const reader = new FileReader();
    reader.onload= function  (this: any){
      this.profileForm.patchValue({
        profile_image: reader.result

      })
                          this.previewImage2 = reader.result;

     }.bind(this)
     reader.readAsDataURL(this.selectedFile2);
    console.log(this.selectedFile2)
   //  this.previewImage2 =this.selectedFile2.name;
  }
  click(a: any){
  console.log(a)
       this.stay_logged_in = !this.stay_logged_in;
        console.log(this.stay_logged_in)
        this.stay_logged_in = !this.stay_logged_in;
        console.log(this.stay_logged_in)
   if(this.stay_logged_in==true){
          this.stay_log = 'true'

   }else{
    this.stay_log = 'false'
   }
  }
   clicka(a: any){
   this.send_email = !this.send_email;
        console.log(this.send_email)
   if(this.send_email==true){
          this.semail = 'true'

   }else{
    this.semail = 'false'
   }

  }
  checkPassword(group: FormGroup) {
    let pass = group.controls.newPswd.value;
    let confirm_password = group.controls.conPswd.value;
    var flag = false
    let returnable: any = {
      pwdNotSame: null,
    }
    if (pass != confirm_password) {
      returnable.pwdNotSame = true
      flag = true
    }
    return flag ? returnable : null;
  }

    onEyeClick(field: any, type: any) {
    if (field == 'pass') {
      this.pass = type
    }
    else if (field == 'Cpass') {
      this.Cpass = type
    } else {
      this.confirmPass = type
    }
  }
   submitForm(): void {
    this.isFormSubmitted = true;
    let obj={
        old_password: this.passwordform.controls.currPswd.value,
        new_password: this.passwordform.controls.newPswd.value,
          //      conPswd: this.passwordform.controls.conPswd.value,

    }
    const email = this.passwordform.controls.currPswd.value;
    this.authService.resetpass(obj).subscribe((response : any) => {
           this.toastr.success('Password changed!', 'Success!');
           this.error=""
           this.edit= false
      },
      (error : any) => {
      this.error= error.error.errors.msg
        console.log(error.error.errors.msg);
      }
    );
  }


    addcredit(): void {
  //  this.isFormSubmitted = true;
    let obj={
        price: this.price
    //    new_password: this.passwordform.controls.newPswd.value,
          //      conPswd: this.passwordform.controls.conPswd.value,

    }
  //  const email = this.passwordform.controls.currPswd.value;
    this.authService.addcredit(obj).subscribe((response : any) => {
          // this.toastr.success('Credit added', 'Success!');
        this.modalFlag1= false
        this.getprofile();
        this.router.navigate([`/home/payment`])
      },
      (error : any) => {
      this.error= error.error.errors.msg
        console.log(error.error.errors.msg);
      }
    );
  }
   getreviewlistfilter(a: any){
   this.filteroption = a
    let obj={
        'id': this.profile.id,
        'role' : 'painter',
        'order_filter': a,
        'limit': 5
    }
		 this.authService.getreivewlist(obj).subscribe((response : any) => {
         this.reviewlist= response.data
          this.shownewreview=  false
           this.reviewlist.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);
        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
        console.log('rem days', val.days_remaining);
      });
        //   this.toastr.success('Password changed!', 'Success!');
      })
  }

  paginationOnChange(event: any) {
  console.log("PaginationAmit==========>",event)
    this.limit = event.pageSize;
    this.offset = event.pageIndex * event.pageSize;
    this.getreviewlist()
  }

  showfilterrevire(){
   this.shownewreview= !this.shownewreview
  }
  //=====================getreviewlist24FebAmitStart
    getreviewlist(){
    let obj={
        'id': this.profile.id,
        'role' : 'painter',
        'limit': this.limit,
        'offset' : this.offset
    }
		 this.authService.getreivewlist(obj).subscribe((response : any) => {
         this.reviewlist= response.data
         this.totalRecords = response.count;
           this.reviewlist.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);
      });
      })
     // this.authService.getreivewlist(obj).subscribe((response: any) => {
     // this.reviewlist = response.data;
     // this.totalRecords = response.data.length
     // this.totalRecords = response.totalRecords; // Assuming your API returns the total number of records
     // this.updateDaysRemaining();
   // });
  }

  goToPage(pageNumber: number) {
    console.log('page number-->>>',pageNumber)
   this.offset = (pageNumber - 1) * this.limit;
   this.currentPage = pageNumber;
   this.getreviewlist();
 }

 goToPreviousPage() {
   console.log('offset value in previous-->>>', this.offset);
   if (this.currentPage > 1) {
      this.currentPage--;
      this.offset -= this.limit;
      console.log('offset value in previous-->>>', this.offset);
      this.getreviewlist();
   }
}

goToNextPage() {
   console.log('next page offset ', this.offset);
   if ((this.currentPage * this.limit) < this.totalRecords) {
      this.currentPage++;
      this.offset += this.limit;
      this.getreviewlist();
   }
}

 getPageNumbers(): any[] {
  const totalPages = Math.ceil(this.totalRecords / this.limit);
  return Array(totalPages).fill(0).map((x, i) => i + 1);
}

// updateDaysRemaining() {
//     let current_date: any = moment(new Date());
//     this.reviewlist.forEach((val: any, ind: number) => {
//       let deadline_date: any = moment(val.created_at);
//       val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
//     });
//   }
 //=====================getreviewlist24FebAmitEnd

  getprofile(){
		 this.authService.getprofile().subscribe((response : any) => {
         this.profile= response.profileData
         this.stay_log =this.profile.stay_logged_in
         this.semail = this.profile.send_email
         if(this.profile.stay_logged_in== "true"){
          this.stay_logged_in = true
         }else{
               this.stay_logged_in = false
         }
         if(this.profile.send_email== "true"){
          this.send_email = true
         }else{
               this.send_email = false
         }
         if(this.profile.state){
       this.savedstate = this.profile.state
      }
         var date = new Date(this.profile.created_at);
         this.currentdate = new Date()
          this.newdate = date.setFullYear(date.getFullYear() + 1);
        console.log(this.newdate)
        var d = new Date(this.profile.created_at);
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    var c = new Date(year + 1, month, day);
    console.log(c);
    this.newdate =  new Date(year + 1, month, day);
    console.log(c)
    if((this.currentdate > this.newdate) || (this.reviewlist.length >100)){
    this.show = true
    }else{
        this.show = false
    }
     //     this.send_email = this.profile.send_email
         this.profileForm.patchValue({

         'phone': this.profile.phone_number,
         'address': this.profile.full_address,'city': this.profile.city,
         'zipcode': this.profile.zip_code,
           'location': this.profile.location,
         'latitude': this.profile.latitude,
                  'longitude': this.profile.longitude,
                          'state' : this.profile.state,
                                  'about_me':  this.profile.about_me,
                                  'email' : this.profile.email
                         // 'city' : this.profile.city


         })
         if(this.profile.profile_image != 'undefined'){
           this.previewImage2 = this.profile.profile_image
           this.selectedFile2 =  this.profile.profile_image
         console.log(this.profileForm)
         }
       this.getreviewlist()

        //   this.toastr.success('Password changed!', 'Success!');

      })
  }

    getprofilebyid(){
		 this.authService.getprofilebyvalue(this.id).subscribe((response : any) => {
         this.profile= response.profileData
         this.stay_log =this.profile.stay_logged_in
         this.semail = this.profile.send_email
         if(this.profile.stay_logged_in== "true"){
          this.stay_logged_in = true
         }else{
               this.stay_logged_in = false
         }
         if(this.profile.send_email== "true"){
          this.send_email = true
         }else{
               this.send_email = false
         }

     //     this.send_email = this.profile.send_email
         this.profileForm.patchValue({

         'phone': this.profile.phone_number,
         'address': this.profile.full_address,'city': this.profile.city,'state': this.profile.state,
         'zipcode': this.profile.zip_code,


         })
         if(this.profile.profile_image){
           this.previewImage2 = this.profile.profile_image
           this.selectedFile2 =  this.profile.profile_image
         console.log(this.profileForm)
         }

        //   this.toastr.success('Password changed!', 'Success!');

      })
  }
  addComment(ev: any): void {
    const comment: NewComment = {
      content: ev.msg,
      painterClientID: this.painterProfile.painter.clientID,
      authorEmail: this.tokenStorage.getEmail(),
      rating: ev.rate
    };
    this.commentService.postComment(comment).subscribe(res => {
      this.loadPainterProfileDataAndComments();
    });
  }

  filter(startFromFirstPage: boolean): void {
    this.commentService.getPainterComments(this.painterProfile.painter.clientID, this.page, this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue, this.selectedOrdering.sortDir
    ).subscribe(res => {
      this.pageableResponse = res;
      this.checkBadgeMoreThan100Comments();
    });
  }

  setSelectedRate(event: any): void {
    this.userSelectedRate = event;
  }

  validateCommentContent(): boolean {
    if (this.userCommentContent != null && this.userCommentContent.length > 0 && this.userSelectedRate != null) {
      return true;
    }
    return false;
  }

  loadPainterProfileDataAndComments(): void {
    const clientID = this.activatedRoute.snapshot.paramMap.get('clientid');
    this.painterService.getPainterDetailsForProfile(clientID!).subscribe(response => {
      this.painterProfile = response;
      this.checkBadgeBrushingForOverOneYear();
      this.avgRating = this.painterProfile.painter.avgRating;
      this.filter(true);
      console.log(response);
    });
  }

  checkBadgeBrushingForOverOneYear(): void {
    if (this.painterProfile.brushingForOverOneYear === true) {
      this.painterBadges[0].isReached = true;
    }
  }

  checkBadgeMoreThan100Comments(): void {
    if (this.pageableResponse?.totalElements != null && this.pageableResponse?.totalElements > 100) {
      this.painterBadges[1].isReached = true;
    }
  }

  checkIfPainterHasAnyBadges(): boolean {
    return this.painterBadges.filter(badge => badge.isReached === true).length > 0;
  }
  changeOrdering(event: any): void {
    this.orderOptions.forEach(item => {
      if (item.optionLabel === event.optionLabel) {
        item.optionSelected = true;
      } else {
        item.optionSelected = false;
      }
    });
    this.filter(false);
  }
  get selectedOrdering(): any {
    return this.orderOptions.filter(pt => pt.optionSelected === true)[0];
  }

  closeComplainModal() {
    this.modalFlag = false
    this.modalFlag1 = false
  }
}
