import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TokenStorageService} from '../../services/token-storage.service';
import {MessageService} from '../../services/message.service';
import {ShareDataService} from '../../services/data-sharing/share-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('sideNavbar') sideNavbar!: ElementRef;
  @ViewChild('expandFilters') expandFilters!: ElementRef;
  @ViewChild('closeFilters') closeFilters!: ElementRef;
  @ViewChild('childRoutersHomeComponent') childComponent!: ElementRef;
  menuVisible = false;
  constructor(private router: Router,
              private tokenStorage: TokenStorageService,
              public messageService: MessageService,
              private shareDataService: ShareDataService) {
    this.messageService.connect();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
   this.hideNavbarWhenRouteChange();
   const hideFun = (): void => {
     if (this.menuVisible) {
       this.sideNavbar.nativeElement.classList.remove('animationDisplayBlockNav');
       this.sideNavbar.nativeElement.classList.add('animationSmoothHideNav');
       setTimeout(() => {
         this.sideNavbar.nativeElement.style.display = 'none';
         this.menuVisible = false;
       }, 500);
     }
   };
   this.shareDataService.setHideSideNavbarFunction(hideFun);
  }

  ngOnDestroy(): void {
    this.messageService.disconnect();
  }
  public toggleMenu(event: any): void {
    if (this.menuVisible) {
      this.sideNavbar.nativeElement.classList.remove('animationDisplayBlockNav');
      this.sideNavbar.nativeElement.classList.add('animationSmoothHideNav');
      setTimeout(() => {
        this.sideNavbar.nativeElement.style.display = 'none';
        this.menuVisible = false;
      }, 500);
    } else {
      this.shareDataService.hideMarketplaceFiltersIfExpanded();
      this.sideNavbar.nativeElement.style.display = 'block';
      this.sideNavbar.nativeElement.classList.remove('animationSmoothHideNav');
      this.sideNavbar.nativeElement.classList.add('animationDisplayBlockNav');
      this.menuVisible = true;
    }
  }

  hideNavbarWhenRouteChange(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (this.menuVisible) {
          this.menuVisible = false;
          this.sideNavbar.nativeElement.style.display = 'none';
        }
      }
    });
  }

  windowResizeEvent(): void {
    const screenWidth = screen.width;
    if (screenWidth > 1280) {
      this.menuVisible = false;
      this.sideNavbar.nativeElement.classList.remove('animationSmoothHideNav');
      this.sideNavbar.nativeElement.classList.remove('animationDisplayBlockNav');
      this.sideNavbar.nativeElement.style.display = 'block';
    } else {
      this.sideNavbar.nativeElement.style.display = 'none';
      this.menuVisible = false;
    }
  }
}
