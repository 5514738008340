import { Component } from '@angular/core';
import { CreatorSection } from '../../model/CreatorSection';
import { EProjectType } from '../../model/enums/EProjectType';
import { EJobType } from '../../model/enums/EJobType';
import { ItemsProvide } from '../../model/enums/template/ItemsProvide';
import { SurfaceCondition } from '../../model/enums/template/SurfaceCondition';
import { HasWallpaper } from '../../model/enums/template/HasWallpaper';
import { SurfaceFinish } from '../../model/enums/template/SurfaceFinish';
import { TSurfaceArea } from '../../model/enums/tags/TSurfaceArea';
import { WhatPainted } from '../../model/enums/template/WhatPainted';
import { ERoomType } from '../../model/enums/ERoomType';
import { RoomsNumber } from '../../model/enums/template/RoomsNumber';
import { LocalType } from '../../model/enums/template/LocalType';
import { CeilingHeight } from '../../model/enums/template/CeilingHeight';
import { PropertyType } from '../../model/enums/template/PropertyType';
import { WorkingSchedule } from '../../model/enums/template/WorkingSchedule';
import { Labels } from '../../model/enums/template/Labels';
import { SurfaceType } from '../../model/enums/template/SurfaceType';

export enum EProjectCreatorInputType {
  CHECKBOXES,
  IMAGES,
  IMAGES_MULTIPLE,
  BOXES,
  BOXES_WITH_IMAGES,
  SELECT
}

@Component({
  template: ''
})
export abstract class ProjectCreatorsComponent {

  pages!: [CreatorSection];
  howManyPages = 4;
  currentPage = 0;
  postedProject: any;
  /* labels map */
  selectedValues: Map<string, number> = new Map<string, number>();



  /* view flags */
  projectAdvancedExterior = false;
  projectAdvancedInterior = false;
  choosingJobType = false;

  chosenJobType!: EJobType;
  pathSelectors = {
    jobTypes: [
      {
        label: 'House',
        value: EJobType.HOUSE,
        description: '',
        image: '/assets/project-creator/house.png'
      },
      // {
      //   label: 'Deck',
      //   value: EJobType.DECK,
      //   description: '',
      //   image: '/assets/project-creator/deck.png'
      // },
      // {
      //   label: 'Fence',
      //   value: EJobType.FENCE,
      //   image: '/assets/project-creator/fence.png'
      // },
    ],
    projectTypes: [
      {
        ifAdvanced: true,
        label: 'Interior',
        value: EProjectType.PROJECT_INTERIOR,
        description: 'Interior projects are all about the work that needs to be done inside your apartment. Anything from painting your kitchen to putting a wallpaper in your bedroom. ',
        image: '/assets/project-creator/interior-type.png'
      },
      {
        ifAdvanced: true,
        label: 'Exterior',
        value: EProjectType.PROJECT_EXTERIOR,
        description: 'Exterior projects include all jobs related with outer part of your house.',
        image: '/assets/project-creator/exterior-type.png'
      },
      // {
      //   ifAdvanced: false,
      //   label: 'Others',
      //   value: EProjectType.PROJECT_OTHERS,
      //   description: 'You got some kind of special task for our professionals which do not match previous categories? You have found right place for your project then. Make sure to provide good description!',
      //   image: '/assets/project-creator/other-type.png'
      // },
    ]
  };
  projectDetailsSections = {
    sectionTitle: 'Project detailed info',
    items: [
      {
        id: 0,
        title: 'Images',
        description: 'Upload your images here. Projects with attached images are more attractive for professionals.',
      },
      {
        id: 1,
        title: 'Project title', description: 'Please enter short title which will describe your job in few words. If you will leave this space empty, we will provide it for you.', hint: '0 /100 characters'
      },
      {
        id: 2,
        title: 'Description', description: 'Please provide us with short description of your project. It\n' +
          '          will help potential professionals with better understanding', hint: '0 /400 characters'
      },
      {
        id: 3,
        title: 'Timeline',
        description: 'Please set the start date and end date to provide us with the timeframe of your project.',
      },
    ]
  };
  roomsPageAdvancedCreator = {
    title: 'Manage your rooms',
    claim: 'You haven\'t added any rooms yet',
    hint: 'At least 1 rooms is required.'
  };

  private advancedWayInteriorPages = [
    {
      id: 1,
      title: 'Is this home or a business',
      sectionKey: 'localType',
      sectionPrefix: 'hb',
      type: EProjectCreatorInputType.IMAGES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Home', selected: false, value: LocalType.HOME, image: '/assets/project-creator/home.png' },
        { id: 2, label: 'Business', selected: false, value: LocalType.BUSINESS, image: '/assets/project-creator/business.png' },
      ]
    },
    {
      id: 2,
      title: 'Are you providing any items for work? ',
      sectionKey: 'providingItems',
      sectionPrefix: 'it',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'No at all', selected: false, value: ItemsProvide.NOT_PROVIDING },
        { id: 2, label: 'I will provide only paint', selected: false, value: ItemsProvide.ONLY_PAINT },
        { id: 3, label: 'Paint and painting supplies', selected: false, value: ItemsProvide.PAINT_AND_SUPPLIES },
        { id: 4, label: 'I can provide everything required but need a list', selected: false, value: ItemsProvide.ALL }
      ]
    },
    {
      id: 3,
      title: 'What kind of property?',
      sectionKey: 'propertyType',
      sectionPrefix: 'pr',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Bungalow', selected: false, value: PropertyType.BUNGALOW },
        { id: 2, label: 'Storey house', selected: false, value: PropertyType.STOREY_HOUSE },
        { id: 3, label: 'Tenement', selected: false, value: PropertyType.TENEMENT },
        { id: 4, label: 'Condo', selected: false, value: PropertyType.CONDO },
        { id: 5, label: 'Multi-storey building', selected: false, value: PropertyType.MULTI_STOREY_BUILDING }
      ]
    },
    {
      id: 4,
      title: 'When would you like the work to be done?',
      sectionKey: 'schedulingPreferences',
      sectionPrefix: 'wh',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'I can adjust to the painter', selected: false, value: WorkingSchedule.CAN_ADJUST },
        { id: 2, label: 'Between 8 and 16', selected: false, value: WorkingSchedule.BETWEEN_8_AND_16 },
        { id: 3, label: 'Between 16 and 22', selected: false, value: WorkingSchedule.BETWEEN_16_AND_22 },
        { id: 3, label: 'During night shift', selected: false, value: WorkingSchedule.DURING_NIGHT_SHIFT }
      ]
    },
  ];
  private basicWayInteriorPages = [
    {
      id: 1,
      title: 'Is this home or a business',
      sectionKey: 'localType',
      sectionPrefix: 'hb',
      type: EProjectCreatorInputType.IMAGES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Home', selected: false, value: LocalType.HOME, image: '/assets/project-creator/home.png' },
        { id: 2, label: 'Business', selected: false, value: LocalType.BUSINESS, image: '/assets/project-creator/business.png' },
      ]
    },
    {
      id: 2,
      title: 'How many rooms need to be painted?',
      sectionKey: 'roomsNumber',
      sectionPrefix: 'ro',
      type: EProjectCreatorInputType.SELECT,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: '1', selected: false, value: RoomsNumber.ONE_ROOM },
        { id: 2, label: '2', selected: false, value: RoomsNumber.TWO_ROOMS },
        { id: 3, label: '3', selected: false, value: RoomsNumber.THREE_ROOMS },
        { id: 4, label: '4', selected: false, value: RoomsNumber.FOUR_ROOMS },
        { id: 5, label: '5', selected: false, value: RoomsNumber.FIVE_ROOMS },
        { id: 6, label: '6', selected: false, value: RoomsNumber.SIX_ROOMS },
        { id: 7, label: '7', selected: false, value: RoomsNumber.SEVEN_ROOMS },
        { id: 8, label: '8', selected: false, value: 8 },
        { id: 9, label: '9', selected: false, value: 9 },
        { id: 10, label: '10', selected: false, value: 10 },
        { id: 11, label: '11', selected: false, value: 11 },
        { id: 12, label: '12', selected: false, value: 12 },
        { id: 13, label: '13', selected: false, value: 13 },
        { id: 14, label: '14', selected: false, value: 14 },
        { id: 15, label: '15', selected: false, value: 15 },
        { id: 16, label: '16', selected: false, value: 16 },
        { id: 17, label: '17', selected: false, value: 17 },
        { id: 18, label: '18', selected: false, value: 18 },
        { id: 19, label: '19', selected: false, value: 19 },
        { id: 20, label: '20', selected: false, value: 20 }

      ]
    },
    // fixme: brakuje tego
    {
      id: 3,
      title: 'Select room types you need painted',
      sectionKey: 'rooms-types',
      sectionPrefix: 'rt',
      type: EProjectCreatorInputType.IMAGES_MULTIPLE,
      minimumChecked: false,
      multipleChoice: true,
      items: [
        { id: 1, label: 'Living Room', selected: false, value: ERoomType.LIVING_ROOM, mapLabel: 'roomLiving', image: '/assets/project-creator/living-room.png' },
        { id: 2, label: 'Bathroom', selected: false, value: ERoomType.BATHROOM, mapLabel: 'roomBathroom', image: '/assets/project-creator/bathroom.png' },
        { id: 3, label: 'Bedroom', selected: false, value: ERoomType.BEDROOM, mapLabel: 'roomBedroom', image: '/assets/project-creator/bedroom.png' },
        { id: 4, label: 'Dining Room', selected: false, value: ERoomType.DINING_ROOM, mapLabel: 'roomDining', image: '/assets/project-creator/dining-room.png' },
        { id: 5, label: 'Kitchen', selected: false, value: ERoomType.KITCHEN, mapLabel: 'roomKitchen', image: '/assets/project-creator/kitchen.png' },
        { id: 6, label: 'Hallway', selected: false, value: ERoomType.HALLWAY, mapLabel: 'roomHallway', image: '/assets/project-creator/hallway.png' },
        { id: 7, label: 'Others', selected: false, value: ERoomType.OTHER, mapLabel: 'roomOthers', image: '/assets/project-creator/others.png' },
      ]
    },
    {
      id: 4,
      title: 'What do you want painted?',
      sectionKey: 'what-painted',
      sectionPrefix: 'wp',
      type: EProjectCreatorInputType.IMAGES_MULTIPLE,
      minimumChecked: false,
      multipleChoice: true,
      items: [
        { id: 1, label: 'Walls', selected: false, value: WhatPainted.WALLS, mapLabel: 'paintingWalls', image: '/assets/project-creator/walls.png' },
        { id: 2, label: 'Trim', selected: false, value: WhatPainted.TRIM, mapLabel: 'paintingTrim', image: '/assets/project-creator/trims.png' },
        { id: 3, label: 'Ceiling', selected: false, value: WhatPainted.CEILING, mapLabel: 'paintingCeiling', image: '/assets/project-creator/ceiling.png' },
        { id: 4, label: 'Doors', selected: false, value: WhatPainted.DOORS, mapLabel: 'paintingDoors', image: '/assets/project-creator/doors.png' },
        { id: 5, label: 'Window frames', selected: false, value: WhatPainted.WINDOW_FRAMES, mapLabel: 'paintingWindows', image: '/assets/project-creator/window-frames.png' }
      ]
    },

    {
      id: 5,
      title: 'How big is surface you want to be painted (estimated in m²)? ',
      sectionKey: 'surfaceArea',
      sectionPrefix: 'su',
      type: EProjectCreatorInputType.SELECT,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'less than 30 m²', selected: false, value: TSurfaceArea.LESS_THAN_30 },
        { id: 2, label: '30-50m²', selected: false, value: TSurfaceArea.MORE_THAN_30_LESS_THAN_50 },
        { id: 3, label: '50-70m²', selected: false, value: TSurfaceArea.MORE_THAN_50_LESS_THAN_70 },
        { id: 4, label: 'more than 70m²', selected: false, value: TSurfaceArea.MORE_THAN_70 },
        { id: 5, label: 'Need help in measurement', selected: false, value: TSurfaceArea.NEED_HELP_IN_MEASURE }
      ]
    },
    {
      id: 6,
      title: 'What is the height of your ceiling (estimated in ft)',
      sectionKey: 'ceilingHeight',
      sectionPrefix: 'ch',
      minimumChecked: false,
      type: EProjectCreatorInputType.SELECT,
      multipleChoice: false,
      items: [
        { id: 1, label: '4 ft', selected: false, value: CeilingHeight.FOUR_FT },
        { id: 2, label: '5ft', selected: false, value: CeilingHeight.FIVE_FT },
        { id: 3, label: '6ft', selected: false, value: CeilingHeight.SIX_FT },
        { id: 4, label: '7ft', selected: false, value: CeilingHeight.SEVEN_FT },
        { id: 5, label: '8ft and more', selected: false, value: CeilingHeight.EIGHT_AND_MORE_FT },
        { id: 6, label: 'Need help in measurement', selected: false, value: CeilingHeight.NEED_HELP_IN_MEASURE }
      ]
    },
    {
      id: 7,
      title: 'What color or finish do you want',
      sectionKey: 'color-finish',
      sectionPrefix: 'cf',
      type: EProjectCreatorInputType.CHECKBOXES,
      minimumChecked: false,
      multipleChoice: true,
      items: [
        { id: 1, label: 'Repainting - same color', selected: false, value: SurfaceFinish.SAME_COLOR, mapLabel: 'sameColor' },
        { id: 2, label: 'Repainting - different color', selected: false, value: SurfaceFinish.COLOR_CHANGE, mapLabel: 'colorChange' },
        { id: 3, label: 'Decorative painting - faux finish', selected: false, value: SurfaceFinish.FAUX_FINISH, mapLabel: 'fauxFinish' },
        { id: 4, label: 'Decorative painting - texture coating', selected: false, value: SurfaceFinish.TEXTURE_COATING, mapLabel: 'textureCoating' },
      ]
    },
    {
      id: 8,
      title: 'Any wallpaper to put or remove?',
      sectionKey: 'hasWallpaper',
      sectionPrefix: 'wp',
      type: EProjectCreatorInputType.BOXES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'No', selected: false, value: HasWallpaper.NO_WALLPAPER },
        { id: 2, label: 'Yes', selected: false, value: HasWallpaper.HAS_WALLPAPER },
      ]
    },
    {
      id: 9,
      title: 'How would you rate your surface condition? ',
      sectionKey: 'surfaceCondition',
      sectionPrefix: 'suc',
      type: EProjectCreatorInputType.BOXES_WITH_IMAGES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Bad', selected: false, value: SurfaceCondition.BAD, image: '/assets/project-creator/bad.png' },
        { id: 2, label: 'Poor', selected: false, value: SurfaceCondition.POOR, image: '/assets/project-creator/sad.png' },
        { id: 3, label: 'Fair', selected: false, value: SurfaceCondition.FAIR, image: '/assets/project-creator/fair.png' },
        { id: 4, label: 'Good', selected: false, value: SurfaceCondition.GOOD, image: '/assets/project-creator/good.png' },
        { id: 5, label: 'Need help in measurement', selected: false, value: SurfaceCondition.NEED_HELP_IN_MEASURE }
      ]
    },
    {
      id: 10,
      title: 'Are you providing any items for work? ',
      sectionKey: 'providingItems',
      sectionPrefix: 'it',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'No at all', selected: false, value: ItemsProvide.NOT_PROVIDING },
        { id: 2, label: 'I will provide only paint', selected: false, value: ItemsProvide.ONLY_PAINT },
        { id: 3, label: 'Paint and painting supplies', selected: false, value: ItemsProvide.PAINT_AND_SUPPLIES },
        { id: 4, label: 'I can provide everything required but need a list', selected: false, value: ItemsProvide.ALL }
      ]
    },
    {
      id: 11,
      title: 'What kind of property?',
      sectionKey: 'propertyType',
      sectionPrefix: 'pr',
      type: EProjectCreatorInputType.BOXES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Bungalow', selected: false, value: PropertyType.BUNGALOW },
        { id: 2, label: 'Storey house', selected: false, value: PropertyType.STOREY_HOUSE },
        { id: 3, label: 'Tenement', selected: false, value: PropertyType.TENEMENT },
        { id: 4, label: 'Condo', selected: false, value: PropertyType.CONDO },
        { id: 5, label: 'Multi-storey building', selected: false, value: PropertyType.MULTI_STOREY_BUILDING }
      ]
    },
    {
      id: 12,
      title: 'When would you like the work to be done?',
      sectionKey: 'schedulingPreferences',
      sectionPrefix: 'wh',
      type: EProjectCreatorInputType.BOXES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'I can adjust to the painter', selected: false, value: WorkingSchedule.CAN_ADJUST },
        { id: 2, label: 'Between 8 and 16', selected: false, value: WorkingSchedule.BETWEEN_8_AND_16 },
        { id: 3, label: 'Between 16 and 22', selected: false, value: WorkingSchedule.BETWEEN_16_AND_22 },
        { id: 3, label: 'During night shift', selected: false, value: WorkingSchedule.DURING_NIGHT_SHIFT }
      ]
    },
    {
      id: 13,
      title: 'Select all those applicable for your project',
      sectionKey: 'labels',
      sectionPrefix: 'lb',
      type: EProjectCreatorInputType.CHECKBOXES,
      minimumChecked: true,
      multipleChoice: true,
      items: [
        { id: 1, label: 'Moving furniture', selected: false, value: Labels.MOVING_FURNITURE, mapLabel: 'movingFurniture' },
        { id: 2, label: 'Cleaning windows', selected: false, value: Labels.CLEANING_WINDOWS, mapLabel: 'cleaningWindows' },
        { id: 3, label: 'Cleaning the floors', selected: false, value: Labels.CLEANING_FLOORS, mapLabel: 'cleaningFloors' },
        { id: 4, label: 'Disposing of the debris', selected: false, value: Labels.DISPOSING_DEBRIS, mapLabel: 'disposingDebris' },
        { id: 5, label: 'Removing/attaching light fixtures', selected: false, value: Labels.REMOVING_ATTACHING_LIGHT_FIXTURES, mapLabel: 'lightFixtures' },
      ]
    },
  ];
  private basicWayExteriorPages = [
    {
      id: 1,
      title: 'What kind of property do you need painted?',
      sectionKey: 'propertyType',
      sectionPrefix: 'pt',
      minimumChecked: false,
      additionalInputId: 'property-type-input',
      additionalInputPlaceholder: 'Type property',
      type: EProjectCreatorInputType.IMAGES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Apartment', selected: false, value: PropertyType.APARTMENT, image: '/assets/project-creator/apartment.png' },
        { id: 2, label: 'House', selected: false, value: PropertyType.HOUSE, image: '/assets/project-creator/house.png' },
        { id: 3, label: 'Townhouse', selected: false, value: PropertyType.TOWN_HOUSE, image: '/assets/project-creator/townhouse.png' },
        { id: 4, label: 'Condo', selected: false, value: PropertyType.CONDO, image: '/assets/project-creator/condo.png' },
      ]
    },
    {
      id: 2,
      title: 'Select surface type you need painted',
      sectionKey: 'surfaceType',
      sectionPrefix: 'st',
      additionalInputId: 'surface-type-input',
      type: EProjectCreatorInputType.BOXES,
      additionalInputPlaceholder: 'Type surface',
      minimumChecked: false,
      items: [
        { id: 1, label: 'Siding', selected: false, value: SurfaceType.SIDING },
        { id: 2, label: 'Wood Siding', selected: false, value: SurfaceType.WOOD_SIDING },
        { id: 3, label: 'Brick', selected: false, value: SurfaceType.BRICK },
        { id: 4, label: 'Stucco', selected: false, value: SurfaceType.STUCCO },
      ]
    },
    {
      id: 3,
      title: 'How many ft² has surface you want to be painted?',
      sectionKey: 'surfaceArea',
      sectionPrefix: 'su',
      minimumChecked: false,
      type: EProjectCreatorInputType.SELECT,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Less than 30 ft²', selected: false, value: TSurfaceArea.LESS_THAN_30 },
        { id: 2, label: '30-50ft²', selected: false, value: TSurfaceArea.MORE_THAN_30_LESS_THAN_50 },
        { id: 3, label: '50-70ft²', selected: false, value: TSurfaceArea.MORE_THAN_50_LESS_THAN_70 },
        { id: 4, label: 'More than 70ft²', selected: false, value: TSurfaceArea.MORE_THAN_70 },
        { id: 5, label: 'I\'m not sure', selected: false, value: TSurfaceArea.NEED_HELP_IN_MEASURE }
      ]
    },
    {
      id: 4,
      title: 'How would you rate your surface condition? ',
      sectionKey: 'surfaceCondition',
      sectionPrefix: 'suc',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES_WITH_IMAGES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Bad', selected: false, value: SurfaceCondition.BAD, image: '/assets/project-creator/bad.png' },
        { id: 2, label: 'Poor', selected: false, value: SurfaceCondition.POOR, image: '/assets/project-creator/sad.png' },
        { id: 3, label: 'Fair', selected: false, value: SurfaceCondition.FAIR, image: '/assets/project-creator/fair.png' },
        { id: 4, label: 'Good', selected: false, value: SurfaceCondition.GOOD, image: '/assets/project-creator/good.png' },
        { id: 5, label: 'I don\'t know, I need help', selected: false, value: SurfaceCondition.NEED_HELP_IN_MEASURE }
      ]
    },
    {
      id: 5,
      title: 'Are you providing any items for work? ',
      sectionKey: 'providingItems',
      sectionPrefix: 'it',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'No at all', selected: false, value: ItemsProvide.NOT_PROVIDING },
        { id: 2, label: 'I will provide only paint', selected: false, value: ItemsProvide.ONLY_PAINT },
        { id: 3, label: 'Paint and painting supplies', selected: false, value: ItemsProvide.PAINT_AND_SUPPLIES },
        { id: 4, label: 'I can provide everything required but need a list', selected: false, value: ItemsProvide.ALL }
      ]
    },
    {
      id: 6,
      title: 'What other things would you like the painter to do?',
      sectionKey: 'other-things',
      sectionPrefix: 'ot',
      minimumChecked: true,
      multipleChoice: true,
      type: EProjectCreatorInputType.CHECKBOXES,
      // additionalInputId: 'surface-type-input',
      // additionalInputPlaceholder: 'Type surface',
      items: [
        { id: 1, label: 'Power wash', selected: false, value: Labels.POWER_WASH, mapLabel: 'powerWash' },
        { id: 2, label: 'Cleaning windows', selected: false, value: Labels.CLEANING_WINDOWS, mapLabel: 'cleaningWindows' },
        { id: 3, label: 'Painting gutters', selected: false, value: Labels.CLEANING_FLOORS, mapLabel: 'cleaningFloors' },
        { id: 4, label: 'Disposing of the debris', selected: false, value: Labels.DISPOSING_DEBRIS, mapLabel: 'disposingDebris' },
      ]
    },
  ];
  private algorithmWayFencePages = [
    {
      id: 1,
      title: 'Is this home or a business',
      sectionKey: 'home-business',
      sectionPrefix: 'hb',
      type: EProjectCreatorInputType.IMAGES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Home', selected: false, value: 1, image: '/assets/project-creator/home.png' },
        { id: 2, label: 'Business', selected: false, value: 2, image: '/assets/project-creator/business.png' },
      ]
    },
    {
      id: 2,
      title: 'What material is the fence made of?',
      sectionKey: 'fence-material',
      sectionPrefix: 'fm',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Wood', selected: false, value: 1 },
        { id: 2, label: 'Brick', selected: false, value: 2 },
        { id: 3, label: 'Stone', selected: false, value: 3 },
      ]
    },
    {
      id: 3,
      title: 'How tall is you fence/gate?',
      sectionKey: 'fence-tall',
      sectionPrefix: 'ft',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: '6 feet or less', selected: false, value: 1 },
        { id: 2, label: 'Over 6 feet', selected: false, value: 2 },
      ]
    },
    {
      id: 4,
      title: 'How long is the fence?',
      sectionKey: 'fence-length',
      sectionPrefix: 'fl',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Less than 100 feet', selected: false, value: 1 },
        { id: 2, label: '100 - 200 feet', selected: false, value: 2 },
        { id: 3, label: '200 - 300 feet', selected: false, value: 3 },
        { id: 4, label: '300 - 400 feet', selected: false, value: 4 },
        { id: 5, label: 'More than 400 feet', selected: false, value: 5 },
      ]
    },
    {
      id: 5,
      title: 'What type of painting do you need?',
      sectionKey: 'painting-type',
      sectionPrefix: 'pt',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'First-time painting on raw surface', selected: false, value: 1 },
        { id: 2, label: 'Full repainting', selected: false, value: 2 },
        { id: 3, label: 'Touch ups', selected: false, value: 3 },
        { id: 4, label: 'Design with multiple colors', selected: false, value: 4 },
      ]
    },
    {
      id: 6,
      title: 'Do you need a gate paint?',
      sectionKey: 'gate-paint',
      sectionPrefix: 'pt',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Yes', selected: false, value: 1 },
        { id: 2, label: 'No', selected: false, value: 2 },
      ]
    },
    {
      id: 7,
      title: 'What finish is on your fence now?',
      sectionKey: 'fence-finish',
      sectionPrefix: 'pt',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Wood stain', selected: false, value: 1 },
        { id: 2, label: 'Paint', selected: false, value: 2 },
        { id: 3, label: 'Unfinished', selected: false, value: 3 },
        { id: 4, label: 'Texture', selected: false, value: 4 },
      ]
    },
    {
      id: 8,
      title: 'When would you like the work to be done?',
      sectionKey: 'working-hours',
      sectionPrefix: 'wh',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'I can adjust to the painter', selected: false, value: 1 },
        { id: 2, label: 'Between 8 and 16', selected: false, value: 2 },
        { id: 3, label: 'Between 16 and 22', selected: false, value: 3 },
        { id: 3, label: 'During night shift', selected: false, value: 4 }
      ]
    },
  ];
  private algorithmWayDeckPages = [];
  private algorithmWayHousePages = [
    {
      id: 1,
      title: 'Blank page',
      sectionKey: 'blank-page',
      sectionPrefix: 'bp',
      type: EProjectCreatorInputType.IMAGES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Home', selected: false, value: 1, image: '/assets/project-creator/home.png' }
      ]
    },
    {
      id: 2,
      title: 'Is this home or a business',
      sectionKey: 'home-business',
      sectionPrefix: 'hb',
      type: EProjectCreatorInputType.IMAGES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Home', selected: false, value: 1, image: '/assets/project-creator/home.png' },
        { id: 2, label: 'Business', selected: false, value: 2, image: '/assets/project-creator/business.png' },
      ]
    },
    {
      id: 3,
      title: 'What kind of property?',
      sectionKey: 'propertyType',
      sectionPrefix: 'pr',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Bungalow', selected: false, value: PropertyType.BUNGALOW },
        { id: 2, label: 'Storey house', selected: false, value: PropertyType.STOREY_HOUSE },
        { id: 3, label: 'Tenement', selected: false, value: PropertyType.TENEMENT },
        { id: 4, label: 'Condo', selected: false, value: PropertyType.CONDO },
        { id: 5, label: 'Multi-storey building', selected: false, value: PropertyType.MULTI_STOREY_BUILDING }
      ]
    },
    {
      id: 4,
      title: 'What material is the surface made of?',
      sectionKey: 'surface-material',
      sectionPrefix: 'fm',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Wood', selected: false, value: 1 },
        { id: 2, label: 'Brick', selected: false, value: 2 },
        { id: 3, label: 'Stucco', selected: false, value: 3 },
        { id: 4, label: 'Siding', selected: false, value: 4 },
        { id: 5, label: 'Other', selected: false, value: 5 },
      ]
    },
    {
      id: 5,
      title: 'How would you rate your surface condition? ',
      sectionKey: 'surfaceCondition',
      sectionPrefix: 'suc',
      type: EProjectCreatorInputType.BOXES_WITH_IMAGES,
      minimumChecked: false,
      multipleChoice: false,
      items: [
        { id: 1, label: 'Bad', selected: false, value: SurfaceCondition.BAD, image: '/assets/project-creator/bad.png' },
        { id: 2, label: 'Poor', selected: false, value: SurfaceCondition.POOR, image: '/assets/project-creator/sad.png' },
        { id: 3, label: 'Fair', selected: false, value: SurfaceCondition.FAIR, image: '/assets/project-creator/fair.png' },
        { id: 4, label: 'Good', selected: false, value: SurfaceCondition.GOOD, image: '/assets/project-creator/good.png' },
        { id: 5, label: 'Need help in measurement', selected: false, value: SurfaceCondition.NEED_HELP_IN_MEASURE }
      ]
    },
    {
      id: 6,
      title: 'Are you providing any items for work? ',
      sectionKey: 'providingItems',
      sectionPrefix: 'it',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'No at all', selected: false, value: ItemsProvide.NOT_PROVIDING },
        { id: 2, label: 'I will provide only paint', selected: false, value: ItemsProvide.ONLY_PAINT },
        { id: 3, label: 'Paint and painting supplies', selected: false, value: ItemsProvide.PAINT_AND_SUPPLIES },
        { id: 4, label: 'I can provide everything required but need a list', selected: false, value: ItemsProvide.ALL }
      ]
    },
    {
      id: 7,
      title: 'What type of painting do you need?',
      sectionKey: 'painting-type',
      sectionPrefix: 'pt',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'First-time painting on raw surface', selected: false, value: 1 },
        { id: 2, label: 'Full repainting', selected: false, value: 2 },
        { id: 3, label: 'Touch ups', selected: false, value: 3 },
        { id: 4, label: 'Design with multiple colors', selected: false, value: 4 },
      ]
    },
    {
      id: 8,
      title: 'When would you like the work to be done?',
      sectionKey: 'working-hours',
      sectionPrefix: 'wh',
      minimumChecked: false,
      type: EProjectCreatorInputType.BOXES,
      multipleChoice: false,
      items: [
        { id: 1, label: 'I can adjust to the painter', selected: false, value: 1 },
        { id: 2, label: 'Between 8 and 16', selected: false, value: 2 },
        { id: 3, label: 'Between 16 and 22', selected: false, value: 3 },
        { id: 3, label: 'During night shift', selected: false, value: 4 }
      ]
    },
    {
      id: 9,
      title: 'Check those which are true for your project',
      sectionKey: 'labels',
      sectionPrefix: 'lb',
      type: EProjectCreatorInputType.CHECKBOXES,
      minimumChecked: true,
      multipleChoice: true,
      items: [
        { id: 2, label: 'Cleaning windows', selected: false, value: Labels.CLEANING_WINDOWS, mapLabel: 'cleaningWindows' },
        { id: 4, label: 'Disposing of the debris', selected: false, value: Labels.DISPOSING_DEBRIS, mapLabel: 'disposingDebris' },
        { id: 5, label: 'Removing/attaching light fixtures', selected: false, value: Labels.REMOVING_ATTACHING_LIGHT_FIXTURES, mapLabel: 'lightFixtures' },
      ]
    },
      {
      id: 10,
      title: 'Gutters',
      sectionKey: 'labels',
      sectionPrefix: 'lb',
      type: EProjectCreatorInputType.SELECT,
      minimumChecked: true,
      multipleChoice: true,
      items: [
        {id: 2, label: 'Cleaning windows', selected: false, value: Labels.CLEANING_WINDOWS, mapLabel: 'cleaningWindows'},
        {id: 4, label: 'Disposing of the debris', selected: false, value: Labels.DISPOSING_DEBRIS, mapLabel: 'disposingDebris'},
        {id: 5, label: 'Removing/attaching light fixtures', selected: false, value: Labels.REMOVING_ATTACHING_LIGHT_FIXTURES, mapLabel: 'lightFixtures'},
      ]
    },
     {
      id: 11,
      title: 'Roof modeling',
      sectionKey: 'labels',
      sectionPrefix: 'lb',
      type: EProjectCreatorInputType.SELECT,
      minimumChecked: true,
      multipleChoice: true,
      items: [
        {id: 2, label: 'Cleaning windows', selected: false, value: Labels.CLEANING_WINDOWS, mapLabel: 'cleaningWindows'},
        {id: 4, label: 'Disposing of the debris', selected: false, value: Labels.DISPOSING_DEBRIS, mapLabel: 'disposingDebris'},
        {id: 5, label: 'Removing/attaching light fixtures', selected: false, value: Labels.REMOVING_ATTACHING_LIGHT_FIXTURES, mapLabel: 'lightFixtures'},
      ]
    },


  ];


  prepareData(projectType: string, isBasicCreator: boolean, jobType?: string): void {
    this.pages = [{} as CreatorSection];
    if (isBasicCreator) {
      if (projectType === EProjectType.PROJECT_INTERIOR) {
        this.pages = JSON.parse(JSON.stringify(this.basicWayInteriorPages));
        this.howManyPages = this.pages.length + 2;
      }
      if (projectType === EProjectType.PROJECT_EXTERIOR) {
        this.pages = JSON.parse(JSON.stringify(this.basicWayExteriorPages));
        this.howManyPages = this.pages.length + 2;
      }
    } else {
      if (projectType === EProjectType.PROJECT_INTERIOR) {
        this.projectAdvancedInterior = true;
        this.pages = JSON.parse(JSON.stringify(this.advancedWayInteriorPages));
        this.howManyPages = this.pages.length + 3;
      }
      if (projectType === EProjectType.PROJECT_EXTERIOR) {
        this.projectAdvancedExterior = true;
        if (jobType) {
          if (jobType === EJobType.HOUSE) {
            this.pages = JSON.parse(JSON.stringify(this.algorithmWayHousePages));
            this.howManyPages = this.pages.length + 3;
          }
          /*
          if (jobType === EJobType.FENCE){
            this.pages = JSON.parse(JSON.stringify(this.algorithmWayFencePages));
            this.howManyPages = this.pages.length + 2;
          }
          if (jobType === EJobType.DECK){
            this.pages = JSON.parse(JSON.stringify(this.algorithmWayDeckPages));
            this.howManyPages = this.pages.length + 2;
          }
           */
        }
      }
    }
  }
}

