<div class="route-title">
  <span>Projects</span>
  <app-notification-bell></app-notification-bell>
</div>
<div class="wrapper">
  <div class="projects-wrapper user_bids_card">
    <div class="top-filters">
      <div class="expand-filters" #expandFilters>
        <nb-icon icon="menu-arrow-outline" (click)="toggleMenu($event)"></nb-icon>
      </div>
      <!-- FIXME if they will not need search by title/description then delete this one -->
      <div class="input-wrapper">
        <span class="icon-wrapper project_icon">
          <nb-icon icon="search-outline"></nb-icon>
        </span>
        <input placeholder="Search" [(ngModel)]="search" (keyup)="getProjectssearch($event)" />
      </div>
      <div class="input-wrapper">
        <span class="icon-wrapper project_icon">
          <nb-icon icon="navigation-2-outline"></nb-icon>
        </span>
        <!-- <input type="text" name="" class="form-control" placeholder="Location" [(ngModel)]="newloc"
                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                    ngx-google-places-autocomplete  #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" (input)="clearLocation()" >-->

        <input placeholder="Zip code/State" [(ngModel)]="searchloc" (keyup)="getstteserach($event)" />
        <ul class="lctn_drpdwn" *ngIf="showlist">
          <li *ngFor="let item of newstatelist">
            <a (click)="handleAddressChange(item.name)">{{item.name}}</a>
          </li>
        </ul>
      </div>
      <div class="input-wrapper">
        <span class="icon-wrapper project_icon">
          <nb-icon icon="radio-button-off-outline"></nb-icon>
        </span>
        <input placeholder="Max. Distance(miles)" [(ngModel)]="radius" (keyup)="getProjectssearchradoius($event)" />
      </div>
      <button class="custom-button search-button" (click)="filter(true)">
        Search
      </button>
      <div class="search-button-res">
        <button class="custom-button" (click)="filter(true)">Search</button>
      </div>
    </div>

    <div class="card card_my_prjet card" *ngFor="let item of projects">
      <div class="div_cd d-flex">
        <div class="img_nw">
          <img [src]="item.images[0]?.image" alt="" />
        </div>
        <div class="div_pntr">
          <div class="div_hrd d-flex">
            <div class="div_prject_text">
              <div class="div_spn">
                <span class="ned_urgnt title" (click)="navigateToOffer(item, item.id)" [inputData]="item"><a>{{
                    item.project_title }}</a></span>
                <div class="iner_degn">
                  <p>{{ item.project_type | titlecase }}</p>
                </div>
                <div class="div_descrptin">
                  <p>
                    {{ item.customerDetails?.city | titlecase }},&nbsp;{{
                    item.customerDetails?.state | titlecase
                    }}
                  </p>
                </div>
              </div>
            </div>
            <div class="ago_tym" *ngIf="item.days_remaining == 0">
              <h1>Today</h1>
            </div>
            <div class="ago_tym" *ngIf="item.days_remaining != 0">
              <h1>{{ item.days_remaining }} days ago</h1>
            </div>
          </div>
          <div class="div_descrptin">
            <p>{{ item?.description }}</p>
          </div>
          <div class="section bottom">
            <div class="tags">
              <div class="tag-wrappers">
                <div class="tag fill" *ngIf="!item?.Bids">No Bids</div>
                <div class="tag fill bid-accepted" *ngIf="item?.Bids">
                  Bid Submitted
                </div>
              </div>
              <!--<div class="tag-wrappers">
                <div class="tag">{{item.tags.hasWallpaper ? item.tags.hasWallpaper : 'No wallpaper'}}</div>
                
              </div>-->
              <div class="tag-wrappers">
                <div class="tag">{{ item.room?.length }} Rooms</div>
              </div>
              <!--   <div class="tag-wrappers">
                <div class="tag">Medium Area</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Fine Collection</div>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  <app-pagination [adjacentPagesNo]="2" *ngIf="projects" (newPageNumber)="changePageNumber($event)"
      [totalPages]="collectionSize" [pageNumber]="pageSize">
    </app-pagination>-->
    <!-- <app-pagination *ngIf="bidsAcceptedPageableResponse" (newPageNumber)="changePageNumber($event)"
              [adjacentPagesNo]="2" [totalPages]="bidsAcceptedPageableResponse.totalPages"
              [pageNumber]="bidsAcceptedPageableResponse.pageable?.pageNumber">
            </app-pagination>-->

    <!-- project-listings -->

    <!-- <div class="card card_my_prjet card">
      <div class="div_cd d-flex">
        <div class="img_nw">
          <img src="assets/Rectangle5.png" alt="">
        </div>
        <div class="div_pntr">
          <div class="div_hrd d-flex">
            <div class="div_prject_text">
              <div class="div_spn">
                <span class="ned_urgnt title" (click)="viewDemoDetails()">HARDCODED TITLE CREATOR SHOULD
                  CREATE THIS TITLE</span>
                <div class="iner_degn">
                  <p>Project Interior</p>
                </div>
              </div>
            </div>
            <div class="ago_tym">
              <h1>21 days ago</h1>
            </div>
          </div>
          <div class="div_descrptin">
            <p>Arcu integer vulputate laoreet lorem eget mauris, ligula cursus rutrum. Nisl, justo vitae massa
              id massa egestas turpis. Aliquam hendrerit ultricies
              varius at nibh neque nunc sit. Tristique feugiat enim vitae sed pellentesque scelerisque metus,
              velit...</p>
          </div>
          <div class="section bottom">
            <div class="tags">
              <div class="tag-wrappers">
                <div class="tag fill">15 bids</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Wallpaper</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Four Rooms</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Medium Area</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Fine Collection</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card card_my_prjet card">
      <div class="div_cd d-flex">
        <div class="img_nw">
          <img src="assets/Rectangle5.png" alt="">
        </div>
        <div class="div_pntr">
          <div class="div_hrd d-flex">
            <div class="div_prject_text">
              <div class="div_spn">
                <span class="ned_urgnt title" (click)="viewDemoDetails()">HARDCODED TITLE CREATOR SHOULD
                  CREATE THIS TITLE</span>
                <div class="iner_degn">
                  <p>Project Interior</p>
                </div>
              </div>
            </div>
            <div class="ago_tym">
              <h1>21 days ago</h1>
            </div>
          </div>
          <div class="div_descrptin">
            <p>Arcu integer vulputate laoreet lorem eget mauris, ligula cursus rutrum. Nisl, justo vitae massa
              id massa egestas turpis. Aliquam hendrerit ultricies
              varius at nibh neque nunc sit. Tristique feugiat enim vitae sed pellentesque scelerisque metus,
              velit...</p>
          </div>
          <div class="section bottom">
            <div class="tags">
              <div class="tag-wrappers">
                <div class="tag fill">15 bids</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Wallpaper</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Four Rooms</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Medium Area</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Fine Collection</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="card card_my_prjet card">
      <div class="div_cd d-flex">
        <div class="img_nw">
          <img src="assets/Rectangle5.png" alt="">
        </div>
        <div class="div_pntr">
          <div class="div_hrd d-flex">
            <div class="div_prject_text">
              <div class="div_spn">
                <span class="ned_urgnt title" (click)="viewDemoDetails()">HARDCODED TITLE CREATOR SHOULD
                  CREATE THIS TITLE</span>
                <div class="iner_degn">
                  <p>Project Interior</p>
                </div>
              </div>
            </div>
            <div class="ago_tym">
              <h1>21 days ago</h1>
            </div>
          </div>
          <div class="div_descrptin">
            <p>Arcu integer vulputate laoreet lorem eget mauris, ligula cursus rutrum. Nisl, justo vitae massa
              id massa egestas turpis. Aliquam hendrerit ultricies
              varius at nibh neque nunc sit. Tristique feugiat enim vitae sed pellentesque scelerisque metus,
              velit...</p>
          </div>
          <div class="section bottom">
            <div class="tags">
              <div class="tag-wrappers">
                <div class="tag fill">15 bids</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Wallpaper</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Four Rooms</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Medium Area</div>
              </div>
              <div class="tag-wrappers">
                <div class="tag">Fine Collection</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>





    <!-- project-listing-end -->

    <!-- <app-nothing-to-display *ngIf="projects?.length == 0" [img]="'assets/vision.svg'"
      [claim]="'Projects that you were looking for were not found'" [subclaim]="'Try to change your search criteria!'">
    </app-nothing-to-display> -->
    <!-- <app-project-component [marketplace]="true" *ngFor="let item of projects" (click)="navigateToOffer(item, item.id)"
      [inputData]="item">
    </app-project-component> -->

    <!--        <div class="total-elements-info">{{from}}-{{to}} from {{pageableResponse?.totalElements}} offers</div>-->
  </div>
  <div class="filters" #filters (window:resize)="windowResizeEvent()">
    <div class="close-filters" #closeFilters>
      <nb-icon icon="close-outline" (click)="toggleMenu($event)"></nb-icon>
    </div>
    <div class="filters-text">Additional Filters</div>
    <div class="proper-filters">
      <!-- ORDERING-->
      <!--        <div class="filter-section-texts">

          <app-select-input [label]="'Max distance'"
                            [ownValueType]="'number'"
                            [ownValueText]="'Within ${value} miles'"
                            [inputOptions]="distanceOptions"
                            (valueChanged)="changeDistance($event)"
                            (ownOptionChanged)="assignDistanceFromSelectInput($event)"
                            class="select-input">
          </app-select-input>

          <app-select-input [label]="selectedOrdering.optionLabel"
                            [inputOptions]="orderOptions"
                            (valueChanged)="changeOrdering($event)"
                            class="select-input">
          </app-select-input>

          <app-select-input [label]="selectedPageSize.optionLabel"
                            [inputOptions]="pageSizeOptions"
                            (valueChanged)="changePageSize($event)"
                            class="select-input">
          </app-select-input>

        </div>
        <input type="text" placeholder="Search state/city/zipcode" style="height: 40px"
               (change)="setSearchStateCityZipCode($event)">
        <br>-->

      <!-- PROJECT TYPES randomToggle($event, 'projectTypes') ;-->

      <div class="filter-section small-shrink active">
        <div class="section-title" (click)="toggleSectionWithProjectTypes($event)">
          Project Type
          <img class="img" [src]="
              projectTypeExpanded
                ? 
                 'assets/icons/angle-down-solid.svg' : 'assets/icons/angle-up-solid.svg'
            " />
        </div>
        <div class="content-types" #projectsTypes id="projectTypes">
          <!--<app-checkbox-input *ngFor="let projectType of projectTypes"
            (change)="projectTypeCheckboxChanged(projectType.id)" [label]="projectType.label"
            [ngModel]="projectType.selected">
          </app-checkbox-input>-->

          <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilter($event, 'interior')" class="control control-checkbox" />

            <label class="control control-checkbox">Interior</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilter($event, 'exterior')" class="control control-checkbox" />

            <label class="control control-checkbox">Exterior</label>
          </div>

          <!--  <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilter($event, 'others')" class="control control-checkbox" />

            <label class="control control-checkbox">Other</label>
          </div>-->
        </div>
      </div>

      <div class="filter-section">
        <div class="section-title" (click)="toggleSectionWithSurfaceArea($event)">
          Property type<img [src]="
              projectTagsSurfaceAreaSectionExpanded
                ?  'assets/icons/angle-down-solid.svg' :  'assets/icons/angle-up-solid.svg'
                
            " class="img" />
        </div>
        <div class="content-types" #projectTagsSurfaceAreaSection>
          <!-- <app-checkbox-input *ngFor="let projectTag of projectTagsSurfaceArea"
            (change)="projectTagSurfaceAreaCheckboxChanged(projectTag.id)" [label]="projectTag.label"
            [ngModel]="projectTag.selected">
          </app-checkbox-input>-->

          <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilterserface($event, 'residential')" />
            <label>Residential</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilterserface($event, 'business')" />
            <label>Business</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilterserface($event, 'other')" />
            <label>Other</label>
          </div>

          <!--  <div class="d-flex align-items-center">

            <input type="checkbox" (change)="projectfilterserface($event,'more_70')">
            <label>More than 70m�</label>
          </div>

          <div class="d-flex align-items-center">

            <input type="checkbox" (change)="projectfilterserface($event,'not_sure')">
            <label>Need help in
              measurement</label>
          </div>-->
        </div>
      </div>
      <div class="filter-section">
        <div class="section-title" (click)="toggleSectionWithSurfaceCondition($event)">
          State<img [src]="
              projectTagsSurfaceConditionSectionExpanded
                ?  'assets/icons/angle-down-solid.svg' :  'assets/icons/angle-up-solid.svg'
            " class="img" />
        </div>
        <div class="content-types" #projectTagsSurfaceConditionSection>
          <!-- <app-checkbox-input *ngFor="let projectTag of projectTagsSurfaceArea"
            (change)="projectTagSurfaceAreaCheckboxChanged(projectTag.id)" [label]="projectTag.label"
            [ngModel]="projectTag.selected">
          </app-checkbox-input>-->

          <div class="d-flex align-items-center" *ngFor="let item of statelist">
            <input type="checkbox" (change)="projectfilterserfacecon($event, item.name)" />
            <label>{{ item.name }}</label>
          </div>

          <!--  <div class="d-flex align-items-center">

            <input type="checkbox" (change)="projectfilterserface($event,'more_70')">
            <label>More than 70m�</label>
          </div>

          <div class="d-flex align-items-center">

            <input type="checkbox" (change)="projectfilterserface($event,'not_sure')">
            <label>Need help in
              measurement</label>
          </div>-->
        </div>
      </div>

      <!--   <div class="filter-section ">
        <div class="section-title" (click)="toggleSectionWithSurfaceCondition($event)">Surface Condition<img
            [src]="projectTagsSurfaceConditionSectionExpanded ? 'assets/icons/angle-up-solid.svg' : 'assets/icons/angle-down-solid.svg'"
            class="img"></div>
        <div class="content-types" #projectTagsSurfaceConditionSection>
         

          <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilterserfacecon($event,'bad')">
            <label>Bad</label>

          </div>

          <div class="d-flex align-items-center">

            <input type="checkbox" (change)="projectfilterserfacecon($event,'poor')">
            <label>Poor</label>
          </div>

          <div class="d-flex align-items-center">

            <input type="checkbox" (change)="projectfilterserfacecon($event,'fair')">
            <label>Fair</label>
          </div>

          <div class="d-flex align-items-center">

            <input type="checkbox" (change)="projectfilterserfacecon($event,'good')">
            <label>Good</label>
          </div>

          <div class="d-flex align-items-center">
            <input type="checkbox" (change)="projectfilterserfacecon($event,'needHelpinMeasurment')">
            <label>Need help in
              measurement</label>

          </div>

        </div>
      </div>-->
      <!--    <div class="filter-section ">
        <div class="section-title" (click)="toggleSectionWithSurfaceCondition($event)">Tags<img
            [src]="projectTagsSurfaceConditionSectionExpanded ? 'assets/icons/angle-up-solid.svg' : 'assets/icons/angle-down-solid.svg'"
            class="img"></div>
        <div class="content-types" #projectTagsSurfaceConditionSection>
          <app-checkbox-input *ngFor="let projectTag of projectTagsSurfaceCondition"
            (change)="projectTagSurfaceConditionCheckboxChanged(projectTag.id)" [label]="projectTag.label"
            [ngModel]="projectTag.selected">
          </app-checkbox-input>
        </div>
      </div>-->

      <!-- tags ; randomToggle($event, 'projectTagsSurfaceAreaSection')
       nubmer of rooms-->
      <div class="filter-section" *ngIf="projectInteriorSelected && !projectExteriorSelected">
        <div class="section-title" (click)="toggleSectionWithRoomsQuantity($event)">
          Number Of Rooms
          <img [src]="
              roomQuantityExpanded
                ? 'assets/icons/angle-up-solid.svg'
                : 'assets/icons/angle-down-solid.svg'
            " class="img" />
        </div>
        <div class="content-types unique" #roomsQuantitySection>
          <app-checkbox-input *ngFor="let roomQ of roomsQuantity" (change)="roomsQuantityCheckboxChanges(roomQ)"
            [label]="roomQ.label" [ngModel]="roomQ.selected">
          </app-checkbox-input>
        </div>
      </div>

      <!-- room types-->
      <div class="filter-section" *ngIf="projectInteriorSelected && !projectExteriorSelected">
        <div class="section-title" (click)="toggleSectionWithRoomTypes($event)">
          Rooms Type
          <img [src]="
              roomTypeExpanded
                ? 'assets/icons/angle-up-solid.svg'
                : 'assets/icons/angle-down-solid.svg'
            " class="img" />
        </div>
        <div class="content-types" #roomsTypesSection>
          <app-checkbox-input *ngFor="let room of roomTypes" (change)="roomTypeCheckboxChanged(room)"
            [label]="room.label" [ngModel]="room.selected" class="control control-checkbox">
          </app-checkbox-input>
        </div>
      </div>
      <!-- exterior painting types-->
      <div class="filter-section" *ngIf="!projectInteriorSelected && projectExteriorSelected">
        <div class="section-title" (click)="toggleSectionWithExteriorJobs($event)">
          Job Type
          <img [src]="
              jobTypeExpanded
                ? 'assets/icons/angle-up-solid.svg'
                : 'assets/icons/angle-down-solid.svg'
            " class="img" />
        </div>
        <div class="content-types" #exteriorTypesSection>
          <app-checkbox-input *ngFor="let job of exteriorJobs" (change)="exteriorJobTypeChanged(job)"
            [label]="job.label" [ngModel]="job.selected" class="control control-checkbox">
          </app-checkbox-input>
        </div>
      </div>
    </div>
    <button (click)="filter(true)" class="custom-button">Filter</button>
  </div>
</div>

<app-modal class="project_report_mdl dlt_prfl_mdl prjctdlt_modl position-relative" [displayModal]="modalusrdetail"
  >

  <slot-content>
    <span class="cross_hide" ></span>
    <form class="form" [formGroup]="profileForm">

      <div class="bid_detail_modal usr_dtl_mdl_wrp">
        <div class="bid-card-wrapper">
          <p class="title exchng_clr">Enter Details</p>
          <!-- <p>
                    Are you sure you want to arcive this project? It will be vanished from the list
                </p> -->
          <div class="usr_dtls_wrp">
            <div class="dtls_flx">
              <div class="cstm_inputs">
                <label class="inp_label">Name</label>
                <div class="input-wrapper">
                  <input class="n_cstm" formControlName="name" placeholder="Enter name">
                  <div class="error-label"
                    *ngIf="!profileForm.controls['name'].valid && (profileForm.controls['name'].touched)">
                    <p class="error-label" *ngIf="profileForm.controls['name'].hasError('required')">
                      Please enter name
                    </p>
                  </div>
                  <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                </div>
              </div>
              <div class="cstm_inputs">
                <label class="inp_label">Phone</label>
                <div class="input-wrapper">
                  <input formControlName="phone" maxLength="15"
                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder="(123)-456-7890">
                  <div class="error-label"
                    *ngIf="!profileForm.controls['phone'].valid && (profileForm.controls['phone'].touched)">
                    <p class="error-label" *ngIf="profileForm.controls['phone'].hasError('required')">
                      Please enter valid phone number
                    </p>
                  </div>
                  <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                </div>
              </div>
            </div>
            <div class="">

              <div class="cstm_inputs mb_20">
                <label class="inp_label">Street Address</label>
                <div class="input-wrapper">
                  <input formControlName="address" placeholder="4958 Ethels Lane">
                  <div class="error-label"
                    *ngIf="!profileForm.controls['address'].valid && (profileForm.controls['address'].touched)">
                    <p class="error-label" *ngIf="profileForm.controls['address'].hasError('required')">
                      Please enter address
                    </p>
                  </div>
                  <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                </div>
              </div>
              <div class="cstm_inputs mb_20">
                <label class="inp_label">Zip code</label>
                <div class="input-wrapper">
                  <input formControlName="zipcode" placeholder="33602" maxlength="6" minlength="3"
                    (keyup)="checkzip($event)"
                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                  <div class="error-label"
                    *ngIf="!profileForm.controls['zipcode'].valid && (profileForm.controls['zipcode'].touched)">
                    <p class="error-label" *ngIf="profileForm.controls['zipcode'].hasError('required')">
                      Please enter zip code
                    </p>
                  </div>
                  <div class="error-label" *ngIf="errorpin">
                    <p class="error-label" *ngIf="errorpin">
                      {{errorpin}}
                    </p>
                  </div>
                  <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                </div>
              </div>
              <div class="cstm_inputs mb_20">
                <label class="inp_label">City</label>
                <div class="input-wrapper">
                  <input formControlName="city" placeholder="City" readonly
                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                  <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                </div>
              </div>
              <div class="cstm_inputs mb_20">
 
                <!-- <label class="inp_label">State</label> -->

                <label class="inp_label">State</label>

                <div class="input-wrapper">
                  <input formControlName="state" placeholder="State" readonly
                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                  <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex dbl_btns">
            <button class="custom-button" (click)="changeprofile()">Save</button>

          </div>
        </div>
      </div>
    </form>
  </slot-content>


</app-modal>