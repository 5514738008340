  <div class="select-box" [ngClass]="{'select-box-aboslute': absolute}" (mouseleave)="onMouseLeave()">
    <div class="options-container" #optionsContainer [ngClass]="{'absolute-container': absolute}">
      <input *ngIf="ownValueText != null && ownValueText != '' && ownValueType =='number'" placeholder="Type..." type="number" style="height: 40px" (input)="ownValueChange($event)">
      <input *ngIf="ownValueText != null && ownValueText != '' && ownValueType =='text'"  placeholder="Type..." type="text" style="height: 40px" (input)="ownValueChange($event)">
        <div class="option" *ngFor="let option of inputOptions" [ngClass]="{'selected-position': option.optionSelected}" (click)="optionSelected(option)">
          <input  type="radio" class="radio" [id]="'opt-' + option.optionLabel" name="category"  >
          <label [for]="'opt-'+option.optionLabel">{{option.optionLabel}}</label>
        </div>
    </div>
    <div class="selected" (click)="toggleInput($event)">
      {{label}}
    </div>
  </div>

