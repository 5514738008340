<!-- <div class="message" *ngIf="message"> -->
<div class="message painter_chat">
  <!--<div [ngClass]="isItMyMessage ? 'message-header right' : 'message-header left'" class="message-header">-->
  <div class="message-header">
    <div class="avatar-wrapper"><img src ="./assets/Ellipse1.png" ></div>
    <div class="margin_top">
      <div class="info">
        <div class="full-name">Wilson Stanton</div>
        <div class="time">03:00 PM</div>
      </div>
      <p class="info">
        Sagittis, massa diam vitae lectus vitae morbi nulla nisi. Eleifend pellentesque amet, tempus phasellus risus purus at. 
        Sagittis nunc accumsan, blandit velit et commodo nam nullam. Sed fames pulvinar egestas a tristique non a.
      </p>
    </div>
    
  </div>
  
  <!--<div [ngClass]="isItMyMessage ? 'message-content right' : 'message-content left'">-->
  <!-- <div class="message-content" >
    <pre *ngIf="message.messageType === EMessageType.TEXT" [innerHTML]="messageTextContent"></pre>
    <img *ngIf="message.messageType == EMessageType.IMAGE" class="message-image" [src]="'data:image/png;base64,'+message.file" (click)="downloadFile()" alt="image">
    <div *ngIf="message.messageType == EMessageType.FILE" class="file-wrapper" (click)="downloadFile()">
      <img [src]="fileImage" alt="image">
        <div class="file-name">{{message.fileName}}
      </div>
    </div>
  </div> -->

  <!-- <div class="message-content" >
    <pre>fgghh</pre>
    <img class="message-image" src ="./assets/Ellipse1.png" (click)="downloadFile()" alt="image">
    <div class="file-wrapper" (click)="downloadFile()">
      <img [src]="fileImage" alt="image">
        <div class="file-name">ggjghj
      </div>
    </div>
  </div> -->


</div>
<div class="message painter_chat">
  <div class="message-header">
    <div class="avatar-wrapper"><img src ="./assets/Ellipse4.png" ></div>
    <div class="margin_top">
      <div class="info">
        <div class="full-name">Wilson Dias</div>
        <div class="time">03:00 PM</div>
      </div>
      <p class="info">
        Volutpat turpis integer consectetur gravida. Aliquet donec malesuada vitae consequat volutpat.
      </p>
    </div>
    
  </div>
</div>

