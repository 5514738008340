import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { CheckboxInputComponent } from 'src/app/components/input/checkbox-input/checkbox-input.component';
import {AnimationOptions} from 'ngx-lottie';
import {AnimationItem} from 'lottie-web';
import {AuthService} from '../../services/auth.service';
import {FormBuilder, Validators,FormGroup} from '@angular/forms';
    import { ToastrService } from 'ngx-toastr';
        import {SocialAuthService, SocialUser, GoogleLoginProvider} from  "angularx-social-login"

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi: true
    }
  ]
})
export class ContactUsComponent implements OnInit {
user !: SocialUser
  contactUsForm: FormGroup;

  @Input() checked = false;
  @Input() label!: string;
  @Input() index!: string;
  options: AnimationOptions = {
    path: './assets/illustrations/landing-page/chatbot-anim.json'
  };
 

  onChange: any = () => {};
  onTouch: any = () => {};

  check: boolean=false
data: any ={}
  constructor(  private authService: AuthService,private formBuilder: FormBuilder,private toastr: ToastrService) { 
   this.contactUsForm = this.formBuilder.group({
     fname:['',Validators.required],
     // comment:['',Validators.required],
      email:['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      phone_no:['',Validators.required, Validators.minLength(6), Validators.maxLength(11)],
      subject:['',Validators.required],
      enquiry: ['',Validators.required]
    
   // name: ['', Validators.required],
  });}

  ngOnInit(): void {
  this.getprofile();
  }


  onModelChange(e: any): void {
    this.checked = e;
    this.onChange(e);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: any): void {
    this.checked = checked;
  }
  change(e: any){
        //console.log(e, this.check)
        if(e.target.checked==false){
            this.check= false
        }else{
            this.check=true
        }
  }

  submit(){
  console.log(this.contactUsForm, this.check)
         if(this.contactUsForm.invalid){
    this.contactUsForm.markAllAsTouched()
    return;
  }
  if(this.check==false){
           this.toastr.error('Accept terms and condtions', 'Error!');
return;
  }
  var obj={
    name:this.contactUsForm.controls.fname.value,
    email:this.contactUsForm.controls.email.value,
    subject:this.contactUsForm.controls.subject.value,
    phone_no:this.contactUsForm.controls.phone_no.value,
    comment:this.contactUsForm.controls.enquiry.value
  }

  this.authService.contactUs(obj).subscribe(data => {
         this.toastr.success('Sent enquiry successfully', 'Success!');

    this.contactUsForm.reset()
    // this.router.navigate(["/contents/contact-us-feedback"])
  //  this.isFormSubmitted = true
    
  }, err => {
    console.log(err);
    
    this.authService.showMessage({
      message: err.error.errors ? err.error.errors.msg : 'Something went Wrong'
    })
  })
  }
  
   getprofile(){
   let obj={}
		 this.authService.getCMSdata('contact_us').subscribe((response : any) => {
         this.data= response.response
         
        // this.previewImage2 = this.path + this.profile.profile_image
      //     this.selectedFile2 =  this.profile.profile_image
        // console.log(this.profileForm)
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }
}
