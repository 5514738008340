import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {NavigationExtras, Router} from '@angular/router';
import {EConfirmationType} from '../../model/enums/EConfirmationType';
import {ERole} from '../../model/enums/ERole';
import {BaseUserInputDataComponent} from '../../components/MAIN_COMPONENTS/BaseUserInputDataComponent';
 import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent extends BaseUserInputDataComponent implements OnInit {
  userDataForm!: FormGroup;
  showerror: boolean = false
  terms: boolean = false
  constructor(private formBuilder: FormBuilder,private afAuth: AngularFireAuth,
              private authService: AuthService,
              private router: Router) {
    super();
    const navigation = this.router.getCurrentNavigation()!;
    if (navigation.extractedUrl.queryParams.accountTypePainter) {
      this.prepareForm(true);
    } else {
      this.prepareForm(false);
    }
  }

  isPainterRegistration = false;

  ngOnInit(): void {
  if(localStorage.getItem('role')){
  let a =localStorage.getItem('role')
  if(a=='painter'){
  this.isPainterRegistration = true
    this.prepareForm(true);
  }else{
          this.prepareForm(false);
  }
        
  }
  if(localStorage.getItem('email')){
        this.userDataForm.patchValue({
            'emailAddress': localStorage.getItem('email'),
            'password': localStorage.getItem('password')
        })
  }
   if(localStorage.getItem('phone')){
        this.userDataForm.patchValue({
            'phoneNumber': localStorage.getItem('phone'),
         //   'password': localStorage.getItem('password')
        })
  }
  }

  prepareForm(painterRegistration: boolean): void {
    if (painterRegistration) {
      this.isPainterRegistration = true;
      this.userDataForm = this.formBuilder.group({
        emailAddress: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required, Validators.minLength(9)]], //, Validators.pattern(this.phoneNumberPattern)
        password: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]],
        accepted: ['']
      });
    } else {
      this.isPainterRegistration = false;
      this.userDataForm = this.formBuilder.group({
        emailAddress: ['', [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]],
        accepted: ['']
      });
    }
  }

  registerPainter(email: string, phoneNumber: string, password: string, navigationExtras: NavigationExtras): void {
     this.router.navigate(['login/WelcomeOne'], navigationExtras);
     localStorage.setItem('email',this.emailAddress.value)
          localStorage.setItem('password',this.password.value)
 localStorage.setItem('phone',this.phoneNumber.value)
  }

  registerCustomer(email: string, password: string, navigationExtras: NavigationExtras): void {
     this.router.navigate(['login/WelcomeOne'], navigationExtras);
     localStorage.setItem('email',this.emailAddress.value)
          localStorage.setItem('password',this.password.value)
        localStorage.setItem('role','customer')
  
    
  }

  submitRegisterForm(): void {
  console.log(this.userDataForm)
  if(this.userDataForm.invalid){
        return;
  } 
  console.log(this.terms)
  if(!this.terms){
  this.showerror = true
   return;
  }else{

  
  this.showerror = false
    let navigationExtras: NavigationExtras;
    if (this.isPainterRegistration) {
      const formattedPhoneNumber =  this.phoneNumber.value.replaceAll('-', '');
      navigationExtras = {
        queryParams: {
          confirmationType: EConfirmationType.PHONE,
          confirmationParam: formattedPhoneNumber
        }
      };
      this.registerPainter(this.emailAddress.value, formattedPhoneNumber, this.password.value, navigationExtras);
    } else {
      navigationExtras = {
        queryParams: {
          confirmationType: EConfirmationType.EMAIL,
          confirmationParam: this.emailAddress.value
        }
      };
      console.log(this.emailAddress.value);
      console.log(this.password.value);
      this.registerCustomer(this.emailAddress.value, this.password.value, navigationExtras);
    }
    }
  }

  onEnterClicked(): void {
    if (this.userDataForm.valid) {
      this.submitRegisterForm();
    }
  }

    async signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    if(localStorage.getItem('role')=="painter"){    
      try {
        const result: any = await this.afAuth.signInWithPopup(provider);
        if (result) {
          console.log(result, "ddd");
          let credential = result.credential;
          let user = result.user;
          let profile = result.additionalUserInfo.profile
          let token = credential.accessToken;
          let idToken = credential.idToken;
          let multiFactorUser = user.multiFactor;
          let userObj = {
            idToken: idToken,
            accessToken: token,
            email: profile.email,
            //  email:profile.email,
            social_id: user.uid,
            social_type: "google",
            first_name: profile.given_name,
            last_name: profile.family_name,
            allowed_type: "",
            role: 'painter',
          };
          console.log(userObj, "userobj");
          if (sessionStorage["allowedType"]) {
            userObj.allowed_type = sessionStorage["allowedType"];
          }
          this.authService.Sociallogin(userObj).subscribe((res: any) => {
            localStorage['token'] = res.token
            // localStorage['userData'] = JSON.stringify(res.user)
            // localStorage['remember_me'] = "yes"
            console.log("response@@@", res);
            localStorage.setItem("trylogResonlogin", JSON.stringify(res))
            sessionStorage.setItem("user_details", JSON.stringify(res.response.user))
             sessionStorage.setItem("role", res.response.user.role)

              sessionStorage.setItem("token", res.response.token)
              localStorage.setItem('token', res.response.token)
              localStorage.setItem("remember_me", "no")
                localStorage.setItem("role", res.response.user.role)
                localStorage.setItem('loginstatus', res.status)
                localStorage.setItem('loginbysocial', 'true')

                if(res.response.user.role=='customer'){
            this.router.navigate(['/home/my-project-card'])
            }else{
            this.router.navigate(['/home/marketplace'])
            }

          });
        }
      }catch (error) {
        console.log(error)
        }
    }else{
         try {
      const result: any = await this.afAuth.signInWithPopup(provider);
      if (result) {
          console.log(result, "ddd");
          let credential = result.credential;
          let user = result.user;
          let profile = result.additionalUserInfo.profile
          let token = credential.accessToken;
          let idToken = credential.idToken;
          let multiFactorUser = user.multiFactor;
          let userObj = {
            idToken: idToken,
            accessToken: token,
            email: profile.email,
            //  email:profile.email,
            social_id: user.uid,
            social_type: "google",
            first_name: profile.given_name,
            last_name: profile.family_name,
            allowed_type: "",
            role: 'customer',
          };
          console.log(userObj, "userobj");
          if (sessionStorage["allowedType"]) {
            userObj.allowed_type = sessionStorage["allowedType"];
          }
          this.authService.Sociallogin(userObj).subscribe((res: any) => {
            localStorage['token'] = res.response.token
            // localStorage['userData'] = JSON.stringify(res.user)
            // localStorage['remember_me'] = "yes"
            console.log("response", res);
            localStorage.setItem("trylogResonlogin", JSON.stringify(res))
            sessionStorage.setItem("user_details", JSON.stringify(res.response.user))
            sessionStorage.setItem("role", res.response.user.role)
            sessionStorage.setItem("token", res.response.token)
            localStorage.setItem('token', res.response.token)
            localStorage.setItem("remember_me", "no")
            localStorage.setItem("role", res.response.user.role)
            localStorage.setItem('loginstatus', res.status)
            localStorage.setItem('loginbysocial', 'true')

             if(res.response.user.role=='customer'){
         this.router.navigate(['/home/my-project-card'])
        }else{
         this.router.navigate(['/home/marketplace'])
        }

          });
        }
    } catch (error) {
      console.log(error)
    }
    }
  }
  
  async signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider();
    try {
      const result: any = await this.afAuth.signInWithPopup(provider);
      console.log('JGHHG',result)
    } catch (error) {
      console.log("gg",error)
    }
  }
}

