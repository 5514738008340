<div class="main-box" *ngIf="!isFormSubmitted">

  <p class="hint margin-b15">Reset password</p>
  <form [formGroup]="userDataForm" class="form margin-b10">
    <div class="input-wrapper margin-b15">
      <label><span class="label-text">Password</span></label>
      <span class="icon-wrapper">
        <img [src]="validatePassword() ? '/assets/icons/errors/error-solid.svg' : '/assets/icons/lock-solid.svg'"
          class="icon">
      </span>
      <input formControlName="password" class="email" placeholder="Enter password" type="password">
      <div class="error-wrp" *ngIf="validatePassword()">
        <img src="assets/icons/errors/error-solid.svg" class="error-icon">
        <div class="error-text" *ngIf="checkPasswordForErrors()">{{passwordErrorMessage}}</div>
      </div>
    </div>

    <div class="input-wrapper margin-b25">
      <label>Confirm password</label>
      <span class="icon-wrapper">
        <img [src]="validateConfirmPassword() ? '/assets/icons/errors/error-solid.svg' : '/assets/icons/lock-solid.svg'"
          class="icon">
      </span>
      <input formControlName="confirmPassword" placeholder="Confirm password" type="password">
      <div class="error-wrp" *ngIf="validateConfirmPassword()">
        <img src="assets/icons/errors/error-solid.svg" class="error-icon">
        <div class="error-text" *ngIf="checkConfirmPasswordForErrors()">{{confirmPasswordErrorMessage}}</div>
      </div>
    </div>
  </form>

  <div class="error-404" *ngIf="error404">
    <div class="error-text">
      <img src="assets/icons/errors/error-solid.svg" class="error-icon">
      {{tokenNotFoundMessage}}
    </div>
  </div>
  <button (click)="submitForm()" [disabled]="!userDataForm.valid || buttonDisabled4ever" class="custom-button">Reset
    password
  </button>
</div>

<div class="main-box" *ngIf="isFormSubmitted">
  <div class="title margin-b10">Awesome!</div>
  <img src="assets/illustrations/register-confirmation/special.svg" class="img margin-b10">
  <div class="claim margin-b10">Your password has been changed.</div>
  <div class="subclaim margin-b10">You can now login using your credentials and start your journey with brush!</div>
  <button (click)="goBackToLogin()" class="custom-button">Login</button>
</div>