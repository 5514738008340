<select (change)="emit($event)">
  <option value="">-</option>
  <option value="IN_DRAFT">In draft</option>
  <option value="AWAITING">Awaiting</option>
  <option value="PENDING">Pending</option>
  <option value="IN_PROGRESS">In progress</option>
  <option value="COMPLETED">Completed</option>
  <option value="COMPLAIN">Complain</option>

</select>
