<div class="rooms-wrapper">
  <div class="room-creator-wrapper" *ngIf="roomCreatorVisible" #roomCreatorWrapper>
    <div class="form-wrapper">
    <div class="current-page-header">
      <span>{{currentPage.title}}</span><img src="assets/project-creator/cancel-creating-room.png" alt="cancel"
                                             (click)="cancelCreatingRoom()"></div>
    <div *ngIf="displayingPages" class="room-creator-section">
      <div *ngIf="currentPage.creatorType === ERoomCreatorInputType.IMAGES" class="images-wrapper">
        <div class="image-wrapper" *ngFor="let item of currentPage.items">
          <div class="image-sub-wrapper">
            <img [src]="item.image">
          </div>
          <div [ngClass]="item.selected ? 'selected image-label' : 'image-label'" class="image-label" (click)="select(currentPage, item)">{{item.label}}</div>
        </div>
      </div>
      <div *ngIf="currentPage.creatorType === ERoomCreatorInputType.LIST" class="list-wrapper">
        <app-simple-drop-down-component [placeHolderOrValue]="getPlaceholderValueForList()" [items]="currentPage.items"
                                        (valueChanged)="select(currentPage, $event)"></app-simple-drop-down-component>
      </div>
      <div *ngIf="currentPage.creatorType === ERoomCreatorInputType.BOXES_WITH_IMAGES" class="images-wrapper">
        <div [ngClass]="item.selected ? 'selected box-with-image' : 'box-with-image'" *ngFor="let item of currentPage.items" (click)="select(currentPage, item)">
          <img [src]="item.image">
          <div class="box-with-image-label">{{item.label}}</div>
          <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
        </div>
      </div>
           <div *ngIf="currentPage.creatorType === ERoomCreatorInputType.SELECT" class="images-wrapper">
             <div class="select_room_no">
              <select  class="select-input"
              [(ngModel)]="room"
                class="select-input"  (change)="sslect(currentPage, room)">
                <option   value="" disabled>Select</option>
                <option  *ngFor="let item of currentPage.items" [value]="item.value"> {{item.label}}</option>
              </select>
            </div>
        </div>
      <div *ngIf="currentPage.creatorType === ERoomCreatorInputType.BOXES" class="images-wrapper">
        <div [ngClass]="item.selected ? 'selected box-without-image' : 'box-without-image'" *ngFor="let item of currentPage.items" (click)="select(currentPage, item)">
          <div class="box-without-image-label">{{item.label}}</div>
          <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
        </div>
      </div>
      <div *ngIf="currentPage.creatorType === ERoomCreatorInputType.IMAGES_MULTIPLE" class="images-wrapper">
        <div [ngClass]="item.selected ? 'selected image-wrapper' : 'image-wrapper'" *ngFor="let item of currentPage.items">
          <div class="image-sub-wrapper">
            <img [src]="item.image">
          </div>
          <div [ngClass]="item.selected ? 'selected image-label' : 'image-label'"  class="image-label"
               (click)="select(currentPage, item)">{{item.label}}</div>
        </div>
      </div>
      <footer>
        <button class="custom-button back-button" *ngIf="backButtonVisible" (click)="previousPage()">Back</button>
        <button class="custom-button next-button" *ngIf="nextButtonVisible" [disabled]="nextButtonDisabled" (click)="nextPage()">Next</button>
      </footer>
    </div>
    <div *ngIf="wallCreatorVisible" class="room-creator-section">
      <app-wall-card [editMode]="true" [disableForm]="false" *ngIf="walls != null && walls.length < 4" (wallCreated)="addWall($event)" [exteriorCreator]="false"></app-wall-card>
      <app-wall-card [exteriorCreator]="false" [editMode]="false" [disableForm]="this.indexEdited == null" [wallName]="'Wall ' + (i+1)" [wall]="wall" (wallCreated)="addWall($event)" [duplicateButtonVisible]="walls.length < 4" *ngFor="let wall of walls; let i=index; trackBy: trackByFn"
                     (copyWall)="addWall(wall)"
                     (wallEdited)="wallEdited($event, i)"
                     (deleteWall)="deleteWall(i)"></app-wall-card>
      <footer>
        <button class="custom-button back-button" *ngIf="backButtonVisible" (click)="previousPage()">Back</button>
        <button class="custom-button next-button" [disabled]="walls.length != 4" (click)="modifyCeiling()">Next</button>
      </footer>
    </div>
    <div *ngIf="ceilingCreatorVisible" [formGroup]="ceilingFormGroup" class="room-creator-section">
      <div class="dimensions">
        <input type="number" formControlName="ceilingHeight" placeholder="Height" [attr.disabled]="true">
        <span>x</span>
        <input type="number" formControlName="ceilingWidth" placeholder="Width" [attr.disabled]="true">
      </div>
      <div class="colors">
        <div class="color-wrapper">
          <div class="color-title">Current Color</div>
          <div class="color-input" [ngStyle]="{'background': ceilingFormGroup.controls['ceilingCurrentColor'].value}">
            <input type="color"  formControlName="ceilingCurrentColor">
          </div>
        </div>
        <div class="color-wrapper">
          <div class="color-title">New Color</div>
          <div class="color-input" [ngStyle]="{'background': ceilingFormGroup.controls['ceilingNewColor'].value}">
            <input type="color"  formControlName="ceilingNewColor">
          </div>
        </div>
      </div>
      <footer>
        <button class="custom-button back-button" *ngIf="backButtonVisible" (click)="modifyWalls()">Back</button>
        <button class="custom-button next-button" [disabled]="!ceilingFormGroup.valid" (click)="addRoom()">Done</button>
      </footer>
    </div>
  </div>
  </div>
  <div class="custom-button" (click)="openRoomCreator()">Add Room</div>
  <div class="no-rooms-added" *ngIf="rooms == null || rooms.length == 0">
    You haven’t added any rooms yet. Add a room to continue.
  </div>
  <div class="room-card-wrapper" *ngFor="let room of rooms; let i=index">
    <app-room-card [room]="room" (copy)="copyRoom(i)" (deleteRoom)="deleteRoom(i)" (edit)="editRoom(i)"></app-room-card>
  </div>
  <button class="custom-button next-page-button" [disabled]="rooms.length == 0" (click)="saveRoomsAndGoNextPage()">Next</button>
</div>
