import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ImageFile} from '../../../model/ImageFile';
import {BaseUploadFileComponent} from '../../MAIN_COMPONENTS/base-upload-file.component';


@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent extends BaseUploadFileComponent {
  @ViewChild('container', {static: false}) fileDrop!: ElementRef;
  @ViewChild('fileDropRef', {static: false}) fileDropEl!: ElementRef;
  @ViewChild('gallery') gallery!: ElementRef;
  @ViewChild('galleryOverflowScroll') galleryOverflowScroll!: ElementRef;
  intervals = new Array();
  swipeSpeed = 6; // 1 is super speed
  @Input() multiple = true;
  @Output() whenFilesChange: EventEmitter<any[]> = new EventEmitter();
  files: ImageFile[] = [];

  constructor(private cdk: ChangeDetectorRef) {
    super();
  }

  /* drop event */
  onFileDropped(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.processData(event.dataTransfer.files);
  }
  /* browsing event */
  fileBrowseHandler(event: any): void {
    this.processData(event.target.files);
  }

  /* delete file */
  deleteFile(index: number): void {
    this.files.splice(index, 1);
    this.whenFilesChange.emit(this.files);
    this.cdk.detectChanges();
  }

  private processData(actualFiles: any): void {
    for (let i = 0; i < actualFiles.length; i++) {
      if (this.checkForExtensions(actualFiles[i].name)) {
        return;
      }
      const imageFile = this.processFile(actualFiles[i].name, actualFiles[i].size, actualFiles[i]);
      this.prepareFilesList(imageFile);
    }
  }

  /* checks if file has proper extension */
  private checkForExtensions(name: string): boolean {
    const fileNameSplit = name.split('.');
    const possibleImagesExtension = ['jpg', 'jpeg', 'png', 'gif'];
    return !possibleImagesExtension.includes(fileNameSplit[fileNameSplit.length - 1]);
  }
  private prepareFilesList(file: ImageFile): void {
    //this.simulateUploadProcess(file);
    this.files.push(file);
    this.fileDropEl.nativeElement.value = '';
    this.whenFilesChange.emit(this.files);
  }
  // private simulateUploadProcess(file: ImageFile): void {
  //   setTimeout(() => {
  //     const progressInterval = setInterval(() => {
  //       if (file.progress === 100) {
  //         clearInterval(progressInterval);
  //       } else {
  //         file.progress += 1;
  //       }
  //     }, 20);
  //   }, 1000);
  //
  // }

  isGalleryOverflown(): boolean {
    const element = document.getElementById('uploadedImages') as HTMLElement;
    return element?.scrollHeight > element?.clientHeight || element?.scrollWidth > element?.clientWidth;
  }

  swipeRight(): void {
    this.galleryOverflowScroll.nativeElement.scrollBy(10, 0);
  }

  swipeLeft(): void {
    this.galleryOverflowScroll.nativeElement.scrollBy(-10, 0);
  }

  mouseDownSwipeLeft(): void {
    const interval = setInterval(() => {
      this.swipeLeft();
    }, this.swipeSpeed);

    this.intervals.push(interval);
  }

  mouseDownSwipeRight(): void {
    const interval = setInterval(() => {
      this.swipeRight();
    }, this.swipeSpeed);

    this.intervals.push(interval);
  }

  clearInterval(): void {
    for (const interval of this.intervals) {
      window.clearInterval(interval);
    }
  }
}
