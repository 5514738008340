<div class="smooth-scroll">
  <app-header [userTheme]="true"></app-header>

  <section id="section-one" class="landing_sec_first">
    <div class="section-one-text">
      <p class="label">Looking for a painter?</p>
      <div class="heading">Are you tired of the traditional call and schedule for an estimate approach when looking for
        a painter? Never know what you should pay?
      </div>
      <!-- <div class="head">Find and hire the best painters for your project - online.</div> -->

      <div class="heading"> <strong class="brush-exchange-text">BrushExchange</strong> was created to help you hire
        the
        right painter for your project and pay what is right - fair and square.
      </div>

      <button class="custom-button show-me-how-button customer-theme-btn" (click)="scrollToShowHow()">Show me
        how</button>
    </div>
    <!-- <img src="assets/illustrations/landing-page/seo.svg" class="hero-img" alt="hero-img"> -->
    <img src="assets/hdr.svg" class="hero-img" alt="hero-img">
  </section>

  <div class="cstmr-stps">
    <div class="how-does-it-work" id="section-two-index">How does it work?</div>
    <section id="section-two" class="customer-steps">
      <div class="outer-flex">
        <div class="left-flex">
          <div class="item">
            <img src="./assets/pay-for-quality.svg" class="left-img" alt="'idea">


          </div>
          <div class="item">
            <p class="label-xl">Step#2</p>
            <p class="head">Post your <span class="non-breakable-span">project ></span></p>
            <p class="description">Post your project to our marketplace and wait for bids from our
              <strong>painters</strong>!
            </p>
          </div>
          <div class="item">
            <img src="./assets/brushes-gold-M.svg" class="left-img hgt-2 customer_img3" alt="meeting">
          </div>
        </div>
      </div>
      <div class="center-flex">
        <div class="timeline"></div>
        <div class="timeline-item">
          <div class="undr-tymlyn">

          </div>
        </div>
        <div class="timeline-item">
          <div class="undr-tymlyn">

          </div>
        </div>
        <div class="timeline-item last-step">success</div>
      </div>
      <div class="outer-flex">
        <div class="right-flex">
          <div class="item">
            <p class="label-xl">Step#1</p>
            <p class="head"><span class="non-breakable-span">> Create</span> project</p>
            <p class="description">Enter your project details and receive free fair price estimate!</p>
          </div>
          <div class="item mb-5 ">
            <!-- <img src="assets/illustrations/landing-page/plan.svg" class="right-img" alt="plan"> -->
            <img src="./assets/image-6-painter-pc-M-no-info.svg" class="right-img" alt="plan">

          </div>
          <div class="item item5">
            <p class="label-xl">Step#3</p>
            <p class="head">Select the <span class="non-breakable-span">painter ></span></p>
            <p class="description">Chat and connect with your selected painter</p>
          </div>
        </div>
      </div>
    </section>
  </div>

  <!-- new-section-how-work -->


  <!-- <section class="how_works_sec">
    <div class="how_wrok">
      <div class="how-does-it-work" id="section-two-index">How does it work?</div>

      <p class="heading_pr light_txt">
        Lorem ipsum dolor sit amet consectetur.
      </p> 
      <div class="row">
        <div class="col-md-4">
          <nb-card>
            <nb-card-body>
              <img src="assets/illustrations/landing-page/idea.svg" alt="'idea">
              <h2>
                Create project
              </h2>
              <p class="light_txt">
                Enter your project details and receive free fair price estimate!
              </p>
            </nb-card-body>
            <span>1</span>
          </nb-card>
        </div>
        <div class="col-md-4">
          <nb-card>
            <nb-card-body>
              <img src="assets/illustrations/landing-page/plan.svg" alt="'idea">
              <h2>
                Post your ad
              </h2>
              <p class="light_txt">
                Post your project to our marketplace and wait for bids from our painters!
              </p>
            </nb-card-body>
            <span>2</span>
          </nb-card>
        </div>
        <div class="col-md-4">
          <nb-card>
            <nb-card-body>
              <img src="assets/illustrations/landing-page/meeting.svg" alt="'idea">
              <h2>
                Choose best bid
              </h2>
              <p class="light_txt">
                Make an appointment with your selected painter and schedule your project!
              </p>
            </nb-card-body>
            <span>3</span>
          </nb-card>
        </div>
      </div>
    </div>
  </section> -->

  <!-- end-new-section-how-work -->

  <section id="section-three">
    <div class="box" [ngClass]="{'painter-dots-bg': !customerTheme, 'customer-dots-bg' : customerTheme }">
      <div class="switch margin-b5">
        <div class="customer-switch">
          <p class="title" (click)="changeTheme()" [class.inactive-title]="!customerTheme">customers</p>
          <div class="painter-theme-bckg" *ngIf="customerTheme"></div>
        </div>
        <div class="section-three-header-divider"></div>
        <div class="painter-switch">
          <p class="title" (click)="changeTheme()" [class.inactive-title]="customerTheme"><strong>painters</strong></p>
          <div class="customer-theme-bckg" *ngIf="!customerTheme"></div>
        </div>
      </div>
      <div *ngIf="customerTheme" class="content">
        <p class="description mb-3">Create your project along with a free estimate from Brush Exchange and receive
          tailored
          bids from painters - all at the fingertips of our platform.
        </p>
        <button class="btn-xl painter-theme-btn" [routerLink]="'/register'">Hire a painter</button>
      </div>
      <div *ngIf="!customerTheme" class="content">
        <p class="description mb-3">Bid on projects for $1 or less - you only pay when you are ready to bid for a
          project.
          Aside from this, joining Brush Exchange doesn’t cost you anything!</p>
        <button class="btn-xl customer-theme-btn no-wrap" [routerLink]="'/host'">Earn money with Brush Exchange</button>
      </div>
    </div>
  </section>

  <section id="section-four" class="ai_section painter_side_ai" *ngIf="customerTheme">
    <div  class="section-four-content section-four-customer">
      <div class="anim-text-block">
        <p class="label customer-theme">Finding a painter has never been so easy before</p>
        <p class="head">Get free estimate for your project from our AI model</p>
        <p class="description margin-b20">Create a project, provide details - receive your free estimate and bids from
          painters</p>
        <button class="btn customer-theme-btn mt-2" [routerLink]="'/register'">POST PROJECT</button>
      </div>
      <div class="text-center">
        <img src="./assets/robot1.svg" alt="">
      </div>
      <!-- <ng-lottie [options]="options"></ng-lottie> -->
    </div>

    
  </section>
  <section id="section-four" class="ai_section painter_side_ai" *ngIf="!customerTheme">
    <div >
      <div class="section-four-content section-four-painter">
        <div class="anim-text-block">
          <p class="label painter-theme-2">Finding painting work has never been so easy before!
          </p>
          <p class="head">Elevate your business with <strong>BrushExchange</strong></p>
          <p class="description margin-b20">Find out how a partnership with us can help
            your buisness. Sign up, complete your profile and start
            earning money as a freelancer.</p>
          <button class="btn painter-theme-btn" [routerLink]="'/host'">BROWSE JOBS</button>
        </div>
  
        <div class="text-center pntr-tab-img">
          <img src="./assets/Image-4-painter-ladders-M.svg" alt="">
        </div>
        <!-- <ng-lottie [options]="options2"></ng-lottie> -->
  
      </div>
  
    </div>
  </section>
 

  <!-- <section id="section-five">
    <div>
      <div class="head customer-theme">What's great about <strong class="strong">BrushExchange</strong>?</div>
    </div>
    <div class="service-card-wrapper">
      <div class="services-wrapper">
        <app-service-card *ngFor="let service of customerServices" class="service-card" [img]="service.img"
          [label]="service.label" [title]="service.title" [description]="service.description"></app-service-card>
      </div>
      <div class="services-wrapper">
        <app-service-card *ngFor="let service of painterServices" class="service-card" [img]="service.img"
          [label]="service.label" [title]="service.title" [description]="service.description"></app-service-card>
      </div>
    </div>     
  </section> -->




  <!-- <div class="pntr-stps" *ngIf="!customerTheme">
    <div class="how-does-it-work" id="section-two-index">How does it work?</div>

    <section id="section-two" class="painter-steps" >

      <div class="outer-flex">
        <div class="left-flex">
          <div class="item">
            <img src="./assets/stpe1.png" class="left-img" alt="'idea">


          </div>
          <div class="item">
            <p class="label-xl">Step#2</p>
            <p class="head">Bid on projects you like ></p>
            <p class="description">Prepare a tailored offer using key information from the customer - it’ll cost you only $1!
            </p>
          </div>
          <div class="item">
            <img src="./assets/step3.png" class="left-img hgt-2 step3_img" alt="meeting">
          </div>
          <div class="item mt-80">
            <p class="label-xl">Step#4</p>
            <p class="head">BrushExchange Benefits ></p>
            <p class="description">See complete project details with pictures, measurements, colors, surface conditions
              and more. Improve the accuracy of your bids and chances of landing a job!
            </p>
          </div>
        </div>
      </div>
      <div class="center-flex">
        <div class="timeline"></div>
        <div class="timeline-item">
          <div class="undr-tymlyn">

          </div>
        </div>
        <div class="timeline-item timeline2">
          <div class="undr-tymlyn">

          </div>
        </div>
        <div class="timeline-item timeline3">
          <div class="undr-tymlyn">

          </div>
        </div>
        <div class="timeline-item last-step">success</div>
      </div>
      <div class="outer-flex">
        <div class="right-flex">
          <div class="item">
            <p class="label-xl">Step#1</p>
            <p class="head"><span class="non-breakable-span">> Create</span> project</p>
            <p class="description">Being part of Brush Exchange is totally free - no maintenance costs</p>
          </div>
          <div class="item mb-5 ">
            <img src="./assets/step2.png" class="right-img step2_img" alt="plan">

          </div>
          <div class="item">
            <p class="label-xl">Step#3</p>
            <p class="head"><span class="non-breakable-span">Start Earning <br>Money > </span></p>
            <p class="description">if your offer is accepted we facilitate contact with the customer !</p>
          </div>
          <div class="item mt-4 ">

            <img src="./assets/step4.png" class="right-img step4_img" alt="plan">

          </div>
        </div>
      </div>
    </section>
  </div> -->


  <div class="help-section mt-10 customer_benfts" *ngIf="customerTheme">
    <div class="sec-b">
      <div class="hlp-container">
        <div class="sec-b-hdng">What's great about BrushExchange ?</div>
        <div id="section-four" class="ai_section">
          <div  class="section-four-content section-four-customer">
            
            <div class="text-center">
              <img src="./assets/pay-for-quality.svg" alt="">
            </div>
            <div class="anim-text-block">
              <ul>
                <li>
                  <span>Hassle-free estimates</span> - Brush Exchange algorithm will give you an estimate for your project. No need for inconvenient visits from many painters. 
                </li>
                <li>
                  <span>Tailored bids from Painters</span> - Because you provide details on your projects, bids from painters should be more accurate. 
                </li>
                <li>
                  <span>Finding a painter made easy</span> - Creating a project, receiving bids and selecting a painter all on Brush Exchange. And, you pay what is right, fair and square !
                </li>
              </ul>
            </div>
            <!-- <ng-lottie [options]="options"></ng-lottie> -->
          </div>
      
          
        </div>
        <!-- <div class="row" >
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="hlp-card">
                  <img src="./assets/Image-2-redy-custumer-pc-M.svg" alt="" class="vu-icn-2">
                  <h4>Hassle-free estimates</h4>

                  <p class="light_txt">Brush Exchange algorithm will give you an estimate for your project. No need for
                    inconvenient visits from many painters. </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="hlp-card">
                  <img src="./assets/Image-2-redy-custumer-pc-M.svg" alt="" class="vu-icn-2">

                  <h4>Tailored bids from Painters </h4>

                  <p class="light_txt">Because you provide details on your projects, bids from painters should be more
                    accurate. </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="hlp-card">
                  <img src="./assets/Image-2-redy-custumer-pc-M.svg" alt="" class="vu-icn-2">
                  <h4>Finding a painter made easy</h4>
                  <p class="light_txt">Creating a project, receiving bids and selecting a painter all on Brush Exchange.
                    And, you pay what is right, fair and square!</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <div class="help-section mt-10 customer_benfts" *ngIf="!customerTheme">
    <div class="sec-b">
      <div class="hlp-container">
        <div class="sec-b-hdng painter-theme-2">What's great about BrushExchange ?</div>
        <div id="section-four" class="ai_section">
          <div  class="section-four-content section-four-customer">
            
            <div class="text-center">
              <img src="./assets/image-6-painter-pc-M-no-info.svg" alt="">
            </div>
            <div class="anim-text-block">
              <ul>
                <li>
                  
                  <span></span>Being a painter on Brush Exchange does not cost anything - no maintenance and subscription costs!
                </li>
                <li>
                  
                  <span></span>Pay only $1 for bids when you like a job you see on Brush Exchange marketplace. 
                </li>
                <li>
                  
                  <span></span>Because projects on Brush Exchange always include necessary information for providing quotes, your chances of landing a job increase. 
                </li>
                <li>
                  
                  <span></span>You don’t have to travel to potential job sites to provide estimates - you can do it from the comfort of the Brush Exchange platform. 
                </li>
              </ul>
            </div>
            <!-- <ng-lottie [options]="options"></ng-lottie> -->
          </div>
      
          
        </div>
        <!-- <div class="row" >
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="hlp-card">
                  <img src="./assets/Image-2-redy-custumer-pc-M.svg" alt="" class="vu-icn-2">
                  <h4>Hassle-free estimates</h4>

                  <p class="light_txt">Brush Exchange algorithm will give you an estimate for your project. No need for
                    inconvenient visits from many painters. </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="hlp-card">
                  <img src="./assets/Image-2-redy-custumer-pc-M.svg" alt="" class="vu-icn-2">

                  <h4>Tailored bids from Painters </h4>

                  <p class="light_txt">Because you provide details on your projects, bids from painters should be more
                    accurate. </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="card">
              <div class="card-body">
                <div class="hlp-card">
                  <img src="./assets/Image-2-redy-custumer-pc-M.svg" alt="" class="vu-icn-2">
                  <h4>Finding a painter made easy</h4>
                  <p class="light_txt">Creating a project, receiving bids and selecting a painter all on Brush Exchange.
                    And, you pay what is right, fair and square!</p>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  

  <!--Help centre-->
  <!--<div class="question-div">-->

  <!--<div class="box">-->
  <!--<div class="left-part">-->
  <!--<p class="head">Got questions?</p>-->
  <!--<span class="border"></span>-->
  <!--<p class="description-small">Customer support is our highest-->
  <!--priority. We're here to answer all your questions via our Support Docs, Video Demos, and 24/7 Live Chat.</p>-->
  <!--</div>-->

  <!--<div class="right-part">-->
  <!--<div class="card">-->
  <!--<img src="assets/illustrations/122-work-from-home-2.svg" class="img">-->
  <!--<a href="#" class="btn" style="background: #7B68EE;">Watch a Demo</a>-->
  <!--</div>-->
  <!--<div class="card">-->
  <!--<img src="assets/illustrations/109-map-location.svg" class="img">-->
  <!--<a href="#" class="btn" style="background: #fd71af;">Find solution</a>-->
  <!--</div>-->
  <!--<div class="card">-->
  <!--<img src="assets/illustrations/121-work-from-home-1.svg" class="img">-->
  <!--<a href="#" class="btn" style="background:  #ffc800;">Start Chat</a>-->
  <!--</div>-->
  <!--</div>-->

  <!--</div>-->
  <app-footer></app-footer>
</div>