import {AfterViewInit, Component, HostListener, Input, OnInit, OnChanges} from '@angular/core';
import {MessageService} from '../../services/message.service';
import {ShareDataService} from '../../services/data-sharing/share-data.service';
import {PageableResponse} from '../../model/PageableResponse';
import {NavigationEnd, Router} from '@angular/router';
import {EMessageStatus} from '../../model/enums/EMessageStatus';
import {Message} from '../../model/Message';
import { HttpserviceService } from '../../services/httpservice.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss']
})
export class NotificationBellComponent implements AfterViewInit {
  unreadMessagesCount!: number;
  notificationList!: PageableResponse;
  notificationListExpanded = false;
  count: any
   isPainter = false;
    isCustomer = false;
  @Input() hide = true;
  shownew: boolean=false;
  notificationsnew: any =[]
  notifications: any=0
    moment =moment;

  constructor(private router: Router, private messageService: MessageService, private shareDataService: ShareDataService, private authService: HttpserviceService) {
  }

  ngAfterViewInit(): void {
     if(localStorage.getItem('role')=='painter'){
             this.isPainter = true;
      }else if(localStorage.getItem('role')=='customer'){
             this.isCustomer = true;
      }
      console.log(this.isPainter, this.isCustomer)
        this.getnotifications()

    if(localStorage.getItem('noti')){
        this.notifications = localStorage.getItem('noti')
    }else{
    }
    console.log(this.notifications)
   
  }

  show(){
        this.shownew= !this.shownew
  }
    getnotifications(){
    let obj ={
        limit: 2,
        offset: 0
    }
    let param= '3&offset=0'
		  this.authService.getnotificationnew(param).subscribe((response: any) => {
          console.log('test',response)
          		  this.notificationsnew = response?.notifications
                    console.log(this.notificationsnew)
		  this.notifications = response?.count
                   localStorage.setItem('noti',response.notifications[0]?.unseen_notification)

  })
  }

  
 

  delete(id: any){
  let obj={
		'id': id
  }
    this.authService.deletenoti(obj).subscribe((response: any) => {
		//  this.notifications = response.notifications
        window.location.reload();
		this.getnotifications();
  })
  }

  unseennoti(id: any, type: any){
    let obj={
		'id': id
  }
    this.authService.seennoti(obj).subscribe((response: any) => {
	//	  this.notifications = response.notifications
          //  window.location.reload();

 this.authService.getnotification().subscribe((response: any) => {
		  this.notifications = response.notifications
         localStorage.setItem('noti',response.notifications[0]?.unseen_notification)
                  window.location.reload();

  })
  })
  }


    viewDemoDetailsscomp(id: any, type:any) {
    console.log('a')
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-jobs/details/'+id]);
  }

    viewDemoDetailss(id: any, type:any) {
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-jobs/completed-details/'+id]);
  }
}
