import {Component, Input, OnInit} from '@angular/core';
import {UserPainter} from '../../model/UserPainter';
import {UserCustomer} from '../../model/UserCustomer';
import {getRedirectLinkForProjectType} from '../../model/enums/EProjectType';
import {ReportService} from '../../services/report.service';
import {TokenStorageService} from '../../services/token-storage.service';
import {SimpleDropDownComponentItem} from '../../model/components/SimpleDropDownComponentItem';

@Component({
  selector: 'app-contact-card-mini',
  templateUrl: './contact-card-mini.component.html',
  styleUrls: ['./contact-card-mini.component.scss']
})
export class ContactCardMiniComponent implements OnInit {
  reportModalVisible = false;
  selectedReportReason!: any;

  reportReasons!: SimpleDropDownComponentItem [];

  @Input() user!: UserPainter | UserCustomer;
  @Input() isUserPainter!: boolean;
  @Input() detailedContactAvailable = false;
  @Input() totalCommentsForPainter!: any;
  @Input() reportPresent = false;

  constructor(private reportService: ReportService, public tokenStorage: TokenStorageService) {
  }

  ngOnInit(): void {
    this.reportReasons = [
      {
        label: 'Painter has not paid for project',
        visible: true
      }];
  }

  select(event: any): void {
    this.selectedReportReason = event.label;
  }

  sendReport(): void {
    this.reportService.postReport(this.selectedReportReason, 'home/profile/' + this.user.clientID, this.user.id).subscribe(res => {
      this.reportModalVisible = false;
      this.reportPresent = true;
    });
  }
}
