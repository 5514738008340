import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Bid} from '../../../model/Bid';
import {EBidStatus} from 'src/app/model/enums/EBidStatus';
import {Router} from '@angular/router';
import {BrushNotification} from '../../../services/notification-service';
import {MessageService} from '../../../services/message.service';
import {Project} from '../../../model/Project';
import {TokenStorageService} from '../../../services/token-storage.service';
import {getRedirectLinkForProjectType} from '../../../model/enums/EProjectType';
import {BidsService} from '../../../services/bids.service';

export interface BidStatusChange {
  status: EBidStatus;
  tag: string;
}

@Component({
  selector: 'app-bid-card-mini',
  templateUrl: './bid-card-mini.component.html',
  styleUrls: ['./bid-card-mini.component.scss']
})
export class BidCardMiniComponent implements OnInit {
  @Input() bid!: Bid;
  @Input() project!: Project;
  @Input() detailedView!: boolean;
  acceptBidModalVisible = false;
  denyBidModalVisible = false;
  displayDetailsModalVisible = false;
  @Output() bidStatusChanged = new EventEmitter();
  @Output() bidAccepted = new EventEmitter();
  EBidStatus = EBidStatus;

  constructor(private router: Router, private bidsService: BidsService, private messageService: MessageService, private tokenStorage: TokenStorageService) {
  }

  ngOnInit(): void {
  }
  checkPendingOrNewStatus(): boolean {
    if (this.bid.status !== EBidStatus.NEW && this.bid.status !== EBidStatus.PENDING) {
      return true;
    }
    return false;
  }

  checkCardStatus(): string {
    if (this.bid.status === EBidStatus.DENIED) {
      return 'bid-card-wrapper denied';
    }
    return 'bid-card-wrapper';
  }

  checkDetailedView(): string {
    if (this.detailedView) {
      return 'bid-comment';
    }
    return 'bid-comment not-detailed';
  }

  visitPainterProfile(): void {
    this.router.navigate(['/home/profile/' + this.bid.userPainter.clientID]);
  }

  acceptBid(): void {
    this.bidsService.changeBidStatus({status: EBidStatus.ACCEPTED, tag: this.bid.tag}).subscribe(
      response => {
        this.hideAllModals();
        this.bidStatusChanged.emit();
        this.bidAccepted.emit();
      },
      error => {
        console.log(error);
      });
  }

  denyBid(): void {
    this.bidsService.changeBidStatus({status: EBidStatus.DENIED, tag: this.bid.tag}).subscribe(
      response => {
        //fixme: na serwer
        this.messageService.sendMessageRelatedIdSub(this.tokenStorage.getId(), BrushNotification.BID_DENIED_NOTIFICATION_CUSTOMER, this.project.projectDetails.title, getRedirectLinkForProjectType(this.project.projectType).value + '/' + this.project.tag as string, this.bid.tag);
        this.hideAllModals();
        this.bidStatusChanged.emit();
      },
      error => {
        console.log(error);
      });
  }

  hideAllModals(): void {
    this.denyBidModalVisible = false;
    this.acceptBidModalVisible = false;
    this.displayDetailsModalVisible = false;
  }

  hideAcceptBidModal(): void {
    this.acceptBidModalVisible = false;
  }
  hideDenyBidModal(): void {
    this.denyBidModalVisible = false;
  }
  openAcceptBidModal(): void {
    this.acceptBidModalVisible = true;
  }

  openDenyBidModal(): void {
    this.denyBidModalVisible = true;
  }

  openDetailsModal(): void {
    this.displayDetailsModalVisible = true;
    if (this.bid.status === EBidStatus.NEW) {
      this.bidsService.changeBidStatus({status: EBidStatus.PENDING, tag: this.bid.tag}).subscribe(
        response => {
          this.bid.status = EBidStatus.PENDING;
        },
        error => {
          console.log(error);
        });
    }
  }
}
