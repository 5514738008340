<div class="wall-card-wrapper">
  <div [ngClass]="editMode ? 'header accent' : 'header'"><span>{{wallName != null ? wallName : 'Add New Wall'}}</span>
    <div class="tooltip">
      <img *ngIf="editMode && this.wallFormGroup.valid" src="assets/project-creator/add-wall.png" alt="add-wall"
           (click)="saveWall()">
      <span class="tooltiptext">Save</span>
    </div>

    <div *ngIf="!editMode" class="non-edit-mode-icons">
      <div class="tooltip">
        <nb-icon icon="copy-outline" *ngIf="duplicateButtonVisible" (click)="copy()"></nb-icon>
        <span class="tooltiptext">Duplicate Wall</span>
      </div>
      <div class="tooltip">
        <nb-icon icon="trash-2-outline" (click)="delete()"></nb-icon>
        <span class="tooltiptext">Delete Wall</span>
      </div>
    </div>
  </div>
  <div class="content" [formGroup]="wallFormGroup">
    <div class="dimensions">
      <input type="number" formControlName="wallHeight" placeholder="Height" min="0">
      <span>x</span>
      <input type="number" formControlName="wallWidth" placeholder="Width">
    </div>
    <div class="color">
      <div class="color-title">Current Color</div>
      <div class="color-input" [ngStyle]="{'background': wallFormGroup.controls['previousColor'].value}">
        <input type="color" formControlName="previousColor">
      </div>
      <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse" class="eclipse-image">
      <div class="checkboxes" *ngIf="!exteriorCreator">
      <div class="checkbox-wrapper"
           [ngClass]="wallFormGroup.controls['previousWallpaper'].value === true ? 'checkbox-wrapper checkbox-checked' : 'checkbox-wrapper'">
        <div class="checkbox-label">Has wallpaper</div>
        <input type="checkbox" class="checkbox" formControlName="previousWallpaper">
      </div>
      <div class="checkbox-wrapper"
           [ngClass]="wallFormGroup.controls['previousFaux'].value === true ? 'checkbox-wrapper checkbox-checked' : 'checkbox-wrapper'">
        <div class="checkbox-label">Faux finish</div>
        <input type="checkbox" class="checkbox" formControlName="previousFaux">
      </div>
      <div class="checkbox-wrapper"
           [ngClass]="wallFormGroup.controls['previousDecorative'].value === true ? 'checkbox-wrapper checkbox-checked' : 'checkbox-wrapper'">
        <div class="checkbox-label">Decorative painting</div>
        <input type="checkbox" class="checkbox" formControlName="previousDecorative">
      </div>
      </div>


    </div>
    <div class="color">
      <div class="color-title">New Color</div>
      <div class="color-input" [ngStyle]="{'background': wallFormGroup.controls['newColor'].value}">
        <input type="color" formControlName="newColor">
      </div>
      <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse" class="eclipse-image">
      <div class="checkboxes" *ngIf="!exteriorCreator">
      <div class="checkbox-wrapper"
           [ngClass]="wallFormGroup.controls['newWallpaper'].value === true ? 'checkbox-wrapper checkbox-checked' : 'checkbox-wrapper'">
        <div class="checkbox-label">Has wallpaper</div>
        <input type="checkbox" class="checkbox" formControlName="newWallpaper">
      </div>
      <div class="checkbox-wrapper"
           [ngClass]="wallFormGroup.controls['newFaux'].value === true ? 'checkbox-wrapper checkbox-checked' : 'checkbox-wrapper'">
        <div class="checkbox-label">Faux finish</div>
        <input type="checkbox" class="checkbox" formControlName="newFaux">
      </div>
      <div class="checkbox-wrapper"
           [ngClass]="wallFormGroup.controls['newDecorative'].value === true ? 'checkbox-wrapper checkbox-checked' : 'checkbox-wrapper'">
        <div class="checkbox-label">Decorative painting</div>
        <input type="checkbox" class="checkbox" formControlName="newDecorative">
      </div>
      </div>
    </div>
  </div>
</div>
