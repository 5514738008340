<div class="footer-package modified_footer">

  <div class="upper-section">
    <div class="overlay_sec">
      <div class="fixed_sec_content">
        <p class="claim">If you have questions about <span>Brush Exchange</span>, we’d be happy to connect</p>
        <!-- <p  class="subclaim">Brushed with love.</p> -->

        <a class="green_btn" routerLink="/contact-us">Contact Us</a>
      </div>
    </div>
  </div>

  <div class="middle-section">
    <div class="row">
      <div class="column logo_sec responsive_big_screen_logo">
        <div class="logo-wrapper">
          <img src="assets/logo3.png" class="logo">
        </div>
      </div>

      <!------responsive---->
      <div class="column logo_sec responsive_small_screen_logo">
        <div class="logo-wrapper">
          <img src="assets/logo.png" class="logo">
        </div>
      </div>

      <div class="column">
        <!-- <div class="title">Brush</div> -->
        <a class="item" href="/how-works">How does it work</a>
      <!--  <a class="item" href="/about-us">About us</a>-->
        <a class="item" href="./contact-us">Contact us</a>
        <a class="item" href="./faq">Faq</a>

      </div>

      <div class="column">
        <!-- <div class="title">Support</div> -->
        <a class="item" href="./privacy-policy">Privacy policy</a>
        <a class="item" href="./terms-conditions">Terms and Conditions</a>
        <a class="item" href="/help">Help</a>
        <a class="item" href="/blogs">Blog</a>
        <!-- <a class="item" href="./get-inspired">Get inspired</a> -->
      </div>


      <div class="column">
        <!-- <div class="title">More</div> -->
        <div class="bottom-section">
          <div class="socials-wrapper">
            <a target="_blank" href="{{profile[0]?.icon_url}}" class="icon-link"><svg aria-hidden="true"
                focusable="false" data-prefix="fab" data-icon="facebook" class="svg-inline--fa fa-facebook fa-w-16"
                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="currentColor"
                  d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z">
                </path>
              </svg></a>
            <a target="_blank" href="{{profile[1]?.icon_url}}" class="icon-link"><svg aria-hidden="true"
                focusable="false" data-prefix="fab" data-icon="linkedin-in"
                class="svg-inline--fa fa-linkedin-in fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                </path>
              </svg></a>
            <a target="_blank" href="{{profile[2]?.icon_url}}" href="https://www.instagram.com/brushexchange/"
              target="_blank" class="icon-link"><svg aria-hidden="true" focusable="false" data-prefix="fab"
                data-icon="instagram" class="svg-inline--fa fa-instagram fa-w-14" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z">
                </path>
              </svg></a>
            <a target="_blank" href="{{profile[3]?.icon_url}}" class="icon-link label-link"><svg aria-hidden="true"
                focusable="false" data-prefix="fas" data-icon="globe" class="svg-inline--fa fa-globe fa-w-16" role="img"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                <path fill="currentColor"
                  d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z">
                </path>
              </svg></a>
          </div>
        </div>
        <div class="rights light_txt copyright_center">&#169; {{ getCurrentYear() }} Brushexchange<span> All rights reserved</span></div>

      </div>
    </div>
  </div>

  <div class="bottom-section">
    <div class="inner-flex">





    </div>
  </div>

</div>
