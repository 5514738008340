export enum EBidStatus {
  DELETED = -2,
  ACCEPTED = 1,
  DENIED = -1,
  PENDING = 0,
  NEW = 2,
  EXPIRED = 3
}

export function ifPending(status: EBidStatus): boolean {
  return status === EBidStatus.PENDING;
}

export function ifDenied(status: EBidStatus): boolean {
  return status === EBidStatus.DENIED;
}

export function ifAccepted(status: EBidStatus): boolean {
  return status === EBidStatus.ACCEPTED;
}
