import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {BrushNotification} from '../../services/notification-service';
import {EMessageStatus} from '../../model/enums/EMessageStatus';


@Component({
  template: ''
})
export abstract class NotificationViewComponent implements OnInit {
  @Input() message: any;
  @Output() messageMarkedAsRead = new EventEmitter();
  @Output() deleteMessage = new EventEmitter();
  icon: any;


  abstract notificationNewBidCustomer(): void;

  abstract notificationNewBidPainter(): void;

  abstract notificationBidAcceptedCustomer(): void;

  abstract notificationBidAcceptedPainter(): void;

  abstract notificationBidCanceledCustomer(): void;

  abstract notificationBidCanceledPainter(): void;

  abstract notificationBidDeniedPainter(): void;

  abstract notificationBidDeniedCustomer(): void;

  abstract notificationPaymentDoneCustomer(): void;

  abstract notificationPaymentDonePainter(): void;

  abstract notificationNewCommentPainter(): void;

  abstract notificationProjectCreated(): void;

  abstract notificationProjectAcceptedFromDraft(): void;

  abstract notificationProjectDeletedCustomer(): void;

  abstract notificationProjectDeletedPainter(): void;

  protected constructor(protected router: Router) {

  }
  whatTypeOfNotification(): void {
    switch (this.message.notificationType) {
      case 'NEW_BID_NOTIFICATION_CUSTOMER':
        this.notificationNewBidCustomer();
        break;
      case 'NEW_BID_NOTIFICATION_PAINTER':
        this.notificationNewBidPainter();
        break;
      case 'BID_ACCEPTED_NOTIFICATION_CUSTOMER':
        this.notificationBidAcceptedCustomer();
        break;
      case 'BID_ACCEPTED_NOTIFICATION_PAINTER':
        this.notificationBidAcceptedPainter();
        break;
      case 'BID_CANCELED_NOTIFICATION_CUSTOMER':
        this.notificationBidCanceledCustomer();
        break;
      case 'BID_CANCELED_NOTIFICATION_PAINTER':
        this.notificationBidCanceledPainter();
        break;
      case 'PAYMENT_DONE_PAINTER':
        this.notificationPaymentDonePainter();
        break;
      case 'PAYMENT_DONE_CUSTOMER':
        this.notificationPaymentDoneCustomer();
        break;
      case 'BID_DENIED_NOTIFICATION_PAINTER':
        this.notificationBidDeniedPainter();
        break;
      case 'BID_DENIED_NOTIFICATION_CUSTOMER':
        this.notificationBidDeniedCustomer();
        break;
      case 'NEW_COMMENT_PAINTER':
        this.notificationNewCommentPainter();
        break;
      case 'PROJECT_CREATED_CUSTOMER':
        this.notificationProjectCreated();
        break;
      case 'PROJECT_ACCEPTED_FROM_DRAFT':
        this.notificationProjectAcceptedFromDraft();
        break;
      case 'PROJECT_DELETED_CUSTOMER':
        this.notificationProjectDeletedCustomer();
        break;
      case 'PROJECT_DELETED_PAINTER':
        this.notificationProjectDeletedPainter();
        break;
    }
  }

  markMessageAsRead(): void {
    if (this.message.messageStatus === EMessageStatus.UNREAD) {
      this.messageMarkedAsRead.emit(this.message);
      this.message.messageStatus = 'READ';
    }
  }

  deleteMessageEvent(): void {
    this.deleteMessage.emit(this.message);
  }

  ngOnInit(): void {
    this.whatTypeOfNotification();
  }

  navigate(): void {
    this.router.navigate([BrushNotification.uriBuilder(this.message)], {skipLocationChange: false});
  }
}
