<div class="route-title">
    <div class="d-flex">
        <a class="bck_btn" routerLink="/home/my-project-card">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </a>
        Messages
    </div>

    <app-notification-bell></app-notification-bell>
</div>
<!-- <div class="wrapper" *ngIf="!isConversationListEmpty"> -->
<div class="wrapper chat_section_height chat-wraper chat_wrp">
    <div class="contact-list ">
        <!-- <app-chat-contact-list-element *ngFor="let conv of messageService.getPrivateConversations()"
      (click)="loadMessages(conv, tokenStorage.getUser().fullName === conv.userCustomer.userPersonal.fullName ? conv.userPainter.userPersonal.fullName : conv.userCustomer.userPersonal.fullName)"
      [conversation]="conv"
      [topic]="getProjectTitle(conv)">
    </app-chat-contact-list-element> -->

        <!--   <app-chat-contact-list-element></app-chat-contact-list-element>-->
        <div class="chat_container" *ngFor="let item of rooms">
            <div class="contact">
                <div class="info" (click)="openChatrrom(item.id, item.room_id)">
                    <div class="avatar-wrapper">
                        <!-- <img src="{{profileImage| defaultAvatar}}" alt="avatar" class="avatar-image"> -->
                        <img [src]="item.senderData?.profile_image && item.senderData?.profile_image!='null' ? item.senderData?.profile_image : 'assets/default.png'"
                            alt="avatar" class="avatar-image"> 
                            <!-- <img  *ngIf="!item.senderData?.profile_image" src="assets/default.png">  -->
                            <!-- <img [src]="item?.userData?.profile_image ? 
                            (startsWithHttps(item.userData.profile_image) ? 
                            item.userData.profile_image : path + item.userData.profile_image)
                            : './assets/default.png'" alt="avatar" class="avatar-image"> -->

                    </div>
                    <div class="detailed-info">
                        <!-- <div class="name">{{username}}</div> -->

                        <div class="name"><a>{{item.senderData?.full_name}}</a></div>
                        <!-- <div class="topic">{{topic}}</div> -->
                        <div class="topic">{{item?.project_details?.project_title}}</div>
                        <!-- <div [ngClass]="isUnread && messageService.getOpenedPrivateConversation()?.conversationId != conversation.conversationId? 'unread content' : 'content'">{{lastMessageFrom}}{{conversation.lastMessageContent}}</div> -->
                        <div class="you_replay" *ngIf="item.latest_chat[0].sender_id== item.sender_id">You:
                            {{item?.latest_chat[0]?.message}}</div>
                        <div class="you_replay" *ngIf="item.latest_chat[0].sender_id != item.sender_id">
                            {{item?.latest_chat[0]?.message}}</div>

                    </div>
                </div>
            </div>
            <hr>

        </div>


    </div>
    <!-- <div class="chat-window" *ngIf="conversation"> -->
    <div class="chat-window">
        <section class="chat-window-header" *ngIf="s_detials">
            <div class="user-info">
                <!-- <div class="avatar-wrapper"><img [src]="conversation.userPainter.profileImage | defaultAvatar"></div> -->
               <div class="avatar-wrapper" *ngIf="s_detials?.profile_image && s_detials?.profile_image !='null'" (click)="opendetails(s_detials.id)"><img [src]="s_detials.profile_image"></div>
        <div class="avatar-wrapper" *ngIf="!s_detials?.profile_image || s_detials?.profile_image == 'null'" (click)="opendetails(s_detials.id)"><img src='./assets/default.png'></div>
              


                <div class="user-info-details">
                    <!-- <div class="full-name">{{conversation.userPainter.userP<a>ersonal.fullName}}</div> -->
                    <div class="full-name" (click)="opendetails(s_detials.id)"><a>{{s_detials?.full_name}}</a></div>
                    <!-- <div class="project-title">{{getProjectTitle(conversation)}}</div> -->
                    <div class="project-title"><a
                            routerLink="/home/project-detail/{{projectdetails?.id}}"><b>{{projectdetails?.project_title}}</b></a>
                    </div>
                    <!-- <div class="project-title">{{projectdetails?.description}}</div>-->
                    <div class="view-details" routerLink="/home/project-detail/{{projectdetails?.id}}">View Details
                    </div>
                </div>
            </div>
            <div class="chat_dlt cst">
                <div class="chat_bid_amt">
                    <h6>Bid Amount</h6>
                    <h3>${{bid?.bid}} </h3>
                </div>
                <span (click)="open()">
                    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                            <path d="M3 6H5H21" stroke="#200920" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 11V17" stroke="#200920" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M14 11V17" stroke="#200920" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </g>
                    </svg>
                </span>

            </div>
        </section>

        <nb-card [nbSpinner]="loading" class="nb-theme-brush">
            <section class="chat-messages" id="chat-window">
                <!-- <div class="message" *ngIf="message"> -->
                <div class="message painter_chat " style="overflow:auto; height: 100%;" #scrollMe
                    [scrollTop]="scrollMe.scrollHeight">
                    <!--<div [ngClass]="isItMyMessage ? 'message-header right' : 'message-header left'" class="message-header">-->
                    <div *ngFor="let msg of messages">
                        <section class="date">
                            <!--FIXME what is this date??-->
                            <small>{{ msg.date | chatDate }}</small>

                        </section>
                        <div *ngFor="let item of msg.chats">
                            <div class="message-header" *ngIf="item?.senderData?.id== item.sender_id">
                                <div class="avatar-wrapper" *ngIf="item?.senderData?.profile_image && item?.senderData?.profile_image !='null'"><img
                                        [src]=" item?.senderData?.profile_image"></div>
                                <div class="avatar-wrapper" *ngIf="!item?.senderData?.profile_image || item?.senderData?.profile_image =='null' "><img
                                        src='./assets/default.png'>
                                </div>

                                <div class="margin_top">
                                    <div class="info">
                                        <div class="full-name">{{item?.senderData?.full_name}}</div>
                                        <div class="time"> {{ item.created_at | date:'HH:mm' }}</div>
                                    </div>
                                    <p class="info">
                                        {{item.message}}
                                    </p>
                                    <span class="info" *ngIf="item.message_type=='media'">
                                        {{item.message}}
                                        <div class="txt_file" *ngIf="item?.attachments?.length">
                                            <div *ngFor='let items of item.attachments'>
                                                <div class="text_file">
                                                    <!-- <i class="far fa-file-alt"></i> -->
                                                    <img [src]="getFileIcon(items)" class="img-fluid" alt="">
                                                </div>
                                                <div class="file_nme">
                                                    <div class="d-flex justify-content-between">
                                                        <p><a (click)="downloadFile(items?.url)"> {{items?.name}}</a>
                                                            <!-- {{getFileName(attachment.name)}} -->
                                                        </p>
                                                        <i class="far fa-arrow-to-bottom"
                                                            (click)="downloadFile(items?.url)">
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </span>

                                </div>

                            </div>
                            <div class="message-header" *ngIf="item?.senderData?.id != item.sender_id">
                                <div class="avatar-wrapper" *ngIf="!item?.userData?.profile_image || item?.userData?.profile_image == 'null'"><img
                                    src='./assets/default.png'>
                                </div>
                                
                                <div class="avatar-wrapper" *ngIf="item?.userData?.profile_image && item?.userData?.profile_image !='null'"><img
                                        [src]="item?.userData?.profile_image"></div>

                                <div class="margin_top">
                                    <div class="info">
                                        <div class="full-name">{{item?.userData?.full_name}}</div>
                                        <div class="time"> {{ item.created_at | date:'HH:mm' }}</div>
                                    </div>
                                    <p class="info">
                                        {{item.message}}
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!--<div [ngClass]="isItMyMessage ? 'message-content right' : 'message-content left'">-->
                    <!-- <div class="message-content" >
    <pre *ngIf="message.messageType === EMessageType.TEXT" [innerHTML]="messageTextContent"></pre>
    <img *ngIf="message.messageType == EMessageType.IMAGE" class="message-image" [src]="'data:image/png;base64,'+message.file" (click)="downloadFile()" alt="image">
    <div *ngIf="message.messageType == EMessageType.FILE" class="file-wrapper" (click)="downloadFile()">
      <img [src]="fileImage" alt="image">
        <div class="file-name">{{message.fileName}}
      </div>
    </div>
  </div> -->

                    <!-- <div class="message-content" >
    <pre>fgghh</pre>
    <img class="message-image" src ="./assets/Ellipse1.png" (click)="downloadFile()" alt="image">
    <div class="file-wrapper" (click)="downloadFile()">
      <img [src]="fileImage" alt="image">
        <div class="file-name">ggjghj
      </div>
    </div>
  </div> -->


                </div>



                <!-- <app-chat-message></app-chat-message>-->
            </section>
        </nb-card>
        <section class="input-wrapper">
            <textarea [(ngModel)]="typedText" (keyup.enter)="sendMessagenew()" placeholder="Type your message"
                name="typedText" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"></textarea>
            <div *ngIf="filesArr.length" class="selected-file-wrapper">
                <div *ngFor="let file of filesArr; let i = index">
                    <img [src]="getURLofFile(file)" alt="image" class="selected-file-image">
                    <span (click)="removeFile(i)"> <mat-icon>close</mat-icon>
                    </span>

                </div>

            </div>
            <div class="icons">
                <div class="file-input-wrapper">
                    <label for="formFile">
                        <img src="assets/icons/chat/chat-attachment.png" class="pin_icon">
                    </label>
                    <input class="form-control" type="file" id="formFile"
                        (change)="selectFile($event);uploadFiles($event)">
                </div>

                <div class="file-input-wrapper">
                    <label for="imgId">
                        <img src="assets/icons/chat/chat-image-attachment.png" class="gallery_icon">
                    </label>
                    <input type="file" [accept]="'.jpg, .jpeg, .png, .gif'" id="imgId"
                        (change)="selectFile($event);uploadFiles($event)">
                </div>
                <img *ngIf="filesArr?.length" src="assets/icons/chat/chat-send.png" (click)="sendMessagenewupload()">

                <img class="sendMsgIcn" *ngIf="filesArr?.length == 0" src="assets/icons/chat/chat-send.png"
                    (click)="sendMessagenew()">
            </div>

        </section>

    </div>
</div>

<app-modal class="bid-detail-mdl" [displayModal]="modalFlag" (cancelButtonClicked)="closeComplainModal()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <div class="d-flex">
                    <div class="bid_img">
                        <img [src]="path+cus_profile?.profile_image">
                    </div>
                    <div class="info">
                        <div class="painter-details">
                            <div class="div_upr d-flex">
                                <h3>
                                    {{cus_profile?.full_name}}
                                </h3>
                                <!-- <span routerLink="/home/profile" class="undr_lne_text">View Profile</span> -->

                            </div>
                            <div class="sub-info">
                                <img src="assets/icons/star.png" alt="star" class="logo">
                                <span class="rating"> {{cus_profile?.avgRating | number: '1.0-0'}}.0 </span>
                                <span class="no-of-reviews">({{cus_profile?.completed_projects}} Reviews)</span>
                            </div>

                        </div>
                        <!---  <div class="bid-details">
              <div class="bid-amount-text">Bid Amount</div>
              <div class="bid-amount">$3,400.00</div>
            </div>-->


                    </div>

                </div>
                <div class="content">

                    <div class="main-info mt-3">
                        <div class="title">Contact Details</div>
                        <div>

                            <div class="contact-detail">
                                <img src="assets/icons/contact/localization.png" alt="phone">
                                <div class="contact-data"> {{cus_profile?.state |
                                    titlecase}},
                                    {{cus_profile?.zip_code}}</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>

<app-modal class="project_report_mdl dlt_prfl_mdl prjctdlt_modl" [displayModal]="modalFlagnew"
    (cancelButtonClicked)="closeComplainModal()">
    <slot-content>
        <div class="bid_detail_modal dlt_chat_mdl">
            <div class="bid-card-wrapper">
                <p class="title exchng_clr">Delete Chat?</p>
                <p class="mdl_txt">
                    Are you sure you want to delete this chat?
                </p>
                <div class="d-flex dbl_btns">
                    <button class="custom-button" (click)="deleteproj()">Delete</button>
                    <button class="border_button mt-0" (click)="this.modalFlagnew = false">Cancel</button>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>
<!-- <app-nothing-to-display [img]="'/assets/vision.svg'" *ngIf="isConversationListEmpty"
                        [claim]="'You must have at least 1 available conversation to see chat.'"></app-nothing-to-display> -->