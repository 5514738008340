<div class="route-title nav-with-icon">
  <span (click)="navigateBack()">
    <nb-icon icon="arrow-back-outline"></nb-icon>&nbsp;My Jobs
  </span>
  <!-- <app-notification-bell></app-notification-bell> -->
</div>
<div>
  <section class="bid-details-wrapper crd_wpr">
    <!--  <app-project-component [inputData]="project" [showProjectStatus]="true" [marketplace]="true">-->
    <div class="card">


      <div class="image-wrapper">
        <div class="image-sub-wrapper">
          <img src="{{projectDetails?.images[0]?.image}}">
        </div>


      </div>

      <div class="top-main-info-res">
        <div class="title-wrapper">
          <div class="title-status-wrapper">
            <ngb-rating [max]="5" [(rate)]="currentRate" [readonly]="false"></ngb-rating>
            <span class="title"> {{projectDetails.project_title}}</span>

            <!-- <span class="title">{{project.title}}</span> -->
            <!-- <span class="title">Need a painter for my mansion, Urgent!</span> -->
            <!-- <app-project-status *ngIf="!marketplace" [project]="project"></app-project-status> -->
          </div>
          <div class="when-posted" *ngIf="projectDetails.days_remaining>0">Posted
            {{projectDetails.days_remaining}} days ago</div>
          <div class="when-posted" *ngIf="projectDetails.days_remaining == 0">Posted
            today</div>
          <!--  <div class="when-posted">15h ago</div>-->
        </div>
        <!-- <div class="project-type">{{project.type | enumPipe: EProjectType: true: false}}</div> -->
        <div class="project-type">{{projectDetails.project_type | titlecase}}</div>

        <div class="description-res">
          <!-- <div class="two-lines-description">
        {{project.description}}</div> -->

          <div class="two-lines-description">
            {{projectDetails.description}}
          </div>
          <div painter-perspective class="view-original-listing"
            (click)="viewDemoDetailsscomp(projectDetails?.id, projectDetails?.status)">
            <div class="view-original-listing-text">View Original Listing</div>
            <nb-icon icon="arrow-forward-outline"></nb-icon>
          </div>
          <!-- 
          <div class="section bottom painter_job_dtl">
            <div class="tags">
              <div class="tag-wrappers">
                <div class="tag yellow-filled">In Progress</div>
                <div class="tag">Wallpaper</div>
                <div class="tag">Four rooms</div>
                <div class="tag">Medium area</div>
                <div class="tag">Fine condition</div>
              </div>

            </div>
          </div> -->
          <!-- <div painter-perspective class="view-original-listing"
            routerLink="/home/my-project-detail/{{projectDetails.id}}">
            <div class="view-original-listing-text">View Original Listing</div>
            <nb-icon icon="arrow-forward-outline"></nb-icon>
          </div>-->
        </div>
      </div>
      <!-- <div class="properties">
        <div class="property">
          <div class="property-key">Type of Project</div>
          <div class="property-value">{{projectDetails.projectType}}</div>
        </div>
        <div class="property">
          <div class="property-key">Job Type</div>
          <div class="property-value">{{projectDetails.tags.localType}}</div>
        </div>
        <div class="property">
          <div class="property-key">Property Type</div>
          <div class="property-value">{{projectDetails.tags.propertyType}}</div>
        </div>
        <div class="property">
          <div class="property-key">Scheduling Preferences</div>
          <div class="property-value">
            {{projectDetails.tags.schedulingPreferences}}</div>
        </div>
        <div class="property">
          <div class="property-key">Number of Rooms</div>
          <div class="property-value">{{projectDetails.tags.roomsNumber}}</div>
        </div>
        <div class="property">
          <div class="property-key">Ceiling Height</div>
          <div class="property-value">{{projectDetails.tags.ceilingHeight}}</div>
        </div>
        <div class="property">
          <div class="property-key">Has wallpaper</div>
          <div class="property-value">{{projectDetails.tags.hasWallpaper}}</div>
        </div>
        <div class="property">
          <div class="property-key">Providing items</div>
          <div class="property-value">{{projectDetails.tags.providingItems}}</div>
        </div>
        <div class="property">
          <div class="property-key">Surface area</div>
          <div class="property-value">{{projectDetails.tags.surfaceArea}}</div>
        </div>
        <div class="property">
          <div class="property-key">Surface condition</div>
          <div class="property-value">{{projectDetails.tags.surfaceCondition}}
          </div>
        </div>
      </div> -->
    </div>


    <!--  </app-project-component>-->
    <div class="your-bid-wrapper" *ngIf="projectDetails?.Bids?.bid">
      <div class="accepted-bid-text">
        Accepted Bid
      </div>
      <div class="accepted-bid-value">
        ${{projectDetails?.Bids?.bid}}
      </div>
    </div>

  </section>
  <section class="main">
    <div class="main-info-wrapper project-pending" *ngIf="
                                         projectDetails.painter_status=='disputed'">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text ">Job was disputed by painter, you will get an update soon</div>
      <button class="custom-button" (click)="viewdispute()">Review</button>

    </div>
    <div class="main-info-wrapper project-pending" *ngIf="
                                         projectDetails.customer_status=='disputed'">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text ">Job was disputed by customer, you will get an update soon</div>
      <button class="custom-button" (click)="viewdispute()">Review</button>

    </div>
    <div class="main-info-wrapper project-completed" *ngIf="projectDetails?.status=='archived'">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text">Project is completed</div>
    </div>
  </section>


  <section class="main" *ngIf="projectDetails.bid_status=='approved' && projectDetails.status=='brushed' && projectDetails.painter_status !='completed'">
    <div class="main-info-wrapper project-in-progress"
      >
      <nb-icon icon="clock"></nb-icon>

      <div class="main-info-text">Project in Progress
      </div>
      <button class="custom-button" (click)="markcomplete(projectDetails.id)">Mark
        Complete</button>

    </div>
    <div class="main-info-wrapper project-in-progress">
      <nb-icon icon="clock"></nb-icon>

      <div class="main-info-text">Chat with customer
      </div>
      <button class="custom-button"
        routerLink="/home/chat/{{projectDetails?.Bids?.room?.room_id}}/{{projectDetails?.Bids?.room?.id}}">Chat with
        customer</button>

    </div>
    <div class="main-info-wrapper project-pending" *ngIf="
                                         projectDetails.painter_status=='completed'">
      <nb-icon icon="clock"></nb-icon>

      <div class="main-info-text">Painter Status - Completed, Customer Status - Brushed
      </div>

    </div>
    <div class="main-info-wrapper project-in-progress">
      <nb-icon icon="clock"></nb-icon>
      <div class="main-info-text">Is there something wrong about
        this job or customer? Please let us know!
      </div>

      <button class="custom-button" (click)="openComplainModal()">Raise an Issue</button>
    </div>
    <!--  <div class="main-info-wrapper project-pending">
                                            <nb-icon icon="checkmark-circle-2"></nb-icon>
                                            <div class="main-info-text ">It seems that there is a complain related with
                                                this project</div>
                                            <button class="custom-button footer-button">Quit job</button>
                                        </div>-->


  </section>
  <section class="main" *ngIf="projectDetails?.status=='completed'">

    <div class="main-info-wrapper project-in-progress"
      *ngIf="!projectDetails?.review_detail || !projectDetails?.review_detail?.painter_rating">
      <div class="d-flex">
        <nb-icon icon="clock"></nb-icon>
        <div class="main-info-text">Add review!
        </div>
      </div>

      <button class="custom-button" (click)="openComplainModal()">Review</button>
    </div>
    <div class="main-info-wrapper project-in-progress" *ngIf="projectDetails?.review_detail?.painter_rating">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text"> Review added!
      </div>

    </div>



  </section> 
  <!-- <section class="main">
    <div class="main-info-wrapper project-in-progress" *ngIf="project.status ===  EProjectStatus.AWAITING">
      <nb-icon icon="clock"></nb-icon>
      <div class="main-info-text" *ngIf="!project.markedAsCompletedByCustomer">We are awaiting your payment. After the
        payment is done you will be able to reach customer.
      </div>
      <button class="custom-button" (click)="pay()">Pay now (notworking)</button>
    </div>
    <div class="main-info-wrapper project-in-progress"
      *ngIf="project.status ===  EProjectStatus.IN_PROGRESS && complain == null">
      <nb-icon icon="clock"></nb-icon>
      <div class="main-info-text" *ngIf="!project.markedAsCompletedByCustomer">Project in progress</div>
      <div class="main-info-text" *ngIf="project.markedAsCompletedByCustomer">Project in progress. Customer has marked
        project as completed
      </div>
      <button class="custom-button" (click)="markAsCompletedModalVisible = true"
        *ngIf="!project.markedAsCompletedByPainter">Mark Complete
      </button>
    </div>
    <div class="main-info-wrapper project-in-progress"
      *ngIf="(project.status ===  EProjectStatus.IN_PROGRESS || EProjectStatus.AWAITING) && complain == null">
      <nb-icon icon="clock"></nb-icon>
      <div class="main-info-text" *ngIf="project.status ===  EProjectStatus.IN_PROGRESS ">Is there something wrong about
        this job or customer? Please let us know!
      </div>
      <div class="main-info-text" *ngIf="project.status ===  EProjectStatus.AWAITING ">We are awaiting your payment,
        something gone wrong? Please let us know!
      </div>
      <button class="custom-button" (click)="openComplainModal()">Make a complain</button>
    </div>
    <div class="main-info-wrapper project-pending" *ngIf="complain != null">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text ">It seems that there is a complain related with this project</div>
      <button class="custom-button footer-button">Details</button>
    </div>
    <div class="main-info-wrapper project-completed" *ngIf="project.status ===  EProjectStatus.COMPLETED">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text">Project is completed</div>
    </div>
  </section> -->
</div>
<!-- <app-modal [displayModal]="markAsCompletedModalVisible" [title]="'Mark Project as Completed'"
  [subclaim]="'Are you sure you want to mark project as Completed?'" [confirmButtonText]="'Confirm'"
  (confirmButtonClicked)="markCompleted()" (cancelButtonClicked)="markAsCompletedModalVisible = false"></app-modal>


<app-modal [displayModal]="makeComplainDialogVisible" [title]="'Make a complain'"
  [subclaim]="'Select reason for complain.'" (confirmButtonClicked)="makeComplain()"
  [confirmButtonText]="'Make complain'" (cancelButtonClicked)="closeComplainModal()">
  <slot-content>
    <app-simple-drop-down-component [placeHolderOrValue]="'Select Reason'" [items]="complainReasons"
      (valueChanged)="select($event)"></app-simple-drop-down-component>
    <div *ngIf="selectedComplainReason !== EComplainReason.DEFUALT">
      <p>Please tell us what exactly has gone wrong</p>
      <textarea class="content" rows="5" placeholder="Write a complain..."></textarea>
    </div>
  </slot-content>
</app-modal> -->

<app-modal [displayModal]="makeComplainDialogVisible" class="markComplain_modal" [title]="'Rate'"
  (cancelButtonClicked)="closeComplainModal()">

  <slot-content>
    <form class="chat_wrap" [formGroup]="feedBackform">
      <app-painter-rating></app-painter-rating>

      <div class="input-wrapper">

        <p>Please write your review below</p>
        <textarea class="content" rows="5" formControlName='complaint' placeholder="Write a review..."></textarea>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <button (click)="save()" class="custom-button">Save</button>
      </div>
    </form>
  </slot-content>

</app-modal>

<app-modal [displayModal]="makeComplainDialogVisible1dispute" class="markComplain_modal" [title]="'Rate'"
  (cancelButtonClicked)="closeComplainModal()">

  <slot-content>
    <p class="mrgn_sbt">Title</p>


    <div class="input-wrapper">

      <p>{{projectDetails?.dispute_time |date}}</p>

      <textarea class="content" disabled [(ngModel)]="projectDetails.dispute_option"
        placeholder="Write a complain..."></textarea>
    </div>

    <div class="input-wrapper">

      <p>Description</p>
      <textarea class="content" rows="5" [(ngModel)]="projectDetails.dispute_reason" disabled
        placeholder="Write a complain..."></textarea>
    </div>
    <div class="input-wrapper" *ngIf="projectDetails?.dispute_reply?.length">

      <p>Admin reply</p>
      <p>{{projectDetails?.dispute_reply[0].created_at |date}}</p>

      <textarea class="content" rows="5" [(ngModel)]="projectDetails.dispute_reply[0].reply" disabled
        placeholder="Write a complain..."></textarea>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <button (click)="clos()" class="custom-button">Close</button>
    </div>

  </slot-content>

</app-modal>