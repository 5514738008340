<!-- <div class="card" (click)="viewProjectDetails()" *ngIf="project"> -->
<div class="card">


  <div class="image-wrapper">
    <div class="image-sub-wrapper">
      <img src="./assets/Rectangle5.png">
    </div>
    <div class="top-main-info-res">
      <div class="title-wrapper">
        <div class="title-status-wrapper">
          <!-- <span class="title">{{project.title}}</span> -->
          <!-- <span class="title">Need a painter for my mansion, Urgent!</span> -->
          <!-- <app-project-status *ngIf="!marketplace" [project]="project"></app-project-status> -->
        </div>
        <div class="when-posted">15 Days</div>
        <div class="when-posted">15h ago</div>
      </div>
      <!-- <div class="project-type">{{project.type | enumPipe: EProjectType: true: false}}</div> -->
      <div class="project-type">Project Interior</div>

      <div class="description-res">
        <!-- <div class="two-lines-description">
          {{project.description}}</div> -->

        <div class="two-lines-description">
          Arcu integer vulputate laoreet lorem eget mauris, ligula cursus rutrum. Nisl, justo vitae massa id massa
          varius at nibh neque nunc sit. Tristique feugiat enim vitae sed pellentesque scelerisque metus, velit...</div>
      </div>
    </div>
  </div>
  <div class="details">
    <div class="section top-section">
      <div class="top-main-info">
        <div class="title-wrapper">

          <div class="title-status-wrapper">
            <span class="title">Need a painter for my mansion, Urgent!</span>
            <!-- <app-project-status *ngIf="!marketplace" [project]="project"></app-project-status> -->
          </div>
          <div class="when-posted">15 hours ago</div>
          <!-- <p>Posted <span class="when-posted">{{project.postedAgo}}</span> by Michael Holmes</p> -->

          <!-- <div class="icon_div">
            <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.5383 8.44731C19.2899 8.33248 19 8.352 18.7703 8.49733C18.5399 8.64421 18.4001 8.89888 18.4001 9.17232V10.5067C18.4001 10.9481 18.0414 11.3067 17.6001 11.3067C17.2047 11.3067 16.8703 11.0223 16.8055 10.6356C16.1547 6.60117 13.1906 0 12 0C10.8094 0 7.84533 6.60117 7.19531 10.6309C7.12971 11.0223 6.79531 11.3067 6.4 11.3067C5.95861 11.3067 5.6 10.9481 5.6 10.5067V9.17227C5.6 8.89883 5.46016 8.64416 5.22971 8.49728C4.99925 8.35195 4.71093 8.33243 4.46176 8.44725C1.73829 9.7176 0 15.2535 0 18.5067C0 23.6879 4.9344 27.3067 12 27.3067C19.0656 27.3067 24 23.6879 24 18.5067C24 15.2535 22.2617 9.7176 19.5383 8.44731Z" fill="url(#paint0_linear_3_67)"/>
              <path d="M15.8805 16.4863C15.7344 16.2504 15.4773 16.1067 15.2 16.1067H13.425L12.7758 13.5129C12.6945 13.1879 12.4203 12.9481 12.0875 12.9114C11.7477 12.8762 11.4344 13.0504 11.2844 13.3489L8.08436 19.7489C7.96014 19.9965 7.97342 20.291 8.1195 20.527C8.26558 20.7629 8.52265 20.9067 8.79998 20.9067H10.575L11.2242 23.5004C11.3054 23.8254 11.5797 24.0653 11.9125 24.102C12.2548 24.138 12.5688 23.9572 12.7156 23.6645L15.9156 17.2645C16.0398 17.0168 16.0265 16.7223 15.8805 16.4863Z" fill="url(#paint1_linear_3_67)"/>
              <defs>
              <linearGradient id="paint0_linear_3_67" x1="12" y1="27.3067" x2="12" y2="0" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FD5900"/>
              <stop offset="1" stop-color="#FFDE00"/>
              </linearGradient>
              <linearGradient id="paint1_linear_3_67" x1="12" y1="24.1066" x2="12" y2="12.9069" gradientUnits="userSpaceOnUse">
              <stop stop-color="#FFE59A"/>
              <stop offset="1" stop-color="#FFFFD5"/>
              </linearGradient>
              </defs>
            </svg>              
          </div> -->
        </div>
        <div class="project-type">Project Interior</div>
      </div>
    </div>

    <div class="section description">
      <div class="two-lines-description">
        Arcu integer vulputate laoreet lorem eget mauris, ligula cursus rutrum. Nisl, justo vitae
        varius at nibh neque nunc sit. Tristique feugiat enim vitae sed pellentesque scelerisque...
      </div>
    </div>
    <div class="section bottom">
      <div class="tags">
        <div class="tag-wrapper bid ylo-filled">
          <div class="tag ">In Progress</div>
        </div>
        <div class="tag-wrapper bid">
          <div class="tag">Wallpaper</div>
        </div>
        <div class="tag-wrapper bid">
          <div class="tag">Four rooms</div>
        </div>
        <div class="tag-wrapper bid">
          <div class="tag">Medium area</div>
        </div>
        <div class="tag-wrapper bid">
          <div class="tag">Fine condition</div>
        </div>

      </div>
      <!-- <div class="tag-wrapper bid"
          [ngClass]="{'bid-pending': bid.status === EBidStatus.PENDING, 'bid-accepted': bid.status === EBidStatus.ACCEPTED, 'bid-denied': bid.status === EBidStatus.DENIED, 'bid-deleted-expired': bid.status === EBidStatus.EXPIRED || bid.status === EBidStatus.DELETED}"
          *ngIf="bid != null && !showProjectStatus">
          <div class="tag">{{bid.status | enumPipe: EBidStatus: true}}</div>
        </div> -->
      <!-- <div class="tag-wrapper hint-bids" *ngIf="project.howManyBids === 0 && !showProjectStatus">
          <div class="tag">No bids yet</div>
        </div> -->
      <!-- <div class="tag-wrapper hint-bids" *ngIf="project.howManyBids == 1 && !showProjectStatus">
          <div class="tag">{{project.howManyBids}} bid</div>
        </div> -->
      <!-- <div class="tag-wrapper hint-bids" *ngIf="project.howManyBids > 1 && !showProjectStatus">
          <div class="tag">{{project.howManyBids}} bids</div>
        </div> -->
      <!-- <div class="tag-wrapper" *ngFor="let tag of project.tags | keyvalue">
          <div class="tag">
            {{tag.value | tagPipe: tag.key}}
          </div>
        </div> -->
      <!-- </div> -->
      <!-- <div class="icons" *ngIf="!marketplace">
        <div class="tooltip" *ngIf="inputData.status === EProjectStatus.PENDING">
          <nb-icon class="icon" icon="archive-outline" (click)="archive($event)"></nb-icon>
          <span class="tooltiptext">Archive</span>
        </div>
        <div class="tooltip" *ngIf="inputData.status === EProjectStatus.PENDING">
          <nb-icon class="icon" icon="trash-2-outline" (click)="delete($event)"
            *ngIf="inputData.status === EProjectStatus.PENDING"></nb-icon>
          <span class="tooltiptext">Delete</span>
        </div>

      </div> -->

    </div>
    <!-- <div class="location-wrapper">
      <div class="location-icon"><nb-icon icon="navigation-2-outline"></nb-icon></div>
      <div>{{project.customerCity}}, {{project.customerState}} {{project.customerZipCode}}</div>
      <div class="circle"></div>
      <div>{{project.distance}} miles away</div>
    </div> -->

    <ng-content select="[painter-perspective]"></ng-content>
  </div>
</div>
<app-modal [displayModal]="displayModal" [title]="'Modify project status'" [subclaim]="modalClaim"
  (confirmButtonClicked)="modifyStatus()" (cancelButtonClicked)="displayModal = false">
</app-modal>