import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EPostingNewProjectWay} from '../../../../model/enums/EPostingNewProjectWay';
import {EProjectType, getRedirectLinkForProjectType} from '../../../../model/enums/EProjectType';
import {WhiteSpaceValidator} from '../../../../_helpers/white-space.validator';
import {ProjectService} from '../../../../services/project.service';
import {ProjectRequest} from '../../../../model/ProjectRequest';
import {EProjectCreatorInputType, ProjectCreatorsComponent} from '../../../../components/MAIN_COMPONENTS/ProjectCreatorsComponent';
import {EJobType} from '../../../../model/enums/EJobType';
import {CreatorSection} from '../../../../model/CreatorSection';
import {BrushNotification} from '../../../../services/notification-service';
import {MessageService} from '../../../../services/message.service';
import {TokenStorageService} from '../../../../services/token-storage.service';
import { SelectInputOption } from 'src/app/model/components/SelectInputOption';
import {HttpserviceService} from '../../../../services/httpservice.service';

enum OrderingFunctionEnum {
  SORT_DATE_DESC,
  SORT_DATE_ASC,
  SORT_PRICE_ASC,
  SORT_PRICE_DESC
}


@Component({
  selector: 'app-create-new-project',
  templateUrl: './create-new-project.component.html',
  styleUrls: ['./create-new-project.component.scss'],
})
export class CreateNewProjectComponent extends ProjectCreatorsComponent {
filesn: any=[]
n: any =[]
proppaint: any
newwalls: any=[]
room: any = "Select"
  orderOptions: SelectInputOption[] = [
    {
      optionLabel: '1',
      optionValue: OrderingFunctionEnum.SORT_DATE_DESC,
      sortDir: '',
      optionSelected: true,
      optionDisabled: false
    },
    {
      optionLabel: '2',
      optionValue: OrderingFunctionEnum.SORT_DATE_ASC,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    },
    {
      optionLabel: '3',
      optionValue: OrderingFunctionEnum.SORT_PRICE_ASC,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    },
    {
      optionLabel: '4',
      optionValue: OrderingFunctionEnum.SORT_PRICE_DESC,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    }
  ];


  selectedItem = '2';
  EPostingNewProjectWay = EPostingNewProjectWay;
  EProjectCreatorInputType = EProjectCreatorInputType;

  estimatedCost = '';

  /* view flags */
  creatorSelectorVisible = true;
  basicCreatorVisible!: boolean;
  projectTypeSelectorVisible = true;
  displayingPages = false;
  actualCreatorContent = false;
  projectDetailedInfoVisible = false;
  projectSummaryVisible = false;

  /* navigation flags */
  postProjectVisible = false;
  nextButtonDisabled = false;
  nextButtonVisible = false;
  headerVisible = false;

  newProjectForm = this.formBuilder.group({
    creatorType: [EPostingNewProjectWay, []],
    projectType: [EProjectType, [Validators.required]],
    rooms: this.formBuilder.array([]),
    walls: this.formBuilder.array([])
  });
  projectDetailsForm = this.formBuilder.group({
    description: ['', [Validators.required, Validators.minLength(5), WhiteSpaceValidator]],
    title: ['', [Validators.required, Validators.minLength(5)]],
    images: this.formBuilder.array([]),
    startDate: ['', [Validators.required]],
    dueDate: ['', [Validators.required]],
  });

  constructor(private messageService: MessageService, private tokenStorage: TokenStorageService, private router: Router, private formBuilder: FormBuilder, private projectService: ProjectService, private servicehttp: HttpserviceService) {
    super();
    console.log(this.EProjectCreatorInputType, this.pages)
  }

  /* choosing posting type */
  choosePostingWay(postingNewProjectWay: EPostingNewProjectWay): void {
  console.log('this.post', EPostingNewProjectWay)
    this.creatorType.value = postingNewProjectWay;
    this.creatorSelectorVisible = false;
    this.basicCreatorVisible = (postingNewProjectWay === EPostingNewProjectWay.BASIC_WAY);
    this.navigationButtonNextBasedOnPage();
  }

  /* choosing project type */
  chooseProjectType(projectType: string): void {
  console.log(projectType)
    this.projectType.value = projectType;
      console.log(this.projectType.value)

    this.prepareData(projectType, this.basicCreatorVisible);
    console.log(projectType, this.basicCreatorVisible)
    this.navigateForward();
  }

  /* choosing job type for PROJECT EXTERIOR ADVANCED*/
  chooseJobType(jobType: string): void {
  console.log(jobType)
    this.chosenJobType = EJobType[jobType];
    this.prepareData(this.projectType.value, this.basicCreatorVisible, jobType);
    this.navigateForward();
  }

  /* picking 1 box out of 5 -- displaying pages*/
  changeCheckboxState(checkboxValue: any, sectionIndex: number, section: CreatorSection): void {
      console.log(this.pages[sectionIndex].minimumChecked, this.pages[sectionIndex].multipleChoice, checkboxValue)

    if (section.additionalInputId) {
      const el = document.getElementById(section.additionalInputId as string) as HTMLInputElement;
      if (el != null) {
        el.value = '';
      }
    }
    if (this.pages[sectionIndex].multipleChoice) {
      this.pickLabel(checkboxValue, sectionIndex);
    } else {
      this.pages[sectionIndex].minimumChecked = true;
      this.pages[sectionIndex].items.forEach(item => {
        if (item.value === checkboxValue) {
          item.selected = true;
          this.selectedValues.set(this.pages[sectionIndex].sectionKey, item.value);
        } else {
          item.selected = false;
        }
      });
      if ((this.pages[sectionIndex].minimumChecked) && !(this.pages[sectionIndex].multipleChoice)) {
        this.navigateForward();
      }
    }
  }
  change(e: any){
        console.log(e)
  }
  /* picking max 5 out of 10 or more -- lables */
  pickLabel(checkboxValue: any, sectionIndex: number): void {
    this.pages[sectionIndex].items.forEach(item => {
      if (checkboxValue === item.value) {
        item.selected = !item.selected;
      }
      if (item.selected) {
        this.selectedValues.set(item.mapLabel!, item.value);
      } else {
        this.selectedValues.delete(item.mapLabel!);
      }
    });
    let atLeastOneChecked = false;
    this.pages[sectionIndex].items.forEach(item => {
      if (item.selected) {
        atLeastOneChecked = true;
        return;
      }
    });
    this.pages[sectionIndex].minimumChecked = atLeastOneChecked;
    this.navigationButtonNextBasedOnPage();
  }

  /* building request */
  buildNewProjectRequest(): ProjectRequest {
    const out = Object.create(null);
    this.selectedValues.forEach((value, key) => {
      out[key] = value;
    });
    this.newwalls= localStorage.getItem('walls')
    this.newwalls = JSON.parse(this.newwalls)
    const newProjectRequest = {
      projectCreatorType: this.creatorType.value,
      projectType: this.projectType.value,
      projectDetails: this.projectDetailsForm.value,
      rooms: this.rooms.value,
      walls: this.newwalls,
      tags: out,
      images: this.filesn
    } as ProjectRequest;

    console.log(newProjectRequest);
    return newProjectRequest;
  }
   selectFile(event: any) {
    console.log("gfgfg",event);

    let files = event.target.files
    if (files) {
      for (let i = 0; i < files.length; i++) {
        let val = files[i];
        this.n.push(val);
        console.log(this.n);
      }
    }
    this.upload();
  }
  	uploadFile(evt: any) {
      console.log(evt.target.files)
    if (!evt.target.files.length) {
        return;
    }
	  for (var i = 0; i < evt.target.files.length; i++) { 
	              this.n.push(evt.target.files[i]);

        }
	 
		  //  this.isLoading = true;

	//const job_id = localStorage.getItem("job_id");
    const file = evt.target.files[0];
    var img_arr = file.name.split('.');
    const ext = img_arr.pop();

	   if (file.size/1024/1024 > 25) {
    //  this.isLoading = false;
	   for (var i = 0; i < evt.target.files.length; i++) { 
	              this.n.splice(evt.target.files.indexOf(i),1);

        }  
		//this.fileuploaderror= "The file should not exceed more than 25MB."
      return;
    }

        const fr = new FileReader();
        fr.readAsDataURL(file);
        const formData = new FormData();
		for (var i = 0; i < this.n.length; i++) { 
      formData.append("images",this.n[i]);

    }
		        //  formData.append('job_id',job_id);

			console.log(this.n)

		console.log(formData)
        this.servicehttp.uploadimgaes(formData).subscribe(async res => {
        let a = 'image'
        for(let i=0; i<res.imgs.length;i++){
		this.filesn.push({'name': res.imgs[i]})
        }
      
        console.log("files",this.filesn)
        
		//   console.log("post file obj =========== ",obj)
         
		  		          //  this.isLoading = false;

        },(err) => {
          this.servicehttp.handleError(err)
          console.log(err)
		  		         //   this.isLoading = false;

        })
 
	}
  upload(){
          if (this.n) {
   
      console.log(this.n)
    this.servicehttp.uploadUserMedia(this.n).subscribe((res:any) => {
    console.log(res)

    })
    
    }
  }
  /* sending request to server */
  sendNewProjectRequest(): void {
    this.projectDetailedInfoVisible = false;
    this.servicehttp.postproject(this.buildNewProjectRequest()).subscribe(response => {
      this.projectSummaryVisible = true;
      this.headerVisible = false;
      for (let i = 0; i < this.files.value.length; i++) {
        this.projectService.postProjectImage(this.files.value[i].src, response.projectId).subscribe(responsePostImage => {
        });
      }
      localStorage.removeItem('walls')
      this.postedProject = response;
      this.estimatedCost = '';
      if (this.postedProject.projectType === EProjectType.PROJECT_INTERIOR) {
        this.estimatedCost = this.postedProject.pricesForRooms != null ? this.postedProject.pricesForRooms.summary : '';
      }
    });
  }

  /* events */
  filesChanged(event: any): void {
    this.files.value = event;
    // fixme:?
    // this.files.value.push(event as ImageFile);
  }

  cancelProjectCreation(): void {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }


  /* navigation/utils */
  navigateForward(): void {
  console.log(this.currentPage)
    if (this.currentPage < this.howManyPages) {
      this.currentPage++;
      this.changeViewsBasedOnPage();
    }
  }
  first(){

  }
  navigateBack(): void {
    if (this.currentPage >= 1) {
      this.currentPage--;
      this.changeViewsBasedOnPage();
    }else{
        this.router.navigate(['/home/my-project-card'])
    }
  }

  changeViewsBasedOnPage(): void {
    this.resetFlags();
    if (this.currentPage === 0) {
      this.projectTypeSelectorVisible = true;
    }
    if (this.basicCreatorVisible) {
      if (this.currentPage >= 1 && this.currentPage <= this.pages.length) {
        this.displayingPages = true;
      }
      /* project detailed info */
      if (this.currentPage === this.pages.length + 1) {
        this.projectDetailedInfoVisible = true;
      }
    } else {
      /*  selector */
      if (this.projectAdvancedInterior) {
        if (this.currentPage >= 1 && this.currentPage <= this.pages.length) {
          this.displayingPages = true;
        }
        /* rooms page */
        if (this.currentPage === this.pages.length + 1) {
          this.actualCreatorContent = true;
          this.headerVisible = true;
        }
        /* project detailed info */
        if (this.currentPage === this.pages.length + 2) {
          this.projectDetailedInfoVisible = true;
        }
      } else if (this.projectAdvancedExterior) {
        /*  selector */
        /* job chose */
        if (this.currentPage === 1) {
          this.headerVisible = true;
          this.choosingJobType = true;
        }
        if (this.currentPage >= 2 && this.currentPage <= this.pages.length) {
          this.displayingPages = true;
        }
        /* project detailed info */
        if (this.currentPage === this.pages.length + 1) {
          this.actualCreatorContent = true;
          this.headerVisible = true;
        }
        if (this.currentPage === this.pages.length + 2){
          this.projectDetailedInfoVisible = true;
        }
      }
    }
    this.navigationButtonNextBasedOnPage();
  }

  navigationButtonNextBasedOnPage(): void {
    this.nextButtonVisible = false;
    this.postProjectVisible = false;
    if (this.basicCreatorVisible) {
      if (this.projectDetailedInfoVisible) {
        this.postProjectVisible = true;
      }
    } else {
      if (this.projectAdvancedInterior) {
        if (this.projectTypeSelectorVisible) {
          this.projectAdvancedInterior = false;
          this.projectAdvancedExterior = false;
        }
        if (this.actualCreatorContent) {
          this.postProjectVisible = false;
        }
        if (this.projectDetailedInfoVisible) {
          this.postProjectVisible = true;
        }
      } else if (this.projectAdvancedExterior) {
        if (this.projectTypeSelectorVisible) {
          this.projectAdvancedInterior = false;
          this.projectAdvancedExterior = false;
        }
      }
    }
    if (this.projectDetailedInfoVisible) {
      this.headerVisible = true;
      this.postProjectVisible = true;
    }
    if (this.displayingPages) {
      this.headerVisible = true;
      this.postProjectVisible = false;
      this.nextButtonVisible = this.pages[this.currentPage - 1].multipleChoice || this.pages[this.currentPage - 1].items.filter(item => item.selected)[0] != null;
      this.nextButtonDisabled = !this.pages[this.currentPage - 1].minimumChecked;
    }
  }

  resetFlags(): void {
    this.headerVisible = false;
    this.projectTypeSelectorVisible = false;
    this.projectDetailedInfoVisible = false;
    this.displayingPages = false;
    this.choosingJobType = false;
    this.projectSummaryVisible = false;
    this.actualCreatorContent = false;
  }

  get rooms(): any {
    return this.newProjectForm.get('rooms');
  }

    get walls(): any {
    return this.newProjectForm.get('walls');
  }

  get title(): any {
    return this.projectDetailsForm.get('title');
  }

  get projectType(): any {
    return this.newProjectForm.get('projectType');
  }

  get files(): any {
    return this.projectDetailsForm.get('images');
  }

  get creatorType(): any {
    return this.newProjectForm.get('creatorType');
  }

  characterCounterForTitle(event: any): void {
    this.projectDetailsSections.items[1].hint = event.target.value.length + ' /100 characters';
  }

  characterCounterForDescription(event: any): void {
    this.projectDetailsSections.items[2].hint = event.target.value.length + ' /400 characters';
  }
  assignWallsAndGoNextPage(walls: any): void{
   console.log(walls);


    this.navigateForward();
  }
  assignRoomsAndGoNextPage(rooms: any): void {
    console.log(rooms);
    this.rooms.value = [];
    for (const room of rooms) {
      this.rooms.value.push(room);
    }

    this.navigateForward();
  }
 
  setProjectDetailsDate(event: any): void {
    this.projectDetailsForm.get('startDate')?.setValue(event.startDate);
    this.projectDetailsForm.get('dueDate')?.setValue(event.endDate);
  }

}



