<div class="route-title nav-with-icon" *ngIf="roles.customer"><span (click)="navigateBack()">
    <nb-icon icon="arrow-back-outline"></nb-icon>&nbsp;Projects
  </span>
  <app-notification-bell></app-notification-bell>
</div>
<!-- <div class="route-title nav-with-icon" *ngIf="roles.painter"><span (click)="navigateBack()"><nb-icon icon="arrow-back-outline"></nb-icon>&nbsp;Marketplace</span><app-notification-bell></app-notification-bell></div> -->
<div class="route-title nav-with-icon" *ngIf="roles.painter"><span (click)="navigateBack()">
    <nb-icon icon="arrow-back-outline"></nb-icon>&nbsp; <a routerLink="/home/marketplace"> Project Details </a>
  </span>
  <app-notification-bell></app-notification-bell>
</div>
<div class="route-title nav-with-icon" *ngIf="roles.admin"><span (click)="navigateBack()">
    <nb-icon icon="arrow-back-outline"></nb-icon>&nbsp;Admin dashboard
  </span>
  <!-- <app-notification-bell></app-notification-bell> -->
</div>
<div *ngIf="projectDetails != null" class="main-wrapper">
  <nb-card class="nb-theme-brush card">
    <nb-card-body>
      <nb-tabset #tabset>

        <nb-tab tabTitle="Destails" [active]="isMainActive">
          <div class="tab-content-wrapper">
            <app-project-main-details [project]="projectDetails"></app-project-main-details>
          </div>
        </nb-tab>

        <nb-tab tabTitle="Bids" *ngIf="!tokenStorage.isUserPainter()" #bidTabset>
          <app-bids [project]="project" (bidStatusChanged)="actionOnBidStatusChange()"></app-bids>
        </nb-tab>
        <nb-tab tabTitle="Rooms" [active]="isMainActive" class="room-tab"
          *ngIf="project?.projectCreatorType == EPostingNewProjectWay.ALGORITHM_WAY">
          <div class="rooms">
            <app-room-card-details *ngFor="let room of project.rooms" [room]="room" [pricesVisible]="roles.customer">
            </app-room-card-details>
          </div>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </nb-card>
</div>