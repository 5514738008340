<div class="smooth-scroll">
    <app-header [userTheme]="true"></app-header>
  
   <section class="terms_sec contact_us_sec about_us_hero_sec" style="background-image: url({{data.img1}});">
        <div class="overlay_sec">
            <div class="container">
                <div class="row">
                    <div class="col-md-8">
                        <div class="banner_cnt">
                            <h1>
                                {{data.heading1}}
                            </h1>
                            <p>
                                {{data.description1}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="elementor-shape elementor-shape-bottom">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.5 27.8" preserveAspectRatio="none">
                <path class="elementor-shape-fill" d="M283.5,9.7c0,0-7.3,4.3-14,4.6c-6.8,0.3-12.6,0-20.9-1.5c-11.3-2-33.1-10.1-44.7-5.7	s-12.1,4.6-18,7.4c-6.6,3.2-20,9.6-36.6,9.3C131.6,23.5,99.5,7.2,86.3,8c-1.4,0.1-6.6,0.8-10.5,2c-3.8,1.2-9.4,3.8-17,4.7	c-3.2,0.4-8.3,1.1-14.2,0.9c-1.5-0.1-6.3-0.4-12-1.6c-5.7-1.2-11-3.1-15.8-3.7C6.5,9.2,0,10.8,0,10.8V0h283.5V9.7z M260.8,11.3	c-0.7-1-2-0.4-4.3-0.4c-2.3,0-6.1-1.2-5.8-1.1c0.3,0.1,3.1,1.5,6,1.9C259.7,12.2,261.4,12.3,260.8,11.3z M242.4,8.6	c0,0-2.4-0.2-5.6-0.9c-3.2-0.8-10.3-2.8-15.1-3.5c-8.2-1.1-15.8,0-15.1,0.1c0.8,0.1,9.6-0.6,17.6,1.1c3.3,0.7,9.3,2.2,12.4,2.7	C239.9,8.7,242.4,8.6,242.4,8.6z M185.2,8.5c1.7-0.7-13.3,4.7-18.5,6.1c-2.1,0.6-6.2,1.6-10,2c-3.9,0.4-8.9,0.4-8.8,0.5	c0,0.2,5.8,0.8,11.2,0c5.4-0.8,5.2-1.1,7.6-1.6C170.5,14.7,183.5,9.2,185.2,8.5z M199.1,6.9c0.2,0-0.8-0.4-4.8,1.1	c-4,1.5-6.7,3.5-6.9,3.7c-0.2,0.1,3.5-1.8,6.6-3C197,7.5,199,6.9,199.1,6.9z M283,6c-0.1,0.1-1.9,1.1-4.8,2.5s-6.9,2.8-6.7,2.7	c0.2,0,3.5-0.6,7.4-2.5C282.8,6.8,283.1,5.9,283,6z M31.3,11.6c0.1-0.2-1.9-0.2-4.5-1.2s-5.4-1.6-7.8-2C15,7.6,7.3,8.5,7.7,8.6	C8,8.7,15.9,8.3,20.2,9.3c2.2,0.5,2.4,0.5,5.7,1.6S31.2,11.9,31.3,11.6z M73,9.2c0.4-0.1,3.5-1.6,8.4-2.6c4.9-1.1,8.9-0.5,8.9-0.8	c0-0.3-1-0.9-6.2-0.3S72.6,9.3,73,9.2z M71.6,6.7C71.8,6.8,75,5.4,77.3,5c2.3-0.3,1.9-0.5,1.9-0.6c0-0.1-1.1-0.2-2.7,0.2	C74.8,5.1,71.4,6.6,71.6,6.7z M93.6,4.4c0.1,0.2,3.5,0.8,5.6,1.8c2.1,1,1.8,0.6,1.9,0.5c0.1-0.1-0.8-0.8-2.4-1.3	C97.1,4.8,93.5,4.2,93.6,4.4z M65.4,11.1c-0.1,0.3,0.3,0.5,1.9-0.2s2.6-1.3,2.2-1.2s-0.9,0.4-2.5,0.8C65.3,10.9,65.5,10.8,65.4,11.1	z M34.5,12.4c-0.2,0,2.1,0.8,3.3,0.9c1.2,0.1,2,0.1,2-0.2c0-0.3-0.1-0.5-1.6-0.4C36.6,12.8,34.7,12.4,34.5,12.4z M152.2,21.1	c-0.1,0.1-2.4-0.3-7.5-0.3c-5,0-13.6-2.4-17.2-3.5c-3.6-1.1,10,3.9,16.5,4.1C150.5,21.6,152.3,21,152.2,21.1z"></path>
                <path class="elementor-shape-fill" d="M269.6,18c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C267.7,18.8,269.7,18,269.6,18z"></path>
                <path class="elementor-shape-fill" d="M227.4,9.8c-0.2-0.1-4.5-1-9.5-1.2c-5-0.2-12.7,0.6-12.3,0.5c0.3-0.1,5.9-1.8,13.3-1.2	S227.6,9.9,227.4,9.8z"></path>
                <path class="elementor-shape-fill" d="M204.5,13.4c-0.1-0.1,2-1,3.2-1.1c1.2-0.1,2,0,2,0.3c0,0.3-0.1,0.5-1.6,0.4	C206.4,12.9,204.6,13.5,204.5,13.4z"></path>
                <path class="elementor-shape-fill" d="M201,10.6c0-0.1-4.4,1.2-6.3,2.2c-1.9,0.9-6.2,3.1-6.1,3.1c0.1,0.1,4.2-1.6,6.3-2.6	S201,10.7,201,10.6z"></path>
                <path class="elementor-shape-fill" d="M154.5,26.7c-0.1-0.1-4.6,0.3-7.2,0c-7.3-0.7-17-3.2-16.6-2.9c0.4,0.3,13.7,3.1,17,3.3	C152.6,27.5,154.6,26.8,154.5,26.7z"></path>
                <path class="elementor-shape-fill" d="M41.9,19.3c0,0,1.2-0.3,2.9-0.1c1.7,0.2,5.8,0.9,8.2,0.7c4.2-0.4,7.4-2.7,7-2.6	c-0.4,0-4.3,2.2-8.6,1.9c-1.8-0.1-5.1-0.5-6.7-0.4S41.9,19.3,41.9,19.3z"></path>
                <path class="elementor-shape-fill" d="M75.5,12.6c0.2,0.1,2-0.8,4.3-1.1c2.3-0.2,2.1-0.3,2.1-0.5c0-0.1-1.8-0.4-3.4,0	C76.9,11.5,75.3,12.5,75.5,12.6z"></path>
                <path class="elementor-shape-fill" d="M15.6,13.2c0-0.1,4.3,0,6.7,0.5c2.4,0.5,5,1.9,5,2c0,0.1-2.7-0.8-5.1-1.4	C19.9,13.7,15.7,13.3,15.6,13.2z"></path>
            </svg> -->
        </div>
   </section>
   <section class="all_in_one_sec">
        <div class="all_in_one">
            <h2>
               {{data.heading2}}
            </h2>
            <div class="row">
                <div class="col-md-4">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <path style="fill:#3A4D51;" d="M256.013,320.663c-4.136,0-7.487-3.352-7.487-7.487v-82.75c0-11.397,8.537-20.988,19.857-22.31  l222.024-25.927c3.773-0.44,6.619-3.638,6.619-7.436V88.999c0-4.128-3.358-7.487-7.487-7.487H464.54  c-4.136,0-7.487-3.352-7.487-7.487c0-4.135,3.351-7.487,7.487-7.487h24.998c12.385,0,22.462,10.076,22.462,22.462v85.752  c0,11.397-8.537,20.988-19.857,22.31L270.12,222.989c-3.773,0.44-6.619,3.638-6.619,7.436v82.75  C263.501,317.31,260.15,320.663,256.013,320.663z"></path>
                        <path style="fill:#FF434D;" d="M209.682,132.762H68.115c-4.136,0-7.487,3.352-7.487,7.487v120.512  c0,17.266,14.047,31.313,31.313,31.313s31.312-14.047,31.312-31.313v-35.546c0-8.195,6.668-14.863,14.864-14.863  c8.195,0,14.863,6.668,14.863,14.863v17.145c0,17.696,14.398,32.094,32.094,32.094c17.697,0,32.094-14.398,32.094-32.094V140.25  C217.169,136.115,213.818,132.762,209.682,132.762z"></path>
                        <path style="fill:#DD3646;" d="M209.682,132.762H68.115c-4.136,0-7.487,3.352-7.487,7.487v23.96h156.541v-23.96  C217.169,136.115,213.818,132.762,209.682,132.762z"></path>
                        <path style="fill:#FF6137;" d="M32.755,33.571H7.487C3.351,33.571,0,36.924,0,41.059v65.93c0,4.135,3.351,7.487,7.487,7.487h25.267  c4.136,0,7.487-3.352,7.487-7.487v-65.93C40.242,36.924,36.89,33.571,32.755,33.571z"></path>
                        <path style="fill:#FFD039;" d="M449.565,0.672H47.73c-12.385,0-22.462,10.076-22.462,22.462v101.779  c0,12.386,10.077,22.462,22.462,22.462h401.837c12.385,0,22.462-10.076,22.462-22.462V23.134  C472.027,10.748,461.95,0.672,449.565,0.672z"></path>
                        <path style="fill:#FFAE47;" d="M449.565,115.055H47.73c-12.385,0-22.462-10.076-22.462-22.462v32.321  c0,12.386,10.077,22.462,22.462,22.462h401.837c12.385,0,22.462-10.076,22.462-22.462V92.593  C472.027,104.979,461.95,115.055,449.565,115.055z"></path>
                        <path style="fill:#546F7A;" d="M288.227,305.688h-62.912c-4.136,0-7.487,3.352-7.487,7.487v159.211  c0,21.473,17.47,38.942,38.943,38.942s38.943-17.47,38.943-38.942V313.175C295.715,309.04,292.362,305.688,288.227,305.688z"></path>
                        <path style="fill:#475D63;" d="M288.227,305.688h-31.798c4.136,0,7.487,3.352,7.487,7.487v159.211  c0,15.811-9.477,29.441-23.044,35.535c4.859,2.182,10.237,3.407,15.899,3.407c21.474,0,38.943-17.47,38.943-38.942V313.175  C295.715,309.04,292.362,305.688,288.227,305.688z"></path>
                        <g>
                        <path style="fill:#FFD039;" d="M409.082,379.917l-18.22-8.166c-5.876-2.634-10.562-7.321-13.197-13.196l-8.166-18.22   c-1.206-2.692-3.882-4.426-6.832-4.426s-5.626,1.733-6.832,4.425l-8.168,18.221c-2.633,5.876-7.319,10.562-13.195,13.196   l-18.22,8.166c-2.692,1.207-4.426,3.882-4.426,6.832c0,2.95,1.732,5.626,4.426,6.832l18.22,8.166   c5.876,2.634,10.562,7.32,13.196,13.197l8.167,18.22c1.207,2.692,3.882,4.425,6.832,4.425s5.626-1.733,6.832-4.426l8.166-18.22   c2.635-5.876,7.321-10.563,13.197-13.196l18.22-8.166c2.692-1.207,4.426-3.882,4.426-6.832   C413.507,383.799,411.774,381.124,409.082,379.917z"></path>
                        <path style="fill:#FFD039;" d="M506.962,259.117l-18.22-8.166c-5.876-2.634-10.562-7.32-13.197-13.196l-8.166-18.22   c-1.206-2.692-3.882-4.426-6.832-4.426s-5.626,1.733-6.832,4.426l-8.166,18.22c-2.635,5.876-7.321,10.562-13.197,13.196   l-18.22,8.166c-2.692,1.207-4.426,3.882-4.426,6.832s1.732,5.626,4.426,6.832l18.22,8.166c5.876,2.634,10.562,7.32,13.197,13.196   l8.166,18.22c1.206,2.692,3.882,4.426,6.832,4.426s5.626-1.733,6.832-4.426l8.166-18.22c2.635-5.876,7.321-10.563,13.197-13.196   l18.22-8.166c2.692-1.207,4.426-3.882,4.426-6.832C511.387,263,509.655,260.324,506.962,259.117z"></path>
                        </g>
                        <path style="fill:#FF434D;" d="M97.345,304.875c-1.422-1.639-3.485-2.581-5.655-2.581c-2.17,0-4.234,0.941-5.655,2.581  c-4.379,5.048-42.772,50.021-42.772,74.713c0,26.703,21.724,48.427,48.427,48.427s48.428-21.724,48.428-48.427  C140.118,354.896,101.724,309.922,97.345,304.875z"></path>
                        <path style="fill:#DD3646;" d="M97.345,304.875c-1.422-1.639-3.485-2.581-5.655-2.581c-2.17,0-4.234,0.941-5.655,2.581  c-1.249,1.44-5.269,6.133-10.375,12.675c12.788,16.386,32.398,44.39,32.398,62.038c0,21.085-13.551,39.057-32.398,45.689  c5.021,1.767,10.412,2.738,16.03,2.738c26.703,0,48.428-21.724,48.428-48.427C140.118,354.896,101.724,309.922,97.345,304.875z"></path>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                    </svg> -->

                    <img src="{{data.img2}}" alt="">
                    <h3>
                        {{data.heading3}}
                    </h3>
                    <p class="light_txt">
                        {{data.description2}}
                    </p>
                    <!-- <a class="button_theme">View more</a> -->
                </div>
                <div class="col-md-4">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <rect x="17.895" y="263.55" style="fill:#FFB125;" width="244.15" height="216.86"></rect>
                        <rect x="173.7" y="263.55" style="fill:#FF901D;" width="88.34" height="216.86"></rect>
                        <path style="fill:#AF8A82;" d="M240,256.634l-118.223-53.806c-1.129-0.514-2.425-0.514-3.554,0L0,256.634v12.15h240V256.634z"></path>
                        <g>
                        <rect x="234.16" y="39.821" style="fill:#FFB125;" width="42.09" height="62.812"></rect>
                        <path style="fill:#FFB125;" d="M281.369,46.124h-52.331c-1.785,0-3.232-1.447-3.232-3.232V27.453c0-1.785,1.447-3.232,3.232-3.232   h52.331c1.785,0,3.232,1.447,3.232,3.232v15.439C284.6,44.677,283.153,46.124,281.369,46.124z"></path>
                        </g>
                        <polygon style="fill:#FF4C4C;" points="469.868,119.48 345.984,37.071 222.099,119.48 222.099,480.405 469.924,480.405   469.924,119.48 "></polygon>
                        <polygon style="fill:#F33A3A;" points="469.868,119.48 345.984,37.071 321.784,53.169 421.469,119.48 421.525,119.48   421.525,480.405 469.924,480.405 469.924,119.48 "></polygon>
                        <path style="fill:#AF8A82;" d="M198.932,140.979l144.985-96.445c1.269-0.844,2.92-0.844,4.189,0l144.985,96.445  c4.083,2.716,9.397,2.716,13.48,0l0,0c7.239-4.815,7.239-15.449,0-20.264L348.106,15.303c-1.269-0.844-2.92-0.844-4.189,0  L185.452,120.715c-7.239,4.815-7.239,15.449,0,20.264l0,0C189.535,143.695,194.849,143.695,198.932,140.979z"></path>
                        <path style="fill:#927266;" d="M483.255,256.626H0v21.896c0,1.786,1.448,3.234,3.234,3.234h480.021c1.786,0,3.234-1.448,3.234-3.234  V259.86C486.489,258.073,485.041,256.626,483.255,256.626z"></path>
                        <path style="fill:#E1EFFB;" d="M177.319,345.019H62.681c-6.196,0-11.219,5.023-11.219,11.219v21.264h137.076v-21.264  C188.538,350.042,183.515,345.019,177.319,345.019z"></path>
                        <rect x="51.46" y="377.5" style="fill:#CCDFED;" width="137.08" height="32.483"></rect>
                        <rect x="51.46" y="409.99" style="fill:#E1EFFB;" width="137.08" height="32.483"></rect>
                        <rect x="51.46" y="442.47" style="fill:#CCDFED;" width="137.08" height="32.483"></rect>
                        <path style="fill:#FFB125;" d="M326.671,357.854h-61.339c-3.445,0-6.237,2.793-6.237,6.237v116.313h73.813V364.092  C332.908,360.647,330.116,357.854,326.671,357.854z"></path>
                        <path style="fill:#FFFFFF;" d="M449.338,436.363h-88.634v-63.702c0-2.842,2.304-5.146,5.146-5.146h78.342  c2.842,0,5.146,2.304,5.146,5.146V436.363z"></path>
                        <path style="fill:#E6E6E6;" d="M444.192,367.515h-39.171v68.848h44.317v-63.702C449.338,369.819,447.034,367.515,444.192,367.515z"></path>
                        <path style="fill:#F33A3A;" d="M452.575,425.144h-95.107c-4.601,0-8.33,3.73-8.33,8.33s3.73,8.33,8.33,8.33h95.107  c4.601,0,8.33-3.73,8.33-8.33S457.176,425.144,452.575,425.144z"></path>
                        <path style="fill:#FFFFFF;" d="M325.007,209.658h-63.344V141.45c0-1.712,1.388-3.1,3.1-3.1h57.145c1.712,0,3.1,1.388,3.1,3.1v68.208  H325.007z"></path>
                        <path style="fill:#E6E6E6;" d="M321.907,138.351h-28.572v71.307h31.672v-68.207C325.007,139.739,323.619,138.351,321.907,138.351z"></path>
                        <path style="fill:#F33A3A;" d="M325.553,197.364h-64.435c-4.601,0-8.33,3.73-8.33,8.33c0,4.601,3.73,8.33,8.33,8.33h64.435  c4.601,0,8.33-3.73,8.33-8.33C333.883,201.093,330.153,197.364,325.553,197.364z"></path>
                        <path style="fill:#FFFFFF;" d="M432.924,209.658H369.58V141.45c0-1.712,1.388-3.1,3.1-3.1h57.145c1.712,0,3.1,1.388,3.1,3.1  L432.924,209.658L432.924,209.658z"></path>
                        <path style="fill:#E6E6E6;" d="M429.825,138.351h-28.572v71.307h31.672v-68.207C432.924,139.739,431.536,138.351,429.825,138.351z"></path>
                        <path style="fill:#F33A3A;" d="M433.47,197.364h-64.435c-4.601,0-8.33,3.73-8.33,8.33c0,4.601,3.73,8.33,8.33,8.33h64.435  c4.601,0,8.33-3.73,8.33-8.33C441.8,201.093,438.07,197.364,433.47,197.364z"></path>
                        <path style="fill:#77DD00;" d="M499.268,472.947H12.732c-6.733,0-12.192,5.458-12.192,12.192l0,0  c0,6.733,5.458,12.192,12.192,12.192h486.537c6.733,0,12.192-5.458,12.192-12.192l0,0  C511.46,478.405,506.002,472.947,499.268,472.947z"></path>
                        <path style="fill:#876960;" d="M483.255,256.625H438.09v25.13h45.165c1.786,0,3.234-1.448,3.234-3.234v-18.662  C486.489,258.073,485.041,256.625,483.255,256.625z"></path>
                        <path style="fill:#6EC102;" d="M511.393,483.866c-0.005-0.052-0.017-0.101-0.023-0.153c-0.043-0.369-0.098-0.735-0.173-1.093  c-0.004-0.019-0.01-0.037-0.014-0.056c-0.171-0.796-0.42-1.562-0.739-2.292v-0.001l0,0c-1.88-4.309-6.173-7.324-11.175-7.324  h-36.208v2.558c0,6.677-5.413,12.09-12.09,12.09H12.732c-5.002,0-9.295-3.015-11.175-7.324c-0.849,1.947-1.214,4.153-0.912,6.474  c0.795,6.125,6.242,10.585,12.418,10.585h449.998h35.415c5.204,0,10.074-2.977,12.089-7.774  C511.399,487.571,511.58,485.664,511.393,483.866z"></path>
                        <path style="fill:#FF901D;" d="M317.064,401.939c-4.36,0-7.893,3.534-7.893,7.893v13.682c0,4.359,3.533,7.893,7.893,7.893  s7.893-3.534,7.893-7.893v-13.682C324.958,405.473,321.424,401.939,317.064,401.939z"></path>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        </svg> -->

                        <img src="{{data.img3}}" alt="">
                    <h3>
                       {{data.heading4}}
                    </h3>
                    <p class="light_txt">
                      {{data.description3}}
                    </p>
                    <!-- <a class="button_theme">View more</a> -->
                </div>
                <div class="col-md-4">
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1" x="0px" y="0px" viewBox="0 0 464 464" style="enable-background:new 0 0 464 464;" xml:space="preserve">
                        <path style="fill:#FDBD40;" d="M144,48h176v400H144V48z"></path>
                        <path style="fill:#5A6470;" d="M208,352h48v96h-48V352z"></path>
                        <path style="fill:#A7C6D3;" d="M0,224h144v208H0V224z"></path>
                        <path style="fill:#CFDFE8;" d="M16,128h96v96H16V128z"></path>
                        <path style="fill:#A7C6D3;" d="M32,72h64v56H32V72z"></path>
                        <path style="fill:#5A6470;" d="M56,16h16v56H56V16z"></path>
                        <path style="fill:#E9686A;" d="M320,224h144v208H320V224z"></path>
                        <path style="fill:#F98C96;" d="M448,224h-96V72l96,32V224z"></path>
                        <g>
                        <path style="fill:#5A6470;" d="M376,120h16v104h-16V120z"></path>
                        <path style="fill:#5A6470;" d="M408,120h16v104h-16V120z"></path>
                        <path style="fill:#5A6470;" d="M24,248h96v16H24V248z"></path>
                        <path style="fill:#5A6470;" d="M24,280h96v16H24V280z"></path>
                        <path style="fill:#5A6470;" d="M24,312h96v16H24V312z"></path>
                        <path style="fill:#5A6470;" d="M24,344h96v16H24V344z"></path>
                        <path style="fill:#5A6470;" d="M184,96h32v32h-32V96z"></path>
                        <path style="fill:#5A6470;" d="M248,96h32v32h-32V96z"></path>
                        <path style="fill:#5A6470;" d="M184,160h32v32h-32V160z"></path>
                        <path style="fill:#5A6470;" d="M248,160h32v32h-32V160z"></path>
                        <path style="fill:#5A6470;" d="M184,224h32v32h-32V224z"></path>
                        <path style="fill:#5A6470;" d="M248,224h32v32h-32V224z"></path>
                        <path style="fill:#5A6470;" d="M184,288h32v32h-32V288z"></path>
                        <path style="fill:#5A6470;" d="M248,288h32v32h-32V288z"></path>
                        <path style="fill:#5A6470;" d="M368,384h48v48h-48V384z"></path>
                        <path style="fill:#5A6470;" d="M344,248h16v16h-16V248z"></path>
                        <path style="fill:#5A6470;" d="M376,248h32v16h-32V248z"></path>
                        <path style="fill:#5A6470;" d="M424,248h16v16h-16V248z"></path>
                        <path style="fill:#5A6470;" d="M344,280h16v16h-16V280z"></path>
                        <path style="fill:#5A6470;" d="M376,280h32v16h-32V280z"></path>
                        <path style="fill:#5A6470;" d="M424,280h16v16h-16V280z"></path>
                        <path style="fill:#5A6470;" d="M344,312h16v16h-16V312z"></path>
                        <path style="fill:#5A6470;" d="M376,312h32v16h-32V312z"></path>
                        <path style="fill:#5A6470;" d="M424,312h16v16h-16V312z"></path>
                        <path style="fill:#5A6470;" d="M344,344h16v16h-16V344z"></path>
                        <path style="fill:#5A6470;" d="M376,344h32v16h-32V344z"></path>
                        <path style="fill:#5A6470;" d="M424,344h16v16h-16V344z"></path>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        </svg> -->

                        <img src="{{data.img4}}" alt="">
                    <h3>
                        {{data.heading5}}
                    </h3>
                    <p class="light_txt">
                        {{data.description4}}
                    </p>
                    <!-- <a class="button_theme">View more</a> -->
                </div>
            </div>
        </div>
   </section>
   <section class="pink_bckgrd_sec" [style.background-image]="'url(' + data.img5 + ')'">
    <div class="white_layer">
        <div class="pink_sec">
             <h1>
                {{data.heading6}}
             </h1>
             <p class="ht_txt">
                 {{data.description5}}
             </p>
        </div>
    </div>
   </section>
   
   <section>
        <div class="about_us_sec">
            <div class="row">
                <div class="col-md-4">
                    <img src="{{data.img6}}" class="error-icon side_img">
                </div>
                <div class="col-md-8">
                    <div class="">
                        <h1>
                          {{data.heading7}}
                        </h1>
                    </div>
                    <div class="address_inCard">
                        <h3 class="">
                           {{data.heading8}}
                        </h3>
                        <p class="light_txt">
                        {{data.description6}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
   </section>
    <app-footer></app-footer>
  </div>
  