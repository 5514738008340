import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {Router} from '@angular/router';
import {BaseUserInputDataComponent} from '../../../components/MAIN_COMPONENTS/BaseUserInputDataComponent';
    import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseUserInputDataComponent implements OnInit {

  userDataForm = this.formBuilder.group({
    emailAddress: ['', [Validators.required]],
  });
  isFormSubmitted = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private authService: AuthService,  private toastr: ToastrService) {
    super();
  }

  ngOnInit(): void {
  }

  submitForm(): void {
   
    const email = this.emailAddress.value;
    let obj={
        email: this.emailAddress.value
    }
    this.authService.forgot(obj).subscribe(response => {
     this.isFormSubmitted = true;
      this.toastr.success('Email sent!', 'Success!');
      },
      error => {
       this.toastr.error(error.error.errors.msg, 'Error!');
        console.log(error.message);
      }
    );
  }

  goBackToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  onEnterClicked(): void {
    if (this.userDataForm.valid) {
      this.submitForm();
    }
  }
}
