import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ENotificationType} from '../model/enums/ENotificationType';
import {Breakpoints} from '@angular/cdk/layout';

export class BrushNotification {
  public static readonly NEW_BID_NOTIFICATION_CUSTOMER = new BrushNotification('New bid', 'You have received new bid for your project ${arg}', ENotificationType.NEW_BID_NOTIFICATION_CUSTOMER);
  public static readonly NEW_BID_NOTIFICATION_PAINTER = new BrushNotification('New bid', 'You have posted new bid for project ${arg}', ENotificationType.NEW_BID_NOTIFICATION_PAINTER);
  public static readonly BID_ACCEPTED_NOTIFICATION_CUSTOMER = new BrushNotification('Bid accepted', 'You have accepted bid for your project ${arg}', ENotificationType.BID_ACCEPTED_NOTIFICATION_CUSTOMER);
  public static readonly BID_ACCEPTED_NOTIFICATION_PAINTER = new BrushNotification('Bid accepted', 'Your bid for project ${arg} was accepted', ENotificationType.BID_ACCEPTED_NOTIFICATION_PAINTER);
  public static readonly BID_CANCELED_NOTIFICATION_CUSTOMER = new BrushNotification('Bid cancelled', 'Unfortunately a bid for your project ${arg} was cancelled', ENotificationType.BID_CANCELED_NOTIFICATION_CUSTOMER);
  public static readonly BID_CANCELED_NOTIFICATION_PAINTER = new BrushNotification('Bid cancelled', 'You have cancelled a bid for project ${arg}', ENotificationType.BID_CANCELED_NOTIFICATION_PAINTER);
  public static readonly BID_DENIED_NOTIFICATION_PAINTER = new BrushNotification('Bid denied', 'Unfortunately your bid for project ${arg} was denied', ENotificationType.BID_DENIED_NOTIFICATION_PAINTER);
  public static readonly BID_DENIED_NOTIFICATION_CUSTOMER = new BrushNotification('Bid denied', 'You have denied a bid for project ${arg}', ENotificationType.BID_DENIED_NOTIFICATION_CUSTOMER);
  public static readonly PAYMENT_DONE_PAINTER = new BrushNotification('Payment done', 'You have just done a payment for project ${arg}', ENotificationType.PAYMENT_DONE_PAINTER);
  public static readonly PAYMENT_DONE_CUSTOMER = new BrushNotification('Payment done', 'Payment for project ${arg} done', ENotificationType.PAYMENT_DONE_CUSTOMER);
  public static readonly NEW_COMMENT_PAINTER = new BrushNotification('New comment', 'Customer ${arg} has just posted a comment under your profile', ENotificationType.NEW_COMMENT_PAINTER);

  private constructor(public readonly title: string, public readonly text: string, readonly type: ENotificationType) {
  }

  public static uriBuilder(message: any): string {
    let uri = 'home/';
    switch (message.notificationType) {
      case 'NEW_BID_NOTIFICATION_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID + '/' + message.relatedIDSub;
        break;
      case 'NEW_BID_NOTIFICATION_PAINTER':
        uri = 'home/my-bids/bid/' + message.relatedID;
        break;
      case 'BID_DENIED_NOTIFICATION_PAINTER':
        uri = 'home/my-bids/bid/' + message.relatedID;
        break;
      case 'BID_DENIED_NOTIFICATION_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID + '/' + message.relatedIDSub;
        break;
      case 'BID_ACCEPTED_NOTIFICATION_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'BID_ACCEPTED_NOTIFICATION_PAINTER':
        uri = 'home/my-bids/bid/' + message.relatedID;
        break;
      case 'BID_CANCELED_NOTIFICATION_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'BID_CANCELED_NOTIFICATION_PAINTER':
        uri = 'home/my-bids/bid/' + message.relatedID;
        break;
      case 'PAYMENT_DONE_PAINTER':
        uri = 'home/my-bids/bid/' + message.relatedID;
        break;
      case 'PAYMENT_DONE_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'NEW_COMMENT_PAINTER':
        uri = 'home/my-profile/comments';
        break;
      case 'PROJECT_DELETED_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'PROJECT_DELETED_PAINTER':
        uri = 'home/my-bids/bid/' + message.relatedID;
        break;
      case 'PROJECT_CREATED_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'PROJECT_ACCEPTED_FROM_DRAFT':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'PROJECT_MARKED_COMPLETED_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'PROJECT_MARKED_COMPLETED_PAINTER':
        uri = 'home/my-jobs/details/' + message.relatedID;
        break;
      case 'PROJECT_COMPLAIN_PAINTER':
        uri = 'home/my-jobs/details/' + message.relatedID;
        break;
      case 'PROJECT_COMPLAIN_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;
      case 'PROJECT_FULLY_COMPLETED_PAINTER':
        uri = 'home/my-jobs/details/' + message.relatedID;
        break;
      case 'PROJECT_FULLY_COMPLETED_CUSTOMER':
        uri = 'home/my-projects/details/' + message.relatedID;
        break;

    }
    return uri;
  }
}

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  notificationCounter = 0;
  DISAPPEAR_AFTER_MS = 5000;
  MOVE_RIGHT_SPEED = 1000;
  COLOR = '#36f';
  MIN_HEIGHT_PX = 150;
  NORMAL_WIDTH_PX = 600;
  MARGIN_TOP_PX = 25;
  GAP_BETWEEN_NOTIFICATIONS_PX = 25;
  WINDOW_WIDTH: any;
  WINDOW_HEIGHT: any;

  constructor(protected urlRouter: Router) {
    this.WINDOW_WIDTH = window.innerWidth || document.documentElement.clientWidth ||
      document.body.clientWidth;
    this.WINDOW_HEIGHT = window.innerHeight || document.documentElement.clientHeight ||
      document.body.clientHeight;
    if (this.WINDOW_WIDTH < 650) {
      this.MARGIN_TOP_PX = 5;
    }
  }

  //fixme:?
  showNotification(message: any, clickCallback: any): void {
    console.log('raz');
    const top = ((this.MIN_HEIGHT_PX * this.notificationCounter) + (this.GAP_BETWEEN_NOTIFICATIONS_PX * this.notificationCounter) + this.MARGIN_TOP_PX);
    const notificationWrapper = document.createElement('div');
    notificationWrapper.style.position = 'fixed';
    notificationWrapper.style.top = top + 'px';
    notificationWrapper.style.background = 'white';
    notificationWrapper.style.border = '1px solid ' + this.COLOR;
    notificationWrapper.style.right = '1%';
    notificationWrapper.style.zIndex = '10000';
    if (this.WINDOW_WIDTH < 650) {
      notificationWrapper.style.width = '98%';
    } else {
      notificationWrapper.style.width = this.NORMAL_WIDTH_PX + 'px';
    }
    notificationWrapper.style.minHeight = this.MIN_HEIGHT_PX + 'px';
    notificationWrapper.style.display = 'flex';
    notificationWrapper.style.flexDirection = 'column';

    const header = document.createElement('div');
    header.style.width = '100%';
    header.style.color = 'white';
    header.style.background = this.COLOR;
    header.style.display = 'flex';
    header.style.fontSize = '18px';
    header.style.fontWeight = 'bold';
    header.style.alignItems = 'center';
    const closeIcon = document.createElement('div');
    closeIcon.innerText = 'x';
    closeIcon.style.fontSize = '22px';
    closeIcon.style.top = '5px';
    closeIcon.style.padding = '5px';
    closeIcon.style.maxWidth = '5%';
    closeIcon.style.cursor = 'pointer';
    closeIcon.style.marginLeft = 'auto';
    closeIcon.style.marginRight = '5px';
    const titleDiv = document.createElement('h3');
    titleDiv.style.fontSize = '1.3rem';
    titleDiv.innerText = message.title;
    titleDiv.style.marginLeft = '15px';
    header.append(titleDiv);
    header.appendChild(closeIcon);
    notificationWrapper.append(header);
    const notificationContent = document.createElement('div');
    notificationContent.style.padding = '25px';
    notificationContent.style.borderTop = 'none';
    notificationContent.innerHTML = message.content;
    notificationContent.style.fontSize = '1.3rem';
    notificationContent.addEventListener('click', () => {
      if (clickCallback != null) {
        clickCallback(message);
      }
      this.urlRouter.navigate([BrushNotification.uriBuilder(message)]);
    }, false);
    notificationContent.addEventListener('mouseover', () => {
      notificationContent.style.opacity = '0.7';
      notificationContent.style.cursor = 'pointer';
    }, false);
    notificationContent.addEventListener('mouseout', () => {
      notificationContent.style.opacity = '1';
      notificationContent.style.cursor = 'auto';
    }, false);
    notificationWrapper.appendChild(notificationContent);
    const router = document.getElementById('childRoutersHomeComponent');
    setTimeout(() => {
      notificationWrapper.animate([
        {right: '1%'}, // main-dark
        {right: (-1 * (this.NORMAL_WIDTH_PX + 50)) + 'px'}
      ], {
        duration: this.MOVE_RIGHT_SPEED,
        iterations: 1
      });
      setTimeout(() => {
        if (router?.contains(notificationWrapper)) {
          router?.removeChild(notificationWrapper);
        }
        this.notificationCounter--;
      }, this.MOVE_RIGHT_SPEED - 1);
    }, this.DISAPPEAR_AFTER_MS);
    closeIcon.addEventListener('click', () => {
      if (router != null) {
        router.removeChild(notificationWrapper);
      }
    }, false);
    if (router != null) {
      router.appendChild(notificationWrapper);
      this.notificationCounter++;
      const startNoCounter = this.notificationCounter;
      const posInterval = setInterval(() => {
        if (this.notificationCounter < startNoCounter) {
          notificationWrapper.style.top = top - ((startNoCounter - this.notificationCounter) * this.MIN_HEIGHT_PX) + 'px';
        }
        if (this.notificationCounter === 0) {
          clearInterval(posInterval);
        }
      }, 250);

    }
  }
}
