import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Room} from '../../../model/Room';
import {EProjectStatus} from '../../../model/enums/EProjectStatus';
import {EnumPipe} from '../../../pipes/enum.pipe';
import {EBidStatus} from '../../../model/enums/EBidStatus';


@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent {
  @Input() project: any;
  EProjectStatus = EProjectStatus;
  constructor() {
    //  super.project = this.project;

  }
   ngOnInit(): void {
     console.log(this.project)

   }
}
