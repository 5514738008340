export enum ENotificationType{
  NEW_BID_NOTIFICATION_CUSTOMER,
  NEW_BID_NOTIFICATION_PAINTER,
  BID_ACCEPTED_NOTIFICATION_CUSTOMER,
  BID_ACCEPTED_NOTIFICATION_PAINTER,
  BID_CANCELED_NOTIFICATION_CUSTOMER,
  BID_CANCELED_NOTIFICATION_PAINTER,
  BID_DENIED_NOTIFICATION_PAINTER,
  BID_DENIED_NOTIFICATION_CUSTOMER,
  PAYMENT_DONE_PAINTER,
  PAYMENT_DONE_CUSTOMER,
  NEW_COMMENT_PAINTER,
  PROJECT_DELETED_CUSTOMER,
  PROJECT_DELETED_PAINTER,
  PROJECT_CREATED_CUSTOMER,
  PROJECT_ACCEPTED_FROM_DRAFT
}
