<div class="main-box welcm_bck">
  <img src="assets/logo.png" class="error-icon">
  <p class="title exchng_clr">Welcome to Brush Exchange</p>
  <form class="form margin-b10">
    <div class="input-wrapper paragraph_text">
      <p>Before you start using Brush Exchange, please provide us with some basic information about you. It will only
        take a quick minute! This information can be later modified in your profile settings</p>
    </div>
  </form>
  <div class="div_btn_lgn get_strt_btn">
    <button class="custom-button" routerLink="/login/WelcomeTwo">Get Started
      <svg width="1em" height="1em" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
        viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg></button>
  </div>
</div>