<h2 class="route-title"><span>My Bids</span>
  <app-notification-bell></app-notification-bell>
</h2>
<div class="bids-wrapper">

  <!--<div class="filters-horizontal">-->
  <!--<div class="inner">-->
  <!--<app-select-input class="filter" [label]="selectedPageSize.optionLabel"-->
  <!--[inputOptions]="pageSizeOptions"-->
  <!--(valueChanged)="changePageSize($event)">-->
  <!--</app-select-input>-->

  <!--<app-select-input [label]="selectedOrdering.optionLabel"-->
  <!--[inputOptions]="orderOptions"-->
  <!--(valueChanged)="changeOrdering($event)" class="filter">-->
  <!--</app-select-input>-->
  <!--</div>-->
  <!--</div>-->
  <nb-card class="nb-theme-brush card user_bids_card">
    <nb-card-body>

      <nb-tabset #tabset>
        <nb-tab [active]="this.selectedtab == 'one' ? true : false" tabTitle="Accepted({{accepetedbids.length}})">
          <div class="">
           <div class="projects-wrapper" >
              <div class="card card_my_prjet card" *ngFor="let item of accepetedbids">
                <div class="div_cd d-flex">
                  <div class="img_nw">
                    <img [src]="item?.Project_details?.images[0]?.image" alt="">
                  </div>
                  <div class="div_pntr">
                    <div class="div_hrd d-flex">
                      <div class="div_prject_text">
                        <div class="div_spn">
                          <a class="ned_urgnt title" (click)="yourFunc('one');viewDemoDetails(item.id)">{{item.Project_details.project_title}}</a>
                          <div class="iner_degn">
                            <p>{{item.Project_details.project_type | titlecase}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="ago_tym" *ngIf="item.days_remaining>0">
                        <h1>Posted {{item.days_remaining}} days ago</h1>
                      </div>
                       <div class="ago_tym" *ngIf="item.days_remaining==0">
                        <h1>Posted today</h1>
                      </div>
                    </div>
                    <div class="div_descrptin">
                      <p>{{item.Project_details.description}}</p>
                    </div>
                    <div class="section bottom">
                      <div class="tags">
                        <div class="tag-wrapper bid bid-accepted">
                          <div class="tag">Accepted</div>
                        </div>
                        <div class="tag-wrapper hint-bids">
                          <div class="tag">{{item?.Project_details?.bid_count ? item?.Project_details?.bid_count : 0}} Bids</div>
                        </div>
                      </div>
                    </div>
                    <div painter-perspective class="bid-details-wrapper pay_bid_wrap">
                      <!-- <div class="d-flex" (click)="viewDemoDetails(item.id)">
                        <div class="view-bid-details">
                          View Details
                        </div>
                        <nb-icon icon="arrow-forward-outline"></nb-icon>
                      </div> -->
                      <div class="bid-details"><span class="bid-amount-text">Your Bid:&nbsp;</span><span
                          class="bid-amount">${{item.bid}}</span></div>

                    </div>
                  </div>
                </div>
              </div>
           
            </div>

           
          </div>
        </nb-tab>
        <nb-tab [active]="this.selectedtab == 'second' ? true : false" tabTitle="Submitted({{bids.length}})">
          <div class="">
            <div class="projects-wrapper" *ngFor="let item of bids">
              <div class="card card_my_prjet card">
                 <div class="div_cd d-flex">
                  <div class="img_nw">
                    <img [src]="item?.Project_details?.images[0]?.image" alt="">
                  </div>
                  <div class="div_pntr">
                    <div class="div_hrd d-flex">
                      <div class="div_prject_text">
                        <div class="div_spn">
                          <span class="ned_urgnt title" (click)="yourFunc('second');viewDemoDetails(item.id)"><a>{{item.Project_details.project_title}}</a></span>
                          <div class="iner_degn">
                            <p>{{item.Project_details.project_type | titlecase}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="ago_tym" *ngIf="item.days_remaining>0">
                        <h1>Posted {{item.days_remaining}} days ago</h1>
                      </div>
                       <div class="ago_tym" *ngIf="item.days_remaining==0">
                        <h1>Posted today</h1>
                      </div>
                    </div>
                    <div class="div_descrptin">
                      <p>{{item.Project_details.description}}</p>
                    </div>
                    <div class="section bottom">
                      <div class="tags">
                        <div class="tag-wrapper bid bid-accepted">
                          <div class="tag">Submitted</div>
                        </div>
                        <div class="tag-wrapper hint-bids">
                          <div class="tag">{{item?.Project_details?.bid_count ? item?.Project_details?.bid_count : 0}} Bids</div>
                        </div>
                      </div>
                    </div>
                    <div painter-perspective class="bid-details-wrapper pay_bid_wrap">
                      <!-- <div class="d-flex" (click)="viewDemoDetails(item.id)">
                        <div class="view-bid-details">
                          View Details
                        </div>
                        <nb-icon icon="arrow-forward-outline"></nb-icon>
                      </div> -->
                      <div class="bid-details"><span class="bid-amount-text">Your Bid:&nbsp;</span><span
                          class="bid-amount">${{item.bid}}</span></div>
                      <div painter-perspective class="bid-details-wrapper pay_bid_wrap">
                        <div class="view-bid-details">
                          <a class="button_theme" (click)="open(item.id, item.project_id)">Withdraw</a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                  
              </div>
         
            </div>
           
          </div>
        </nb-tab>
        <nb-tab [active]="this.selectedtab == 'third' ? true : false" tabTitle="Rejected({{rejectedbids.length}})">
          <div class="">
           <div class="projects-wrapper" *ngFor="let item of rejectedbids">
              <div class="card card_my_prjet card">
                    <div class="div_cd d-flex">
                  <div class="img_nw">
                      <img [src]="item?.Project_details?.images[0]?.image" alt="">
                  </div>
                  <div class="div_pntr">
                    <div class="div_hrd d-flex">
                      <div class="div_prject_text">
                        <div class="div_spn">
                          <span class="ned_urgnt title" (click)="yourFunc('third');viewDemoDetails(item.id)"><a>{{item.Project_details.project_title}}</a></span>
                          <div class="iner_degn">
                            <p>{{item.Project_details.project_type | titlecase}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="ago_tym" *ngIf="item.days_remaining>0">
                        <h1>Posted {{item.days_remaining}} days ago</h1>
                      </div>
                       <div class="ago_tym" *ngIf="item.days_remaining==0">
                        <h1>Posted today</h1>
                      </div>
                    </div>
                    <div class="div_descrptin">
                      <p>{{item.Project_details.description}}</p>
                    </div>
                    <div class="section bottom">
                      <div class="tags">
                        <div class="tag-wrapper bid bid-accepted">
                          <div class="tag">Rejected</div>
                        </div>
                        <div class="tag-wrapper hint-bids">
                          <div class="tag">{{item?.Project_details?.bid_count ? item?.Project_details?.bid_count : 0}} Bids</div>
                        </div>
                      </div>
                    </div>
                    <div painter-perspective class="bid-details-wrapper pay_bid_wrap">
                      <div class="d-flex" (click)="viewDemoDetails(item.id)">
                        <div class="view-bid-details">
                          View Details
                        </div>
                        <nb-icon icon="arrow-forward-outline"></nb-icon>
                      </div>
                      <div class="bid-details"><span class="bid-amount-text">Your Bid:&nbsp;</span><span
                          class="bid-amount">${{item.bid}}</span></div>

                    </div>
                  </div>
                </div>
              </div>
           
            </div>

         
          </div>
        </nb-tab>
        <nb-tab [active]="this.selectedtab == 'fourth' ? true : false" tabTitle="Deleted({{deletedbids.length}})">
          <div class="">
            <div class="projects-wrapper" *ngFor="let item of deletedbids">
              <div class="card card_my_prjet card">
                   <div class="div_cd d-flex">
                  <div class="img_nw">
                      <img [src]="item?.Project_details?.images[0]?.image" alt="">
                  </div>
                  <div class="div_pntr">
                    <div class="div_hrd d-flex">
                      <div class="div_prject_text">
                        <div class="div_spn">
                          <span class="ned_urgnt title" (click)="yourFunc('fourth');viewDemoDetails(item.id)"><a>{{item.Project_details.project_title}}</a></span>
                          <div class="iner_degn">
                            <p>{{item.Project_details.project_type | titlecase}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="ago_tym" *ngIf="item.days_remaining>0">
                        <h1>Posted {{item.days_remaining}} days ago</h1>
                      </div>
                       <div class="ago_tym" *ngIf="item.days_remaining==0">
                        <h1>Posted today</h1>
                      </div>
                    </div>
                    <div class="div_descrptin">
                      <p>{{item.Project_details.description}}</p>
                    </div>
                    <div class="section bottom">
                      <div class="tags">
                        <div class="tag-wrapper bid bid-accepted">
                          <div class="tag">Deleted</div>
                        </div>
                        <div class="tag-wrapper hint-bids">
                          <div class="tag">{{item?.Project_details?.bid_count ? item?.Project_details?.bid_count : 0}} Bids</div>
                        </div>
                      </div>
                    </div>
                    <div painter-perspective class="bid-details-wrapper pay_bid_wrap">
                      <div class="d-flex" (click)="viewDemoDetails(item.id)">
                        <div class="view-bid-details">
                          View Details
                        </div>
                        <nb-icon icon="arrow-forward-outline"></nb-icon>
                      </div>
                      <div class="bid-details"><span class="bid-amount-text">Your Bid:&nbsp;</span><span
                          class="bid-amount">${{item.bid}}</span></div>

                    </div>
                  </div>
                </div>
              </div>
           
            </div>
           <!-- <app-pagination>
            </app-pagination>-->
          </div>
        </nb-tab>
        <nb-tab [active]="this.selectedtab == 'five' ? true : false" tabTitle="Expired({{expiredbids.length}})">
          <div class="">
              <div class="projects-wrapper" *ngFor="let item of expiredbids">
              <div class="card card_my_prjet card">
                  <div class="div_cd d-flex">
                  <div class="img_nw">
                   <img [src]="item?.Project_details?.images[0]?.image" alt="">
                  </div>
                  <div class="div_pntr">
                    <div class="div_hrd d-flex">
                      <div class="div_prject_text">
                        <div class="div_spn">
                          <span class="ned_urgnt title" (click)="yourFunc('five');viewDemoDetails(item.id)"><a>{{item.Project_details.project_title}}</a></span>
                          <div class="iner_degn">
                            <p>{{item.Project_details.project_type | titlecase}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="ago_tym" *ngIf="item.days_remaining>0">
                        <h1>Posted {{item.days_remaining}} days ago</h1>
                      </div>
                       <div class="ago_tym" *ngIf="item.days_remaining==0">
                        <h1>Posted today</h1>
                      </div>
                    </div>
                    <div class="div_descrptin">
                      <p>{{item.Project_details.description}}</p>
                    </div>
                    <div class="section bottom">
                      <div class="tags">
                        <div class="tag-wrapper bid bid-accepted">
                          <div class="tag">Expired</div>
                        </div>
                        <div class="tag-wrapper hint-bids">
                          <div class="tag">{{item?.Project_details?.bid_count ? item?.Project_details?.bid_count : 0}} Bids</div>
                        </div>
                      </div>
                    </div>
                    <div painter-perspective class="bid-details-wrapper pay_bid_wrap">
                      <!-- <div class="d-flex" (click)="viewDemoDetails(item.id)">
                        <div class="view-bid-details">
                          View Details
                        </div>
                        <nb-icon icon="arrow-forward-outline"></nb-icon>
                      </div> -->
                      <div class="bid-details"><span class="bid-amount-text">Your Bid:&nbsp;</span><span
                          class="bid-amount">${{item.bid}}</span></div>

                    </div>
                  </div>
                </div>
              </div>
           
            </div>
           
          </div>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
  </nb-card>
</div>



<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlagnew" (cancelButtonClicked)="closeComplainModalnew()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
       <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
          <div class="">
            <div class="painter-details">
              <div class="div_upr d-flex">
                <h3 class="text-center">
                 Are you sure you want to withdraw this bid?
                </h3>
              </div>
            </div>
          </div>

        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <button class="custom-button" (click)="withdraw('withdraw')">Yes</button>
             <button class="custom-button" (click)="cancel()">No</button>
          </div>
        </div>
      </div>
    </div>
  </slot-content>


</app-modal>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlag" (cancelButtonClicked)="closeComplainModal()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>
          <div class="">
            <div class="painter-details">
              <div class="div_upr d-flex">
                <h3 class="text-center">
                  Your bid was accepted. You will be charged <span>$1</span> to see the contact details.
                </h3>
              </div>
            </div>
          </div>

        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <button class="custom-button">Bid Now</button>
          </div>
        </div>
      </div>
    </div>
  </slot-content>


</app-modal>