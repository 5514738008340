<div class="route-title nav-with-icon">
  <span (click)="navigateBack()">
    <nb-icon icon="arrow-back-outline"></nb-icon>&nbsp; <a routerLink="/home/my-bids">My Bids</a>
  </span>
  <!-- <app-notification-bell></app-notification-bell> -->
</div>
<!-- <div *ngIf="myBid"> -->
<div>
  <section class="bid-details-wrapper bids_detial_sec">
    <!--<div class="projects-wrapper">-->
    <!--  <app-project-component [inputData]="biddetails" [marketplace]="true">

       <div painter-perspective class="view-original-listing" (click)="navigateToProject()">
        <div class="view-original-listing-text">View Original Listing</div>
        <nb-icon icon="arrow-forward-outline"></nb-icon>
      </div> -->
    <div class="card">
      <div class="d-flex">
        <div class="image-wrapper">
          <div class="image-sub-wrapper" *ngIf="biddetails.project_images?.length">
            <!-- <img [src]="biddetails.project_details.project_sub_details.project_images[0].image"> -->
            <img [src]="biddetails?.project_images[0]?.image" alt="">
          </div>
        </div>
        <div class="details">
          <div class="section top-section">
            <div class="top-main-info">
              <div class="title-wrapper">
                <div class="title-status-wrapper">
                  <span class="title">{{biddetails.Project_details.project_title}}</span>
                  <!-- <app-project-status *ngIf="!marketplace" [project]="project"></app-project-status> -->
                </div>
                <div class="when-posted" *ngIf="biddetails.days_remaining>0">Posted {{biddetails.days_remaining}} days
                  ago
                </div>
                <div class="when-posted" *ngIf="biddetails.days_remaining == 0">Posted today</div>

                <!-- <p>Posted <span class="when-posted">{{project.postedAgo}}</span> by Michael Holmes</p> -->
                <!-- <div class="icon_div">
                  <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5383 8.44731C19.2899 8.33248 19 8.352 18.7703 8.49733C18.5399 8.64421 18.4001 8.89888 18.4001 9.17232V10.5067C18.4001 10.9481 18.0414 11.3067 17.6001 11.3067C17.2047 11.3067 16.8703 11.0223 16.8055 10.6356C16.1547 6.60117 13.1906 0 12 0C10.8094 0 7.84533 6.60117 7.19531 10.6309C7.12971 11.0223 6.79531 11.3067 6.4 11.3067C5.95861 11.3067 5.6 10.9481 5.6 10.5067V9.17227C5.6 8.89883 5.46016 8.64416 5.22971 8.49728C4.99925 8.35195 4.71093 8.33243 4.46176 8.44725C1.73829 9.7176 0 15.2535 0 18.5067C0 23.6879 4.9344 27.3067 12 27.3067C19.0656 27.3067 24 23.6879 24 18.5067C24 15.2535 22.2617 9.7176 19.5383 8.44731Z" fill="url(#paint0_linear_3_67)"/>
                    <path d="M15.8805 16.4863C15.7344 16.2504 15.4773 16.1067 15.2 16.1067H13.425L12.7758 13.5129C12.6945 13.1879 12.4203 12.9481 12.0875 12.9114C11.7477 12.8762 11.4344 13.0504 11.2844 13.3489L8.08436 19.7489C7.96014 19.9965 7.97342 20.291 8.1195 20.527C8.26558 20.7629 8.52265 20.9067 8.79998 20.9067H10.575L11.2242 23.5004C11.3054 23.8254 11.5797 24.0653 11.9125 24.102C12.2548 24.138 12.5688 23.9572 12.7156 23.6645L15.9156 17.2645C16.0398 17.0168 16.0265 16.7223 15.8805 16.4863Z" fill="url(#paint1_linear_3_67)"/>
                    <defs>
                    <linearGradient id="paint0_linear_3_67" x1="12" y1="27.3067" x2="12" y2="0" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FD5900"/>
                    <stop offset="1" stop-color="#FFDE00"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_3_67" x1="12" y1="24.1066" x2="12" y2="12.9069" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFE59A"/>
                    <stop offset="1" stop-color="#FFFFD5"/>
                    </linearGradient>
                    </defs>
                  </svg>              
                </div> -->
              </div>
              <div class="project-type">{{biddetails.Project_details.project_type | titlecase}}</div>
            </div>
          </div>

          <div class="section description">
            <div class="two-lines-description">
              {{biddetails?.Project_details?.description}}
            </div>
          </div>

          <div class="section bottom">
            <div class="tags">
              <div class="tag-wrapper bid ylo-filled" *ngIf="biddetails.Project_details.property_type">
                <div class="tag ">{{biddetails.Project_details.property_type}}</div>
              </div>
              <div class="tag-wrapper bid ylo-filled" *ngIf="biddetails.Project_details.jobtime">
                <div class="tag ">{{biddetails.Project_details.jobtime}}</div>
              </div>
              <div class="tag-wrapper bid ylo-filled" *ngIf="biddetails.Project_details.height_of_ceiling">
                <div class="tag ">{{biddetails.Project_details.height_of_ceiling}}</div>
              </div>
              <div class="tag-wrapper bid ylo-filled" *ngIf="biddetails.Project_details.providing_items">
                <div class="tag ">{{biddetails.Project_details.providing_items}}</div>
              </div>
              <div class="tag-wrapper bid ylo-filled" *ngIf="biddetails.Project_details.surface_area">
                <div class="tag ">{{biddetails.Project_details.surface_area}}</div>
              </div>
              <div class="tag-wrapper bid" *ngIf="biddetails.Project_details.surface_condition">
                <div class="tag">{{biddetails.Project_details.surface_condition}}</div>
              </div>
              <div class="tag-wrapper bid" *ngIf="biddetails?.Project_details?.room?.length">
                <div class="tag">{{biddetails.Project_details.room.length}} Rooms</div>
              </div>


            </div>
            <!-- <div class="tag-wrapper bid"
            [ngClass]="{'bid-pending': bid.status === EBidStatus.PENDING, 'bid-accepted': bid.status === EBidStatus.ACCEPTED, 'bid-denied': bid.status === EBidStatus.DENIED, 'bid-deleted-expired': bid.status === EBidStatus.EXPIRED || bid.status === EBidStatus.DELETED}"
            *ngIf="bid != null && !showProjectStatus">
            <div class="tag">{{bid.status | enumPipe: EBidStatus: true}}</div>
          </div> -->
            <!-- <div class="tag-wrapper hint-bids" *ngIf="project.howManyBids === 0 && !showProjectStatus">
            <div class="tag">No bids yet</div>
          </div> -->
            <!-- <div class="tag-wrapper hint-bids" *ngIf="project.howManyBids == 1 && !showProjectStatus">
            <div class="tag">{{project.howManyBids}} bid</div>
          </div> -->
            <!-- <div class="tag-wrapper hint-bids" *ngIf="project.howManyBids > 1 && !showProjectStatus">
            <div class="tag">{{project.howManyBids}} bids</div>
          </div> -->
            <!-- <div class="tag-wrapper" *ngFor="let tag of project.tags | keyvalue">
            <div class="tag">
              {{tag.value | tagPipe: tag.key}}
            </div>
          </div> -->
            <!-- </div> -->
            <!-- <div class="icons" *ngIf="!marketplace">
          <div class="tooltip" *ngIf="inputData.status === EProjectStatus.PENDING">
            <nb-icon class="icon" icon="archive-outline" (click)="archive($event)"></nb-icon>
            <span class="tooltiptext">Archive</span>
          </div>
          <div class="tooltip" *ngIf="inputData.status === EProjectStatus.PENDING">
            <nb-icon class="icon" icon="trash-2-outline" (click)="delete($event)"
              *ngIf="inputData.status === EProjectStatus.PENDING"></nb-icon>
            <span class="tooltiptext">Delete</span>
          </div>
  
        </div> -->

          </div>
          <!-- <div class="location-wrapper">
            <div class="location-icon"><nb-icon icon="navigation-2-outline"></nb-icon></div>
            <div>{{project.customerCity}}, {{project.customerState}} {{project.customerZipCode}}</div>
            <div class="circle"></div>
            <div>{{project.distance}} miles away</div>
          </div> -->
          <div class="d-flex withdraw_btn_flex">

            <ng-content select="[painter-perspective]"></ng-content>
            <div painter-perspective class="view-original-listing" *ngIf="biddetails?.status=='pending'"
              routerLink="/home/my-project-detail/{{biddetails.project_id}}">
              <div class="view-original-listing-text"><a
                  routerLink="/home/my-project-detail/{{biddetails.project_id}}">View
                  Original Listing</a></div>


              <nb-icon icon="arrow-forward-outline"></nb-icon>
            </div>

            <div class="view-original-listing-text custom-button" *ngIf="biddetails.status=='pending'"><a
                (click)="modalFlagnew=true">Withdraw</a></div>
          </div>
        </div>
      </div>
    </div>



    <!--</app-project-component>
    </div>-->
    <div class="your-bid-wrapper">
      <div class="your-bid-header">
        <div class="your-bid-text">
          Your Bid
        </div>
        <div class="your-bid-status-wrapper  bid-accepted" style="color: white" *ngIf="biddetails.status=='pending'">
          Bid submitted
        </div>

        <div class="your-bid-status-wrapper  bid-accepted" style="color: white" *ngIf="biddetails.status=='approved'">
          Accepted
        </div>
        <!-- <div class="your-bid-status-wrapper" style="color: black" *ngIf="biddetails.status=='approved'">
          Approved

        </div> -->
      </div>
      <div class="modify_txt mt_10">
        <form>
          <div class="input-wrapper">
            <div class="div_incs input-with-hint">
              <span class="icon-wrapper icn_sld_pn">
                <img class="icon" src="assets/icons/dollar.svg">
              </span>
              <input placeholder="Your bid" [(ngModel)]="bidAmt" [ngModelOptions]="{standalone: true}">
              <span class="hint">
                <a class="hint-text"></a>
              </span>
            </div>

          </div>
        </form>
        <!--<app-modal *ngIf="modalVisible" [displayModal]="modalVisible"
           [title]="'Modify offer'"
           [subclaim]="'Are you sure you want to change your offer to <b>' + (newOfferForm.controls.newPrice.value.replace('$', '').replace(',','').replace('.','') | currency: 'USD') + '</b>?\n Your current bid will be is going to be deleted and new bid will be placed instead.'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="emitValue()"
           (cancelButtonClicked)="toggleModalVisibility()"></app-modal>-->

        <span *ngIf="biddetails.status=='pending' || biddetails.status=='disapproved' ">
          <a (click)="bidmodify()"> Modify</a>
        </span>
      </div>


      <div class="description-wrapper" *ngIf="biddetails.status=='approved'">
        <img src="./assets/icons/bid-accepted-icon.png" alt="icon" class="bid-status-icon">
        <div>
          <p>It seems like your offer was irresistible!
            <b>Congratulations</b> on your new job!
          </p>
        </div>
      </div>
    </div>

  </section>
  <!-- <section class="main">
    <div class="main-info-wrapper bid-accepted" *ngIf="ifAccepted() && !ifPaid()">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text">Your bid was accepted. You will be charged {{myBid.fee}}$ to se the contact details.</div>
      <button class="custom-button" (click)="pay()">Pay Now</button>
    </div>
    <div class="main-info-wrapper bid-accepted" *ngIf="ifAccepted() && ifPaid()">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text">Congratulations your payment was processed, you can now contact project owner!</div>
    </div>
    <div class="main-info-wrapper bid-pending" *ngIf="ifPending()">
      <nb-icon icon="clock"></nb-icon>
      <div class="main-info-text">Your bid is waiting for customer acceptation. You can undo your bid</div>
      <button class="custom-button" (click)="isDeleteBidModalVisible = true">Undo Bid</button>
    </div>
    <div class="main-info-wrapper bid-denied" *ngIf="ifDenied() && !ifProjectPending()">
      <nb-icon icon="close-square"></nb-icon>
      <div class="main-info-text">Your bid was denied. Project is not available anymore</div>
    </div>
    <div class="main-info-wrapper bid-denied" *ngIf="ifDenied() && ifProjectPending()">
      <nb-icon icon="close-square"></nb-icon>
      <div class="main-info-text">Your bid was denied. Don't give up and post new bid</div>
      <button class="custom-button" (click)="this.isPlaceBidModalVisible = true">Post New Bid</button>
    </div>
    <div class="main-info-wrapper bid-deleted-expired" *ngIf="ifDeleted() && ifProjectPending()">
      <nb-icon icon="undo"></nb-icon>
      <div class="main-info-text">You have undone your bid. If you want to you can still post new bid</div>
      <button class="custom-button" (click)="this.isPlaceBidModalVisible = true">Post New Bid</button>
    </div>
    <div class="main-info-wrapper bid-deleted-expired" *ngIf="ifDeleted() && !ifProjectPending()">
      <nb-icon icon="undo"></nb-icon>
      <div class="main-info-text">You have undone your bid. Project is not available anymore</div>
    </div>
    <app-contact-card-mini *ngIf="ifPaid()" [detailedContactAvailable]="true" [isUserPainter]="false" [user]="project.customer"></app-contact-card-mini>

  </section> -->
  <section class="how-brush-work">
    <div class="how-brush-work-title">How Brush Exchange Works?</div>
    <div class="how-brush-work-details">
      <!-- <div class="how-brush-work-flex-with-arrow">
        <div class="how-brush-works-card">
          <div class="how-brush-works-card-header">
            <div class="how-brush-works-card-title">
              {{howBrushWorks.title}}
            </div>
            <img class="how-brush-works-card-image" [src]="howBrushWorks.icon" alt="image">
          </div>
          <div class="how-brush-works-card-description">
            {{howBrushWorks.description}}
          </div>
        </div>
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon"
             alt="arrow">
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon-res"
             alt="arrow">
      </div> -->

      <div class="how-brush-work-flex-with-arrow">
        <div class="how-brush-works-card">
          <div class="how-brush-works-card-header">
            <div class="how-brush-works-card-title">
              {{howworkd.heading1}}
            </div>
            <img _ngcontent-orc-c275="" alt="image" class="how-brush-works-card-image" [src]="howworkd.image1">
          </div>
          <div class="how-brush-works-card-description">
            {{howworkd.description1}}
          </div>
        </div>
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon" alt="arrow">
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon-res" alt="arrow">
      </div>

      <div class="how-brush-work-flex-with-arrow">
        <div class="how-brush-works-card">
          <div class="how-brush-works-card-header">
            <div class="how-brush-works-card-title">
              {{howworkd.heading2}}
            </div>
            <img _ngcontent-orc-c275="" alt="image" class="how-brush-works-card-image" [src]="howworkd.image2">
          </div>
          <div class="how-brush-works-card-description">
            {{howworkd.description2}}
          </div>
        </div>
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon" alt="arrow">
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon-res" alt="arrow">
      </div>

      <div class="how-brush-work-flex-with-arrow">
        <div class="how-brush-works-card">
          <div class="how-brush-works-card-header">
            <div class="how-brush-works-card-title">
              {{howworkd.heading3}}
            </div>
            <img _ngcontent-orc-c275="" alt="image" class="how-brush-works-card-image" [src]="howworkd.image3">
          </div>
          <div class="how-brush-works-card-description">
            {{howworkd.description3}}
          </div>
        </div>
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon" alt="arrow">
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon-res" alt="arrow">
      </div>

      <div class="how-brush-work-flex-with-arrow">
        <div class="how-brush-works-card">
          <div class="how-brush-works-card-header">
            <div class="how-brush-works-card-title">
              {{howworkd.heading4}}
            </div>
            <img _ngcontent-orc-c275="" alt="image" class="how-brush-works-card-image" [src]="howworkd.image4">
          </div>
          <div class="how-brush-works-card-description">
            {{howworkd.description4}}
          </div>
        </div>
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon" alt="arrow">
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon-res" alt="arrow">
      </div>

      <div class="how-brush-work-flex-with-arrow">
        <div class="how-brush-works-card">
          <div class="how-brush-works-card-header">
            <div class="how-brush-works-card-title">
              {{howworkd.heading5}}
            </div>
            <img _ngcontent-orc-c275="" alt="image" class="how-brush-works-card-image" [src]="howworkd.image5">
          </div>
          <div class="how-brush-works-card-description">
            {{howworkd.description5}}
          </div>
        </div>
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon" alt="arrow">
        <img src="./assets/icons/how-brush-works/how-brush-works-arrow.png" class="arrow-icon-res" alt="arrow">
      </div>

    </div>
  </section>
</div>
<app-modal [displayModal]="paymentProceedVisible" [title]="issuccs ? 'Payment successful' : 'Payment was not proceed.'"
  [subclaim]="issuccs ? 'Your payment was proceed with success. You can now contact project owner' : 'There was an problem during processing your payment. Try again, money was not taken'"
  [confirmButtonText]="'Confirm'" (confirmButtonClicked)="paymentProceedVisible = false">
</app-modal>
<app-modal [displayModal]="isDeleteBidModalVisible" [title]="'Undo bid'"
  [subclaim]="'Are you sure you want to undo your offer?'" [confirmButtonText]="'Confirm'"
  (confirmButtonClicked)="deleteBid()" (cancelButtonClicked)="toggleOffAllModals()"></app-modal>
<!-- <app-modal [displayModal]="checkIfDisplay()" [title]="bidPlacedModalValues.title"
  [subclaim]="bidPlacedModalValues.subclaim" [confirmButtonText]="bidPlacedModalValues.btn"
  (cancelButtonClicked)="toggleOffAllModals()"
  (confirmButtonClicked)="isPlaceBidModalVisible ? sendBid(true) : toggleOffAllModals()"
  [confirmButtonDisabled]="newOfferForm.controls['newPrice'].value <= 0">
  <slot-content *ngIf="isPlaceBidModalVisible">
    <div style="display: flex; flex-direction: column; width: 200px;" [formGroup]="newOfferForm">
      <div>Your offer:</div>
      <input (input)="consumeWrongCharacters($event)" formControlName="newPrice" placeholder="$" />
    </div>
  </slot-content>
</app-modal> -->
<app-modal class="bid-detail-mdl pay_bid_modal " [displayModal]="modalFlagbid"
  (cancelButtonClicked)="closeComplainModalnew()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/bid-modify.svg" alt="meeting" class="left-img">
          </div>
          <div class="">
            <div class="painter-details">
              <div class="div_upr d-flex">
                <h3 class="text-center">
                  Your bid was accepted. You will be charged <span>1 credit.</span>
                </h3>
              </div>
            </div>
          </div>

        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <!-- <button class="custom-button" type="button" routerLink="/home/bid-payment">Pay Now</button> -->
          </div>
        </div>
      </div>
    </div>
  </slot-content>


</app-modal>
<app-modal class="bid-detail-mdl pay_bid_modal withw_mdkl" [displayModal]="modalFlagnew"
  (cancelButtonClicked)="closeComplainModalnew()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
          <div class="">
            <div class="painter-details">
              <div class="div_upr d-flex">
                <h3 class="text-center">
                  Are you sure you want to withdraw this bid?
                </h3>
              </div>
            </div>
          </div>

        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <button class="custom-button" (click)="withdraw('withdraw',biddetails.id, biddetails?.project_id)">Yes</button>
            <button class="custom-button" (click)="cancel()">No</button>
          </div>
        </div>
      </div>
    </div>
  </slot-content>


</app-modal>