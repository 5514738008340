<div class="div_full_pg" id="childRoutersHomeComponent">
    <div class="projects-wrapper">
        <div class="div_upr_text d-flex">
            <div class="d-flex align-items-center">
                <a>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                        <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </a>
                <h1>Projects</h1>
            </div>
            <!-- <div class="bell_notification">
                <img src="assets/icons/notifications-icon.png" alt="notifications-icon">
                <div class="unread-notification-count">2</div>
            </div> -->
            <app-notification-bell></app-notification-bell>
        </div>
        <div class="div_btn_cret">
            <button class="custom-button" routerLink="/home/make-new-project">+ Create New Project</button>
        </div>
        <nb-card class="nb-theme-brush card">
            <nb-card-body>
                <nb-tabset>
                    <nb-tab id="tab1" [active]="this.selectedtab == 'one' ? true : false"
                        tabTitle="Waiting for Approval({{waiting}})">
                        <div class="card_my_prjet card " *ngFor="let project of projects">
                            <div class="div_cd d-flex">
                                <div class="img_nw">
                                    <img [src]="project?.images[0]?.image" alt="" onerror="src='./assets/dummy.png'" />
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a (click)="yourFunc('one', project.id)">
                                                    <span class="ned_urgnt">{{project.project_title}}</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>{{project.project_type| titlecase }}</p>
                                                </div>
                                            </div>
                                            <span class="orng_color">Project waiting to be approved</span>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining==0">
                                            <h1>Today </h1>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining !=0">
                                            <h1>{{project.days_remaining }} days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>{{project.description}}</p>
                                    </div>
                                    <div class="btn_tgs">
                                        <button class="grey ">
                                            No Offers Yet
                                        </button>

                                        <button class="Otline_grey grey">
                                            {{project.room ? project.room.length : 'No'}} Rooms
                                        </button>
                                        <button class="Otline_grey grey">
                                            {{project.bids.length}} bids
                                        </button>

                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="div_in" (click)="delet(project.id);modalFlag=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--   <div class="card_my_prjet card">
                            <div class="div_cd d-flex">
                                <div class="img_nw">
                                    <img src="assets/Rectangle5.png" alt="">
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a [routerLink]="'/home/project-detail'">
                                                    <span class="ned_urgnt">Need a painter for my mansion,
                                                        Urgent!</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>Project Interior</p>
                                                </div>
                                            </div>
                                            <span class="yellow_clr">Project pending award</span>
                                        </div>
                                        <div class="ago_tym">
                                            <h1>18 days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>Arcu integer vulputate laoreet lorem eget mauris, ligula cursus rutrum. Nisl,
                                            justo vitae
                                            massa
                                            id massa egestas turpis. Aliquam hendrerit ultricies
                                            varius at nibh neque nunc sit. Tristique feugiat enim vitae sed pellentesque
                                            scelerisque
                                            metus,
                                            velit...</p>
                                    </div>
                                    <div class="btn_tgs">
                                        <button class="grey">
                                            No Offers Yet
                                        </button>
                                        <button class="Otline_grey grey">
                                            Wallpaper
                                        </button>
                                        <button class="Otline_grey grey">
                                            Four rooms
                                        </button>
                                        <button class="Otline_grey grey">
                                            Medium area
                                        </button>
                                        <button class="Otline_grey grey">
                                            Fine condition
                                        </button>
                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="div_in">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card_my_prjet card">
                            <div class="div_cd d-flex">
                                <div class="img_nw">
                                    <img src="assets/Rectangle5.png" alt="">
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a [routerLink]="'/home/project-detail'">
                                                    <span class="ned_urgnt">Need a painter for my mansion,
                                                        Urgent!</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>Project Interior</p>
                                                </div>
                                            </div>
                                            <span class="green_div">Project pending award</span>
                                        </div>
                                        <div class="ago_tym">
                                            <h1>18 days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>Arcu integer vulputate laoreet lorem eget mauris, ligula cursus rutrum. Nisl,
                                            justo vitae
                                            massa
                                            id massa egestas turpis. Aliquam hendrerit ultricies
                                            varius at nibh neque nunc sit. Tristique feugiat enim vitae sed pellentesque
                                            scelerisque
                                            metus,
                                            velit...</p>
                                    </div>
                                    <div class="btn_tgs">
                                        <button class="grey">
                                            No Offers Yet
                                        </button>
                                        <button class="Otline_grey grey">
                                            Wallpaper
                                        </button>
                                        <button class="Otline_grey grey">
                                            Four rooms
                                        </button>
                                        <button class="Otline_grey grey">
                                            Medium area
                                        </button>
                                        <button class="Otline_grey grey">
                                            Fine condition
                                        </button>
                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="div_in">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>-->


                    </nb-tab>
                    <nb-tab id="tab2" [active]="this.selectedtab == 'two' ? true : false"
                        tabTitle="On Marketplace({{completed}})">
                        <div class="card_my_prjet card " *ngFor="let project of projectsapproved">
                            <div class="div_cd d-flex">
                                <div class="img_nw" *ngIf="project.images.length">
                                    <img [src]="project.images[0].image" alt="">
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a (click)="yourFunc('two', project.id)">
                                                    <span class="ned_urgnt">{{project.project_title}}</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>{{project.project_type| titlecase }}</p>
                                                </div>
                                            </div>
                                            <span class="orng_color">On marketplace</span>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining==0">
                                            <h1>Today </h1>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining !=0">
                                            <h1>{{project.days_remaining }} days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>{{project.description}}</p>
                                    </div>
                                    <div class="btn_tgs">

                                        <!-- <button class="Otline_grey grey">
                                         <!--   {{project.tags.hasWallpaper ? project.tags.hasWallpaper : 'No wallpaper'}}
                                        </button>-->
                                        <button class="Otline_grey grey">
                                            {{project.room ? project.room.length : 'No'}} Rooms

                                        </button>
                                        <button class=" grey tag fill bid-accepted">
                                            {{project.bids.length}} bids
                                        </button>
                                        <button *ngIf="project.bids.length" class="grey project-pending">
                                            Award Project
                                        </button>

                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <!-- <div class="div_in" (click)="deleteproj(project.id)"> -->
                                        <div class="div_in" (click)="delet(project.id);modalFlag=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </nb-tab>
                    <nb-tab id="tab3" [active]="this.selectedtab == 'three' ? true : false"
                        tabTitle="Brushed({{brushed}})">
                        <div class="card_my_prjet card " *ngFor="let project of brushedproject">
                            <div class="div_cd d-flex">
                                <div class="img_nw" *ngIf="project.images.length">
                                    <img [src]="project.images[0].image" alt="">
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a (click)="yourFunc('three', project.id)">
                                                    <span class="ned_urgnt">{{project.project_title}}</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>{{project.project_type| titlecase }}</p>
                                                </div>
                                            </div>
                                            <span class="yellow_clr">Brushed</span>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining==0">
                                            <h1>Today </h1>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining !=0">
                                            <h1>{{project.days_remaining }} days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>{{project.description}}</p>
                                    </div>
                                    <div class="btn_tgs">

                                        <!-- <button class="Otline_grey grey">
                                           {{project.tags.hasWallpaper ? project.tags.hasWallpaper : 'No wallpaper'}}
                                        </button>-->
                                        <button class="grey project-pending">
                                            Mark Complete
                                        </button>
                                        <button class="Otline_grey grey">
                                            {{project.room ? project.room.length : 'No'}} Rooms

                                        </button>
                                        <button class=" grey tag fill bid-accepted">
                                            {{project.bids.length}} bids
                                        </button>

                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <!-- <div class="div_in" (click)="deleteproj(project.id)"> -->
                                        <div class="div_in" (click)="delet(project.id);modalFlag=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </nb-tab>
                    <nb-tab id="tab4" [active]="this.selectedtab == 'four' ? true : false"
                        tabTitle="Completed({{com_count}})">
                        <div class="card_my_prjet card " *ngFor="let project of completedprojects">
                            <div class="div_cd d-flex">
                                <div class="img_nw" *ngIf="project.images.length">
                                    <img [src]="project.images[0].image" alt="">
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a (click)="yourFunc('four', project.id)">
                                                    <span class="ned_urgnt">{{project.project_title}}</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>{{project.project_type| titlecase }}</p>
                                                </div>
                                            </div>
                                            <span class="green_div">Project Completed</span>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining==0">
                                            <h1>Today </h1>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining !=0">
                                            <h1>{{project.days_remaining }} days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>{{project.description}}</p>
                                    </div>
                                    <div class="btn_tgs">

                                        <!-- <button class="Otline_grey grey">
                                         <!--   {{project.tags.hasWallpaper ? project.tags.hasWallpaper : 'No wallpaper'}}
                                        </button>-->
                                        <button class=" grey tag fill bid-accepted">
                                            Project Completed
                                        </button>
                                        <button class="grey project-pending"
                                            *ngIf="!project?.review_detail ||  !project?.review_detail?.customer_id">
                                            Provide Rating
                                        </button>

                                        <button class=" grey tag fill bid-accepted"
                                            *ngIf="project?.review_detail?.customer_id">
                                            Review Provided
                                        </button>
                                        <button class="Otline_grey grey">
                                            {{project.room ? project.room.length : 'No'}} Rooms

                                        </button>
                                        <button class=" grey tag fill bid-accepted">
                                            {{project.bids.length}} bids
                                        </button>

                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt" (click)="archive(project.id);modalFlagnew=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <!-- <div class="div_in" (click)="deleteproj(project.id)" > -->
                                        <div class="div_in" (click)="delet(project.id);modalFlag=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </nb-tab>
                    <nb-tab id="tab5" [active]="this.selectedtab == 'five' ? true : false"
                        tabTitle="Archived({{archived}})">
                        <div class="card_my_prjet card " *ngFor="let project of archivedJobs">
                            <div class="div_cd d-flex">
                                <div class="img_nw" *ngIf="project.images.length">
                                    <img [src]="project.images[0].image" alt="">
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a (click)="yourFunc('five', project.id)">
                                                    <span class="ned_urgnt">{{project.project_title}}</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>{{project.project_type| titlecase }}</p>
                                                </div>
                                            </div>
                                            <span class="green_div"
                                                *ngIf="!project?.customer_status && !project?.painter_status">Project
                                                {{project.status | titlecase}}</span>
                                            <span class="green_div"
                                                *ngIf="project?.customer_status && project?.painter_status">Project
                                                Completed</span>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining==0">
                                            <h1>Today </h1>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining !=0">
                                            <h1>{{project.days_remaining }} days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>{{project.description}}</p>
                                    </div>
                                    <div class="btn_tgs">

                                        <!-- <button class="Otline_grey grey">
                                         <!--   {{project.tags.hasWallpaper ? project.tags.hasWallpaper : 'No wallpaper'}}
                                        </button>-->
                                        <button class="Otline_grey grey">
                                            {{project.room ? project.room.length : 'No'}} Rooms

                                        </button>
                                        <button class=" grey tag fill bid-accepted">
                                            {{project.bids.length}} bids
                                        </button>

                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt" (click)="archive(project.id);modalFlagnew=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <!-- <div class="div_in" (click)="deleteproj(project.id)"> -->
                                        <div class="div_in" (click)="delet(project.id);modalFlag=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </nb-tab>
                    <nb-tab [active]="this.selectedtab == 'six' ? true : false" tabTitle="Disputed({{disputed}})">
                        <div class="card_my_prjet card " *ngFor="let project of disputedjobs">
                            <div class="div_cd d-flex">
                                <div class="img_nw" *ngIf="project.images.length">
                                    <img [src]="project.images[0].image" alt="">
                                </div>
                                <div class="div_pntr">
                                    <div class="div_hrd d-flex">
                                        <div class="div_prject_text">
                                            <div class="div_spn">
                                                <a (click)="yourFunc('six', project.id)">
                                                    <span class="ned_urgnt">{{project.project_title}}</span>
                                                </a>
                                                <div class="iner_degn">
                                                    <p>{{project.project_type| titlecase }}</p>
                                                </div>
                                            </div>
                                            <span class="green_div">Project Disputed</span>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining==0">
                                            <h1>Today </h1>
                                        </div>
                                        <div class="ago_tym" *ngIf="project.days_remaining !=0">
                                            <h1>{{project.days_remaining }} days ago</h1>
                                        </div>
                                    </div>
                                    <div class="div_descrptin">
                                        <p>{{project.description}}</p>
                                    </div>
                                    <div class="btn_tgs">

                                        <!-- <button class="Otline_grey grey">
                                         <!--   {{project.tags.hasWallpaper ? project.tags.hasWallpaper : 'No wallpaper'}}
                                        </button>-->
                                        <button class="Otline_grey grey">
                                            {{project.room ? project.room.length : 'No'}} Rooms

                                        </button>
                                        <button class=" grey tag fill bid-accepted">
                                            {{project.bids.length}} bids
                                        </button>

                                    </div>
                                    <div class="div_dlt_btn">
                                        <div class="div_in mar_ryt">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M21 8V21H3V8" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M23 3H1V8H23V3Z" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M10 12H14" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="div_in" (click)="modalFlag=true;">
                                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path d="M3 6H5H21" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path
                                                        d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                                                        stroke="#200920" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                    <path d="M10 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M14 11V17" stroke="#200920" stroke-width="2"
                                                        stroke-linecap="round" stroke-linejoin="round" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </nb-tab>
                </nb-tabset>

            </nb-card-body>
        </nb-card>




    </div>

</div>

<app-modal class="project_report_mdl dlt_prfl_mdl prjctdlt_modl" [displayModal]="modalFlag"
    (cancelButtonClicked)="closeComplainModal()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <p class="title exchng_clr">Delete Project?</p>
                <p>
                    Are you sure you want to delete this project?
                </p>
                <div class="d-flex dbl_btns">
                    <button class="custom-button" (click)="deleteproj()">Delete</button>
                    <button class="border_button" (click)="this.modalFlag = false">Cancel</button>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>


<app-modal class="project_report_mdl dlt_prfl_mdl prjctdlt_modl" [displayModal]="modalFlagnew"
    (cancelButtonClicked)="closeComplainModal()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <p class="title exchng_clr">Archive Project?</p>
                <p>
                    Are you sure you want to arcive this project? It will be vanished from the list
                </p>
                <div class="d-flex dbl_btns">
                    <button class="custom-button" (click)="rchiveproject()">Archive</button>
                    <button class="border_button" (click)="this.modalFlagnew = false">Cancel</button>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>

<app-modal class="project_report_mdl dlt_prfl_mdl prjctdlt_modl position-relative" [displayModal]="modalusrdetail">
    <slot-content>
        
            <span class="cross_hide" ></span>
       
        <form class="form" [formGroup]="profileForm">

            <div class="bid_detail_modal usr_dtl_mdl_wrp">
                <div class="bid-card-wrapper">
                    <p class="title exchng_clr">Enter Details</p>
                    <!-- <p>
                    Are you sure you want to arcive this project? It will be vanished from the list
                </p> -->
                    <div class="usr_dtls_wrp">
                        <div class="dtls_flx">
                            <div class="cstm_inputs">
                                <label class="inp_label">Name</label>
                                <div class="input-wrapper">
                                    <input class="n_cstm" formControlName="name" placeholder="Enter name">
                                    <div class="error-label"
                                        *ngIf="!profileForm.controls['name'].valid && (profileForm.controls['name'].touched)">
                                        <p class="error-label"
                                            *ngIf="profileForm.controls['name'].hasError('required')">
                                            Please enter name
                                        </p>
                                    </div>
                                    <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                                </div>
                            </div>
                            <div class="cstm_inputs">
                                <label class="inp_label">Phone</label>
                                <div class="input-wrapper">
                                    <input formControlName="phone" maxLength="15"
                                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        placeholder="(123)-456-7890">
                                    <div class="error-label"
                                        *ngIf="!profileForm.controls['phone'].valid && (profileForm.controls['phone'].touched)">
                                        <p class="error-label"
                                            *ngIf="profileForm.controls['phone'].hasError('required')">
                                            <a class="error-wrp">
                                                Please enter valid phone number
                                            </a>
                                        </p>
                                    </div>
                                    <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="">

                            <div class="cstm_inputs mb_20">
                                <label class="inp_label">Street Address</label>
                                <div class="input-wrapper">
                                    <input formControlName="address" placeholder="4958 Ethels Lane">
                                    <div class="error-label"
                                        *ngIf="!profileForm.controls['address'].valid && (profileForm.controls['address'].touched)">
                                        <p class="error-label"
                                            *ngIf="profileForm.controls['address'].hasError('required')">
                                            Please enter address
                                        </p>
                                    </div>
                                    <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                                </div>
                            </div>
                            <div class="cstm_inputs mb_20">
                                <label class="inp_label">Zip code</label>
                                <div class="input-wrapper">
                                    <input formControlName="zipcode" placeholder="33602" maxlength="6" minlength="3"
                                        (keyup)="checkzip($event)"
                                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');">
                                    <div class="error-label"
                                        *ngIf="!profileForm.controls['zipcode'].valid && (profileForm.controls['zipcode'].touched)">
                                        <p class="error-label"
                                            *ngIf="profileForm.controls['zipcode'].hasError('required')">
                                            Please enter zip code
                                        </p>
                                    </div>
                                    <div class="error-label" *ngIf="errorpin">
                                        <p class="error-label" *ngIf="errorpin">
                                            {{errorpin}}
                                        </p>
                                    </div>
                                    <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                                </div>
                            </div>
                            <div class="cstm_inputs mb_20">
                                <label class="inp_label">City</label>
                                <div class="input-wrapper">
                                    <input formControlName="city" placeholder="City" readonly
                                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                                    <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                                </div>
                            </div>
                            <div class="cstm_inputs mb_20">

                                <label class="inp_label">State</label>


                                <div class="input-wrapper">
                                    <input formControlName="state" placeholder="State" readonly
                                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                                    <!-- <div class="error-label">
                                        <p class="error-label">
                                            <a class="error-wrp">
                                                <img src="assets/icons/errors/error-solid.svg" class="error-icon">
                                            </a>
                                        </p>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex dbl_btns">
                        <button class="custom-button" (click)="changeprofile()">Save</button>

                    </div>
                </div>
            </div>
        </form>
    </slot-content>


</app-modal>