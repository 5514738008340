import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'defaultProjectImage'})
export class DefaultProjectImagePipe implements PipeTransform {

  transform(image: string): any {
    if (image != null && image !== '' && image !== 'null') {
      return image;
    }
    return 'assets/logo-better-dimension.png';
  }

}
