import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from './token-storage.service';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {UserDataWithConfigsAndAddress} from '../model/UserDataWithConfigsAndAddress';
import {CustomHttpParameterCodec} from '../_helpers/requests/CustomHttpParameterCodes';
import {Address} from '../model/Address';
import {AccountSettings} from '../model/AccountSettings';
import {ESettingKey} from '../model/enums/ESettingKey';
import { map, tap, finalize } from "rxjs/operators";


const API = environment.SERVER_BASE_URL + 'user';
const API_data = environment.SERVER_BASE_URL ;

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public encoder: CustomHttpParameterCodec;
  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
    this.encoder = new CustomHttpParameterCodec();
  }

  getMyBasicData(): Observable<UserDataWithConfigsAndAddress> {
    return this.http.get<UserDataWithConfigsAndAddress>(API);
  }
  changePassword(newPassword: string, currentPassword: string): Observable<any> {
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, this.tokenStorage.getEmail(), 'email');
    return this.http.patch(API + '/change-password', {
      newPassword,
      currentPassword,
    }, {params: queryParams});
  }
  changeContact(newEmail: string, newPhoneNumber: string): Observable<any> {
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, this.tokenStorage.getEmail(), 'email');
    queryParams = this.encoder.addToHttpParams(queryParams, newEmail, 'newEmail');
    queryParams = this.encoder.addToHttpParams(queryParams, newPhoneNumber, 'newPhoneNumber');
    return this.http.patch(API + '/change-contact/', null, {params: queryParams});
  }
  changeAddress(address: Address): Observable<any> {
    if (address === null || address === undefined) {
      throw new Error('Required parameter address was null or undefined when calling changeAddress.');
    }
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, this.tokenStorage.getEmail(), 'email');
    return this.http.patch<any>(API + '/change-address', address, {params: queryParams});
  }
   resetpass(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${API_data}users/changePassword`;
    return this.http.post(API_URL, data).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  changeAccountSettings(accountSettings: AccountSettings): Observable<any> {
    if (accountSettings === null || accountSettings === undefined) {
      throw new Error('Required parameter accountSettings was null or undefined when calling changeAccountSettings.');
    }
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, this.tokenStorage.getEmail(), 'email');
    return this.http.patch<any>(API + '/change-account-settings', accountSettings, {params: queryParams});
  }

  getAccountSettingValue(settingKey: ESettingKey): Observable<boolean> {
    if (settingKey === null || settingKey === undefined) {
      throw new Error('Required parameter settingKey was null or undefined when calling getUserSettingValue.');
    }
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, this.tokenStorage.getEmail(), 'email');
    return this.http.get<boolean>(API + '/account-setting/' + settingKey, {params: queryParams});
  }
  changeProfileImage(file?: string): Observable<any> {
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling changeProfileImage.');
    }
    const body = new FormData();
    body.append('file', file);

    return this.http.post(API + '/change-profile-image', body);
  }
}
