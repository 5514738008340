<form [formGroup]="newOfferForm">
  <div class="input-wrapper">
    <div class="input-with-hint">
      <input formControlName="newPrice" [value]='5' [type]="'text'" placeholder="{{myBid.price | currency:'USD'}}" (blur)="preFormatValueOnBlur($event)" (click)="undoCurrencyPipe($event)" (input)="consumeWrongCharacters($event)">
      <span class="hint" (click)="toggleModalVisibility()" *ngIf="!readOnly && !isNewOfferButtonDisabled() && valueChanged">
      <a class="hint-text" >{{buttonText}}</a>
      </span>
    </div>
  </div>
</form>
<app-modal *ngIf="modalVisible" [displayModal]="modalVisible"
           [title]="'Modify offer'"
           [subclaim]="'Are you sure you want to change your offer to <b>' + (newOfferForm.controls.newPrice.value.replace('$', '').replace(',','').replace('.','') | currency: 'USD') + '</b>?\n Your current bid will be is going to be deleted and new bid will be placed instead.'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="emitValue()"
           (cancelButtonClicked)="toggleModalVisibility()"></app-modal>
