import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {KeyBoardListenerComponent} from './KeyBoardListenerComponent';


@Component({
  template: ''
})
export abstract class BaseUserInputDataComponent extends KeyBoardListenerComponent {
  abstract userDataForm: FormGroup;

  protected phoneNumberPattern = '[2-9]{1}[0-9]{2}-[2-9]{1}[0-9]{2}-[0-9]{4}';
  protected fullNamePattern = '^[a-zA-Z]{2,}(?: [a-zA-Z]+){1,2}$';
  protected streetPattern = '(^\\d+\\s[A-z]+(\\s[A-z]+)*)|(^[A-z]+(\\s[A-z]+)*\\s\\d+)';
  protected cityNamePattern = '^([a-zA-Z\u0080-\u024F]+(?:. |-| |\'))*[a-zA-Z\u0080-\u024F]*$';
  protected zipCodePattern = '[0-9]{5}';
  protected passwordMinLength = 8;
  protected cityMinLength = 2;
  protected US_PHONE_PREFIX = '1';
  protected phoneNumberIncorrectPatternErrorMsg = 'This phone number is incorrect!';
  protected phoneNumberInUseErrorMsg = 'This phone number already in use!';
  protected emailInUseErrorMsg = 'This email is already in use!';
  protected emailIncorrectPatternErrorMsg = 'This email is invalid!';
  protected cityIncorrectPatternErrorMsg = 'This city name is invalid!';
  protected streetIncorrectPatternErrorMsg = 'This address is invalid!';
  protected zipCodeIncorrectPatternErrorMsg = 'This zip code is invalid!';
  protected fullNameIncorrectPatternErrorMsg = 'This full name is invalid!';
  protected passwordMustMatchErrorMsg = 'Password must match!';
  protected fieldRequiredErrorMsg = 'This field is required!';
  protected emailOrPasswordInvalidErrorMsg = 'Invalid email or password!';
  protected passwordLengthErrorMsg = 'Password must be ' + this.passwordMinLength + ' characters or longer!';
  protected cityLengthErrorMsg = 'City must be ' + this.cityMinLength + ' characters or longer!';


  isPwVisible = false;

  fullNameErrorMessage = '';
  cityErrorMessage = '';
  stateErrorMessage = '';
  zipCodeErrorMessage = '';
  streetErrorMessage = '';
  emailErrorMessage = '';
  phoneNumberErrorMessage = '';
  passwordErrorMessage = '';
  confirmPasswordErrorMessage = '';

  get password(): any {
    return this.userDataForm.get('password');
  }
  get currentPassword(): any {
    return this.userDataForm.get('currentPassword');
  }

  get confirmPassword(): any {
    return this.userDataForm.get('confirmPassword');
  }

  validatePassword(): boolean {
    return (this.password.invalid && (this.password.dirty || this.password.touched));
  }

  checkPasswordForErrors(): boolean {
    this.passwordErrorMessage = '';
    if (this.password.errors?.required) {
      this.passwordErrorMessage = this.fieldRequiredErrorMsg;
    }
    if (this.password.errors?.minlength) {
      this.passwordErrorMessage = this.passwordLengthErrorMsg;
    }
    if (this.password.errors?.invalidCredentials) {
      this.passwordErrorMessage = this.emailOrPasswordInvalidErrorMsg;
    }
    return this.password.errors;
  }

  checkCurrentPasswordForErrors(): boolean {
    if (this.currentPassword.value != null && this.currentPassword.value !== '') {
      return false;
    }
    return true;
  }

  checkConfirmPasswordForErrors(): boolean {
    this.confirmPasswordErrorMessage = '';
    if (this.confirmPassword.errors?.required) {
      this.confirmPasswordErrorMessage = this.fieldRequiredErrorMsg;
    }
    if (this.confirmPassword.errors?.minlength) {
      this.confirmPasswordErrorMessage = this.passwordLengthErrorMsg;
    }
    if (this.confirmPassword.errors?.mustMatch) {
      this.confirmPasswordErrorMessage = this.passwordMustMatchErrorMsg;
    }
    return this.confirmPassword.errors;
  }

  validateConfirmPassword(): boolean {
    return (this.confirmPassword.invalid && (this.confirmPassword.dirty || this.confirmPassword.touched));
  }

  togglePasswordVisibility(): void {
    this.isPwVisible = !this.isPwVisible;
  }

  emailAddressChanges(): void {
    const subscription = this.emailAddress.valueChanges.subscribe(() => {
      if (this.emailAddress.hasError('emailAlreadyInUse')) {
        this.emailAddress.setErrors({emailAlreadyInUse: false});
      }
    });
    subscription.unsubscribe();
  }

  emailOrPhoneChanges(): void {
    const sub = this.userDataForm.valueChanges.subscribe(() => {
      if (this.emailAddress.errors?.emailAlreadyInUse || this.phoneNumber.errors?.phoneAlreadyInUse) {
        this.emailAddress.updateValueAndValidity({emitEvent: false});
        this.phoneNumber.updateValueAndValidity({emitEvent: false});
      }
      sub.unsubscribe();
    });
  }

  validateEmail(): boolean {
    return (this.emailAddress.invalid && (this.emailAddress.dirty || this.emailAddress.touched));
  }

  validatePhoneNumber(): boolean {
    return (this.phoneNumber.invalid && (this.phoneNumber.dirty || this.phoneNumber.touched));
  }
  validateFullName(): boolean {
    return (this.fullName.invalid && (this.fullName.dirty || this.fullName.touched));
  }

  checkFullNameForErrors(): boolean {
    if (this.fullName.errors?.required) {
      this.fullNameErrorMessage = this.fieldRequiredErrorMsg;
    }
    if (this.fullName.errors?.pattern) {
      this.fullNameErrorMessage = this.fullNameIncorrectPatternErrorMsg;
    }
    return this.fullName.errors;
  }

  checkForPhoneNumberErrors(): boolean {
    this.phoneNumberErrorMessage = '';
    if (this.phoneNumber.errors?.required) {
      this.phoneNumberErrorMessage = this.fieldRequiredErrorMsg;
    }
    if (this.phoneNumber.errors?.minlength) {
      this.phoneNumberErrorMessage = this.phoneNumberIncorrectPatternErrorMsg;
    }
    if (this.phoneNumber.errors?.maxlength) {
      this.phoneNumberErrorMessage = this.phoneNumberIncorrectPatternErrorMsg;
    }
    if (this.phoneNumber.errors?.pattern) {
      this.phoneNumberErrorMessage = this.phoneNumberIncorrectPatternErrorMsg;
    }
    if (this.phoneNumber.errors?.phoneAlreadyInUse) {
      this.phoneNumberErrorMessage = this.phoneNumberInUseErrorMsg;
    }
    return this.phoneNumber.errors;
  }

  checkForEmailErrors(): boolean {
    this.emailErrorMessage = '';
    if (this.emailAddress.errors?.required) {
      this.emailErrorMessage = this.fieldRequiredErrorMsg;
    } else if (this.emailAddress.errors?.emailAlreadyInUse) {
      this.emailErrorMessage = this.emailInUseErrorMsg;
    } else if (this.emailAddress.errors?.invalidCredentials) {
      this.emailErrorMessage = this.emailOrPasswordInvalidErrorMsg;
    } else if (this.emailAddress.errors) {
      this.emailErrorMessage = this.emailIncorrectPatternErrorMsg;
    }
    return this.emailAddress.errors;
  }

  checkForCityErrors(): boolean {
    this.cityErrorMessage = '';
    if (this.city.errors?.required) {
      this.cityErrorMessage = this.fieldRequiredErrorMsg;
    }
    if (this.city.errors?.minlength) {
      this.cityErrorMessage = this.cityLengthErrorMsg;
    }
    if (this.city.errors?.pattern) {
      this.cityErrorMessage = this.cityIncorrectPatternErrorMsg;
    }
    return this.city.errors;
  }

  checkForStateErrors(): boolean {
    this.stateErrorMessage = '';
    if (this.state.errors?.required) {
      this.stateErrorMessage = this.fieldRequiredErrorMsg;
    }
    return this.state.errors;
  }

  checkForStreetErrors(): boolean {
    this.streetErrorMessage = '';
    if (this.street.errors?.required) {
      this.streetErrorMessage = this.fieldRequiredErrorMsg;
    }
    if (this.street.errors?.pattern) {
      this.streetErrorMessage = this.streetIncorrectPatternErrorMsg;
    }
    return this.street.errors;
  }

  checkForZipCodeErrors(): boolean {
    this.zipCodeErrorMessage = '';
    if (this.zipCode.errors?.required) {
      this.zipCodeErrorMessage = this.fieldRequiredErrorMsg;
    }
    if (this.zipCode.errors?.pattern) {
      this.zipCodeErrorMessage = this.zipCodeIncorrectPatternErrorMsg;
    }
    return this.zipCode.errors;
  }


  get emailAddress(): any {
    return this.userDataForm.get('emailAddress');
  }

  get phoneNumber(): any {
    return this.userDataForm.get('phoneNumber');
  }

  get city(): any {
    return this.userDataForm.get('city');
  }
  get state(): any {
    return this.userDataForm.get('state');
  }
  get street(): any {
    return this.userDataForm.get('street');
  }
  get zipCode(): any {
    return this.userDataForm.get('zipCode');
  }
  get fullName(): any {
    return this.userDataForm.get('fullName');
  }


  validateCity(): boolean {
    return (this.city.invalid && (this.city.dirty || this.city.touched));
  }
  validateState(): boolean {
    return (this.state.invalid && (this.state.dirty || this.state.touched));
  }
  validateStreet(): boolean {
    return (this.street.invalid && (this.street.dirty || this.street.touched));
  }
  validateZipCode(): boolean {
    return (this.zipCode.invalid && (this.zipCode.dirty || this.zipCode.touched));
  }

  phoneNumberChange(event: any): void {
    const value = event.target.value;
    if (value.length === 3 || value.length === 7) {
      event.target.value += '-';
    }
  }
  setState(event: any): void {
    this.state.markAsTouched();
    this.userDataForm.controls.state.setValue(event);
  }
  setCity(event: any): void {
    this.city.markAsTouched();
    this.userDataForm.controls.city.setValue(event);
  }

  checkForAddressErrors(): boolean {
    return this.checkForStateErrors() || this.checkForCityErrors() || this.checkForZipCodeErrors() || this.checkForStreetErrors();
  }
}
