import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatchValidator } from '../../../../../_helpers/must-match.validator';
import { BaseUserInputDataComponent } from '../../../../../components/MAIN_COMPONENTS/BaseUserInputDataComponent';
import { UserService } from '../../../../../services/user.service';
import { UserDataWithConfigsAndAddress } from '../../../../../model/UserDataWithConfigsAndAddress';
import { TokenStorageService } from '../../../../../services/token-storage.service';
import { Address } from '../../../../../model/Address';
import { ESettingKey } from '../../../../../model/enums/ESettingKey';
import { AccountSettings } from '../../../../../model/AccountSettings';
import { ERole } from '../../../../../model/enums/ERole';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import { AuthService } from '../../../../../services/auth.service';

import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent extends BaseUserInputDataComponent implements OnInit {
  //location: any

  modalFlag: boolean = false;
  profileForm!: FormGroup
  imgForm!: FormGroup
  stay_logged_in: boolean = false;
  send_email: boolean = false
  semail: any
  stay_log: any
  previewImage2: any
  errorpin: any
  savedstate: any
  selectedFile2: any;
  passwordform: FormGroup
  error: any
  isFormSubmitted = false;
  pass: String = 'password'
  confirmPass: String = 'password'
  Cpass: String = 'password';
  path: any = 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'
  profile: any
  userDataForm = this.formBuilder.group({
    emailAddress: ['', [Validators.required]],
    phoneNumber: ['', [Validators.required, Validators.minLength(9), Validators.pattern(this.phoneNumberPattern)]],
    password: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(this.passwordMinLength)]],
    // city: ['', [Validators.required, Validators.minLength(this.cityMinLength), Validators.pattern(this.cityNamePattern)]],
    state: [''],
    street: ['', [Validators.required, Validators.pattern(this.streetPattern)]],
    zipCode: ['', [Validators.required, Validators.pattern(this.zipCodePattern)]],
    latitude: ['', Validators.required],
    longitude: ['', Validators.required],
    location: ['', Validators.required],
    currentPassword: [''],

  }, {
    validator: MustMatchValidator('password', 'confirmPassword')
  });

  myData!: UserDataWithConfigsAndAddress;
  eSettingKey = ESettingKey;
  ERole = ERole;
  changeContactModalVisible = false;
  changePasswordModalVisible = false;
  changeAddressModalVisible = false;
  isPasswordNotChangedDueToError = false;
  isContactNotChangedDueToError = false;
  contactNotChangedDueToErrorSubclaim = '';
  passwordNotChangedDueToErrorSubclaim = '';

  constructor(private formBuilder: FormBuilder, private userService: UserService, public tokenStorageService: TokenStorageService, private authService: HttpserviceService, private toastr: ToastrService, private service: AuthService, private router: Router) {
    super();
    this.loadMyBasicData();
    this.profileForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
      //  name: ['',[Validators.required]],
      //  about_me: [''],
      profile_image: [null],
      address: ['', [Validators.required, Validators.maxLength(15)]],
      city: [''],
      state: [''],
      zipcode: ['', [Validators.required]],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      location: ['', Validators.required]

    })
    this.passwordform = this.formBuilder.group({
      currPswd: ['', [Validators.required]],
      newPswd: ['', [Validators.required]],
      conPswd: ['', [Validators.required]],

    }, {
      validator: this.checkPassword,
    });
  }

  ngOnInit(): void {
    this.getprofile()

  }

  changeContact(): void {
    let formattedPhoneNumber = '';
    if (this.phoneNumber.value != null && this.phoneNumber.value !== '') {
      //formattedPhoneNumber = this.US_PHONE_PREFIX + this.phoneNumber.value.replaceAll('-', '');
      formattedPhoneNumber = this.phoneNumber.value.replaceAll('-', '');
    }
    this.userService.changeContact(this.emailAddress.value, formattedPhoneNumber).subscribe(res => {
      this.tokenStorageService.logout();
    }, error => {
      this.changeContactModalVisible = false;
      this.displayContactNotChangedDueToErrorModal(error);
    });
  }

  changePassword(): void {
    this.userService.changePassword(this.password.value, this.currentPassword.value).subscribe(res => {
      this.tokenStorageService.logout();
    }, error => {
      this.changePasswordModalVisible = false;
      this.displayPasswordNotChangedDueToErrorModal(error.status);
    });
  }

  changeprofile() {

    let obj = {
      profile_image: '',
      full_name: this.profileForm.value.name,
      email: this.profileForm.value.email,
      phone_number: this.profileForm.value.phone,
    }
    const formdata = new FormData()
    formdata.append('full_address', this.profileForm?.get('address')?.value)

    formdata.append('profile_image', this.selectedFile2)
    formdata.append('phone_number', this.profileForm.value.phone)
    formdata.append('latitude', this.profileForm?.get('latitude')?.value)
    formdata.append('longitude', this.profileForm?.get('longitude')?.value)
    formdata.append('state', this.profileForm?.get('state')?.value)
    formdata.append('location', this.profileForm?.get('location')?.value)

    formdata.append('zip_code', this.profileForm?.get('zipcode')?.value)
    formdata.append('stay_logged_in', this.stay_log)
    formdata.append('send_email', this.semail)

    formdata.append('city', this.profileForm?.get('city')?.value)

    formdata.append('email', this.profileForm?.get('email')?.value)

    this.authService.updateprofile(formdata).subscribe((response: any) => {
      ///  this.profile= response.profileData
      this.toastr.success('Profile Updated Successfully!', 'Success!');
      this.getprofile()
      window.location.reload();
    })
  }

  fileUpload2(event: any) {
    console.log(event)

    console.log(event, event.target.files[0])
    if (event.target.files[0] > 2000) {
      this.authService.showMessage({
        message: 'Please select file less than 2kb'
      })
      return;
    }
    this.selectedFile2 = event.target.files[0]
    const reader = new FileReader();
    reader.onload = function (this: any) {
      this.profileForm.patchValue({
        sign_img: reader.result

      })
      this.previewImage2 = reader.result;

    }.bind(this)
    reader.readAsDataURL(this.selectedFile2);
    console.log(this.selectedFile2)
    //  this.previewImage2 =this.selectedFile2.name;
  }
  click(a: any) {
    console.log(a)
    this.stay_logged_in = !this.stay_logged_in;
    console.log(this.stay_logged_in)
    this.stay_logged_in = !this.stay_logged_in;
    console.log(this.stay_logged_in)
    if (this.stay_logged_in == true) {
      this.stay_log = 'true'

    } else {
      this.stay_log = 'false'
    }
  }
  clicka(a: any) {
    this.send_email = !this.send_email;
    console.log(this.send_email)
    if (this.send_email == true) {
      this.semail = 'true'

    } else {
      this.semail = 'false'
    }

  }
  showodal() {
    this.modalFlag = true
  }
  checkPassword(group: FormGroup) {
    let pass = group.controls.newPswd.value;
    let confirm_password = group.controls.conPswd.value;
    var flag = false
    let returnable: any = {
      pwdNotSame: null,
    }
    if (pass != confirm_password) {
      returnable.pwdNotSame = true
      flag = true
    }
    return flag ? returnable : null;
  }
  handleAddressChange(event: any) {
    // console.log(this.location)
    console.log('address change', event, event.geometry.location.lat(), event.geometry.location.lng());
    this.profileForm.controls['latitude'].patchValue(event.geometry.location.lat());
    this.profileForm.controls['longitude'].patchValue(event.geometry.location.lng());
    this.profileForm.controls['location'].patchValue(event.formatted_address);
    this.profileForm.controls['state'].patchValue(event.name);

  }
  deleteacc() {
    this.authService.logout();
    let obj = {
      'id': this.profile.id
    }
    this.authService.deleteaccount(obj).subscribe((response: any) => {
      this.toastr.success('Profile deleted Successfully!', 'Success!');
      setTimeout(() => {
        this.router.navigate(['/login']);
        localStorage.clear();

      }, 2000)


    })
  }
  clearLocation() {
    console.log('test')
  }
  onEyeClick(field: any, type: any) {
    if (field == 'pass') {
      this.pass = type
    }
    else if (field == 'Cpass') {
      this.Cpass = type
    } else {
      this.confirmPass = type
    }
  }
  submitForm(): void {
    this.isFormSubmitted = true;
    let obj = {
      old_password: this.passwordform.controls.currPswd.value,
      new_password: this.passwordform.controls.newPswd.value,
      //      conPswd: this.passwordform.controls.conPswd.value,

    }
    const email = this.passwordform.controls.currPswd.value;
    this.authService.resetpass(obj).subscribe((response: any) => {
      this.toastr.success('Password changed!', 'Success!');
      this.error = ""
    },
      (error: any) => {
        this.error = error.error.errors.msg
        console.log(error.error.errors.msg);
      }
    );
  }
  getprofile() {
    this.authService.getprofile().subscribe((response: any) => {
      this.profile = response.profileData
      this.stay_log = this.profile.stay_logged_in
      this.semail = this.profile.send_email
      if (this.profile.stay_logged_in == "true") {
        this.stay_logged_in = true
      } else {
        this.stay_logged_in = false
      }
      if (this.profile.send_email == "true") {
        this.send_email = true
      } else {
        this.send_email = false
      }

      //     this.send_email = this.profile.send_email
      this.profileForm.patchValue({
        'email': this.profile.email,
        'phone': this.profile.phone_number,
        'address': this.profile.full_address,
        'location': this.profile.location,
        'latitude': this.profile.latitude,
        'longitude': this.profile.longitude,
        'city': this.profile.city,
        'zipcode': this.profile.zip_code,
        'state': this.profile.state
      })
      if (this.profile.profile_image != 'undefined') {
        this.previewImage2 = this.profile.profile_image
        this.selectedFile2 = this.profile.profile_image
        console.log(this.profileForm, this.previewImage2)
      }
      if(this.profile.state){
       this.savedstate = this.profile.state
      }
     
      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  changeAddress(): void {
    this.userService.changeAddress(this.buildChangeAddressRequest()).subscribe(res => {
      this.loadMyBasicData();
      this.userDataForm.reset();
      this.changeAddressModalVisible = false;
    }, error => {
      console.log(error);
    });
  }

  changeSetting(event: any, settingKey: ESettingKey): void {
    this.userService.changeAccountSettings(this.buildChangeAccountSettingRequest(event.target.checked, ESettingKey[settingKey])).subscribe(res => {
      if (settingKey === ESettingKey.STAY_SIGNED_IN) {
        if (event.target.checked === true) {
          this.tokenStorageService.saveToken(this.tokenStorageService.getToken() as string, true);
        } else {
          this.tokenStorageService.removeTokenFromLocalStorage();
        }
      }
    });
  }

  checkzip(e: any) {
    // console.log(e, this.location)

    let obj = {
      'pin_code': this.userDataForm.value.zip
    }
    this.service.checkzipcode(this.profileForm.value.zipcode).subscribe((res: any) => {
      if (res) {
        this.errorpin = ''
        this.profileForm.controls['latitude'].patchValue(res.response[0].latitude);
        this.profileForm.controls['longitude'].patchValue(res.response[0].longitude);
        this.profileForm.controls['city'].patchValue(res.response[0].city);
        this.profileForm.controls['state'].patchValue(res.response[0].administrativeLevels?.level1long);
        console.log(this.profileForm)
      }
    }, error => {
      console.log(error);
      this.errorpin = 'Please enter valid zip code'
    })
  }

  loadMyBasicData(): void {
    this.userService.getMyBasicData().subscribe(res => {
      this.myData = res;
    });
  }

  changePasswordModalToggleVisibility(): void {
    this.changePasswordModalVisible = !this.changePasswordModalVisible;
  }

  changeContactModalToggleVisibility(): void {
    this.changeContactModalVisible = !this.changeContactModalVisible;
  }

  changeAddressModalToggleVisibility(): void {
    this.changeAddressModalVisible = !this.changeAddressModalVisible;

  }

  buildChangeAddressRequest(): Address {
    return {
      fullAddress: this.street.value,
      city: this.city.value,
      zipCode: this.zipCode.value,
      state: this.state.value,
    };
  }

  buildChangeAccountSettingRequest(settingValue: boolean, settingKeyValue: string): AccountSettings {
    return {
      value: settingValue,
      settingKey: settingKeyValue,
    };
  }

  displayPasswordNotChangedDueToErrorModal(status: number): void {
    this.isPasswordNotChangedDueToError = true;
    switch (status) {
      case 401:
        this.passwordNotChangedDueToErrorSubclaim = 'You have provided wrong current password';
        break;
      default:
        this.passwordNotChangedDueToErrorSubclaim = 'Unfortunately there were some issues during changing your password. Please try again.';
    }
  }

  displayContactNotChangedDueToErrorModal(error: any): void {
    this.isContactNotChangedDueToError = true;
    switch (error.status) {
      case 409:
        this.contactNotChangedDueToErrorSubclaim = error.error.errorMsg;
        break;
      default:
        this.contactNotChangedDueToErrorSubclaim = 'Unfortunately there were some issues during changing your contact data. Please try again.';
    }
  }

  onEnterClicked(): void {
  }

  closeComplainModal() {
    this.modalFlag = false
  }
}
