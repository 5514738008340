<div class="notification-wrapper" (click)="navigate()">
  <!-- <img *ngIf="icon" class="icon" [src]="icon"> -->
  <img class="icon" src ="./assets/icons/notifications/notification-icon-cancelled.png">
  <div class="notification-main">
    <div class="notification-title"><span>Bid for your project was cancelled</span> <img *ngIf="!clearTextVisible" src="assets/icons/notifications/notification-delete.png" (click)="deleteModalToggle($event)" alt="delete"></div>
    <div class="notification-content">We are sorry but it seems like Brush has cancelled
      bid for your project.</div>
    <footer>
      <div class="how-long-ago">17:13 | 2022-04-08</div>
      <div class="clear-content" (click)="deleteModalToggle($event)" *ngIf="clearTextVisible">
        <span class="clear-text">Clear</span>
        <img src="assets/icons/notifications/notification-delete.png" alt="delete">
      </div>
    </footer>
  </div>
</div>
<div class="notification-wrapper" (click)="navigate()">
  <!-- <img *ngIf="icon" class="icon" [src]="icon"> -->
  <img class="icon" src ="./assets/icons/notifications/notification-icon-star.png">
  <div class="notification-main">
    <div class="notification-title"><span>New Bid</span> <img *ngIf="!clearTextVisible" src="assets/icons/notifications/notification-delete.png" (click)="deleteModalToggle($event)" alt="delete"></div>
    <div class="notification-content">You have received new bid for your project</div>
    <footer>
      <div class="how-long-ago">17:13 | 2022-04-08</div>
      <div class="clear-content" (click)="deleteModalToggle($event)" *ngIf="clearTextVisible">
        <span class="clear-text">Clear</span>
        <img src="assets/icons/notifications/notification-delete.png" alt="delete">
      </div>
    </footer>
  </div>
</div>
<div class="notification-wrapper" (click)="navigate()">
  <!-- <img *ngIf="icon" class="icon" [src]="icon"> -->
  <img class="icon" src ="./assets/icons/notifications/notification-icon-cancelled.png">
  <div class="notification-main">
    <div class="notification-title"><span>Bid cancelled</span> <img *ngIf="!clearTextVisible" src="assets/icons/notifications/notification-delete.png" (click)="deleteModalToggle($event)" alt="delete"></div>
    <div class="notification-content">We are sorry but it seems like Brush has cancelled
      bid for your project.</div>
    <footer>
      <div class="how-long-ago">17:13 | 2022-04-08</div>
      <div class="clear-content" (click)="deleteModalToggle($event)" *ngIf="clearTextVisible">
        <span class="clear-text">Clear</span>
        <img src="assets/icons/notifications/notification-delete.png" alt="delete">
      </div>
    </footer>
  </div>
</div>
<app-modal [displayModal]="deleteMessageModalVisible"
           [title]="'Delete notification'"
           [subclaim]="'Are you sure you want to delete this notification?'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="deleteMessageEvent()"
           (cancelButtonClicked)="deleteModalToggle($event)"></app-modal>
