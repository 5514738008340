<div [ngClass]="tokenStorage.isUserPainter() && !isPending() ? 'wrapper column' : 'wrapper'" *ngIf="project">
  <!-- <div *ngIf="tokenStorage.isUserPainter() && !isPending()" class="cannot-post-new-bid">
    <nb-icon icon="close-square"></nb-icon>
      <div class="bid-ttl">You cannot post new bid for this project</div>
  </div> -->
  <div [ngClass]="[tokenStorage.isUserPainter() ? 'project-info width' : 'project-info width100' ]" >

  <section class="image-section-wrapper">
    <nb-icon icon="chevron-left-outline" *ngIf="isGalleryOverflown()" (pointerdown)="mouseDownSwipeLeft()" (pointerup)="clearInterval()" (click)="swipeLeft()"></nb-icon>

    <div id="images" class="image-overflow-x-scroll" #galleryOverflowScroll>
  <div class="image-section" #gallery>
    <div class="image-wrapper" *ngFor="let image of project.projectImages">
    <div class="image-sub-wrapper">
      <img [src]="image.image" alt="image" (click)="viewImage(image.image)">
    </div>
  </div>
    <div class="image-wrapper" *ngIf="project.projectImages.length === 0">
      <div class="image-sub-wrapper">
        <img  src="assets/logo-better-dimension.png" alt="image">
      </div>
    </div>
  </div>
    </div>
    <nb-icon icon="chevron-right-outline" *ngIf="isGalleryOverflown()" (pointerdown)="mouseDownSwipeRight()" (pointerup)="clearInterval()" (click)="swipeRight()"></nb-icon>
  </section>
  <section class="details">
    <div class="title-wrapper"><span class="title">{{project.projectDetails.title}}</span>
      <app-project-status [project]="project"></app-project-status>
      <button class="custom-button mark-as-completed-button" *ngIf="!tokenStorage.isUserPainter() && project.status === EProjectStatus.IN_PROGRESS && !project?.markedAsCompletedByCustomer && complain == null" (click)="markAsCompletedModalVisible = true">Mark Project as Complete</button>
    </div>
      <div class="sub-title">
    <div class="project-type">{{project.projectType | enumPipe: EProjectType: true: false}}</div>
        <div class="circle"></div>
    <div class="when-posted">Posted: {{project.projectDetails.days_remaining}} by {{project.customerDetails.full_name | firstName}}</div>
    </div>
    <div class="description"> <pre>{{project.projectDetails.description}}</pre></div>
    <div class="properties">
      <div class="property" *ngFor="let property of projectProperties | keyvalue: originalOrder">
      <div class="property-key">{{property.key}}</div>
      <div class="property-value">{{property.value}}</div>
      </div>
    </div>

    <div class="main-info-wrapper project-pending" *ngIf="tokenStorage.isUserCustomer() && isPending()">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text ">Your project is now in visible in our marketplace - stay tuned for Painters offers!</div>
    </div>
    <div class="main-info-wrapper project-pending" *ngIf="tokenStorage.isUserCustomer() && (isAwaiting() || isInProgress()) && complain == null">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text " *ngIf="isAwaiting()">You have accepted bid for project. It is on painter side to contact you now! If he gone missing for too long, please place an complaint</div>
      <div class="main-info-text " *ngIf="isInProgress()">Your project is during progress. If theres something wrong with painters behaviour, please let us know!</div>
      <button class="custom-button footer-button" (click)="openMakeComplainModal()">Raise an Issue</button>
    </div>
    <div class="main-info-wrapper project-pending" *ngIf="complain != null">
      <nb-icon icon="checkmark-circle-2"></nb-icon>
      <div class="main-info-text ">Is there something wrong with the job? Please let us know.</div>
      <button class="custom-button footer-button">Details</button>
    </div>
    <div class="report" (click)="reportModalVisible = true" *ngIf="this.tokenStorage.isUserPainter()">
      <span *ngIf="!project.reportPresent">Report This Project</span>
      <span *ngIf="project.reportPresent">You've reported this project</span>
    </div>
    <button class="custom-button mark-as-completed-button-res" *ngIf="!tokenStorage.isUserPainter() && project.status === EProjectStatus.IN_PROGRESS && !project?.markedAsCompletedByCustomer" (click)="markAsCompletedModalVisible = true">Mark Project as Complete</button>
    <div *ngIf="tokenStorage.isUserAdmin() && project.status === EProjectStatus.IN_DRAFT">
      <button class="custom-button margin-b15"  (click)="acceptDraftModalVisible = true">Accept draft</button>
      <button class="custom-button orange-theme"   (click)="prepareDraftRequiresChanges()">Draft requires changes</button>
    </div>


  </section>
  </div>
  <div class="bid-wrapper" *ngIf="userdetails=='painter' ">
    <div class="bid-content">
      <form [formGroup]="newOfferForm" >
        <div class="bid-ttl" *ngIf="myBid == null">Wait no more! Place a bid</div>
        <div class="bid-description" *ngIf="myBid == null">Your bid will be sent to project owner. After placing a bid it will be visible inside My bids section.</div>
        <div class="bid-ttl main-ttl"><span>Your Bid</span> <span *ngIf="myBid != null" class="view-details" (click)="navigateToBidDetails()"><span>View Details</span><nb-icon icon="arrow-forward-outline"></nb-icon></span> </div>
        <input class="new-bid-price" type="text" formControlName="newPrice" placeholder="$" (blur)="preFormatValueOnBlur($event)" (click)="undoCurrencyPipe($event)" (input)="consumeWrongCharacters($event)">
        <div class="bid-ttl">Cover Letter</div>
        <textarea formControlName="comment" placeholder="{{myBid == null ? '' : myBid.comment}}" rows="20"></textarea>
        <div class="bid-tip">You’ll only pay if the customer responds. After your bid will be accepted you will have to pay fee which value is calculated based on your rating and current job value.</div>
        <button class="custom-button" (click)="isPlaceBidModalVisible= true" [disabled]="(!newOfferForm.valid) || (myBid != null && ((myBid?.price | currency:'USD') == newOfferForm.controls['newPrice'].value) || myBid?.price == newOfferForm.controls['newPrice'].value|| myBid?.price == newOfferForm.controls['newPrice'].value)">SEND</button>
      </form>
    </div>
  </div>
</div>


<app-img-carousel *ngIf="selectedImage.length > 0"
                  (carouselClose)="selectedImage = ''"
                  [images]="project?.projectDetails?.imageURLs"
                  [selectedImage]="selectedImage"></app-img-carousel>

<app-modal [displayModal]="checkIfDisplay()"
           [title]="'Bid placement'"
           [subclaim]="myBid == null ? 'Do you want place a bid in the amount of <b>'+ (newOfferForm.controls['newPrice'].value |currency:'USD' )+ '</b>? Your bid will be sent to project owner. After placing a bid it will be visible inside My bids section.' : 'Are you sure you want to change your offer to <b>'+ (newOfferForm.controls['newPrice'].value |currency:'USD' )+ '</b>? Your current bid will be is going to be deleted and new bid will be placed instead.'"
           [confirmButtonText]="bidPlacedModalValues.btn"
           (cancelButtonClicked)="toggleOffAllModals()"
           (confirmButtonClicked)="isPlaceBidModalVisible ? sendBid() : toggleOffAllModals()">
</app-modal>
<app-modal [displayModal]="markAsCompletedModalVisible"
           [title]="'Mark Project as Completed'"
           [subclaim]="project?.markedAsCompletedByPainter ? 'Painter has marked project as <b>Completed</b>. Do you confirm it was done?' : 'Are you sure you want to mark project as <b>Completed</b>?'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="markCompleted()"
           (cancelButtonClicked)="markAsCompletedModalVisible = false"></app-modal>


<app-modal [displayModal]="acceptDraftModalVisible"
           [title]="'Accept draft'"
           [subclaim]="'Do you want to accept this project?'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="acceptDraft()"
           (cancelButtonClicked)="acceptDraftModalVisible = false"></app-modal>

<app-modal [displayModal]="draftRequiresChangesModalVisible"
           [title]="'Project draft changes'"
           [subclaim]="'Please provide necessary changes for project draft.'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="acceptDraftAfterChanges()"
           (cancelButtonClicked)="draftRequiresChangesModalVisible = false">

  <slot-content>
    <textarea class="content" rows="1" placeholder="Write a complain..." [(ngModel)]="changedTitle"></textarea>
    <textarea class="content" rows="5" placeholder="Write a complain..." [(ngModel)]="changedDescription"></textarea>
    <div class="single-file" *ngFor="let file of changedUrls; let i = index">
          <img [src]="file" alt="image" class="uploaded-image">
    </div>
  </slot-content>


</app-modal>



<app-modal [displayModal]="reportModalVisible"
           [title]="'Report Project'"
           [subclaim]="'Select reason for reporting this project'"
           [confirmButtonText]="'Send Report'"
           [confirmButtonDisabled]="selectedReportReason == null"
           (confirmButtonClicked)="sendReport()"
           (cancelButtonClicked)="reportModalVisible = false">

  <slot-content>
    <app-simple-drop-down-component [placeHolderOrValue]="'Select Reason'" [items]="reportReasons"
                                    (valueChanged)="select($event)"></app-simple-drop-down-component>
  </slot-content>

</app-modal>

<app-modal [displayModal]="makeComplainDialogVisible"
           [title]="'Make a complain'"
           [subclaim]="'Select reason for complain.'"
           (confirmButtonClicked)="makeComplain()"
           [confirmButtonText]="'Make complain'" (cancelButtonClicked)="closeComplainModal()">
  <slot-content>
    <app-simple-drop-down-component [placeHolderOrValue]="'Select Reason'" [items]="complainReasons"
                                    (valueChanged)="selectComplain($event)"></app-simple-drop-down-component>
    <div *ngIf="selectedComplainReason !== EComplainReason.DEFUALT">
      <p>Please tell us what exactly has gone wrong</p>
      <textarea class="content" rows="5" placeholder="Write a complain..."></textarea>
    </div>
  </slot-content>
</app-modal>
