export enum ERoomType {
  LIVING_ROOM = 'LIVING_ROOM',
  BEDROOM = 'BEDROOM',
  BATHROOM = 'BATHROOM',
  DINING_ROOM = 'DINING_ROOM',
  HIGH_CELLING_ROOM = 'HIGH_CELLING_ROOM',
  KITCHEN = 'KITCHEN',
  HALLWAY = 'HALLWAY',
  OTHER = 'OTHER',
}
