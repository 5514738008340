// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//const ENV_SERVER_BASE_URL = "http://ec2-3-21-196-116.us-east-2.compute.amazonaws.com:8080/"//window["env"]["server_base_url"];
//const ENV_SERVER_BASE_URL = " https://api.brushexchange.com:3009/users/demo/"
// const ENV_SERVER_BASE_URL = " https://production.promaticstechnologies.com:3009/"
// live creditionals
const ENV_SERVER_BASE_URL = "https://api.brushexchange.com/s1/"

//const 
export const environment = {
  
  SERVER_BASE_URL:  ENV_SERVER_BASE_URL,
  ENV_BLOG : "https://api.brushexchange.com/BrushBlogs/index.php/wp-json/wp/v2/posts?per_page=1&offset=0",
//  ENV_BLOG : "https://production.promaticstechnologies.com/BrushBlogs/index.php/wp-json/wp/v2/posts?per_page=1&offset=0",
 
production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCIQe4HGD-8XKBe-JtKj0IJsjeegtirMtk",
    authDomain: "brush-exchange-387305.firebaseapp.com",
    projectId: "brush-exchange-387305",
    storageBucket: "brush-exchange-387305.appspot.com",
    messagingSenderId: "695853230855",
    appId: "1:695853230855:web:4401fbd8745f242e01f3a2",
    measurementId: "G-3XJDH1X02D",
            vapidKey:'BFHWMBE7EV4n9XeVOhIWbEOFUoG6NvFE7Nry3rC1PnNO79rpo9sJDtoMOSUD08l9Ah2JFUEPSuTyOHgGYNyliyU'

  },
     //socketUrl: "https://production.promaticstechnologies.com:3012",
     socketUrl: "https://api.brushexchange.com:3012",

     mode: 'Dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
