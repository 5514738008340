export enum EProjectStatus {

  IN_DRAFT = 0,
  PENDING = 1,
  AWAITING = 2,
  IN_PROGRESS = 3,
  DELETED = -1,
  ARCHIVED = -2,
  COMPLAIN = 5,
  COMPLETED = 10,
  pending = 89
}

