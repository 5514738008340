import { APP_ID, Inject, Injectable, PLATFORM_ID, EventEmitter } from '@angular/core'; 
import {environment} from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpClientModule ,HttpHeaders, 
  HttpEventType,} from '@angular/common/http';
import { map, tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
const API = environment.SERVER_BASE_URL ;
    import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class HttpserviceService {
public myEvent: EventEmitter<any> = new EventEmitter<any>();
  public trailTokenData: EventEmitter<any> = new EventEmitter<any>();

 // SERVER_URL = environment.SERVER_URL;

  constructor(
    private router: Router,
  ///  private loader: NgxUiLoaderService,
    private http: HttpClient,
        private httpClient: HttpClientModule,
            public afAuth: AngularFireAuth,

    private _snackBar: MatSnackBar,
   // private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platform: Object

  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  console.log(request)
    const started = Date.now();
   // this.loader.start();

    let ok: any;
    const token = this.getToken();
    console.log(token)
    if (token) { // make sure token is coming,
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }


    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => ok = event instanceof HttpResponse ? 'succeeded' : '',
        // Operation failed; error is an HttpErrorResponse
        error => ok = error
      ),
      // Log when response observable either completes or errors
      finalize(() => {
     //   this.loader.stop();
        const elapsed = Date.now() - started;
        if (ok == "succeeded") {
          const msg = `${request.method} "${request.urlWithParams}"
           ${ok} in ${elapsed} ms.`;
          // console.log(msg);
        } else {
          console.log(ok);
          if (ok["status"] == 401) {
            // logout from here
            this.logout();
            this.router.navigate(['/']);
            console.log(ok["status"]);
          } else {
            this.handleError(ok);
          }
        }

      })
    );
  }
   authLogin(provider: any) {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithPopup(provider)
        .then((data: any) => {
          console.log("You have login ");
          resolve(data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  }
  showMessage(object: any) {
    this._snackBar.open(object.message, object.action ? object.action : "CLOSE", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    })

  }
   handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error && error.error.errors) {
      // client-side error and server side
      if (Array.isArray(error.error.errors.msg)) { // validation error message

        if (error.error.errors.msg.length) {
          const ob = error.error.errors.msg[0]
          if (ob.msg == "IS_EMPTY") {
            errorMessage = `${ob.param} missing`
          } else {
            errorMessage = ob.msg

          }
        }
      } else {
        errorMessage = error.error.errors.msg;
      }
    } else {
      // server-side error
      if (error.status == 401) {
        // Unauthorised 
        this.logout();
        this.router.navigate(['/'])
      }

      console.log(error)
      errorMessage = `Something went wrong here`;
      // errorMessage = `${error.message}`;
    }
    console.log("I am here ", errorMessage);
    this.showMessage({
      message: errorMessage
    })
    return throwError(errorMessage);
  }
 
   resetpass(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/changePassword`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    createRoom(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/create/room`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   changeallbidstatus(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/updateBidforpending`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   sortbids(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getBidByProject`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  postproject(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/createProjectBasic`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  postprojectnew(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/createNewProjectBasic`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }

    deletechatroom(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/deleteChat`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    createwllext(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/saveAdvanceTownhouseRoom`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  cretenewroom(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/saveRoom`;
    return this.http.post(API_URL, data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }

      gethowtowork(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getHowToWork`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }

  
      getnotification(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/notificationList`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
     getnotificationnew(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/notificationList?limit=${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getstatelist(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/stateList`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
     getstatelistsearch(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/stateList?search=${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }

     getprofile(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getProfile`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
      getprofilebyvalue(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getProfile?id=${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   changesttus(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/setArchive/${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    getprojectstep(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getprojectsteps?type=${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    getprojectstepsecond(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getsecountstep?projectstep1_id=${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    getfooterlinks(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getIcons`;
    return this.http.get(API_URL).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
     getcreditvaluemodify(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getcreditvalueforModify`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getcreditpackege(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getCreditPackage`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getcreditvalue(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getcreditvalueforUser`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    objToQueryString(obj: any): string {

    if (!obj) {
      return ""
    }

    const searchParams = new URLSearchParams();
    const params = obj;
    Object.keys(params).forEach(key => searchParams.append(key, params[key]));

    return searchParams.toString()

  }
     getProjects(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/getNewProjectforUser`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    craetFcm(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/addFCM`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    deteleFcm(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/removeFCM`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
     savepayment(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/saveTransaction`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    addCreditIntoPaypal(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
      var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
      const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/addCredit`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  //----to get credit on payment page
  addcredit(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
      var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
      const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/addCreditforpaypal`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  
  getcredit(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
      var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
      const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/getcredit`;
    return this.http.get(API_URL,httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }


     disputeproject(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/updateProjectStatusForDispute`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   changeprojectstatus(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/updateProjectStatus`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    deletenoti(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/deleteNotification`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   seennoti(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/seenNotification`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   changereview(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/reviewProject`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    getreivewlist(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/reviewProjectList`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   createprojectRoom(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/createProjectRoom`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getRoombyIdsingle(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjects`;
   let API_URL = `${API}users/getRoombyId`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   deleteaccount(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/deleteAccount`;
    return this.http.post(API_URL,obj).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getjobs(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getjobs`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getchatrromdetails(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getallChat?${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getcompletedlist(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getallcompleteList?${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getcompletedlistid(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getallcompleteList?${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  getrooms(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getRooms`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getProjectsbypainter(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

   // let API_URL = `${API}users/getProjectsbypainter`;
   let API_URL = `${API}users/getNewProjectforPainter`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  getProjectsPainter(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getProjects/painter/side`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    getbids(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/get/Allbids/painter/side?${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getbidsbyId(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/bid/${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getreportingReason(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/reporting/reasons/listing/from/admin`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getbidsDetails(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/get/Allbids/painter/side?id=${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    getProjectsdetails(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
        const query = this.objToQueryString(obj);

     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getProjects`;
    return this.http.post(API_URL,obj, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  getProjectsdetailsnew(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
        const query = this.objToQueryString(obj);

     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/getProjects?${obj}`;
    return this.http.get(API_URL, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    handleUploaderResponse(res: HttpEvent<any>) {
    if (res.type === HttpEventType.Response) {
      return {
        body: res.body,
        status: "finished",
      };
    } else if (res.type === HttpEventType.UploadProgress) {
      let total = 0;
      if (res && res.total) {
        total = res.total;
      }
      return {
        status: "uploading",
        progress: +Math.round((100 * res.loaded) / total),
      };
    } else {
      return {
        status: "inProgress",
        progress: 0,
      };
    }
  }
    uploadUserMedia(media: File): Observable<any> {
    const formdata = new FormData();
  
    formdata.append("images", media);
   // formdata.append("path", path);
     var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };
    let API_URL = `${API}users/upload/multiple/project/imgs`;
    return this.http
      .post(API_URL, formdata, {
        responseType: "json",
        reportProgress: true,
        observe: "events",
      })
      .pipe(
        map((res: HttpEvent<any>) => {
          return this.handleUploaderResponse(res);
        })
      );
  }
      uploadimgaes(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/upload/multiple/project/imgs`;
    return this.http.post(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  acceptRejectBids(id: any, data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/bid/approval/${id}`;
    return this.http.patch(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  acceptRejectBidschange( data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/bid/approval`;
    return this.http.patch(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  updatebid( data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/update/bid`;
    return this.http.patch(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
  deleteproject(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/delete/project`;
    return this.http.post(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   createreason(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/reporting/reasons `;
    return this.http.post(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    Sociallogin(data: any): Observable<any> {
    let API_URL = `${API}auth/socialRegisterAndLogin`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
    createBid(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/create/bid`;
    return this.http.post(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
    updateprofile(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
     console.log(localStorage.getItem("token"))
   var headers_object = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("token"));
   const httpOptions = {
          headers: headers_object
        };

    let API_URL = `${API}users/updateProfile`;
    return this.http.patch(API_URL,data, httpOptions).pipe(
      map((res : any) => {
        return res;
      })
    );
  }
   getToken() {
   
   console.log(localStorage.getItem('token'))
    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return localStorage.getItem("token")

    } if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {

      return sessionStorage.getItem("token")

    } else {

      return null

    }
  }
    download(url: string): void {
    window.open(`${url}`);
  }

    logout() {
    localStorage.removeItem('currenttab');
        localStorage.removeItem('device_id');
    localStorage.removeItem('fcmtoken');
    localStorage.removeItem('remember_me');
    localStorage.removeItem('role');
    localStorage.removeItem('token');
        localStorage.removeItem('type');
    localStorage.removeItem('user_details');

    sessionStorage.clear();
    // delete cookies
    // document.cookie = "role" + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
   // this.deleteCookie("role")
  }

  // AuthLogin(provider:any) {
  //   return this.afAuth
  //     .signInWithPopup(provider)
  //     .then((result) => {
  //       return result
     
  //     })
  //     .catch((error) => {
  //       return error;
  //     });
  // }

}
