import {Component, OnInit} from '@angular/core';
import {AnimationOptions} from 'ngx-lottie';
import {AnimationItem} from 'lottie-web';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  customerTheme = true;
  customerThemeCc = true;
  options: AnimationOptions = {
    path: '/assets/illustrations/landing-page/chatbot-anim.json'
  };
  options2: AnimationOptions = {
    path: '/assets/illustrations/landing-page/growth-anim.json'
  };
  customerServices = [
    {
      img: 'https://uploads-ssl.webflow.com/5d7916698d51d4b21226b9fb/5d794a39df910e2d2d1a7d80_icon-buy-box.png',
      title: 'Browse portfolios',
      label: 'customer',
      description: 'Find professionals you can trust by browsing their samples of previous work and reading their reviews.'
    },
    {
      img: 'https://uploads-ssl.webflow.com/5d7916698d51d4b21226b9fb/5d794a39251450bb5d39a4bd_icon-search.png',
      title: 'View bids',
      label: 'customer',
      description: 'Receive free quotes from registered painters in your area and chat live with the painters.'
    },
    {
      img: 'https://uploads-ssl.webflow.com/5d7916698d51d4b21226b9fb/5d794a3925199e300c910462_icon-budget.png',
      title: 'Pay for quality',
      label: 'customer',
      description: 'Pay for work when it has been completed and you\'re 100% satisfied.'
    }
  ];

  painterServices = [
    {
      img: 'https://uploads-ssl.webflow.com/5d7916698d51d4b21226b9fb/5d8cd7674b005113939a9bdb_icon-increase-sales.png',
      title: 'Earn money',
      label: 'painter',
      description: 'Become a painter and find new projects to grow your business.'
    },
    {
      img: 'https://uploads-ssl.webflow.com/5d7916698d51d4b21226b9fb/5d794a398db071bc6f3f909e_icon-sales.png',
      title: 'Top the charts',
      label: 'painter',
      description: 'Earn top reviews for your job and become BrushExchange quality painter, unlocking new features!'
    },
    {
      img: 'https://uploads-ssl.webflow.com/5d7916698d51d4b21226b9fb/5d794a392514503d6339a4be_icon-product-rank.png',
      title: 'Grow your buisness',
      label: 'painter',
      description: 'Build your portfolio based on previous work and let your business escalate quickly.'
    }
  ];


  constructor() {
  }

  ngOnInit(): void {
  localStorage.clear();
    console.log(environment.SERVER_BASE_URL);
  }

  changeTheme(): void {
    this.customerTheme = !this.customerTheme;
  }

  changeCcTheme(): void {
    this.customerThemeCc = !this.customerThemeCc;
  }

  scrollToShowHow(): void {
    document.getElementById('section-two-index')!.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }
}
