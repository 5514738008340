import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectBasicData } from '../../../../model/ProjectBasicData';
import { ProjectService } from '../../../../services/project.service';
import { ProjectBasicShareService } from '../../../../services/data-sharing/ProjectBasicShareService';
import { getRedirectLinkForProjectType } from '../../../../model/enums/EProjectType';
import { BrushProjectsViewComponent } from '../../../../components/MAIN_COMPONENTS/BrushProjectsViewComponent';
import { ShareDataService } from '../../../../services/data-sharing/share-data.service';
import * as moment from 'moment';
import { HttpserviceService } from '../../../../services/httpservice.service';
import { v4 as uuidv4 } from 'uuid';
import { AngularFireMessaging } from '@angular/fire/messaging';
import firebase from 'firebase/app'
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';

import { BehaviorSubject } from 'rxjs'


@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss']
})
export class MarketplaceComponent extends BrushProjectsViewComponent implements OnInit {
  @ViewChild('filters') filters!: ElementRef;
  projects: any = []
  showlist: boolean = false
  newloc: any
  modalusrdetail: boolean = false
  newstatelist: any = []
  searchloc: any
  newarray: any = []
  fcmtokennew: any
  messaging = firebase.messaging;
  radius: any
  statelist: any = []
  newarraysrface: any = [];
  surfacecondition: any = []
  @ViewChild('expandFilters') expandFilters!: ElementRef;
  @ViewChild('closeFilters') closeFilters!: ElementRef;
  private menuVisible = false;
  @ViewChild('projectsTypes') contentTypes!: ElementRef;
  @ViewChild('projectTagsSurfaceAreaSection') projectTagsSurfaceAreaSection!: ElementRef;
  @ViewChild('projectTagsSurfaceConditionSection') projectTagsSurfaceConditionSection!: ElementRef;
  @ViewChild('roomsQuantitySection') roomsQuantitySection!: ElementRef;
  @ViewChild('roomsTypesSection') roomsTypesSection!: ElementRef;
  @ViewChild('exteriorTypesSection') exteriorTypesSection!: ElementRef;
  search: any
  pageew = 1;
  pageSize = 4;
  collectionSize = 0;
  limit: any = 10
  offset: any = 0
  responsiveP: boolean = true;
      profileForm!: FormGroup


  tokensave: any
  p: number = 1;
  firebaseApp: any;
  errorpin: any
  total: number = 0;
  zero: number = 0;
  scrollsMap!: Map<any, any>;
  modalFlag: boolean | undefined;

  constructor(
    private sidebarDataShareService: ShareDataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private projectService: ProjectService,
    private projectBasicShare: ProjectBasicShareService,
    private angularFireMessaging: AngularFireMessaging, private service: AuthService,
    private authService: HttpserviceService, private toastr: ToastrService) {
    super();
    this.scrollsMap = new Map();

      this.profileForm = this.formBuilder.group({
     // email: ['', [ Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
        name: ['',[Validators.required]],

      profile_image: [null],
      address: ['', [Validators.required, Validators.maxLength(15)]],
      city: [''],
      state: [''],
      zipcode: ['', [Validators.required]],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
    //  address: ['', Validators.required]

    })
  }

  ngOnInit(): void {

   if(localStorage.getItem('loginstatus') == 'true' && localStorage.getItem('loginbysocial')=='true'){
  this.modalusrdetail = true
  }
    if (localStorage.getItem('role') == "customer") {
      this.toastr.error('Access denied!', 'Error!');


      let obj = {
        'device_token': localStorage.getItem('fcmtoken'),
        'device_id': localStorage.getItem('device_id')

      }
      this.authService.deteleFcm(obj).subscribe((resp: any) => {
        console.log("token=> deleted ", resp);
        this.authService.logout();
      })
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();

    }
    if (!localStorage.getItem('fcmtoken')) {
      this.requestPermission();
      this.tokensave = uuidv4()
    }
    //  this.createfcm()
    console.log(uuidv4(), this.tokensave)
    this.getBothProjectTypesBasicData();
    this.getProjects();
    this.statelistnew()
    const hideFun = (): void => {
      if (this.menuVisible) {
        this.filters.nativeElement.classList.remove('animationDisplayBlock');
        this.filters.nativeElement.classList.add('animationSmoothHide');
        setTimeout(() => {
          this.filters.nativeElement.style.display = 'none';
          this.menuVisible = false;
        }, 500);
      }
    };
    this.sidebarDataShareService.setHideMarketplaceFiltersFunction(hideFun);
  }

  filter(startFromFirstPage: boolean): void {
    console.log(startFromFirstPage)
    if (startFromFirstPage) {
      this.page = 0;
    }
    if (this.selectedProjectTypes == null || this.selectedProjectTypes.length !== 1) {
      this.getBothProjectTypesBasicData();
    } else if (this.isProjectInteriorSelected) {
      this.getProjectInteriorsOnly();
    } else if (this.isProjectExteriorSelected) {
      this.getProjectExteriorsOnly();
    }
  }

   checkzip(e: any) {
    // console.log(e, this.location)

   
    this.service.checkzipcode(this.profileForm.value.zipcode).subscribe((res: any) => {
      if (res) {
        this.errorpin = ''
        this.profileForm.controls['latitude'].patchValue(res.response[0].latitude);
        this.profileForm.controls['longitude'].patchValue(res.response[0].longitude);
        this.profileForm.controls['city'].patchValue(res.response[0].city);
        this.profileForm.controls['state'].patchValue(res.response[0].administrativeLevels?.level1long);
        console.log(this.profileForm)
      }
    }, error => {
      console.log(error);
      this.errorpin = 'Please enter valid zip code'
    })
  }

  navigateToOffer(projectBasic: ProjectBasicData, item: any): void {
    this.projectBasicShare.setCurrentProject(projectBasic);
    // this.router.navigate(['/home/marketplace/project-marketplace-details/' + getRedirectLinkForProjectType(projectBasic.projectType).value + '/' + item]);
    //    this.router.navigate(['/home/marketplace/project-marketplace-details/' + item]);
    this.router.navigate(['/home/my-project-detail/' + item]);
  }
  custom(e: any) {
    // console.log(page)
    //this.page = page
    this.offset = (this.pageew - 1) * 10
    this.getProjects()
  }

  getBothProjectTypesBasicData(): void {
    this.projectService.getAllProjectMarketplace(
      this.page,
      this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue,
      this.selectedOrdering.sortDir,
      this.searchStateCityZipCode,
      this.searchDistance,
      this.selectedSurfaceArea,
      this.selectedSurfaceCondition)
      .subscribe(response => {
        this.pageableResponse = response;
        console.log(response);
        this.pageNavCalc();
      });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.fcmtokennew = token
        localStorage.setItem('fcmtoken', this.fcmtokennew)
        localStorage.setItem('device_id', this.tokensave)

        const data = {

          device_token: token,
          device_type: 'web',
          device_id: this.tokensave,

        }
        this.authService.craetFcm(data).subscribe((resp: any) => {
          console.log("createFcmToken=>", resp);

        })


      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  getProjectInteriorsOnly(): void {
    this.projectService.getAllProjectInterior(
      this.page,
      this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue,
      this.selectedOrdering.sortDir,
      this.searchStateCityZipCode,
      this.selectedRoomsQuantity,
      this.selectedRoomTypes,
      this.searchDistance,
      this.selectedSurfaceArea,
      this.selectedSurfaceCondition)
      .subscribe(response => {
        this.pageableResponse = response;
        console.log(response);
        this.pageNavCalc();
      });
  }

  getProjectExteriorsOnly(): void {
    this.projectService.getAllProjectExterior(
      this.page,
      this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue,
      this.selectedOrdering.sortDir,
      this.searchStateCityZipCode,
      this.searchDistance,
      this.selectedSurfaceArea,
      this.selectedSurfaceCondition)
      .subscribe(response => {
        this.pageableResponse = response;
        this.pageNavCalc();
      });
  }

  toggleSectionWithProjectTypes(event: any): void {
    this.projectTypeExpanded = !this.projectTypeExpanded;
    if (this.projectTypeExpanded) {
      this.contentTypes.nativeElement.style.maxHeight = this.contentTypes.nativeElement.scrollHeight + 'px';
    } else {
      this.contentTypes.nativeElement.style.maxHeight = 0 + 'px';
    }
  }
  statelistnew() {

    this.authService.getstatelist().subscribe((response: any) => {
      this.statelist = response.data
    })
  }


  getProjects() {
    let obj = {
      'limit': this.limit,
      'offset': this.offset,
      //  'status': 'approved'
    }
    this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
      this.projects = response.projects

      this.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  getProjectssearch(e: any) {
    let a = "search=" + this.search
    let obj = {
      'search': this.search,
      //     'status': 'approved'

    }
    this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
      this.projects = response.projects

      this.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

  getstteserach(e: any) {
    //let a = "search=" + this.search
    console.log(e)
    let obj = {
      'search': this.searchloc,
      //     'status': 'approved'

    }
    this.authService.getstatelistsearch(this.searchloc).subscribe((response: any) => {
      this.newstatelist = response.data
      if (this.newstatelist.length && this.searchloc) {
        this.showlist = true

      } else {
        this.showlist = false

        let obj1 = {
          'location': this.searchloc,
          //     'status': 'approved'

        }
        this.authService.getProjectsbypainter(obj1).subscribe((response: any) => {
          this.projects = response.projects

          this.projects.forEach((val: any, ind: number) => {
            let current_date: any = moment(new Date());
            let deadline_date: any = moment(val.created_at);

            val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

            console.log('rem days', val.days_remaining);



          });

          //   this.toastr.success('Password changed!', 'Success!');

        })
      }
    });

    //   this.toastr.success('Password changed!', 'Success!');


  }
  handleAddressChange(event: any) {
    this.showlist = false

    console.log(event)
    // console.log(this.location)
    let a = event.formatted_address
    let obj = {
      'location': event,
      'kms': this.radius,
    }
    this.searchloc = event
    this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
      this.projects = response.projects

      this.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });
      //   this.toastr.success('Password changed!', 'Success!');

    })

  }

  clearLocation() {
    console.log('test')
  }
  getProjectssearchradoius(e: any) {
    let a = "search=" + this.search
    let obj = {
      'kms': this.radius,
      'location': this.searchloc
      //     'status': 'approved'

    }
    this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
      this.projects = response.projects

      this.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  projectfilter(event: any, e: any) {
    console.log(event.target.checked)
    if (event.target.checked == true) {
      this.newarray.push(e)
      console.log(this.newarray)
      let obj = {}
      let a = "project_type=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'surface_condition': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset
        }
      }

      console.log(obj)
      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });

        //   this.toastr.success('Password changed!', 'Success!');

      })
    } else {
      this.newarray.splice(e, 1)
      let obj = {}
      let a = "project_type=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'surfaceCondition': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset
        }
      }


      console.log(obj)
      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });
        //   this.toastr.success('Password changed!', 'Success!');

      })
    }

  }


   changeprofile() {
    console.log(this.profileForm)
   if(this.profileForm.invalid){
       this.profileForm.markAllAsTouched()

          return;
   }
    const formdata = new FormData()
    formdata.append('full_address', this.profileForm?.get('address')?.value)
        formdata.append('full_name', this.profileForm?.get('name')?.value)

   // formdata.append('profile_image', this.selectedFile2)
    formdata.append('phone_number', this.profileForm.value.phone)
    formdata.append('latitude', this.profileForm?.get('latitude')?.value)
    formdata.append('longitude', this.profileForm?.get('longitude')?.value)
    formdata.append('state', this.profileForm?.get('state')?.value)
    formdata.append('location', this.profileForm?.get('location')?.value)

    formdata.append('zip_code', this.profileForm?.get('zipcode')?.value)
  //  formdata.append('stay_logged_in', this.stay_log)
   // formdata.append('send_email', this.semail)

    formdata.append('city', this.profileForm?.get('city')?.value)


    this.authService.updateprofile(formdata).subscribe((response: any) => {
      ///  this.profile= response.profileData
     this.toastr.success(' Updated Successfully!', 'Success!');
          this.modalusrdetail = false
          localStorage.removeItem('loginbysocial');
          localStorage.removeItem('loginstatus');
    //  this.getprofile()
     // window.location.reload();
    })
  }


  projectfilterserfacecon(event: any, e: any) {
    console.log(event.target.checked)
    if (event.target.checked == true) {
      this.surfacecondition.push(e)
      console.log(this.newarray)
      let obj = {}
      let a = "project_type=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          //    'surface_condition': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          //    'surfaceCondition': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'state': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'state': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset,
          //'status' : 'approved'
        }
      }


      console.log(obj)
      console.log(obj)
      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });

        //   this.toastr.success('Password changed!', 'Success!');

      })
    } else {
      this.newarraysrface.splice(e, 1)
      let obj = {}
      let a = "project_type=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          'state': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          'state': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'state': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'state': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset,
          //  'status' :'approved'
        }
      }

      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });
        //   this.toastr.success('Password changed!', 'Success!');

      })
    }

  }
  statefilter(event: any, e: any) {

    if (event.target.checked == true) {
      this.newarraysrface.push(e)
      console.log(this.newarray)
      let obj = {}
      let a = "state=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'surface_condition': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset
        }
      }


      console.log(obj)
      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });

        //   this.toastr.success('Password changed!', 'Success!');

      })
    } else {
      this.newarraysrface.splice(e, 1)
      let obj = {}
      let a = "project_type=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'surface_condition': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset
        }
      }

      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });
        //   this.toastr.success('Password changed!', 'Success!');

      })
    }

  }
  projectfilterserface(event: any, e: any) {
    console.log(event.target.checked)
    if (event.target.checked == true) {
      this.newarraysrface.push(e)
      console.log(this.newarray)
      let obj = {}
      let a = "project_type=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'surface_condition': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset
        }
      }


      console.log(obj)
      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });

        //   this.toastr.success('Password changed!', 'Success!');

      })
    } else {
      this.newarraysrface.splice(e, 1)
      let obj = {}
      let a = "project_type=" + this.newarray
      if (this.newarray.length && this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition

        }
      } else if (this.newarray.length && this.newarraysrface.length) {
        obj = {


          'project_type': this.newarray,
          'home_type': this.newarraysrface,
        }
      } else if (this.newarraysrface.length && this.surfacecondition.length) {
        obj = {
          // 'projectType' : this.newarray,
          'home_type': this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length && this.surfacecondition.length) {
        obj = {
          'project_type': this.newarray,
          //    'surfaceArea' : this.newarraysrface,
          'surface_condition': this.surfacecondition
        }
      } else if (this.newarray.length) {
        obj = {
          'project_type': this.newarray,
        }

      } else if (this.newarraysrface.length) {
        obj = {
          'home_type': this.newarraysrface,

        }

      } else if (this.surfacecondition.length) {
        obj = {
          'surface_condition': this.surfacecondition,

        }

      } else {
        obj = {
          'limit': this.limit,
          'offset': this.offset
        }
      }

      this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
        this.projects = response.projects

        this.projects.forEach((val: any, ind: number) => {
          let current_date: any = moment(new Date());
          let deadline_date: any = moment(val.created_at);

          val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

          console.log('rem days', val.days_remaining);



        });
        //   this.toastr.success('Password changed!', 'Success!');

      })
    }

  }
  toggleSectionWithSurfaceArea(event: any): void {
    this.projectTagsSurfaceAreaSectionExpanded = !this.projectTagsSurfaceAreaSectionExpanded;
    if (this.projectTagsSurfaceAreaSectionExpanded) {
      this.projectTagsSurfaceAreaSection.nativeElement.style.maxHeight = this.projectTagsSurfaceAreaSection.nativeElement.scrollHeight + 'px';
    } else {
      this.projectTagsSurfaceAreaSection.nativeElement.style.maxHeight = 0 + 'px';
    }
  }
  toggleSectionWithSurfaceCondition(event: any): void {
    this.projectTagsSurfaceConditionSectionExpanded = !this.projectTagsSurfaceConditionSectionExpanded;
    if (this.projectTagsSurfaceConditionSectionExpanded) {
      this.projectTagsSurfaceConditionSection.nativeElement.style.maxHeight = this.projectTagsSurfaceConditionSection.nativeElement.scrollHeight + 'px';
    } else {
      this.projectTagsSurfaceConditionSection.nativeElement.style.maxHeight = 0 + 'px';
    }
  }

  toggleSectionWithSurfaceTag(event: any): void {
    this.projectTagsSurfaceConditionSectionExpanded = !this.projectTagsSurfaceConditionSectionExpanded;
    if (this.projectTagsSurfaceConditionSectionExpanded) {
      this.projectTagsSurfaceConditionSection.nativeElement.style.maxHeight = this.projectTagsSurfaceConditionSection.nativeElement.scrollHeight + 'px';
    } else {
      this.projectTagsSurfaceConditionSection.nativeElement.style.maxHeight = 0 + 'px';
    }
  }

  toggleSectionWithRoomsQuantity(event: any): void {
    this.roomQuantityExpanded = !this.roomQuantityExpanded;
    if (this.roomQuantityExpanded) {
      this.roomsQuantitySection.nativeElement.style.maxHeight = this.roomsQuantitySection.nativeElement.scrollHeight + 'px';
    } else {
      this.roomsQuantitySection.nativeElement.style.maxHeight = 0 + 'px';
    }
  }

  toggleSectionWithRoomTypes(event: any): void {
    this.roomTypeExpanded = !this.roomTypeExpanded;
    if (this.roomTypeExpanded) {
      this.roomsTypesSection.nativeElement.style.maxHeight = this.roomsTypesSection.nativeElement.scrollHeight + 'px';
    } else {
      this.roomsTypesSection.nativeElement.style.maxHeight = 0 + 'px';
    }
  }

  toggleSectionWithExteriorJobs(event: any): void {
    this.jobTypeExpanded = !this.jobTypeExpanded;
    if (this.jobTypeExpanded) {
      this.exteriorTypesSection.nativeElement.style.maxHeight = this.exteriorTypesSection.nativeElement.scrollHeight + 'px';
    } else {
      this.exteriorTypesSection.nativeElement.style.maxHeight = 0 + 'px';
    }
  }

  randomToggle(event: any, id: string): void {
    const parentElement = event.target.parentElement;
    const scrollHeight = parentElement.scrollHeight;
    if (event.target.parentElement.classList.contains('active')) {
      this.scrollsMap.set('down-' + id, scrollHeight);
    } else {
      this.scrollsMap.set('up-' + id, scrollHeight);
    }
    parentElement.classList.toggle('active');
    if (parentElement.classList.contains('active')) {
      document.getElementById(id)!.style.maxHeight = this.scrollsMap.get('down-' + id) + 'px';
    } else {
      document.getElementById(id)!.style.maxHeight = 0 + 'px';
    }
  }
  public toggleMenu(event: any): void {
    if (this.menuVisible) {
      this.filters.nativeElement.classList.remove('animationDisplayBlock');
      this.filters.nativeElement.classList.add('animationSmoothHide');
      this.expandFilters.nativeElement.style.display = 'block';
      setTimeout(() => {
        this.filters.nativeElement.style.display = 'none';
        this.closeFilters.nativeElement.style.display = 'none';
        this.menuVisible = false;
      }, 500);
    } else {
      this.sidebarDataShareService.hideSideNavbarIfExpanded();
      this.closeFilters.nativeElement.style.display = 'block';
      this.filters.nativeElement.classList.remove('animationSmoothHide');
      this.filters.nativeElement.classList.add('animationDisplayBlock');
      this.filters.nativeElement.style.display = 'flex';
      this.menuVisible = true;
    }
  }

  windowResizeEvent(): void {
    const screenWidth = screen.width;
    if (screenWidth > 1024) {
      this.menuVisible = false;
      this.expandFilters.nativeElement.style.display = 'none';
      this.filters.nativeElement.classList.remove('animationSmoothHide');
      this.filters.nativeElement.classList.remove('animationDisplayBlock');
      this.filters.nativeElement.style.display = 'block';
    } else {
      this.expandFilters.nativeElement.style.display = 'block';
      this.filters.nativeElement.style.display = 'none';
      this.menuVisible = false;
    }
  }
  viewDemoDetails() {
    this.router.navigate(['/home/my-bids/bid']);
  }
  makeComplain() { }
  openAcceptBidModal() { }
  checkPendingOrNewStatus(): boolean { return true }
  openDenyBidModal() { }
  closeComplainModal() {
    this.modalFlag = false
    this.modalusrdetail = false
    localStorage.removeItem('loginbysocial');
     localStorage.removeItem('loginstatus');
  }
}
