import {Injectable} from '@angular/core';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import {TokenStorageService} from './token-storage.service';
import {BrushNotification, NotificationService} from './notification-service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CustomHttpParameterCodec} from '../_helpers/requests/CustomHttpParameterCodes';
import {PageableResponse} from '../model/PageableResponse';
import {Message} from '../model/Message';
import {EMessageStatus} from '../model/enums/EMessageStatus';
import {ChatConversation} from '../model/ChatConversation';
import {ShareDataService} from './data-sharing/share-data.service';
import {EMessageType} from '../model/enums/EMessageType';

export interface MessageStatusChangeRequest {
  messageId: number;
  messageStatus: EMessageStatus;
}
const API_REST = environment.SERVER_BASE_URL + 'message';
const API = environment.SERVER_BASE_URL + 'notifications';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  stompClient: any;
  public encoder: CustomHttpParameterCodec;
  privateChatMessages = new Array();
  privateConversations = new Array();
  openedPrivateConversation!: ChatConversation | null;
  unreadChatConversationsCount = 0;
  newNotificationClickCallback = (message: any): void => {
    if (message.messageStatus === EMessageStatus.UNREAD) {
      this.changeMessageStatus({messageId: message.messageId, messageStatus: EMessageStatus.READ}).subscribe(res => {
        this.sidebarData.changeCounter(-1);
      }, error => {
        console.log(error);
      });
    }
  }

  constructor(private http: HttpClient, protected tokenStorage: TokenStorageService, protected notificationService: NotificationService, private sidebarData: ShareDataService) {
    this.encoder = new CustomHttpParameterCodec();
  }


  connect(): void {
      if (this.tokenStorage.getToken() != null && this.tokenStorage.getToken() !== '') {
        const socket = new SockJS(API);
        this.stompClient = Stomp.over(socket);
        this.stompClient.reconnect_delay = 5000;
        this.stompClient.debug = null;
        this.stompClient.connect({username: this.tokenStorage.getEmail(), Authorization: 'Bearer ' + this.tokenStorage.getToken()}, () => {
          console.log('Websocket connection established');

          this.stompClient.subscribe('/users/queue/messages', (response: any) => {
            new Audio('/assets/sounds/new-notification.ogg')?.play();
            const msg = JSON.parse(response.body);
            console.log(msg);
            if (!msg.isChatMessage) {
              if (msg.messageStatus === EMessageStatus.UNREAD) {
                this.sidebarData.changeCounter(+1);
              }
              if (document.hidden) { // check whether brush browser card is active
                const interval1 = setInterval(() => {
                  if (!document.hidden) {
                    clearInterval(interval1);
                    this.notificationService.showNotification(msg, this.newNotificationClickCallback);
                  }
                }, 1000);
              } else {
                this.notificationService.showNotification(msg, this.newNotificationClickCallback);
              }
            } else {
              if (this.getOpenedPrivateConversation()?.conversationId === msg.conversationId) {
                this.pushToPrivateChatMessages(msg);
                this.sidebarData.newChatMessageForOpenedConversation();
              }
              this.getStartedConversations().subscribe(conversations => {
                this.setPrivateConversations(conversations);
              });
            }
          });
        }, () => {
          this.reConnectWebSocket();
          console.error('Websocket not connected');
        });
      }
  }
  // id = primary key from database
  sendMessage(receiverId: string | number, notification: BrushNotification, arg: string, messageRelatedID: string): void  {
    this.sendMessageRelatedIdSub(receiverId, notification, arg, messageRelatedID, '');
  }

  sendMessageRelatedIdSub(receiverId: string | number, notification: BrushNotification, arg: string, messageRelatedID: string, messageRelatedIDSub: string): void {
        let messageContent = notification.text;
        if (arg != null) {
          messageContent = messageContent.replace('${arg}', arg);
        } else {
          console.error('message not sent, arg cannot be null');
          return;
        }
        this.stompClient.send('/app/notifications', {}, JSON.stringify({
          from: this.tokenStorage.getEmail(),
          content: messageContent,
          title: notification.title,
          receiver: receiverId,
          notificationType: notification.type,
          relatedID: messageRelatedID,
          relatedIDSub: messageRelatedIDSub
        }));
  }
  sendPrivateMessage(receiverEmail: string, messageContent: string, eMessageType: EMessageType, contentFileName: string): Observable<any> {
    return this.stompClient.send('/app/private-chat-message', {}, JSON.stringify({sender: this.tokenStorage.getEmail(), content: messageContent, receiver: receiverEmail, messageType: eMessageType, fileName: contentFileName}));
  }

  disconnect(): void {
    if (this.stompClient?.connected) {
      this.stompClient.disconnect();
    }
  }

  reConnectWebSocket(): void {
    setTimeout(() => {
      console.log('Websocket attempting to reconnect');
      this.connect();
    }, 5000);
  }

  getAllReceivedMessages(page?: number, pageSize?: number, status?: EMessageStatus, sort?: string, sortDir?: string): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (status !== undefined && status !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        status, 'status');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    return this.http.get<PageableResponse>(API_REST + '/get-all-received-notifications', {params: queryParameters});
  }
  getUnreadMessagesCount(): Observable<any> {
    return this.http.get<any>(API_REST + '/get-unread-notifications-count');
  }
  changeMessageStatus(message: MessageStatusChangeRequest): Observable<any> {
    return this.http.put<any>(API_REST + '/change-message-status', message);
  }


  getPrivateChatMessages(conversationId: string, page?: number, pageSize?: number): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (conversationId !== undefined && conversationId !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        conversationId, 'conversationId');
    }
    return this.http.get<PageableResponse>(API_REST + '/get-private-chat-messages', {params: queryParameters});
  }

  getStartedConversations(): Observable<ChatConversation[]> {
    return this.http.get<ChatConversation[]>(API_REST + '/get-started-conversations');
  }

  getUnreadChatConversationsCount(): Observable<any> {
    return this.http.get<any>(API_REST + '/get-unread-conversations-count');
  }

  setConversationContentAsRead(conversationId: any): Observable<ChatConversation[]> {
    return this.http.put<ChatConversation[]>(API_REST + '/set-conversation-content-as-read', conversationId);
  }

  setPrivateConversations(conversations: ChatConversation[]): void {
    this.privateConversations = conversations;
    this.getUnreadChatConversationsCount().subscribe(res => {
      this.setUnreadConversationCount(res.content);
    });
  }

  getPrivateConversations(): ChatConversation[] {
    return this.privateConversations;
  }

  setOpenedPrivateConversation(conversation: ChatConversation | null): void {
    this.openedPrivateConversation = conversation;
    if (conversation != null) {
      if (this.isTheConversationUnread(conversation)) {
        this.setConversationContentAsRead(conversation.conversationId).subscribe(conversations => {
          this.setPrivateConversations(conversations);
        }, error => {
          console.log(error);
        });
      }
    }
  }

  getOpenedPrivateConversation(): ChatConversation | null {
    return this.openedPrivateConversation;
  }

  isTheConversationUnread(conv: any): boolean {
    if (this.tokenStorage.isUserPainter() && conv.hasPainterUnreadMessages) {
      return true;
    }
    if (this.tokenStorage.isUserCustomer() && conv.hasCustomerUnreadMessages) {
      return true;
    }
    return false;
  }

  setUnreadConversationCount(unreadConversationsCount: number): void {
    this.unreadChatConversationsCount = unreadConversationsCount;
  }

  getUnreadConversationCountOrZero(): number {
    if (this.unreadChatConversationsCount != null && this.unreadChatConversationsCount > 0) {
      return this.unreadChatConversationsCount;
    }
    return 0;
  }

  informAboutNewChatMessage(): boolean {
    return this.getUnreadConversationCountOrZero() > 0 && this.openedPrivateConversation == null;
  }

  getPrivateMessages(): Message[] {
    return this.privateChatMessages;
  }

  setPrivateMessages(messages: any): void {
    this.privateChatMessages = messages;
  }

  pushToPrivateChatMessages(content: any): void {
    this.privateChatMessages.push(content);
  }
}
