<div class="faq-page smooth-scroll">
    <app-header></app-header>
    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="help-section">
                <div class="sec-a"
                    style="background-image: url(https://img.freepik.com/free-vector/faqs-concept-illustration_114360-6685.jpg?w=740&t=st=1695365694~exp=1695366294~hmac=bce0c563b6eb090cd8f302ce96ed6d3ef8e3132854f2886a735d9aa39d328f53);">
                    <div class="cntnt-sec">
                        <form [formGroup]="searchForm">
                        <h1 class="text-center">Frequently Asked Questions</h1>
                        <div class="srch-bar">
                            <input type="text" placeholder="Search.." formControlName="search">
                            <button class="srch-btn"><i class="fa-solid fa-magnifying-glass"></i></button>
                        </div>
                    </form>
                    </div>
                </div>

                <div class="sec-b">
                    <div class="container">
                        <h1 class="how-does-it-work">F.A.Q</h1>

                        <div *ngFor="let item of faqData; let i = index" class="accordion"
                            [ngClass]="{ 'active': item?.active }">
                            <div class="accordion-header" (click)="toggleAccordion(i)">
                                {{ item.question }}
                                <i class="fa"
                                    [ngClass]="{'fa-chevron-up': item?.active, 'fa-chevron-down': !item?.active}"></i>
                            </div>
                            <div class="accordion-body">
                                {{ item.answer }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-footer></app-footer>
</div>