<!-- <div class="comment" *ngIf="comment || editable" > -->
<div class="comment">
  <div class="comment-header">
    <div class="avatar-full-name">
      <div class="avatar">
        <img src="https://www.pivitglobal.com/hubfs/Lyndsey%20McDonald.png">
      </div>
      <div class="comment-info">
      <!-- <div class="full-name">{{ fullName}}</div> -->
      <div class="full-name">Alfredo Aminoff</div>
      <!-- <div *ngIf="comment" class="posted-date">{{comment.howLongAgo}}</div> -->
      <div class="posted-date">15 days ago</div>
      </div>
    </div>
    <!-- <app-painter-rating *ngIf="!editable" [avgRating]="comment.rating" [displayRatingText]="false"></app-painter-rating> -->
    <app-painter-rating ></app-painter-rating>
    <!-- <app-painter-rating *ngIf="editable" (selectedRateEmitter)="setSelectedRate($event)" #editablePainterRating></app-painter-rating> -->
  </div>
  <!-- <div class="content" *ngIf="!editable"><pre>{{comment.content}}</pre></div> -->
  <div class="content"><pre>Viverra tristique nisl, nunc consectetur in. Pretium habitant sollicitudin est eget. Nec euismod laoreet commodo augue sed eu nunc.</pre></div>
  <textarea class="content" rows="5" *ngIf="editable" placeholder="Write a comment..." [(ngModel)]="content"></textarea>
</div>
<button class="custom-button" *ngIf="editable" [disabled]="postCommentButtonDisabled()" (click)="post()">Post Comment</button>
