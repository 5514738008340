import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServerDataSource} from 'ng2-smart-table';
import {environment} from '../../../../environments/environment';
import {BaseTableViewComponent} from '../../MAIN_COMPONENTS/BaseTableViewComponent';
import {TableActionsComponent} from '../../table-actions/table-actions.component';
import {RolePickerComponent} from '../custom-filters/role-picker-component/role-picker.component';
import {LastDaysPickerComponent} from '../custom-filters/last-days-picker/last-days-picker.component';
import {EProjectType} from '../../../model/enums/EProjectType';
import {ERole} from '../../../model/enums/ERole';
import {EnumPipe} from '../../../pipes/enum.pipe';

@Component({
  selector: 'app-brush-table-users',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
  providers: [EnumPipe]
})
export class UsersTableComponent extends BaseTableViewComponent{

  constructor(private enumPipe: EnumPipe, private httpClient: HttpClient, private cdRef: ChangeDetectorRef) {
    super();
    this.source = new ServerDataSource(httpClient, {
      endPoint: environment.SERVER_BASE_URL + 'admin/users',
      dataKey: 'content',
      totalKey: 'totalElements',
      pagerLimitKey: 'pageSize',
      pagerPageKey: 'page',
      sortFieldKey: 'sort',
      sortDirKey: 'sortDir',
      filterFieldKey: 'search_#field#'
    });
    this.source.onChanged().subscribe(change => {
      console.log(this.source);
      this.totalElements = this.source.count();
      this.cdRef.detectChanges();
    });
  }
  settings = {
    rowClassFunction: (row: any) => {
      if (row.data.userRoleName === 'ROLE_CUSTOMER') {
        return 'neutral';
      } else if (row.data.userRoleName === 'ROLE_PAINTER') {
        return 'warning';
      } else if (row.data.userRoleName === 'ROLE_ADMIN') {
        return 'success';
      }
      return '';
    },
    mode: 'external',
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize
    },
    noDataMessage: this.noDataMessage,
    columns: {
      actions: {
        title: 'Actions',
        sort: false,
        filter: false,

        type: 'custom',
        renderComponent: TableActionsComponent,
        onComponentInitFunction: (rc: any) => {

        },
        width: '15vw'
      },
      clientID: {
        title: 'Client ID'
      },
      email: {
        title: 'Email'
      },
      fullName: {
        title: 'Full name'
      },
      phoneNumber: {
        title: 'Phone number'
      },
      signUpDate: {
        title: 'Sign up date',
        filter: {
          type: 'custom',
          component: LastDaysPickerComponent,
        }
      },
      lastSignIn: {
        title: 'Last sign in',
        filter: {
          type: 'custom',
          component: LastDaysPickerComponent,
        }
      },
      userRoleName: {
        title: 'Role',
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.userRoleName, ERole, false, true),
        filter: {
          type: 'custom',
          component: RolePickerComponent,
        }
      }
    }
  };
}

