import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '../../../services/project.service';
import {BidsService} from '../../../services/bids.service';
import {TokenStorageService} from '../../../services/token-storage.service';
import {MessageService} from '../../../services/message.service';
import {PainterBidComponent} from '../../MAIN_COMPONENTS/PainterBidComponent';
import {FormBuilder} from '@angular/forms';
import {Bid} from '../../../model/Bid';
import {CurrencyPipe, KeyValue} from '@angular/common';
import {EnumPipe} from '../../../pipes/enum.pipe';
import {EProjectType, getRedirectLinkForProjectType} from '../../../model/enums/EProjectType';
import {EProjectStatus} from '../../../model/enums/EProjectStatus';
import {ReportService} from '../../../services/report.service';
import {AdminService} from '../../../services/admin.service';
import {SimpleDropDownComponentItem} from '../../../model/components/SimpleDropDownComponentItem';
import {Complain} from '../../../model/Complain';
import {EComplainReason} from '../../../model/enums/EComplainReason';
import {ComplainService} from '../../../services/complain.service';
import {ChangedProjectData} from '../../../model/ChangedProjectData';


@Component({
  selector: 'app-project-main-details',
  templateUrl: './project-main-details.component.html',
  styleUrls: ['./project-main-details.component.scss'],
  providers: [EnumPipe, CurrencyPipe]
})
export class ProjectMainDetailsComponent extends PainterBidComponent implements OnInit {
userdetails: any
  @Input() project: any;
  @ViewChild('gallery') gallery!: ElementRef;
  @ViewChild('galleryOverflowScroll') galleryOverflowScroll!: ElementRef;
  intervals = new Array();
  reportModalVisible = false;
  swipeSpeed = 6; // 1 is super speed
  EProjectType = EProjectType;
  EProjectStatus = EProjectStatus;
  EComplainReason = EComplainReason;
  markAsCompletedModalVisible = false;
  acceptDraftModalVisible = false;
  draftRequiresChangesModalVisible = false;
  makeComplainDialogVisible = false;
  reportReasons: SimpleDropDownComponentItem [] = [
    {
      label: 'Project contains contact details in description',
      visible: false
    }
  ];
  complainReasons!: SimpleDropDownComponentItem[];
  complain!: Complain;
  selectedReportReason!: any;
  selectedComplainReason!: EComplainReason;

  changedTitle: any;
  changedDescription: any;
  changedUrls: any;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private projectService: ProjectService,
              protected bidsService: BidsService,
              public tokenStorage: TokenStorageService,
              protected messageService: MessageService,
              protected formBuilder: FormBuilder,
              private enumPipe: EnumPipe,
              private currencyPipe: CurrencyPipe,
              private reportService: ReportService,
              private adminService: AdminService,
              private complainService: ComplainService
  ) {
    super(formBuilder, bidsService, tokenStorage, messageService);
  }


  public selectedImage = '';
  projectProperties: Map<string, any> = new Map<string, any>();
  bids!: [Bid];

  ngOnInit(): void {
  this.userdetails= localStorage.getItem('role')
  console.log(this.userdetails)
    this.myBid = this.project.myBid;
    this.complain = this.project.complain;
    if (this.myBid != null) {
      this.newOfferForm.patchValue(this.myBid);
      this.newOfferForm.controls['newPrice'].setValue(this.currencyPipe.transform(this.myBid.price));
    }
    console.log(this.newOfferForm)
    super.project = this.project;
    console.log(this.project)
    this.buildProperties();
    this.bids = this.project.bids;
  }

  getData(redirectValue: any, tag: any): void {
    this.projectService.getProjectWithFullDetailsByBasicData(redirectValue, tag).subscribe(response => {
      console.log(response);
      this.project = response;
      this.myBid = this.project.myBid;
      this.complain = this.project.complain;
      if (this.myBid != null) {
        this.newOfferForm.patchValue(this.myBid);
        this.newOfferForm.controls['newPrice'].setValue(this.currencyPipe.transform(this.myBid.price));
      }
      super.project = this.project;
      this.buildProperties();

      this.bids = this.project.bids;
      console.log(this.project);
    }, error => {
      if (error.status === 404) {
        this.router.navigate(['home/marketplace']);
      }
    });
  }


  buildProperties(): void {
    this.setPropertyIfNotNullOrEmpty('Additional work', this.prepareMoreComplexDetails(['CLEANING', 'DISPOSING', 'FURNITURE', 'LIGHT', 'WASH']));
    this.setPropertyIfNotNullOrEmpty('What to paint', this.prepareMoreComplexDetails(['CEILING', 'DOORS', 'TRIM', 'WALLS', 'FRAMES']));
    this.setPropertyIfNotNullOrEmpty('Color finish', this.prepareMoreComplexDetails(['COLOR_CHANGE', 'SAME_COLOR', 'FAUX_FINISH', 'TEXTURE_COATING']));

    this.setPropertyIfNotNullOrEmpty('Type of project', this.enumPipe.transform(this.project.projectType, EProjectType, true, true));
    this.setPropertyIfNotNullOrEmpty('Job type', this.project.tags?.jobType);
    this.setPropertyIfNotNullOrEmpty('Due date', this.project.projectDetails?.dueDate);
    this.setPropertyIfNotNullOrEmpty('Property type', this.project.tags?.propertyType);
    this.setPropertyIfNotNullOrEmpty('Scheduling preferences', this.project.tags?.schedulingPreferences);
    this.setPropertyIfNotNullOrEmpty('Provided items', this.project.tags?.providingItems);

    if (this.project.projectType === 'PROJECT_INTERIOR') {
      this.setPropertyIfNotNullOrEmpty('Number of rooms', this.project.tags?.roomsNumber);
      this.setPropertyIfNotNullOrEmpty('Estimated surface', this.project.tags?.surfaceArea);
      this.setPropertyIfNotNullOrEmpty('Ceiling height', this.project.tags?.ceilingHeight);
      this.setPropertyIfNotNullOrEmpty('Surface condition', this.project.tags?.surfaceCondition);
      this.setPropertyIfNotNullOrEmpty('Wallpaper', this.project.tags?.hasWallpaper);

    } else if (this.project.projectType === 'PROJECT_EXTERIOR') {

    } else if (this.project.projectType === 'PROJECT_OTHERS') {

    }
    this.setPropertyIfNotNullOrEmpty('Other information', this.project.tags?.otherInformation);
  }

  setPropertyIfNotNullOrEmpty(title: string, value: string): void {
    if (value != null && value !== '') {
      while (value.includes('_')) {
        value = value.replace('_', ' ');
      }
      if (!value.includes(',')){
        value = value[0] + value.substr(1).toLowerCase();
      } else {
        value = value.slice(0, value.length - 2);
      }
      this.projectProperties.set(title, value);
    }
  }

  prepareMoreComplexDetails(showCase: string[]): string {
  console.log(showCase, this.project.tags)
    const asArray: string[] = Object.values(this.project.tags);
    console.log(asArray)
    let additionalWork = '';
    asArray.forEach(tag => {
      if (tag !== null) {
        showCase.forEach(item => {
       
         
         
            additionalWork += tag[0]?.toUpperCase()  + tag[0]?.substr(1)?.toLowerCase() + ', ';
          
        });
      }
    });

    while (additionalWork.includes('_')) {
      additionalWork = additionalWork.replace('_', ' ');
    }
    return additionalWork;
  }


  isPending(): boolean {
  console.log(this.project.status)
    return this.project.status === EProjectStatus.PENDING;
  }

  isAwaiting(): boolean {
    return this.project.status === EProjectStatus.AWAITING;
  }

  isInProgress(): boolean {
    return this.project.status === EProjectStatus.IN_PROGRESS;
  }

  onBidPriceChanged(): void {
  }

  viewImage(ev: any): void {
    this.selectedImage = ev;
    console.log(this.selectedImage);
  }

  navigateToBidDetails(): void {
    this.router.navigateByUrl('/home/my-bids/bid/' + this.myBid.tag);
  }

  originalOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return 0;
  };

  onBidPlaced(): void {
  }

  onBidDeleted(): void {
  }

  isGalleryOverflown(): boolean {
    const element = document.getElementById('images') as HTMLElement;
    return element?.scrollHeight > element?.clientHeight || element?.scrollWidth > element?.clientWidth;
  }

  swipeRight(): void {
    this.galleryOverflowScroll.nativeElement.scrollBy(10, 0);
  }

  swipeLeft(): void {
    this.galleryOverflowScroll.nativeElement.scrollBy(-10, 0);
  }

  mouseDownSwipeLeft(): void {
    const interval = setInterval(() => {
      this.swipeLeft();
    }, this.swipeSpeed);

    this.intervals.push(interval);
  }

  mouseDownSwipeRight(): void {
    const interval = setInterval(() => {
      this.swipeRight();
    }, this.swipeSpeed);

    this.intervals.push(interval);
  }

  clearInterval(): void {
    for (const interval of this.intervals) {
      window.clearInterval(interval);
    }
  }

  preFormatValueOnBlur(event: any): void {
    event.target.value = this.currencyPipe.transform(event.target.value);
  }

  undoCurrencyPipe(event: any): void {
    if (event.target.value != null && event.target.value !== '') {
      event.target.value = parseInt(event.target.value.replace('$', '').replace(',', ''), 10);
    }
  }

  acceptDraft(): void {
    this.acceptDraftModalVisible = false;
    this.adminService.changeProjectStatus(this.project.tag, EProjectStatus.PENDING).subscribe(respones => {
      this.tokenStorage.doDefaultRouting();
    });
  }

  acceptDraftAfterChanges(): void {
    if (this.changedTitle === this.project.projectDetails.title) {
      this.changedTitle = null;
    }
    if (this.changedDescription === this.project.projectDetails.description) {
      this.changedDescription = null;
    }
    const changedData = {title: this.changedTitle, description: this.changedDescription} as ChangedProjectData;
    this.adminService.changeProjectData(this.project.tag, changedData).subscribe(response => {
      this.tokenStorage.doDefaultRouting();
    });
  }


  markCompleted(): void {
    this.markAsCompletedModalVisible = false;
    const redirectValue = this.activatedRoute.snapshot.paramMap.get('projectType');
    this.projectService.markProjectAsCompleted(this.project.tag).subscribe(res => {
      this.getData(redirectValue, this.project.tag);
    });
  }

  select(event: any): void {
    this.selectedReportReason = event.label;
  }

  sendReport(): void {
    this.reportService.postReport(this.selectedReportReason, 'home/marketplace/project-marketplace-details/' + getRedirectLinkForProjectType(this.project.projectType).value + '/' + this.project.tag, this.project.customer.id).subscribe(res => {
      this.reportModalVisible = false;
      this.project.reportPresent = true;
    });
  }

  openMakeComplainModal(): void {
    this.selectedComplainReason = EComplainReason.DEFUALT;
    this.complainReasons = [
      {
        label: 'No contact with painter',
        visible: true,
        value: EComplainReason.NO_CONTACT_WITH_PAINTER
      },
      {
        label: 'Painter screwed his work',
        visible: this.project.status === EProjectStatus.IN_PROGRESS,
        value: EComplainReason.PAINTER_DID_JOB_BADLY
      }


    ];
    this.makeComplainDialogVisible = true;
  }

  makeComplain(): void {
    const complain: Complain = {content: 'empty for now', reason: this.selectedComplainReason, projectTag: this.project.tag};
    this.complainService.postComplain(complain).subscribe(res => {
      this.makeComplainDialogVisible = false;
    }, error => {

    });
  }

  selectComplain(event: any): void {
    this.selectedComplainReason = event.value;
  }

  closeComplainModal(): void {
    this.makeComplainDialogVisible = false;
    this.selectedComplainReason = EComplainReason.DEFUALT;
  }

  prepareDraftRequiresChanges(): void {
    this.changedTitle = this.project.projectDetails.title;
    this.changedDescription = this.project.projectDetails.description;
    this.changedUrls = this.project.projectDetails.imageURLs;


    this.draftRequiresChangesModalVisible = true;
  }
}
