<div class="rating-wrapper" *ngIf="avgRating">
  <div class="stars">
    <div class="stars-wrapper">
    <img class="rating-icon" src="assets/icons/star.png"
         *ngFor="let brush of counter(stars)">
         
    <img class="rating-icon" src="assets/icons/star-gray.png" *ngFor="let brush of counter(grayStars)">
    </div>
    <!-- <div class="avg-rating">{{avgRatingString}}<span class="total-comments" *ngIf="totalCommentsForPainter">({{totalCommentsForPainter}})</span></div> -->
    <div class="avg-rating">{{avgRatingString}}</div>
  </div>
</div>
<div class="rating-wrapper" *ngIf="!avgRating">
  <!-- <div class="select-rating">Select Rating</div> -->
  <div class="d-flex">
    <div class="stars">
      <div class="stars-wrapper">
      <img [src]="brush.isSelected ? '/assets/icons/star.png' : './assets/icons/star-gray.png'"
           (mouseover)="onMouseOverSelectStar(brush)" (mouseout)="onMouseOutUnselectStar()"
           class="selectable rating-icon" (click)="emitSelectedRate(brush)" *ngFor="let brush of selectableStar"
           alt="brush">
      </div>
    </div>
    <div class="avg-rating" *ngIf="selectedRate">{{selectedRate}}.0</div>
  </div>
  
</div>
