import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  faqData:any
  searchForm:FormGroup
  data: any ={}
  searchData: any;
  // constructor(  private authService: AuthService,) { }
  constructor(private authservice:AuthService, private fb:FormBuilder) { 
    this.searchForm=this.fb.group({
      search:[]
    });
    this.searchForm.controls['search'].valueChanges
    .pipe(debounceTime(500)).subscribe(res=>{
      this.applyFilter(res);
    })
  }


  ngOnInit(): void {
  this.getprofile();
  this.getFaq()
  }
  
  applyFilter(value: any) {
    this.searchData = value;
    this.getFaq()
  }

  toggleAccordion(index: number) {
    this.faqData.forEach((item:any, i:any) => {
      if (i !== index) {
        item.active = false; // Close other accordions
      }
    });

    this.faqData[index].active = !this.faqData[index].active;
  }

  getFaq() {
    const data={
      search:this.searchData ?? ''
    }
    this.authservice.getHelperList(data).subscribe(res => {
      res.list.map((item: any) => {
        item.active = false;
      });
      console.log(res.list,'filter');
      this.faqData=res.list
    })
  }


   getprofile(){
   let obj={}
		 this.authservice.getCMSdata('help').subscribe((response : any) => {
         this.data= response.response
         
        // this.previewImage2 = this.path + this.profile.profile_image
      //     this.selectedFile2 =  this.profile.profile_image
        // console.log(this.profileForm)
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }
}
