import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BidRequest} from '../model/BidRequest';
import {TokenStorageService} from './token-storage.service';
import {CustomHttpParameterCodec} from '../_helpers/requests/CustomHttpParameterCodes';
import {environment} from '../../environments/environment';
import {BidStatusChange} from '../components/bids/bid-card-mini/bid-card-mini.component';
import {EBidStatus} from '../model/enums/EBidStatus';
import {PageableResponse} from '../model/PageableResponse';
import {BidPriceChange} from '../components/MAIN_COMPONENTS/PainterBidComponent';


const API = environment.SERVER_BASE_URL + 'bids';

@Injectable({
  providedIn: 'root'
})

export class BidsService {

  public encoder: CustomHttpParameterCodec;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
    this.encoder = new CustomHttpParameterCodec();
  }

  makeNewBid(bid: BidRequest): Observable<any> {
    return this.http.post<BidRequest>(API, bid);
  }

  changeBidStatus(bidStatusChange: BidStatusChange): Observable<any> {
    if (bidStatusChange?.status === null || bidStatusChange?.status === undefined) {
      throw new Error('Required parameter status was null or undefined when calling changeBidStatus.');
    }
    if (bidStatusChange?.tag === null || bidStatusChange?.tag === undefined) {
      throw new Error('Required parameter tag was null or undefined when calling changeBidStatus.');
    }
    return this.http.patch(API + '/change-bid-status', bidStatusChange);
  }

  changeBidPrice(bidPriceChange: BidPriceChange): Observable<any> {
    if (bidPriceChange?.price === null || bidPriceChange?.price === undefined) {
      throw new Error('Required parameter price was null or undefined when calling changeBidPrice.');
    }
    if (bidPriceChange?.tag === null || bidPriceChange?.tag === undefined) {
      throw new Error('Required parameter tag was null or undefined when calling changeBidPrice.');
    }
    return this.http.patch(API + '/change-bid-price', bidPriceChange);
  }

  getAllBidBasic(status?: EBidStatus, page?: number, pageSize?: number, sort?: string, sortDir?: string): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (status !== undefined && status !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        status, 'status');
    }
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    return this.http.get<PageableResponse>(API + '/all', {params: queryParameters});
  }

  /**
   * It returns all bid related information for PAINTER
   * when entering bid details from PAINTER perspective
   */
  getBidWithAllDetailsForPainer(bidTAG: string): Observable<any> {
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, bidTAG, 'bidTAG');
    return this.http.get<any>(API + '/bid-details-for-painter', {params: queryParams});
  }

  getEstimatedFeePriceForBid(bidPrice: number): Observable<any> {
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, bidPrice, 'price');
    return this.http.post<number>(API + '/estimate-fee', null, {params: queryParams});
  }
}
