import {ChangeDetectorRef, Component} from '@angular/core';
import {ServerDataSource} from 'ng2-smart-table';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BaseTableViewComponent} from '../../MAIN_COMPONENTS/BaseTableViewComponent';
import {TableActionsComponent} from '../../table-actions/table-actions.component';
import {EProjectType, getProjectTypeByRedirectValue, getRedirectLinkForProjectType} from '../../../model/enums/EProjectType';
import {EProjectStatus} from '../../../model/enums/EProjectStatus';
import {ProjectTypePickerComponent} from '../custom-filters/project-type-picker/project-type-picker.component';
import {StatePickerComponent} from '../custom-filters/state-picker/state-picker.component';
import {ProjectStatusPickerComponent} from '../custom-filters/project-status-picker/project-status-picker.component';
import {CityPickerComponent} from '../custom-filters/city-picker/city-picker.component';
import {LastDaysPickerComponent} from '../custom-filters/last-days-picker/last-days-picker.component';
import {EnumPipe} from '../../../pipes/enum.pipe';
import {Router} from '@angular/router';
import {AdminService} from '../../../services/admin.service';

function rowClassFunction(row: any): string {
  if (row.data.projectType === EProjectType.PROJECT_EXTERIOR) {
    return 'inactive';
  }
  if (row.data.projectType === EProjectType.PROJECT_EXTERIOR) {
    return 'success';
  }
  if (row.data.dbInstanceStatus == null) {
    return '';
  }
  return 'danger';
}

@Component({
  selector: 'app-brush-table-projects',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss'],
  providers: [EnumPipe]
})
export class ProjectsTableComponent extends BaseTableViewComponent {

  constructor(private enumPipe: EnumPipe, private httpClient: HttpClient, private cdRef: ChangeDetectorRef, private router: Router, private adminService: AdminService) {
    super();
    this.source = new ServerDataSource(httpClient, {
      endPoint: environment.SERVER_BASE_URL + 'admin/projects',
      dataKey: 'content',
      totalKey: 'totalElements',
      pagerLimitKey: 'pageSize',
      pagerPageKey: 'page',
      sortFieldKey: 'sort',
      sortDirKey: 'sortDir',
      filterFieldKey: 'search_#field#'
    });
    this.source.onChanged().subscribe(change => {
      this.totalElements = this.source.count();
      this.cdRef.detectChanges();
    });
  }

  settings = {
    rowClassFunction: (row: any) => {
      if (row.data.status === 0) { // PENDING
        return 'neutral';
      } else if (row.data.status === 1) { // IN_PROGRESS
        return 'warning';
      } else if (row.data.status === 10) { // COMPLETED
        return 'success';
      } else if (row.data.status === -1) { // COMPLAIN
        return 'danger';
      }
      return '';
    },
    mode: 'external',
    actions: {
      delete: false,
      edit: false,
      add: true,
    },
    pager: {
      display: true,
      perPage: this.pageSize
    },
    noDataMessage: this.noDataMessage,
    columns: {
      actions: {
        title: 'Actions',
        sort: false,
        filter: false,

        type: 'custom',
        renderComponent: TableActionsComponent,
        onComponentInitFunction: (rc: any) => {
          this.totalElements = this.source.count();
          this.cdRef.detectChanges();
          rc.addAction(
            {icon: 'eye', pack: 'fas', tip: 'View project'}).subscribe((x: any) => {
            this.router.navigate(['home/my-projects/details/' + getRedirectLinkForProjectType(rc.rowData.projectType).value + '/' + rc.rowData.tag]);
          });
        },
        width: '15vw'
      },
      status: {
        title: 'Project status',
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.status, EProjectStatus),
        filter: {
          type: 'custom',
          component: ProjectStatusPickerComponent,
        }
      },
      tag: {
        title: 'Project tag'
      },
      projectType: {
        title: 'Project type',
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.projectType, EProjectType, false, true),
        filter: {
          type: 'custom',
          component: ProjectTypePickerComponent,
        }
      },
      state: {
        title: 'State',
        filter: {
          type: 'custom',
          component: StatePickerComponent,
        }
      },
      city: {
        title: 'City',
        filter: {
          type: 'custom',
          component: CityPickerComponent,
        }
      },
      fullAddress: {
        title: 'Full address'
      },
      postedDate: {
        title: 'Posted date',
        filter: {
          type: 'custom',
          component: LastDaysPickerComponent,
        }
      }
    }
  };
}
