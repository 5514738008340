import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {NotificationViewComponent} from '../MAIN_COMPONENTS/NotificationViewComponent';
import {EMessageStatus} from '../../model/enums/EMessageStatus';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent extends NotificationViewComponent {
  constructor(protected router: Router) {
    super(router);
  }
  @Input() clearTextVisible = true;

  deleteMessageModalVisible = false;

  notificationNewBidPainter(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationNewBidCustomer(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationBidAcceptedCustomer(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationBidCanceledCustomer(): void {
    this.icon = 'assets/icons/notifications/notification-icon-cancelled.png';
  }


  notificationBidAcceptedPainter(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationBidCanceledPainter(): void {
    this.icon = 'assets/icons/notifications/notification-icon-cancelled.png';
  }

  notificationBidDeniedPainter(): void {
    this.icon = 'assets/icons/notifications/notification-icon-cancelled.png';
  }

  notificationBidDeniedCustomer(): void {
    this.icon = 'assets/icons/notifications/notification-icon-cancelled.png';
  }

  notificationPaymentDoneCustomer(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationPaymentDonePainter(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationNewCommentPainter(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationProjectCreated(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }

  notificationProjectAcceptedFromDraft(): void {
    this.icon = './assets/icons/notifications/notification-icon-star.png';
  }


  notificationProjectDeletedCustomer(): void {
    this.icon = 'assets/icons/notifications/notification-icon-cancelled.png';
  }

  notificationProjectDeletedPainter(): void {
    this.icon = 'assets/icons/notifications/notification-icon-cancelled.png';
  }

  navigate(): void {
    this.markMessageAsRead();
    super.navigate();
  }

  deleteModalToggle(event: any): void {
    event?.stopPropagation();
    this.deleteMessageModalVisible = !this.deleteMessageModalVisible;
  }
}
