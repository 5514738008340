import {Component} from '@angular/core';
import {BrushTableCustomFilterComponent} from '../../../MAIN_COMPONENTS/BrushTableCustomFilterComponent';


@Component({
  selector: 'app-state-picker',
  templateUrl: './city-picker.component.html',
  styleUrls: ['./city-picker.component.scss']
})
export class CityPickerComponent extends BrushTableCustomFilterComponent {

  constructor() {
    super();
  }
  emit(stateName: string): void {
    if (stateName != null) {
      this.filter.emit(stateName);
    }
  }

}
