import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TokenStorageService} from '../../../services/token-storage.service';
import {ERole} from '../../../model/enums/ERole';
import {MessageService} from '../../../services/message.service';
import {AuthService} from '../../../services/auth.service';
import { Router } from '@angular/router';
import {HttpserviceService} from '../../../services/httpservice.service';

@Component({
    selector: 'app-side-navbar',
    templateUrl: './side-navbar.component.html',
    styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit, AfterViewInit {
    isAdmin = false;
    isPainter = false;
    isCustomer = false;
    profile: any
    @ViewChild('navRoutingSection') private navRoutingSection!: ElementRef;
    @ViewChild('sidebar') private sidebar!: ElementRef;
    userData: any;
        path :any= 'https://production.promaticstechnologies.com/brush_rest_apis/public/usersImages/'

    constructor(public tokenStorage: TokenStorageService,private authService: HttpserviceService,
                public messageService: MessageService, public service: AuthService, private router : Router) {
    }

  ngOnInit(): void {
  this.getProfile()
        console.log(localStorage.getItem('role'))

   if(localStorage.getItem('role')=='painter'){
             this.isPainter = true;
      }else if(localStorage.getItem('role')=='customer'){
             this.isCustomer = true;
      }
    this.userData = this.tokenStorage.getUser();
    this.checkRoles(this.userData.role);
    this.messageService.getUnreadChatConversationsCount().subscribe(res => {
      this.messageService.setUnreadConversationCount(res.content);
    });
  }

    ngAfterViewInit(): void {
      this.calculateSideBarHeight();
     
    }
    getProfile(){
		 this.authService.getprofile().subscribe((response : any) => {
         this.profile= response.profileData
         
        

      })
  }

    checkRoles(role: ERole): void {
        switch (role as ERole) {
            case ERole.ROLE_ADMIN :
                this.isAdmin = true;
                break;
            case ERole.ROLE_PAINTER :
                this.isPainter = true;
                break;
            case ERole.ROLE_CUSTOMER :
                this.isCustomer = true;
                break;
        }
    }

    logout(): void {
    let obj={
        'device_token' : localStorage.getItem('fcmtoken'),
         'device_id' : localStorage.getItem('device_id')

    }
      this.authService.deteleFcm(obj).subscribe((resp: any) => {
      console.log("token=> deleted ", resp);
      this.service.logout();
    })
  //      
         //  this.service.logout();
      

    }

  calculateSideBarHeight(): void {
    if (this.sidebar) {
      this.sidebar.nativeElement.style.height = (window.innerHeight) + 'px';
    }
  }
}
