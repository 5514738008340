import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {TokenStorageService} from '../../../services/token-storage.service';
import {EMessageType} from '../../../model/enums/EMessageType';
import {BaseUploadFileComponent} from '../../MAIN_COMPONENTS/base-upload-file.component';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatMessageComponent extends BaseUploadFileComponent implements OnInit {
  @Input() message!: any;
  messageTextContent: any;
  isItMyMessage!: boolean;
  amICustomer!: boolean;

  constructor(public tokenStorage: TokenStorageService) {
    super();
  }

  ngOnInit(): void {
    if (this.message != null) {
      this.isItMyMessage = this.message.sender === this.tokenStorage.getEmail();
      this.amICustomer = this.tokenStorage.isUserCustomer();
      if (this.message.messageType === EMessageType.FILE) {
        const fileExtension = this.message.content.split('.');
        this.assignFileImage(fileExtension[fileExtension.length - 1]);
      }
      if (this.message.messageType === EMessageType.TEXT) {
        this.messageTextContent = this.urlify(this.message.content);
      }
    }
  }
  downloadFile(): void {
    const base64String = this.message.file;
    const fileExtension = this.message.fileName.split('.');
    const source = this.getBase64Prefix(fileExtension[fileExtension.length - 1]) + base64String;
    const link = document.createElement('a');
    link.href = source;
    link.download = `${this.message.fileName}`;
    link.click();
  }
  urlify(text: any): any {
    return text?.replace(this.urlRegex, (url: any) => {
      return '<a href="' + url + '">' + url + '</a>';
    });
  }
}
