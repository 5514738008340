import {Component} from '@angular/core';
import {EProjectType} from '../../model/enums/EProjectType';
import {ERoomType} from '../../model/enums/ERoomType';
import {EProjectStatus} from '../../model/enums/EProjectStatus';
import {SelectInputOption} from '../../model/components/SelectInputOption';
import {PageableComponent} from './PageableComponent';
import {TSurfaceArea} from '../../model/enums/tags/TSurfaceArea';
import {SurfaceCondition} from '../../model/enums/template/SurfaceCondition';


@Component({
  template: ''
})
export abstract class BrushProjectsViewComponent extends PageableComponent {
  searchStateCityZipCode: any;
  searchDistance: any;

  projectTypeExpanded = true;
  projectTagsSurfaceAreaSectionExpanded = true;
  projectTagsSurfaceConditionSectionExpanded = true;
  roomQuantityExpanded = true;
  jobTypeExpanded = true;
  roomTypeExpanded = true;
  statusesExpanded = true;
  sortingFiltersExpanded = true;
  projectInteriorSelected = false;
  projectExteriorSelected = false;


  displayGrid = false;

  orderOptions: SelectInputOption[] = [
    {optionLabel: 'Posted date: Newest',  optionValue: 'projectDetails.postedDate', sortDir: 'DESC', optionSelected: true, optionDisabled: false},
    {optionLabel: 'Posted date: Oldest', optionValue: 'projectDetails.postedDate', sortDir: 'ASC', optionSelected: false, optionDisabled: false}
  ];
  projectTypes = [
    {id: 1, label: 'Interior', selected: false, value: EProjectType.PROJECT_INTERIOR},
    {id: 2, label: 'Exterior', selected: false, value: EProjectType.PROJECT_EXTERIOR}
  ];
  projectTagsSurfaceArea = [
    {id: 1, label: 'Less than 30', selected: false, value: TSurfaceArea.LESS_THAN_30},
    {id: 2, label: 'Between 30 and 50', selected: false, value: TSurfaceArea.MORE_THAN_30_LESS_THAN_50},
    {id: 3, label: 'Between 50 and 70', selected: false, value: TSurfaceArea.MORE_THAN_50_LESS_THAN_70},
    {id: 4, label: 'More than 70', selected: false, value: TSurfaceArea.MORE_THAN_70},
    {id: 5, label: 'Need help in measure', selected: false, value: TSurfaceArea.NEED_HELP_IN_MEASURE}
  ];

  projectTagsSurfaceCondition = [
    {id: 1, label: 'Bad', selected: false, value: SurfaceCondition.BAD},
    {id: 2, label: 'Poor', selected: false, value: SurfaceCondition.POOR},
    {id: 3, label: 'Fair', selected: false, value: SurfaceCondition.FAIR},
    {id: 4, label: 'Good', selected: false, value: SurfaceCondition.GOOD},
  ];

  roomsQuantity = [
    {id: 1, label: 'All', selected: true, value: 0},
    {id: 2, label: '1', selected: false, value: 1},
    {id: 3, label: '2', selected: false, value: 2},
    {id: 4, label: '3', selected: false, value: 3},
    {id: 5, label: '4', selected: false, value: 4},
    {id: 6, label: '5', selected: false, value: 5},
    {id: 7, label: '6+', selected: false, value: 6}
  ];
  roomTypes = [
    {id: 1, label: 'All', selected: true, value: null},
    {id: 2, label: 'Living room', selected: false, value: ERoomType.LIVING_ROOM},
    {id: 3, label: 'Kitchen', selected: false, value: ERoomType.KITCHEN},
    {id: 4, label: 'Bedroom', selected: false, value: ERoomType.BEDROOM},
    {id: 5, label: 'Dining room', selected: false, value: ERoomType.DINING_ROOM},
    {id: 6, label: 'Bathroom', selected: false, value: ERoomType.BATHROOM},
    {id: 7, label: 'Hallway', selected: false, value: ERoomType.HALLWAY},
    {id: 8, label: 'Other', selected: false, value: ERoomType.OTHER},
  ];

  // FIXME ???
  exteriorJobs = [
    {id: 1, label: 'All', selected: true, value: null},
    {id: 2, label: 'Bungalow', selected: false, value: ERoomType.LIVING_ROOM},
    {id: 3, label: 'Storey house', selected: false, value: ERoomType.LIVING_ROOM},
    {id: 4, label: 'Tenement', selected: false, value: ERoomType.KITCHEN},
    {id: 5, label: 'Shed', selected: false, value: ERoomType.KITCHEN},
    {id: 6, label: 'Roof', selected: false, value: ERoomType.BEDROOM},
    {id: 7, label: 'Fence', selected: false, value: ERoomType.DINING_ROOM},
    {id: 8, label: 'Multi-storey building', selected: false, value: ERoomType.DINING_ROOM},
  ];
  projectStatuses = [
    {id: 1, label: 'Pending', selected: true, value: EProjectStatus.PENDING},
    {id: 2, label: 'In progress', selected: false, value: EProjectStatus.IN_PROGRESS},
    {id: 3, label: 'Completed', selected: false, value: EProjectStatus.COMPLETED},
    {id: 4, label: 'Complain', selected: false, value: EProjectStatus.COMPLAIN},
    {id: 5, label: 'Archived', selected: false, value: EProjectStatus.ARCHIVED},
  ];

  abstract filter(startFromFirstPage: boolean): void;

  projectTypeCheckboxChanged(id: number): void {

    this.projectTypes.forEach(item => {
      if (item.id === id) {
        item.selected = !item.selected;
        this.projectInteriorSelected = this.isProjectInteriorSelected;
        this.projectExteriorSelected = this.isProjectExteriorSelected;
      }
    });

  }

  projectTagSurfaceAreaCheckboxChanged(id: number): void {
    this.projectTagsSurfaceArea.forEach(item => {
      if (item.id === id) {
        item.selected = !item.selected;
      } else {
        item.selected = false;
      }
    });
  }

  projectTagSurfaceConditionCheckboxChanged(id: number): void {
    this.projectTagsSurfaceCondition.forEach(item => {
      if (item.id === id) {
        item.selected = !item.selected;
      } else {
        item.selected = false;
      }
    });
  }
  roomTypeCheckboxChanged(room: any): void {
    if (room.value == null && room.selected === false) {
      this.roomTypes.forEach(item => {
        if (item.value != null) {
          item.selected = false;
        }
      });
      room.selected = true;
    } else {
      this.roomTypes.forEach(item => {
        if (item.value == null && item.selected === true) {
          item.selected = false;
        } else if (item.id === room.id) {
          item.selected = !item.selected;
        }
      });
    }
  }

  roomsQuantityCheckboxChanges(roomQ: any): void {
    this.roomsQuantity.forEach(item => {
      if (item.id === roomQ.id) {
        item.selected = !item.selected;
      } else {
        item.selected = false;
      }
    });
  }
  projectStatusesChanges(id: number): void {
    this.projectStatuses.forEach(item => {
      if (item.id === id) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
  }

  exteriorJobTypeChanged(job: any): void {
    if (job.value == null && job.selected === false) {
      this.exteriorJobs.forEach(item => {
        if (item.value != null) {
          item.selected = false;
        }
      });
      job.selected = true;
    } else {
      this.exteriorJobs.forEach(item => {
        if (item.value == null && item.selected === true) {
          item.selected = false;
        } else if (item.id === job.id) {
          item.selected = !item.selected;
        }
      });
    }
  }

  get selectedProjectTypes(): EProjectType[] {
    return this.projectTypes.filter(pt => pt.selected === true).map(pt => pt.value);
  }
  get selectedSurfaceArea(): TSurfaceArea {
    return this.projectTagsSurfaceArea.filter(pt => pt.selected === true).map(pt => pt.value)[0];
  }
  get selectedSurfaceCondition(): SurfaceCondition {
    return this.projectTagsSurfaceCondition.filter(pt => pt.selected === true).map(pt => pt.value)[0];
  }

  get selectedProjectStatus(): string {
    return EProjectStatus[this.projectStatuses.filter(pt => pt.selected === true).map(pt => pt.value)[0]];
  }

  get selectedRoomTypes(): any {
    return this.roomTypes.filter(pt => pt.selected === true).map(pt => pt.value);
  }
  get isProjectInteriorSelected(): boolean {
    return this.selectedProjectTypes != null && this.selectedProjectTypes.includes(EProjectType.PROJECT_INTERIOR);
  }

  get isProjectExteriorSelected(): boolean {
    return this.selectedProjectTypes != null && this.selectedProjectTypes.includes(EProjectType.PROJECT_EXTERIOR);
  }

  get selectedRoomsQuantity(): any {
    return this.roomsQuantity.filter(pt => pt.selected === true).map(pt => pt.value)[0];
  }

  setSearchStateCityZipCode(event: any): void {
    this.searchStateCityZipCode = event.target.value;
  }
  setSearchDistance(event: any): void {
    this.searchDistance = event.target.value;
  }

  get selectedOrdering(): SelectInputOption {
    return this.orderOptions.filter(pt => pt.optionSelected === true)[0];
  }

  get selectedPageSize(): SelectInputOption {
    return this.pageSizeOptions.filter(pt => pt.optionSelected === true)[0];
  }

  changeOrdering(event: any): void {
    this.orderOptions.forEach(item => {
      if (item.optionLabel === event.optionLabel) {
        item.optionSelected = true;
      } else {
        item.optionSelected = false;
      }
    });
    this.filter(false);
  }
}



