<div class="crd_wpr">
  <div class="main-wrapper rm_text_bck_grnd">
    <div class="div_upr_text d-flex">
      <div class="d-flex align-items-center">
        <a routerLink="/home/marketplace">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </a>
        <h1><a routerLink="/home/marketplace">Project Detail</a></h1>
      </div>
      <app-notification-bell></app-notification-bell>
      <!-- <div class="bell_notification">
        <img src="assets/icons/notifications-icon.png" alt="notifications-icon" />
        <div class="unread-notification-count">2</div>
      </div> -->
    </div>
    <nb-card class="nb-theme-brush card rooms_crds_rpn pntrprjct_dtl">
      <nb-card-body>
        <nb-tabset>
          <nb-tab tabTitle="Details" class="active">
            <div class="tab-content-wrapper d-flex">
              <div class="project_my">
                <div>
                  <section class="image-section-wrapper">
                    <ng-image-slider #nav [images]="imageObject" [infinite]="false" [autoSlide]="1"
                      [imageSize]="{ width: '40%', height: 300 }" slideImage="1"></ng-image-slider>
                  </section>
                  <section class="details">
                    <div class="title-wrapper justify_content">
                      <span class="title">{{
                        projectDetails.project_title
                        }}</span>
                      <a class="reprt_text" (click)="modalFlag = true">Report</a>
                    </div>
                    <div class="sub-title">
                      <div class="project-type">
                        {{ projectDetails.project_type | titlecase }}
                      </div>
                      <div class="circle"></div>
                      <div class="when-posted" *ngIf="projectDetails.days_remaining > 0">
                        Posted {{ projectDetails.days_remaining }} days ago
                      </div>
                      <div class="when-posted" *ngIf="projectDetails.days_remaining == 0">
                        Posted today
                      </div>
                      &nbsp; &nbsp;
                      <div class="circle"></div>
                      <div class="project-type">
                        {{ projectDetails?.customerDetails?.city | titlecase }},{{
                        projectDetails?.customerDetails?.state | titlecase }}


                      </div>
                    </div>

                    <div class="description">
                      <pre>{{ projectDetails.description }}
                                        </pre>
                    </div>
                    <div class="properties">
                      <div class="property">
                        <div class="property-key">Type of Project</div>
                        <span class="property-value">
                          {{ projectDetails.project_type | titlecase }}
                        </span>
                      </div>

                      <div class="property">
                        <div class="property-key">Type of Property</div>
                        <span class="property-value">
                          {{ projectDetails.home_type | titlecase }}
                        </span>
                      </div>

                      <div class="property">
                        <div class="property-key">Job Type</div>
                        <div class="property-value" *ngIf="projectDetails.creater_type != 'basic'">
                          {{ projectDetails.creater_type | titlecase }}
                        </div>
                        <div class="property-value" *ngIf="projectDetails.creater_type == 'basic'">
                          Basic without an Estimate
                        </div>
                      </div>
                      <div class="property">
                        <div class="property-key">Property Type</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.property_type | titlecase }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.jobtime">
                        <div class="property-key">Scheduling Preferences</div>
                        <div class="property-value" *ngIf="
                            projectDetails.jobtime ==
                            'I_can_adjust_to_the_painter'
                          ">
                          I can adjust to the painter
                        </div>
                        <div class="property-value" *ngIf="projectDetails.jobtime == 'anytime'">
                          Anytime
                        </div>
                        <div class="property-value" *ngIf="projectDetails.jobtime == 'during_the_day'">
                          During the day
                        </div>
                      </div>
                      <div class="property" *ngIf="projectDetails.room">
                        <div class="property-key">Number of Rooms</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.room.length }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.surface_area_to_be_painted">
                        <div class="property-key">
                          Surface area to be painted
                        </div>
                        <div>
                          <span class="property-value">
                            {{
                            projectDetails.surface_area_to_be_painted.replace(
                            "_",
                            " "
                            ) | titlecase
                            }}
                            ft
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.height_of_ceiling">
                        <div class="property-key">Ceiling Height</div>
                        <div>
                          <span class="property-value">
                            {{
                            projectDetails.height_of_ceiling
                            ? projectDetails.height_of_ceiling + " " + "feet"
                            : "N/A"
                            }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.wallpaper">
                        <div class="property-key">Has wallpaper</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.wallpaper }}
                          </span>
                        </div>

                      </div>
                          <div class="property" *ngIf="projectDetails?.room_type?.length">
                        <div class="property-key">Items in scope</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails?.room_type ; let i = index;">
                           {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.room_type.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails?.what_painted?.length">
                        <div class="property-key">What needs to be painted</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails.what_painted; let i = index;">
                          {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.what_painted.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>

                      <div class="property" *ngIf="projectDetails?.finishing?.length">
                        <div class="property-key">Finishing</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails.finishing; let i = index;">
                            {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.finishing.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>

                      <div class="property" *ngIf="projectDetails?.project_applicable?.length">
                        <div class="property-key">Project Applicable</div>
                        <div>
                          <span class="property-value" *ngFor="let item of projectDetails.project_applicable; let i = index;">
                            {{ item | removeUnderscore }}  {{i === projectDetails.project_applicable.length -1 ? '' : ',' }}
                          </span>
                        </div>

                      </div>

                      <div class="property" *ngIf="projectDetails.room">
                        <div class="property-key">Room number</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.room.length }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.providing_items">
                        <div class="property-key">
                          Customer will provide items
                        </div>
                        <div>
                          <span class="property-value">
                            {{
                            projectDetails.providing_items | removeUnderscore
                            }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.surface_area">
                        <div class="property-key">Surface area</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.surface_area }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.surface_condition">
                        <div class="property-key">Surface condition</div>
                        <div>
                          <span class="property-value" *ngIf="projectDetails.surface_condition  == 'good'">
                            No repairs needed
                          </span>
                          <span class="property-value" *ngIf="projectDetails.surface_condition  == 'fair'">
                            Need some repairs
                          </span>
                          <span class="property-value" *ngIf="projectDetails.surface_condition  == 'bad'">
                            Need major repairs
                          </span>
                          <span class="property-value"
                            *ngIf="projectDetails.surface_condition  == 'needHelpinMeasurment'">
                            Need help in assessing surface condition
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.room_no">
                        <div class="property-key">
                          No of rooms to be painted
                        </div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.room_no }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails.material">
                        <div class="property-key">Surface Type</div>
                        <div class="property-value" *ngIf="!projectDetails.matrial_desc.length">
                          {{ projectDetails.material }}
                        </div>
                        <div class="property-value" *ngIf="projectDetails.matrial_desc.length">
                          {{ projectDetails.matrial_desc[0].title | titlecase}},
                          {{ projectDetails.matrial_desc[0].decription | titlecase }}
                        </div>
                      </div>
                      <div class="property" *ngIf="
                          projectDetails?.other_thing_painter_to_do?.length
                        ">
                        <div class="property-key">
                          Other things painter to do
                        </div>
                        <!-- <div class="property-value">{{projectDetails.other_thing_painter_to_do}}</div> -->
                        <div class="property-value">
                          <span class="pntr_badge" *ngFor="
                              let item of projectDetails.other_thing_painter_to_do; let i =index;
                            ">
                                                      {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.other_thing_painter_to_do.length -1 ? '' : ',' }}

                          </span>
                        </div>
                      </div>
                      <div class="property" *ngIf="projectDetails.home_type">
                        <div class="property-key">Home type</div>
                        <div>
                          <span class="property-value">
                            {{ projectDetails.home_type | titlecase }}
                          </span>
                        </div>

                      </div>
                      <div class="property" *ngIf="projectDetails?.startDate">
                        <div class="property-key">Project start date-</div>
                        <div class="property-value">
                          {{ projectDetails.startDate | date }}
                        </div>
                      </div>
                      <div class="property" *ngIf="projectDetails?.endDate">
                        <div class="property-key">
                          Project expected to be completed by-
                        </div>
                        <div class="property-value">
                          {{ projectDetails.endDate | date }}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="div_innr_wpr" *ngIf="!projectDetails?.Bids">
                <div class="div_text_wrp">
                  <h1>Wait no more! Place a bid</h1>
                  <p>
                    Your bid will be sent to project owner. After placing a bid
                    it will be visible inside My bids section.
                  </p>
                </div>
                <form action="" class="form margin-b10">
                  <div class="input-wrapper bid_inpt">
                    <label><span class="label-text">Your Bid</span></label>
                    <div class="div_eml div_incs">
                      <input class="email" [ngModelOptions]="{ standalone: true }"
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        [(ngModel)]="bid_value" placeholder="Enter your bid offer" email />
                    </div>
                  </div>
                  <div class="input-wrapper bid_inpt">
                    <label><span class="label-text">Cover Letter</span></label>
                    <div class="div_eml div_incs">
                      <textarea class="email" [ngModelOptions]="{ standalone: true }"
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                        [(ngModel)]="cover_letter" placeholder="" rows="18" cols="33">
                      </textarea>
                    </div>
                  </div>
                </form>
                <div class="">
                  <p class="last_div">You will pay one credit for this bid</p>
                </div>
                <div class="div_btn_cret bid_send">
                  <!-- <button class="custom-button" (click)="sendbid()">Send Bid</button> -->
                  <button class="custom-button" (click)="sendbid()">
                    Send Bid
                  </button>
                </div>
              </div>
              <!-- <div class="div_innr_wpr" *ngIf="projectDetails.projectDetails.Bids">
                                <div class="div_text_wrp">
                                    <h1>Bid already created</h1>
                                    <p>${{projectDetails.projectDetails.Bids.bid}}</p>
                                </div>


                            </div> -->

              <div class="your-bid-wrapper new_rap" *ngIf="projectDetails?.Bids">
                <div class="div_flx_nw">
                  <div class="your-bid-text">
                    <h6>Your Bid</h6>
                  </div>
                  <div class="your-bid-status-wrapper bid-accepted new_accept" style="color: white">
                    Bid Submitted
                  </div>
                </div>
                <div class="modify_txt modifr_tcxt">
                  <form>
                    <div class="input-wrapper">
                      <div class="div_incs input-with-hint">
                        <div class="icon-wrapper icn_sld_pn">
                          <img class="icon" src="assets/icons/dollar.svg" />
                        </div>
                        <input placeholder="Your bid" [(ngModel)]="bidAmt" [ngModelOptions]="{ standalone: true }" />

                        <span class="hint">
                          <a class="hint-text"></a>
                        </span>
                      </div>
                    </div>
                  </form>
                  <!--<app-modal *ngIf="modalVisible" [displayModal]="modalVisible"
           [title]="'Modify offer'"
           [subclaim]="'Are you sure you want to change your offer to <b>' + (newOfferForm.controls.newPrice.value.replace('$', '').replace(',','').replace('.','') | currency: 'USD') + '</b>?\n Your current bid will be is going to be deleted and new bid will be placed instead.'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="emitValue()"
           (cancelButtonClicked)="toggleModalVisibility()"></app-modal>-->

                  <span>
                    <a (click)="bidmodify(projectDetails?.Bids?.id)">Modify</a>
                  </span>
                </div>
              </div>
            </div>
          </nb-tab>

          <nb-tab tabTitle="Rooms/Walls({{ projectDetails?.room?.length }})" class="room-tab">
            <div class="rooms painterSide_rooms">
              <div class="d-flex mrgn_tbs">
                <div class="div_inr_cost mrgn_ryt_txt" *ngFor="let item of projectDetails.room; let i = index">
                  <div class="room-card-wrapper" *ngIf="item?.rooms?.length">
                    <div class="div_imgs_room" *ngIf="item?.images">
                      <img class="bed_innr" [src]="item?.images[0]?.image" alt="" />
                    </div>
                    <div class="div_imgs_room" *ngIf="!item?.images">
                      <img class="bed_innr" [src]="projectDetails.images[0].image" alt="" />
                    </div>
                    <div class="info tym_info">
                      <div class="room-type">
                        {{ item.sel_rrom[0] | titlecase }}
                      </div>
                      <div class="undr_lne_text vw_detls" (click)="openmodal(item)">
                        View Details
                      </div>
                    </div>
                  </div>
                  <div class="room-card-wrapper" *ngIf="item?.walls?.length && !item?.rooms?.length">
                    <div class="">
                      <img class="bed_innr" style="width: 130px" src="/assets/project-creator/walls.png" alt="" />
                    </div>
                    <div class="info tym_info">
                      <div class="room-type">Wall {{ i + 1 }}</div>
                      <div class="undr_lne_text vw_detls" (click)="openmodalwall(item)">
                        View Details
                      </div>
                    </div>
                  </div>
                </div>
                <!--  <div class="div_inr_cost">
                                    <div class="room-card-wrapper">
                                        <div class="div_imgs_room">
                                            <img class="bed_innr" src="assets/Rectangle.png" alt="">
                                        </div>
                                        <div class="info tym_info">
                                            <div class="room-type">Bedroom</div>
                                            <div class="undr_lne_text vw_detls" (click)="modalFlag1=true;">View Details</div>
                                        </div>
                                    </div>
                                </div>-->
              </div>
            </div>
          </nb-tab>
          <!-- <nb-tab tabTitle="Rooms" [active]="isMainActive" class="room-tab" *ngIf="project.projectCreatorType == EPostingNewProjectWay.ALGORITHM_WAY">
                <div class="rooms">
                <app-room-card-details *ngFor="let room of project.rooms" [room]="room" [pricesVisible]="roles.customer">
                </app-room-card-details>
              </div>
            </nb-tab> -->
        </nb-tabset>
      </nb-card-body>
    </nb-card>

    <app-modal class="project_report_mdl" [displayModal]="modalFlag" (cancelButtonClicked)="closeComplainModal()">
      <slot-content>
        <div class="bid_detail_modal">
          <div class="bid-card-wrapper">
            <p class="title exchng_clr">Report Project</p>
            <p>Select Reason for reporting this painter</p>
            <div class="select_room_no">
              <!--  <app-select-input [label]="orderOptions[0].optionLabel"
                              [inputOptions]="orderOptions"
                            
                              class="select-input">
                            </app-select-input>-->
              <select (change)="click($event)" [(ngModel)]="reason" class="report-select select-input">
                <option *ngFor="let item of reporting_reason" [value]="item.reason">
                  {{ item.reason }}
                </option>
              </select>
            </div>
            <div class="d-flex dbl_btns">
              <button class="custom-button" (click)="sendreport()">
                Send Report
              </button>
            </div>
          </div>
        </div>
      </slot-content>
    </app-modal>

    <app-modal class="rooms_detail_modal painterSide mydtls" [displayModal]="modalFlag1"
      (cancelButtonClicked)="closeComplainModal()" *ngIf="roomdetails">
      <slot-content>
        <div class="div_inr_cost mrgn_ryt_txt">
          <div class="room-card-wrapper flex_wrap_class">
            <div class="div_imgs_room" *ngIf="roomdetails?.images">
              <img class="bed_innr" [src]="roomdetails?.images[0]?.image" alt="" />
            </div>
            <div class="div_imgs_room" *ngIf="!roomdetails?.images">
              <img class="bed_innr" [src]="projectDetails?.images[0]?.image" alt="" />
            </div>
            <div class="info tym_info">
              <div class="room-type">
                {{ roomdetails?.sel_rrom[0] | titlecase }}
              </div>
            </div>
          </div>
        </div>
        <div class="view-all-imgs">
          <div class="slider-rooms">
            <div class="room_all_images">
              <div class="slider_imgs" *ngIf="imageobjectarray.length">
                <ng-image-slider #nav [images]="imageobjectarray" [infinite]="false" [autoSlide]="1"
                  class="slider_img_size" slideImage="1">
                </ng-image-slider>
                <!-- [imageSize]="{width: '25%', height: '130px'}" -->
              </div>
            </div>
          </div>
        </div>
        

        <div class="room_dtl_sec row">
          <div class="col-4" *ngIf="roomdetails.rooms.length">
            <p class="cover-letter">Areas to be painted</p>
            <p *ngFor="let item of roomdetails.rooms; let i =index;">
            {{ (item | titlecase)}} {{i === roomdetails.rooms.length -1 ? '' : ',' }}
              
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.doors">
            <p class="cover-letter">No of doors</p>
            <p>
              {{ roomdetails.doors }}
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.no_wondows">
            <p class="cover-letter">No of windows</p>
            <p>
              {{ roomdetails.no_wondows }}
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.moving_furniture_exterior == 'no'">
            <p class="cover-letter">Moving furniture</p>
            <p>
              No
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.moving_furniture_exterior == 'multiple_pieces'">
            <p class="cover-letter">Moving furniture</p>
            <p>
              Multiple pieces
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.moving_furniture_exterior == 'couple_pieces'">
            <p class="cover-letter">Moving furniture</p>
            <p>
              Couple of pieces
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.closet">
            <p class="cover-letter">Closet</p>
            <p>
              {{ roomdetails.closet | titlecase }}
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.crown">
            <p class="cover-letter">Crown molding</p>
            <p>
              {{ roomdetails.crown | titlecase }}
            </p>
          </div>
          <div class="col-4" *ngIf="roomdetails.base">
            <p class="cover-letter">Base molding</p>
            <p>
              {{ roomdetails.base | titlecase }}
            </p>
          </div>
        </div>
        <div class="room_dtl_sec row">

          <!--<div class="col-4">
                        <p class="cover-letter"> Color/Finish</p>
                        <p>
                            Decorative painting
                            Faux finish
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="cover-letter">Wallpaper</p>
                        <p>
                            Yes
                        </p>
                    </div>-->
          <div class="col-4">
            <p class="cover-letter">Surface Condition</p>
            <p *ngIf="roomdetails?.surface_condition[0]  == 'good'">
              No repairs needed
            </p>
            <p *ngIf="roomdetails?.surface_condition[0]  == 'bad'">
              Need major repairs
            </p>
            <p *ngIf="roomdetails?.surface_condition[0]  == 'fair'">
              Need some repairs
            </p>
            <p *ngIf="roomdetails?.surface_condition[0]  == 'needHelpinMeasurment'">
              Need help in assessing surface condition
            </p>
          </div>
        <!-- </div>
        <div class="room_dtl_sec row"> -->
          <div class="col-4">
            <p class="cover-letter">Work supply</p>
            <p>
              {{ projectDetails.providing_items | removeUnderscore }}
            </p>
          </div>
          <div class="col-8" *ngIf="projectDetails?.other_thing_painter_to_do?.length">
            <p class="cover-letter">Additional</p>
            <p *ngFor="let item of projectDetails.other_thing_painter_to_do; let i= index">
           
               {{ (item.replace('_','')  | titlecase)}} {{i === projectDetails.other_thing_painter_to_do.length -1 ? '' : ',' }}
            </p>
          </div>
        </div>
        <div class="old_color_warp" *ngIf="roomdetails?.length">
          <p class="cover-letter"> Ceiling Details</p>
          <div class="d-flex">
            <p>
              (Length-{{ roomdetails?.length }} x Width-{{ roomdetails?.width}})
            </p>
          </div>
        </div>

        <div class="old_color_warp" *ngIf="roomdetails?.ceilingPreviousColor">
          <p class="cover-letter">Old Ceiling Color</p>
          <div class="d-flex">
            <div class="color_clr" style="background-color: {{ roomdetails.ceilingPreviousColor }};"></div>

          </div>
        </div>
        <div class="old_color_warp" *ngIf="roomdetails?.ceilingnewColor">
          <p class="cover-letter">New Ceiling Color</p>
          <div class="d-flex">
            <div class="color_clr" style="background-color: {{ roomdetails.ceilingnewColor }};"></div>

          </div>
        </div>
        <div *ngFor="let item of roomdetails?.walls; let i = index">
          <div *ngIf="i<4">
            <div class="wall_dtl" *ngIf="item.wallHeight">
              <div class="d-flex">
                <div class="room-type">Wall {{ i + 1 }}</div>
                <p>
                  (Height-{{ item.wallHeight }} x Length-{{ item.wallWidth }})
                </p>
              </div>
            </div>
            <div *ngIf="item.wallHeight && item.wallWidth">
              <div class="old_color_warp">
                <p class="cover-letter">Old Color</p>
                <div class="d-flex">
                  <div class="color_clr" style="background-color: {{ item.previousColor }}"></div>
                  <div class="btn_tgs">
                    <button class="Otline_grey grey" *ngIf="item.previousWallpaper">
                      Has wallpaper
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.previousDecorative">
                      Decorative painting
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.previousFaux">
                      Faux Finish
                    </button>
                  </div>
                </div>
              </div>
              <div class="old_color_warp">
                <p class="cover-letter">New Color</p>
                <div class="d-flex">
                  <div class="color_clr sec_color" *ngIf="item.newColor" style="background-color: {{ item?.newColor }}">
                  </div>
                  <div class="btn_tgs">
                    <button class="Otline_grey grey" *ngIf="item.newWallpaper">
                      Has Wallpaper
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.newDecorative">
                      Decorative painting
                    </button>
                    <button class="Otline_grey grey" *ngIf="item.newFaux">
                      Faux Finish
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--   <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 2</div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>

                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 3 </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>

                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 4 </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Ceiling </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="ceiling_wrap">
                    <div class="old_color_warp">
                        <p class="cover-letter"> Old Color</p>
                        <div class="color_clr"></div>
                    </div>
                    <div class="old_color_warp">
                        <p class="cover-letter"> New Color</p>
                        <div class="color_clr sec_color"></div>
                    </div>
                </div>-->
      </slot-content>
    </app-modal>
    <app-modal class="rooms_detail_modal painterSide mydtls" [displayModal]="modalFlag12"
      (cancelButtonClicked)="closeComplainModal()" *ngIf="walldetails">
      <slot-content>
        <div class="div_inr_cost mrgn_ryt_txt">
          <div class="room-card-wrapper"></div>
        </div>

        <!--   <div class="room_dtl_sec row">
                    <div class="col-4">
                        <p class="cover-letter"> Areas to be painted </p>
                        <p *ngFor="let item of roomdetails.rooms">
                           {{item| titlecase}} 
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="cover-letter"> No of doors </p>
                        <p>
                          {{roomdetails.doors}}
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="cover-letter"> No of windows </p>
                        <p>
                           {{roomdetails.no_wondows}}
                        </p>
                    </div>
                </div>
              <div class="room_dtl_sec row">
                    <div class="col-4">
                        <p class="cover-letter"> Color/Finish</p>
                        <p>
                            Decorative painting
                            Faux finish
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="cover-letter">Wallpaper</p>
                        <p>
                            Yes
                        </p>
                    </div>
                    <div class="col-4">
                        <p class="cover-letter"> Surface Condition </p>
                        <p>
                            Fair
                        </p>
                    </div>
                </div>
                <div class="room_dtl_sec row">
                    <div class="col-4">
                        <p class="cover-letter"> Work supply</p>
                        <p>
                            Will Provide - Paint and painting supplies
                        </p>
                    </div>
                    <div class="col-8">
                        <p class="cover-letter">Additional</p>
                        <p>
                            Cleaning windows, Disposing of the debris, Moving furniture
                        </p>
                    </div>
                </div>-->
        <div *ngFor="let item of walldetails?.walls; let i = index"> 
          <div *ngIf="i<4">
            <div class="wall_dtl" *ngIf="item.wallHeight && item.wallWidth">
              <div class="d-flex">
                <div class="room-type">Wall {{ i + 1 }}</div>
                <p>
                  (Height-{{ item.wallHeight }} x Length-{{ item.wallWidth }})
                </p>
              </div>
            </div>
            <div *ngIf="item.wallHeight && item.wallWidth">
              <div class="old_color_warp" *ngIf="item.previousColor">
                <p class="cover-letter">Old Color</p>
                <div class="color_clr" style="background-color: {{ item.previousColor }}"></div>
              </div>
              <div class="old_color_warp" *ngIf="!item.previousColor">
                <p class="cover-letter">Old Color - N/A</p>
              </div>
              <div class="old_color_warp" *ngIf="item.newColor">
                <p class="cover-letter">New Color</p>
                <div class="color_clr" style="background-color: {{ item.newColor }}"></div>
              </div>
              <div class="old_color_warp" *ngIf="!item.newColor">
                <p class="cover-letter">New Color - N/A</p>
              </div>
            </div>
          </div>
        </div>
        <!--   <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 2</div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>

                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 3 </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>

                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Wall 4 </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> Old Color</p>
                    <div class="d-flex">
                        <div class="color_clr"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Has wallpaper
                            </button>
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="old_color_warp">
                    <p class="cover-letter"> New Color</p>
                    <div class="d-flex">
                        <div class="color_clr sec_color"></div>
                        <div class="btn_tgs">
                            <button class="Otline_grey grey">
                                Decorative painting
                            </button>
                            <button class="Otline_grey grey">
                                Faux Finish
                            </button>
                        </div>
                    </div>
                </div>
                <div class="wall_dtl">
                    <div class="d-flex">
                        <div class="room-type">Ceiling </div>
                        <p>
                            (124x234)
                        </p>
                    </div>
                </div>
                <div class="ceiling_wrap">
                    <div class="old_color_warp">
                        <p class="cover-letter"> Old Color</p>
                        <div class="color_clr"></div>
                    </div>
                    <div class="old_color_warp">
                        <p class="cover-letter"> New Color</p>
                        <div class="color_clr sec_color"></div>
                    </div>
                </div>-->
      </slot-content>
    </app-modal>
  </div>

  <!--<section class="main">
        <div class="main-info-wrapper project-in-progress">
            <nb-icon icon="clock"></nb-icon>

            <div class="main-info-text">Project in Progress
            </div>
            <button class="custom-button" (click)="openComplainModal()">Mark Complete</button>
        </div>

        <div class="main-info-wrapper project-in-progress">
            <nb-icon icon="clock"></nb-icon>
            <div class="main-info-text">Is there something wrong about
                this job or customer? Please let us know!
            </div>
            <div class="main-info-text">We are awaiting your payment,
                something gone wrong? Please let us know!
            </div>
            <button class="custom-button" (click)="openComplainModal()">Make a complain</button>
        </div>
        <div class="main-info-wrapper project-pending">
            <nb-icon icon="checkmark-circle-2"></nb-icon>
            <div class="main-info-text ">It seems that there is a complain related with this project</div>
            <button class="custom-button footer-button">Details</button>
        </div>
        <div class="main-info-wrapper project-completed">
            <nb-icon icon="checkmark-circle-2"></nb-icon>
            <div class="main-info-text">Project is completed</div>
        </div>
    </section>-->
</div>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlagbid"
  (cancelButtonClicked)="closeComplainModal()">
  <slot-content>
    <div class="add-credit-sec">
      <h4 class="text-center">Available Credits</h4>

      <div class="credit_img text-center">
        <img src="./assets/coin.svg" alt="" />
      </div>

      <div class="form-group input_wrapper">
        <input type="text" placeholder=" Credits" disabled [(ngModel)]="price" class="inputt" />
      </div>

      <div class="text-center mt-3">
        <button class="btn custom-button" (click)="done()">Submit Bid</button>
      </div>
    </div>
  </slot-content>
</app-modal>
<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlagbids"
  (cancelButtonClicked)="closeComplainModal()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/bid-modify.svg" alt="meeting" class="left-img" />
          </div>
          <div class="">
            <div class="painter-details">
              <div class="div_upr d-flex">
                <h3 class="text-center">
                  Your bid was accepted. You will be charged
                  <span>1 credit.</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <!-- <button class="custom-button" type="button" routerLink="/home/bid-payment">Pay Now</button> -->
          </div>
        </div>
      </div>
    </div>
  </slot-content>
</app-modal>
<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlagbidnew"
  (cancelButtonClicked)="closeComplainModal()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/bid-modify.svg" alt="meeting" class="left-img" />
          </div>
          <div class="">
            <div class="painter-details">
              <div class="div_upr">
                <h3 class="text-center">Bid created.</h3>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <button class="custom-button" type="button" (click)="donenew()">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </slot-content>
</app-modal>