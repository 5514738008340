import {ChangeDetectorRef, Component} from '@angular/core';
import {ServerDataSource} from 'ng2-smart-table';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BaseTableViewComponent} from '../../MAIN_COMPONENTS/BaseTableViewComponent';
import {TableActionsComponent} from '../../table-actions/table-actions.component';
import {LastDaysPickerComponent} from '../custom-filters/last-days-picker/last-days-picker.component';
import {EnumPipe} from '../../../pipes/enum.pipe';
import {EReportStatus} from '../../../model/enums/EReportStatus';
import {ReportStatusPickerComponent} from '../custom-filters/report-status-picker/report-status-picker.component';
import {ERole} from '../../../model/enums/ERole';
import {Router} from '@angular/router';
import {AdminService} from '../../../services/admin.service';
import {EProjectStatus} from '../../../model/enums/EProjectStatus';
import {ProjectStatusPickerComponent} from '../custom-filters/project-status-picker/project-status-picker.component';
import {EBidStatus} from '../../../model/enums/EBidStatus';
import {BidStatusPickerComponent} from '../custom-filters/bid-status-picker/bid-status-picker.component';
import {EPaymentStatus} from '../../../model/enums/EPaymentStatus';
import {CurrencyPipe} from '@angular/common';

@Component({
  selector: 'app-brush-table-bids',
  templateUrl: './bids-table.component.html',
  styleUrls: ['./bids-table.component.scss'],
  providers: [EnumPipe, CurrencyPipe]
})
export class BidsTableComponent extends BaseTableViewComponent{
  bidDetailsCoverLetter!: string | null;


  constructor(private currency: CurrencyPipe, private adminService: AdminService, private router: Router, private enumPipe: EnumPipe, private httpClient: HttpClient, private cdRef: ChangeDetectorRef) {
    super();
    this.source = new ServerDataSource(httpClient, {
      endPoint: environment.SERVER_BASE_URL + 'admin/bids',
      dataKey: 'content',
      totalKey: 'totalElements',
      pagerLimitKey: 'pageSize',
      pagerPageKey: 'page',
      sortFieldKey: 'sort',
      sortDirKey: 'sortDir',
      filterFieldKey: 'search_#field#'
    });
    this.source.onChanged().subscribe(change => {
      this.totalElements = this.source.count();
      this.cdRef.detectChanges();
    });
  }

  settings = {
    rowClassFunction: (row: any) => {
      if ((row.data.status === EBidStatus.DELETED) || (row.data.status === EBidStatus.EXPIRED)) {
        return 'neutral';
      }
      else if ((row.data.status === EBidStatus.NEW) || (row.data.status === EBidStatus.PENDING)) {
          return 'warning';
      } else if (row.data.status === EBidStatus.DENIED) {
        return 'danger';
      } else if (row.data.status === EBidStatus.ACCEPTED) {
        return 'success';
      }
      return '';
    },
    mode: 'external',
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize
    },
    noDataMessage: this.noDataMessage,
    columns: {
      actions: {
        title: 'Actions',
        sort: false,
        filter: false,

        type: 'custom',
        renderComponent: TableActionsComponent,
        onComponentInitFunction: (rc: any) => {
          this.totalElements = this.source.count();
          this.cdRef.detectChanges();
          rc.addAction(
            {icon: 'eye', pack: 'fas', tip: 'View Cover Letter'}).subscribe((x: any) => {
            this.bidDetailsCoverLetter = rc.rowData.comment || '-';
          });
      },
        width: '15vw'
      },
      status: {
        title: 'Bid Status',
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.status, EBidStatus),
        filter: {
          type: 'custom',
          component: BidStatusPickerComponent,
        }
      },
      price: {
        title: 'Price',
        filter: false,
        valuePrepareFunction: (c: any, x: any) =>  this.currency.transform(x.price)
      },
      TAG: {
        title: 'Bid tag',
        valuePrepareFunction: (c: any, x: any) =>  x.tag
      },
      projectTAG: {
        title: 'Project tag',
        sort: false,
      },
      painterID: {
        title: 'Painter ID',
        sort: false,
      },
      paymentStatus: {
        title: 'Payment',
        sort: false,
        filter: false,
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.paymentStatus, EPaymentStatus),
      },
      dateTime: {
        title: 'Posted date',
        filter: {
          type: 'custom',
          component: LastDaysPickerComponent,
        }
      },
      statusTime: {
        title: 'Status time',
        filter: {
          type: 'custom',
          component: LastDaysPickerComponent,
        }
      },
    }
  };
}

