<div *ngIf="bid" [ngClass]="checkCardStatus()">
  <div class="status-new" *ngIf="bid.status === EBidStatus.NEW">NEW</div>
  <section class="bid-card-header">
    <div class="bid-details-res">
      <div class="bid-amount-text">Bid Amount</div>
      <div class="bid-amount">{{bid.price | currency:'USD'}}</div>
    </div>
    <div class="avatar">
      <img src="{{bid.userPainter.profileImage | defaultAvatar}}">
    </div>
    <div class="info">
      <div class="painter-details" (click)="visitPainterProfile()">
        <div class="main-info">{{bid.userPainter.fullName}}</div>
        <div class="sub-info"><img src="assets/icons/star.png" class="logo" alt="star">
          <span class="rating"> {{bid.userPainter.avgRating}}</span>
          <span class="no-of-reviews">({{bid.userPainter.noOfReviews}} Reviews)</span></div>
      </div>
      <div class="bid-details">
        <div class="bid-amount-text">Bid Amount</div>
        <div class="bid-amount">{{bid.price | currency:'USD'}}</div>
      </div>
    </div>
  </section>
  <section class="content">
  <div class="cover-letter">Cover Letter</div>
  <div [ngClass]="checkDetailedView()" ><pre>{{bid.comment}}</pre></div>
    <button class="custom-button" (click)="openDetailsModal()" *ngIf="!detailedView">Details</button>
  </section>
</div>
<app-modal [displayModal]="displayDetailsModalVisible"
           [title]="''"
           [subclaim]="''"
           [cancelButtonText]="'Decline'"
           (confirmButtonClicked)="openAcceptBidModal()"
           (modalClose)="displayDetailsModalVisible = false"
           [withoutActions]="checkPendingOrNewStatus()"
           (cancelButtonClicked)="openDenyBidModal()">
  <slot-content *ngIf="displayDetailsModalVisible">
    <app-bid-card-mini [bid]="bid" [detailedView]="true" [project]="project"></app-bid-card-mini>
  </slot-content>
</app-modal>
<app-modal [displayModal]="acceptBidModalVisible"
           [title]="'Accept bid'"
           [subclaim]="'Are you sure you want to accept this bid? After you confirm your project will dissapear from marketplace and all other bids will be automatically denied.'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="acceptBid()"
           (cancelButtonClicked)="hideAcceptBidModal()">
</app-modal>


<app-modal [displayModal]="denyBidModalVisible"
           [title]="'Deny bid'"
           [subclaim]="'After you deny this bid you cant revert it. Painter will be informed about your decision.'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="denyBid()"
           (cancelButtonClicked)="hideDenyBidModal()"></app-modal>
