<nb-card class="nb-theme-brush">
  <nb-card-header>Hr by state</nb-card-header>
  <nb-card-body>
    <ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>
  </nb-card-body>
</nb-card>

<app-modal [displayModal]="changePropertyModalVisible"
           [title]="'Change value for ' + selectedProperty?.areaTitle"
           [inputType]="'number'"
           [subclaim]="'Enter new value'"
           [inputPlaceHolder]="selectedProperty?.hmean"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="changeProperty($event)"
           (cancelButtonClicked)="hideModal()"></app-modal>
