<div class="smooth-scroll">
    <app-header [userTheme]="true"></app-header>
  
   <section class="terms_sec" style="background-image: url({{data.img}})">
    <div class="overlay_sec">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <!-- <h1>
                        Privacy policy
                    </h1> -->
                    <!-- <p>
                        Home / Privacy policy
                    </p> -->
                </div>
            </div>
        </div>
    </div>
   </section>
   <section>
        <div class="terms_content">
            <h6>
                {{data.heading}}
            </h6>
            <p>
              {{data.description1}}
            </p>
             <p [innerHTML]="data.description2">
            
            </p>
           <!-- <div class="listing">
                <ul>
                    <li>
                        Quis tempor se ipsum quem et incurreret
                    </li>
                    <li>
                        Ipsum quem et incurreret
                    </li>
                    <li>
                        Quis tempor se ipsum quem et incurreret
                    </li>
                    <li>
                        Quis tempor
                    </li>
                    <li>
                        Ipsum quem et incurreret
                    </li>
                    <li>
                        Quis tempor se ipsum quem et incurreret
                    </li>
                </ul>
            </div>
            <h6>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h6>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </p>
            <h6>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h6>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
                .Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </p>
            <h6>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h6>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
                .Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </p>
        -->
        </div>
   </section>
    <app-footer></app-footer>
  </div>
  