import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-nothing-to-display',
  templateUrl: './nothing-to-display.component.html',
  styleUrls: ['./nothing-to-display.component.scss']
})
export class NothingToDisplayComponent implements OnInit {

  @Input() claim: string | undefined;
  @Input() img: string | undefined;
  @Input() subclaim: string | undefined;
  @Input() buttonText: string | undefined;

  @Output() buttonClicked = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  click(): void {
    this.buttonClicked.emit();
  }

}
