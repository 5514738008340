import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentService } from '../../../../services/comment.service';
import { TokenStorageService } from '../../../../services/token-storage.service';
import { PainterService } from '../../../../services/painter.service';
import { PageableComponent } from '../../../../components/MAIN_COMPONENTS/PageableComponent';
import { SelectInputOption } from '../../../../model/components/SelectInputOption';
import { EProjectStatus } from '../../../../model/enums/EProjectStatus';
import { ProjectService } from '../../../../services/project.service';
import { Observable } from 'rxjs';
import { HttpserviceService } from '../../../../services/httpservice.service';
import * as moment from 'moment';


@Component({
  selector: 'app-my-jobs',
  templateUrl: './my-jobs.component.html',
  styleUrls: ['./my-jobs.component.scss']
})
export class MyJobsComponent extends PageableComponent implements OnInit {
  projectAwaitingPageableResponse!: any;
  jobs: any=[]
  limit: any=100
  completedjob: any=[]
  disputed: any=[]
  offset: any=0
       selectedtab: any

  archived: any=0
  archivedlist: any[]=[];
  projectInProgressPageableResponse!: any;
  projectCompletedPageableResponse!: any;
  activeStatus = EProjectStatus.AWAITING;
  orderOptions: SelectInputOption[] = [
    {
      optionID: 1,
      optionLabel: 'Sort By: New',
      optionValue: 'projectDetails.postedDate',
      sortDir: 'DESC',
      optionDisabled: false,
      optionSelected: true
    },
    {
      optionID: 2,
      optionLabel: 'Sort By: Old',
      optionValue: 'projectDetails.postedDate',
      sortDir: 'ASC',
      optionDisabled: false,
      optionSelected: false
    },
  ];
  modalFlag: boolean = false;

  constructor(
    public tokenStorage: TokenStorageService,
     private authService: HttpserviceService,
    private router: Router,
    private commentService: CommentService,
    private activatedRoute: ActivatedRoute,
    private painterService: PainterService,
    private projectService: ProjectService) {
    super();
  }

  ngOnInit(): void {
  if(localStorage.getItem('currenttab')){
                this.selectedtab = localStorage.getItem('currenttab')
                console.log(this.selectedtab)
                     this.updateRoute({
          step: this.selectedtab,
         
        })
                }else{
                    this.selectedtab ='one'
                }
  this.getJobs()
  this.completedjobs()
    this.getProjects(EProjectStatus.IN_PROGRESS).subscribe(res => {
      this.projectInProgressPageableResponse = res;
      this.pageableResponse = res;
    });
    this.getProjects(EProjectStatus.COMPLETED).subscribe(res => {
      this.projectCompletedPageableResponse = res;
    });
    this.getProjects(EProjectStatus.AWAITING).subscribe(res => {
      this.projectAwaitingPageableResponse = res;
    });
  }

    updateRoute(queryParams: any) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge'
      }
    );
  }
   yourFunc(e: any){
 
          this.selectedtab=e
         this.updateRoute({
          step: this.selectedtab,
         })
       localStorage.setItem('currenttab',this.selectedtab)
  //     this.router.navigate(['/home/project-detail/'+ id])
       
  }

  filter(startFromFirstPage: boolean): void {
    if (startFromFirstPage) {
      this.page = 0;
    }
    this.getProjects(this.activeStatus).subscribe(response => {
      switch (this.activeStatus) {
        case EProjectStatus.IN_PROGRESS:
          this.projectInProgressPageableResponse = response;
          break;
        case EProjectStatus.COMPLETED:
          this.projectCompletedPageableResponse = response;
          break;
        case EProjectStatus.AWAITING:
          this.projectAwaitingPageableResponse = response;
          break;
      }
    }, error => {
      console.log(error);
    });
    this.projectService.getAllProjectForPainter(this.page, this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue, this.selectedOrdering.sortDir, EProjectStatus[this.activeStatus]
    ).subscribe(res => {
      this.pageableResponse = res;
    });
  }

   getJobs() {
let obj={
        'limit': this.limit,
        'offset': this.offset,
      //  'status': 'approved'
  }
		 this.authService.getjobs(obj).subscribe((response : any) => {
         this.jobs= response.projects

          this.jobs.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
        
        console.log('rem days', val.days_remaining);
        	

				
      });
        
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }

    completedjobs() {
let obj={
        'limit': this.limit,
        'offset': this.offset,
      //  'status': 'approved'
  }
  let param ='status=completed'
		 this.authService.getcompletedlist(param).subscribe((response : any) => {
         this.completedjob= response.projects

          this.completedjob.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
        
        console.log('rem days', val.days_remaining);
        	

				
      });
        
        //   this.toastr.success('Password changed!', 'Success!');

      })
       let param2 ='status=disputed'
		 this.authService.getcompletedlist(param2).subscribe((response : any) => {
         this.disputed= response.projects

          this.disputed.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
        
        console.log('rem days', val.days_remaining);
        	

				
      });
        
        //   this.toastr.success('Password changed!', 'Success!');

      })
        let param3 ='status=archived'
		 this.authService.getcompletedlist(param3).subscribe((response : any) => {
         this.archivedlist= response.projects

          this.archivedlist.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));
        
        console.log('rem days', val.days_remaining);
        	

				
      });
        
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }

  changeOrdering(event: any): void {
    this.orderOptions.forEach(item => {
      if (item.optionLabel === event.optionLabel) {
        item.optionSelected = true;
      } else {
        item.optionSelected = false;
      }
    });
    this.filter(false);
  }
  onChangeTab(event: any): void {
    const tabTitle = event.tabTitle.toLowerCase();
    if (tabTitle.includes('in progress')) {
      this.activeStatus = EProjectStatus.IN_PROGRESS;
    } else if (tabTitle.includes('completed')) {
      this.activeStatus = EProjectStatus.COMPLETED;
    } else if (tabTitle.includes('awaiting')) {
      this.activeStatus = EProjectStatus.AWAITING;
    }
    this.filter(true);
  }
  viewDetails(project: any): void {
    this.router.navigate(['/home/my-jobs/details/' + project.bid.tag]);
  }
  get selectedOrdering(): any {
    return this.orderOptions.filter(pt => pt.optionSelected === true)[0];
  }
  getProjects(status: EProjectStatus): Observable<any> {
    return this.projectService.getAllProjectForPainter(this.page, this.selectedPageSize.optionValue,
      this.selectedOrdering.optionValue, this.selectedOrdering.sortDir, EProjectStatus[status]);
  }

  viewDemoDetailss(id: any) {
    this.router.navigate(['/home/my-jobs/details/'+id]);
  }
   viewDemoDetailsscomp(id: any, type:any) {
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-jobs/completed-details/'+id]);
  }
  viewDemoDetails() {
    this.router.navigate(['/home/my-jobs/details/']);
  }
  makeComplain() { }
  openAcceptBidModal() { }
  checkPendingOrNewStatus(): boolean { return true }
  openDenyBidModal() { }
  closeComplainModal() {
    this.modalFlag = false
  }
}
