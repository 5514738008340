import {Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {HttpserviceService} from '../../services/httpservice.service';
import {Router} from '@angular/router';
    import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
passwordform: FormGroup
 error: any
  isFormSubmitted = false;
   pass: String = 'password'
  confirmPass: String = 'password'
  Cpass: String = 'password';
  constructor(private router: Router, private formBuilder: FormBuilder, private authService: HttpserviceService, private toastr: ToastrService) {
   // super();
    this.passwordform = this.formBuilder.group({
    currPswd: ['', [Validators.required]],
    newPswd : ['', [Validators.required]],
          conPswd: ['', [Validators.required]],

  }, {
      validator: this.checkPassword,
    });
  }

  ngOnInit(): void {
  }

   checkPassword(group: FormGroup) {
    let pass = group.controls.newPswd.value;
    let confirm_password = group.controls.conPswd.value;
    var flag = false
    let returnable: any = {
      pwdNotSame: null,
    }
    if (pass != confirm_password) {
      returnable.pwdNotSame = true
      flag = true
    }
    return flag ? returnable : null;
  }
  submitForm(): void {
    this.isFormSubmitted = true;
    let obj={
        old_password: this.passwordform.controls.currPswd.value,
        new_password: this.passwordform.controls.newPswd.value,
          //      conPswd: this.passwordform.controls.conPswd.value,

    }
    const email = this.passwordform.controls.currPswd.value;
    this.authService.resetpass(obj).subscribe((response : any) => {
           this.toastr.success('Password changed!', 'Success!');

      },
      (error : any) => {
      this.error= error.error.errors.msg
        console.log(error.error.errors.msg);
      }
    );
  }
   onEyeClick(field: any, type: any) {
    if (field == 'pass') {
      this.pass = type
    }
    else if (field == 'Cpass') {
      this.Cpass = type
    } else {
      this.confirmPass = type
    }
  }
  goBackToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  onEnterClicked(): void {
    if (this.passwordform.valid) {
      this.submitForm();
    }
  }
}
