<div class="main-box welcm_bck">
    <img src="assets/logo.png" class="error-icon">
    <p class="title exchng_clr">Your Location</p>
    <form class="form margin-b10 register_loc" [formGroup]="userDataForm">
        <div class="input-wrapper paragraph_text margin-b15">
            <p *ngIf="!isPainterRegistration">Please provide us with your location. It will be needed while you use Brush Exchange – painters will look
                for your project by where it’s located.</p>
            <p *ngIf="isPainterRegistration">Please provide us with your location. It will be needed while you use Brush Exchange – customers will
                post their projects by where it’s located</p>
        </div>
        <div class="div_logn input-wrapper">
            <input type="text" class="password"
                oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                formControlName="full_address" placeholder="Street Address">
        </div>
        <!--  <div class="div_logn input-wrapper">

   <!--	<input type="text" name="" class="form-control" placeholder="Location" formControlName="location" 
                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                    ngx-google-places-autocomplete  #placesRef="ngx-places"
                    (onAddressChange)="handleAddressChange($event)" (input)="clearLocation()" >
                    <input type="hidden" formControlName="latitude">
                        <input type="hidden" formControlName="longitude">
  <!--<input ngx-google-places-autocomplete (onAddressChange)="handleAddressChange($event)" />

           <!-- <input  type="text" class="password" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" formControlName="state" placeholder="State">
        

          

        </div>-->
        <div class="div_logn input-wrapper">

            <input type="text" class="password"
                oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" (keyup)="checkzip($event)"
                formControlName="zip" maxLength="9"
                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="ZIP">
            <span *ngIf="error" style="
    color: red;
    font-size: 11px;
">{{error}}</span>


            <!-- <input  type="text" class="password" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" formControlName="state" placeholder="State">-->
            <br>



        </div>
        <div class="div_logn input-wrapper">
            <input type="text" class="password"
                oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" formControlName="city"
                placeholder="City" readonly>

            <br>
            <br>


            <input type="text" class="password"
                oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" formControlName="state"
                placeholder="State" readonly>



        </div>
        <div>

            <div class="div_logn input-wrapper">


                <!-- <input  type="text" class="password" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" formControlName="city" placeholder="City">-->

            </div>
            <div class="div_logn input-wrapper">

            </div>
        </div>


    </form>
    <div class="div_btn_lgn get_strt_btn">
        <button class="custom-button" (click)="submitRegisterForm()" [disabled]="!userDataForm.valid">Submit
        </button>
    </div>
</div>