import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  inputVal: any;

  @Input() displayModal: boolean | undefined = false;
  @Input() displayModal1: boolean | undefined = false;
  @Input() title: string | undefined = 'Default title';
  @Input() subclaim: string | null = 'Default subtitle';
  @Input() confirmButtonText: string | undefined = 'Confirm';
  @Input() cancelButtonText!: any;
  @Input() alertModal: boolean | undefined = false;
  @Input() confirmButtonDisabled: boolean | undefined = false;
  @Input() inputType!: string;
  @Input() inputPlaceHolder!: string;
  @Input() withoutActions!: boolean;

  @Output() confirmButtonClicked = new EventEmitter();
  @Output() cancelButtonClicked = new EventEmitter();
  @Output() modalClose = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  confirmClicked(event: any): void {
    event.stopPropagation();
    if (this.inputVal != null) {
      this.confirmButtonClicked.emit(this.inputVal);
    } else {
      this.confirmButtonClicked.emit();
    }
  }

  inputChange(event: any): void {
    this.inputVal = event.target.value;
  }

  cancelClicked(event: any): void {
    event.stopPropagation();
    this.cancelButtonClicked.emit();
  }
  closeModal(): void {
    this.modalClose.emit();
  }

}
