import { APP_INITIALIZER, Injector, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { Location, LOCATION_INITIALIZED } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './views/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './views/login/login.component';
import { HomeComponent } from './views/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AuthGuardService } from './services/auth-guard-service';
import { AdminGuard } from './services/adminGuard';
import { MatPaginatorModule } from '@angular/material/paginator';

import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { EmailConfirmedComponent } from './views/register/email-confirmed/email-confirmed.component';
import { PasswordResetComponent } from './views/login/password-reset/password-reset.component';
import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { UserDetailsComponent } from './components/navigation-others/user-details/user-details.component';
import { UserPersonalService } from './services/user-personal.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
//import { AngularFireModule } from '@angular/fire/compat';
import * as firebase from 'firebase/app';
import { NgxMaterialRatingModule } from 'ngx-material-rating';

import { ForgotPasswordComponent } from './views/login/forgot-password/forgot-password.component';
import { SplashBgComponent } from './views/splash-bg/splash-bg.component';
import { ConfirmRegistrationComponent } from './views/register/confirm-registration/confirm-registration.component';
import { FooterComponent } from './components/navigation-others/footer/footer.component';
import { BecomePainterComponent } from './views/become-painter/become-painter.component';
import { SideNavbarComponent } from './components/navigation-others/side-navbar/side-navbar.component';
import { NothingToDisplayComponent } from './components/navigation-others/nothing-to-display/nothing-to-display.component';
import { ModalComponent } from './components/navigation-others/modal/modal.component';
import { MyProjectsComponent } from './views/home/home-nav-routes/my-projects/my-projects.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { TopPanelComponent } from './components/navigation-others/top-panel/top-panel.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ServiceCardComponent } from './components/service-card/service-card.component';
import { RoutingConfirmDialogServiceReference } from './services/dirty-guard/routing-confirm-dialog-service-reference';
import { DirtyGuardService } from './services/dirty-guard/dirty-guard-service';
import { RoutingConfirmDialogService } from './services/dirty-guard/routing-confirm-dialog-service';
import { RoutingConfirmationDialogComponent } from './components/navigation-others/routing-confirm-dialog/routing-confirm-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageUploadComponent } from './components/input/image-upload/image-upload.component';
import { BrushSpinnerComponent } from './components/spinner/brush-spinner.component';
import { RoomCardComponent } from './components/projects/room-card/room-card.component';
import { MarketplaceComponent } from './views/home/home-nav-routes/marketplace/marketplace.component';
import { PaginationComponent } from './components/navigation-others/pagination/pagination.component';
//import { MaterialModule } from './material.module';

import { MyBidsComponent } from './views/home/home-nav-routes/my-bids/my-bids.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { BidCardMiniComponent } from './components/bids/bid-card-mini/bid-card-mini.component';
import { ProjectCardComponent } from './components/projects/project-card/project-card.component';
import { OneOfManyOptionsInputComponent } from './components/input/one-of-many-options-input/one-of-many-options-input.component';
import {
    NbAccordionModule,
    NbCardModule,
    NbChatModule,
    NbDatepickerComponent,
    NbDatepickerDirective, NbDatepickerModule,
    NbIconModule,
    NbLayoutModule,
    NbMenuModule,
    NbSelectModule, NbSpinnerModule,
    NbTabsetModule,
    NbCheckboxModule,
    NbThemeModule,
    NbStepperModule,
    NbContextMenuModule
} from '@nebular/theme';
import { ProjectMainDetailsComponent } from './components/projects/project-main-details/project-main-details.component';
import { NotificationService } from './services/notification-service';

import { BidDetailsComponent } from './views/home/home-nav-routes/my-bids/bid-details/bid-details.component';
import { PainterProfileComponent } from './views/home/home-nav-routes/painter-profile/painter-profile.component';
import { AdminDashboardComponent } from './views/home/home-nav-routes/admin-dashboard/admin-dashboard.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { TableActionsComponent } from './components/table-actions/table-actions.component';
import { RolePickerComponent } from './components/tables/custom-filters/role-picker-component/role-picker.component';
import { ProjectsTableComponent } from './components/tables/projects/projects-table.component';
import { UsersTableComponent } from './components/tables/users/users-table.component';
import { ProjectTypePickerComponent } from './components/tables/custom-filters/project-type-picker/project-type-picker.component';
import { StatePickerComponent } from './components/tables/custom-filters/state-picker/state-picker.component';
import { ProjectStatusPickerComponent } from './components/tables/custom-filters/project-status-picker/project-status-picker.component';
import { CityPickerComponent } from './components/tables/custom-filters/city-picker/city-picker.component';
import { LastDaysPickerComponent } from './components/tables/custom-filters/last-days-picker/last-days-picker.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { UserStatsChartComponent } from './components/charts/user-stats-chart/user-stats-chart.component';
import { NotificationsComponent } from './views/home/home-nav-routes/notifications/notifications.component';
import { ShareDataService } from './services/data-sharing/share-data.service';
import { NotificaitonsShareService } from './services/data-sharing/NotificaitonsShareService';
import { MouseDirectionDirective } from './directives/MouseDirection.directive';
import { FocusoutDirective } from './directives/Focusout.directive';
import { FirstNamePipe } from './pipes/first-name.pipe';
import { LastNamePipe } from './pipes/last-name.pipe';
import { DefaultAvatarPipe } from './pipes/default-avatar.pipe';
import { HeaderComponent } from './components/navigation-others/header/header.component';
import { TagPipe } from './pipes/tag.pipe';
import { StateAbbereviationPipe } from './pipes/state-abbereviation.pipe';
import { SelectInputComponent } from './components/input/select-input/select-input.component';
import { CheckboxInputComponent } from './components/input/checkbox-input/checkbox-input.component';
import { BidsComponent } from './components/bids/bids-customer/bids.component';
import { PainterRatingComponent } from './components/painter-rating/painter-rating.component';
import { CommentCardComponent } from './components/comment-card/comment-card.component';
import { DateOnlyPipe } from './pipes/date-only.pipe';
import { ChatDatePipe } from './pipes/chat-date.pipe';

import { MyProfileComponent } from './views/home/home-nav-routes/my-profile/my-profile.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { ProfileSettingsComponent } from './views/home/home-nav-routes/my-profile/profile-settings/profile-settings.component';
import { SplicePhoneNumberPrefix } from './pipes/splice-phone-number-prefix';
import { AccountSettingValuePipe } from './pipes/account-setting-value.pipe';

import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { CurrencyInputComponent } from './components/input/currency-input/currency-input.component';
import { ImgCarouselComponent } from './components/img-carousel/img-carousel.component';
import { FullNameDotPipe } from './pipes/full-name-dot.pipe';
import { CreateNewProjectComponent } from './views/home/home-nav-routes/create-new-project/create-new-project.component';
import { ChatComponent } from './views/home/home-nav-routes/chat/chat.component';
import { ChatContactListElementComponent } from './components/chat/chat-contact-list-element/chat-contact-list-element.component';
import { TokenStorageService } from './services/token-storage.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { ProjectDetailsComponent } from './components/projects/project-details/project-details.component';
import { DropDownDotsComponent } from './components/drop-down-dots/drop-down-dots.component';
import { EnumPipe } from './pipes/enum.pipe';
import { ProfileImageChooserComponent } from './components/profile-image-chooser/profile-image-chooser.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HrByStateTableComponent } from './components/tables/hr-by-state/hr-by-state-table.component';
import { FrByRoomTypeTableComponent } from './components/tables/fr-by-room-type/fr-by-room-type-table.component.';
import { AlgorithmFactorsTableComponent } from './components/tables/algorithm-factors/algorithm-factors-table.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { ProfileImageUploadComponent } from './components/input/profile-image-upload/profile-image-upload.component';
import { ProjectStatusComponent } from './components/projects/project-status/project-status.component';
import { RoomCardDetailsComponent } from './components/projects/room-card-details/room-card-details.component';
import { ContactCardMiniComponent } from './components/contact-card-mini/contact-card-mini.component';
import { DefaultProjectImagePipe } from './pipes/default-project-image.pipe';
import { NotificationBellComponent } from './components/notifications-bell/notification-bell.component';
import { ChatMessageComponent } from './components/chat/chat-message/chat-message.component';
import { CreateNewProjectPageComponent } from './views/home/home-nav-routes/create-new-project/create-new-project-page/create-new-project-page.component';
import { RoomCreatorComponent } from './views/home/home-nav-routes/create-new-project/room-creator/room-creator.component';
import { SimpleDropDownComponent } from './components/input/simple-dropdown/simple-drop-down.component';
import { WallCardComponent } from './components/projects/wall-card/wall-card.component';
import { RoomImagePipe } from './pipes/room-image-pipe';
import { DateRangePickerComponent } from './components/input/date-range-picker/date-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MyJobsComponent } from './views/home/home-nav-routes/my-jobs/my-jobs.component';
import { MyJobDetailsComponent } from './views/home/home-nav-routes/my-jobs/my-job-details/my-job-details.component';
import { ReportsTableComponent } from './components/tables/reports/reports-table.component';
import { ReportStatusPickerComponent } from './components/tables/custom-filters/report-status-picker/report-status-picker.component';
import { BidsTableComponent } from './components/tables/bids/bids-table.component';
import { ExteriorCreatorComponent } from './views/home/home-nav-routes/create-new-project/exterior-creator/exterior-creator.component';
import { FiltersComponent } from './components/navigation-others/filters/filters.component';
import { WelcomeOneComponent } from './views/login/welcome-one/welcome-one.component';
import { WelcomeTwoComponent } from './views/login/welcome-two/welcome-two.component';
import { WelcomeThreeComponent } from './views/login/welcome-three/welcome-three.component';
import { MyProjectCardComponent } from './views/home/home-nav-routes/my-project-card/my-project-card.component';
import { MyProjectDetailComponent } from './views/home/home-nav-routes/my-project-detail/my-project-detail.component';

import { UserProjectDetailComponent } from './views/home/home-nav-routes/user-project-detail/user-project-detail.component';
import { WelcomeScreenComponent } from './views/login/welcome-screen/welcome-screen.component';
import { TermsConditionComponent } from './views/terms-condition/terms-condition.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { ContactUsComponent } from './views/contact-us/contact-us.component';
import { BlogsComponent } from './views/blogs/blogs.component';
import { ChangePasswordComponent } from './views/change-password/change-password.component';
import { ChangefpassowordComponent } from './views/changefpassoword/changefpassoword.component';
import { MyProfileEditComponent } from './views/my-profile-edit/my-profile-edit.component';
import { MyProfileUserEditComponent } from './views/my-profile-user-edit/my-profile-user-edit.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgImageSliderModule } from 'ng-image-slider';
import { RemoveUnderscorePipe } from './app.component';
import { FacebookLoginProvider, SocialLoginModule, SocialAuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { AboutUsComponent } from './views/about-us/about-us.component';
import { HelpComponent } from './views/help/help.component';
import { HowItWorksComponent } from './views/how-it-works/how-it-works.component';
import { MakeNewProjectComponent } from './views/home/home-nav-routes/make-new-project/make-new-project.component';
import { MakeNewProjectDeatilsComponent } from './views/home/home-nav-routes/make-new-project-deatils/make-new-project-deatils.component';
import { BidPaymentComponent } from './views/home/home-nav-routes/bid-payment/bid-payment.component';
import { MakeNewProjectAdvancedComponent } from './views/home/home-nav-routes/make-new-project-advanced/make-new-project-advanced.component';
//import { CompletedJobDetailsComponent } from './home/my-jobs/completed-job-details/completed-job-details.component';
import { CompletedJobDetailsNewComponent } from './views/home/home-nav-routes/my-jobs/completed-job-details-new/completed-job-details-new.component';
import { environment } from 'src/environments/environment';
import { CustomerChatComponent } from './views/home/customer-chat/customer-chat.component';
import { CusChatComponent } from './views/home/home-nav-routes/cus-chat/cus-chat.component';
import { PainterLoginComponent } from './views/painter-login/painter-login.component';
import { StarRatingComponent } from './views/home/home-nav-routes/my-jobs/star-rating/star-rating.component';
import { PaymentComponent } from './views/home/home-nav-routes/payment/payment.component';
import { NewProjectCompleteDetailsComponent } from './views/home/home-nav-routes/new-project-complete-details/new-project-complete-details.component';
import { NewNotificationComponent } from './views/home/home-nav-routes/new-notification/new-notification.component';
import { GetInspiredComponent } from './views/home-nav-routes/get-inspired/get-inspired.component';
import { GetInspiredLatestComponent } from './views/home/home-nav-routes/get-inspired-latest/get-inspired-latest.component';
import { NewGetInspiredComponent } from './views/new-get-inspired/new-get-inspired.component';


import {MatMenuModule} from '@angular/material/menu';
import { FaqComponent } from './views/faq/faq.component';
import { ModalsComponent } from './views/modals/modals.component';
import { PaymentSuccessComponent } from './views/home/payment-success/payment-success.component';



export function playerFactory() {
    return player;
}

export function appInitializerFactory(tokenStorageService: TokenStorageService, authService: AuthService, router: Router, location: Location, injector: Injector): any {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            if (tokenStorageService.getToken() == null) {
                if (tokenStorageService.getTokenFromLocalStorage() != null) {  // app waits until request is done
                    authService.loginAutomatically().subscribe(data => {
                        tokenStorageService.saveEmail(data.email);
                        tokenStorageService.saveUser(data);
                        tokenStorageService.saveToken(data.token, true);
                        if (location === null || !location.path().startsWith('/home')) { // if activated route does not contain HomeComponent path then do default routing
                            tokenStorageService.doDefaultRouting();
                        }
                        return resolve(null);

                    }, error => {
                        console.log(error);
                        if (error.status === 403) { // account is not active
                            router.navigateByUrl('/login');
                        }
                        return resolve(null);
                    });
                } else { // user does not have automatically login option turned on -> app normally loads
                    return resolve(null);
                }
            } else { // page refresh -> app normally loads
                return resolve(null);
            }
        });
    });
}

@NgModule({
    declarations: [
        AppComponent,
        RegisterComponent,
        LoginComponent,
        HomeComponent,
        EmailConfirmedComponent,
        PasswordResetComponent,
        LandingPageComponent,
        LandingPageComponent,
        UserDetailsComponent,
        ForgotPasswordComponent,
        SplashBgComponent,
        FooterComponent,
        BecomePainterComponent,
        SideNavbarComponent,
        NothingToDisplayComponent,
        ModalComponent,
        MyProjectsComponent,
        ProjectCardComponent,
        MyProjectsComponent,
        ModalComponent,
        PageNotFoundComponent,
        PhoneNumberPipe,
        ConfirmRegistrationComponent,
        TopPanelComponent,
        ServiceCardComponent,
        CreateNewProjectComponent,
        RoutingConfirmationDialogComponent,
        ImageUploadComponent,
        BrushSpinnerComponent,
        RoomCardComponent,
        MarketplaceComponent,
        PaginationComponent,
        MyBidsComponent,
        BidCardMiniComponent,
        ProjectCardComponent,
        OneOfManyOptionsInputComponent,
        ProjectMainDetailsComponent,
        OneOfManyOptionsInputComponent,
        ProjectDetailsComponent,
        BidDetailsComponent,
        PainterProfileComponent,
        AdminDashboardComponent,
        TableActionsComponent,
        RolePickerComponent,
        ProjectsTableComponent,
        UsersTableComponent,
        ProjectTypePickerComponent,
        StatePickerComponent,
        ProjectStatusPickerComponent,
        CityPickerComponent,
        LastDaysPickerComponent,
        UserStatsChartComponent,
        NotificationsComponent,
        MouseDirectionDirective,
        FocusoutDirective,
        LastNamePipe,
        FirstNamePipe,
        DefaultAvatarPipe,
        HeaderComponent,
        BidsComponent,
        PainterRatingComponent,
        CommentCardComponent,
        DateOnlyPipe,
        ChatDatePipe,
        TagPipe,
        StateAbbereviationPipe,
        SelectInputComponent,
        CheckboxInputComponent,
        MyProfileComponent,
        ProfileSettingsComponent,
        SplicePhoneNumberPrefix,
        AccountSettingValuePipe,
        NotificationCardComponent,
        CurrencyInputComponent,
        ImgCarouselComponent,
        FullNameDotPipe,
        ChatComponent,
        ChatContactListElementComponent,
        DropDownDotsComponent,
        EnumPipe,
        ProfileImageChooserComponent,
        HrByStateTableComponent,
        FrByRoomTypeTableComponent,
        AlgorithmFactorsTableComponent,
        ProfileImageUploadComponent,
        ProjectStatusComponent,
        RoomCardDetailsComponent,
        ContactCardMiniComponent,
        DefaultProjectImagePipe,
        NotificationBellComponent,
        ChatMessageComponent,
        CreateNewProjectPageComponent,
        RoomCreatorComponent,
        SimpleDropDownComponent,
        WallCardComponent,
        RoomImagePipe,
        DateRangePickerComponent,
        MyJobsComponent,
        MyJobDetailsComponent,
        ReportsTableComponent,
        ReportStatusPickerComponent,
        BidsTableComponent,
        ExteriorCreatorComponent,
        FiltersComponent,
        WelcomeOneComponent,
        WelcomeTwoComponent,
        WelcomeThreeComponent,
        MyProjectCardComponent,
        MyProjectDetailComponent,
        UserProjectDetailComponent,
        WelcomeScreenComponent,
        TermsConditionComponent,
        PrivacyPolicyComponent,
        ContactUsComponent,
        BlogsComponent,
        ChangePasswordComponent,
        ChangefpassowordComponent,
        MyProfileEditComponent,
        MyProfileUserEditComponent,
        AboutUsComponent,
        HelpComponent,
        HowItWorksComponent,
        MakeNewProjectComponent,
        MakeNewProjectDeatilsComponent,
        BidPaymentComponent,
        MakeNewProjectAdvancedComponent,
        // CompletedJobDetailsComponent,
        CompletedJobDetailsNewComponent,
        CustomerChatComponent,
        CusChatComponent,
        PainterLoginComponent,
        StarRatingComponent,
        RemoveUnderscorePipe,
        PaymentComponent,
        NewProjectCompleteDetailsComponent,
        NewNotificationComponent,
        GetInspiredComponent,
        GetInspiredLatestComponent,
        NewGetInspiredComponent,
        FaqComponent,
        ModalsComponent,
        PaymentSuccessComponent,
    ],
    imports: [

        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NbTabsetModule,
        HttpClientModule,
        GooglePlaceModule,
        NbCardModule,
        //   RemoveUnderscorePipe,
        MatTabsModule,
        SocialLoginModule,
        MatSnackBarModule,
        NbIconModule,
        NbAccordionModule,
        Ng2SmartTableModule,
        NgxMaterialRatingModule,
        NbSelectModule,
        NbCheckboxModule,
        MatPaginatorModule,
        NbMenuModule.forRoot(),
        NbContextMenuModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        NbLayoutModule,
        NbEvaIconsModule,
        NbChatModule,
        ImageCropperModule,
        FontAwesomeModule,
        NbSpinnerModule,
        NbStepperModule,
        NgImageSliderModule,
        MatMenuModule,
        NbThemeModule.forRoot({ name: 'brush' }),
        ToastrModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NgxDaterangepickerMd.forRoot({
            separator: ' - ',
            applyLabel: 'Select',
        }),
        LottieModule.forRoot({ player: playerFactory }),
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return '';
                }
            }
        }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    providers: [{
        provide: APP_INITIALIZER,
        useFactory: appInitializerFactory,
        deps: [TokenStorageService, AuthService, Router, Location, Injector],
        multi: true
    },
    {
        provide: 'SocialAuthServiceConfig',
        useValue: {
            autoLogin: false,
            providers: [
                {
                    id: FacebookLoginProvider.PROVIDER_ID,
                    provider: new FacebookLoginProvider('1204162080245734'), // * facebook app id
                },
            ],
        } as SocialAuthServiceConfig,
    },
        NotificationService, JwtHelperService, AuthGuardService,AdminGuard, authInterceptorProviders, UserPersonalService, DirtyGuardService, RoutingConfirmDialogService, RoutingConfirmDialogServiceReference, ShareDataService, NotificaitonsShareService],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {
}
