<div class="wrapper">
    <!-- <div class="project-creator-header">
        <button routerLink="/home/my-project-card" class="custom-button cancel-button">CANCEL</button>
    </div> -->

    <!--CHOOSE CREATOR TYPE-->
    <div class="page">
        <div class="page-title">Create a New Project</div>

        <div class="way-card-wrapper">
            <div class="way-card">
                <div class="way-header basic">Basic Creator</div>
                <div class="way-content">
                    <div class="way-title">
                        Do you like to keep things as
                        simple as possible?
                    </div>
                    <div class="way-description">
                        Then this creator was designed for you! Create
                        your project and post it on the market in <b>less
                            than 60 seconds!</b>
                    </div>
                    <div class="creator-way">
                        <a (click)="gotonext('basic')">

                            <img src="./assets/icons/creator-way.png">
                        </a>
                    </div>
                </div>
            </div>

            <div class="way-card">
                <div class="way-header advanced">Advanced Creator</div>

                <div class="way-content">
                    <div class="way-title">
                        Want to know what your project should cost?
                    </div>
                    <div class="way-description">
                        You will be asked to provide us with some more detailed information so our model can estimate
                        the cost of your project.
                    </div>
                    <div class="additional-info">
                        <img src="./assets/icons/tick.png">
                        <div class="additional-info-text">Includes Pro Feature Free of Cost</div>
                    </div>
                    <div class="creator-way">
                        <a (click)="gotonext('advanced')">

                            <img src="./assets/icons/creator-way.png">

                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>