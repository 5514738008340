import {Component, Input, OnInit} from '@angular/core';
import {MessageService} from '../../../services/message.service';
import {ERole} from '../../../model/enums/ERole';
import {TokenStorageService} from '../../../services/token-storage.service';
import {FirstNamePipe} from '../../../pipes/first-name.pipe';
import {DefaultAvatarPipe} from '../../../pipes/default-avatar.pipe';

@Component({
  selector: 'app-chat-contact-list-element',
  templateUrl: './chat-contact-list-element.component.html',
  styleUrls: ['./chat-contact-list-element.component.scss'],
  providers: [FirstNamePipe]
})
export class ChatContactListElementComponent implements OnInit {
  @Input() conversation!: any;
  @Input() topic!: string;
  profileImage!: string;
  isUnread!: boolean;
  lastMessageFrom!: string;
  username!: string;
  isUserCustomer!: boolean;

  constructor(private firstNamePipe: FirstNamePipe, public messageService: MessageService, private tokenStorage: TokenStorageService) {
  }

  ngOnInit(): void {
    if(this.conversation != null) {
      this.isUserCustomer = this.tokenStorage.isUserCustomer();
      this.lastMessageFrom = this.checkLastMessageRole();
      this.isUnread = this.messageService.isTheConversationUnread(this.conversation);
      this.username = this.isUserCustomer ? this.conversation.userPainter.userPersonal.fullName : this.conversation.userCustomer.userPersonal.fullName;
      this.profileImage = this.isUserCustomer ? this.conversation.userPainter.profileImage : this.conversation.userCustomer.profileImage

    }
  }

  checkLastMessageRole(): string {
    if (this.isUserCustomer && this.conversation.lastMessageFromRole === ERole.ROLE_CUSTOMER) {
      return 'You: ';
    } else if (!this.isUserCustomer && this.conversation.lastMessageFromRole === ERole.ROLE_PAINTER) {
      return 'You: ';
    } else if (!this.isUserCustomer && this.conversation.lastMessageFromRole === ERole.ROLE_CUSTOMER) {
      return this.firstNamePipe.transform(this.conversation.userCustomer.userPersonal.fullName) + ': ';
    }
    return this.firstNamePipe.transform(this.conversation.userPainter.userPersonal.fullName) + ': ';
  }
}
