import {Component, EventEmitter, Input} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-brush-table-actions',
  templateUrl: './table-actions.component.html',
  styleUrls: ['./table-actions.component.scss']
})
export class TableActionsComponent {
  @Input() inputData: any;

  constructor() {
  }

  actions: any[] = [];

  doAction(action: any): void {
    action.event.emit({value: this.inputData, ...action});
  }

  public addAction(act: { icon: string, pack: string, tip: string, cond?: (r: any) => boolean }): Observable<any> {
    const event$ = new EventEmitter();
    this.actions.push({event: event$, ...act});
    return event$;
  }
  public getData(): any {
    return this.inputData;
  }

}

