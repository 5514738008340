<div class="card">
    <div class="card-body">
        <div *ngIf="!isFormSubmitted" class="main-box">
            <h4 class="title margin-b15">Change password</h4>
            <form [formGroup]="passwordform" class="form margin-b10 mt-4">
                <div class="input-wrapper margin-b15">

                    <div class="form-group position-relative">

                        <label><span class="label-text">Current password</span></label>
                        <span class="icon-wrapper"></span>


                        <input [type]="Cpass" name="" class="form-control " placeholder="" formControlName="currPswd">
                        <span *ngIf="Cpass == 'text'" class="eye_icon" (click)="onEyeClick('Cpass', 'password')">
                            <svg width="20" height="13" viewBox="0 0 20 13" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.29565 6.7632C1.1361 6.56013 1.00404 6.38224 0.900947 6.23866C0.997612 6.09726 1.1211 5.92302 1.27053 5.72458C1.69179 5.16511 2.31455 4.41936 3.11828 3.6754C4.7353 2.17866 7.01653 0.75 9.82143 0.75C12.6263 0.75 14.9076 2.17866 16.5246 3.6754C17.3283 4.41936 17.9511 5.16511 18.3723 5.72458C18.5218 5.92302 18.6452 6.09726 18.7419 6.23866C18.6388 6.38224 18.5068 6.56013 18.3472 6.7632C17.9058 7.32498 17.2587 8.07347 16.4375 8.82004C14.7807 10.3262 12.4992 11.75 9.82143 11.75C7.14368 11.75 4.86212 10.3262 3.2054 8.82004C2.38417 8.07347 1.73706 7.32498 1.29565 6.7632Z"
                                    stroke="#545465" stroke-width="1.5" />
                                <circle cx="9.82143" cy="6.24965" r="2.82143" stroke="#545465" stroke-width="1.5" />
                            </svg>
                        </span>
                        <span *ngIf="Cpass == 'password'" class="eye_icon pswrd_hide"
                            (click)="onEyeClick('Cpass', 'text')">
                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.30849 8.89459C1.14302 8.68399 1.00656 8.49996 0.900682 8.35228C0.999999 8.20676 1.12767 8.0264 1.28274 7.82046C1.71202 7.25034 2.34663 6.49038 3.16572 5.73222C4.81343 4.20707 7.1393 2.75 10 2.75C12.8607 2.75 15.1866 4.20707 16.8343 5.73222C17.6534 6.49038 18.288 7.25034 18.7173 7.82046C18.8723 8.0264 19 8.20676 19.0993 8.35228C18.9934 8.49996 18.857 8.68399 18.6915 8.89459C18.2417 9.46703 17.5824 10.2297 16.7455 10.9905C15.0575 12.525 12.7313 13.9773 10 13.9773C7.26867 13.9773 4.94248 12.525 3.2545 10.9905C2.41765 10.2297 1.75826 9.46703 1.30849 8.89459Z"
                                    stroke="#545465" stroke-width="1.5" />
                                <circle cx="10.0001" cy="8.36293" r="2.88636" stroke="#545465" stroke-width="1.5" />
                                <rect x="2.1875" y="1.44922" width="1.52727" height="20.3636"
                                    transform="rotate(-45 2.1875 1.44922)" fill="#545465" />
                            </svg>
                        </span>

                        <span
                            *ngIf="(passwordform.get('currPswd')?.touched || passwordform.get('currPswd')?.dirty) && passwordform.get('currPswd')?.invalid && passwordform.get('currPswd')?.errors?.required"
                            class="err_msg">
                            *Please enter password
                        </span>
                        <span
                            *ngIf="(passwordform.get('currPswd')?.touched || passwordform.get('currPswd')?.dirty) && passwordform.get('currPswd')?.invalid  && passwordform.get('currPswd')?.errors?.pattern"
                            class="err_msg">
                            *Please enter valid password
                        </span>
                    </div>




                    <div class="form-group position-relative">
                        <label>New Password</label>
                        <input [type]="pass" name="" class="form-control " placeholder="" formControlName="newPswd">
                        <span *ngIf="pass == 'text'" class="eye_icon" (click)="onEyeClick('pass', 'password')">
                            <svg width="20" height="13" viewBox="0 0 20 13" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.29565 6.7632C1.1361 6.56013 1.00404 6.38224 0.900947 6.23866C0.997612 6.09726 1.1211 5.92302 1.27053 5.72458C1.69179 5.16511 2.31455 4.41936 3.11828 3.6754C4.7353 2.17866 7.01653 0.75 9.82143 0.75C12.6263 0.75 14.9076 2.17866 16.5246 3.6754C17.3283 4.41936 17.9511 5.16511 18.3723 5.72458C18.5218 5.92302 18.6452 6.09726 18.7419 6.23866C18.6388 6.38224 18.5068 6.56013 18.3472 6.7632C17.9058 7.32498 17.2587 8.07347 16.4375 8.82004C14.7807 10.3262 12.4992 11.75 9.82143 11.75C7.14368 11.75 4.86212 10.3262 3.2054 8.82004C2.38417 8.07347 1.73706 7.32498 1.29565 6.7632Z"
                                    stroke="#545465" stroke-width="1.5" />
                                <circle cx="9.82143" cy="6.24965" r="2.82143" stroke="#545465" stroke-width="1.5" />
                            </svg>
                        </span>
                        <span *ngIf="pass == 'password'" class="eye_icon pswrd_hide"
                            (click)="onEyeClick('pass', 'text')">
                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.30849 8.89459C1.14302 8.68399 1.00656 8.49996 0.900682 8.35228C0.999999 8.20676 1.12767 8.0264 1.28274 7.82046C1.71202 7.25034 2.34663 6.49038 3.16572 5.73222C4.81343 4.20707 7.1393 2.75 10 2.75C12.8607 2.75 15.1866 4.20707 16.8343 5.73222C17.6534 6.49038 18.288 7.25034 18.7173 7.82046C18.8723 8.0264 19 8.20676 19.0993 8.35228C18.9934 8.49996 18.857 8.68399 18.6915 8.89459C18.2417 9.46703 17.5824 10.2297 16.7455 10.9905C15.0575 12.525 12.7313 13.9773 10 13.9773C7.26867 13.9773 4.94248 12.525 3.2545 10.9905C2.41765 10.2297 1.75826 9.46703 1.30849 8.89459Z"
                                    stroke="#545465" stroke-width="1.5" />
                                <circle cx="10.0001" cy="8.36293" r="2.88636" stroke="#545465" stroke-width="1.5" />
                                <rect x="2.1875" y="1.44922" width="1.52727" height="20.3636"
                                    transform="rotate(-45 2.1875 1.44922)" fill="#545465" />
                            </svg>
                        </span>
                        <span
                            *ngIf="(passwordform.get('newPswd')?.touched || passwordform.get('newPswd')?.dirty) && passwordform.get('newPswd')?.invalid && passwordform.get('newPswd')?.errors?.required"
                            class="err_msg">
                            *Please enter new password
                        </span>
                        <span
                            *ngIf="(passwordform.get('newPswd')?.touched || passwordform.get('newPswd')?.errors?.required || passwordform.get('newPswd')?.dirty) && passwordform.get('newPswd')?.invalid  && passwordform.get('newPswd')?.errors?.pattern"
                            class="err_msg">
                            *Please enter valid password
                        </span>
                        <span
                            *ngIf="(passwordform.get('newPswd')?.touched && passwordform.get('currPswd')?.touched) && (passwordform.get('newPswd')?.value)==(passwordform.get('currPswd')?.value) && passwordform.get('currPswd')?.valid"
                            class="err_msg">
                            *New password must not match with old password
                        </span>
                        <small id="emailHelp" class="form-text text-pink">
                            Password must contain at least 8 characters, including upper/lowercase and numbers
                        </small>
                    </div>
                    <div class="form-group mb-36 position-relative">
                        <label>Confirm New Password</label>
                        <input [type]="confirmPass" name="" class="form-control " placeholder=""
                            formControlName="conPswd">
                        <span *ngIf="confirmPass == 'text'" class="eye_icon"
                            (click)="onEyeClick('confirmPass', 'password')">
                            <svg width="20" height="13" viewBox="0 0 20 13" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.29565 6.7632C1.1361 6.56013 1.00404 6.38224 0.900947 6.23866C0.997612 6.09726 1.1211 5.92302 1.27053 5.72458C1.69179 5.16511 2.31455 4.41936 3.11828 3.6754C4.7353 2.17866 7.01653 0.75 9.82143 0.75C12.6263 0.75 14.9076 2.17866 16.5246 3.6754C17.3283 4.41936 17.9511 5.16511 18.3723 5.72458C18.5218 5.92302 18.6452 6.09726 18.7419 6.23866C18.6388 6.38224 18.5068 6.56013 18.3472 6.7632C17.9058 7.32498 17.2587 8.07347 16.4375 8.82004C14.7807 10.3262 12.4992 11.75 9.82143 11.75C7.14368 11.75 4.86212 10.3262 3.2054 8.82004C2.38417 8.07347 1.73706 7.32498 1.29565 6.7632Z"
                                    stroke="#545465" stroke-width="1.5" />
                                <circle cx="9.82143" cy="6.24965" r="2.82143" stroke="#545465" stroke-width="1.5" />
                            </svg>
                        </span>
                        <span *ngIf="confirmPass == 'password'" class="eye_icon pswrd_hide"
                            (click)="onEyeClick('confirmPass', 'text')">
                            <svg width="20" height="16" viewBox="0 0 20 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M1.30849 8.89459C1.14302 8.68399 1.00656 8.49996 0.900682 8.35228C0.999999 8.20676 1.12767 8.0264 1.28274 7.82046C1.71202 7.25034 2.34663 6.49038 3.16572 5.73222C4.81343 4.20707 7.1393 2.75 10 2.75C12.8607 2.75 15.1866 4.20707 16.8343 5.73222C17.6534 6.49038 18.288 7.25034 18.7173 7.82046C18.8723 8.0264 19 8.20676 19.0993 8.35228C18.9934 8.49996 18.857 8.68399 18.6915 8.89459C18.2417 9.46703 17.5824 10.2297 16.7455 10.9905C15.0575 12.525 12.7313 13.9773 10 13.9773C7.26867 13.9773 4.94248 12.525 3.2545 10.9905C2.41765 10.2297 1.75826 9.46703 1.30849 8.89459Z"
                                    stroke="#545465" stroke-width="1.5" />
                                <circle cx="10.0001" cy="8.36293" r="2.88636" stroke="#545465" stroke-width="1.5" />
                                <rect x="2.1875" y="1.44922" width="1.52727" height="20.3636"
                                    transform="rotate(-45 2.1875 1.44922)" fill="#545465" />
                            </svg>
                        </span>
                        <span
                            *ngIf="(passwordform.get('conPswd')?.touched || passwordform.get('conPswd')?.dirty) && passwordform.get('conPswd')?.invalid && passwordform.get('conPswd')?.errors?.required"
                            class="err_msg">
                            *Please enter confirm password
                        </span>
                        <span
                            *ngIf="(passwordform.get('conPswd')?.touched || passwordform.get('conPswd')?.dirty) && passwordform.hasError('pwdNotSame') && !passwordform.get('conPswd')?.errors?.required"
                            class="form-text text-pink">
                            *Password does not match
                        </span>
                    </div>
                    <div class="error-wrp" *ngIf="error">
                        {{error}}
                    </div>


                </div>
            </form>

            <div class="text-center">
                <button (click)="submitForm()" type="submit" [disabled]="!passwordform.valid" class="custom-button">Save
                </button>
            </div>

        </div>
    </div>
</div>