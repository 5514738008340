import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

import {TokenStorageService} from './token-storage.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private tokenStorage: TokenStorageService, public router: Router,public service: AuthService,private toastr: ToastrService) {
  console.log( localStorage.getItem('role'))
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.service.getToken() || localStorage.getItem('role') !== "painter") {
      this.router.navigate(['/login']);
      console.log('access denied');
      this.toastr.error('Access denied!', 'Error!');
      return false;
    }
    
    if (this.tokenStorage.ifFirstLogin()) {
      this.router.navigate(['/user-details']);
      return false;
    }
    return true;
  }


}
