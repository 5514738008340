import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TokenStorageService} from '../../services/token-storage.service';
import {PainterRatingComponent} from '../painter-rating/painter-rating.component';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss']
})
export class CommentCardComponent implements OnInit {
  @Input() comment!: any;
  @Input() editable!: boolean;
  @Output() newPost = new EventEmitter<any>();
  @ViewChild('editablePainterRating') editablePainterRating!: PainterRatingComponent;

  content = '';
  selectedRate = 0;
  profileImage = '';
  fullName?: any;

  constructor(private tokenStorage: TokenStorageService) {

  }

  ngOnInit(): void {
    if (this.comment != null || this.editable != null) {
      this.profileImage = this.editable === true ? this.tokenStorage.getProfileImage() : this.comment.customerProfileImage;
      this.fullName = this.editable === true ? this.tokenStorage.getFullname() : this.comment.customerFullName;
    }
  }

  setSelectedRate(ev: any): void {
    this.selectedRate = ev;
  }

  post(): void {
    if (this.selectedRate > 0) {

      this.newPost.emit({msg: this.content, rate: this.selectedRate});
      this.content = '';
      this.selectedRate = 0;
      this.editablePainterRating.resetSelectedRate();
    } else {
      console.log('select rating pls');

    }
  }
  postCommentButtonDisabled(): boolean {
    return this.selectedRate === 0 || this.content === '';
  }
}
