<nb-card-body class="nb-theme-brush">
  <nb-tabset>
    <nb-tab tabTitle="Users">
      <div class="legend">
        <h5>Legend (by user role)</h5>
        <div class="legendContent">
          <div class="info">Customer</div>
          <div class="legendColor rgbaGrayBg"></div>
          <div class="info">Painter</div>
          <div class="legendColor rgbaYellowBg"></div>
          <div class="info">Admin</div>
          <div class="legendColor rgbaGreenBg"></div>
        </div>
      </div>
      <app-brush-table-users></app-brush-table-users>
      <app-user-stats-chart></app-user-stats-chart>
    </nb-tab>
    <nb-tab tabTitle="Projects">
    <div class="legend">
      <h5>Legend (by project status)</h5>
      <div class="legendContent">
        <div class="info">Pending</div>
        <div class="legendColor rgbaGrayBg"></div>
        <div class="info">Completed</div>
        <div class="legendColor rgbaGreenBg"></div>
        <div class="info">Complain</div>
        <div class="legendColor rgbaRedBg"></div>
        <div class="info">In progress</div>
        <div class="legendColor rgbaYellowBg"></div>
      </div>
    </div>
    <app-brush-table-projects></app-brush-table-projects>
  </nb-tab>
    <nb-tab tabTitle="Reports">
      <div class="legend">
        <h5>Legend (by report status)</h5>
        <div class="legendContent">
          <div class="info">New</div>
          <div class="legendColor rgbaGrayBg"></div>
          <div class="info">Rejected</div>
          <div class="legendColor rgbaRedBg"></div>
          <div class="info">Accepted</div>
          <div class="legendColor rgbaGreenBg"></div>
        </div>
      </div>
      <app-brush-table-reports></app-brush-table-reports>
    </nb-tab>
    <nb-tab tabTitle="Bids">
      <div class="legend">
        <h5>Legend (by bid status)</h5>
        <div class="legendContent">
          <div class="info">New/Pending</div>
          <div class="legendColor rgbaYellowBg"></div>
          <div class="info">Deleted/Expired</div>
          <div class="legendColor rgbaGrayBg"></div>
          <div class="info">Denied</div>
          <div class="legendColor rgbaRedBg"></div>
          <div class="info">Accepted</div>
          <div class="legendColor rgbaGreenBg"></div>
        </div>
      </div>
      <app-brush-table-bids></app-brush-table-bids>
    </nb-tab>

    <nb-tab tabTitle="Algorithm factors">
      <app-brush-hr-by-state></app-brush-hr-by-state>
      <app-brush-fr-by-room-type></app-brush-fr-by-room-type>
      <app-brush-table-algorithm-factors></app-brush-table-algorithm-factors>
    </nb-tab>
    </nb-tabset>
 </nb-card-body>
