import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'fullNameDot'})
export class FullNameDotPipe implements PipeTransform {

  transform(fullName: string): any {
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.split(' ')[1];
    return firstName  + ' '  + lastName.substr(0, 1) + '.';
  }

}
