<div class="modal-keeper" *ngIf="modalVisible">
  <div class="modal-inner">
    <div class="modal-dialog">
      <ng-template [ngIf]="checkIfDisplayModal(0)">
        <img
          src="assets/icons/brushexchange-with-brush.png"
          alt="brush"
          class="header-image"
        />
        <p class="header-text">Welcome to Brush Exchange</p>
        <p class="description">
          Before you start using BrushExchange, please fill us with some
          details. It will only take a while! All data can be later modified in
          your profile section.
        </p>
        <button class="custom-button" (click)="incraseModal()">
          Get started >
        </button>
      </ng-template>

      <ng-template [ngIf]="checkIfDisplayModal(1)">
        <img
          src="assets/icons/brushexchange-with-brush.png"
          alt="brush"
          class="header-image"
        />
        <p class="header-text">Let us know you better!</p>
        <p class="description">
          Please enter your full name below. Be aware that all of data you
          provide should be authentic as they can be later verified.
        </p>
        <form [formGroup]="userDataForm">
          <div class="input-wrapper input-wrapper-without-image">
            <input
              formControlName="fullName"
              type="text"
              placeholder="Your Full Name"
            />
            <div class="error-wrp" *ngIf="validateFullName()">
              <img
                src="assets/icons/errors/error-solid.svg"
                class="error-icon"
              />
              <div class="error-text" *ngIf="checkFullNameForErrors()">
                {{ fullNameErrorMessage }}
              </div>
            </div>
          </div>
        </form>
        <button
          (click)="incraseModal()"
          class="custom-button"
          [disabled]="checkFullNameForErrors()"
        >
          Next >
        </button>
      </ng-template>

      <ng-template [ngIf]="checkIfDisplayModal(2)">
        <img
          src="assets/icons/brushexchange-with-brush.png"
          alt="brush"
          class="header-image"
        />
        <p class="header-text">How we can contact you?</p>
        <p class="description">
          Your phone number will be only used for contact purposes with the
          painter whose bid you would have picked for your project.
        </p>
        <form [formGroup]="userDataForm">
          <div class="input-wrapper input-wrapper-without-image">
            <input
              (input)="phoneNumberChange($event)"
              formControlName="phoneNumber"
              class="email"
              type="tel"
              maxlength="12"
              placeholder="Your Phone Number"
            />
            <div class="error-wrp" *ngIf="validatePhoneNumber()">
              <img
                src="assets/icons/errors/error-solid.svg"
                class="error-icon"
              />
              <div class="error-text" *ngIf="checkForPhoneNumberErrors()">
                {{ phoneNumberErrorMessage }}
              </div>
            </div>
          </div>
        </form>
        <button
          (click)="incraseModal()"
          class="custom-button"
          [disabled]="checkForPhoneNumberErrors()"
        >
          Next >
        </button>
      </ng-template>

      <ng-template [ngIf]="checkIfDisplayModal(3)">
        <img
          src="assets/icons/brushexchange-with-brush.png"
          alt="brush"
          class="header-image"
        />
        <p class="header-text">Your location</p>
        <p class="description">
          Please provide us with your location. It will be helpful while
          BrushExchange.
        </p>
        <form [formGroup]="userDataForm">
          <div class="input-wrapper input-wrapper-without-image">
            <input placeholder="Street Address" formControlName="street" />
            <div class="error-wrp" *ngIf="validateStreet()">
              <img
                src="assets/icons/errors/error-solid.svg"
                class="error-icon"
              />
              <div class="error-text" *ngIf="checkForStreetErrors()">
                {{ streetErrorMessage }}
              </div>
            </div>
          </div>
          <div class="input-wrapper input-wrapper-without-image">
            <app-one-of-many-options-input
              [allowEmptyValues]="true"
              [pathForData]="'assets/data/states.txt'"
              [placeHolder]="'State'"
              [topResult]="3"
              (selectedOption)="setState($event)"
              [allowCustomValues]="false"
            ></app-one-of-many-options-input>
            <div class="error-wrp" *ngIf="validateState()">
              <img
                src="assets/icons/errors/error-solid.svg"
                class="error-icon"
              />
              <div class="error-text" *ngIf="checkForStateErrors()">
                {{ stateErrorMessage }}
              </div>
            </div>
          </div>
          <div class="city-zip-code">
            <div class="input-wrapper input-wrapper-without-image">
              <app-one-of-many-options-input
                [allowEmptyValues]="true"
                [pathForData]="'assets/data/cities.txt'"
                [placeHolder]="'City'"
                [topResult]="5"
                (selectedOption)="setCity($event)"
                [allowCustomValues]="true"
              ></app-one-of-many-options-input>
              <div class="error-wrp" *ngIf="validateCity()">
                <img
                  src="assets/icons/errors/error-solid.svg"
                  class="error-icon"
                />
                <div class="error-text" *ngIf="checkForCityErrors()">
                  {{ cityErrorMessage }}
                </div>
              </div>
            </div>
            <div class="input-wrapper input-wrapper-without-image">
              <input
                placeholder="ZIP"
                maxlength="5"
                formControlName="zipCode"
              />
              <div class="error-wrp" *ngIf="validateZipCode()">
                <img
                  src="assets/icons/errors/error-solid.svg"
                  class="error-icon"
                />
                <div class="error-text" *ngIf="checkForZipCodeErrors()">
                  {{ zipCodeErrorMessage }}
                </div>
              </div>
            </div>
          </div>
        </form>
        <button
          (click)="incraseModal()"
          class="custom-button"
          [disabled]="checkForAddressErrors()"
        >
          Submit
        </button>
      </ng-template>

      <ng-template [ngIf]="checkIfDisplayModal(4)">
        <img
          src="assets/icons/brushexchange-with-brush.png"
          alt="brush"
          class="header-image"
        />
        <p class="header-text">Glad to have you on board!</p>
        <p class="description">
          Thank you for filling us with some details! Now you can start your
          journey with brush. We hope you will enjoy it!
        </p>
        <button class="custom-button" (click)="submitData()">Continue ></button>

        <!-- Event snippet for Sign-up (1) conversion page --> 
        <script> 
          gtag('event', 'conversion', {'send_to': 'AW-16571460786/_sF7CK39q7MZELLZ8d09'}); 
        </script>
      </ng-template>
    </div>
  </div>
</div>
