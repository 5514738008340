import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-splash-bg',
  templateUrl: './splash-bg.component.html',
  styleUrls: ['./splash-bg.component.scss']
})
export class SplashBgComponent implements OnInit {

  loginRoute = false;
  userRegistration = false;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
    if (this.router.url.includes('/login')) {
      this.loginRoute = true;
    } else {
      this.loginRoute = false;
      this.userRegistration = !this.router.url.includes('accountTypePainter=true');
      console.log(this.userRegistration);
    }
  }

}
