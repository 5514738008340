import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MustMatchValidator} from '../../../_helpers/must-match.validator';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseUserInputDataComponent} from '../../../components/MAIN_COMPONENTS/BaseUserInputDataComponent';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent extends BaseUserInputDataComponent implements OnInit {

  userDataForm = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(8)]],
    confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
  }, {
    validator: MustMatchValidator('password', 'confirmPassword')
  });
  isFormSubmitted = false;
  token = 'MISSING';
  error404 = false;
  tokenNotFoundMessage = 'Reset password token probably expired.';
  buttonDisabled4ever = false;

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token')!;
  }

  submitForm(): void {
    this.error404 = false;
    const password = this.password.value;
    this.authService.changePassword(password, this.token).subscribe(response => {
        this.isFormSubmitted = true;
      },
      error => {
        if (error.status === 404) {
          this.error404 = true;
          this.buttonDisabled4ever = true;

        } else {
          console.log(error.message);
        }
      });
  }
  goBackToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  onEnterClicked(): void {
    if (this.userDataForm.valid && !this.buttonDisabled4ever) {
      this.submitForm();
    }
  }
}
