<div class="view-wrapper view-content-center">
  <div class="view-content-wide view-row">
    <!--<nb-card class="nb-theme-brush sideNavbar">-->
      <!--<nb-menu [items]="items">-->
      <!--</nb-menu>-->
    <!--</nb-card>-->
    <div class="wrp">
      <div id="my-profile-routers">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
