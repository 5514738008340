import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() totalPages?: number;
  @Input() pageNumber?: any;
  @Input() adjacentPagesNo?: any;
  pageNumberList = new Array();
  @Output() newPageNumber = new EventEmitter();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createPageNumberList();
  }

  checkAndEmitValidPageNumber(newValue: any): void {
    this.newPageNumber.emit(newValue);
    this.createPageNumberList();

  }

  createPageNumberList(): void {
    this.pageNumberList = new Array();
    if (this.totalPages != null) {
      for (let i = 1; i <= this.totalPages; i++) {
        this.pageNumberList.push(i);
      }
    }
  }

  adjacentCurrentPageAbs(pageNo: number): boolean {
    if (Math.abs(pageNo - (this.pageNumber + 1)) <= this.adjacentPagesNo || pageNo === 1 || pageNo === this.totalPages) {
      return true;
    }
    return false;
  }

  displayDotsOrNotAfterFirstPage(pageNo: number): boolean {
    if (this.totalPages != null && pageNo === 1 && (this.pageNumber - this.adjacentPagesNo) > 1) {
      return true;
    }
    return false;
  }

  displayDotsOrNotBeforeLastPage(pageNo: number): boolean {
    if (this.totalPages != null && pageNo === this.totalPages && (this.pageNumber + this.adjacentPagesNo) < this.totalPages - 2) {
  return true;
    }
    return false;
  }

  firstPage(): void {
    this.newPageNumber.emit(1);
    this.createPageNumberList();
  }

  lastPage(): void {
    this.newPageNumber.emit(this.totalPages);
    this.createPageNumberList();
  }

  nextPage(): void {
    if (this.totalPages != null && this.pageNumber < this.totalPages) {
      this.newPageNumber.emit(this.pageNumber + 2);
      this.createPageNumberList();
    }
  }

  previousPage(): void {
    if (this.pageNumber >= 1) {
      this.newPageNumber.emit(this.pageNumber > 1 ? this.pageNumber : 1);
      this.createPageNumberList();
    }
  }
}
