import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from './token-storage.service';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {PageableResponse} from '../model/PageableResponse';
import {CustomHttpParameterCodec} from '../_helpers/requests/CustomHttpParameterCodes';
import {NewComment} from '../model/NewComment';


const API = environment.SERVER_BASE_URL + 'comments/';


@Injectable(
  {providedIn: 'root'}
)
export class CommentService {

  public encoder: CustomHttpParameterCodec;

  constructor(private httpClient: HttpClient, private tokenService: TokenStorageService) {
    this.encoder = new CustomHttpParameterCodec();
  }

  postComment(newComment: NewComment): Observable<any> {
    return this.httpClient.post(API + 'new', newComment);
  }

  getPainterComments(painterClient: string, page?: number, pageSize?: number, sort?: string, sortDir?: string): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    return this.httpClient.get<PageableResponse>(API + painterClient, {params: queryParameters});
  }
}
