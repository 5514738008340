<nb-card class="nb-theme-brush">
  <nb-card-header>Algorithm factors</nb-card-header>
  <nb-card-body>
    <form [formGroup]="algorithmFactorsForm" class="form margin-b10">
      <div class="input-wrapper">
        <label><span class="label-text">Crown molding</span></label>
        <input formControlName="crownMolding" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Floor molding</span></label>
        <input formControlName="floorMolding" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Doors quantity</span></label>
        <input formControlName="doorsQuantity" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Windows quantity</span></label>
        <input formControlName="windowsQuantity" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Hourly paint</span></label>
        <input formControlName="hourlyPaint" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Hourly ceiling</span></label>
        <input formControlName="hourlyCeiling" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Hourly wallpaper</span></label>
        <input formControlName="hourlyWallpaper" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Large closet</span></label>
        <input formControlName="largeCloset" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Medium closet</span></label>
        <input formControlName="mediumCloset" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Small closet</span></label>
        <input formControlName="smallCloset" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Not same color</span></label>
        <input formControlName="notSameColor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Same color</span></label>
        <input formControlName="sameColor" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Painter markup</span></label>
        <input formControlName="painterMarkup" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Surface major repairs</span></label>
        <input formControlName="surfaceMajorRepairs" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Surface bad</span></label>
        <input formControlName="surfaceBad" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Surface fair</span></label>
        <input formControlName="surfaceFair" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text">Surface good</span></label>
        <input formControlName="surfaceGood" type="number">
      </div>
      <div class="input-wrapper">
        <label><span class="label-text"></span></label>
        <button class="custom-button" [disabled]="!algorithmFactorsForm.valid" (click)="showModal()">save</button>
      </div>
    </form>
  </nb-card-body>
</nb-card>
<app-modal [displayModal]="changePropertyModalVisible"
           [title]="'Updating algorithm factors'"
           [subclaim]="'Do you want to change algorithm factor values?'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="changeAlgorithmFactors()"
           (cancelButtonClicked)="hideModal()"></app-modal>

