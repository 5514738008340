<div class="smooth-scroll">
    <app-header [userTheme]="true"></app-header>

    <div class="page-wrapper">
        <div class="container-fluid">
            <div class="help-section">
                <div class="sec-a" style="background-image: url({{data.img1}});">
                    <div class="cntnt-sec">
                        <h1 class="text-center">Get Inspired</h1>

                    </div>
                </div>

                <div class="sec-b">
                    <div class="hlp-container">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img2" alt="">
                                            <h4>{{data.heading1}}</h4>
                                            <p class="light_txt">{{data.heading2}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img3" alt="">
                                            <h4>{{data.heading3}}</h4>
                                            <p class="light_txt">{{data.heading4}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img4" alt="">
                                            <h4>{{data.heading5}}</h4>
                                            <p class="light_txt">{{data.heading6}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section class="pink_bckgrd_sec">
                    <div class="white_layer">
                        <div class="pink_sec">
                            <h1>
                                {{data.heading7}}
                            </h1>
                            <p>
                                {{data.heading8}}
                            </p>
                        </div>
                    </div>
                </section>

                <div class="sec-b">
                    <div class="hlp-container">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img5" alt="">
                                            <h4>{{data.heading9}}</h4>
                                            <p class="light_txt">{{data.heading10}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img6" alt="">
                                            <h4>{{data.heading11}}</h4>
                                            <p class="light_txt">{{data.heading12}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="hlp-card">
                                            <img [src]="data.img7" alt="">
                                            <h4>{{data.heading13}}</h4>
                                            <p class="light_txt">{{data.heading14}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>




    <app-footer></app-footer>

</div>