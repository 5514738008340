import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

interface SelectableStar {
  id: number;
  isSelected: boolean;
}

@Component({
  selector: 'app-painter-rating',
  templateUrl: './painter-rating.component.html',
  styleUrls: ['./painter-rating.component.scss']
})
export class PainterRatingComponent implements OnInit, OnChanges {
  MAX_RATING = 5;
  selectedRate!: number;
  @Input() avgRating!: number;
  @Input() totalCommentsForPainter!: any;
  grayStars!: number;
  stars!: number;
  selectableStar = new Array<SelectableStar>();
  @Output() selectedRateEmitter = new EventEmitter();
  avgRatingString!: string;

  constructor() {
  }

  ngOnInit(): void {
    if (this.avgRating) {
      this.avgRatingCalc();
    } else {
      for (let i = 1; i <= this.MAX_RATING; i++) {
        this.selectableStar.push({id: i, isSelected: false});
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.avgRating && changes.avgRating.currentValue) {
      this.avgRatingCalc();
    }
  }

  counter(i: number): number[] {
    return new Array(i);
  }

  onMouseOverSelectStar(brush: any): void {
    for (const star of this.selectableStar) {
      if (star.id <= brush.id) {
        star.isSelected = true;
      } else {
        if (this.selectedRate != null) {
          star.isSelected = false;
        } else {
          star.isSelected = false;
        }
      }
    }
  }

  onMouseOutUnselectStar(): void {
    for (const star of this.selectableStar) {
      if (this.selectedRate != null) {
        if (star.id <= this.selectedRate) {
          star.isSelected = true;
        } else {
          star.isSelected = false;
        }
      } else {
        star.isSelected = false;
      }
    }
  }

  emitSelectedRate(brush: any): void {
    this.selectedRate = brush.id;
    console.log(brush)
    localStorage.setItem('rating',brush.id)
    this.selectedRateEmitter.emit((this.selectedRate));
    for (const star of this.selectableStar) {
      if (star.id > this.selectedRate) {
        star.isSelected = false;
      }
    }
  }

  avgRatingCalc(): void {
    // if (typeof this.avgRating !== 'number' || isNaN(this.avgRating) || !isFinite(this.avgRating)) {
    //   // Handle the case where this.avgRating is not a valid number
    //   console.error('Invalid avgRating:', this.avgRating);
    //   return;
    // }

    this.grayStars = Math.round(this.MAX_RATING - this.avgRating);
    this.stars = this.MAX_RATING - this.grayStars;
       
   // this.avgRatingString = this.avgRating.toFixed(2) ? this.avgRating.toFixed(2): (this.avgRating as number).toFixed(2);
   this.avgRatingString = this.avgRating.toFixed(2);
    console.log(this.avgRatingString,"this.avgRatingString"); 
  }

  resetSelectedRate(): void {
    this.selectedRate = 0;
    for (const star of this.selectableStar) {
      star.isSelected = false;
    }
  }
}
