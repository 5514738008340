import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ProjectRequest} from '../model/ProjectRequest';
import {UserProjectBasic} from '../model/UserProjectBasic';
import {TokenStorageService} from './token-storage.service';
import {CustomHttpParameterCodec} from '../_helpers/requests/CustomHttpParameterCodes';
import {ERoomType} from '../model/enums/ERoomType';
import {PageableResponse} from '../model/PageableResponse';
import {EProjectType, getProjectTypeByRedirectValue} from '../model/enums/EProjectType';

import {EProjectStatus} from '../model/enums/EProjectStatus';
import {SurfaceCondition} from '../model/enums/template/SurfaceCondition';
import {TSurfaceArea} from '../model/enums/tags/TSurfaceArea';
import {Room} from '../model/Room';


const API = environment.SERVER_BASE_URL + 'projects';

@Injectable({
  providedIn: 'root'
})

export class ProjectService {

  public encoder: CustomHttpParameterCodec;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
    this.encoder = new CustomHttpParameterCodec();
  }

  postNewProject(project: ProjectRequest): Observable<any> {
    return this.http.post<ProjectRequest>(API, project);
  }

  getAllProjectMarketplace(page?: number, pageSize?: number, sort?: string, sortDir?: string, searchStateCityZipCode?: string, searchDistance?: number, searchSurfaceArea?: TSurfaceArea, searchSurfaceCondition?: SurfaceCondition): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    if (searchStateCityZipCode !== undefined && searchStateCityZipCode !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchStateCityZipCode, 'searchStateCityZipCode');
    }
    if (searchDistance !== undefined && searchDistance !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchDistance, 'searchDistance');
    }
    if (searchSurfaceArea !== undefined && searchSurfaceArea !== null ) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchSurfaceArea, 'searchSurfaceArea');
    }
    if (searchSurfaceCondition !== undefined && searchSurfaceCondition !== null ) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchSurfaceCondition, 'searchSurfaceCondition');
    }
    return this.http.get<PageableResponse>(API + '/all-marketplace', {params: queryParameters});
  }

  getAllProjectForCustomer(page?: number, pageSize?: number, sort?: string, sortDir?: string, searchStatus?: string, searchProjectType?: EProjectType[]): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    if (searchStatus !== undefined && searchStatus !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchStatus, 'searchStatus');
    }
    if (searchProjectType !== undefined && searchProjectType !== null) {
      searchProjectType.forEach((element) => {
        queryParameters = this.encoder.addToHttpParams(queryParameters,
          element, 'searchProjectType');
      });
    }
    return this.http.get<PageableResponse>(API + '/all-for-customer', {params: queryParameters});
  }

  getAllProjectInterior(page?: number, pageSize?: number, sort?: string, sortDir?: string, searchStateCityZipCode?: string, searchNumberOfRooms?: number, searchRoomTypes?: Array<ERoomType>, searchDistance?: number, searchSurfaceArea?: TSurfaceArea, searchSurfaceCondition?: SurfaceCondition): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    if (searchStateCityZipCode !== undefined && searchStateCityZipCode !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchStateCityZipCode, 'searchStateCityZipCode');
    }
    if (searchNumberOfRooms !== undefined && searchNumberOfRooms !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchNumberOfRooms, 'searchNumberOfRooms');
    }
    if (searchRoomTypes) {
      searchRoomTypes.forEach((element) => {
        queryParameters = this.encoder.addToHttpParams(queryParameters,
          element, 'searchRoomTypes');
      });
    }
    if (searchDistance !== undefined && searchDistance !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchDistance, 'searchDistance');
    }
    if (searchSurfaceArea !== undefined && searchSurfaceArea !== null ) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchSurfaceArea, 'searchSurfaceArea');
    }
    if (searchSurfaceCondition !== undefined && searchSurfaceCondition !== null ) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchSurfaceCondition, 'searchSurfaceCondition');
    }
    return this.http.get<PageableResponse>(API + '/all-interior', {params: queryParameters});
  }

  getAllProjectExterior(page?: number, pageSize?: number, sort?: string, sortDir?: string, searchStateCityZipCode?: string, searchDistance?: number, searchSurfaceArea?: TSurfaceArea, searchSurfaceCondition?: SurfaceCondition): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    if (searchDistance !== undefined && searchDistance !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchDistance, 'searchDistance');
    }
    if (searchStateCityZipCode !== undefined && searchStateCityZipCode !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchStateCityZipCode, 'searchStateCityZipCode');
    }
    if (searchSurfaceArea !== undefined && searchSurfaceArea !== null ) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchSurfaceArea, 'searchSurfaceArea');
    }
    if (searchSurfaceCondition !== undefined && searchSurfaceCondition !== null ) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchSurfaceCondition, 'searchSurfaceCondition');
    }
    return this.http.get<PageableResponse>(API + '/all-exterior', {params: queryParameters});
  }

  getAllUserProjectBasic(): Observable<any> {
    const userID = this.tokenStorage.getEmail();
    console.log(userID);
    return this.http.get<UserProjectBasic[]>(API + '/' + userID);
  }

  postProjectImage(file?: Blob, projectId?: number): Observable<any> {
    if (projectId === null || projectId === undefined) {
      throw new Error('Required parameter projectId was null or undefined when calling postProjectImage.');
    }
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling postProjectImage.');
    }
    const body = new FormData();
    body.append('file', file);

    return this.http.post(API + '/post-project-image/' + projectId, body);
  }

  getProjectWithFullDetailsByBasicData(redirectValue: string, tag: string): Observable<any> {
    if (tag === null || tag === undefined) {
      throw new Error('Required parameter tag was null or undefined when calling getProjectWithFullDetailsByBasicData.');
    }
    if (redirectValue === null || redirectValue === undefined) {
      throw new Error('Required parameter redirectValue was null or undefined when calling getProjectWithFullDetailsByBasicData.');
    }
    const projectTypes = EProjectType;
    const projectType = getProjectTypeByRedirectValue(redirectValue);
    switch (projectType) {
      case projectTypes.PROJECT_INTERIOR:
        return this.http.get<any>(API + '/interior/' + tag);
      case projectTypes.PROJECT_EXTERIOR:
        return this.http.get<any>(API + '/exterior/' + tag);
      case EProjectType.PROJECT_OTHERS:
        return this.http.get<any>(API + '/others/' + tag);
    }
  }
  changeProjectStatus(tag: string, projectStatus: string): Observable<any> {
    if (tag === null || tag === undefined) {
      throw new Error('Required parameter tag was null or undefined when calling changeProjectStatus.');
    }
    if (projectStatus === null || projectStatus === undefined) {
      throw new Error('Required parameter projectStatus was null or undefined when calling changeProjectStatus.');
    }
    let queryParameters = new HttpParams({encoder: this.encoder});
    queryParameters = this.encoder.addToHttpParams(queryParameters, this.tokenStorage.getEmail(), 'email');
    return this.http.get(API + '/' + tag + '/' + projectStatus, {params: queryParameters});
  }
  calculateSingleRoomPrice(room: Room): Observable<number> {
    return this.http.patch<number>(API + '/interior/calculate-single-room-cost', room);
  }

  getAllProjectForPainter(page?: number, pageSize?: number, sort?: string, sortDir?: string, searchStatus?: string): Observable<PageableResponse> {
    let queryParameters = new HttpParams({encoder: this.encoder});
    if (page !== undefined && page !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        page, 'page');
    }
    if (pageSize !== undefined && pageSize !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        pageSize, 'pageSize');
    }
    if (sort !== undefined && sort !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sort, 'sort');
    }
    if (sortDir !== undefined && sortDir !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        sortDir, 'sortDir');
    }
    if (searchStatus !== undefined && searchStatus !== null) {
      queryParameters = this.encoder.addToHttpParams(queryParameters,
        searchStatus, 'searchStatus');
    }
    queryParameters = this.encoder.addToHttpParams(queryParameters,
      this.tokenStorage.getId(), 'painterId');
    return this.http.get<PageableResponse>(API + '/all-for-painter', {params: queryParameters});
  }

  markProjectAsCompleted(projectTag: any): Observable<any> {
    return this.http.patch<PageableResponse>(API + '/mark-as-completed/' + projectTag, null);
  }
}
