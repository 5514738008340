<div class="smooth-scroll">
  <app-header [userTheme]="false"></app-header>

  <div class="bg_white">
    <section id="section-one" class="" style="margin-top: 0px;">
      <div class="section-one-text">
        <p class="label">Ready to Paint?</p>
        <div class="heading">Work your way up</div>
        <div class="head">You bring the skill - we will make earning money easy.</div>
        <div class="head-small">Sign up with <strong class="brush-exchange-text">BrushExchange</strong> and browse real
          job offers now
        </div>
        <button class="custom-button show-me-how-button painter-theme-btn" (click)="scrollToShowHow()">Show me how
        </button>
      </div>
      <img src="./assets/Image-4-painter-ladders-M.svg" class="hero-img" alt="hero-img">
    </section>
  </div>

  <div class="how-does-it-work painterSide_steps" id="section-two-index">How does it work?</div>
  <div class="bg_white">
    <section id="section-two" class="painter-steps">
      <div class="outer-flex">
        <div class="left-flex">
          <div class="item">
            <img src="assets/image-6-painter-pc-M-no-info.svg" class="left-img " alt="'idea">
          </div>
          <div class="item">
            <p class="label-xl">Step#2</p>
            <p class="head">Bid on projects <span class="non-breakable-span"> you like ></span></p>
            <p class="description">Prepare a tailored offer using key information from the customer - it’ll cost you
              only
              $1!</p>
          </div>
          <div class="item">
            <img src="assets/Image-4-painter-ladders-M.svg" class="left-img painter_img3" alt="meeting">
          </div>
          <div class="item">
            <p class="label-xl">Step#4</p>
            <p class="head"><span class="non-breakable-span">> BrushExchange </span> benefits</p>
            <p class="description">See complete project details with pictures, measurements, colors, surface conditions
              and more. Improve the accuracy of your bids and chances of landing a job!</p>
          </div>
        </div>
      </div>
      <div class="center-flex">
        <div class="timeline"></div>
        <div class="timeline-item"></div>
        <div class="timeline-item"></div>
        <div class="timeline-item last-step">success</div>
      </div>
      <div class="outer-flex">
        <div class="right-flex">
          <div class="item">
            <p class="label-xl">Step#1</p>
            <p class="head"><span class="non-breakable-span">> Create</span> account</p>
            <p class="description">Being part of Brush Exchange is totally free - no maintenance costs</p>
          </div>
          <div class="item">
            <img src="assets/painter.svg" class="right-img painter_img2" alt="plan">
          </div>
          <div class="item">
            <p class="label-xl">Step#3</p>
            <p class="head"><span class="non-breakable-span">> Start</span> earning money</p>
            <p class="description">If your offer is accepted we facilitate contact with the customer!</p>
          </div>
          <div class="item">
            <img src="assets/two-last-image-phone-M.svg" class="right-img painter_img4" alt="plan">
          </div>
        </div>
      </div>
    </section>
  </div>
  <section id="section-three-host" class="painterSide_pink_footer">
    <div class="divider-inner">
      <p class="header-div">Join and become part of <strong>Brush Exchange</strong> for free - $1 bids for unlimited
        earning potential.
      </p>
      <a class="custom-button divider-button customer-theme-btn" [routerLink]="'/register'">SIGN UP</a>
    </div>
    <img src="assets/splashes/bottom-splash2.svg" class="splashy">
  </section>
  <!-- HELP CENTER -->
  <!--<div id="section3">-->

  <!--<p class="head-s3">Need some help?</p>-->
  <!--<p class="subhead">We are there to help you. Please check our additional resources prepared for painters below.</p>-->


  <!--<div class="resources-wrapper">-->
  <!--<div class="guide-wrapper">-->
  <!--<p class="head">Help center</p>-->
  <!--<p class="description">Check our help center and find answers for most common asked questions</p>-->
  <!--<p class="hint">Go to our help center <span>></span></p>-->
  <!--</div>-->

  <!--<div class="guide-wrapper pink-bg">-->
  <!--<p class="head">Guides</p>-->
  <!--<p class="description">Find how-to articles and guides for painters</p>-->
  <!--<p class="hint">Go to our guide center <span>></span></p>-->
  <!--</div>-->
  <!--</div>-->

  <!--</div>-->
  <app-footer></app-footer>
</div>