import { Component } from '@angular/core';
import { SurfaceCondition } from '../../model/enums/template/SurfaceCondition';
import { EAdvancedCreatorInputType } from '../../model/enums/EAdvancedCreatorInputType';
import { ERoomType } from '../../model/enums/ERoomType';
import { ECloset } from '../../model/enums/ECloset';

@Component({
  template: ''
})

export abstract class AdvancedCreatorComponent {

  ERoomCreatorInputType = EAdvancedCreatorInputType;

  tagsPrefix = 'selectedValues';

  pages!: any;
  pageTitle: any;
  currentPage: any;
  currentPageIndex = 0;

  backButtonVisible = false;
  nextButtonVisible = false;
  nextButtonDisabled = true;

  wallCreatorVisible = false;
  roomCreatorVisible!: boolean;
  displayingPages = true;

  ceilingCreatorVisible = false;

  select(section: any, item: any): void { }

  nextPage(): void {
    this.displayingPages = false;
    this.wallCreatorVisible = false;
    this.ceilingCreatorVisible = false;
    if (this.pages.length - 1 > this.currentPageIndex) {
      this.displayingPages = true;
      this.currentPage = this.pages[++this.currentPageIndex];
      this.pageTitle = this.currentPage.title;
    } else {
      this.modifyWalls();
    }
    this.buttonsVisibleFunc();
  }

  modifyWalls(): void {
    this.ceilingCreatorVisible = false;
    this.wallCreatorVisible = true;
    this.pageTitle = 'Wall Creator';
    this.buttonsVisibleFunc();
  }


  getPlaceholderValueForList(): any {
    for (const item of this.currentPage.items) {
      if (item.selected) {
        return item.value;
      }
    }
    return 'Select';
  }

  previousPage(): void {
    if (this.displayingPages) {
      this.currentPage = this.pages[--this.currentPageIndex];
      this.pageTitle = this.currentPage.title;
    } else if (this.wallCreatorVisible) {
      this.wallCreatorVisible = false;
      this.displayingPages = true;
      this.pageTitle = this.currentPage.title;
      this.currentPage = this.pages[this.currentPageIndex];
    } else if (this.ceilingCreatorVisible) {
      this.ceilingCreatorVisible = false;
      this.wallCreatorVisible = true;
      this.currentPage.title = 'Wall Creator';
    }
    this.buttonsVisibleFunc();
  }

  buttonsVisibleFunc(): void {
    this.nextButtonVisible = false;
    this.backButtonVisible = false;
    if (this.ceilingCreatorVisible || this.wallCreatorVisible) {
      this.nextButtonVisible = true;
      this.backButtonVisible = true;
    } else {
      this.nextButtonVisible = this.currentPage.multipleChoice || this.currentPage.items.filter((item: any) => item.selected)[0] != null;
      this.nextButtonDisabled = this.currentPage.items.filter((item: any) => item.selected)[0] == null;
      if (this.currentPage.id !== 1) {
        this.backButtonVisible = true;
      }
    }
  }

  unselectAllItemsInSectionAndChangeItemState(item: any): void {
    this.currentPage.items.forEach((sectionItem: any) => sectionItem.selected = false);
    item.selected = !item.selected;
  }


  createPages(isCreatorInterior: boolean): void {
    if (isCreatorInterior) {
      this.advancedInteriorPages();
    } else {
      this.advancedExteriorPages();
    }
  }

  // HELPERS
  trackByFn(index: any, item: any): any {
    return index;
  }

  deepObjectCopy(object: any): any {
    return JSON.parse(JSON.stringify(object));
  }

  advancedInteriorPages(): void {
    this.pages = [
      {
        id: 1,
        title: 'Room Type',
        sectionKey: 'roomType',
        sectionPrefix: 'roomType',
        creatorType: EAdvancedCreatorInputType.IMAGES,
        minimumChecked: false,
        multipleChoice: false,
        items: [
          { id: 1, label: 'Living Room', selected: false, value: ERoomType.LIVING_ROOM, mapLabel: 'room-living', image: '/assets/project-creator/living-room.png' },
          { id: 2, label: 'Bathroom', selected: false, value: ERoomType.BATHROOM, mapLabel: 'room-bathroom', image: '/assets/project-creator/bathroom.png' },
          { id: 3, label: 'Bedroom', selected: false, value: ERoomType.BEDROOM, mapLabel: 'room-bedroom', image: '/assets/project-creator/bedroom.png' },
          { id: 4, label: 'Dining Room', selected: false, value: ERoomType.DINING_ROOM, mapLabel: 'room-dining', image: '/assets/project-creator/dining-room.png' },
          { id: 5, label: 'Kitchen', selected: false, value: ERoomType.KITCHEN, mapLabel: 'room-kitchen', image: '/assets/project-creator/kitchen.png' },
          { id: 6, label: 'Hallway', selected: false, value: ERoomType.HALLWAY, mapLabel: 'room-hallway', image: '/assets/project-creator/hallway.png' },
          { id: 7, label: 'Others', selected: false, value: ERoomType.OTHER, mapLabel: 'room-others', image: '/assets/project-creator/others.png' },
        ]
      },
      {
        id: 2,
        title: 'No. of Windows',
        sectionKey: 'windows',
        sectionPrefix: 'windows',
        creatorType: EAdvancedCreatorInputType.SELECT,
        minimumChecked: false,
        multipleChoice: false,
        items: [
          { id: 1, label: '0', selected: false, value: 0 },
          { id: 2, label: '1', selected: false, value: 1 },
          { id: 3, label: '2', selected: false, value: 2 },
          { id: 4, label: '3', selected: false, value: 3 },
          { id: 5, label: '4+', selected: false, value: 4 },
        ]
      },
      {
        id: 2,
        title: 'No. of Doors',
        sectionKey: 'doors',
        sectionPrefix: 'doors',
        minimumChecked: false,
        creatorType: EAdvancedCreatorInputType.SELECT,
        multipleChoice: false,
        items: [
          { id: 1, label: '0', selected: false, value: 0 },
          { id: 2, label: '1', selected: false, value: 1 },
          { id: 3, label: '2', selected: false, value: 2 },
          { id: 4, label: '3+', selected: false, value: 3 }
        ]
      },
      {
        id: 3,
        title: 'How big closet',
        sectionKey: 'closet',
        sectionPrefix: 'closet',
        minimumChecked: false,
        creatorType: EAdvancedCreatorInputType.BOXES,
        multipleChoice: false,
        items: [
          { id: 1, label: ECloset.NONE, selected: false, value: ECloset.NONE },
          { id: 2, label: ECloset.SMALL, selected: false, value: ECloset.SMALL },
          { id: 3, label: ECloset.MEDIUM, selected: false, value: ECloset.MEDIUM },
          { id: 4, label: ECloset.LARGE, selected: false, value: ECloset.LARGE },
        ]
      },
      {
        id: 4,
        title: 'How would you rate surface condition in this room?',
        sectionKey: 'surfaceCondition',
        sectionPrefix: 'surfaceCondition',
        minimumChecked: false,
        multipleChoice: false,
        creatorType: EAdvancedCreatorInputType.BOXES_WITH_IMAGES,
        items: [
          { id: 1, label: SurfaceCondition.BAD, selected: false, value: SurfaceCondition.BAD, image: '/assets/project-creator/bad.png' },
          { id: 2, label: SurfaceCondition.POOR, selected: false, value: SurfaceCondition.POOR, image: '/assets/project-creator/sad.png' },
          { id: 3, label: SurfaceCondition.FAIR, selected: false, value: SurfaceCondition.FAIR, image: '/assets/project-creator/fair.png' },
          { id: 4, label: SurfaceCondition.GOOD, selected: false, value: SurfaceCondition.GOOD, image: '/assets/project-creator/good.png' },
        ]
      },
      {
        id: 5,
        title: 'Does room have crown molding?',
        sectionKey: 'crownMolding',
        sectionPrefix: 'crownMolding',
        creatorType: EAdvancedCreatorInputType.BOXES,
        minimumChecked: false,
        multipleChoice: false,
        items: [
          { id: 1, label: 'Yes', selected: false, value: true },
          { id: 2, label: 'No', selected: false, value: false },
        ]
      },
      {
        id: 6,
        title: 'Does room have base molding?',
        sectionKey: 'baseMolding',
        sectionPrefix: 'baseMolding',
        minimumChecked: false,
        multipleChoice: false,
        creatorType: EAdvancedCreatorInputType.BOXES,
        items: [
          { id: 1, label: 'Yes', selected: false, value: true },
          { id: 2, label: 'No', selected: false, value: false },
        ]
      },
      {
        id: 7,
        title: 'Ceiling',
        sectionKey: this.tagsPrefix,
        sectionPrefix: 'whatpainted', //FIXME this is unused on backend
        minimumChecked: false,
        creatorType: EAdvancedCreatorInputType.IMAGES_MULTIPLE,
        multipleChoice: true,
        items: [
          { id: 1, label: 'Walls', selected: false, value: true, mapLabel: 'paintingWalls', image: '/assets/project-creator/walls.png' },
          { id: 2, label: 'Trim', selected: false, value: true, mapLabel: 'paintingTrim', image: '/assets/project-creator/trims.png' },
          { id: 3, label: 'Ceiling', selected: false, value: true, mapLabel: 'paintingCeiling', image: '/assets/project-creator/ceiling.png' },
          { id: 4, label: 'Doors', selected: false, value: true, mapLabel: 'paintingDoors', image: '/assets/project-creator/doors.png' },
          { id: 5, label: 'Window frames', selected: false, value: true, mapLabel: 'paintingWindows', image: '/assets/project-creator/window-frames.png' }
        ]
      },
    ];
  }
  advancedExteriorPages(): void {
    this.pages = [
      {
        id: 1,
        title: 'No. of Windows',
        sectionKey: 'windows',
        sectionPrefix: 'windows',
        creatorType: EAdvancedCreatorInputType.SELECT,
        minimumChecked: false,
        multipleChoice: false,
        items: [
          { id: 1, label: '0', selected: false, value: 0 },
          { id: 2, label: '1', selected: false, value: 1 },
          { id: 3, label: '2', selected: false, value: 2 },
          { id: 4, label: '3', selected: false, value: 3 },
          { id: 5, label: '4+', selected: false, value: 4 },
        ]
      },
      {
        id: 2,
        title: 'No. of Doors',
        sectionKey: 'doors',
        sectionPrefix: 'doors',
        minimumChecked: false,
        creatorType: EAdvancedCreatorInputType.SELECT,
        multipleChoice: false,
        items: [
          { id: 1, label: '0', selected: false, value: 0 },
          { id: 2, label: '1', selected: false, value: 1 },
          { id: 3, label: '2', selected: false, value: 2 },
          { id: 4, label: '3+', selected: false, value: 3 }
        ]
      },
      {
        id: 3,
        title: 'How would you rate surface condition in this room?',
        sectionKey: 'surfaceCondition',
        sectionPrefix: 'surfaceCondition',
        minimumChecked: false,
        multipleChoice: false,
        creatorType: EAdvancedCreatorInputType.BOXES_WITH_IMAGES,
        items: [
          { id: 1, label: SurfaceCondition.BAD, selected: false, value: SurfaceCondition.BAD, image: '/assets/project-creator/bad.png' },
          { id: 2, label: SurfaceCondition.POOR, selected: false, value: SurfaceCondition.POOR, image: '/assets/project-creator/sad.png' },
          { id: 3, label: SurfaceCondition.FAIR, selected: false, value: SurfaceCondition.FAIR, image: '/assets/project-creator/fair.png' },
          { id: 4, label: SurfaceCondition.GOOD, selected: false, value: SurfaceCondition.GOOD, image: '/assets/project-creator/good.png' },
        ]
      },
      {
        id: 4,
        title: 'Does wall have roof molding?',
        sectionKey: 'baseMolding',
        sectionPrefix: 'baseMolding',
        minimumChecked: false,
        multipleChoice: false,
        creatorType: EAdvancedCreatorInputType.BOXES,
        items: [
          { id: 1, label: 'Yes', selected: false, value: true },
          { id: 2, label: 'No', selected: false, value: false },
        ]
      },
      {
        id: 5,
        title: 'What do you want painted',
        sectionKey: this.tagsPrefix,
        sectionPrefix: 'whatpainted',
        minimumChecked: false,
        creatorType: EAdvancedCreatorInputType.IMAGES_MULTIPLE,
        multipleChoice: true,
        items: [
          { id: 1, label: 'Walls', selected: false, value: true, mapLabel: 'paintingWalls', image: '/assets/project-creator/walls.png' },
          { id: 2, label: 'Doors', selected: false, value: true, mapLabel: 'paintingDoors', image: '/assets/project-creator/doors.png' },
          { id: 3, label: 'Window frames', selected: false, value: true, mapLabel: 'paintingWindows', image: '/assets/project-creator/window-frames.png' }
        ]
      },
    ];
  }

}
