import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NbTabComponent, NbTabsetComponent} from '@nebular/theme';
import {ActivatedRoute} from '@angular/router';
import {SelectInputOption} from '../../../model/components/SelectInputOption';

enum OrderingFunctionEnum {
  SORT_DATE_DESC,
  SORT_DATE_ASC,
  SORT_PRICE_ASC,
  SORT_PRICE_DESC
}

@Component({
  selector: 'app-bids',
  templateUrl: './bids.component.html',
  styleUrls: ['./bids.component.scss']
})
export class BidsComponent implements OnChanges, AfterViewInit {

  @Input() project!: any;
  @Output() bidStatusChanged = new EventEmitter();
  @ViewChild('tabset') tabset!: NbTabsetComponent;
  @ViewChild('winningTabset') winningTabset!: NbTabComponent;
  @ViewChild('newTabset') newTabset!: NbTabComponent;
  @ViewChild('deniedTabset') deniedTabset!: NbTabComponent;

  orderOptions: SelectInputOption[] = [
    {
      optionLabel: 'Sort By: Newest',
      optionValue: OrderingFunctionEnum.SORT_DATE_DESC,
      sortDir: '',
      optionSelected: true,
      optionDisabled: false
    },
    {
      optionLabel: 'Sort By: Oldest',
      optionValue: OrderingFunctionEnum.SORT_DATE_ASC,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    },
    {
      optionLabel: 'Sort By: Offer Highest',
      optionValue: OrderingFunctionEnum.SORT_PRICE_ASC,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    },
    {
      optionLabel: 'Sort By: Offer Lowest',
      optionValue: OrderingFunctionEnum.SORT_PRICE_DESC,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    }
  ];

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngAfterViewInit(): void {
    const bidTag = this.activatedRoute.snapshot.paramMap.get('bidId');
    if (bidTag != null) {
      const int = setInterval(() => {
        if (this.project != null) {
          this.activateBidTabset(bidTag);
          clearInterval(int);
        }
      }, 250);
    }
  }

  changeOrdering(order: any): void {
    this.orderOptions.forEach(item => {
      item.optionSelected = false;
      if (item.optionLabel === order.optionLabel) {
        item.optionSelected = true;
        switch (item.optionValue) {
          case OrderingFunctionEnum.SORT_DATE_ASC:
            this.sortDateAsc();
            break;
          case OrderingFunctionEnum.SORT_DATE_DESC:
            this.sortDateDesc();
            break;
          case  OrderingFunctionEnum.SORT_PRICE_ASC:
            this.sortPriceAsc();
            break;
          case OrderingFunctionEnum.SORT_PRICE_DESC:
            this.sortPriceDesc();
            break;
        }
      }
    });
  }

  sortPriceDesc(): void {
    const sortFunc = (b1: any, b2: any) => {
      return b1.price - b2.price;
    };
    this.sortData(sortFunc);
  }

  sortPriceAsc(): void {
    const sortFunc = (b1: any, b2: any) => {
      return b2.price - b1.price;
    };
    this.sortData(sortFunc);
  }

  sortDateDesc(): void {
    const sortFunc = (b1: any, b2: any) => {
      return new Date(b2.statusTime).getTime() - new Date(b1.statusTime).getTime();
    };
    this.sortData(sortFunc);
  }

  sortDateAsc(): void {
    const sortFunc = (b1: any, b2: any) => {
      return new Date(b1.statusTime).getTime() - new Date(b2.statusTime).getTime();
    };
    this.sortData(sortFunc);
  }

  sortData(sortFunc: any): void {
    this.project.newBids?.sort(sortFunc);
    this.project.deniedBids?.sort(sortFunc);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const selectedOrder = this.orderOptions.filter(opt => opt.optionSelected === true)[0];
    this.changeOrdering(selectedOrder);
  }

  navigateToWinningTabset(): void {
    const timeout = setInterval(() => {
      if (this.tabset != null && this.winningTabset != null) {
        clearInterval(timeout);
        this.tabset.selectTab(this.winningTabset);
      }
    }, 300);
  }

  activateBidTabset(bidTag: string): void {
    if (this.project.deniedBids.filter((opt: any) => opt.tag === bidTag).length > 0) {
      this.tabset.selectTab(this.deniedTabset);
    }
    if (this.project.newBids.filter((opt: any) => opt.tag === bidTag).length > 0) {
      this.tabset.selectTab(this.newTabset);
    }
  }
}
