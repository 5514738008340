<div class="nav-header big_screen_header">
  <img src="assets/logo.png" class="logo" alt="logo" [routerLink]="'/'">

  <nav class="nav-routers">
    <ng-template [ngIf]="anyKindForm">
      <a class="nav-item" *ngIf="login">Don't have an account yet?</a>
      <a class="nav-item " (click)="role('painter')" *ngIf="login"
        [ngClass]="!userTheme ? 'painter-theme' : 'customer-theme'" [routerLink]="'/register'">Register</a>

      <a class="nav-item" *ngIf="!login">Already registered?</a>
      <a class="nav-item " *ngIf="!login" [ngClass]="!userTheme ? 'painter-theme' : 'customer-theme'"
        [routerLink]="'/login'">Login</a>
    </ng-template>

    <ng-template [ngIf]="!anyKindForm">
      <a class="nav-item" [ngClass]="!userTheme ? 'customer-theme-text-hover' : 'painter-theme-text-hover'"
        [routerLink]="'/login'">Sign in</a>
      <a class="nav-item" [ngClass]="!userTheme ? 'customer-theme-text-hover' : 'painter-theme-text-hover'"
        (click)="openSignUpModal()"> Sign up</a>
      <a *ngIf="userTheme" class="nav-item painter-theme" (click)="role('painter')" [routerLink]="'/host'">Become a
        painter</a>
      <a *ngIf="!userTheme " class="nav-item customer-theme" (click)="role('customer')" [routerLink]="'/'">Look for
        professionals</a>
    </ng-template>
  </nav>
</div>


<div class="nav-header small_screen_header">
  <img src="assets/logo.png" class="logo" alt="logo" [routerLink]="'/'">
  <nb-icon icon="menu-arrow-outline" class="drop_icon rotateArrow" (click)="openSidebar()"></nb-icon>
</div>

<div class="sidebar" [ngClass]="{'open': showSidebar}">
  <div class="dEnd">
    <nb-icon icon="menu-arrow-outline" class="drop_icon" (click)="closeSidebar()"></nb-icon>
    <!-- <p class="mt-1">Hide</p> -->
  </div>

  <div class="nav-header small_screen_header sidebar-cntnt">

    <nav class="nav-routers">
      <ng-template [ngIf]="anyKindForm">
        <a class="nav-item" *ngIf="login">Don't have an account yet?</a>
        <a class="nav-item " (click)="role('painter')" *ngIf="login"
          [ngClass]="!userTheme ? 'painter-theme' : 'customer-theme'" [routerLink]="'/register'">Register</a>

        <a class="nav-item" *ngIf="!login">Already registered?</a>
        <a class="nav-item " *ngIf="!login" [ngClass]="!userTheme ? 'painter-theme' : 'customer-theme'"
          [routerLink]="'/login'">Login</a>
      </ng-template>

      <ng-template [ngIf]="!anyKindForm">
        <a class="nav-item" [ngClass]="!userTheme ? 'customer-theme-text-hover' : 'painter-theme-text-hover'"
          [routerLink]="'/login'">Sign in</a>
        <a class="nav-item" [ngClass]="!userTheme ? 'customer-theme-text-hover' : 'painter-theme-text-hover'"
         (click)="openSignUpModal()"> Sign up</a>
        <a *ngIf="userTheme" class="nav-item painter-theme" (click)="role('painter')" [routerLink]="'/host'">Become a
          painter</a>
        <a *ngIf="!userTheme " class="nav-item customer-theme" (click)="role('customer')" [routerLink]="'/'">Look for
          professionals</a>
      </ng-template>
    </nav>
  </div>

</div>

<app-modal class="bid-detail-mdl signup_as_cntner_main pay_bid_modal" [displayModal]="showModal"
  (cancelButtonClicked)="closeComplainModal()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/signup.svg" alt="meeting" class="left-img">
          </div>
          <div class="signup_as_cntner">
            <div class="signup_as_cntner_inner">
              <div class="select_option cstmer_Selection" [class.selected]="isCustomerSelected" (click)="selectCustomer()">
                <a >Become a customer</a>
              </div>
              <div class="select_option pnter_Selection" [class.selected]="isPainterSelected" (click)="selectPainter()">
                <a >Become a painter</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </slot-content>


</app-modal>