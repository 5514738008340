import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from './token-storage.service';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Painter} from '../model/Painter';
import {UserPainterProfile} from '../model/UserPainterProfile';
import {CustomHttpParameterCodec} from '../_helpers/requests/CustomHttpParameterCodes';

const API = environment.SERVER_BASE_URL + 'user-painter';
@Injectable({
  providedIn: 'root'
})
export class PainterService {
  public encoder: CustomHttpParameterCodec;

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
    this.encoder = new CustomHttpParameterCodec();
  }

  findPainterByProjectTag(projectTag: string): Observable<Painter> {
    if (projectTag === undefined || projectTag === null) {
      throw new Error('Required parameter projectTag was null or undefined when calling findPainterByProjectTag.');
    }
    return this.http.get<Painter>(API + '/get-by-project-tag/' + projectTag);
  }
  getPainterDetailsForProfile(clientID: string): Observable<UserPainterProfile> {
    const parameters = new HttpParams()
      .set('customerEmail', this.tokenStorage.getEmail());
    return this.http.get<any>(API + '/' + clientID, {
      params: parameters
    });
  }
  getAllPaintersIdsRelatedForProject(projectTag: string): Observable<number[]> {
    let queryParams = new HttpParams({encoder: this.encoder});
    queryParams = this.encoder.addToHttpParams(queryParams, projectTag, 'projectTAG');
    return this.http.get<number[]>(API + '/painters-related-to-project', {params: queryParams});
  }
}
