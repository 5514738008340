import {Injectable} from '@angular/core';
import {ENotificationType} from '../../model/enums/ENotificationType';
import { Subject } from 'rxjs';

@Injectable()
export class NotificaitonsShareService {

   notificationType!: Subject<string>;

  constructor() {
    this.notificationType = new Subject<string>();
  }
}
