<div class="totalElements">Found <b>{{totalElements || 0}}</b> reports</div>
<ng2-smart-table [settings]="settings" [source]="source"></ng2-smart-table>

<app-modal [displayModal]="acceptReportModalVisible"
           [title]="'Accept Report'"
           [subclaim]="'Do you want to accept this report?'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="changeReportStatus(EReportStatus.ACCEPTED)"
           (cancelButtonClicked)="acceptReportModalVisible = false"></app-modal>


<app-modal [displayModal]="rejectReportModalVisible"
           [title]="'Reject Report'"
           [subclaim]="'Do you want to reject this report?'"
           [confirmButtonText]="'Confirm'"
           (confirmButtonClicked)="changeReportStatus(EReportStatus.REJECTED)"
           (cancelButtonClicked)="rejectReportModalVisible = false"></app-modal>
