import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
//import {AuthService} from '../../../services/auth.service';
import {NavigationExtras, Router} from '@angular/router';
@Component({
  selector: 'app-welcome-three',
  templateUrl: './welcome-three.component.html',
  styleUrls: ['./welcome-three.component.scss']
})
export class WelcomeThreeComponent implements OnInit {

  userDataForm!: FormGroup;

  constructor(private formBuilder: FormBuilder,
             // private authService: AuthService,
              private router: Router) { 
               this.userDataForm = this.formBuilder.group({
        phone: ['', [Validators.required, Validators.minLength(10)]],
        
      });}

  ngOnInit(): void {
  if(localStorage.getItem('phone')){
        this.userDataForm.patchValue({
            'phone': localStorage.getItem('phone'),
         //   'password': localStorage.getItem('password')
        })
  }
  }

  submitRegisterForm(){
  localStorage.setItem('phone',this.userDataForm.value.phone)
        this.router.navigate(['/login/register-location'])
  }
}
