<div class="wrapper">
  <div class="search-input" #searchWrapper>
    <input type="text"
           placeholder="{{placeHolder}}"
           (input)="searchValue($event)"

           (blur)="onBlur($event)"
      [ngModel]="input">
    <div class="autocom-box">
      <li *ngFor="let value of displayData" (mousedown)="setSelectedOption(value)">{{value}}</li>
      <div *ngIf="displayData == null || displayData.length == 0" class="no-data-found">no data found</div>
    </div>
    <div class="icon" *ngIf="imgSrc"><img src="{{imgSrc}}" class="img-icon" alt="icon"></div>
  </div>
</div>
