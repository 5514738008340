import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Bid} from '../../model/Bid';
import {EProjectStatus} from '../../model/enums/EProjectStatus';
import {EBidStatus} from '../../model/enums/EBidStatus';
import {BidRequest} from '../../model/BidRequest';
import {BidsService} from '../../services/bids.service';
import {TokenStorageService} from '../../services/token-storage.service';
import {getRedirectLinkForProjectType} from '../../model/enums/EProjectType';
import {BrushNotification} from '../../services/notification-service';
import {MessageService} from '../../services/message.service';
import {WhiteSpaceValidator} from '../../_helpers/white-space.validator';
import {PainterService} from '../../services/painter.service';

export interface BidPriceChange {
  price: string;
  tag: string;
  comment?: string;
}

@Component({
  template: ''
})
export abstract class PainterBidComponent implements OnInit {
  bidPlacedModalValues = {
    title: 'Bid placement',
    subclaim: '',
    btn: 'Send bid'
  };
  myBid!: Bid;
  project!: any;
  isBidNotPlacedDueToErrorModalVisible = false;
  isPlaceBidModalVisible = false;
  isDeleteBidModalVisible = false;
    newOfferForm = this.formBuilder.group({
    newPrice: ['', [Validators.required]],
    comment: ['', [Validators.required, Validators.minLength(5), WhiteSpaceValidator]],
  });

  abstract onBidPriceChanged(): void;
  abstract onBidPlaced(): void;
  abstract onBidDeleted(): void;

  constructor(protected formBuilder: FormBuilder, protected bidsService: BidsService, protected tokenStorage: TokenStorageService, protected messageService: MessageService) {
  }

  consumeWrongCharacters(event: any): void {
    const currentVal = event.target.value;
    if (!(currentVal[currentVal.length - 1] as string)?.match('^[0-9]+$')) {
      event.target.value = currentVal.slice(0, -1);
    }
  }

  ngOnInit(): void {
  console.log(this.isPlaceBidModalVisible)
  }




  get newOfferValue(): FormControl {
    return this.newOfferForm.get('newPrice') as FormControl;
  }

  isNewOfferButtonDisabled(): boolean {
    return this.newOfferValue.value == this.myBid?.price || ((this.newOfferValue.invalid && (this.newOfferValue.dirty || this.newOfferValue.touched)));
  }

  isBidActive(): boolean {
    return this.myBid.project?.status === EProjectStatus.PENDING && (this.myBid.status === EBidStatus.PENDING || this.myBid.status === EBidStatus.NEW);
  }

  isNewBidPossible(): boolean {
    return this.myBid.project?.status === EProjectStatus.PENDING && this.myBid.status !== EBidStatus.PENDING && this.myBid.status !== EBidStatus.NEW;
  }

  displayBidNotPlacedDueToErrorModal(status: number): void {
    this.isBidNotPlacedDueToErrorModalVisible = true;
    switch (status) {
      case 406:
        this.bidPlacedModalValues.title = 'Cannot post bid';
        this.bidPlacedModalValues.subclaim = 'Customer is no longer interested in considering any new offers for this project.';
        this.bidPlacedModalValues.btn = 'Confirm';
        break;
      case 409:
        this.bidPlacedModalValues.title = 'Bid already posted';
        this.bidPlacedModalValues.subclaim = 'Seems you have already posted bid for this project and it is still an active bid. If you really want to post different bid you have to undo your previous bid and then post a new one.';
        this.bidPlacedModalValues.btn = 'Confirm';
        break;
      default:
        this.bidPlacedModalValues.title = 'Ooops, something went wrong...';
        this.bidPlacedModalValues.subclaim = 'Unfortunately there were some issues during placing your offer. Please try again.';
        this.bidPlacedModalValues.btn = 'Confirm';
    }
  }

  displayBidNotDeletedDueToErrorModal(status: number): void {
    this.isBidNotPlacedDueToErrorModalVisible = true;
    this.bidPlacedModalValues.title = 'Cannot delete this bid';
    switch (status) {
      case 400:
        this.bidPlacedModalValues.subclaim = 'You cannot delete this bid. Probably customer accepted your bid earlier.';
        this.bidPlacedModalValues.btn = 'Confirm';
        break;
      case 403:
        this.bidPlacedModalValues.subclaim = 'You do not have permissions to delete this bid';
        this.bidPlacedModalValues.btn = 'Confirm';
        break;
      default:
        this.bidPlacedModalValues.subclaim = 'Unfortunately there were some issues during deleting your offer. Please try again.';
        this.bidPlacedModalValues.btn = 'Confirm';
    }
  }

  toggleOffAllModals(): void {
    this.isPlaceBidModalVisible = false;
    this.isBidNotPlacedDueToErrorModalVisible = false;
    this.isDeleteBidModalVisible = false;
  }

  checkIfDisplay(): boolean {
  console.log(this.isPlaceBidModalVisible )
    return this.isPlaceBidModalVisible || this.isBidNotPlacedDueToErrorModalVisible;
  }

  sendBid(newBid?: boolean): void {
    const bid = this.buildNewBidRequest();
    if (this.myBid == null || newBid) {
      this.bidsService.makeNewBid(bid).subscribe(response => {
          this.myBid = response as Bid;
          this.sendNewBidNotifications();
          this.onBidPlaced();
          this.toggleOffAllModals();
        },
        error => {
          this.isPlaceBidModalVisible = false;
          this.displayBidNotPlacedDueToErrorModal(error.status);
        });
    } else {
      this.bidsService.changeBidPrice(this.buildBidPriceChangeRequest(this.newOfferForm.controls['newPrice'].value, this.myBid.tag, this.newOfferForm.controls['comment'].value)).subscribe(res => {
        this.myBid = res;
        this.toggleOffAllModals();
        this.sendNewBidNotifications();
        this.onBidPriceChanged();
      }, error => {
        this.isPlaceBidModalVisible = false;
        this.displayBidNotPlacedDueToErrorModal(error.status);
      });
    }
  }

  buildNewBidRequest(): BidRequest {
    return {
      projectTag: this.project.tag,
      painterEmail: this.tokenStorage.getEmail(),
      price: this.newOfferForm.controls['newPrice'].value,
      comment: this.newOfferForm.controls['comment'].value,
      projectType: this.project.projectType
    };
  }

  buildBidPriceChangeRequest(newPrice: string, bidTag: string, newComment?: string): BidPriceChange {
    return {
      tag: bidTag,
      comment: newComment,
      price: newPrice
    };
  }

  changeBidPrice(newPrice: string, bidTag: string): void {
    this.bidsService.changeBidPrice(this.buildBidPriceChangeRequest(newPrice, bidTag)).subscribe(res => {
      this.myBid = res;
      this.sendNewBidNotifications();
      this.onBidPriceChanged();
    }, error => {
      console.log(error);
    });
  }

  deleteBid(): void {
    this.bidsService.changeBidStatus({status: EBidStatus.DELETED, tag: this.myBid.tag}).subscribe(res => {
      this.sendUndoBidNotifications();
      this.onBidDeleted();
      this.toggleOffAllModals();
    }, error => {
      this.displayBidNotDeletedDueToErrorModal(error.status);
    });
  }

  sendNewBidNotifications(): void {
    this.messageService.sendMessageRelatedIdSub(this.project?.customer?.id, BrushNotification.NEW_BID_NOTIFICATION_CUSTOMER, this.project.projectDetails.title, getRedirectLinkForProjectType(this.project.projectType).value + '/' + this.project.tag, this.myBid.tag);
    this.messageService.sendMessage(this.tokenStorage.getId(), BrushNotification.NEW_BID_NOTIFICATION_PAINTER, this.project.projectDetails.title, this.myBid.tag);
  }
  sendUndoBidNotifications(): void {
    this.messageService.sendMessage(this.project?.customer?.id, BrushNotification.BID_CANCELED_NOTIFICATION_CUSTOMER, this.project.projectDetails.title, getRedirectLinkForProjectType(this.project.projectType).value + '/' + this.project.tag);
    this.messageService.sendMessage(this.tokenStorage.getId(), BrushNotification.BID_CANCELED_NOTIFICATION_PAINTER, this.project.projectDetails.title, this.myBid.tag);
  }
}
