import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
//import {BaseUserInputDataComponent} from '../../../components/MAIN_COMPONENTS/BaseUserInputDataComponent';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-changefpassoword',
  templateUrl: './changefpassoword.component.html',
  styleUrls: ['./changefpassoword.component.scss']
})
export class ChangefpassowordComponent implements OnInit {

  userDataForm: FormGroup
  email: any
  isFormSubmitted = false;

  constructor(private router: Router, private formBuilder: FormBuilder, private authService: AuthService, private toastr: ToastrService, private route: ActivatedRoute) {
    //  super();

    this.userDataForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confpassword:  ['', [Validators.required]],
    }, {
      validator: this.checkPassword,
    });
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.params.email
  }

   checkPassword(group: FormGroup) {
   console.log(group)
    let pass = group.controls.password.value;
    let confirm_password = group.controls.confpassword.value;
    var flag = false
    let returnable: any = {
      pwdNotSame: null,
    }
    console.log( group.controls.password.value,  group.controls.confpassword.value)
    if (pass != confirm_password) {
      returnable.pwdNotSame = true
      flag = true
    }
    console.log(returnable, flag)
    return flag ? returnable : null;
  }

  submitForm(): void {

    const email = this.userDataForm.controls.value;
    let obj = {
      email: this.email,
      new_password: this.userDataForm.controls.password.value
    }
    this.authService.forgotchange(obj).subscribe((response: any) => {
      this.isFormSubmitted = true;
      this.toastr.success('Password changed!', 'Success!');
      this.router.navigate(['/login'])
    },
      (error: any) => {
        this.toastr.success(error.error.errors.msg, 'Error!');
        console.log(error.message);
      }
    );
  }

  goBackToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  onEnterClicked(): void {
    if (this.userDataForm.valid) {
      this.submitForm();
    }
  }
}

