import { Pipe, PipeTransform } from '@angular/core';

@Pipe({  name: 'firstName'})
export class FirstNamePipe implements PipeTransform {

  transform(fullName: string): any {
    if (fullName === null || fullName === undefined) {
      return '';
    }
    return fullName.split(' ')[0];
  }

}
