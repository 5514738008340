import {Component} from '@angular/core';
import {PageableResponse} from '../../model/PageableResponse';
import {SelectInputOption} from '../../model/components/SelectInputOption';

@Component({
    template: ''
})
export abstract class PageableComponent {


  pageSizeOptions: SelectInputOption[] = [
    {optionLabel: 'Results per page: 12', optionValue: '12', optionDisabled: false, optionSelected: true},
    {optionLabel: 'Results per page: 24', optionValue: '24', optionDisabled: false, optionSelected: false},
    {optionLabel: 'Results per page: 48', optionValue: '48', optionDisabled: false, optionSelected: false}
  ];
    pageableResponse!: PageableResponse;
    page = 0;
    from!: number;
    to!: number;

    abstract filter(startFromFirstPage: boolean): void;

  changePageSize(event: any): void {
    this.pageSizeOptions.forEach(item => {
      if (item.optionLabel === event.optionLabel) {
        item.optionSelected = true;
      } else {
        item.optionSelected = false;
      }
    });
    this.filter(true);
  }


    changePageNumber(event: number): void {
    console.log(event)
        this.page = event - 1;
        this.filter(false);
    }

    get selectedPageSize(): any {
      return this.pageSizeOptions.filter(pt => pt.optionSelected)[0];
    }

    pageNavCalc(): void {
        if (this.pageableResponse?.size && this.pageableResponse?.totalElements) {
            this.from = (+this.page) * +this.pageableResponse?.size + +1;
            this.to = (+this.page + +1) * +this.pageableResponse?.size;
            if (this.to > this.pageableResponse?.totalElements) {
                this.to = this.pageableResponse.totalElements;
            }
        }
    }

}
