import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'chatDate'
})
export class ChatDatePipe implements PipeTransform {

  transform(date: string, ...args: unknown[]): unknown {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];    
    var diffDays:any = "";

    const date1:any = new Date(date)
    const date2:any = new Date(moment().format("YYYY-MM-DD"));

    const diffTime = Math.abs(date2 - date1);
    diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    console.log(date1);
    console.log(date2);    

    console.log("date1.getTime() ",date1.getTime());
    console.log("date2.getTime() ",date2.getTime());   


    if(date1.getTime() === date2.getTime()){
      return "Today"
    } else if(diffDays == 1){
      return "Yesterday"
    }else if(diffDays < 3){ // return day name
      return days[date1.getDay()]
    }else{
      return moment(date1).format("YYYY-MM-DD")
    }

  }

}
