<div class="route-title"><span>My Jobs</span>
  <app-notification-bell></app-notification-bell>
</div>

<div class="my-jobs-wrapper">
  <nb-card class="nb-theme-brush card">
    <nb-card-body>
      <nb-card class="nb-theme-brush card user_bids_card myjobs">
        <nb-card-body>

          <nb-tabset #tabset>
            <nb-tab [active]="this.selectedtab == 'one' ? true : false" tabTitle="Awarded({{jobs?.length}})">
              <div class="">
                <div class="projects-wrapper">
                  <div class="card card_my_prjet card" *ngFor="let item of jobs">
                    <div class="div_cd d-flex">
                      <div class="img_nw">
                        <img src="{{item?.images[0]?.image}}" alt="">
                      </div>
                      <div class="div_pntr">
                        <div class="div_hrd d-flex">
                          <div class="div_prject_text">
                            <div class="div_spn">
                              <span class="ned_urgnt title" (click)="yourFunc('one');viewDemoDetailss(item.id)">{{item.project_title}}</span>
                              <div class="iner_degn">
                                <p>{{item.project_type | titlecase}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="ago_tym" *ngIf="item.days_remaining >0">
                            <h1>{{item.days_remaining}} days ago</h1>
                          </div>
                            <div class="ago_tym" *ngIf="item.days_remaining ==0">
                            <h1>Today</h1>
                          </div>
                        </div>
                        <div class="div_descrptin">
                          <p>{{item.description}}</p>
                        </div>
                        <div class="section bottom">
                          <div class="tags">
                            <div class="tag-wrappers">
                              <div class="tag">Awarded</div>
                                                            <div class="tag project-pending">Mark Complete</div>

                            </div>

                          </div>
                        </div>
                        <div painter-perspective class="bid-details-wrapper d-flex justify-content-between mt-100">
                          <div class="d-flex">
                            <div class="view-bid-details" (click)="viewDemoDetailss(item.id)">
                              View Details
                            </div>
                            <nb-icon icon="arrow-forward-outline"></nb-icon>
                          </div>


                          <div class="bidd">
                            <span class="bid-amount-text" icon="arrow-forward-outline">
                              Accepted Bid:
                            </span>
                            <span class="bid-value"> ${{item?.Bids?.bid}}.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>

               <!-- <app-pagination class="pagi">
                </app-pagination>-->
              </div>
            </nb-tab>


            <nb-tab [active]="this.selectedtab == 'two' ? true : false" tabTitle="Completed({{completedjob?.length}})">
              <div class="">
                <div class="projects-wrapper">
                <div class="card card_my_prjet card" *ngFor="let item of completedjob">
                    <div class="div_cd d-flex">
                      <div class="img_nw">
                        <img src="{{item?.images[0]?.image}}" alt="">
                      </div>
                      <div class="div_pntr">
                        <div class="div_hrd d-flex">
                          <div class="div_prject_text">
                            <div class="div_spn">
                              <span class="ned_urgnt title" (click)="yourFunc('two');viewDemoDetailsscomp(item.id, 'completed')">{{item.project_title}}</span>
                              <div class="iner_degn">
                                <p>{{item.project_type | titlecase}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="ago_tym" *ngIf="item.days_remaining >0">
                            <h1>{{item.days_remaining}} days ago</h1>
                          </div>
                            <div class="ago_tym" *ngIf="item.days_remaining ==0">
                            <h1>Today</h1>
                          </div>
                        </div>
                        <div class="div_descrptin">
                          <p>{{item.description}}</p>
                        </div>
                        <div class="section bottom">
                          <div class="tags">
                            <div class="tag-wrappers">
                              <div class="tag">Completed</div>
                               <div class="tag project-pending" *ngIf="!item?.review_detail ||  !item?.review_detail?.painter_id">
                                            Provide Rating
                                        </div>
                                          <div class="  tag review-provided" *ngIf="item?.review_detail?.painter_id">
                                          Review Provided
                                        </div>
                                           <div class="  tag project-complete " >
                                         Project Completed
                                        </div>


                            </div>

                          </div>
                        </div>
                        <div painter-perspective class="bid-details-wrapper d-flex justify-content-between mt-100">
                          <div class="d-flex">
                            <div class="view-bid-details" (click)="viewDemoDetailsscomp(item.id, 'completed')">
                              View Details
                            </div>
                            <nb-icon icon="arrow-forward-outline"></nb-icon>
                          </div>


                          <div class="bidd">
                            <span class="bid-amount-text" icon="arrow-forward-outline">
                              Accepted Bid:
                            </span>
                            <span class="bid-value"> ${{item?.Bids?.bid}}.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <!--<app-pagination class="pagi">
                </app-pagination>-->
              </div>
            </nb-tab>
 <nb-tab [active]="this.selectedtab == 'three' ? true : false" tabTitle="Disputed({{disputed?.length}})">
              <div class="">
                <div class="projects-wrapper">
                <div class="card card_my_prjet card" *ngFor="let item of disputed">
                    <div class="div_cd d-flex">
                      <div class="img_nw">
                        <img src="{{item?.images[0]?.image}}" alt="">
                      </div>
                      <div class="div_pntr">
                        <div class="div_hrd d-flex">
                          <div class="div_prject_text">
                            <div class="div_spn">
                              <span class="ned_urgnt title" (click)="yourFunc('three');viewDemoDetailsscomp(item.id, 'disputed')">{{item.project_title}}</span>
                              <div class="iner_degn">
                                <p>{{item.project_type | titlecase}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="ago_tym" *ngIf="item.days_remaining >0">
                            <h1>{{item.days_remaining}} days ago</h1>
                          </div>
                            <div class="ago_tym" *ngIf="item.days_remaining ==0">
                            <h1>Today</h1>
                          </div>
                        </div>
                        <div class="div_descrptin">
                          <p>{{item.description}}</p>
                        </div>
                        <div class="section bottom">
                          <div class="tags">
                            <div class="tag-wrappers">
                              <div class="tag disputed">Disputed</div>
                               <div class="tag project-pending">See Dispute status</div>
                            </div>

                          </div>
                        </div>
                        <div painter-perspective class="bid-details-wrapper d-flex justify-content-between mt-100">
                          <div class="d-flex">
                            <div class="view-bid-details" (click)="viewDemoDetailsscomp(item.id, 'disputed')">
                              View Details
                            </div>
                            <nb-icon icon="arrow-forward-outline"></nb-icon>
                          </div>


                          <div class="bidd">
                            <span class="bid-amount-text" icon="arrow-forward-outline">
                              Accepted Bid:
                            </span>
                            <span class="bid-value"> ${{item?.Bids?.bid}}.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              <!--  <app-pagination class="pagi">
                </app-pagination>-->
              </div>
            </nb-tab>

         <nb-tab [active]="this.selectedtab == 'four' ? true : false" tabTitle="Archived({{archivedlist?.length}})">
              <div class="">
                <div class="projects-wrapper">
                <div class="card card_my_prjet card" *ngFor="let item of archivedlist">
                    <div class="div_cd d-flex">
                      <div class="img_nw">
                        <img src="{{item?.images[0]?.image}}" alt="">
                      </div>
                      <div class="div_pntr">
                        <div class="div_hrd d-flex">
                          <div class="div_prject_text">
                            <div class="div_spn">
                              <span class="ned_urgnt title" (click)="yourFunc('four');viewDemoDetailsscomp(item.id, 'archived')">{{item.project_title}}</span>
                              <div class="iner_degn">
                                <p>{{item.project_type | titlecase}}</p>
                              </div>
                            </div>
                          </div>
                          <div class="ago_tym" *ngIf="item.days_remaining >0">
                            <h1>{{item.days_remaining}} days ago</h1>
                          </div>
                            <div class="ago_tym" *ngIf="item.days_remaining ==0">
                            <h1>Today</h1>
                          </div>
                        </div>
                        <div class="div_descrptin">
                          <p>{{item.description}}</p>
                        </div>
                        <div class="section bottom">
                          <div class="tags">
                            <div class="tag-wrappers">
                              <div class="tag archived-btn">Archived</div>
                            </div>

                          </div>
                        </div>
                        <div painter-perspective class="bid-details-wrapper d-flex justify-content-between mt-100">
                          <div class="d-flex">
                            <div class="view-bid-details" (click)="viewDemoDetailsscomp(item.id, 'archived')">
                              View Details
                            </div>
                            <nb-icon icon="arrow-forward-outline"></nb-icon>
                          </div>


                          <div class="bidd">
                            <span class="bid-amount-text" icon="arrow-forward-outline">
                              Accepted Bid:
                            </span>
                            <span class="bid-value"> ${{item?.Bids?.bid}}.00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              <!--  <app-pagination class="pagi">
                </app-pagination>-->
              </div>
            </nb-tab>

          </nb-tabset>
        </nb-card-body>
      </nb-card>
    </nb-card-body>
  </nb-card>


</div>
