<div *ngIf="!isFormSubmitted" class="main-box">
  <p class="title margin-b15">Reset password</p>
  <form [formGroup]="userDataForm" class="form margin-b10 forgot_password_wrap">
    <div class="input-wrapper margin-b15 new_text_ic">
      <label><span class="label-text">Email</span></label>
      <span class="icon-wrapper">
      <img
        [src]="validateEmail() ? '/assets/icons/errors/envelope-solid-error.svg' : '/assets/icons/envelope-solid.svg'"
        class="icon"></span>
      <input formControlName="emailAddress" placeholder="Enter your email" email>
      <div class="error-wrp" *ngIf="validateEmail()">
        <img src="assets/icons/errors/error-solid.svg" class="error-icon">
        <div class="error-text" *ngIf="checkForEmailErrors()">{{emailErrorMessage}}</div>
      </div>
    </div>
  </form>
  <button (click)="submitForm()" type="submit" [disabled]="!userDataForm.valid" class="custom-button">Send me the link
  </button>
  <p class="hint-small margin-b10">or <a routerLink="/login" class="hint-color">Sign in </a></p>
</div>

<div *ngIf="isFormSubmitted" class="main-box">
  <div class="title margin-b10">Go to your inbox</div>
  <img src="assets/illustrations/pw-forgot.svg" class="img margin-b10">
  <div class="hint-large">Recovery link sent.</div>
  <div class="hint-small margin-b10">We've sent a recovery link to your email address, which will allow you to reset
    your password.
  </div>
  <button (click)="goBackToLogin()" class="custom-button">Login</button>
</div>
