import {Component, SimpleChanges} from '@angular/core';
import {BrushTableCustomFilterComponent} from '../../../MAIN_COMPONENTS/BrushTableCustomFilterComponent';


@Component({
  selector: 'app-project-type-picker',
  templateUrl: './project-type-picker.component.html',
  styleUrls: ['./project-type-picker.component.scss']
})
export class ProjectTypePickerComponent extends BrushTableCustomFilterComponent {

  constructor() {
    super();
  }
  emit(event: any): void {
    this.filter.emit(event.target.value);
  }

}
