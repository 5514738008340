import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
//import {AuthService} from '../../../services/auth.service';
import {NavigationExtras, Router} from '@angular/router';

@Component({
  selector: 'app-welcome-two',
  templateUrl: './welcome-two.component.html',
  styleUrls: ['./welcome-two.component.scss']
})
export class WelcomeTwoComponent implements OnInit {
  userDataForm!: FormGroup;
  isPainterRegistration: boolean = false
  constructor(private formBuilder: FormBuilder,
             // private authService: AuthService,
              private router: Router) { 
               this.userDataForm = this.formBuilder.group({
        full_name: ['', [Validators.required]],
        business_name : ['']
      });}

  ngOnInit(): void {
  if(localStorage.getItem('role')){
  let a =localStorage.getItem('role')
  if(a=='painter'){
  this.isPainterRegistration = true
  }
 
        
  }

  if(localStorage.getItem('name')){
        this.userDataForm.patchValue({
            'full_name': localStorage.getItem('name'),
            //'password': localStorage.getItem('password')
        })
  }
  }

  submitRegisterForm(){
  localStorage.setItem('name',this.userDataForm.value.full_name)
    localStorage.setItem('businesss_name',this.userDataForm.value.business_name)

        this.router.navigate(['/login/WelcomeThree'])
  }
}
