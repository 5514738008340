<div class="container">
  <div class="logo-wrapper">
    <img src="assets/log.svg" class="logo">
  </div>
    <div class="heading-wrapper">
      <div class="header">Oooooops!</div>
      <div class="claim">The page you were looking for does not exist!</div>
      <div class="subclaim">Let us help you get what you'were looking for! You might want to go to the</div>
      <div class="inner-flex">
        <a class="navigate" [routerLink]="path">{{pathName}} ></a>
        <a class="bckhm" [routerLink]="'/home'">Back home ></a>
      </div>

    </div>
  <img src="assets/error404.svg" class="hero-img">
</div>
