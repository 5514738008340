<app-select-input [label]="orderOptions[0].optionLabel"
                  [inputOptions]="orderOptions"
                  (valueChanged)="changeOrdering($event)"
                  class="select-input">
</app-select-input>
<nb-card class="nb-theme-brush card">
    <nb-card-body>
      <nb-tabset #tabset>
        <nb-tab tabTitle="Accepted" *ngIf=" project?.acceptedBid && project?.acceptedBid != null" #winningTabset>
          <app-bid-card-mini [detailedView]="false" [bid]=" project?.acceptedBid && project?.acceptedBid" [project]="project"></app-bid-card-mini>
        </nb-tab>
        <nb-tab tabTitle="New" *ngIf="project?.acceptedBid && project.acceptedBid == null" #newTabset>
          <app-bid-card-mini [detailedView]="false" *ngFor="let bid of project.newBids" [bid]="bid" [project]="project" (bidAccepted)="navigateToWinningTabset()" (bidStatusChanged)="bidStatusChanged.emit()"></app-bid-card-mini>
        </nb-tab>
        <nb-tab tabTitle="Denied" #deniedTabset>
        <div *ngIf="project?.deniedBids && project?.deniedBids?.length">
          <app-bid-card-mini [detailedView]="false" *ngFor="let bid of project.deniedBids" [bid]="bid" [project]="project"  (bidStatusChanged)="bidStatusChanged.emit()"></app-bid-card-mini>
          </div>
        </nb-tab>
      </nb-tabset>
    </nb-card-body>
 </nb-card>
