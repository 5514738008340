<div class="filters_sec">
    <div class="filters_wrap">
        <nb-card>
            <h4>
                Additional Filters
            </h4>
            <nb-accordion>
                <nb-accordion-item #item>
                  <nb-accordion-item-header>
                    Project Type
                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    <div class="content_wrap">
                        <div class="checkbox">
                            <app-checkbox-input></app-checkbox-input>
                            <p>Interior</p>
                        </div>
                        <div class="checkbox">
                            <app-checkbox-input></app-checkbox-input>
                            <p>Interior</p>
                        </div>
                        <div class="checkbox">
                            <app-checkbox-input></app-checkbox-input>
                            <p>Interior</p>
                        </div>
                    </div>
                  </nb-accordion-item-body>
                </nb-accordion-item>
          
                <nb-accordion-item>
                  <nb-accordion-item-header>
                    Surface Area
                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    22222222222222222
                  </nb-accordion-item-body>
                </nb-accordion-item>
          
                <nb-accordion-item>
                  <nb-accordion-item-header>
                    Surface Condition
                  </nb-accordion-item-header>
                  <nb-accordion-item-body>
                    333333333333333333
                  </nb-accordion-item-body>
                </nb-accordion-item>

                <nb-accordion-item>
                    <nb-accordion-item-header>
                        Tags
                    </nb-accordion-item-header>
                    <nb-accordion-item-body>
                      333333333333333333
                    </nb-accordion-item-body>
                  </nb-accordion-item>
              </nb-accordion>
              <div class="text-center">
                  <button class="custom-button">Filter</button>
              </div>

        </nb-card>
    </div>
</div>