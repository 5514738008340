import {ChangeDetectorRef, Component} from '@angular/core';
import {ServerDataSource} from 'ng2-smart-table';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BaseTableViewComponent} from '../../MAIN_COMPONENTS/BaseTableViewComponent';
import {TableActionsComponent} from '../../table-actions/table-actions.component';
import {LastDaysPickerComponent} from '../custom-filters/last-days-picker/last-days-picker.component';
import {EnumPipe} from '../../../pipes/enum.pipe';
import {EReportStatus} from '../../../model/enums/EReportStatus';
import {ReportStatusPickerComponent} from '../custom-filters/report-status-picker/report-status-picker.component';
import {ERole} from '../../../model/enums/ERole';
import {Router} from '@angular/router';
import {AdminService} from '../../../services/admin.service';

@Component({
  selector: 'app-brush-table-reports',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss'],
  providers: [EnumPipe]
})
export class ReportsTableComponent extends BaseTableViewComponent{
  acceptReportModalVisible = false;
  rejectReportModalVisible = false;
  EReportStatus = EReportStatus;
  modifiedReportId!: any;


  constructor(private adminService: AdminService, private router: Router, private enumPipe: EnumPipe, private httpClient: HttpClient, private cdRef: ChangeDetectorRef) {
    super();
    this.source = new ServerDataSource(httpClient, {
      endPoint: environment.SERVER_BASE_URL + 'admin/reports',
      dataKey: 'content',
      totalKey: 'totalElements',
      pagerLimitKey: 'pageSize',
      pagerPageKey: 'page',
      sortFieldKey: 'sort',
      sortDirKey: 'sortDir',
      filterFieldKey: 'search_#field#'
    });
    this.source.onChanged().subscribe(change => {
      this.totalElements = this.source.count();
      this.cdRef.detectChanges();
    });
  }

  settings = {
    rowClassFunction: (row: any) => {
      if (row.data.status === EReportStatus.NEW) {
        return 'neutral';
      } else if (row.data.status === EReportStatus.REJECTED) {
        return 'danger';
      } else if (row.data.status === EReportStatus.ACCEPTED) {
        return 'success';
      }
      return '';
    },
    mode: 'external',
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize
    },
    noDataMessage: this.noDataMessage,
    columns: {
      actions: {
        title: 'Actions',
        sort: false,
        filter: false,

        type: 'custom',
        renderComponent: TableActionsComponent,
        onComponentInitFunction: (rc: any) => {
          this.totalElements = this.source.count();
          this.cdRef.detectChanges();
          rc.addAction(
            {icon: 'eye', pack: 'fas', tip: 'View Case'}).subscribe((x: any) => {
            this.router.navigate([rc.rowData.relatedID]);
          });
          rc.addAction(
            {icon: 'circle-check', pack: 'fas', tip: 'Accept', cond: (x: any) => rc.rowData?.status === EReportStatus.NEW }).subscribe((x: any) => {
            this.acceptReportModalVisible = true;
            this.modifiedReportId = rc.rowData.reportId;
          });
          rc.addAction(
            {icon: 'circle-xmark', pack: 'fas', tip: 'Reject', cond: (x: any) => rc.rowData?.status === EReportStatus.NEW }).subscribe((x: any) => {
            this.rejectReportModalVisible = true;
            this.modifiedReportId = rc.rowData.reportId;
          });
      },
        width: '15vw'
      },
      status: {
        title: 'Report status',
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.status, EReportStatus),
        filter: {
          type: 'custom',
          component: ReportStatusPickerComponent,
        }
      },
      reportCase: {
        title: 'Case',
        sort: false,
        filter: false,
      },
      userPostedReportRole: {
        title: 'Reporter role',
        sort: false,
        filter: false,
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.userPostedReportRole, ERole, true, true),
      },
      userPostedReportID: {
        sort: false,
        title: 'Reporter ID',
      },
      userBeingReportedRole: {
        title: 'Reported role',
        sort: false,
        filter: false,
        valuePrepareFunction: (c: any, x: any) => this.enumPipe.transform(x.userBeingReportedRole, ERole, true, true ),
      },
  userBeingReportedID: {
    title: 'User reported ID',
    sort: false,
  },
      postedDate: {
        title: 'Posted date',
        filter: {
          type: 'custom',
          component: LastDaysPickerComponent,
        }
      },
    }
  };

  changeReportStatus(status: EReportStatus): void {
    this.adminService.updateReportStatus(this.modifiedReportId, status).subscribe(res => {
      this.source.refresh();
      this.rejectReportModalVisible = false;
      this.acceptReportModalVisible = false;
    });
  }
}

