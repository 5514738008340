import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ProjectService} from '../../../../services/project.service';
import {PageableResponse} from '../../../../model/PageableResponse';
import {BrushProjectsViewComponent} from '../../../../components/MAIN_COMPONENTS/BrushProjectsViewComponent';
import {EProjectType, getRedirectLinkForProjectType} from '../../../../model/enums/EProjectType';
import {EProjectStatus} from '../../../../model/enums/EProjectStatus';
import {BrushNotification} from '../../../../services/notification-service';
import {MessageService} from '../../../../services/message.service';
import {TokenStorageService} from '../../../../services/token-storage.service';
import {PainterService} from '../../../../services/painter.service';
import {Observable} from 'rxjs';

export interface ChangeStatusData {
  projectTag: string;
  status: number;
  title: string;
  projectType: EProjectType;
}

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects.component.html',
  styleUrls: ['./my-projects.component.scss']
})
export class MyProjectsComponent extends BrushProjectsViewComponent implements OnInit {

  nothingToDisplay = false;
  pageableResponse!: PageableResponse;
  projectsInDraftPageableResponse!: any;
  projectsAwaitingPageableResponse!: any;
  projectsPendingPageableResponse!: any;
  projectsInProgressPageableResponse!: any;
  projectsCompletedPageableResponse!: any;
  projectsComplainPageableResponse!: any;
  projectsArchivedPageableResponse!: any;
  activeStatus = EProjectStatus.PENDING;

  constructor(private painterService: PainterService, private tokenStorage: TokenStorageService, private messageService: MessageService, private router: Router, private projectService: ProjectService) {
    super();
  }

  ngOnInit(): void {
    this.loadProjects();
  }

  changeProjectStatus(changeData: ChangeStatusData): void {
    this.projectService.changeProjectStatus(changeData.projectTag, EProjectStatus[changeData.status]).subscribe(res => {
      this.loadProjects();
    });
  }

  showProjectDetails(project: any): void {
    this.router.navigate(['/home/my-projects/details/' + getRedirectLinkForProjectType(project.projectType).value + '/' + project.tag]);
  }

  showInMarketplace(project: any): void {
    this.router.navigate(['home/marketplace/project-marketplace-details/' + getRedirectLinkForProjectType(project.projectType).value + '/' + project.tag]);
  }

  createNewProject(): void {
    this.router.navigate(['/home/my-projects/new-project']);
  }

  filter(startFromFirstPage: boolean): void {
    if (startFromFirstPage) {
      this.page = 0;
    }
    this.loadProjects();
  }
  getProjects(status: any): Observable<any> {
    return this.projectService.getAllProjectForCustomer(this.page, this.selectedPageSize.optionValue, this.selectedOrdering.optionValue, this.selectedOrdering.sortDir, EProjectStatus[status], this.selectedProjectTypes);
  }

  onChangeTab(event: any): void {
    const tabTitle = event.tabTitle.toLowerCase();
    if (tabTitle.includes('pending')) {
      this.activeStatus = EProjectStatus.PENDING;
    } else if (tabTitle.includes('awaiting')) {
      this.activeStatus = EProjectStatus.AWAITING;
    } else if (tabTitle.includes('in progress')) {
      this.activeStatus = EProjectStatus.IN_PROGRESS;
    } else if (tabTitle.includes('completed')) {
      this.activeStatus = EProjectStatus.COMPLETED;
    } else if (tabTitle.includes('archived')) {
      this.activeStatus = EProjectStatus.ARCHIVED;
    } else if (tabTitle.includes('complain')) {
      this.activeStatus = EProjectStatus.COMPLAIN;
    } else if (tabTitle.includes('in draft')) {
      this.activeStatus = EProjectStatus.IN_DRAFT;
    }
    this.filter(true);
  }

  loadProjects(): void {
    this.getProjects(EProjectStatus.PENDING).subscribe(res => {
      this.projectsPendingPageableResponse = res;
      this.pageableResponse = res;
    });
    this.getProjects(EProjectStatus.IN_PROGRESS).subscribe(res => {
      this.projectsInProgressPageableResponse = res;
    });
    this.getProjects(EProjectStatus.AWAITING).subscribe(res => {
      this.projectsAwaitingPageableResponse = res;
    });
    this.getProjects(EProjectStatus.COMPLETED).subscribe(res => {
      this.projectsCompletedPageableResponse = res;
    });
    this.getProjects(EProjectStatus.ARCHIVED).subscribe(res => {
      this.projectsArchivedPageableResponse = res;
    });
    this.getProjects(EProjectStatus.COMPLAIN).subscribe(res => {
      this.projectsComplainPageableResponse = res;
    });
    this.getProjects(EProjectStatus.IN_DRAFT).subscribe(res => {
      this.projectsInDraftPageableResponse = res;
    });
  }
}

