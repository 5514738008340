import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { TokenStorageService } from '../../services/token-storage.service';
import { NavigationExtras, Router } from '@angular/router';
import { EConfirmationType } from '../../model/enums/EConfirmationType';
import { BaseUserInputDataComponent } from '../../components/MAIN_COMPONENTS/BaseUserInputDataComponent';
import { UserService } from '../../services/user.service';
import { ESettingKey } from '../../model/enums/ESettingKey';
// import { getMessaging } from "firebase/messaging";
import firebase from 'firebase/app'

//import { FacebookAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { ToastrService } from 'ngx-toastr';

// import {SocialAuthService, SocialUser, GoogleLoginProvider} from  "angularx-social-login"
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent extends BaseUserInputDataComponent implements OnInit {
  // firebaseConfig = {
  //   apiKey: "AIzaSyDzll3QmY-LdKBHuzVDvWlasQARvR64EDA",
  //   // apiKey:"AIzaSyCIQe4HGD-8XKBe-JtKj0IJsjeegtirMtk",
  //   authDomain: "facebook-clone-f4994.firebaseapp.com",
  //   projectId: "facebook-clone-f4994",
  //   storageBucket: "facebook-clone-f4994.appspot.com",
  //   messagingSenderId: "593047789391",
  //   appId: "1:593047789391:web:11459d7b291b9465542f3a",
  //   measurementId: "G-FNW1K23DBJ",
  //       vapidKey:'BFHWMBE7EV4n9XeVOhIWbEOFUoG6NvFE7Nry3rC1PnNO79rpo9sJDtoMOSUD08l9Ah2JFUEPSuTyOHgGYNyliyU'

  //  };


  firebaseConfig = {
    apiKey: "AIzaSyCIQe4HGD-8XKBe-JtKj0IJsjeegtirMtk",
    authDomain: "brush-exchange-387305.firebaseapp.com",
    projectId: "brush-exchange-387305",
    storageBucket: "brush-exchange-387305.appspot.com",
    messagingSenderId: "695853230855",
    appId: "1:695853230855:web:4401fbd8745f242e01f3a2",
    measurementId: "G-3XJDH1X02D"
  };

  newcheck: boolean = true

  firebaseApp: any;
  // user !: SocialUser
  messaging = firebase.messaging;
  rem_me: boolean = true
  stay_log: any
  userDataForm = this.formBuilder.group({
    emailAddress: ['', [Validators.required]],
    password: ['', Validators.required],
    checked: ['']
    //  rememeber_me :[]
  });

  @Input() checked = true;
  @Input() label!: string;
  @Input() index!: string;

  onChange: any = () => { };
  onTouch: any = () => { };


  constructor(private afAuth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    // private a: SocialAuthService,

    private tokenStorage: TokenStorageService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService) {
    super();
  }

  ngOnInit(): void {
    console.log(this.newcheck, "jkfjkds")
    // this.a.authState.subscribe((user: any)=>{
    //       this.user= user
    // })
    if (firebase.apps.length === 0) {
     // firebase.initializeApp({});
      this.firebaseApp = firebase.initializeApp(this.firebaseConfig);
    }
   
    //  this.getToken();

  }

  getToken() {

    console.log("this.messaging: ", this.messaging)

    const fcmtoken = this.messaging()
      .getToken()
      .then((mainToken) => {
        return mainToken;
      });

    fcmtoken.then((token) => console.log('fcmtoken:::', token)).catch(err => {
      console.error("Get Token: ", err)
    });

  }

  updateCheckbox() {
    console.log(this.newcheck);

  }

  onModelChange(e: any): void {
    this.checked = e;
    this.onChange(e);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: any): void {
    this.checked = checked;
  }

  submitLoginForm(): any {
    // localStorage.setItem('rwm',this.newcheck)
    const email = this.emailAddress.value;
    const password = this.password.value;
    this.authService.login(email, password).subscribe(
      data => {
        console.log(data);
        if (data.user) {  //success
          // if (this.loginForm.controls['remember_me'].value) {
          //   localStorage.setItem("user_details", JSON.stringify(res.user))
          //   localStorage.setItem("token", res.token)
          //   localStorage.setItem("remember_me", "yes")
          // } else {
          //   sessionStorage.setItem("user_details", JSON.stringify(res.user))
          //   sessionStorage.setItem("token", res.token)
          //   localStorage.setItem('token', res.token)
          //   localStorage.setItem("remember_me", "no")
          // }

          // this.service.setCookies({
          //   key: 'role',
          //   value: res.user.role
          // })
          // if (this.loginForm.controls['remember_me'].value) { 
          if (true) { // disable rememeber feature for now
            localStorage.setItem("user_details", JSON.stringify(data.user))
            localStorage.setItem("token", data.token)
            localStorage.setItem("remember_me", "yes")
            localStorage.setItem("role", data.user.role)
            //  sessionStorage.setItem("user_details", JSON.stringify(res.user))
            sessionStorage.setItem("token", data.token)

          } else {
            sessionStorage.setItem("user_details", JSON.stringify(data.user))
            sessionStorage.setItem("role", data.user.role)

            sessionStorage.setItem("token", data.token)
            localStorage.setItem('token', data.token)

            localStorage.setItem("remember_me", "no")
          }

          if (data.user.role == 'customer') {
            this.router.navigate(['/home/my-project-card'])
          } else {
            this.router.navigate(['/home/marketplace'])
          }

        }
        // this.tokenStorage.saveEmail(data.email);
        // this.tokenStorage.saveProfileImage(data.profileImage);
        // this.tokenStorage.saveUser(data);
        // this.tokenStorage.saveToken(data.token, false); // for request below only
        //  this.userService.getAccountSettingValue(ESettingKey.STAY_SIGNED_IN).subscribe(res => {
        //   this.tokenStorage.saveToken(data.token, res);
        // });


        this.toastr.success('Logged in!', 'Success!');
        this.tokenStorage.doDefaultRouting();

      },
      error => {
        console.log(error);
        if (error.status === 0) {
          this.toastr.error('Unable to connect server', 'Error!');
        } else
          if (error.status === 401) { // bad credentials
            this.emailAddress.setErrors({ invalidCredentials: true });
            this.password.setErrors({ invalidCredentials: true });
            this.loginFormChanges();
          }
          else if (error.status === 403) { // account is not active
            console.log(error.error);
            this.navigateToConfirmAccountPage(error);
          }
          else if (error.status === 409) { // account is not active
            this.toastr.error('Wrong password', 'Error!');

          }
          else if (error.status === 422) { // account is not active
            this.toastr.error('User Does Not Exist', 'Error!');

          }
        console.log(error);
      }
    );
  }
  loginFormChanges(): void {
    const sub = this.userDataForm.valueChanges.subscribe(() => {
      if (this.emailAddress.errors?.invalidCredentials || this.password.errors?.invalidCredentials) {
        this.emailAddress.updateValueAndValidity({ emitEvent: false });
        this.password.updateValueAndValidity({ emitEvent: false });
      }
      sub.unsubscribe();
    });
  }

  navigateToConfirmAccountPage(error: any): void {
    let navigationExtras: NavigationExtras;
    if (error.error?.extraData?.hasOwnProperty(EConfirmationType.PHONE)) { //painter
      navigationExtras = {
        queryParams: {
          confirmationType: EConfirmationType.PHONE,
          confirmationParam: error.error.extraData.phone
        }
      };
      this.router.navigate(['register/confirm'], navigationExtras);
    }
    else { //customer
      navigationExtras = {
        queryParams: {
          confirmationType: EConfirmationType.EMAIL,
          confirmationParam: this.emailAddress.value
        }
      };
      this.router.navigate(['register/confirm'], navigationExtras);
    }
  }

  onEnterClicked(): void {
    if (this.userDataForm.valid) {
      this.submitLoginForm();
    }
  }


  async signInWithGoogle() {
    const provider = new auth.GoogleAuthProvider();
    if (localStorage.getItem('role') == "painter") {
      const result: any = await this.afAuth.signInWithPopup(provider);
      if (result) {
        console.log(result, "ddd");
        let credential = result.credential;
        let user = result.user;
        let profile = result.additionalUserInfo.profile
        let token = credential.accessToken;
        let idToken = credential.idToken;
        let multiFactorUser = user.multiFactor;
        let userObj = {
          idToken: idToken,
          accessToken: token,
          email: profile.email,
          //  email:profile.email,
          social_id: user.uid,
          social_type: "google",
          first_name: profile.given_name,
          last_name: profile.family_name,
          allowed_type: "",
          role: 'painter',
        };
        console.log(userObj, "userobj");
        if (sessionStorage["allowedType"]) {
          userObj.allowed_type = sessionStorage["allowedType"];
        }
        this.authService.Sociallogin(userObj).subscribe(
          (res: any) => {

            if(res?.response?.user){
              localStorage['token'] = res.token
              // localStorage['userData'] = JSON.stringify(res.user)
              // localStorage['remember_me'] = "yes"
              console.log("response",res.response);
              localStorage.setItem("trylogResonlogin", JSON.stringify(res))
              sessionStorage.setItem("user_details", JSON.stringify(res.response.user))
              sessionStorage.setItem("role", res.response.user.role)

              sessionStorage.setItem("token", res.response.token)
              localStorage.setItem('token', res.response.token)
              localStorage.setItem("remember_me", "no")
              localStorage.setItem("role", res.response.user.role)
              localStorage.setItem('loginstatus', res.status)
              localStorage.setItem('loginbysocial', 'true')

                if (res.response.user.role == 'customer') {
                  this.router.navigate(['/home/my-project-card'])
                } else {
                  this.router.navigate(['/home/marketplace'])
                }

            }else{                                                                             localStorage['token'] = res.token
            // localStorage['userData'] = JSON.stringify(res.user)
            // localStorage['remember_me'] = "yes"
            console.log("response",res);
            localStorage.setItem("trylogResonlogin", JSON.stringify(res))
            sessionStorage.setItem("user_details", JSON.stringify(res.user))
            sessionStorage.setItem("role", res.user.role)

            sessionStorage.setItem("token", res.token)
            localStorage.setItem('token', res.token)
            localStorage.setItem("remember_me", "no")
            localStorage.setItem("role", res.user.role)
            localStorage.setItem('loginstatus', res.status)
            localStorage.setItem('loginbysocial', 'true')

            if (res.user.role == 'customer') {
              this.router.navigate(['/home/my-project-card'])
            } else {
              this.router.navigate(['/home/marketplace'])
            }
              }

            

          }, error => {
            console.log(error);
            if (error.status === 0) {
              this.toastr.error('Unable to connect server', 'Error!');
            } else
              if (error.status === 401) { // bad credentials
                this.emailAddress.setErrors({ invalidCredentials: true });
                this.password.setErrors({ invalidCredentials: true });
                this.loginFormChanges();
              }
              else if (error.status === 403) { // account is not active
                console.log(error.error);
                this.navigateToConfirmAccountPage(error);
              }
              else if (error.status === 409) { // account is not active
                this.toastr.error('Wrong password', 'Error!');

              }
              else if (error.status === 422) { // account is not active
                this.toastr.error('Your email address already exists.');

              }
            console.log(error);
          });


      }
    }
     else {      
        const result: any = await this.afAuth.signInWithPopup(provider);
        if (result) {
          console.log(result, "ddd");
          let credential = result.credential;
          let user = result.user;
          let profile = result.additionalUserInfo.profile
          let token = credential.accessToken;
          let idToken = credential.idToken;
          let multiFactorUser = user.multiFactor;
          let userObj = {
            idToken: idToken,
            accessToken: token,
            email: profile.email,
            //  email:profile.email,
            social_id: user.uid,
            social_type: "google",
            first_name: profile.given_name,
            last_name: profile.family_name,
            allowed_type: "",
            role: 'customer',
          };
          if (sessionStorage["allowedType"]) {
            userObj.allowed_type = sessionStorage["allowedType"];
          }
          this.authService.Sociallogin(userObj).subscribe(
            (res: any) => {

            if(res?.response?.user){
            localStorage['token'] = res.response.token
            // localStorage['userData'] = JSON.stringify(res.user)
            // localStorage['remember_me'] = "yes"
            console.log("response",res.response);
            localStorage.setItem("trylogResonlogin", JSON.stringify(res))
            sessionStorage.setItem("user_details", JSON.stringify(res.response.user))
            sessionStorage.setItem("role", res.response.user.role)
            sessionStorage.setItem("token", res.response.token)
            localStorage.setItem('token', res.response.token)
            localStorage.setItem("remember_me", "no")
            localStorage.setItem("role", res.response.user.role)
            localStorage.setItem('loginstatus', res.status)
            localStorage.setItem('loginbysocial', 'true')

               if (res.response.user.role == 'customer') {
              this.router.navigate(['/home/my-project-card'])
            } else {
              this.router.navigate(['/home/marketplace'])
            }
              }else{
              localStorage['token'] = res.token
            // localStorage['userData'] = JSON.stringify(res.user)
            // localStorage['remember_me'] = "yes"
            console.log("response",res);
            localStorage.setItem("trylogResonlogin", JSON.stringify(res))
            sessionStorage.setItem("user_details", JSON.stringify(res.user))
            sessionStorage.setItem("role", res.user.role)

            sessionStorage.setItem("token", res.token)
            localStorage.setItem('token', res.token)
            localStorage.setItem("remember_me", "no")
            localStorage.setItem("role", res.user.role)
                                    localStorage.setItem('loginstatus', res.status)
              localStorage.setItem('loginbysocial', 'true')
               if (res.user.role == 'customer') {
              this.router.navigate(['/home/my-project-card'])
            } else {
              this.router.navigate(['/home/marketplace'])
            }
         }

           

          }, error => {
            console.log(error);
            if (error.status === 0) {
              this.toastr.error('Unable to connect server', 'Error!');
            } else
              if (error.status === 401) { // bad credentials
                this.emailAddress.setErrors({ invalidCredentials: true });
                this.password.setErrors({ invalidCredentials: true });
                this.loginFormChanges();
              }
              else if (error.status === 403) { // account is not active
                console.log(error.error);
                this.navigateToConfirmAccountPage(error);
              }
              else if (error.status === 409) { // account is not active
                this.toastr.error('Wrong password', 'Error!');
    
              }
              else if (error.status === 422) { // account is not active
                this.toastr.error('Your email address already exists.');
    
              }
            console.log(error);
          });
        
      } 
    }
  }

  async signInWithFacebook() {
    const provider = new auth.FacebookAuthProvider();
    try {
      const result: any = await this.afAuth.signInWithPopup(provider);
      console.log('JGHHG', result)
    } catch (error) {
      console.log("gg", error)
    }
  }
}
