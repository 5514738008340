import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BrushSpinnerService} from '../../services/brush-spinner.service';

@Component({
  selector: 'app-brush-spinner',
  templateUrl: './brush-spinner.component.html',
  styleUrls: ['./brush-spinner.component.scss']
})
export class BrushSpinnerComponent implements OnInit {
  showSpinner = false;
  constructor(private spinnerService: BrushSpinnerService, private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.init();
  }
  init(): void {
    this.spinnerService.getSpinnerObserver().subscribe((status) => {
      this.showSpinner = status === 'start';
      this.cdRef.detectChanges();
    });
  }
}
