<div class="smooth-scroll">
    <app-header [userTheme]="true"></app-header>
  
   <section class="blog_sec">
    <div class="terms_content">
        <h2>
            Blogs
        </h2>    
        <div class="blogs_cards">
            <div class="row">
                <div class="col-md-4" *ngFor="let item of data">
                    <div class="blogsngl_crd">
                        <a href="#">
                            <div class="blog_img">
                                <img [src]="item?.media[0]?.source_url" class="error-icon side_img">
                            <span class="blgimg_overlay"></span>
                            </div>
                            
                        </a>
                        <div class="blog_content">
                            <h4 class="blog-title">
                                <a>
                                    {{item.title?.rendered}}
                                </a>
                            </h4>
                            <p class="blg_dsc" [innerHTML]="(item?.content?.rendered | slice:0:100 )+'...'">
    
                            </p>
                            <a class="cntnue_link" (click)="onNavigate(item.link)">Continue Reading <span><i class="fa fa-angle-right"></i></span></a>
                        </div>
                    </div>
                    
                </div>
              <!--  <div class="col-md-4">
                    <div class="blogsngl_crd">
                        <a href="#">
                            <div class="blog_img">
                                <img src="assets/imgs/dummy-blogimg.png" class="error-icon side_img">
                            <span class="blgimg_overlay"></span>
                            </div>
                            
                        </a>
                        <div class="blog_content">
                            <h4 class="blog-title">
                                <a>
                                    Taste-safe sensory nulla dignissim
                                </a>
                            </h4>
                            <p class="blg_dsc">Consectetur enim viverra etiam semper interdum amet faucibus gravida bibendum nisl orci adipiscing ut in tristique diam bibendum turpis in nec nisi amet, ac sit adipiscing egestas gravida accumsan elit…
    
                            </p>
                            <a class="cntnue_link">Continue Reading <span><i class="fa fa-angle-right"></i></span></a>
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-4">
                    <div class="blogsngl_crd">
                        <a href="#">
                            <div class="blog_img">
                                <img src="assets/blog1.jpg" class="error-icon side_img">
                            <span class="blgimg_overlay"></span>
                            </div>
                            
                        </a>
                        <div class="blog_content">
                            <h4 class="blog-title">
                                <a>
                                    Taste-safe sensory nulla dignissim
                                </a>
                            </h4>
                            <p class="blg_dsc">Consectetur enim viverra etiam semper interdum amet faucibus gravida bibendum nisl orci adipiscing ut in tristique diam bibendum turpis in nec nisi amet, ac sit adipiscing egestas gravida accumsan elit…
    
                            </p>
                            <a class="cntnue_link">Continue Reading <span><i class="fa fa-angle-right"></i></span></a>
                        </div>
                    </div>
                    
                </div>-->
            </div>
        </div>
    </div>
   </section>
   <section>
        <div class="contact_from_sec">
            <div class="request_quote">
                <h1>Contact With Us</h1>
                <form class="form margin-b10">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-wrapper">
                                <div class="div_eml div_incs"> 
                                  
                                  <input class="email" placeholder="Name">
                                </div>
                              </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-wrapper">
                                <div class="div_incs">
                                  <input type="email" class="password" placeholder="Email">
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="input-wrapper">
                                <div class="div_eml div_incs"> 
                                  
                                  <input class="text" placeholder="Subject">
                                </div>
                              </div>
                        </div>
                        <div class="col-md-6">
                            <div class="input-wrapper">
                                <div class="div_incs">
                                  <input type="text" class="password" placeholder="Phone No.">
                                </div>
                              </div>
                        </div>
                    </div>
                    
                    <a class="button_theme">SEND</a>
                  </form>
            </div>
        </div>
   </section>
    <app-footer></app-footer>
  </div>
  