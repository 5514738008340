import {Pipe, PipeTransform} from '@angular/core';
import {AccountSettings} from '../model/AccountSettings';
import {ESettingKey} from '../model/enums/ESettingKey';

@Pipe({name: 'accountSettings'})
export class AccountSettingValuePipe implements PipeTransform {
  // format HH:mm:ss YYYY-MM-dd
  transform(settings: AccountSettings[], settingKey: ESettingKey): boolean {
    const tempSettings = settings.filter(val => val.settingKey === ESettingKey[settingKey]);
    if (tempSettings != null && tempSettings.length > 0) {
      return tempSettings[0].value;
    }
    return false;
  }

}
