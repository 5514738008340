import {AfterViewInit, ChangeDetectorRef, Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServerDataSource} from 'ng2-smart-table';
import {environment} from '../../../../environments/environment';
import {BaseTableViewComponent} from '../../MAIN_COMPONENTS/BaseTableViewComponent';
import {TableActionsComponent} from '../../table-actions/table-actions.component';
import {RolePickerComponent} from '../custom-filters/role-picker-component/role-picker.component';
import {LastDaysPickerComponent} from '../custom-filters/last-days-picker/last-days-picker.component';
import {StatePickerComponent} from '../custom-filters/state-picker/state-picker.component';
import {AdminService} from '../../../services/admin.service';
import {FormBuilder} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-brush-hr-by-state',
  templateUrl: './hr-by-state-table.component.html',
  styleUrls: ['./hr-by-state-table.component.scss']
})
export class HrByStateTableComponent extends BaseTableViewComponent{
  changePropertyModalVisible = false;
  selectedProperty: any;

  constructor(private adminService: AdminService, private httpClient: HttpClient, private cdRef: ChangeDetectorRef, private toastrService: ToastrService) {
    super();
    this.source = new ServerDataSource(httpClient, {
      endPoint: environment.SERVER_BASE_URL + 'admin/hr-by-state',
      dataKey: 'content',
      totalKey: 'totalElements',
      pagerLimitKey: 'pageSize',
      pagerPageKey: 'page',
      sortFieldKey: 'sort',
      sortDirKey: 'sortDir',
      filterFieldKey: 'search_#field#'
    });
    this.source.onChanged().subscribe(change => {
      this.totalElements = this.source.count();
      this.cdRef.detectChanges();
    });
  }
  settings = {
    mode: 'external',
    actions: {
      delete: false,
      edit: false,
      add: false,
    },
    pager: {
      display: true,
      perPage: this.pageSize
    },
    noDataMessage: this.noDataMessage,
    columns: {
      actions: {
        title: 'Actions',
        sort: false,
        filter: false,

        type: 'custom',
        renderComponent: TableActionsComponent,
        onComponentInitFunction: (rc: any) => {

          rc.addAction(
            {icon: 'edit', pack: 'fas',  tip: 'Edit'}).subscribe((x: any) => {
            this.selectedProperty = rc.rowData;
            this.changePropertyModalVisible = true;
          });
        },
        width: '50px'
      },
      areaTitle: {
        title: 'State',
        filter: {
          type: 'custom',
          component: StatePickerComponent,
        }
      },
      hmean: {
        filter: false,
        title: 'hMean'
      },
      noOfPaintersFromState: {
        filter: false,
        sort: false,
        title: 'Painters from given state'
      },
      noOfCustomersFromState: {
        filter: false,
        sort: false,
        title: 'Customers from given state'
      }
    }
  };
  hideModal(): void {
    this.changePropertyModalVisible = false;
  }
  changeProperty(newHMean: any): void {
    this.adminService.changeHrByState(this.selectedProperty.hrByStateId, newHMean).subscribe(res => {
      this.hideModal();
      this.toastrService.success('Updated value for ' + this.selectedProperty.areaTitle, 'Success');
      this.source.refresh();
    }, error => {
      this.toastrService.success(error.message, 'Failure');
    });
  }
}

