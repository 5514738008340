import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  faqData:any;
  searchForm!:FormGroup
  searchData: any;
  constructor(private authservice:AuthService, private fb:FormBuilder) {
    this.searchForm=this.fb.group({
      search:[]
    });
    this.searchForm.controls['search'].valueChanges
    .pipe(debounceTime(500)).subscribe(res=>{
      this.applyFilter(res);
    })
   }

  ngOnInit(): void {
    this.getFaq()
  }
  applyFilter(value: any) {
    this.searchData = value;
    this.getFaq()
  }
  // faqItems = [
  //   { question: 'What is Lorem Ipsum?', answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', active: false },
  //   { question: 'How can I contact support?', answer: 'You can contact our support team by emailing support@example.com.', active: false },
  //   { question: 'Is this service free?', answer: 'Yes, our basic service is free to use. However, we also offer premium features for a fee.', active: false },
  //   // Add more FAQ items as needed
  // ];

  toggleAccordion(index: number) {
    this.faqData.forEach((item:any, i:any) => {
      if (i !== index) {
        item.active = false; // Close other accordions
      }
    });

    this.faqData[index].active = !this.faqData[index].active;
  }

  getFaq() {
    const data={
      search:this.searchData ?? ''
    }
    this.authservice.getFaq(data).subscribe(res => {
      res.list.map((item: any) => {
        item.active = false;
      });
      console.log(res.list,'filter');
      this.faqData=res.list
    })
  }
}
