import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

data: any ={}
  constructor(  private authService: AuthService,) { }

  ngOnInit(): void {
  this.getprofile();
  }

  onNavigate(item : any){
    window.open(item, "_blank");
}
  
   getprofile(){
   let obj={}
   let param= '100&offset=0'
		 this.authService.getblogs(param).subscribe((response : any) => {
         this.data= response.response
         
        // this.previewImage2 = this.path + this.profile.profile_image
      //     this.selectedFile2 =  this.profile.profile_image
        // console.log(this.profileForm)
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }
}

