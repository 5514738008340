import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EProjectStatus } from '../../../../../model/enums/EProjectStatus';
import { ProjectService } from '../../../../../services/project.service';
import { BidsService } from '../../../../../services/bids.service';
import { getRedirectLinkForProjectType } from '../../../../../model/enums/EProjectType';
import { SimpleDropDownComponentItem } from '../../../../../model/components/SimpleDropDownComponentItem';
import { ComplainService } from '../../../../../services/complain.service';
import { Complain } from '../../../../../model/Complain';
import { EComplainReason } from '../../../../../model/enums/EComplainReason';
import { HttpserviceService } from '../../../../../services/httpservice.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-completed-job-details-new',
  templateUrl: './completed-job-details-new.component.html',
  styleUrls: ['./completed-job-details-new.component.scss']
})

export class CompletedJobDetailsNewComponent implements OnInit {

   options:any= [{'label':'I cant continue my work'},{'label':'No responses from customer'},{'label':'I have not received payment'},{'label':'Job does not match description'}]

  project!: any;
  myBid!: any;
  option: any
  makeComplainDialogVisible1dispute: boolean= false
   projectDetails: any = {}
 // reporting_reason: any = []
  id: any
    starRating: any;
     currentRate:any = 0;
     makeComplainDialogVisible1: boolean= false
   //  makeComplainDialogVisible :
  complaint: any
  EProjectStatus = EProjectStatus;
  EComplainReason = EComplainReason;
  markAsCompletedModalVisible = false;
  makeComplainDialogVisible = false;
  selectedComplainReason!: EComplainReason;
  complainReasons!: SimpleDropDownComponentItem[];
  complain!: Complain;
    feedBackform: any


  constructor(private projectService: ProjectService, private router: Router, private bidsService: BidsService, private activatedRoute: ActivatedRoute,private toastr: ToastrService,    private fb: FormBuilder,

    private complainService: ComplainService, private authService : HttpserviceService) {
        this.feedBackform = this.fb.group({
              skills: [''],
              complaint: ['']
         })

  }

  ngOnInit(): void {
      this.id = this.activatedRoute.snapshot.params.id

    const tag = this.activatedRoute.snapshot.paramMap.get('tag');
    this.getData(tag);
    this.getPRojectdetails()
  }

  navigateBack(): void {
    this.router.navigateByUrl('/home/my-jobs');
  }
  markcomplete(id: any){
  let obj={
        'project_id' : id,
        'painter_status': 'completed'
  }
   this.authService.changeprojectstatus(obj).subscribe((response: any) => {
        this.toastr.success('Status updated!', 'Success!')
       



      });
  }
   viewdispute(){
      this.makeComplainDialogVisible1dispute = true

  }
  clos(){
            this.makeComplainDialogVisible1dispute = false

  }

  navigateToProject(): void {
    this.router.navigate(['home/my-project-detail']);
  }
    viewDemoDetailsscomp(id: any, type:any) {
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-project-detail-new/'+id]);
  }
   getPRojectdetails() {
    let obj = {
      'project_id': this.id
    }
    let param = 'project_id='+ this.id + "&status="+ localStorage.getItem('type')
    this.authService.getcompletedlistid(param).subscribe((response: any) => {
      this.projectDetails = response.projects[0]

      response.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

  getData(bidTag: any): void {
    this.bidsService.getBidWithAllDetailsForPainer(bidTag).subscribe(response => {
      this.myBid = response.bid;
      this.project = response.project;
      this.complain = this.project.complain;
    },
      error => {
        console.log(error);
      });
  }
   sendreview(): void {

      this.makeComplainDialogVisible1 = true;

  }

  markCompleted(): void {
    this.markAsCompletedModalVisible = false;
    this.projectService.markProjectAsCompleted(this.project.tag).subscribe(res => {
      this.getData(this.myBid.tag);
    });
  }

  makeComplain(): void {
    const complain: Complain = { content: 'empty for now', reason: this.selectedComplainReason, projectTag: this.project.tag };
    this.complainService.postComplain(complain).subscribe(res => {
      this.makeComplainDialogVisible = false;
    }, error => {

    });
  }
  closeComplainModal(): void {
    this.makeComplainDialogVisible = false;
    this.makeComplainDialogVisible1dispute = false
    this.selectedComplainReason = EComplainReason.DEFUALT;
  }
  pay(): void {
  }
  openComplainModal(): void {
      this.makeComplainDialogVisible = true;

    //this.selectedComplainReason = EComplainReason.DEFUALT;
  
  }

  select(event: any): void {
    this.selectedComplainReason = event.value;
  }
  save(){
  console.log(this.feedBackform)
        let obj={
            'rating':localStorage.getItem('rating'),
            'comment': this.feedBackform.value.complaint,
            'project_id':this.id,
            'customer_id': this.projectDetails?.customer_id
            //'dispute_option': this.option
        }

         this.authService.changereview(obj).subscribe((response: any) => {
        this.toastr.success('Review submitted!', 'Success!')
            //    window.location.reload()
                          localStorage.removeItem('rating')

            this.router.navigate(['/home/my-jobs'])
       this.makeComplainDialogVisible= false



      });
  }

    savenew(){
  console.log(this.feedBackform)
        let obj={
            'rating':localStorage.getItem('rating'),
            'comment': this.feedBackform.value.complaint,
            'project_id':this.id,
            //'dispute_option': this.option
        }

         this.authService.changereview(obj).subscribe((response: any) => {
        this.toastr.success('Review submitted!', 'Success!')
              // window.location.reload()
              localStorage.removeItem('rating')
              this.router.navigate(['/home/my-project-card'])
               this.makeComplainDialogVisible1= false



      });
  }

}
