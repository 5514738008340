import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { observable, Observable, of, Subject } from "rxjs";
import * as socketIo from "socket.io-client";
import { HttpserviceService } from "src/app/services/httpservice.service";
import { timeStamp } from "console";
import { io } from "socket.io-client";
@Injectable({
  providedIn: 'root'
})
export class SocketService {

   constructor(private service: HttpserviceService) { }
  socket: any;
  webConnected: Subject<boolean> = new Subject<boolean>();
  isWebConnected = this.webConnected.asObservable();


  initSocket(data: any = {}) {

    this.socket = socketIo.connect(environment.socketUrl, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 3000,
      reconnectionAttempts: Infinity,
      query: {
        token: this.service.getToken(),
      },
    });
    console.log('conn')
    this.socket.on("connect", () => {
      console.log( "connected" ,this.socket.connected); // x8WIv7-mJelg7on_ALbx
    });

    this.socket.on("disconnect", () => {
      console.log("gfhgfhgfhgf"); // undefined
    });
  }

 

  emitAction(action: any, payload: any) {
    this.socket.emit(action, payload);
  }

  emitUpload(action: any, file: any) {
    this.socket.emit(action, file, (status: any) => {
      console.log(status, "dddd");
    });
  }
  public onEvent(event: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(event, (data: any) => {
        observer.next(data);
      });
    });
  }
}

