import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpserviceService } from '../../../services/httpservice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() userTheme!: boolean;
  @Input() anyKindForm!: boolean;
  @Input() login!: boolean;

  constructor(private authService: HttpserviceService, private router: Router) { }



  isCustomerSelected: boolean = false; 
  isPainterSelected: boolean = false;
  showModal = false;
  userType!: string;

  ngOnInit(): void {
  }

  role(role: any) {
    if (role == 'painter') {
      localStorage.setItem('role', 'painter')
    } else {
      localStorage.setItem('role', 'customer')
    }

  }


  selectCustomer() {
    this.isCustomerSelected = true;
    this.isPainterSelected = false;
          localStorage.setItem('role', 'customer')
          this.router.navigate(['/register'])
  }

  selectPainter() {
    this.isCustomerSelected = false;
    this.isPainterSelected = true;
          localStorage.setItem('role', 'painter')
                    this.router.navigate(['/register'])

  }




  // --modal-


  openSignUpModal(): void {
    this.showModal = true;
  }

  closeComplainModal(): void {
    this.showModal = false;
  }

  // Hideshow-sidebar 

  showSidebar = false;

  openSidebar() {
    this.showSidebar = true;
  }

  closeSidebar() {
    this.showSidebar = false;
  }
}
