<span *ngFor="let action of actions" class="element nb-theme-brush">
  <span *ngIf="action.cond==null || action.cond(inputData)">
    <span class="tooltip">
      <span (click)="doAction(action)">
       <nb-icon class="icon" icon="{{action.icon}}" pack="{{action.pack}}"></nb-icon>
      </span>
      <span class="tooltiptext">{{action.tip}}</span>
    </span>
  </span>
</span>
