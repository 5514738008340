import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EPaymentStatus } from '../../../../../model/enums/EPaymentStatus';
import { PaypalService } from '../../../../../services/paypal.service';
import { EBidStatus } from '../../../../../model/enums/EBidStatus';
import { BidsService } from '../../../../../services/bids.service';
import { getRedirectLinkForProjectType } from '../../../../../model/enums/EProjectType';
import { PainterBidComponent } from '../../../../../components/MAIN_COMPONENTS/PainterBidComponent';
import { FormBuilder } from '@angular/forms';
import { EProjectStatus } from '../../../../../model/enums/EProjectStatus';
import { TokenStorageService } from '../../../../../services/token-storage.service';
import { MessageService } from '../../../../../services/message.service';
import { BrushNotification } from '../../../../../services/notification-service';
import {HttpserviceService} from '../../../../../services/httpservice.service';
import * as moment from 'moment';
    import { ToastrService } from 'ngx-toastr';



export interface HowBrushWorksCard {
  title: string;
  icon: string;
  description: string;
}

@Component({
  selector: 'app-bid-details',
  templateUrl: './bid-details.component.html',
  styleUrls: ['./bid-details.component.scss']
})
export class BidDetailsComponent extends PainterBidComponent implements OnInit {
  paymentProceedVisible = false;
  modalFlagnew: boolean = false
    credit_value: any
    howworkd: any
    modalFlagbid: boolean = false

  biddetails: any={}
  bidAmt: any
id: any
  issuccs = false;
  EBidStatus = EBidStatus;
  howBrushWorksCards: HowBrushWorksCard[] = [
    {
      title: 'PROJECT POSTED',
      icon: './assets/icons/how-brush-works/how-brush-works1.png',
      description: 'The client posts a project'
    },
    {
      title: 'BID PLACED',
      icon: './assets/icons/how-brush-works/how-brush-works2.png',
      description: 'You place your bid for the entire project'
    },
    {
      title: 'ACCEPTED',
      icon: './assets/icons/how-brush-works/how-brush-works3.png',
      description: 'The buyer accepts your bid if it’s the right fit'
    },
    {
      title: 'PAYMENT',
      icon: './assets/icons/how-brush-works/how-brush-works4.png',
      description: 'You get the contact details of the client'
    },
    {
      title: 'WORK STARTS',
      icon: './assets/icons/how-brush-works/how-brush-works5.png',
      description: 'You contact the client and start the work'
    }
  ];

  statusDescription = [
    {
      status: EBidStatus.PENDING,
      label: 'PENDING',
      description: 'It seems like customer does not decided on your bid. It has not been accepted nor denied yet.',
      icon: './assets/icons/bid-accepted-icon.png'
    },
    {
      status: EBidStatus.ACCEPTED,
      label: 'ACCEPTED',
      description: 'It seems like your offer was irresistible! <b>Congratulations</b> on your new job!',
      icon: './assets/icons/bid-accepted-icon.png'
    },
    {
      status: EBidStatus.DELETED,
      label: 'DELETED',
      description: 'Ooops! It seems like you have undone your bid.',
      icon: './assets/icons/bid-accepted-icon.png'
    },
    {
      status: EBidStatus.DENIED, label: 'DENIED', description: 'We are sorry to inform you that you bid was denied.',
      icon: './assets/icons/bid-accepted-icon.png'
    },
    {
      status: EBidStatus.EXPIRED,
      label: 'EXPIRED',
      description: 'You did your best, unfortunately customer decided to gave up on the project.',
      icon: './assets/icons/bid-accepted-icon.png'
    }
  ];

  currentBidStatusIcon = '';
  currentBidStatusDesc = '';
  leftTimeline!: Array<any>;
  rightTimeline!: Array<any>;

  constructor(private activatedRoute: ActivatedRoute,
    protected bidsService: BidsService,
    private paypalService: PaypalService,
    private router: Router,
    protected formBuilder: FormBuilder,
    protected tokenStorage: TokenStorageService,
    protected messageService: MessageService,
    private authService: HttpserviceService,  
     private toastr: ToastrService
  ) {
    super(formBuilder, bidsService, tokenStorage, messageService);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.leftTimeline = new Array<any>();
    this.rightTimeline = new Array<any>();
    this.checkForPayment();

  }


  ngOnInit(): void {
     this.id= this.activatedRoute.snapshot.params.id

  this.getbidDetails()
      this.getcredite()

  }


   getcredite() {
 
    this.authService.getcreditvalue().subscribe((response: any) => {
      this.credit_value = response.response



    })
     this.authService.gethowtowork().subscribe((response: any) => {
      this.howworkd = response.response



    })
  }

   closeComplainModalnew() {
    this.modalFlagnew = false
    this.modalFlagbid= false
  }

  bidmodify(){
   let obj={
        'id' : this.id,
        'bid': this.bidAmt,
        'project_id' : this.biddetails?.Project_details?.id
  }
   
     this.authService.updatebid(obj).subscribe((response : any) => {
       this.modalFlagbid = true

     this.toastr.success('Bid updated successfully!', 'Success!');

       }, error => {
                      console.log(error);

                        this.toastr.error('Already other painter bid is approved', 'Error!');

          
     })
  }
     getbidDetails(){
    let obj={
        'project_id' : this.id
  }
   this.authService.getbidsDetails(this.id).subscribe((response : any) => {
         this.biddetails= response.response
         this.bidAmt = this.biddetails.bid
         console.log(this.biddetails, this.bidAmt)
       let val: any
               let current_date: any = moment(new Date());

        let deadline_date: any = moment(this.biddetails.project_details.created_at);
        console.log(deadline_date)
        val.days_remaining =  Math.abs(deadline_date.diff(current_date, 'days'));
        
        console.log('rem days', this.biddetails);
        	
        })
  }

  checkForPayment(): void {
    const tag = this.activatedRoute.snapshot.paramMap.get('tag');
    const isPaymentBeingProcessed = this.paypalService.isPaymentBeingProcessed(this.activatedRoute);
    if (isPaymentBeingProcessed) {
      this.paypalService.executePayment(tag!).subscribe(response => {
        this.issuccs = true;
        this.paymentProceedVisible = true;
        this.loadData(tag!);
      }, error => {
        this.issuccs = false;
      });
    } else {
      console.log('nie');
      this.loadData(tag!);
    }
  }

  checkDescription(): void {
    this.statusDescription.forEach(item => {
      if (item.status === this.myBid.status) {
        this.currentBidStatusIcon = item.icon;
        this.currentBidStatusDesc = item.description;
      }
    });
  }

  loadData(tag: string): void {
    this.bidsService.getBidWithAllDetailsForPainer(tag!).subscribe(response => {
      this.myBid = response.bid;
      console.log(this.myBid);
      super.ngOnInit();
      this.project = response.project;
      this.checkDescription();
    },
      error => {
        console.log(error);
      });
  }

  pay(): void {
    this.paypalService.createPayment(this.myBid.tag, this.myBid.fee).subscribe(response => {
      window.open(response.content, '_self');
      console.log(response);
    });
  }

  navigateToProject(): void {
    // this.router.navigate(['home/marketplace/project-marketplace-details/' + getRedirectLinkForProjectType(this.project.projectType).value + '/' + this.project.tag]);
    this.router.navigate(['home/my-project-detail']);
  }


  ifPending(): boolean {
    return this.myBid.status === EBidStatus.PENDING || this.myBid.status === EBidStatus.NEW;
  }

  ifExpired(): boolean {
    return this.myBid.status === EBidStatus.EXPIRED;
  }

  ifDenied(): boolean {
    return this.myBid.status === EBidStatus.DENIED;
  }

  ifPaid(): boolean {
    return this.myBid.paymentStatus !== EPaymentStatus.NOTPAID;
  }

  ifDeleted(): boolean {
    return this.myBid.status === EBidStatus.DELETED;
  }

  ifAccepted(): boolean {
    return this.myBid.status === EBidStatus.ACCEPTED;
  }

  ifProjectPending(): boolean {
    return this.project.status === EProjectStatus.PENDING;
  }

  changeBidPriceEvent(event: string): void {
    this.changeBidPrice(event, this.myBid.tag);
  }

  onBidPriceChanged(): void {
    this.router.navigateByUrl('/home/my-bids/bid/' + this.myBid.tag);
    this.loadData(this.myBid.tag);
  }

  navigateBack(): void {
    this.router.navigateByUrl('/home/my-bids');
  }

  onBidPlaced(): void {
    this.router.navigateByUrl('/home/my-bids/bid/' + this.myBid.tag);
    this.loadData(this.myBid.tag);
  }

  onBidDeleted(): void {
    this.loadData(this.myBid.tag);
  }
  viewDemoDetails() {
    this.router.navigate(['/home/my-bids/bid']);
  }

  withdraw(status: any,id: any, p_id: any){
  let obj={
        'bid_status1': status,
        'id' : id,
        'type': 'withdraw',
        'project_id' : p_id

  }
   this.authService.acceptRejectBidschange( obj).subscribe((response : any) => {
   this.toastr.success('Status updated!', 'Success!')
             this.modalFlagnew = false
             this.ngOnInit()
        	

				
      });
  }

  cancel(){
        this.modalFlagnew= false
  }
  
}
