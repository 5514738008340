<div class="wrapper">
    <div class="project-creator-header">
    </div>
    <div class="project-creator-header">
        <!-- <button class="custom-button">BACK</button>-->
        <button routerLink="/home/my-project-card" class="custom-button cancel-button">CANCEL</button>
    </div>
    <div class="page">
        <div class="d-flex justify-content-between align-items-center mb-2">
            <div class="page-title">Create a New Project</div>



            <!--  <div class="process-bar">
                <div class="processing-bar">
        

                    <div class="step-dtls"  *ngIf="showwhatpainted">
                        <div class="prcss-cntnt">
                            <p>Step 1/5</p>
                            <h4>How Many Rooms need to Painted</h4>
                        </div>
                    </div>
                    <div class="cstm-progress-bar"  *ngIf="showwhatpainted">
                        <div class="fill-bar">
                            <div class="rcnt-fill">
                                25%
                            </div>
                        </div>
                    </div>
                      <div class="step-dtls"  *ngIf="(showsurface || surface_cond )&& !showwhatpainted">
                        <div class="prcss-cntnt">
                            <p>Step 2/5</p>
                            <h4>How Many Rooms need to Painted</h4>
                        </div>
                    </div>
                    <div class="cstm-progress-bar"  *ngIf="(showsurface || surface_cond) && !showwhatpainted">
                        <div class="fill-bar">
                            <div class="rcnt-fill">
                                50%
                            </div>
                        </div>
                    </div>
                       <div class="step-dtls"  *ngIf="all_applicables || showither">
                        <div class="prcss-cntnt">
                            <p>Step 3/5</p>
                            <h4>How Many Rooms need to Painted</h4>
                        </div>
                    </div>
                    <div class="cstm-progress-bar"  *ngIf="all_applicables || showither">
                        <div class="fill-bar">
                            <div class="rcnt-fill">
                                75%
                            </div>
                        </div>
                    </div>

                   

                </div>
            </div>-->
        </div>



        <div class="stepper-create-project">
            <nb-card>
                <nb-card-body>
                    <nb-stepper orientation="horizontal" #stepper [(selectedIndex)]="stepperIndex">

                        <nb-step [label]="labelOne">

                            <ng-template #labelOne>
                                <div class="step-header-cstm">
                                    <p> Choose Project Type</p>
                                    <p class="step-status mt-auto" *ngIf="show && show1 && project_type">
                                        Completed
                                    </p>
                                    <p class="step-status pending mt-auto" *ngIf="!show && !show1 && !project_type">
                                        Pending
                                    </p>
                                    <!-- <p class="step-status processing mt-auto" *ngIf="show || show1 || project_type">
                                        Processing
                                    </p>-->
                                </div>
                            </ng-template>

                            <div class="choose-project-cntnt">

                                <div class="page" *ngIf="show">
                                    <div class="page-title ">Choose project type</div>
                                    <div class="project_type_choose">
                                        <div class="choose-project-type">
                                            <div class="project-type-wrapper">
                                                <div class="project-type-card">
                                                    <img src="./assets/project-creator/interior-type.png"
                                                        alt="projectTypeImage" (click)="choseProject('interior')">
                                                    <div class="project-type-info-wrapper">
                                                        <div class="project-type-label">Interior</div>
                                                        <div class="project-type-description">
                                                            Interior projects are all about the work that needs to be
                                                            done
                                                            inside your apartment. Anything from painting your kitchen
                                                            to
                                                            putting a wallpaper in your bedroom.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="choose-project-type">
                                            <div class="project-type-wrapper">
                                                <div class="project-type-card">
                                                    <img src="./assets/project-creator/exterior-type.png"
                                                        alt="projectTypeImage" (click)="choseProject('exterior')">
                                                    <div class="project-type-info-wrapper">
                                                        <div class="project-type-label">Exterior</div>
                                                        <div class="project-type-description">
                                                            Exterior projects include all jobs related with outer part
                                                            of
                                                            your house.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="choose-project-type">
                                            <div class="project-type-wrapper">
                                                <div class="project-type-card">
                                                    <img src="./assets/project-creator/others.png"
                                                        alt="projectTypeImage" (click)="choseProject('others')">
                                                    <div class="project-type-info-wrapper">
                                                        <div class="project-type-label">Others</div>
                                                        <div class="project-type-description">
                                                            Exterior projects include all jobs related with outer part
                                                            of
                                                            your house.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>





                                </div>

                                <div class="page mt-4" *ngIf="show1 ">
                                    <div class="page-title">Is this home or a business</div>
                                    <div class="displaying-pages-images">
                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/residential.png"
                                                alt="section-item" (click)="homeorbusiness('residential')">
                                            <div class="image-label">Residential</div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/business.png"
                                                alt="section-item" (click)="homeorbusiness('business')">
                                            <div class="image-label">Business</div>
                                        </div>
                                    </div>
                                </div>

                                <!-- exterior -->

                                <div class="page mt-4" *ngIf="propertyshow && project_type=='interior'">
                                    <div class="page-title">What kind of property do you need painted?</div>
                                    <div class="displaying-pages-images" >
                                        <div class="image-wrapper" (click)="propertyselect('apartment')">
                                            <img class="image-image" src="./assets/project-creator/apartment.png"
                                                alt="section-item">
                                            <div class="image-label">Apartment</div>
                                        </div>

                                        <div class="image-wrapper" (click)="propertyselect('house')">
                                            <img class="image-image" src="./assets/project-creator/house.png"
                                                alt="section-item">
                                            <div class="image-label" (click)="propertyselect('house')">House</div>
                                        </div>

                                        <div class="image-wrapper" (click)="propertyselect('townhouse')">
                                            <img class="image-image" src="./assets/project-creator/townhouse.png"
                                                alt="section-item">
                                            <div class="image-label" (click)="propertyselect('townhouse')">Townhouse
                                            </div>
                                        </div>


                                        <div class="image-wrapper" (click)="propertyselect('condo')">
                                            <img class="image-image" src="./assets/project-creator/condo.png"
                                                alt="section-item" [(ngModel)]="proppaint">
                                            <div class="image-label" (click)="propertyselect('condo')">Condo</div>
                                        </div>

                                        <!--   <div class="image-wrapper" (click)="propertyselect('business')">
                                            <img class="image-image" src="./assets/project-creator/business.png"
                                                alt="section-item"  [(ngModel)]="proppaint" >
                                            <div class="image-label"  (click)="propertyselect('business')">Business</div>
                                        </div>
                                          <div class="image-wrapper" (click)="propertyselect('residential')">
                                            <img class="image-image" src="./assets/project-creator/residential.png"
                                                alt="section-item"  [(ngModel)]="proppaint" >
                                            <div class="image-label" (click)="propertyselect('residential')">Residential</div>
                                        </div>-->
                                    </div>
                                </div>

                                <div class="page mt-4" *ngIf="propertyshow && project_type=='exterior'">
                                    <div class="page-title">Select property?</div>
                                    <div class="displaying-pages-images">
                                        <div *ngFor="let item of otherproperty">
                                            <div class="image-wrapper" *ngIf="item.category=='basic'">

                                                <img class="image-image" [src]="item.image" alt="section-item"
                                                    (click)="propertyselectext(item.title, item.id)">
                                                <div class="image-label"
                                                    (click)="propertyselectext(item.title, item.id)">
                                                    {{item.title}}
                                                </div>
                                            </div>
                                            <div class="image-wrapper" *ngIf="!item.category">

                                                <img class="image-image" [src]="item.image" alt="section-item"
                                                    (click)="propertyselectext(item.title, item.id)">
                                                <div class="image-label"
                                                    (click)="propertyselectext(item.title, item.id)">
                                                    {{item.title}}
                                                </div>
                                            </div>
                                        </div>




                                        <div class="image-wrapper" (click)="openmodalimage()">
                                            <img class="image-image" src="./assets/project-creator/apartment.png"
                                                alt="section-item">
                                            <div class="image-label">Add more</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="page mt-4" *ngIf="pro && project_type=='exterior'">
                                    <div class="page-title">What kind of property do you need painted?</div>
                                    <div class="displaying-pages-images" (click)="propertyselect('apartment')">
                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/apartment.png"
                                                alt="section-item">
                                            <div class="image-label">Apartment</div>
                                        </div>

                                        <div class="image-wrapper" (click)="propertyselect('house')">
                                            <img class="image-image" src="./assets/project-creator/house.png"
                                                alt="section-item">
                                            <div class="image-label" (click)="propertyselect('house')">House</div>
                                        </div>

                                        <div class="image-wrapper" (click)="propertyselect('townhouse')">
                                            <img class="image-image" src="./assets/project-creator/townhouse.png"
                                                alt="section-item">
                                            <div class="image-label" (click)="propertyselect('townhouse')">Townhouse
                                            </div>
                                        </div>


                                        <div class="image-wrapper" (click)="propertyselect('condo')">
                                            <img class="image-image" src="./assets/project-creator/condo.png"
                                                alt="section-item" [(ngModel)]="proppaint">
                                            <div class="image-label" (click)="propertyselect('condo')">Condo</div>
                                        </div>

                                        <!--     <div class="image-wrapper" (click)="propertyselect('business')">
                                            <img class="image-image" src="./assets/project-creator/business.png"
                                                alt="section-item"  [(ngModel)]="proppaint" >
                                            <div class="image-label"  (click)="propertyselect('business')">Business</div>
                                        </div>
                                          <div class="image-wrapper" (click)="propertyselect('residential')">
                                            <img class="image-image" src="./assets/project-creator/residential.png"
                                                alt="section-item"  [(ngModel)]="proppaint" >
                                            <div class="image-label" (click)="propertyselect('residential')">Residential</div>
                                        </div>-->


                                        <div class="image-wrapper" (click)="openmodalimage()">
                                            <img class="image-image" src="./assets/project-creator/apartment.png"
                                                alt="section-item">
                                            <div class="image-label">Add more</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="page mt-4" *ngIf="propertyshow && project_type=='others'">
                                    <div class="page-title">What kind of property do you need painted?</div>
                                    <div class="displaying-pages-images">

                                        <div class="image-wrapper" *ngFor="let item of othersotherproperty">
                                            <img class="image-image" [src]="item.image" alt="section-item"
                                                (click)="propertyselect(item.title)">
                                            <div class="image-label" (click)="propertyselect(item.title)">{{item.title}}
                                            </div>
                                        </div>

                                        <div class="image-wrapper" (click)="openmodalnewimage()">
                                            <img class="image-image" src="./assets/project-creator/apartment.png"
                                                alt="section-item">
                                            <div class="image-label">Add more</div>
                                        </div>

                                    </div>
                                </div>

                                <div>

                                </div>
                            </div>



                        </nb-step>
                        <nb-step [label]="labelTwo" *ngIf="project_type=='interior'">
                            <button class="custom-button border_button" nbButton nbStepperPrevious
                                (click)="first()">Back</button>

                            <ng-template #labelTwo>

                                <div class="step-header-cstm">
                                    <p>Room Details</p>

                                    <p class="step-status mt-auto" *ngIf="showwhatpainted && painted && rooms_painted">
                                        Completed
                                    </p>
                                    <p class="step-status pending mt-auto"
                                        *ngIf="!showwhatpainted && !painted && !rooms_painted">
                                        Pending
                                    </p>

                                </div>

                            </ng-template>

                            <div class="room-details-cntnt">

                                <div class="pages mt-4" *ngIf="painted">
                                    <div class="page-title">How many rooms need to be painted?</div>

                                    <div class="select_room_no">
                                        <select name="" id="" class="select_input" [(ngModel)]="room"
                                            (change)="changeCheckboxState(room)">
                                            <option value="" disabled>Select</option>
                                            <option *ngFor="let item of roomsno" [value]="item.value"> {{item.name}}
                                            </option>
                                        </select>
                                    </div>


                                </div>

                                <div class="paged mt-4" *ngIf="rooms_painted">

                                    <div class="page-title">Select room types you need painted</div>


                                    <div class="displaying-pages-images-multiple">
                                        <div [ngClass]="[item.selected ? 'selected image-wrapper' : 'image-wrapper' ]"
                                            *ngFor="let item of items; let i=index;"
                                            (click)="changeCheckboxStateroom(item.value,i )">
                                            <div id="i">
                                                <img class="image-image" [src]="item.image" alt="section-item">
                                                <div [ngClass]="seletcedis(i)" class="image-label">{{item.label}}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button *ngIf="shoenxt" (click)="next()">Next</button>
                                </div>



                                <div class="paged mt-4" *ngIf="showwhatpainted">

                                    <div class="page-title">What do you want painted?</div>
                                    <div class="displaying-pages-images-multiple">
                                        <div [ngClass]="[item.selected ? 'selected image-wrapper' : 'image-wrapper' ]"
                                            *ngFor="let item of itemswhatpainted; let i=index;"
                                            (click)="changeCheckboxStateroompainted(item.value,i )">
                                            <div id="i">
                                                <img class="image-image" [src]="item.image" alt="section-item">
                                                <div [ngClass]="seletcedisroom(i)" class="image-label">{{item.label}}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>







                            </div>

                            <div *ngIf="showwhatpainted">
                                <button nbButton nbStepperPrevious (click)="first()">prev</button>
                                <button nbButton nbStepperNext>next</button>
                            </div>
                        </nb-step>
                        <nb-step [label]="labelThree">
                            <button class="custom-button border_button" nbButton nbStepperPrevious
                                (click)="second()">BACK</button>

                            <ng-template #labelThree>
                                <div class="step-header-cstm">
                                    <p>Surface Details</p>
                                    <p>Room Details</p>

                                    <!--  <p class="step-status mt-auto" *ngIf="height_ceiling  && color_finish && showwallpaper && surface_cond">
                                        Completed
                                    </p>
                                      <p class="step-status pending mt-auto" *ngIf="!height_ceiling  && !color_finish && !showwallpaper && !surface_cond">
                                        Pending
                                    </p>
                                    <!-- <p class="step-status processing mt-auto" *ngIf="!height_ceiling || !shownexttow || !color_finish ||  !showwallpaper   || !surface_cond">
                                        Processing
                                    </p>-->

                                </div>
                            </ng-template>
                            <div class="surface-dtls-cntnt">

                                <div class="mt-4 paged" *ngIf="height_ceiling && project_type=='interior'">
                                    <div class="page-title"> What is the height of your ceiling (estimated in ft)
                                    </div>

                                    <div class="select_room_no">
                                        <select [(ngModel)]="height" (change)="heightofceiling(height)" name="" id=""
                                            class="select_input">
                                            <option value="4"> 4 ft </option>
                                            <option value="5"> 5ft </option>
                                            <option value="6"> 6ft </option>
                                            <option value="7"> 7ft </option>
                                            <option value="8"> 8ft and more </option>
                                            <option value="need_help"> Need help in measurement </option>
                                        </select>
                                    </div>

                                </div>

                                <div class="mt-4 paged" *ngIf="color_finish && project_type=='interior'">
                                    <div class="page-title">What color or finish do you want
                                    </div>

                                    <div class="d-flex justify-content-center">
                                        <!-- <div class="displaying-pages-images-multiple">
                                            <div [ngClass]="[item.selected ? 'selected image-wrapper' : 'image-wrapper' ]"
                                                *ngFor="let item of itemspainted; let i=index;"
                                                (click)="itemPainted(item.value,i )">
                                                <div id="i">
                                                    <div [ngClass]="seletcedispainted(i)" class="image-label">{{item.label}}

                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->


                                        <!--<div class="displaying-pages-checkboxes">
                                        <div [ngClass]="[item.selected ? 'selected image-wrapper' : 'image-wrapper' ]"
                                                *ngFor="let item of itemspainted; let i=index;"
                                                (click)="itemPainted(item.value, i)">
                                                <div class="checkbox-label">{{item.label}}</div>
                                                <div id="i"   [ngClass]="seletcedispainted(i)"> 
                                                    
                                                </div>
                                            </div>
                                        </div>-->

                                        <div class="d-flex justify-content-center">
                                            <div class="displaying-pages-checkboxes">
                                                <div *ngFor="let item of itemspainted; let i=index;"
                                                    (click)="itemPainted(item.value, i)">
                                                    <div class="displ_fkx">
                                                        <div class="">{{item.label}}
                                                        </div>
                                                        <div
                                                            class="div_remmbr d-flex login_checkbox_sec align-items-center">
                                                            <input type="checkbox" name="remember_me" id="gridCheck"
                                                                class="ng-untouched ng-pristine ng-valid">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button *ngIf="shownexttow" (click)="goto()">Next</button>
                                </div>
                                <div class="mt-4 paged" *ngIf="showwallpaper && project_type=='interior'">
                                    <div class="page-title"> Any wallpaper to put or remove?
                                    </div>

                                    <div class="displaying-pages-boxes">
                                        <div class=" box" (click)="putWallpaper('no')">
                                            No
                                        </div>
                                        <div class="box" (click)="putWallpaper('yes')">
                                            Yes
                                        </div>


                                    </div>
                                </div>

                                <div class="mt-4 paged" *ngIf="surface_cond && project_type=='interior'">
                                    <div class="page-title">How would you rate your surface condition?
                                    </div>

                                    <div class="displaying-pages-boxes-with-images">
                                        <div class="displaying-pages-boxes-with-images">



                                            <div [ngClass]="[item.selected ? 'selected box-with-image' : 'box-with-image' ]"
                                                *ngFor="let item of condition; let i =index"
                                                (click)="changecondition(item.value, i)">
                                                <div id="i" class="d-flex align-items-center">
                                                    <img class="box-with-image-image" *ngIf="item.image"
                                                        alt="box-with-image-icon" [src]="item.image">
                                                    <div
                                                        [ngClass]="[item.selected ? 'box-with-image-label' : 'box-with-image-label' ]">
                                                        {{item.label}}</div>
                                                </div>
                                            </div>
                                        </div>





                                    </div>


                                </div>

                                <div class=" mt-4">

                                    <!-- <div class="pages">
                                        <div class="page-title">How big is surface you want to be painted (estimated
                                            in m²)?
                                        </div>

                                        <div class="select_room_no">
                                            <select name="" id="" class="select_input">
                                                <option value=""> less than 30 m² </option>
                                                <option value=""> 30-50m² </option>
                                                <option value=""> 50-70m² </option>
                                                <option value=""> more than 70m² </option>
                                                <option value=""> Need help in measurement </option>
                                            </select>
                                        </div>
                                    </div>-->


                                    <div class="pages mt-4"
                                        *ngIf=" (project_type =='exterior' ||  project_type=='others') && showmaterial">
                                        <div class="page-title">Select surface type you need painted
                                        </div>

                                        <div class="select_room_no">
                                            <select name="" id="" class="select_input" [(ngModel)]="material"
                                                (change)="changeCheckboxStatematerial(material)">
                                                <option value="" disabled>Select</option>
                                                <option *ngFor="let item of materials" [value]="item.surface_type">
                                                    {{item.surface_type}}</option>
                                                <option value="other">
                                                    Other</option>
                                            </select>

                                        </div>
                                    </div>
                                    <button
                                        *ngIf="(project_type =='exterior' ||  project_type=='others') && showmaterial"
                                        (click)="addnewcxt()">Next</button>


                                    <!-- Exterior -->
                                    <div class="mt-4 paged"
                                        *ngIf=" (project_type =='exterior' || project_type=='others') && showsurface">
                                        <div class="page-title"> How large is the surface you want to be painted
                                            (estimated in sq feet)?

                                        </div>

                                        <div class="select_room_no">
                                            <select name="" id="" class="select_input" [(ngModel)]="sufvalue"
                                                (change)="changeCheckboxStatsurf(sufvalue)">
                                                <option value="" disabled>Select</option>
                                                <option *ngFor="let item of surfacevalue" [value]="item.value">
                                                    {{item.name}}</option>
                                                <option value="other">
                                                    Enter value</option>
                                            </select>

                                        </div>

                                    </div>

                                    <button *ngIf="showsurface || surface_cond" nbButton nbStepperNext
                                        (click)="newnwxt()">next</button>

                                </div>
                            </div>

                            <!---  <div >
                                <button nbButton nbStepperPrevious (click)="second()">prev</button>
                                <button nbButton nbStepperNext>next</button>
                            </div>-->
                        </nb-step>
                        <nb-step [label]="labelFour">
                            <button class="custom-button border_button" nbButton nbStepperPrevious
                                (click)="third()">BACK</button>

                            <ng-template #labelFour>
                                <div class="step-header-cstm">
                                    <p> Requirements</p>
                                    <!-- <p class="step-status pending mt-auto">
                                        Pending
                                    </p> -->
                                    <p class="step-status mt-auto"
                                        *ngIf="all_applicables && pType && whendone && providibf_items ">
                                        Completed
                                    </p>


                                </div>
                            </ng-template>
                            <div class="mt-4 paged"
                                *ngIf="surface_cond && project_type=='exterior' ||  project_type=='others'">
                                <!-- <div class="page-title">How would you rate your surface conditionnnn?
                                </div> -->
                                <div class="page-title">Need help in assessing surface condition
                                </div>

                                <div class="displaying-pages-boxes-with-images">
                                    <div class="displaying-pages-boxes-with-images">



                                        <div [ngClass]="[item.selected ? 'selected box-with-image' : 'box-with-image' ]"
                                            *ngFor="let item of condition; let i =index"
                                            (click)="changeconditionnew(item.value, i)">
                                            <div id="i" class="d-flex align-items-center">
                                                <img class="box-with-image-image" *ngIf="item.image"
                                                    alt="box-with-image-icon" [src]="item.image">
                                                <div
                                                    [ngClass]="[item.selected ? 'box-with-image-label' : 'box-with-image-label' ]">
                                                    {{item.label}}</div>
                                            </div>
                                        </div>
                                    </div>





                                </div>


                            </div>
                            <div class="requirements-cntnt">
                                <div class="mt-4 paged" *ngIf="providibf_items">
                                    <div class="page-title">Are you providing any supplies?

                                    </div>

                                    <div class="displaying-pages-boxes">



                                        <div class="box" (click)="pItems('not_at all')">


                                            No at all
                                        </div>

                                        <div class="box" (click)="pItems('only_paint')">


                                            I will provide only paint
                                        </div>

                                        <div class="box" (click)="pItems('paint_supplies')">

                                            Paint and painting supplies

                                        </div>

                                        <div class="box" (click)="pItems('need_list')">


                                            I can provide all but need list
                                        </div>

                                    </div>


                                </div>

                                <!-- exterior -->
                                <div class="mt-4 paged"
                                    *ngIf="(project_type=='exterior'|| project_type=='others') && showither">
                                    <div class="page-title">What other things would you like the painter to do?
                                    </div>



                                    <div class="d-flex justify-content-center">
                                        <div class="displaying-pages-checkboxes">


                                            <div *ngFor="let item of itemsother; let i=index;"
                                                (click)="selectProjectother(item.value, i)">
                                                <div class="displ_fkx">
                                                    <div class="">{{item.label}}
                                                    </div>
                                                    <div
                                                        class="div_remmbr d-flex login_checkbox_sec align-items-center">
                                                        <input type="checkbox" name="remember_me" id="gridCheck"
                                                            class="ng-untouched ng-pristine ng-valid">

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>




                                <div class="mt-4 paged" *ngIf="whendone && project_type=='interior'">
                                    <div class="page-title">When would you like the job to be done?
                                    </div>

                                    <div class="displaying-pages-boxes">



                                        <div class="box" (click)="whentobe('during_the_day')">
                                            During the day</div>

                                        <div class="box" (click)="whentobe('anytime')">
                                            Anytime </div>

                                        <div class="box" (click)="whentobe('I_can_adjust_to_the_painter')">
                                            I can adjust to the painter </div>
                                    </div>

                                </div>

                                <div class="mt-4 paged" *ngIf="all_applicables && project_type=='interior'">
                                    <div class="page-title">Select all those applicable for your project
                                    </div>

                                    <div class="d-flex justify-content-center">
                                        <div class="displaying-pages-checkboxes">
                                            <div *ngFor="let item of all_applicables_list; let i=index;"
                                                (click)="selectProject(item.value, i)">
                                                <div class="displ_fkx">
                                                    <div class="">{{item.label}}
                                                    </div>
                                                    <div
                                                        class="div_remmbr d-flex login_checkbox_sec align-items-center">
                                                        <input type="checkbox" name="remember_me" id="gridCheck"
                                                            class="ng-untouched ng-pristine ng-valid">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>



                            </div>
                            <div *ngIf="all_applicables && project_type=='interior'">
                                <button nbButton nbStepperPrevious (click)="third()">prev</button>
                                <button nbButton nbStepperNext>next</button>

                            </div>
                            <div *ngIf="(project_type=='exterior'|| project_type=='others') && showither">
                                <button nbButton nbStepperPrevious (click)="third()">prev</button>
                                <button nbButton nbStepperNext>next</button>

                            </div>
                        </nb-step>
                        <nb-step [label]="labelFive">
                            <ng-template #labelFive>Project Details</ng-template>
                            <div class="prjct-dtls-cntnt">
                                <div class="page" *ngIf="!showprojectdetails">
                                    <div class="page-title">Project detailed info</div>
                                    <div class="page-content project-details" [formGroup]="projectDetailsForm">
                                        <div class="section-wrapper"
                                            *ngFor="let section of projectDetailsSections.items">
                                            <div class="project-details-header">{{section.title}}</div>
                                            <div class="project-details-description">{{section.description}}</div>
                                            <ng-template [ngIf]="section.id == 0">
                                                <div class="image-uploader">
                                                    <input class="form-control" type="file" accept="image/*" multiple
                                                        id="formFile" (change)="uploadFile($event)">
                                                    <div class="uploaded-images">
                                                        <div *ngFor="let items of filesn; let index= index;"
                                                            class="uploaded_img_wrap">
                                                            <img id="blah" [src]="items.name" alt="your image" />
                                                            <!-- <button mat-icon-button >delete</button> -->
                                                            <a (click)="removeSelectedFile(index)">
                                                                <svg aria-hidden="true" focusable="false"
                                                                    data-prefix="fas" data-icon="times"
                                                                    class="svg-inline--fa fa-times fa-w-11" role="img"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 352 512">
                                                                    <path fill="currentColor"
                                                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                                                    </path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngIf]="section.id == 1">
                                                <div class="text-area-wrapper">
                                                    <textarea formControlName="title" placeholder="Enter title"
                                                        maxlength="100" [rows]="1"
                                                        (input)="characterCounterForTitle($event)"></textarea>
                                                    <div class="hint">{{section.hint}}</div>
                                                    <!-- <p class="minLengthError">Enter at least 5 characters</p> -->
                                                    
                                                </div>
                                                <div *ngIf="!projectDetailsForm.controls['title'].valid">
                                                    <span style="color: red"
                                                        *ngIf="projectDetailsForm.controls['title'].touched && projectDetailsForm.controls['title'].hasError('minlength')">
                                                        Title must be at least 5 character
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngIf]="section.id == 2">
                                                <div class="text-area-wrapper">
                                                    <textarea formControlName="description"
                                                        placeholder="Add description" maxlength="400" [rows]="6"
                                                        (input)="characterCounterForDescription($event)"></textarea>
                                                    <div class="hint">{{section.hint}}</div>
                                                    <!-- <p class="minLengthError">Enter at least 5 characters</p> -->
                                                </div>
                                                <div *ngIf="!projectDetailsForm.controls['description'].valid">
                                                    <span style="color: red"
                                                        *ngIf="projectDetailsForm.controls['description'].touched && projectDetailsForm.controls['description'].hasError('minlength')">
                                                        Description must be at least 5 character
                                                    </span>
                                                </div>
                                            </ng-template>
                                            <ng-template [ngIf]="section.id == 3">
                                                <div class="d-flex">
                                                    <app-ngx-date-range-picker
                                                        (dateChanged)="setProjectDetailsDate($event)">
                                                    </app-ngx-date-range-picker>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="post-project-button">
                                            <button class="custom-button" (click)="sendNewProjectRequest()"
                                                [disabled]="!projectDetailsForm.valid">POST PROJECT</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="paged mt-4" *ngIf="showprojectdetails">
                                    <!-- SUMMARY-->
                                    <div class="summary">
                                        <div class="summary-wrapper">
                                            <img src="./assets/project-creator/success-icon.png" alt="success-icon">
                                            <div class="success-title">Your project has been successfully created!</div>
                                            <!-- <div class="success-hint">Your project has been posted to marketplace! You
                                                can check its details in My projects
                                                section
                                            </div> -->
                                            <div class="success-hint">Your project has been posted to the marketplace
                                                and is awaiting approval from Brush Exchange. Once it’s approved, you
                                                will be notified and be able to view it in the My Projects section
                                            </div>

                                            <button class="custom-button" [routerLink]="'/home/my-project-card'">My
                                                Projects</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nb-step>
                    </nb-stepper>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>
<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="openother"
    (cancelButtonClicked)="closeComplainModalnew()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <div class="">
                    <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
                    <div class="">
                        <div class="painter-details">
                            <div class="div_upr input-wrapper">
                                <h3 class="text-center">
                                    Add property
                                </h3>
                                <input [(ngModel)]="p_name" class="inputt ">
                            </div>
                        </div>
                    </div>
                    <div class="image-uploader mt-4">
                        <input class="form-control" type="file" accept="image/*" multiple id="formFile"
                            (change)="uploadFiles($event)">
                    </div>
                </div>
                <div class="content">
                    <div class="d-flex dbl_btns">
                        <button class="custom-button" (click)="savenew()">Save</button>

                    </div>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlagnewopen"
    (cancelButtonClicked)="closeComplainModalnew()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <div class="">
                    <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
                    <div class="">
                        <div class="painter-details">
                            <div class="div_upr input-wrapper">
                                <h3 class="text-center">
                                    Add property
                                </h3>
                                <p class="mt-3">Enter Title</p>
                                <input [(ngModel)]="p_newname" class="inputt mt-2">
                            </div>
                        </div>
                    </div>
                    <div class="image-uploader mt-3">
                        <input class="form-control" type="file" accept="image/*" multiple id="formFile"
                            (change)="uploadFilesnew($event)">
                    </div>
                </div>
                <div class="content">
                    <div class="d-flex dbl_btns">
                        <button class="custom-button" (click)="savenew()">Save</button>

                    </div>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="opennewmodal"
    (cancelButtonClicked)="closeComplainModalnew()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <div class="">
                    <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
                    <div class="" *ngIf="showtitle">
                        <div class="painter-details">
                            <div class="div_upr input-wrapper">
                                <h3 class="text-center">
                                    Add Surface
                                </h3>
                                <p class="mt-3">Enter Title</p>
                                <input [(ngModel)]="titlenew" class="inputt mt-2">
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="painter-details">
                            <div class="div_upr input-wrapper">
                                <p class="mt-3">
                                    Desrciption
                                </p>
                                <input [(ngModel)]="desc" class="inputt mt-2">
                            </div>
                        </div>
                    </div>
                    <!--   <div class="image-uploader mt-4">
                        <input class="form-control" type="file" accept="image/*" multiple id="formFile"
                            (change)="uploadFilesnewnew($event)">
                    </div>-->
                </div>
                <div class="content">
                    <div class="d-flex dbl_btns">
                        <button class="custom-button" (click)="savenewnew()">Save</button>

                    </div>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>
<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="opennewmodalsurface"
    (cancelButtonClicked)="closeComplainModalnew()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <div class="">
                    <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
                    <div class="">
                        <div class="painter-details">
                            <div class="div_upr input-wrapper">
                                <h3 class="text-center">
                                    Add Surface area
                                </h3>
                                <p class="mt-3">Enter Value</p>
                                <input type="number" [(ngModel)]="inputvalue"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                                    class="inputt mt-2">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="content">
                    <div class="d-flex dbl_btns">
                        <button class="custom-button" (click)="savenewnewsurface()">Save</button>

                    </div>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="shoeothernew"
    (cancelButtonClicked)="closeComplainModalnew()">
    <slot-content>
        <div class="bid_detail_modal">
            <div class="bid-card-wrapper">
                <div class="">
                    <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
                    <div class="">
                        <div class="painter-details">
                            <div class="div_upr input-wrapper">
                                <h3 class="text-center">
                                    Please write your request
                                </h3>

                                <input type="text" [(ngModel)]="newtwst" placeholder="Please write your request"
                                    oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                                    class="inputt mt-2">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="content">
                    <div class="d-flex dbl_btns">
                        <button class="custom-button" (click)="savenewother()">Save</button>

                    </div>
                </div>
            </div>
        </div>
    </slot-content>


</app-modal>