import {Pipe, PipeTransform} from '@angular/core';

@Pipe({  name: 'lastName'})
export class LastNamePipe implements PipeTransform {

  transform(fullName: string): any {
    if (fullName === null || fullName === undefined) {
      return '';
    }
    return fullName.split(' ').length > 0 ? fullName.split(' ')[1] : 'a';
  }

}
