<h2 class="route-title"><span>Payments</span>
    <!-- <app-notification-bell></app-notification-bell> -->
</h2>

<div class="wrapper">

    <div class="payment-methods">
        <div class="mt-4">
            <div class="page-title">Select Payment Method
            </div>

            <div class="d-flex justify-content-center">
                <div class="displaying-pages-checkboxes">

                    <div class="checkbox-wrapper">


                        <div class="">
                            <div class="checkbox-label">Net Banking</div>
                            <p class="sub-text-muted">This instrument has low success, use UPI or cards for better
                                experience</p>

                        </div>
                        <img class="checkbox-image" alt="checkbox-icon"
                            src="./assets/project-creator/checkbox-unchecked.png">
                    </div>


                    <div class="checkbox-wrapper">



                        <div class="">
                            <div class="checkbox-label">Credit / Debit / ATM Card</div>
                            <p class="sub-text-muted">Add and secure your card as per RBI guidelines</p>

                        </div>
                        <img class="checkbox-image" alt="checkbox-icon"
                            src="./assets/project-creator/checkbox-checked.png">
                    </div>

                    <div class="checkbox-wrapper">

                        <div class="">
                            <div class="checkbox-label">UPI / Wallets</div>
                            <p class="sub-text-muted">Pay with Upi or wallets</p>

                        </div>
                        <img class="checkbox-image" alt="checkbox-icon"
                            src="./assets/project-creator/checkbox-unchecked.png">
                    </div>


                    <div class="text-center mt-4">

                        <button class="button_theme" routerLink="/home/marketplace">Proceed</button>
                    </div>

                </div>
            </div>


        </div>
    </div>


</div>