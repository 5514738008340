<div class="main-box welcm_bck">
  <img src="assets/logo.png" class="error-icon">
  <p class="title exchng_clr">How can we contact you?</p>
  <form class="form margin-b10" [formGroup]="userDataForm">
    <div class="input-wrapper paragraph_text margin-b15">
      <p>Your phone number will only be used for customer service related matters, including account verification.</p>
    </div>
    <div class="div_logn input-wrapper input_res">
      <!-- <label><span class="label-text">Your Phone Number</span><br></label> -->
      <input type="text" class="password" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
        formControlName="phone" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
        placeholder="Your Phone Number">
      <!-- <div class="prfl_icn">
            <svg  width="1em" height="1em" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22 12C22 10.6868 21.7413 9.38647 21.2388 8.1731C20.7362 6.95996 19.9997 5.85742 19.0711 4.92896C18.1425 4.00024 17.0401 3.26367 15.8268 2.76123C14.6136 2.25854 13.3132 2 12 2V4C13.0506 4 14.0909 4.20703 15.0615 4.60889C16.0321 5.01099 16.914 5.60034 17.6569 6.34326C18.3997 7.08594 18.989 7.96802 19.391 8.93848C19.7931 9.90918 20 10.9495 20 12H22Z" fill="currentColor"/><path d="M2 10V5C2 4.44775 2.44772 4 3 4H8C8.55228 4 9 4.44775 9 5V9C9 9.55225 8.55228 10 8 10H6C6 14.4182 9.58173 18 14 18V16C14 15.4478 14.4477 15 15 15H19C19.5523 15 20 15.4478 20 16V21C20 21.5522 19.5523 22 19 22H14C7.37259 22 2 16.6274 2 10Z" fill="currentColor"/><path d="M17.5433 9.70386C17.8448 10.4319 18 11.2122 18 12H16.2C16.2 11.4485 16.0914 10.9023 15.8803 10.3928C15.6692 9.88306 15.3599 9.42017 14.9698 9.03027C14.5798 8.64014 14.1169 8.33081 13.6073 8.11963C13.0977 7.90869 12.5515 7.80005 12 7.80005V6C12.7879 6 13.5681 6.15527 14.2961 6.45679C15.024 6.7583 15.6855 7.2002 16.2426 7.75732C16.7998 8.31445 17.2418 8.97583 17.5433 9.70386Z" fill="currentColor"/></svg>
          </div> -->
    </div>
  </form>
  <div class="div_btn_lgn get_strt_btn">
    <button class="custom-button" (click)="submitRegisterForm()" [disabled]="!userDataForm.valid">Next
      <svg width="1em" height="1em" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
        viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg></button>
  </div>
</div>