import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserPersonalService} from '../../../services/user-personal.service';
import {UserDetails} from '../../../model/UserDetails';
import {Router} from '@angular/router';
import {TokenStorageService} from '../../../services/token-storage.service';
import {ERole} from '../../../model/enums/ERole';
import {BaseUserInputDataComponent} from '../../MAIN_COMPONENTS/BaseUserInputDataComponent';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent extends BaseUserInputDataComponent implements OnInit {

  userDataForm: FormGroup;
  currentModal: number;
  phoneCopy: string;
  modalVisible: boolean;
  addressModalVisible: boolean;
  isUserPainter: boolean;

  constructor(private formBuilder: FormBuilder,
              private userService: UserPersonalService,
              private router: Router,
              private tokenStorage: TokenStorageService) {
    super();

    this.isUserPainter = tokenStorage.isUserPainter();
    this.userDataForm = this.prepareForm(this.isUserPainter);
    this.currentModal = 0;
    this.phoneCopy = '';
    this.modalVisible = true;
    this.addressModalVisible = false;
  }

  ngOnInit(): void {
    if (!this.tokenStorage.isAuthenticated()) {
      this.router.navigateByUrl('/login');
    }
    if (!this.tokenStorage.ifFirstLogin()) {
      this.tokenStorage.doDefaultRouting();
    }
  }

  checkIfDisplayModal(modalNumber: number): boolean {
    return this.currentModal === modalNumber;
  }

  prepareForm(isPainter: boolean): FormGroup {
    if (isPainter) {
      return this.formBuilder.group({
        fullName: ['', [Validators.required, Validators.minLength(1), Validators.pattern(this.fullNamePattern)]],
        city: ['', [Validators.required, Validators.minLength(this.cityMinLength), Validators.pattern(this.cityNamePattern)]],
        state: ['', [Validators.required]],
        street: ['', [Validators.required, Validators.pattern(this.streetPattern)]],
        zipCode: ['', [Validators.required, Validators.pattern(this.zipCodePattern)]],
      });
    } else {
      return this.formBuilder.group({
        fullName: ['', [Validators.required, Validators.minLength(1), Validators.pattern(this.fullNamePattern)]],
        phoneNumber: ['', [Validators.required, Validators.minLength(11), Validators.pattern(this.phoneNumberPattern)]],
        city: ['', [Validators.required, Validators.minLength(this.cityMinLength), Validators.pattern(this.cityNamePattern)]],
        state: ['', [Validators.required]],
        street: ['', [Validators.required, Validators.pattern(this.streetPattern)]],
        zipCode: ['', [Validators.required, Validators.pattern(this.zipCodePattern)]],
      });
    }
  }

  buildNewUserDetails(): UserDetails {
    return {
      fullName: this.userDataForm.get('fullName')!.value,
      phoneNumber: this.userDataForm.get('phoneNumber')!.value,
      address: this.userDataForm.get('street')!.value,
      state: this.userDataForm.get('state')!.value,
      city: this.userDataForm.get('city')!.value,
      zipCode: this.userDataForm.get('zipCode')!.value
    };
  }

  buildNewPainterDetails(): UserDetails {
    return {
      fullName: this.userDataForm.get('fullName')!.value,
      address: this.userDataForm.get('street')!.value,
      city: this.userDataForm.get('city')!.value,
      state: this.userDataForm.get('state')!.value,
      zipCode: this.userDataForm.get('zipCode')!.value
    };
  }

  submitData(): void {
    this.modalVisible = false;
    let userDetails: UserDetails;
    let role: ERole;
    if (this.isUserPainter) {
      userDetails = this.buildNewPainterDetails();
      role = ERole.ROLE_PAINTER;
    } else {
      userDetails = this.buildNewUserDetails();
      role = ERole.ROLE_CUSTOMER;
    }
    this.userService.fillUserDetails(userDetails, role).subscribe(data => {
        this.tokenStorage.setFirstLogin();
        this.tokenStorage.saveUser(Object.assign(this.tokenStorage.getUser(), userDetails));
        this.tokenStorage.doDefaultRouting();
      },
      error => {
        console.log(error);
      });
  }

  incraseModal(): void {
    this.currentModal++;
    if ((this.isUserPainter) && (this.currentModal === 2)) {
      this.currentModal++;
    }
  }

  onEnterClicked(): void {
    if (this.checkIfDisplayModal(0)) {
      this.incraseModal();
    } else if (this.checkIfDisplayModal(1) && !this.checkFullNameForErrors()) {
      this.incraseModal();
    } else if (this.checkIfDisplayModal(2) && !this.checkForPhoneNumberErrors()) {
      this.incraseModal();
    } else if (this.checkIfDisplayModal(3) && !this.checkForAddressErrors()) {
      this.incraseModal();
    } else if (this.checkIfDisplayModal(4)) {
      this.submitData();
    }
  }
}
