<div #expandFilters class="res-top-menu">
  <div class="menu-wrapper" (click)="toggleMenu($event)">
    <nb-icon icon="menu-arrow-outline"></nb-icon>
    <div class="menu-text">Menu</div>
  </div>
  <div class="bell-wrapper">
    <app-notification-bell [hide]="false"></app-notification-bell>
  </div>
</div>
<div class="home-route">
  <div #sideNavbar class="side-navbar-wrapper" (window:resize)="windowResizeEvent()">
    <app-side-navbar>
      <div hide-or-expand *ngIf="menuVisible" class="hide-or-expand-content">
        <div class="menu-wrapper" (click)="toggleMenu($event)">
          <nb-icon icon="menu-arrow-outline"></nb-icon>
          <div class="menu-text">Hide</div>
        </div>
      </div>

    </app-side-navbar>
  </div>
  <div id="childRoutersHomeComponent" #childRoutersHomeComponent>
    <router-outlet></router-outlet>
  </div>
</div>
