<div class="smooth-scroll">
    <app-header [userTheme]="true"></app-header>

    <section class="hero_sec">
        <div class="row">
            <div class="col-md-6">
                <h1>
                    {{data.heading1}}
                </h1>
                <p class="light_txt">
                    {{data.description1}}
                </p>
            </div>
            <div class="col-md-6">
                <img src="assets/image.svg" alt="'idea">
            </div>
        </div>
    </section>

    <section class="how-it-works-newer">
        <div class="cstmr-stps">
            <div class="how-does-it-work" id="section-two-index">How does it work?</div>
            <section id="section-two" class="customer-steps">
                <div class="outer-flex">
                    <div class="left-flex">
                        <div class="item">
                            <img [src]="data.image2" class="left-img" alt="'idea">
                        </div>
                        <div class="item mt-20">
                            <p class="label-xl">Step#2</p>
                            <p class="head">{{data.heading4}} ></p>
                            <p class="description">{{data.description3}}
                            </p>
                        </div>
                        <div class="item">
                            <img [src]="data.image4" class="left-img hgt-2 customer_img3" alt="meeting">
                        </div>
                    </div>
                </div>
                <div class="center-flex">
                    <div class="timeline"></div>
                    <div class="timeline-item">
                        <div class="undr-tymlyn">

                        </div>
                    </div>
                    <div class="timeline-item">
                        <div class="undr-tymlyn">

                        </div>
                    </div>
                    <div class="timeline-item last-step">success</div>
                </div>
                <div class="outer-flex">
                    <div class="right-flex">
                        <div class="item">
                            <p class="label-xl">Step#1</p>
                            <p class="head">{{data.heading3}} ></p>
                            <p class="description">{{data.description2}} <br> <a href="">brushexchange.com</a></p>
                        </div>
                        <div class="item mb-5 ">
                            <!-- <img src="assets/illustrations/landing-page/plan.svg" class="right-img" alt="plan"> -->
                            <img [src]="data.image3" class="right-img" alt="plan">

                        </div>
                        <div class="item item5">
                            <p class="label-xl">Step#3</p>
                            <p class="head">{{data.heading5}} ></p>
                            <p class="description">{{data.description4}}</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>

    <section class="how-it-works-new d-none">
        <h2 class="heading">
            How it Works
        </h2>
        <div class="how-steps-works">
            <div class="step-1">
                <div class="circle-box">
                    <img [src]="data.image2" alt="">
                </div>
                <img src="./assets/up-arrow.png" alt="" class="arrow-icn">
                <div class="text-center">
                    <h3> {{data.heading3}}</h3>
                </div>

                <h2>{{data.description2}} <br> <a href="">brushexchange.com</a></h2>
            </div>

            <div class="step-2">
                <div class="circle-box">
                    <img [src]="data.image3" alt="">
                </div>
                <img src="./assets/up-arrow.png" alt="" class="arrow-icn">
                <div class="text-center">
                    <h3> {{data.heading4}}</h3>
                </div>
                <h2> {{data.description3}}</h2>
            </div>


            <div class="step-3">
                <div class="circle-box">
                    <img [src]="data.image4" alt="">
                </div>
                <img src="./assets/up-arrow.png" alt="" class="arrow-icn">
                <div class="text-center">
                    <h3> {{data.heading5}}</h3>
                </div>
                <h2> {{data.description4}}</h2>
            </div>


            <!-- <div class="step-4">
                <div class="circle-box">
                    <img [src]="data.image4" alt="">
                </div>
                <img src="./assets/up-arrow.png" alt="" class="arrow-icn">
                <div class="text-center">
                    <h3> {{data.heading5}}</h3>
                </div>
                <h2> {{data.heading5}}</h2>
            </div>-->


        </div>
    </section>


    <section class="how_works_sec">
        <div class="how_wrok">
            <h2 class="heading">
                How it Works
            </h2>
            <p class="heading_pr light_txt">
                Lorem ipsum dolor sit amet consectetur.
            </p>
            <div class="row">
                <div class="col-md-4">
                    <nb-card>
                        <nb-card-body>
                            <img src="assets/illustrations/landing-page/idea.svg" alt="'idea">
                            <h2>
                                Create project
                            </h2>
                            <p class="light_txt">
                                Enter your project details and receive free fair price estimate!
                            </p>
                        </nb-card-body>
                        <span>1</span>
                    </nb-card>
                </div>
                <div class="col-md-4">
                    <nb-card>
                        <nb-card-body>
                            <img src="assets/illustrations/landing-page/plan.svg" alt="'idea">
                            <h2>
                                Post your ad
                            </h2>
                            <p class="light_txt">
                                Post your project to our marketplace and wait for bids from our painters!
                            </p>
                        </nb-card-body>
                        <span>2</span>
                    </nb-card>
                </div>
                <div class="col-md-4">
                    <nb-card>
                        <nb-card-body>
                            <img src="assets/illustrations/landing-page/meeting.svg" alt="'idea">
                            <h2>
                                Choose best bid
                            </h2>
                            <p class="light_txt">
                                Make an appointment with your selected painter and schedule your project!
                            </p>
                        </nb-card-body>
                        <span>3</span>
                    </nb-card>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</div>