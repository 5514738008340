import {Pipe, PipeTransform} from '@angular/core';
import {EBidStatus} from '../model/enums/EBidStatus';
import {ERoomType} from '../model/enums/ERoomType';

@Pipe({name: 'roomImagePipe'})
export class RoomImagePipe implements PipeTransform {

  transform(value: any): any {
    switch (ERoomType[value]) {
      case ERoomType.LIVING_ROOM:
        return '/assets/project-creator/living-room.png';
      case ERoomType.BATHROOM:
        return '/assets/project-creator/bathroom.png';
      case ERoomType.BEDROOM:
        return '/assets/project-creator/bedroom.png';
      case ERoomType.DINING_ROOM:
        return '/assets/project-creator/dining-room.png';
      case ERoomType.KITCHEN:
        return '/assets/project-creator/kitchen.png';
      case ERoomType.HALLWAY:
        return '/assets/project-creator/hallway.png';
      case ERoomType.OTHER:
        return '/assets/project-creator/others.png';
    }
  }
}
