<div class="route-title title_header">
  <div class="">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 12H5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 19L5 12L12 5" stroke="#200920" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span>Projects</span>
  </div>
  <app-notification-bell></app-notification-bell>
</div>
<div class="">
  <div class="projects_list wid_set_numb">
    <button class="custom-button" routerLink="/home/new-project">+ Create New Project</button>
    <!-- <div class="card card_shadow">
      <div class="card-body">
        <div class="d-flex">
          <h1>jjjknk</h1>
        </div>
      </div>
    </div> -->
    <nb-card class="nb-theme-brush card">
      <nb-card-body>

        <div class="projects-wrapper">
          <!-- <app-project-component [marketplace]="false" *ngFor="let item of projectsAwaitingPageableResponse?.content"
            [inputData]="item" (changeProjectStatus)="changeProjectStatus($event)"
            (showProjectDetails)="showProjectDetails($event)">
          </app-project-component> -->
          <app-project-component [marketplace]="false" *ngFor="let item of projectsAwaitingPageableResponse?.content"
            [inputData]="item" (changeProjectStatus)="changeProjectStatus($event)"
            (showProjectDetails)="showProjectDetails($event)">
          </app-project-component>
          <app-pagination [adjacentPagesNo]="2" *ngIf="projectsAwaitingPageableResponse"
            (newPageNumber)="changePageNumber($event)" [totalPages]="projectsAwaitingPageableResponse.totalPages"
            [pageNumber]="projectsAwaitingPageableResponse.pageable?.pageNumber">
          </app-pagination>
        </div>
        <!-- <nb-tabset #tabset (changeTab)="onChangeTab($event)">
          <nb-tab *ngIf="projectsInDraftPageableResponse"
                  tabTitle="In draft({{projectsInDraftPageableResponse.totalElements}})">
            <div class="projects-wrapper">
              <app-project-component [marketplace]="false" *ngFor="let item of projectsInDraftPageableResponse?.content"
                                     [inputData]="item"
                                     (changeProjectStatus)="changeProjectStatus($event)"
                                     (showProjectDetails)="showProjectDetails($event)"
              ></app-project-component>
              <app-pagination [adjacentPagesNo]="2" *ngIf="projectsInDraftPageableResponse"
                              (newPageNumber)="changePageNumber($event)"
                              [totalPages]="projectsInDraftPageableResponse.totalPages"
                              [pageNumber]="projectsInDraftPageableResponse.pageable?.pageNumber"></app-pagination>
            </div>
          </nb-tab>
          <nb-tab *ngIf="projectsAwaitingPageableResponse"
                  tabTitle="Awaiting({{projectsAwaitingPageableResponse.totalElements}})">
            <div class="projects-wrapper">
              <app-project-component [marketplace]="false" *ngFor="let item of projectsAwaitingPageableResponse?.content"
                                     [inputData]="item"
                                     (changeProjectStatus)="changeProjectStatus($event)"
                                     (showProjectDetails)="showProjectDetails($event)"
              ></app-project-component>
              <app-pagination [adjacentPagesNo]="2" *ngIf="projectsAwaitingPageableResponse"
                              (newPageNumber)="changePageNumber($event)"
                              [totalPages]="projectsAwaitingPageableResponse.totalPages"
                              [pageNumber]="projectsAwaitingPageableResponse.pageable?.pageNumber"></app-pagination>
            </div>
          </nb-tab>
          <nb-tab *ngIf="projectsPendingPageableResponse" [active]="true"
                  tabTitle="Pending({{projectsPendingPageableResponse.totalElements}})">
            <div class="projects-wrapper">
              <app-project-component [marketplace]="false" *ngFor="let item of projectsPendingPageableResponse?.content"
                                     [inputData]="item"
                                     (changeProjectStatus)="changeProjectStatus($event)"
                                     (showProjectDetails)="showProjectDetails($event)"
              ></app-project-component>
              <app-pagination [adjacentPagesNo]="2" *ngIf="projectsPendingPageableResponse"
                              (newPageNumber)="changePageNumber($event)"
                              [totalPages]="projectsPendingPageableResponse.totalPages"
                              [pageNumber]="projectsPendingPageableResponse.pageable?.pageNumber"></app-pagination>
            </div>
          </nb-tab>
          <nb-tab *ngIf="projectsInProgressPageableResponse"
                  tabTitle="In progress({{projectsInProgressPageableResponse.totalElements}})">
            <div class="projects-wrapper">
              <app-project-component [marketplace]="false" *ngFor="let item of projectsInProgressPageableResponse?.content"
                                     [inputData]="item"
                                     (changeProjectStatus)="changeProjectStatus($event)"
                                     (showProjectDetails)="showProjectDetails($event)"
              ></app-project-component>
              <app-pagination [adjacentPagesNo]="2" *ngIf="projectsInProgressPageableResponse"
                              (newPageNumber)="changePageNumber($event)"
                              [totalPages]="projectsInProgressPageableResponse.totalPages"
                              [pageNumber]="projectsInProgressPageableResponse.pageable?.pageNumber"></app-pagination>
            </div>
          </nb-tab>
    
          <nb-tab *ngIf="projectsCompletedPageableResponse"
                  tabTitle="Completed({{projectsCompletedPageableResponse.totalElements}})">
            <div class="projects-wrapper">
              <app-project-component [marketplace]="false" *ngFor="let item of projectsCompletedPageableResponse?.content"
                                     [inputData]="item"
                                     (changeProjectStatus)="changeProjectStatus($event)"
                                     (showProjectDetails)="showProjectDetails($event)"
              ></app-project-component>
              <app-pagination [adjacentPagesNo]="2" *ngIf="projectsCompletedPageableResponse"
                              (newPageNumber)="changePageNumber($event)"
                              [totalPages]="projectsCompletedPageableResponse.totalPages"
                              [pageNumber]="projectsCompletedPageableResponse.pageable?.pageNumber"></app-pagination>
            </div>
          </nb-tab>
          <nb-tab *ngIf="projectsArchivedPageableResponse"
                  tabTitle="Archived({{projectsArchivedPageableResponse.totalElements}})">
            <div class="projects-wrapper">
              <app-project-component [marketplace]="false" *ngFor="let item of projectsArchivedPageableResponse?.content"
                                     [inputData]="item"
                                     (changeProjectStatus)="changeProjectStatus($event)"
                                     (showProjectDetails)="showProjectDetails($event)"
              ></app-project-component>
              <app-pagination [adjacentPagesNo]="2" *ngIf="projectsArchivedPageableResponse"
                              (newPageNumber)="changePageNumber($event)"
                              [totalPages]="projectsArchivedPageableResponse.totalPages"
                              [pageNumber]="projectsArchivedPageableResponse.pageable?.pageNumber"></app-pagination>
            </div>
          </nb-tab>
          <nb-tab *ngIf="projectsComplainPageableResponse"
                  tabTitle="Complain({{projectsComplainPageableResponse.totalElements}})">
            <div class="projects-wrapper">
              <app-project-component [marketplace]="false" *ngFor="let item of projectsComplainPageableResponse?.content"
                                     [inputData]="item"
                                     (changeProjectStatus)="changeProjectStatus($event)"
                                     (showProjectDetails)="showProjectDetails($event)"
              ></app-project-component>
              <app-pagination [adjacentPagesNo]="2" *ngIf="projectsComplainPageableResponse"
                              (newPageNumber)="changePageNumber($event)"
                              [totalPages]="projectsComplainPageableResponse.totalPages"
                              [pageNumber]="projectsComplainPageableResponse.pageable?.pageNumber"></app-pagination>
            </div>
          </nb-tab>
    
    
    
    
        </nb-tabset> -->
      </nb-card-body>
    </nb-card>

  </div>

  <!-- <div class="filters_div">
    <app-filters></app-filters>
  </div> -->

</div>