import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {ERole} from '../model/enums/ERole';
import {Router} from '@angular/router';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const USER_EMAIL_KEY = 'auth-email';
const BRUSH_PROFILE_IMAGE = 'brush-profile-image';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor(private jwtHelper: JwtHelperService, private router: Router) {
  }

  public ifFirstLogin(): boolean {
    const user = this.getUser();
    return user.firstLogin;
  }

  setFirstLogin(): void {
    const user = this.getUser();
    user.firstLogin = false;
    this.saveUser(user);
  }

  public saveToken(token: string, saveToLocalStorage: boolean): void {
    if (saveToLocalStorage) {
      window.localStorage.removeItem(TOKEN_KEY);
      window.localStorage.setItem(TOKEN_KEY, token);
    }
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public getProfileImage(): string {
    const image = window.sessionStorage.getItem(BRUSH_PROFILE_IMAGE);
    if (image !== '' && image != null) {
      return image;
    }
    return '';
  }

  public saveProfileImage(profileImage: string): void {
    window.sessionStorage.removeItem(BRUSH_PROFILE_IMAGE);
    window.sessionStorage.setItem(BRUSH_PROFILE_IMAGE, profileImage);
  }

  public getTokenFromLocalStorage(): string | null {
    const tokenFromLocalStorage = window.localStorage.getItem(TOKEN_KEY);
    if (window.sessionStorage.getItem(TOKEN_KEY) == null && tokenFromLocalStorage != null) {
      window.sessionStorage.setItem(TOKEN_KEY, tokenFromLocalStorage);
    }
    return tokenFromLocalStorage;
  }

  public saveEmail(email: string): void {
    window.sessionStorage.removeItem(USER_EMAIL_KEY);
    window.sessionStorage.setItem(USER_EMAIL_KEY, email);
  }

  public getEmail(): string | any {
    return window.sessionStorage.getItem(USER_EMAIL_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUserRoles(): any {
    const user = this.getUser();
    return user.role;
  }

  public isAdmin(): boolean {
    const role = this.getUserRoles();
    switch (role as ERole) {
      case ERole.ROLE_ADMIN :
        return true;
    }
    return false;
  }

  public getUserClientID(): string {
    const user = this.getUser();
    return user.clientID;
  }

  public getFullname(): string {
    const user = this.getUser();
    return user.fullName;
  }

  public isUserAdmin(): boolean {
    return this.getUserRoles() === ERole.ROLE_ADMIN;
  }

  public isUserPainter(): boolean {
    return this.getUserRoles() === ERole.ROLE_PAINTER;
  }

  public isUserCustomer(): boolean {
    return this.getUserRoles() === ERole.ROLE_CUSTOMER;
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  public getId(): any {
    return this.getUser().id;
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    if (token != null) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }

  doDefaultRouting(): void {
    if (this.isUserPainter()) {
      this.router.navigateByUrl('/home/marketplace');
    } else if (this.isUserCustomer()) {
      this.router.navigateByUrl('/home/my-projects');
    } else if (this.isUserAdmin()) {
      this.router.navigateByUrl('/home/admin');
    }
  }
  public logout(): void {
    window.sessionStorage.clear();
    this.removeTokenFromLocalStorage();
    this.router.navigateByUrl('login');
  }

  public removeTokenFromLocalStorage(): void {
    window.localStorage.removeItem(TOKEN_KEY);
  }
}
