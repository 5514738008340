import { Component, OnInit } from '@angular/core';
import { HttpserviceService } from '../../../../services/httpservice.service';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-notification',
  templateUrl: './new-notification.component.html',
  styleUrls: ['./new-notification.component.css']
})
export class NewNotificationComponent implements OnInit {
notifications: any=[]
 isPainter = false;
    isCustomer = false;
  moment =moment;
  count: any
  constructor(private authService: HttpserviceService, private router: Router) { }

  ngOnInit(): void {
    if(localStorage.getItem('role')=='painter'){
             this.isPainter = true;
      }else if(localStorage.getItem('role')=='customer'){
             this.isCustomer = true;
      }
  this.getnotifications()
  }

  getnotifications(){
		  this.authService.getnotification().subscribe((response: any) => {
		  this.notifications = response.notifications
          this.count= response.count
  })
  }

  delete(id: any){
  let obj={
		'id': id
  }
    this.authService.deletenoti(obj).subscribe((response: any) => {
		//  this.notifications = response.notifications
        window.location.reload();
		this.getnotifications();
  })
  }

  unseennoti(id: any, type: any){
    let obj={
		'id': id
  }
    this.authService.seennoti(obj).subscribe((response: any) => {
	//	  this.notifications = response.notifications
          //  window.location.reload();

 this.authService.getnotification().subscribe((response: any) => {
		  this.notifications = response.notifications
         localStorage.setItem('noti',response.notifications[0]?.unseen_notification)
         window.location.reload();
  })
  })
  }


    viewDemoDetailsscomp(id: any, type:any) {
    console.log('a')
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-jobs/details/'+id]);
  }

    viewDemoDetailss(id: any, type:any) {
   localStorage.setItem('type', type)
    this.router.navigate(['/home/my-jobs/completed-details/'+id]);
  }
}
