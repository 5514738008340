import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxInputComponent),
      multi: true
    }
  ]
})
export class CheckboxInputComponent implements OnInit, ControlValueAccessor {

  @Input() checked = false;
  @Input() label!: string;
  @Input() index!: string;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor() {
  }

  ngOnInit(): void {
  }

  onModelChange(e: any): void {
    this.checked = e;
    this.onChange(e);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: any): void {
    this.checked = checked;
  }


}
