<div class="wrapper">
  <div class="project-creator-header"></div>

  <div class="page">
    <div class="stepper-create-project">
      <nb-card>
        <nb-card-body>
          <nb-stepper orientation="horizontal" #stepper [(selectedIndex)]="stepperIndex">
            <nb-step [label]="labelOne">
              <ng-template #labelOne>
                <div class="step-header-cstm">
                  <p>Choose Project Type</p>
                  <p class="step-status mt-auto" *ngIf="show && show1 && project_type">
                    Completed
                  </p>
                  <p class="step-status pending mt-auto" *ngIf="!show && !show1 && !project_type">
                    Pending
                  </p>
                  <!-- <p class="step-status processing mt-auto" *ngIf="show || show1 || project_type">
                                        Processing
                                    </p>-->
                </div>
              </ng-template>
              <div class="project-creator-header">
                <button class="custom-button" routerLink="/home/make-new-project">
                  BACK
                </button>
              </div>
              <div class="choose-project-cntnt">
                <div class="page" *ngIf="show">
                  <div class="page-title">Choose project type</div>
                  <div class="choose-project-type">
                    <div class="project-type-wrapper">
                      <div class="project-type-card">
                        <img src="./assets/project-creator/interior-type.png" alt="projectTypeImage"
                          (click)="choseProject('interior')" />
                        <div class="project-type-info-wrapper">
                          <div class="project-type-label">Interior</div>
                          <div class="project-type-description">
                            Interior projects are all about the work that needs
                            to be done inside your apartment. Anything from
                            painting your kitchen to putting a wallpaper in your
                            bedroom.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="choose-project-type">
                    <div class="project-type-wrapper">
                      <div class="project-type-card">
                        <img src="./assets/project-creator/exterior-type.png" alt="projectTypeImage"
                          (click)="choseProject('exterior')" />
                        <div class="project-type-info-wrapper">
                          <div class="project-type-label">Exterior</div>
                          <div class="project-type-description">
                            Exterior projects include all jobs related with
                            outer part of your house.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>

                <div class="page mt-4" *ngIf="show1">
                  <div class="page-title">Is this home or a business</div>
                  <div class="displaying-pages-images">
                    <div class="image-wrapper">
                      <img class="image-image" src="./assets/project-creator/residential.png" alt="section-item"
                        (click)="homeorbusiness('residential')" />
                      <div class="image-label">Residential</div>
                    </div>

                    <div class="image-wrapper">
                      <img class="image-image" src="./assets/project-creator/business.png" alt="section-item"
                        (click)="homeorbusiness('business')" />
                      <div class="image-label">Business</div>
                    </div>
                  </div>
                </div>

                <!-- exterior -->

                <div class="page mt-4" *ngIf="propertyshow && project_type == 'interior'">
                  <div class="page-title">
                    What kind of property do you need painted?
                  </div>
                  <div class="displaying-pages-images" (click)="propertyselect('apartment')">
                    <div class="image-wrapper">
                      <img class="image-image" src="./assets/project-creator/apartment.png" alt="section-item" />
                      <div class="image-label">Apartment</div>
                    </div>

                    <div class="image-wrapper" (click)="propertyselect('house')">
                      <img class="image-image" src="./assets/project-creator/house.png" alt="section-item" />
                      <div class="image-label" (click)="propertyselect('house')">
                        House
                      </div>
                    </div>

                    <div class="image-wrapper" (click)="propertyselect('townhouse')">
                      <img class="image-image" src="./assets/project-creator/townhouse.png" alt="section-item" />
                      <div class="image-label" (click)="propertyselect('townhouse')">
                        Townhouse
                      </div>
                    </div>

                    <div class="image-wrapper" (click)="propertyselect('condo')">
                      <img class="image-image" src="./assets/project-creator/condo.png" alt="section-item"
                        [(ngModel)]="proppaint" />
                      <div class="image-label" (click)="propertyselect('condo')">
                        Condo
                      </div>
                    </div>

                    <!--     <div class="image-wrapper" (click)="propertyselect('business')">
                                            <img class="image-image" src="./assets/project-creator/business.png"
                                                alt="section-item"  [(ngModel)]="proppaint" >
                                            <div class="image-label"  (click)="propertyselect('business')">Business</div>
                                        </div>
                                          <div class="image-wrapper" (click)="propertyselect('residential')">
                                            <img class="image-image" src="./assets/project-creator/residential.png"
                                                alt="section-item"  [(ngModel)]="proppaint" >
                                            <div class="image-label" (click)="propertyselect('residential')">Residential</div>
                                        </div>-->
                  </div>
                </div>

                <div class="page mt-4" *ngIf="propertyshow && project_type == 'exterior'">
                  <div class="page-title">
                    What kind of property do you need painted?
                  </div>
                  <div class="displaying-pages-images">
                    <div *ngFor="let item of otherproperty">
                      <div class="image-wrapper" *ngIf="item.category == 'advance'">
                        <img class="image-image" [src]="item.image" alt="section-item"
                          (click)="propertyselectext(item.title, item.id)" />
                        <div class="image-label" (click)="propertyselectext(item.title, item.id)">
                          {{ item.title }}
                        </div>
                      </div>
                      <div class="image-wrapper" *ngIf="!item.category">
                        <img class="image-image" [src]="item.image" alt="section-item"
                          (click)="propertyselectext(item.title, item.id)" />
                        <div class="image-label" (click)="propertyselectext(item.title, item.id)">
                          {{ item.title }}
                        </div>
                      </div>
                    </div>

                    <div class="image-wrapper" (click)="openmodalimage()">
                      <img class="image-image" src="./assets/project-creator/apartment.png" alt="section-item" />
                      <div class="image-label">Add more</div>
                    </div>
                  </div>
                </div>

                <div class="pages mt-4" *ngIf="
                    (project_type == 'exterior' || project_type == 'others') &&
                    showmaterial
                  ">
                  <div class="page-title">
                    Select surface type you need painted
                  </div>

                  <div class="select_room_no">
                    <select name="" id="" class="select_input" [(ngModel)]="material"
                      (change)="changeCheckboxStatematerialnew(material)">
                      <option value="" disabled>Select</option>
                      <option *ngFor="let item of materials" [value]="item.surface_type">
                        {{ item.surface_type }}
                      </option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <div *ngIf="
                    (project_type == 'exterior' || project_type == 'others') &&
                    showmaterial
                  ">
                  <button nbButton nbStepperNext>next</button>
                </div>
              </div>
            </nb-step>
            <nb-step [label]="labelTwo">
              <div class="project-creator-header" *ngIf="
                  project_type == 'interior' &&
                  (this.productForm.value.plans.length || shownesxt)
                ">
                <button class="custom-button" nbButton nbStepperPrevious (click)="first()">
                  BACK
                </button>
              </div>
              <div class="project-creator-header" *ngIf="project_type == 'exterior'">
                <button class="custom-button" nbButton nbStepperPrevious (click)="first()">
                  BACK
                </button>
              </div>

              <ng-template #labelTwo>
                <div class="step-header-cstm">
                  <p>Room Details</p>

                  <p class="step-status mt-auto" *ngIf="showwhatpainted && painted && rooms_painted">
                    Completed
                  </p>
                  <p class="step-status pending mt-auto" *ngIf="!showwhatpainted && !painted && !rooms_painted">
                    Pending
                  </p>
                </div>
              </ng-template>

              <div class="room-details-cntnt">
                <div class="mt-4 paged" *ngIf="providibf_items && project_type == 'interior'">
                  <div class="page-title">Are you providing any supplies?</div>

                  <div class="displaying-pages-boxes">
                    <div class="box" (click)="pItems('not_at_all')">
                      No at all
                    </div>

                    <div class="box" (click)="pItems('only_paint')">
                      I will provide only paint
                    </div>

                    <div class="box" (click)="pItems('paint_supplies')">
                      Paint and painting supplies
                    </div>

                    <div class="box" (click)="pItems('need_list')">
                      I can provide all but need list
                    </div>
                  </div>
                </div>

                <!--  <div class="mt-4 paged" *ngIf="pType &&  project_type=='interior'">
                                    <div class="page-title">What kind of property?
                                    </div>

                                    <div class="displaying-pages-boxes">



                                        <div class="box" (click)="propertyType('bunglow')">
                                            Bungalow </div>

                                        <div class="box" (click)="propertyType('storey')">
                                            Storey house </div>

                                        <div class="box" (click)="propertyType('tenement')">
                                            Tenement </div>

                                        <div class="box" (click)="propertyType('condo')">
                                            Condo </div>
                                        <div class="box" (click)="propertyType('multi_storey')">
                                            Multi-storey building </div>

                                    </div>

                                </div>-->

                <div class="mt-4 paged" *ngIf="whendone && project_type == 'interior'">
                  <div class="page-title">
                    When would you like the job to be done?
                  </div>

                  <div class="displaying-pages-boxes">
                    <div class="box" (click)="whentobe('during_the_day')">
                      During the day
                    </div>

                    <div class="box" (click)="whentobe('anytime')">Anytime</div>

                    <div class="box" (click)="whentobe('I_can_adjust_to_the_painter')">
                      I can adjust to the painter
                    </div>
                  </div>
                </div>

                <!-- advance-exterior -->

                <!--  <div class="paged mt-4">

                                    <div class="page-title">What do you want painted?</div>


                                    <div class="displaying-pages-images-multiple">
                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/walls.png"
                                                alt="section-item">
                                            <div class="image-label">Walls
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/doors.png"
                                                alt="section-item">
                                            <div class="image-label">Doors
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/window-frames.png"
                                                alt="section-item">
                                            <div class="image-label">Window frames
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <!-- advance -->

                <div class="paged mt-4 cntnt-center" *ngIf="addrooms && project_type == 'interior'">
                  <div class="manage-room">
                    <div class="page-title">Manage your rooms</div>
                    <div class="d-flex crd_wpr" *ngIf="addedrroms.length">
                      <div class="div_inr_cost mrgn_ryt_txt" *ngFor="let item of addedrroms">
                        <div class="room-card-wrapper">
                          <div class="div_imgs_room">
                            <img class="bed_innr" src="assets/Rectangle.png" alt="" />
                          </div>
                          <div class="info tym_info">
                            <div class="room-type">
                              {{ item.sel_rrom[0] | titlecase }}
                            </div>

                            <!-- <div class="cost" *ngIf="item?.details?.ceiling_paint_cost != 0">
                              Ceiling paint cost : $
                              {{
                              item?.details?.ceiling_paint_cost
                              | number : "1.0-2"
                              }}
                            </div> -->
                            <!-- <div class="cost">
                              Wall paint cost :
                              {{
                              item.details.wall_paint_cost | number : "1.0-2"
                              }}
                            </div> -->

                            <div class="cost" *ngIf="item?.details?.gallons_ceiling_paint!=0">
                              Gallons ceiling paint :
                              {{
                              item?.details?.gallons_ceiling_paint
                              | number : "1.0-2"
                              }}
                            </div>
                            <div class="cost">
                              Gallons wall paint : 
                              {{
                              item?.details?.gallons_wall_paint
                              | number : "1.0-2"
                              }}
                            </div>
                            <div class="cost">
                              High range : $
                              {{ item?.details?.high_range | number : "1.0-2" }}
                            </div>

                            <div class="cost">
                              Low range : $
                              {{ item?.details?.low_range | number : "1.0-2" }}
                            </div>
                            <!-- <div class="cost">
                              Prep time :
                              {{ item?.details?.prepTime | number : "1.0-2" }}
                            </div> -->
                            <!-- <div class="cost">
                              Labour hours :
                              {{
                              item?.details?.labour_hour | number : "1.0-2"
                              }}
                            </div> -->
                            <!-- <div class="cost">
                              Surface area :
                              {{
                              item?.details?.surfaceArea | number : "1.0-2"
                              }}
                              sqft
                            </div> -->
                            <!-- <div class="cost">
                              Total : $
                              {{
                              item?.details?.final_price | number : "1.0-2"
                              }}
                            </div> -->

                            <!--  <div class="undr_lne_text">View Details </div>-->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="new_crf">
                      <div class="custom-button" *ngIf="addedrroms.length" type="button" (click)="addnewrroms()">
                        Add Room
                      </div>
                      <div class="no-rooms-added" *ngIf="!addedrroms.length">
                        You haven't added any rooms yet. Add a room to continue.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="paged mt-4 cntnt-center" *ngIf="addroomsext && project_type == 'exterior'">
                  <div class="manage-room">
                    <div class="page-title">Manage your walls</div>

                    <div class="custom-button" *ngIf="addedrroms.length">
                      Add Walls
                    </div>
                    <div class="no-rooms-added" *ngIf="!addedrroms.length">
                      You have not added any walls yet. Add a walls to continue.
                    </div>
                  </div>
                </div>

                <!-- advance -->

                <div class="paged mt-4" *ngIf="all_applicables && project_type == 'interior'">
                  <div class="page-title">Room Type</div>

                  <div class="displaying-pages-images-multiple">
                    <div [ngClass]="[
                        item.selected
                          ? 'selected image-wrapper'
                          : 'image-wrapper'
                      ]" *ngFor="let item of items; let i = index" (click)="changeCheckboxStateroom(item.value, i)">
                      <div id="i" [ngClass]="[
                          item.selected
                            ? 'selected image-wrapper'
                            : 'image-wrapper'
                        ]">
                        <img class="image-image" [src]="item.image" alt="section-item" />
                        <div [ngClass]="seletcedis(i)" class="image-label">
                          {{ item.label }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--<button (click)="nethh()">Next</button>
                                    <!-- <div class="displaying-pages-images-multiple">
                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/living-room.png"
                                                alt="section-item">
                                            <div class="image-label">Living Room
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/bathroom.png"
                                                alt="section-item">
                                            <div class="image-label">Bathroom
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/bedroom.png"
                                                alt="section-item">
                                            <div class="image-label">Bedroom
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/dining-room.png"
                                                alt="section-item">
                                            <div class="image-label">Dining Room
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/kitchen.png"
                                                alt="section-item">
                                            <div class="image-label">Kitchen
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/hallway.png"
                                                alt="section-item">
                                            <div class="image-label">Hallway
                                            </div>
                                        </div>

                                        <div class="image-wrapper">
                                            <img class="image-image" src="./assets/project-creator/others.png"
                                                alt="section-item">
                                            <div class="image-label">Others
                                            </div>
                                        </div>

                                    </div>-->
                </div>

                <div class="pages mt-4" *ngIf="nowindows && project_type == 'interior'">
                  <div class="page-title">No. of Windows</div>

                  <div class="select_room_no">
                    <select name="" id="" class="select_input" [(ngModel)]="windows"
                      (change)="changeCheckboxStatematerial(windows)">
                      <option value="" disabled>Select</option>
                      <option *ngFor="let item of no_of_windows" [value]="item.value">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="pages mt-4" *ngIf="nodoors && project_type == 'interior'">
                  <div class="page-title">No. of Doors</div>

                  <div class="select_room_no">
                    <select name="" id="" class="select_input" [(ngModel)]="doors" (change)="changeCheckdoors(doors)">
                      <option value="" disabled>Select</option>
                      <option *ngFor="let item of no_of_doors" [value]="item.value">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="pages mt-4" *ngIf="nowindowsext && project_type == 'exterior'">
                  <div class="page-title">No. of Windows</div>

                  <div class="select_room_no">
                    <select name="" id="" class="select_input" [(ngModel)]="windows"
                      (change)="changeCheckboxStatematerial(windows)">
                      <option value="" disabled>Select</option>
                      <option *ngFor="let item of no_of_windows" [value]="item.value">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="pages mt-4" *ngIf="nodoors && project_type == 'exterior'">
                  <div class="page-title">No. of Doors</div>

                  <div class="select_room_no">
                    <select name="" id="" class="select_input" [(ngModel)]="doors" (change)="changeCheckdoors(doors)">
                      <option value="" disabled>Select</option>
                      <option *ngFor="let item of no_of_doors" [value]="item.value">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="pages mt-4" *ngIf="closetye && project_type == 'interior'">
                  <div class="page-title">How big closet</div>

                  <div class="select_room_no">
                    <select name="" id="" class="select_input" [(ngModel)]="closetshoose"
                      (change)="changecloset(closetshoose)">
                      <option value="" disabled>Select</option>
                      <option *ngFor="let item of closet_choose" [value]="item.value">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mt-4 paged" *ngIf="sconction && project_type == 'interior'">
                  <div class="page-title">
                    How would you rate surface condition in this room?
                  </div>
                  <div class="displaying-pages-boxes-with-images">
                    <div class="displaying-pages-boxes-with-images">
                      <div [ngClass]="[
                          item.selected
                            ? 'selected box-with-image'
                            : 'box-with-image'
                        ]" *ngFor="let item of condition; let i = index" (click)="changecondition(item.value, i)">
                        <div id="i" class="d-flex align-items-center">
                          <img class="box-with-image-image" *ngIf="item.image" alt="box-with-image-icon"
                            [src]="item.image" />
                          <div [ngClass]="[
                              item.selected
                                ? 'box-with-image-label'
                                : 'box-with-image-label'
                            ]">
                            {{ item.label }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 paged" *ngIf="sconctionext && project_type == 'exterior'">
                  <div class="page-title">
                    How would you rate surface condition in this room?
                  </div>
                  <div class="displaying-pages-boxes-with-images">
                    <div class="displaying-pages-boxes-with-images">
                      <div [ngClass]="[
                          item.selected
                            ? 'selected box-with-image'
                            : 'box-with-image'
                        ]" *ngFor="let item of condition; let i = index" (click)="changecondition(item.value, i)">
                        <div id="i" class="d-flex align-items-center">
                          <img class="box-with-image-image" *ngIf="item.image" alt="box-with-image-icon"
                            [src]="item.image" />
                          <div [ngClass]="[
                              item.selected
                                ? 'box-with-image-label'
                                : 'box-with-image-label'
                            ]">
                            {{ item.label }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="paged mt-4" *ngIf="iscrwom && project_type == 'interior'">
                  <div class="page-title">Does room have crown molding?</div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="iscrown('yes')">
                      <div class="box-without-image-label">Yes</div>
                      <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                    </div>
                  </div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="iscrown('no')">
                      <div class="box-without-image-label">No</div>
                      <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                    </div>
                  </div>
                </div>
                <div class="paged mt-4" *ngIf="isrrof && project_type == 'exterior'">
                  <div class="page-title">Does room have roof molding?</div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="isroof('yes')">
                      <div class="box-without-image-label">Yes</div>
                      <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                    </div>
                  </div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="isroof('no')">
                      <div class="box-without-image-label">No</div>
                      <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                    </div>
                  </div>
                </div>

                <div class="paged mt-4" *ngIf="baseyes && project_type == 'interior'">
                  <div class="page-title">Does room have base molding?</div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="isbase('yes')">
                      <div class="box-without-image-label">Yes</div>
                      <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                    </div>
                  </div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="isbase('no')">
                      <div class="box-without-image-label">No</div>
                      <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
                    </div>
                  </div>
                </div>

                <div class="paged mt-4" *ngIf="ceilingis && project_type == 'interior'">

                  <div class="displaying-pages-images-multiple">
                    <div [ngClass]="[
                        item.selected
                          ? 'selected image-wrapper'
                          : 'image-wrapper'
                      ]" *ngFor="let item of itemswhatpainted; let i = index"
                      (click)="changeCheckboxStateroompainted(item.value, i)">
                      <div id="i" [ngClass]="[
                          item.selected
                            ? 'selected image-wrapper'
                            : 'image-wrapper'
                        ]">
                        <img class="image-image" [src]="item.image" alt="section-item" />
                        <div [ngClass]="seletcedisroom(i)" class="image-label">
                          {{ item.label }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="page-title mt-4">Ceiling</div>
                  <form [formGroup]="ceilingGroup" class="">
                    <div class="input-wrapper">
                      <div class="div_eml div_incs">
                        <label for="">Ceiling Length:</label>
                        <input type="number" formControlName="length" placeholder="Enter Length"
                          oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                          class="email ng-dirty ng-valid ng-touched" ng-reflect-name="length">
                      </div>
                      <div class="div_eml div_incs mt_20">
                        <label for="">Ceiling Width:</label>
                        <input type="number" class="form-control" formControlName="width" placeholder="Enter width"
                          oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                          class="email ng-dirty ng-valid ng-touched" ng-reflect-name="length">
                      </div>
                    </div>

                    <div class="color mt_20">
                      <div class="color-title">Current ceiling Color</div>

                      <div class="color-input" [ngStyle]="{
                          background: this.ceilingGroup.value.previousColor
                        }">
                        <span (click)="showsetprev()"><input formControlName="previousColor" /></span>
                      </div>
                      <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse" class="eclipse-image" />
                    </div>
                    <div class="color">
                      <div class="color-title">New ceiling Color</div>
                      <div class="color-input" [ngStyle]="{
                          background: this.ceilingGroup.value.newColor
                        }">
                        <span (click)="showsetnew()">
                          <input formControlName="newColor" /></span>
                      </div>

                      <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse" class="eclipse-image" />
                      <div class="clrs-show" *ngIf="showprev">
                        <!-- <div class="clr-pckr pickr-size">
                          <span
                            value="#FF0000"
                            class="pck-clr red"
                            (click)="setvalue('#FF0000')"
                          ></span>
                          <span
                            value="#FFC0CB"
                            class="pck-clr pink"
                            (click)="setvalue('#FFC0CB')"
                          ></span>
                          <span
                            value="#808080"
                            class="pck-clr gre"
                            (click)="setvalue('#808080')"
                          ></span>
                          <span
                            value="#800080"
                            class="pck-clr purple"
                            (click)="setvalue('#800080')"
                          ></span>
                          <span
                            value="#36013F"
                            class="pck-clr deep-purple"
                            (click)="setvalue('#36013F')"
                          ></span>
                          <span
                            value="#4B0082"
                            class="pck-clr indigo"
                            (click)="setvalue('#4B0082')"
                          ></span>
                          <span
                            value="#0000ff"
                            class="pck-clr blue"
                            (click)="setvalue('#0000ff')"
                          ></span>
                          <span
                            value="#ADD8E6"
                            class="pck-clr light-blue"
                            (click)="setvalue('#ADD8E6')"
                          ></span>
                          <span
                            value="#00FFFF"
                            class="pck-clr cyan"
                            (click)="setvalue('#00FFFF')"
                          ></span>
                          <span
                            value="#008080"
                            class="pck-clr teal"
                            (click)="setvalue('#008080')"
                          ></span>
                          <span
                            value="#008000"
                            class="pck-clr green"
                            (click)="setvalue('#008000')"
                          ></span>
                          <span
                            value="#008080"
                            class="pck-clr light-green"
                            (click)="setvalue('#008080')"
                          ></span>
                          <span
                            value="#00FF00"
                            class="pck-clr lime"
                            (click)="setvalue('#00FF00')"
                          >
                          </span>
                          <span
                            value="#FFFF00"
                            class="pck-clr yellow"
                            (click)="setvalue('#FFFF00')"
                          ></span>
                          <span
                            value="#ffbf00"
                            class="pck-clr amber"
                            (click)="setvalue('#ffbf00')"
                          ></span>
                        </div> -->

                        <div class="clr-pckr pickr-size">
                          <span value="#673d24" class="pck-clr brown" (click)="setvalue('#673d24')"></span>
                          <span value="#d19188" class="pck-clr peach" (click)="setvalue('#d19188')"></span>
                          <span value="#526fa7" class="pck-clr cyan" (click)="setvalue('#526fa7')"></span>
                          <span value="#516e33" class="pck-clr green" (click)="setvalue('#516e33')"></span>
                          <span value="#8076b4" class="pck-clr lavndr" (click)="setvalue('#8076b4')"></span>
                          <span value="#77c2ba" class="pck-clr trquoise" (click)="setvalue('#77c2ba')"></span>
                          <span value="#e67e25" class="pck-clr orange" (click)="setvalue('#e67e25')"></span>
                          <span value="#38489e" class="pck-clr ryl_blue" (click)="setvalue('#38489e')"></span>
                          <span value="#d8404d" class="pck-clr crimson" (click)="setvalue('#d8404d')"></span>
                          <span value="#452562" class="pck-clr deep_purple" (click)="setvalue('#452562')"></span>
                          <span value="#b3cc37" class="pck-clr neon_green" (click)="setvalue('#b3cc37')"></span>
                          <span value="#e6b820" class="pck-clr mustard" (click)="setvalue('#e6b820')"></span>
                          <span value="#272f7f" class="pck-clr dark_blue" (click)="setvalue('#272f7f')">
                          </span>
                          <span value="#43a147" class="pck-clr light_green" (click)="setvalue('#43a147')"></span>
                          <span value="#ba2425" class="pck-clr red" (click)="setvalue('#ba2425')"></span>

                          <span value="#f1d80d" class="pck-clr yellow" (click)="setvalue('#f1d80d')">
                          </span>
                          <span value="#c23b96" class="pck-clr mgnta" (click)="setvalue('#c23b96')"></span>
                          <span value="#027db9" class="pck-clr blue" (click)="setvalue('#027db9')"></span>
                          <span value="#ffffff" class="pck-clr white" (click)="setvalue('#ffffff')"></span>

                          <span value="#ceced3" class="pck-clr grey_20" (click)="setvalue('#ceced3')">
                          </span>
                          <span value="#adacb1" class="pck-clr grey_40" (click)="setvalue('#adacb1')"></span>
                          <span value="#797879" class="pck-clr grey_60" (click)="setvalue('#797879')"></span>
                          <span value="#464548" class="pck-clr grey_80" (click)="setvalue('#464548')"></span>
                          <span value="#181719" class="pck-clr black" (click)="setvalue('#181719')"></span>
                        </div>
                      </div>
                      <div class="clrs-show" *ngIf="shownew">
                        <div class="clr-pckr pickr-size">
                          <span value="#673d24" class="pck-clr brown" (click)="setvaluenew('#673d24')"></span>
                          <span value="#d19188" class="pck-clr peach" (click)="setvaluenew('#d19188')"></span>
                          <span value="#526fa7" class="pck-clr cyan" (click)="setvaluenew('#526fa7')"></span>
                          <span value="#516e33" class="pck-clr green" (click)="setvaluenew('#516e33')"></span>
                          <span value="#8076b4" class="pck-clr lavndr" (click)="setvaluenew('#8076b4')"></span>
                          <span value="#77c2ba" class="pck-clr trquoise" (click)="setvaluenew('#77c2ba')"></span>
                          <span value="#e67e25" class="pck-clr orange" (click)="setvaluenew('#e67e25')"></span>
                          <span value="#38489e" class="pck-clr ryl_blue" (click)="setvaluenew('#38489e')"></span>
                          <span value="#d8404d" class="pck-clr crimson" (click)="setvaluenew('#d8404d')"></span>
                          <span value="#452562" class="pck-clr deep_purple" (click)="setvaluenew('#452562')"></span>
                          <span value="#b3cc37" class="pck-clr neon_green" (click)="setvaluenew('#b3cc37')"></span>
                          <span value="#e6b820" class="pck-clr mustard" (click)="setvaluenew('#e6b820')"></span>
                          <span value="#272f7f" class="pck-clr dark_blue" (click)="setvaluenew('#272f7f')">
                          </span>
                          <span value="#43a147" class="pck-clr light_green" (click)="setvaluenew('#43a147')"></span>
                          <span value="#ba2425" class="pck-clr red" (click)="setvaluenew('#ba2425')"></span>

                          <span value="#f1d80d" class="pck-clr yellow" (click)="setvaluenew('#f1d80d')">
                          </span>
                          <span value="#c23b96" class="pck-clr mgnta" (click)="setvaluenew('#c23b96')"></span>
                          <span value="#027db9" class="pck-clr blue" (click)="setvaluenew('#027db9')"></span>
                          <span value="#ffffff" class="pck-clr white" (click)="setvaluenew('#ffffff')"></span>

                          <span value="#ceced3" class="pck-clr grey_20" (click)="setvaluenew('#ceced3')">
                          </span>
                          <span value="#adacb1" class="pck-clr grey_40" (click)="setvaluenew('#adacb1')"></span>
                          <span value="#797879" class="pck-clr grey_60" (click)="setvaluenew('#797879')"></span>
                          <span value="#464548" class="pck-clr grey_80" (click)="setvaluenew('#464548')"></span>
                          <span value="#181719" class="pck-clr black" (click)="setvaluenew('#181719')"></span>
                          <!-- <span
                            value="#FF0000"
                            class="pck-clr red"
                            (click)="setvaluenew('#FF0000')"
                          ></span>
                          <span
                            value="#FFC0CB"
                            class="pck-clr pink"
                            (click)="setvaluenew('#FFC0CB')"
                          ></span>
                          <span
                            value="#808080"
                            class="pck-clr gre"
                            (click)="setvaluenew('#808080')"
                          ></span>
                          <span
                            value="#800080"
                            class="pck-clr purple"
                            (click)="setvaluenew('#800080')"
                          ></span>
                          <span
                            value="#36013F"
                            class="pck-clr deep-purple"
                            (click)="setvaluenew('#36013F')"
                          ></span>
                          <span
                            value="#4B0082"
                            class="pck-clr indigo"
                            (click)="setvaluenew('#4B0082')"
                          ></span>
                          <span
                            value="#0000ff"
                            class="pck-clr blue"
                            (click)="setvaluenew('#0000ff')"
                          ></span>
                          <span
                            value="#ADD8E6"
                            class="pck-clr light-blue"
                            (click)="setvaluenew('#ADD8E6')"
                          ></span>
                          <span
                            value="#00FFFF"
                            class="pck-clr cyan"
                            (click)="setvaluenew('#00FFFF')"
                          ></span>
                          <span
                            value="#008080"
                            class="pck-clr teal"
                            (click)="setvaluenew('#008080')"
                          ></span>
                          <span
                            value="#008000"
                            class="pck-clr green"
                            (click)="setvaluenew('#008000')"
                          ></span>
                          <span
                            value="#008080"
                            class="pck-clr light-green"
                            (click)="setvaluenew('#008080')"
                          ></span>
                          <span
                            value="#00FF00"
                            class="pck-clr lime"
                            (click)="setvaluenew('#00FF00')"
                          >
                          </span>
                          <span
                            value="#FFFF00"
                            class="pck-clr yellow"
                            (click)="setvaluenew('#FFFF00')"
                          ></span>
                          <span
                            value="#ffbf00"
                            class="pck-clr amber"
                            (click)="setvaluenew('#ffbf00')"
                          ></span> -->
                        </div>
                      </div>
                    </div>
                  </form>

                  <button (click)="newaddnext()">Next</button>
                </div>

                <!-- add-wall -->
                <div class="paged mt-4" *ngIf="move_furniture && project_type == 'interior'">
                  <div class="page-title">Moving furniture?</div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="isfurnitre('no')">
                      <div class="box-without-image-label">No</div>
                    </div>
                  </div>

                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="isfurnitre('multiple_pieces')">
                      <div class="box-without-image-label">Multiple pieces</div>
                    </div>
                  </div>
                  <div class="images-wrapper">
                    <div class="box-without-image" (click)="isfurnitre('couple_pieces')">
                      <div class="box-without-image-label">
                        Couple of pieces
                      </div>
                    </div>
                  </div>
                </div>

                <div class="paged mt-4" *ngIf="add_wall && project_type == 'interior'">
                  <div class="wall-card-wrapper wall-main-wrapper">
                    <div class="header accent overflow_hidden">
                      <span>Add New Wall</span>
                      <div class="tooltip" *ngIf="this.productForm.value.plans.length < 4">
                        <img *ngIf="this.productForm.valid" src="assets/project-creator/add-wall.png" alt="add-wall"
                          (click)="addhoney()" />

                        <span class="tooltiptext">Save</span>
                      </div>
                    </div>
                    <div class="page-title">Add four walls to continue.</div>

                    <form [formGroup]="productForm">
                      <div class="content wall_content" formArrayName="plans"
                        *ngFor="let item of honeymoon.controls; let i = index">
                        <div [formGroupName]="i">
                          <a (click)="remove(i)" class="wall_remove">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                              class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 352 512">
                              <path fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                              </path>
                            </svg>
                          </a>
                          <div class="div_txt d-flex justify-content-between align-items-center mb-3">
                            <div class="page-title">Wall {{ i + 1 }}</div>
                            <a (click)="duplicate(i)" class="new_themw">
                              duplicate
                            </a>
                          </div>

                          <div class="dimensions">
                            <input type="number" formControlName="wallHeight" placeholder="Height" min="0" />
                            <span>x</span>
                            <input type="number" formControlName="wallWidth" placeholder="Length" />
                          </div>
                          <div class="color">
                            <div class="color-title">Current Color</div>
                            <div class="color-input" [ngStyle]="{
                                background:
                                  this.productForm?.value?.plans[i]
                                    ?.previousColor
                              }">
                              <span (click)="setshowroom(i)">
                                <input formControlName="previousColor" />
                              </span>
                            </div>
                            <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse"
                              class="eclipse-image" />

                            <div class="clrs-show" *ngIf="palletshowhide[i]">
                              <div class="clr-pckr pickr-size">
                                <span value="#673d24" class="pck-clr brown"
                                  (click)="setvalueaddroom('#673d24', i)"></span>
                                <span value="#d19188" class="pck-clr peach"
                                  (click)="setvalueaddroom('#d19188', i)"></span>
                                <span value="#526fa7" class="pck-clr cyan"
                                  (click)="setvalueaddroom('#526fa7', i)"></span>
                                <span value="#516e33" class="pck-clr green"
                                  (click)="setvalueaddroom('#516e33', i)"></span>
                                <span value="#8076b4" class="pck-clr lavndr"
                                  (click)="setvalueaddroom('#8076b4', i)"></span>
                                <span value="#77c2ba" class="pck-clr trquoise"
                                  (click)="setvalueaddroom('#77c2ba', i)"></span>
                                <span value="#e67e25" class="pck-clr orange"
                                  (click)="setvalueaddroom('#e67e25', i)"></span>
                                <span value="#38489e" class="pck-clr ryl_blue"
                                  (click)="setvalueaddroom('#38489e', i)"></span>
                                <span value="#d8404d" class="pck-clr crimson"
                                  (click)="setvalueaddroom('#d8404d', i)"></span>
                                <span value="#452562" class="pck-clr deep_purple"
                                  (click)="setvalueaddroom('#452562', i)"></span>
                                <span value="#b3cc37" class="pck-clr neon_green"
                                  (click)="setvalueaddroom('#b3cc37', i)"></span>
                                <span value="#e6b820" class="pck-clr mustard"
                                  (click)="setvalueaddroom('#e6b820', i)"></span>
                                <span value="#272f7f" class="pck-clr dark_blue" (click)="setvalueaddroom('#272f7f', i)">
                                </span>
                                <span value="#43a147" class="pck-clr light_green"
                                  (click)="setvalueaddroom('#43a147', i)"></span>
                                <span value="#ba2425" class="pck-clr red"
                                  (click)="setvalueaddroom('#ba2425', i)"></span>

                                <span value="#f1d80d" class="pck-clr yellow" (click)="setvalueaddroom('#f1d80d', i)">
                                </span>
                                <span value="#c23b96" class="pck-clr mgnta"
                                  (click)="setvalueaddroom('#c23b96', i)"></span>
                                <span value="#027db9" class="pck-clr blue"
                                  (click)="setvalueaddroom('#027db9', i)"></span>
                                <span value="#ffffff" class="pck-clr white"
                                  (click)="setvalueaddroom('#ffffff', i)"></span>

                                <span value="#ceced3" class="pck-clr grey_20" (click)="setvalueaddroom('#ceced3', i)">
                                </span>
                                <span value="#adacb1" class="pck-clr grey_40"
                                  (click)="setvalueaddroom('#adacb1', i)"></span>
                                <span value="#797879" class="pck-clr grey_60"
                                  (click)="setvalueaddroom('#797879', i)"></span>
                                <span value="#464548" class="pck-clr grey_80"
                                  (click)="setvalueaddroom('#464548', i)"></span>
                                <span value="#181719" class="pck-clr black"
                                  (click)="setvalueaddroom('#181719', i)"></span>
                              </div>
                            </div>

                            <div class="checkboxes">
                              <div class="checkbox-wrapper" [ngClass]="
                                  this.productForm.value.plans[i]
                                    .previousWallpaper === true
                                    ? 'checkbox-wrapper checkbox-checked'
                                    : 'checkbox-wrapper'
                                ">
                                <div class="checkbox-label">Has wallpaper</div>
                                <input type="checkbox" class="checkbox" formControlName="previousWallpaper" />
                              </div>
                              <div class="checkbox-wrapper" [ngClass]="
                                  this.productForm.value.plans[i]
                                    .previousFaux === true
                                    ? 'checkbox-wrapper checkbox-checked'
                                    : 'checkbox-wrapper'
                                ">
                                <div class="checkbox-label">Faux finish</div>
                                <input type="checkbox" class="checkbox" formControlName="previousFaux" />
                              </div>
                              <div class="checkbox-wrapper" [ngClass]="
                                  this.productForm.value.plans[i]
                                    .previousDecorative === true
                                    ? 'checkbox-wrapper checkbox-checked'
                                    : 'checkbox-wrapper'
                                ">
                                <div class="checkbox-label">
                                  Decorative painting
                                </div>
                                <input type="checkbox" class="checkbox" formControlName="previousDecorative" />
                              </div>
                            </div>
                          </div>
                          <div class="color">
                            <div class="color-title">New Color</div>
                            <div class="color-input" [ngStyle]="{
                                background:
                                  this.productForm?.value?.plans[i]?.newColor
                              }">
                              <span (click)="setshowroomnew(i)"><input formControlName="newColor" /></span>
                            </div>
                            <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse"
                              class="eclipse-image" />
                            <div class="clrs-show" *ngIf="palleshowhidenew[i]">
                              <div class="clr-pckr pickr-size">
                                <span value="#673d24" class="pck-clr brown"
                                  (click)="setvalueaddroomnew('#673d24', i)"></span>
                                <span value="#d19188" class="pck-clr peach"
                                  (click)="setvalueaddroomnew('#d19188', i)"></span>
                                <span value="#526fa7" class="pck-clr cyan"
                                  (click)="setvalueaddroomnew('#526fa7', i)"></span>
                                <span value="#516e33" class="pck-clr green"
                                  (click)="setvalueaddroomnew('#516e33', i)"></span>
                                <span value="#8076b4" class="pck-clr lavndr"
                                  (click)="setvalueaddroomnew('#8076b4', i)"></span>
                                <span value="#77c2ba" class="pck-clr trquoise"
                                  (click)="setvalueaddroomnew('#77c2ba', i)"></span>
                                <span value="#e67e25" class="pck-clr orange"
                                  (click)="setvalueaddroomnew('#e67e25', i)"></span>
                                <span value="#38489e" class="pck-clr ryl_blue"
                                  (click)="setvalueaddroomnew('#38489e', i)"></span>
                                <span value="#d8404d" class="pck-clr crimson"
                                  (click)="setvalueaddroomnew('#d8404d', i)"></span>
                                <span value="#452562" class="pck-clr deep_purple"
                                  (click)="setvalueaddroomnew('#452562', i)"></span>
                                <span value="#b3cc37" class="pck-clr neon_green"
                                  (click)="setvalueaddroomnew('#b3cc37', i)"></span>
                                <span value="#e6b820" class="pck-clr mustard"
                                  (click)="setvalueaddroomnew('#e6b820', i)"></span>
                                <span value="#272f7f" class="pck-clr dark_blue"
                                  (click)="setvalueaddroomnew('#272f7f', i)">
                                </span>
                                <span value="#43a147" class="pck-clr light_green"
                                  (click)="setvalueaddroomnew('#43a147', i)"></span>
                                <span value="#ba2425" class="pck-clr red"
                                  (click)="setvalueaddroomnew('#ba2425', i)"></span>

                                <span value="#f1d80d" class="pck-clr yellow" (click)="setvalueaddroomnew('#f1d80d', i)">
                                </span>
                                <span value="#c23b96" class="pck-clr mgnta"
                                  (click)="setvalueaddroomnew('#c23b96', i)"></span>
                                <span value="#027db9" class="pck-clr blue"
                                  (click)="setvalueaddroomnew('#027db9', i)"></span>
                                <span value="#ffffff" class="pck-clr white"
                                  (click)="setvalueaddroomnew('#ffffff', i)"></span>

                                <span value="#ceced3" class="pck-clr grey_20"
                                  (click)="setvalueaddroomnew('#ceced3', i)">
                                </span>
                                <span value="#adacb1" class="pck-clr grey_40"
                                  (click)="setvalueaddroomnew('#adacb1', i)"></span>
                                <span value="#797879" class="pck-clr grey_60"
                                  (click)="setvalueaddroomnew('#797879', i)"></span>
                                <span value="#464548" class="pck-clr grey_80"
                                  (click)="setvalueaddroomnew('#464548', i)"></span>
                                <span value="#181719" class="pck-clr black"
                                  (click)="setvalueaddroomnew('#181719', i)"></span>
                              </div>
                            </div>
                            <div class="checkboxes">
                              <div class="checkbox-wrapper" [ngClass]="
                                  this.productForm.value.plans[i]
                                    .newWallpaper === true
                                    ? 'checkbox-wrapper checkbox-checked'
                                    : 'checkbox-wrapper'
                                ">
                                <div class="checkbox-label">Has wallpaper</div>
                                <input type="checkbox" class="checkbox" formControlName="newWallpaper" />
                              </div>
                              <div class="checkbox-wrapper" [ngClass]="
                                  this.productForm.value.plans[i].newFaux ===
                                  true
                                    ? 'checkbox-wrapper checkbox-checked'
                                    : 'checkbox-wrapper'
                                ">
                                <div class="checkbox-label">Faux finish</div>
                                <input type="checkbox" class="checkbox" formControlName="newFaux" />
                              </div>
                              <div class="checkbox-wrapper" [ngClass]="
                                  this.productForm.value.plans[i]
                                    .newDecorative === true
                                    ? 'checkbox-wrapper checkbox-checked'
                                    : 'checkbox-wrapper'
                                ">
                                <div class="checkbox-label">
                                  Decorative painting
                                </div>
                                <input type="checkbox" class="checkbox" formControlName="newDecorative" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center sve_text">
                        <button class="text-center" *ngIf="this.productForm.value.plans.length == 4"
                          (click)="addroom()">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="paged mt-4" *ngIf="addroomsext && project_type == 'exterior'">
                  <div class="wall-card-wrapper">
                    <div class="header accent overflow_hidden">
                      <span>Add New Wall</span>
                      <div class="tooltip" *ngIf="this.productFormext.value.plans.length < 4">
                        <img *ngIf="this.productFormext.valid" src="assets/project-creator/add-wall.png" alt="add-wall"
                          (click)="addhoneyext()" />
                        <span class="tooltiptext">Save</span>
                      </div>
                    </div>

                    <form [formGroup]="productFormext">
                      <div class="content" formArrayName="plans" *ngFor="
                          let item of honeymoonext.controls;
                          let i = index
                        ">
                        <div [formGroupName]="i">
                          <a (click)="removeext(i)" class="wall_remove">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                              class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 352 512">
                              <path fill="currentColor"
                                d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                              </path>
                            </svg>
                          </a>

                          <div class="dimensions">
                            <input type="number" formControlName="wallHeight" placeholder="Height" min="0" />
                            <span>x</span>
                            <input type="number" formControlName="wallWidth" placeholder="Length" />
                          </div>
                          <div class="color">
                            <div class="color-title">Current Color</div>
                            <div class="color-input" [ngStyle]="{
                                background:
                                  this.productFormext.value.plans[i]
                                    .previousColor
                              }">
                              <span (click)="showextroon()"><input formControlName="previousColor" /></span>

                            </div>
                            <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse"
                              class="eclipse-image" />

                            <div class="clrs-show" *ngIf="setextroomnewshow">
                              <div class="clr-pckr pickr-size">
                                <span value="#673d24" class="pck-clr brown"
                                  (click)="setvalueaddroomnewext('#673d24', i)"></span>
                                <span value="#d19188" class="pck-clr peach"
                                  (click)="setvalueaddroomnewext('#d19188', i)"></span>
                                <span value="#526fa7" class="pck-clr cyan"
                                  (click)="setvalueaddroomnewext('#526fa7', i)"></span>
                                <span value="#516e33" class="pck-clr green"
                                  (click)="setvalueaddroomnewext('#516e33', i)"></span>
                                <span value="#8076b4" class="pck-clr lavndr"
                                  (click)="setvalueaddroomnewext('#8076b4', i)"></span>
                                <span value="#77c2ba" class="pck-clr trquoise"
                                  (click)="setvalueaddroomnewext('#77c2ba', i)"></span>
                                <span value="#e67e25" class="pck-clr orange"
                                  (click)="setvalueaddroomnewext('#e67e25', i)"></span>
                                <span value="#38489e" class="pck-clr ryl_blue"
                                  (click)="setvalueaddroomnewext('#38489e', i)"></span>
                                <span value="#d8404d" class="pck-clr crimson"
                                  (click)="setvalueaddroomnewext('#d8404d', i)"></span>
                                <span value="#452562" class="pck-clr deep_purple"
                                  (click)="setvalueaddroomnewext('#452562', i)"></span>
                                <span value="#b3cc37" class="pck-clr neon_green"
                                  (click)="setvalueaddroomnewext('#b3cc37', i)"></span>
                                <span value="#e6b820" class="pck-clr mustard"
                                  (click)="setvalueaddroomnewext('#e6b820', i)"></span>
                                <span value="#272f7f" class="pck-clr dark_blue"
                                  (click)="setvalueaddroomnewext('#272f7f', i)">
                                </span>
                                <span value="#43a147" class="pck-clr light_green"
                                  (click)="setvalueaddroomnewext('#43a147', i)"></span>
                                <span value="#ba2425" class="pck-clr red"
                                  (click)="setvalueaddroomnewext('#ba2425', i)"></span>

                                <span value="#f1d80d" class="pck-clr yellow"
                                  (click)="setvalueaddroomnewext('#f1d80d', i)">
                                </span>
                                <span value="#c23b96" class="pck-clr mgnta"
                                  (click)="setvalueaddroomnewext('#c23b96', i)"></span>
                                <span value="#027db9" class="pck-clr blue"
                                  (click)="setvalueaddroomnewext('#027db9', i)"></span>
                                <span value="#ffffff" class="pck-clr white"
                                  (click)="setvalueaddroomnewext('#ffffff', i)"></span>

                                <span value="#ceced3" class="pck-clr grey_20"
                                  (click)="setvalueaddroomnewext('#ceced3', i)">
                                </span>
                                <span value="#adacb1" class="pck-clr grey_40"
                                  (click)="setvalueaddroomnewext('#adacb1', i)"></span>
                                <span value="#797879" class="pck-clr grey_60"
                                  (click)="setvalueaddroomnewext('#797879', i)"></span>
                                <span value="#464548" class="pck-clr grey_80"
                                  (click)="setvalueaddroomnewext('#464548', i)"></span>
                                <span value="#181719" class="pck-clr black"
                                  (click)="setvalueaddroomnewext('#181719', i)"></span>
                              </div>
                            </div>
                          </div>
                          <div class="color">
                            <div class="color-title">New Color</div>
                            <div class="color-input" [ngStyle]="{
                                background:
                                  this.productFormext.value.plans[i].newColor
                              }">
                              <span (click)="showextroonext()"><input formControlName="newColor" /></span>

                            </div>
                            <img src="assets/project-creator/wall-color-eclipse.png" alt="eclipse"
                              class="eclipse-image" />

                            <div class="clrs-show" *ngIf="setextroom">
                              <div class="clr-pckr pickr-size">
                                <span value="#673d24" class="pck-clr brown"
                                  (click)="setvalueaddroomnewextrrom('#673d24', i)"></span>
                                <span value="#d19188" class="pck-clr peach"
                                  (click)="setvalueaddroomnewextrrom('#d19188', i)"></span>
                                <span value="#526fa7" class="pck-clr cyan"
                                  (click)="setvalueaddroomnewextrrom('#526fa7', i)"></span>
                                <span value="#516e33" class="pck-clr green"
                                  (click)="setvalueaddroomnewextrrom('#516e33', i)"></span>
                                <span value="#8076b4" class="pck-clr lavndr"
                                  (click)="setvalueaddroomnewextrrom('#8076b4', i)"></span>
                                <span value="#77c2ba" class="pck-clr trquoise"
                                  (click)="setvalueaddroomnewextrrom('#77c2ba', i)"></span>
                                <span value="#e67e25" class="pck-clr orange"
                                  (click)="setvalueaddroomnewextrrom('#e67e25', i)"></span>
                                <span value="#38489e" class="pck-clr ryl_blue"
                                  (click)="setvalueaddroomnewextrrom('#38489e', i)"></span>
                                <span value="#d8404d" class="pck-clr crimson"
                                  (click)="setvalueaddroomnewextrrom('#d8404d', i)"></span>
                                <span value="#452562" class="pck-clr deep_purple"
                                  (click)="setvalueaddroomnewextrrom('#452562', i)"></span>
                                <span value="#b3cc37" class="pck-clr neon_green"
                                  (click)="setvalueaddroomnewextrrom('#b3cc37', i)"></span>
                                <span value="#e6b820" class="pck-clr mustard"
                                  (click)="setvalueaddroomnewextrrom('#e6b820', i)"></span>
                                <span value="#272f7f" class="pck-clr dark_blue"
                                  (click)="setvalueaddroomnewextrrom('#272f7f', i)">
                                </span>
                                <span value="#43a147" class="pck-clr light_green"
                                  (click)="setvalueaddroomnewextrrom('#43a147', i)"></span>
                                <span value="#ba2425" class="pck-clr red"
                                  (click)="setvalueaddroomnewextrrom('#ba2425', i)"></span>

                                <span value="#f1d80d" class="pck-clr yellow"
                                  (click)="setvalueaddroomnewextrrom('#f1d80d', i)">
                                </span>
                                <span value="#c23b96" class="pck-clr mgnta"
                                  (click)="setvalueaddroomnewextrrom('#c23b96', i)"></span>
                                <span value="#027db9" class="pck-clr blue"
                                  (click)="setvalueaddroomnewextrrom('#027db9', i)"></span>
                                <span value="#ffffff" class="pck-clr white"
                                  (click)="setvalueaddroomnewextrrom('#ffffff', i)"></span>

                                <span value="#ceced3" class="pck-clr grey_20"
                                  (click)="setvalueaddroomnewextrrom('#ceced3', i)">
                                </span>
                                <span value="#adacb1" class="pck-clr grey_40"
                                  (click)="setvalueaddroomnewextrrom('#adacb1', i)"></span>
                                <span value="#797879" class="pck-clr grey_60"
                                  (click)="setvalueaddroomnewextrrom('#797879', i)"></span>
                                <span value="#464548" class="pck-clr grey_80"
                                  (click)="setvalueaddroomnewextrrom('#464548', i)"></span>
                                <span value="#181719" class="pck-clr black"
                                  (click)="setvalueaddroomnewextrrom('#181719', i)"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="text-center sve_text" *ngIf="this.productFormext.value.plans.length == 4">
                        <button class="text-center" (click)="addwll()">
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="text-center" *ngIf="
                  project_type == 'interior' &&
                  (this.productForm.value.plans.length || shownesxt)
                ">
                <button nbButton nbStepperPrevious (click)="first()">
                  prev
                </button>
                <button nbButton nbStepperNext *ngIf="shownextbutton">next</button>
              </div>
              <div *ngIf="project_type == 'exterior' && addroomsext">
                <button nbButton nbStepperPrevious (click)="first()">
                  prev
                </button>
                <button nbButton nbStepperNext (click)="addwallext()">
                  next
                </button>
              </div>
            </nb-step>

            <nb-step [label]="labelThree">
              <ng-template #labelThree>Project Details</ng-template>
              <div class="project-creator-header">
                <button class="custom-button" nbButton nbStepperPrevious (click)="second()">
                  BACK
                </button>
              </div>

              <div class="prjct-dtls-cntnt">
                <div class="page" *ngIf="!showprojectdetails">
                  <div class="page-title">Project detailed info</div>
                  <div class="page-content project-details" [formGroup]="projectDetailsForm">
                    <div class="section-wrapper" *ngFor="let section of projectDetailsSections.items">
                      <div class="project-details-header">
                        {{ section.title }}
                      </div>
                      <div class="project-details-description">
                        {{ section.description }}
                      </div>
                      <ng-template [ngIf]="section.id == 0">
                        <div class="image-uploader">
                          <input class="form-control" type="file" accept="image/*" multiple id="formFile"
                            (change)="uploadFile($event)" />

                          <div class="uploaded-images">
                            <div *ngFor="let items of filesn; let index = index" class="uploaded_img_wrap">
                              <img id="blah" [src]="items.name" alt="your image" />
                              <!-- <button mat-icon-button (click)="removeSelectedFile(index)">delete</button> -->
                              <a (click)="removeSelectedFile(index)">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                                  class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 352 512">
                                  <path fill="currentColor"
                                    d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                  </path>
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex crd_wpr overflow_auto_css room_div_css" *ngIf="addedrroms.length">
                          <div class="div_inr_cost" *ngFor="let item of addedrroms">
                            <div class="room-card-wrapper room_img_updt" (click)="openmodal(item)">
                              <div class="info tym_info">
                                <div class="room-type">
                                  {{ item.sel_rrom[0] | titlecase }}
                                </div>

                                <div class="upld_room_text" (click)="openmodal(item)">
                                  <span class="upld_icn">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                                      <path
                                        d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
                                    </svg>
                                  </span>
                                  Upload Image
                                </div>
                              </div>
                              <div class="upldd_img_rm uploaded-images" *ngIf="item?.images?.length">
                                <div class="uploaded_img_wrap" *ngFor=" let new of item?.images">
                                  <img [src]="new?.image" alt="your image" />
                                  <!-- <button mat-icon-button (click)="removeSelectedFile(index)">delete</button> -->

                                </div>
                                <!-- <div class="uploaded_img_wrap">
                                  <img
                                    src="https://production.promaticstechnologies.com/brush_rest_apis/public/projectImages/1695025349685screenshot-konfigurator.sournois.de-2023.08.07-11_22_11.png"
                                    alt="your image" />
                                  <button mat-icon-button (click)="removeSelectedFile(index)">delete</button>
                                  <a>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                                      class="svg-inline--fa fa-times fa-w-11" role="img"
                                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                      <path fill="currentColor"
                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                      </path>
                                    </svg>
                                  </a>
                                </div> -->
                                <!-- <div class="uploaded_img_wrap">
                                  <img
                                    src="https://production.promaticstechnologies.com/brush_rest_apis/public/projectImages/1695025349685screenshot-konfigurator.sournois.de-2023.08.07-11_22_11.png"
                                    alt="your image" />
                                  <button mat-icon-button (click)="removeSelectedFile(index)">delete</button>
                                  <a>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                                      class="svg-inline--fa fa-times fa-w-11" role="img"
                                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                      <path fill="currentColor"
                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                      </path>
                                    </svg>
                                  </a>
                                </div> -->
                                <!-- <div class="uploaded_img_wrap">
                                  <img
                                    src="https://production.promaticstechnologies.com/brush_rest_apis/public/projectImages/1695025349685screenshot-konfigurator.sournois.de-2023.08.07-11_22_11.png"
                                    alt="your image" />
                                  <button mat-icon-button (click)="removeSelectedFile(index)">delete</button>
                                  <a>
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times"
                                      class="svg-inline--fa fa-times fa-w-11" role="img"
                                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                      <path fill="currentColor"
                                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                                      </path>
                                    </svg>
                                  </a>
                                </div> -->
                              </div>
                            </div>
                          </div>

                          <button class=" mt_20 new_themw_new" nbButton nbStepperPrevious (click)="addnewrromsfrom()">
                            Add Room
                          </button>
                        </div>
                        <div class="d-flex crd_wpr overflow_auto_css" *ngIf="addedwalls.length">
                          <div class="div_inr_cost" *ngFor="let item of addedwalls">
                            <div class="room-card-wrapper" (click)="openmodal(item)">
                              <div class="info tym_info">
                                <!--  <div class="undr_lne_text">View Details </div>-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-template>

                      <ng-template [ngIf]="section.id == 1">
                        <div class="text-area-wrapper">
                          <textarea formControlName="title" placeholder="Enter title" maxlength="100" [rows]="1"
                            (input)="characterCounterForTitle($event)"></textarea>
                          <div class="hint">{{ section.hint }}</div>
                        </div>
                        <div *ngIf="!projectDetailsForm.controls['title'].valid">
                          <span style="color: red"
                            *ngIf="projectDetailsForm.controls['title'].touched && projectDetailsForm.controls['title'].hasError('minlength')">
                            Title must be at least 5 character
                          </span>
                        </div>
                      </ng-template>
                      <ng-template [ngIf]="section.id == 2">
                        <div class="text-area-wrapper">
                          <textarea formControlName="description" placeholder="Add description" maxlength="400"
                            [rows]="6" (input)="characterCounterForDescription($event)"></textarea>
                          <div class="hint">{{ section.hint }}</div>
                        </div>
                        <div *ngIf="!projectDetailsForm.controls['description'].valid">
                          <span style="color: red"
                            *ngIf="projectDetailsForm.controls['description'].touched && projectDetailsForm.controls['description'].hasError('minlength')">
                            Description must be at least 5 character
                          </span>
                        </div>
                      </ng-template>
                      <ng-template [ngIf]="section.id == 3">
                        <div class="d-flex">
                          <app-ngx-date-range-picker (dateChanged)="setProjectDetailsDate($event)">
                          </app-ngx-date-range-picker>
                        </div>
                      </ng-template>
                    </div>
                    <div class="post-project-button">
                      <button class="custom-button" (click)="sendNewProjectRequest()"
                        [disabled]="!projectDetailsForm.valid">
                        POST PROJECT
                      </button>
                    </div>
                  </div>
                </div>

                <div class="paged mt-4" *ngIf="showprojectdetails">
                  <!-- SUMMARY-->
                  <div class="summary">
                    <div class="summary-wrapper">
                      <img src="./assets/project-creator/success-icon.png" alt="success-icon" />
                      <div class="success-title">
                        Your project has been successfully created!
                      </div>
                      <div class="success-hint">
                        <!-- Your project has been posted to marketplace! You can
                        check its details in My projects section -->
                        Your project has been posted to the marketplace and is awaiting approval from Brush Exchange. Once it’s approved, you will be notified and be able to view it in the My Projects section.
                      </div>
                      <div class="success-estimated-cost" *ngIf="project_type == 'interior'">
                        <!-- <div class="cost-title">
                          Total Estimate Cost(For all rooms)
                        </div>
                        <div class="cost" *ngIf="sum">
                          $ {{ sum | number : "1.0-2" }}
                        </div> -->

                        <div class="cost-title">Low range</div>
                        <div class="cost" *ngIf="newlowrange">
                          $ {{ newlowrange | number : "1.0-2" }}
                        </div>
                        <div class="cost-title">High range</div>
                        <div class="cost" *ngIf="newhighrange">
                          $ {{ newhighrange | number : "1.0-2" }}
                        </div>
                        <div class="cost-title" *ngIf="newgallonwll">
                          Gallons ceiling paint
                        </div>
                        <div class="cost" *ngIf="newgallonwll">
                          {{ newgallonwll | number : "1.0-2" }}
                        </div>
                        <div class="cost-title">Gallons wall paint</div>
                        <div class="cost" *ngIf="newgallonceiling">
                          {{ newgallonceiling | number : "1.0-2" }}
                        </div>
                      </div>
                      <div class="success-estimated-cost" *ngIf="project_type == 'exterior'">
                        <div class="cost-title">Total Estimate Cost</div>
                        <div class="cost">
                          $ {{ extresp.final_price | number : "1.0-2" }}
                        </div>

                        <div class="cost-title">Low range</div>
                        <div class="cost">
                          $ {{ extresp.low_range | number : "1.0-2" }}
                        </div>
                        <div class="cost-title">High range</div>
                        <div class="cost">
                          $ {{ extresp.high_range | number : "1.0-2" }}
                        </div>

                        <div class="cost-title">Gallons wall paint</div>
                        <div class="cost">
                          {{ extresp.wall_paint_cost | number : "1.0-2" }}
                        </div>
                      </div>
                      <button class="custom-button" [routerLink]="'/home/my-project-card'">
                        My Projects
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </nb-step>
          </nb-stepper>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlagnew"
  (cancelButtonClicked)="closeComplainModalnew()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
          <div class="">
            <div class="painter-details">
              <div class="div_upr d-flex">
                <h3 class="text-center">Add pictures</h3>
              </div>
            </div>
          </div>
          <div class="image-uploader">
            <input class="form-control" type="file" accept="image/*" multiple id="formFile"
              (change)="uploadFiles($event)" />
          </div>
        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <button class="custom-button" (click)="save()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </slot-content>
</app-modal>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="modalFlagnewopen"
  (cancelButtonClicked)="closeComplainModalnew()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
          <div class="">
            <div class="painter-details">
              <div class="div_upr input-wrapper">
                <h3 class="text-center">Title</h3>
                <p class="mt-3">Enter Title</p>
                <input class="inputt mt-2" [(ngModel)]="p_name" />
              </div>
            </div>
          </div>
          <div class="image-uploader mt-3">
            <input class="form-control" type="file" accept="image/*" multiple id="formFile"
              (change)="uploadFilesnew($event)" />
          </div>
        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <button class="custom-button" (click)="savenew()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </slot-content>
</app-modal>

<app-modal class="bid-detail-mdl pay_bid_modal" [displayModal]="opennewmodal"
  (cancelButtonClicked)="closeComplainModalnew()">
  <slot-content>
    <div class="bid_detail_modal">
      <div class="bid-card-wrapper">
        <div class="">
          <!--   <div class="bid_img">
            <img _ngcontent-uau-c257="" src="assets/illustrations/landing-page/meeting.svg" alt="meeting"
              class="left-img">
          </div>-->
          <div class="" *ngIf="showtitle">
            <div class="painter-details">
              <div class="div_upr input-wrapper">
                <h3 class="text-center">Add Surface</h3>
                <p class="mt-3">Enter Title</p>
                <input [(ngModel)]="titlenew" class="inputt mt-2" />
              </div>
            </div>
          </div>
          <div class="">
            <div class="painter-details">
              <div class="div_upr input-wrapper">
                <p class="mt-3">Desrciption</p>
                <input [(ngModel)]="desc" class="inputt mt-2" />
              </div>
            </div>
          </div>
          <div class="image-uploader mt-4">
            <input class="form-control" type="file" accept="image/*" multiple id="formFile"
              (change)="uploadFilesnewnew($event)" />
          </div>
        </div>
        <div class="content">
          <div class="d-flex dbl_btns">
            <button class="custom-button" (click)="savenewnew()">Save</button>
          </div>
        </div>
      </div>
    </div>
  </slot-content>
</app-modal>