<div class="main-box welcm_bck">
  <img src="assets/logo.png" class="error-icon">
  <p class="title exchng_clr">Let us know you better!</p>
  <form class="form margin-b10" [formGroup]="userDataForm">
    <div class="input-wrapper paragraph_text margin-b15">
      <p>Please enter your name below.</p>
    </div>
    <div class="div_logn input-wrapper input_res">
      <!-- <label><span class="label-text">Your Full Name</span><br></label> -->
      <input type="text" class="password" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
        formControlName="full_name" placeholder="Your Full Name">
      <!-- <div class="prfl_icn">
            <svg width="1em" height="1em" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg"><path d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z"/><path d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z"/></svg>
          </div> -->
    </div>
    <div class="div_logn input-wrapper input_res" *ngIf="isPainterRegistration">
      <!-- <label><span class="label-text">Business name</span><br></label> -->
      <input type="text" class="password" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
        formControlName="business_name" placeholder="Enter business name">
      <!-- <div class="prfl_icn">
            <svg width="1em" height="1em" viewBox="0 0 512 512"  xmlns="http://www.w3.org/2000/svg"><path d="M332.64,64.58C313.18,43.57,286,32,256,32c-30.16,0-57.43,11.5-76.8,32.38-19.58,21.11-29.12,49.8-26.88,80.78C156.76,206.28,203.27,256,256,256s99.16-49.71,103.67-110.82C361.94,114.48,352.34,85.85,332.64,64.58Z"/><path d="M432,480H80A31,31,0,0,1,55.8,468.87c-6.5-7.77-9.12-18.38-7.18-29.11C57.06,392.94,83.4,353.61,124.8,326c36.78-24.51,83.37-38,131.2-38s94.42,13.5,131.2,38c41.4,27.6,67.74,66.93,76.18,113.75,1.94,10.73-.68,21.34-7.18,29.11A31,31,0,0,1,432,480Z"/></svg>
          </div> -->
    </div>
  </form>
  <div class="div_btn_lgn get_strt_btn">
    <button class="custom-button" (click)="submitRegisterForm()" [disabled]="!userDataForm.valid">Next
      <svg width="1em" height="1em" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
        viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg></button>
  </div>
</div>