import {Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';


@Component({
  selector: 'app-profile-image-upload',
  templateUrl: './profile-image-upload.component.html',
  styleUrls: ['./profile-image-upload.component.scss'],
})
export class ProfileImageUploadComponent {
  @ViewChild('input') input!: ElementRef;
  @Output() whenFilesChange: EventEmitter<any[]> = new EventEmitter();
  onFileDropped(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.fileBrowseHandler({target: {files: [event.dataTransfer.files[0]]}});
  }
  fileBrowseHandler(event: any): void {
    this.whenFilesChange.emit(event);
  }

  reset(): void {
    this.input.nativeElement.value = null;
  }
}
