import {FormGroup} from '@angular/forms';

export function AtLeastOneFieldRequiredValidator(controlNames: string[]): any {
  return (formGroup: FormGroup) => {
    if (!controlNames.some(controlName => isFieldNotEmpty(controlName, formGroup))) {
      for (const controlName of controlNames) {
        formGroup.controls[controlName].setErrors({atLeastOneRequired: true});
      }
      return {atLeastOneRequired: true};
    } else {
      for (const controlName of controlNames) {
        if (formGroup.controls[controlName].errors && !formGroup.controls[controlName].errors?.atLeastOneRequired) {
          continue;
        }
        formGroup.controls[controlName].setErrors(null);
      }
      return null;
    }
  };

}

function isFieldNotEmpty(controlName: string, fg: FormGroup): boolean {
  const controlValue = fg.get(controlName)!.value;
  if (typeof controlValue === 'number') {
  //  console.log('dla ', controlName + ' ' + controlValue);
    return controlValue != null && controlValue !== undefined ? true : false;
  } else if (typeof controlValue === 'string') {
   // console.log('dla ', controlName + ' ' + controlValue);
    return controlValue != null && controlValue !== undefined && controlValue.trim().length > 0 ? true : false;
  } else if (typeof  controlValue === 'boolean') {
   // console.log('dla ', controlName + ' ' + controlValue);
    return controlValue;
  }
  else {
    return false;
  }
}


