<div class="route-title">
  <span>Notifications</span>
  <app-notification-bell></app-notification-bell>
</div>
<div class="notifications-wrapper">

  <app-notification-card
    *ngFor="let item of pageableResponse?.content"
    (deleteMessage)="deleteMessage($event)"
    [message]="item"
    [clearTextVisible]="false"
    (messageMarkedAsRead)="markMessageAsRead($event)">
  </app-notification-card>
  <app-pagination [adjacentPagesNo]="2" *ngIf="pageableResponse" (newPageNumber)="changePageNumber($event)"
                  [totalPages]="pageableResponse.totalPages"
                  [pageNumber]="pageableResponse.pageable?.pageNumber"></app-pagination>
</div>
