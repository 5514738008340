import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
data: any ={}
  constructor(  private authService: AuthService,) { }

  ngOnInit(): void {
  this.getprofile();
  }

  
   getprofile(){
   let obj={}
		 this.authService.getCMSdata('privacy_policy').subscribe((response : any) => {
         this.data= response.response
         
        // this.previewImage2 = this.path + this.profile.profile_image
      //     this.selectedFile2 =  this.profile.profile_image
        // console.log(this.profileForm)
        //   this.toastr.success('Password changed!', 'Success!');

      })
  }
}
