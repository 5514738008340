import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EProjectStatus} from '../../../model/enums/EProjectStatus';
import {EProjectType} from '../../../model/enums/EProjectType';
import {ChangeStatusData} from '../../../views/home/home-nav-routes/my-projects/my-projects.component';
import {Bid} from '../../../model/Bid';
import {EBidStatus} from '../../../model/enums/EBidStatus';

@Component({
  selector: 'app-project-component',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {

  project!: MarketplaceProjectCardData;
  EProjectType = EProjectType;
  EProjectStatus = EProjectStatus;
  @Output() showProjectDetails = new EventEmitter();
  @Output() changeProjectStatus = new EventEmitter<ChangeStatusData>();

  displayModal = false;
  modalClaim!: string;
  newProjectStatus!: EProjectStatus;
  EBidStatus = EBidStatus;

  constructor() {
  console.log(this.bid)
  }

  @Input() inputData!: any;
  @Input() bid!: Bid;
  @Input() marketplace!: boolean;
  @Input() showProjectStatus = false;

  ngOnInit(): void {
  console.log(this.inputData, this.inputData.projectImages[0].image)
    this.project = new MarketplaceProjectCardData(
      this.inputData.projectImages[0].image,
      this.inputData.projectDetails.title,
   //   this.inputData.projectDetails.howManyBids,
   //   this.inputData.customer.userPersonal.fullName,
     this.inputData.projectDetails.howLongAgo,
  //    this.inputData.customer.address.city,
  //    this.inputData.customer.address.state,
  //    this.inputData.customer.address.zipCode,
  //    this.inputData.customer.address.distance,
      this.inputData.projectDetails.description,
     this.inputData.status,
      this.inputData.projectType
    );

    //this.project.tags = this.prepareTags(this.inputData.projectDetails.tags);
  }

  prepareTags(items: any): Map<string, any> {
    if (items != null) {
      const map = new Map(Object.entries(items));
      map.forEach((value: any, key: any) => {
        if (value === null || value === undefined || (key.includes('room') && !key.includes('rooms'))) {
          map.delete(key);
        }
      });

      while (map.size > 8){
        map.forEach((value: any, key: any) => {
          const randomBoolean = Math.random() < 0.5;
          if (randomBoolean && map.size > 8){
            map.delete(key);
          }
        });
      }
      return map;
    }
    return new Map();
  }

  delete(event: any): void {
    event.stopPropagation();
    this.displayModal = true;
    this.newProjectStatus = EProjectStatus.DELETED;
    this.modalClaim = 'Are you sure you want permanently delete this project?';
  }

  archive(event: any): void {
    event.stopPropagation();
    this.displayModal = true;
    this.newProjectStatus = EProjectStatus.ARCHIVED;
    this.modalClaim = 'Are you sure you want archive this project?';
  }

  modifyStatus(): void {
    if (this.inputData.tag != null && this.newProjectStatus != null) {
      console.log(this.inputData);
      this.changeProjectStatus.emit({
        projectTag: this.inputData.tag,
        status: this.newProjectStatus,
        title: this.inputData.projectDetails?.title,
        projectType: this.inputData.projectType
      });
    }
  }

  viewProjectDetails(): void {
    this.showProjectDetails.emit(this.inputData);
  }

}


export class MarketplaceProjectCardData {
  imageSrc: string;
  title: string;
 
  days_remaining: string;

  description: string;
  status: string;
 type: string

  constructor(imageSrc: string, title: string,   postedAgo: string,  description: string ,status: string, type: string) {
    this.imageSrc = imageSrc;
    this.title = title;
   
    this.description = description;
    this.status = status;
    this.type = type;
  }
}
