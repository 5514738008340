import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectInputOption } from 'src/app/model/components/SelectInputOption';
import { HttpserviceService } from '../../../../services/httpservice.service';
import * as moment from 'moment';
import { Room } from '../../../../model/Room';
import { ToastrService } from 'ngx-toastr';
import { NgImageSliderComponent } from 'ng-image-slider';

import { ActivatedRoute, Router } from '@angular/router';
import { EComplainReason } from 'src/app/model/enums/EComplainReason';
import { EProjectStatus } from 'src/app/model/enums/EProjectStatus';
import { RemoveUnderscorePipe } from 'src/app/app.component';

enum OrderingFunctionEnum {
  SORT_DATE_DESC,
  SORT_DATE_ASC,
  SORT_PRICE_ASC,
  SORT_PRICE_DESC
}


@Component({
  selector: 'app-my-project-detail',
  templateUrl: './my-project-detail.component.html',
  styleUrls: ['./my-project-detail.component.scss']
})
export class MyProjectDetailComponent implements OnInit {
  @ViewChild('nav') slider!: NgImageSliderComponent;
  modalFlagbidnew: boolean = false
  projectDetails: any = {}
  reporting_reason: any = []
  id: any
  modalFlagbids: boolean = false
  bidAmt: any
  profile: any
  price: any
  imageobjectarray: any = []
  imageSrc: any
  event_list: any = [
    {
      event: ' Event 1',
      eventLocation: 'Bangalore',
      eventDescription: 'In bangalore, first event is going to happen. Please be careful about it',
      img: 'https://picsum.photos/900/500?random&t=1',
      eventStartDate: new Date('2019/05/20'),
      eventEndingDate: new Date('2019/05/24')
    },
    {
      event: ' Event 2',
      eventLocation: 'Dubai',
      eventDescription: 'Dubai is another place to host so,e, first event is going to happen. Please be careful about it',
      img: 'https://picsum.photos/900/500?random&t=3',
      eventStartDate: new Date('2019/07/28'),
      eventEndingDate: new Date('2019/07/30')
    },
    {
      event: ' Event 3',
      eventLocation: 'New York',
      eventDescription: 'NewYork sits on top of event hosting',
      img: 'https://picsum.photos/900/500?random&t=4',
      eventStartDate: new Date('2020/05/20'),
      eventEndingDate: new Date('2020/05/24')
    },
    {
      event: ' Event 4',
      eventLocation: 'Singapore',
      eventDescription: 'Singapore is another great hosting city',
      img: 'https://picsum.photos/900/500?random&t=6',
      eventStartDate: new Date('2018/05/20'),
      eventEndingDate: new Date('2018/05/24')
    },
    {
      event: ' Event 5',
      eventLocation: 'Berlin',
      eventDescription: 'Berlin is best place to hang on',
      img: 'https://picsum.photos/900/500?random&t=7',
      eventStartDate: new Date('2017/07/10'),
      eventEndingDate: new Date('2017/08/14')
    },
    {
      event: ' Event 6',
      eventLocation: 'Mumbai',
      eventDescription: 'Mumbai is hub of startups',
      img: 'https://picsum.photos/900/500?random&t=8',
      eventStartDate: new Date(),
      eventEndingDate: new Date()
    },
    {
      event: ' Event 7',
      eventLocation: 'Barcelona',
      eventDescription: 'Barcelona is another good city',
      img: 'https://picsum.photos/900/500?random&t=6',
      eventStartDate: new Date(),
      eventEndingDate: new Date()
    },
  ]

  upcoming_events: any = this.event_list.filter((event: any) => event.eventStartDate > new Date());

  walldetails: any
  modalFlag12: boolean = false
  roomdetails: any
  credit_value: any
  modalFlagbid: boolean = false
  reason: any
  bid_value: any
  cover_letter: any
  modalFlag1: boolean = false;
  displayDetailsModalVisible1 = false



  orderOptions: SelectInputOption[] = [
    {
      optionLabel: 'Select Reason',
      optionValue: OrderingFunctionEnum.SORT_DATE_DESC,
      sortDir: '',
      optionSelected: true,
      optionDisabled: false
    },
    {
      optionLabel: 'option1',
      optionValue: OrderingFunctionEnum.SORT_DATE_ASC,
      sortDir: '',
      optionSelected: false,
      optionDisabled: false
    },

  ];
  imageObject: any = []
  imageObjecta = [{
    image: 'https://production.promaticstechnologies.com/brush_rest_apis/public/projectImages/1684319156322Screenshot 2023-03-01 124027.png',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    title: ''
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    title: ''
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    title: ''
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg'
  }, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    title: ''
  }];


  modalFlag: boolean = false;
  displayDetailsModalVisible = false
  router: any;
  selectedComplainReason: any;
  complainReasons!: { label: string; visible: boolean; value: any; }[];
  project: any;
  makeComplainDialogVisible!: boolean;

  constructor(private authService: HttpserviceService, private activatedRoute: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params.id
    console.log(this.activatedRoute.snapshot.params.id)
    this.getPRojectdetails()
    this.getreporting()
    this.getcredite()
    this.authService.getprofile().subscribe((response: any) => {
      this.profile = response.profileData
      this.price = this.profile.credit
      //  this.previewImage2 = this.path + this.profile.profile_image
      //     this.selectedFile2 =  this.profile.profile_image
      // console.log(this.profileForm)
      //   this.toastr.success('Password changed!', 'Success!');

    })
  }
  openmodalwall(a: any) {

    console.log('awallllll###', a)
    this.modalFlag12 = true
    this.walldetails = a
    alert("hello this.walldetails")
    //    this.addedrroms.splice(this.newobj, 1)
    // console.log(this.addedrroms)
  }

  bidmodify(id: any) {
    let obj = {
      'id': id,
      'bid': this.bidAmt
    }
    this.authService.updatebid(obj).subscribe((response: any) => {
      this.modalFlagbids = true
      
      this.toastr.success('Bid updated successfully!', 'Success!');
    })
  }
  openmodal(a: any) {
    this.modalFlag1 = true
    this.roomdetails = a
    console.log(this.roomdetails)
    for (let i = 0; i < this.roomdetails?.images?.length; i++) {
      this.imageobjectarray.push({
        'image': this.roomdetails.images[i].image,
        'thumbImage': this.roomdetails.images[i].image,
        'title': ''
      })
    }
    console.log(this.imageObject)
  }
  done() {
    //   window.location.reload();
    this.modalFlagbid = false
    this.modalFlagbidnew = true
    //   this.ngOnInit();

  }
  donenew() {
    this.modalFlagbidnew = false
    window.location.reload();
  }
  prevImageClick() {
    this.slider.prev();
  }

  nextImageClick() {
    this.slider.next();
  }
  onClick(imageNameObject: any) {
    console.log(imageNameObject)
    //  this.imageSrc = imageNameObject.image;
    // this.messageText = imageNameObject.name;
  }
  sendbid() {
    console.log(this.bid_value, this.cover_letter)
    if (!this.bid_value || !this.cover_letter) {


      this.toastr.error('Fill all fields', 'Error');

    } else {
      this.modalFlagbid = true


      let obj = {
        'bid': this.bid_value,
        'cover_letter': this.cover_letter,
        'project_id': this.projectDetails.id
      }
      this.authService.createBid(obj).subscribe((response: any) => {
        //    this.toastr.success('Bid created successfully!', 'Success!');
        this.bid_value = '',
          this.cover_letter = ''

        let obj1 = {
          'receiver_id': this.projectDetails.customerDetails.id,
          'sender_id': this.profile.id,
          'project_id': this.projectDetails.id,
          'bid_id': response.bid.id
        }

        this.authService.createRoom(obj1).subscribe((response: any) => {
          console.log('room successfully draeted')
          // this.toastr.success('Report reason sent!', 'Success!');
          //  this.modalFlag = false


        })

      }, error => {
        console.log(error);
        if (error.status === 0) {
          this.toastr.error('Unable to connect server', 'Error!');
        } else if (error.status === 422 && error.error.errors.msg == "You dont have enough credit for this bid") { // bad credentials
          this.toastr.error('You dont have enough credit for this bid', 'Error!')
          

        } else {
          this.toastr.error('Please purchase credits in My Profile to place bids', 'Error!');

        }


      }
      );
    }
  }
  sendreport() {
    let obj = {
      'project_id': this.id,
      'reason': this.reason
    }
    this.authService.createreason(obj).subscribe((response: any) => {
      this.toastr.success('Report reason sent!', 'Success!');
      this.modalFlag = false


    })
  }
  getPRojectdetails() {
    let obj = {
      'project_id': this.id
    }
    this.authService.getProjectsbypainter(obj).subscribe((response: any) => {
      this.projectDetails = response.projects[0]
      console.log('@@@@@@@@@',this.projectDetails?.project_type);
      
      this.bidAmt = response.projects[0]?.Bids?.bid
      for (let i = 0; i < response.projects[0].images.length; i++) {
        console.log(response.projects[0].images[i])
        this.imageObject.push({
          'image': response.projects[0].images[i].image,
          'thumbImage': response.projects[0].images[i].image,
          'title': ''
        })
      }
      console.log(this.imageObject)
      response.projects.forEach((val: any, ind: number) => {
        let current_date: any = moment(new Date());
        let deadline_date: any = moment(val.created_at);

        val.days_remaining = Math.abs(deadline_date.diff(current_date, 'days'));

        console.log('rem days', val.days_remaining);



      });

      //   this.toastr.success('Password changed!', 'Success!');

    })
  }

  click(e: any) {
    console.log(e)
  }
  getreporting() {
    let obj = {
      'project_id': this.id,
      'reason': this.reason
    }
    this.authService.getreportingReason().subscribe((response: any) => {
      this.reporting_reason = response.reasons



    })
  }
  getcredite() {
    let obj = {
      'project_id': this.id,
      'reason': this.reason
    }
    this.authService.getcreditvalue().subscribe((response: any) => {
      this.credit_value = response.response



    })
  }


  makeComplain() { }
  openAcceptBidModal() { }
  checkPendingOrNewStatus(): boolean { return true }
  openDenyBidModal() { }
  closeComplainModal() {
    this.modalFlag = false
    this.modalFlag12 = false
    this.modalFlag1 = false
    this.imageobjectarray = []
    this.modalFlagbid = false
    this.modalFlagbidnew = false
    this.modalFlagbids = false
  }

  bidPayment() {
    this.router.navigate(['/home/bid-payment']);
  }

  openComplainModal(): void {
    this.selectedComplainReason = EComplainReason.DEFUALT;
    this.complainReasons = [
      {
        label: 'I cant continue my work',
        visible: this.project.status === EProjectStatus.IN_PROGRESS,
        value: EComplainReason.PAINTER_CANT_WORK
      },
      {
        label: 'No responses from customer',
        visible: true,
        value: EComplainReason.NO_CONTACT_WITH_CUSTOMER
      },
      {
        label: 'I have not received payment',
        visible: this.project.status === EProjectStatus.IN_PROGRESS,
        value: EComplainReason.PAINTER_NOT_RECEIVED_PAYMENT
      },
      {
        label: 'Job does not match description',
        visible: true,
        value: EComplainReason.JOB_NO_MATCH_DESCRIPTION
      }
    ];
    this.makeComplainDialogVisible = true;
  }

}


