<!-- <div class="wrapper" *ngIf="adjacentPagesNo">
  <img src="assets/icons/pagination/pagination-arrow-double-back.png" (click)="firstPage()">
  <img src="assets/icons/pagination/pagination-arrow-back.png" class="arrow-back" (click)="previousPage()">
  <span *ngFor="let page of pageNumberList">
     <span class="dots" *ngIf="displayDotsOrNotBeforeLastPage(page)">...</span>
    <span *ngIf="adjacentCurrentPageAbs(page)"
          [ngClass]="page == (pageNumber + 1) ? 'page-text current-page' : 'page-text'"
          (click)="checkAndEmitValidPageNumber(page)">
    {{page}}
      </span>
     <span *ngIf="displayDotsOrNotAfterFirstPage(page)" class="dots">...</span>
    </span>
  <img src="assets/icons/pagination/pagination-arrow-forward.png" class="arrow-forward" (click)="nextPage()">
  <img src="assets/icons/pagination/pagination-arrow-double-forward.png" (click)="lastPage()">

</div> -->

<div class="wrapper">
  <img src="assets/icons/pagination/pagination-arrow-double-back.png">
  <img src="assets/icons/pagination/pagination-arrow-back.png" class="arrow-back" >
  <span >
      <span class="page-text current-page">
        1
      </span>
      <span class="page-text">
        2
      </span>
      <span class="page-text">
        3
      </span>
    </span>
  <img src="assets/icons/pagination/pagination-arrow-forward.png" class="arrow-forward" >
  <img src="assets/icons/pagination/pagination-arrow-double-forward.png">

</div>

