import {Component, OnInit} from '@angular/core';
import {NbMenuItem} from '@nebular/theme';
import {TokenStorageService} from '../../../../services/token-storage.service';

//FIXME if there won't be any other settings panel than general this component can be deleted
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  constructor(private tokenStorage: TokenStorageService) {
  }

  items: NbMenuItem[] = [
    {
      title: 'General Settings',
      icon: {icon: 'settings-2-outline', pack: 'eva'},
      link: 'general',
    },
    {
      title: 'Notifications (2do)',
      icon: {icon: 'star-outline', pack: 'eva'},
    },
  ];

  ngOnInit(): void {
  }
}
