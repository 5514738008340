import {UserDetails} from '../model/UserDetails';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from './token-storage.service';
import {Injectable} from '@angular/core';
import {ERole} from '../model/enums/ERole';
import {environment} from '../../environments/environment';


const API = environment.SERVER_BASE_URL + 'user-personal';

@Injectable({
  providedIn: 'root'
})

export class UserPersonalService {

  constructor(private http: HttpClient, private tokenStorage: TokenStorageService) {
  }

  fillUserDetails(userDetails: UserDetails, role: ERole): Observable<any> {
    const parameters = new HttpParams()
      .set('email', this.tokenStorage.getEmail())
      .set('role', role);
    return this.http.post<UserDetails>(API, userDetails, {
        params: parameters
      }
    );
  }
}
