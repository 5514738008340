import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-painter-login',
  templateUrl: './painter-login.component.html',
  styleUrls: ['./painter-login.component.css']
})
export class PainterLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
