import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-drop-down-dots',
  templateUrl: './drop-down-dots.component.html',
  styleUrls: ['./drop-down-dots.component.scss']
})
export class DropDownDotsComponent implements OnInit {

  @Input() identifier: any;
  @Input() data!: DropDownItem[];

  @Output() itemClicked = new EventEmitter<any>();

  constructor(private ref: ElementRef) {
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event', '$event.target'])
  onClick(event: any): void {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  displayMenu(identifier: any): void {
    console.log('displaying for: ' + identifier);
    document.getElementById('nav-el' + identifier)!.classList.toggle('display-none');
  }

  close(): void {
    document.getElementById('nav-el' + this.identifier)!.classList.add('display-none');
  }

  onItemClicked(label: string): void {
    this.itemClicked.emit(label);
    this.close();
  }
}

export interface DropDownItem {
  label: string;
  src: string;
}
