import { APP_ID, Inject, Injectable, PLATFORM_ID, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpClientModule, HttpHeaders } from '@angular/common/http';
//import {Observable} from 'rxjs';
import { ERole } from '../model/enums/ERole';
import { environment } from '../../environments/environment';
import { map, tap, finalize } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject, Observable, Observer, throwError } from "rxjs";
import { Router } from '@angular/router';
//import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireAuth } from '@angular/fire/auth';


const AUTH_API = environment.SERVER_BASE_URL;
const API = environment.SERVER_BASE_URL;
const blog = environment.ENV_BLOG;

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  tolen: any
  constructor(private http: HttpClient,
    private _snackBar: MatSnackBar,
    private router: Router,
    public afAuth: AngularFireAuth,

    private httpClient: HttpClientModule,
    //   public afAuth: AngularFireAuth,
    @Inject(PLATFORM_ID) private platform: Object
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token') // your auth token
    if (token) { // your authorized  logic
      request = request.clone({
        setHeaders: {
          Authorization: `${token}`
        }
      });
    }

    return next.handle(request);
  }
  authLogin(provider: any) {
    return new Promise((resolve, reject) => {
      this.afAuth
        .signInWithPopup(provider)
        .then((data: any) => {
          console.log("You have login ");
          resolve(data);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  }
  intercepth(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    // this.loader.start();
    let ok: any;
    const token = this.getToken();
    console.log(token)

    if (token) { // make sure token is coming,
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }


    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => ok = event instanceof HttpResponse ? 'succeeded' : '',
        // Operation failed; error is an HttpErrorResponse
        error => ok = error
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        //this.loader.stop();
        const elapsed = Date.now() - started;
        if (ok == "succeeded") {
          const msg = `${request.method} "${request.urlWithParams}"
           ${ok} in ${elapsed} ms.`;
          // console.log(msg);
        } else {
          console.log(ok);
          if (ok["status"] == 401) {
            // logout from here
            this.logout();
            this.router.navigate(['/']);
            console.log(ok["status"]);
          } else {
            this.handleError(ok);
          }
        }

      })
    );
  }
  getToken() {


    if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "yes") {

      return localStorage.getItem("token")

    } if (localStorage.getItem("remember_me") && localStorage.getItem("remember_me") == "no") {

      return sessionStorage.getItem("token")

    } else {

      return null

    }
    console.log(localStorage.getItem("token"))
  }
  logout() {
    localStorage.clear();
    sessionStorage.clear();

    console.log('a')
    this.router.navigate(["/login"])
      .then(() => {
        window.location.reload();
      });

  }
  objToQueryString(obj: any): string {

    if (!obj) {
      return ""
    }

    const searchParams = new URLSearchParams();
    const params = obj;
    Object.keys(params).forEach(key => searchParams.append(key, params[key]));

    return searchParams.toString()

  }
  checkzipcode(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    console.log(localStorage.getItem("token"))


    let API_URL = `${API}users/check/pincode?pincode=${data}`;
    return this.http.get(API_URL, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error && error.error.errors) {
      // client-side error and server side
      if (Array.isArray(error.error.errors.msg)) { // validation error message

        if (error.error.errors.msg.length) {
          const ob = error.error.errors.msg[0]
          if (ob.msg == "IS_EMPTY") {
            errorMessage = `${ob.param} missing`
          } else {
            errorMessage = ob.msg

          }
        }
      } else {
        errorMessage = error.error.errors.msg;
      }
    } else {
      // server-side error
      if (error.status == 401) {
        // Unauthorised 
        this.logout();
        this.router.navigate(['/'])
      }

      console.log(error)
      errorMessage = `Something went wrong here`;
      // errorMessage = `${error.message}`;
    }
    console.log("I am here ", errorMessage);
    this.showMessage({
      message: errorMessage
    })
    return throwError(errorMessage);
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'login', {
      email,
      password
    });
  }


  Sociallogin(data: any): Observable<any> {
    let API_URL = `${AUTH_API}socialRegisterAndLogin`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  createFcmToken(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${AUTH_API}users/addFCM`
    return this.http.post(API_URL, data).pipe(
      map(res => {
        return res
      })
    )
  }
  contactUs(data: any): Observable<any> {
    let API_URL = `${AUTH_API}users/submittion/form`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  loginAutomatically(): Observable<any> {
    return this.http.post(AUTH_API + '/signin/automatically', null);
  }
  forgot(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}forgetEmail`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getCMSdata(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}users/get/cms?type=${obj}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  
  gethowworks(): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}users/getHowItWorks`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getblogs(obj: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}users/getwebpress?limit=${obj}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res;
      })
    );
  }


  getFaq(data:any): Observable<any> {
    const query = this.objToQueryString(data)

    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}users/getFaq?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getHelperList(data:any): Observable<any> {
    const query = this.objToQueryString(data)
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}users/getHelperList?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res;
      })
    );
  }


  forgotchange(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}forgotPassword`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  register(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    let API_URL = `${AUTH_API}register`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  resetpass(data: any): Observable<any> {
    // ? if need to convert to query use this -> // const query = this.helper.getQueryParams(data)
    // let token = localStorage.getItem("token")

    let API_URL = `${API}users/changePassword`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  
  confirmPin(token: string, confirmationType: boolean, confirmationTypeValue: string): Observable<any> {
    return this.http.post(AUTH_API + '/validate-pin', {
      token,
      confirmationType,
      confirmationTypeValue
    });
  }

  sendPasswordResetMail(email: string): Observable<any> {
    return this.http.post(AUTH_API + '/send-pwd-reset-mail', null, { params: new HttpParams().set('email', email) });
  }

  resendConfirmationMail(email: string): Observable<any> {
    return this.http.post(AUTH_API + '/resendConfirmationEmail', null, { params: new HttpParams().set('email', email) });
  }
  resendConfirmationSMS(phoneNumber: string): Observable<any> {
    return this.http.post(AUTH_API + '/resendConfirmationSMS', null, { params: new HttpParams().set('phoneNumber', phoneNumber) });
  }
  changePassword(newPassword: string, stringUUID: string): Observable<any> {
    return this.http.post(AUTH_API + '/reset-password', {
      newPassword,
      stringUUID,
    });
  }

  showMessage(object: any) {
    // this.toastr.success('Hello world!', 'Toastr fun!');
    this._snackBar.open(object.message, object.action ? object.action : "CLOSE", {
      duration: 3000,
      horizontalPosition: "center",
      verticalPosition: "bottom",
    })

  }
}
