<div class="card-wrapper">
  <div class="image-wrapper">
    <div class="image-sub-wrapper">
      <img [src]="room.roomType | roomImagePipe">
    </div>
  </div>
  <div class="card-content">
    <div class="card-header">
      <div class="room-type">{{room.roomType | enumPipe: ERoomType: true: false}}</div>
      <div class="estimated-cost-wrapper">
        <div class="estimated-cost-text">
          Estimated Cost
        </div>
        <div class="estimated-cost">
          {{lowerBound | currency:'USD'}} - {{upperBound | currency:'USD'}}
        </div>
      </div>
    </div>
    <div class="actions">
      <div class="tooltip">
        <img src="assets/project-creator/edit-room.png" alt="edit-room" (click)="editRoom()">
        <span class="tooltiptext">Edit Room</span>
      </div>
      <div class="tooltip">
        <img src="assets/project-creator/duplicate-room.png" alt="duplicate-room" (click)="duplicate()">
        <span class="tooltiptext">Duplicate Room</span>
      </div>
      <div class="tooltip">
        <img src="assets/project-creator/delete-room.png" alt="delete-room" (click)="deleteRoomModalVisible = true">
        <span class="tooltiptext">Delete Room</span>
      </div>
    </div>
  </div>
</div>
<app-modal [displayModal]="deleteRoomModalVisible" [title]="'Delete Room'"
  [subclaim]="'Are you sure you want to delete room?'" [confirmButtonText]="'Confirm'" (confirmButtonClicked)="delete()"
  (cancelButtonClicked)="deleteRoomModalVisible = false"></app-modal>