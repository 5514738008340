import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {RoutingConfirmDialogServiceReference} from './routing-confirm-dialog-service-reference';
import {RoutingConfirmationDialogComponent} from '../../components/navigation-others/routing-confirm-dialog/routing-confirm-dialog.component';


@Injectable()
export class RoutingConfirmDialogService {
  componentRef ?: ComponentRef<RoutingConfirmationDialogComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private confirmationDialogReferenceService: RoutingConfirmDialogServiceReference
  ) { }

  loadComponent(viewContainerRef: ViewContainerRef, nextState: any): any {
    this.confirmationDialogReferenceService.routerState = nextState;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(RoutingConfirmationDialogComponent);
    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.confirmationDialogReferenceService.componentRef = this.componentRef;
    return this.confirmationDialogReferenceService.allow;
  }

}
